import { Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from '../../commons/service/common.service';
import { AppSettings } from '../../commons/setting/app_setting';

@Component({
  selector: 'app-load-side',
  templateUrl: './load-side.component.html',
  styleUrls: ['./load-side.component.scss']
})
export class LoadSideComponent implements OnInit {
  @Input() skeletonLoader: any;
  @Input() loadDetail: any;
  @Input() loadId: any;
  @Input() userType: any;
  bidHistorySekeletonLoader: boolean;
  page: number;
  overBidCount: any;
  currentBid: any;
  acceptBidList: any;
  previousBid: any;
  public userId:any;
  public sidePanelProfileDataId:any;
  public sidePanelProfileData:any;
  public ifAccepted: boolean = false;
  totalPage: any;

  constructor(
    private route: ActivatedRoute,
    public commonService: CommonService,
    public dialog: MatDialog,
  ) { }

  ngOnInit() {
    this.userId = localStorage.getItem('user_id');
    this.getHistoryData();
    if (this.loadDetail.visibility === 'private') {
      this.ifAccepted = true;
    } else {
      this.ifAccepted = false;
    }
  }

  // Function to get bid history
  getHistoryData() {
    this.bidHistorySekeletonLoader = true;
    this.page = 1;
    var APIparams = this.getHiAPIParam();
    this.commonService.getList(APIparams).subscribe((ServerRes) => {
      this.bidHistorySekeletonLoader = false;
      this.overBidCount = ServerRes.overBidCount;
      if (ServerRes.response) {
        this.acceptBidList = ServerRes.response.acceptedBids;
        this.previousBid = ServerRes.response.previousBids;
        this.currentBid = ServerRes.response.currentBids;
        this.currentBid.forEach((value) => {
          if (value.status == '4' || this.loadDetail.visibility === 'private') {
            this.ifAccepted = true;
          } else {
            this.ifAccepted = false;
          }
        });
      } else {
        this.currentBid = [];
        this.acceptBidList = [];
        this.previousBid = [];
        this.totalPage = 0;
      }
    });
  }

  // Function to get params for API
  getHiAPIParam() {
    let APIparams, params;
    params = {
      limit: 10,
      shipmentId: this.route.snapshot.params['loadId'],
      page: this.page,
    };
    APIparams = {
      apiKey: AppSettings.APIsNameArray.BIDS.HISTORY,
      uri: this.commonService.getAPIUriFromParams(params),
    };
    return APIparams;
  }

  // Function to close side panel
  closePanel(event){
    this.sidePanelProfileDataId='';
  }

}
