import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { finalize } from 'rxjs/operators';
import { CommonService } from '../service/common.service';
import { AppSettings } from '../setting/app_setting';
import { AlertService } from '../service/alert.service';
import { SharedService } from '../service/shared.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.css'],
})

export class FeedbackComponent implements OnInit {
  public feedbackForm: FormGroup;
  public saving = false;
  public submitted = false;
  public loading = false;
  public message: any;
  public serverPathForUrl: any;
  public countSpace = 1;
  public searchText: any;
  public disabledButton = false;
  public userDetail: any;

  constructor(
    private alertService: AlertService,
    private commonService: CommonService,
    private fb: FormBuilder,
    public sharedService: SharedService,
    public dialogRef: MatDialogRef<FeedbackComponent>
  ) { }

  ngOnInit() {
    this.userDetail = this.sharedService.getAddress();
    this.serverPathForUrl = environment.serverPathForUrl;
    this.commonService.userData.subscribe((userData) => {
      if (userData && this.userDetail === undefined) {
        this.userDetail = userData;
      }
    });

    let userName;
    if (localStorage.getItem('user_type') === 'CARRIER') {
      userName = this.userDetail.legalName;
    } else if (localStorage.getItem('user_type') === 'SHIPPER') {
      userName = this.userDetail.companyName;
    } else if (localStorage.getItem('user_type') === 'STORAGE') {
      userName = this.userDetail.companyName;
    }

    this.feedbackForm = this.fb.group({
      description: ['', [Validators.required, Validators.maxLength(255)]],
      osVersion: ['8.0.2', [Validators.required]],
      appVersion: ['6.3.2', [Validators.required]],
      platform: ['WEB', [Validators.required]],
      legalName: [userName, [Validators.required]],
      email: [this.userDetail.email, [Validators.required]],
      phone: [this.userDetail.phone, [Validators.required]],
    });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  // To submit feedback form 
  feedbackSubmit({ value, valid }) {
    this.submitted = true;
    if (valid) {
      this.saving = true;
      this.loading = true;
      this.disabledButton = true;
      let APIparams = {
        apiKey: AppSettings.APIsNameArray.ADMIN_MANAGMENT.FADD,
        uri: '',
        postBody: value,
      };
      this.commonService
        .post(APIparams)
        .pipe(
          finalize(() => {
            this.saving = false;
          })
        )
        .subscribe(
          (success) => {
            this.disabledButton = false;
            if (success.success === true) {
              this.alertService.showNotificationMessage(
                'success',
                'bottom',
                'right',
                'txt_s',
                'check_circle',
                'Feedback',
                'Feedback received. Thank you!'
              );
              this.loading = false;
              this.onNoClick();
            } else if (success.success === false) {
              this.alertService.showNotificationMessage(
                'danger',
                'bottom',
                'right',
                'txt_d',
                'cancel',
                'Feedback',
                success.message
              );
            }
            this.loading = false;
          },
          (error) => {
            this.disabledButton = false;
            this.message = AppSettings.ERROR;
            this.alertService.showNotificationMessage(
              'danger',
              'bottom',
              'right',
              'txt_d',
              'cancel',
              'Feedback',
              AppSettings.ERROR
            );
            this.loading = false;
          }
        );
    }
  }

 
}
