import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CommonService } from 'src/app/commons/service/common.service';
import { AppSettings } from 'src/app/commons/setting/app_setting';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-lane-summary',
  templateUrl: './lane-summary.component.html',
  styleUrls: ['./lane-summary.component.scss']
})
export class LaneSummaryComponent implements OnInit {
  public loading = false;
  public waypointInfo: any = [];
  public skeletonLoader = true;
  public serverPathForUrl: any;

  constructor(
    public commonService: CommonService,
    private route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.serverPathForUrl = environment.serverPathForUrl;
    this.trackShipmentStatus();
  }

  trackShipmentStatus(params = {}) {
    this.skeletonLoader = true;
    let uri = null;
    let newParams = {
    laneId: this.route.snapshot.params['laneId'],
    };
    if (newParams) uri = this.commonService.getAPIUriFromParams(newParams);
    let APIparams = {
      apiKey: AppSettings.APIsNameArray.SHIPMENT.LANEWAYPOINTS,
      uri: uri,
    };
    this.commonService.getList(APIparams).subscribe(
       (ServerRes) => {
        if (ServerRes.success === true) {
          this.waypointInfo = ServerRes.response.waypoints;  
          this.skeletonLoader = false;
          for (let index = 0; index < this.waypointInfo.length; index++) {
            if(this.waypointInfo[index].type==='pickup'){
              this.waypointInfo[index].daynamicClass='pickup_box';         
            this.waypointInfo[index].locationTypeImage='https://staticprod.laneaxis.com/portal/images/after-login/icon_pic_point.png';
            }
            else if(this.waypointInfo[index].type==='dropoff' && index != this.waypointInfo.length-1){
              this.waypointInfo[index].daynamicClass='dropoff_box';         
            this.waypointInfo[index].locationTypeImage='https://staticprod.laneaxis.com/portal/images/after-login/icon_drop_point.png';
            }
             else if(this.waypointInfo[index].type==='dropoff' && index == this.waypointInfo.length-1){
              this.waypointInfo[index].daynamicClass='last_box';         
            this.waypointInfo[index].locationTypeImage='https://staticprod.laneaxis.com/portal/images/after-login/icon_drop_point.png';
            }
          }  
          this.loading = false;
        } else {
          this.loading = false;
        }
      },
      (error) => {
        if (error) {
          this.loading = false;
        }
      }
    );
  }


}
