import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertService } from 'src/app/commons/service/alert.service';
import { CommonService } from 'src/app/commons/service/common.service';
import { AppSettings } from 'src/app/commons/setting/app_setting';
import { StatusSetting } from 'src/app/commons/setting/status_setting';
import { NetworkPopupComponent } from 'src/app/network-shared/network-popup/network-popup.component';
import { SubscriptionPopupComponent } from 'src/app/shared/subscription-popup/subscription-popup.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-confirmed-load-list',
  templateUrl: './confirmed-load-list.component.html',
  styleUrls: ['./confirmed-load-list.component.scss']
})

export class ConfirmedLoadListComponent implements OnInit {
  @Output() eventPassAction = new EventEmitter();
  public showUnLockButton: boolean = true
  public eCarrierCheckData: any
  @Input() laneBidRecord: any;
  @Input() currentStatus: any;
  @Input() isArchived: any;
  @Input() subscriptionData: any;
  public loading: boolean = false
  public userType: any;
  public selfUserId: any;
  public type = 'listing';
  public userName: any;
  public sidePanelProfileDataId: any;
  public eCarrierCheckRisk: any;
  public sidePanelProfileData: any;
  public serverPathForUrl: any;

  constructor(
    public dialog: MatDialog,
    public commonService: CommonService,
    public router: Router, private alertService: AlertService,
    private route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.serverPathForUrl = environment.serverPathForUrl;
    this.eCarrierCheckRisk = StatusSetting.eCarrierCheckToolTip;
    this.userName = localStorage.getItem('userName');
    this.userType = localStorage.getItem('user_type');
    this.selfUserId = localStorage.getItem('user_id');
  }

   // Sending events to the child component by event emitter
   eventAction(event: any, type: any) {
    if (this.userType == 'SHIPPER' && (this.subscriptionData?.isPremium != true || this.subscriptionData?.subscriptionStatus == 'canceled')) {
      this.getSubscriptionAlertPopup(false);
    } else {
      this.eventPassAction.emit({ event, type });
    }
  }

  // Sending events to the child component by event emitter for profile side panel
  eventActionProfileSidePanel(event: any, type: any) {
    if (event?.status === 2 || (event?.actionBy != localStorage.getItem('user_id') && event.status === 1) || (event?.actionBy == localStorage.getItem('user_id') && event.status === 1)) {
      this.sidePanelProfileDataId = event?.carrier?.id
      this.sidePanelProfileData = event;
      this.eventPassAction.emit({ event, type });
    }
  }

  // Close side panel
  closePanel(event: any) {
    if (event?.type == 'close') {
      this.sidePanelProfileDataId = '';
    }
    else {
      this.sidePanelProfileDataId = '';
    }

  }

  ecarriercheck(laneBidRecord) {
    const dialogRef = this.dialog.open(NetworkPopupComponent, {
      disableClose: true,
      backdropClass: AppSettings.backdropClass,
      width: '1000px',
      height: 'calc(100vh - 100px)',
      data: { openPop: 'ecarriercheck', dotNumber: laneBidRecord?.dotNumber },
    });
    dialogRef.afterClosed().subscribe((result) => {
    });
  }

  getSubscriptionAlertPopup(type) {
    const dialogRef = this.dialog.open(SubscriptionPopupComponent, {
      disableClose: true,
      backdropClass: AppSettings.backdropClass,
      width: type == 'isUnlockSafetyScore' ? '500px' : AppSettings.popWidth,
      data: {
        openPop: 'subscriptionAlert',
        subscriptionData: this.subscriptionData,
        type: type
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.loading = false;
    });
    this.loading = false;

  }

  unlockSafetyScore(laneBidRecord,dotNumber, type) {
    this.loading = true
    if (this.userType == 'SHIPPER' && (((this.subscriptionData?.subscriptionCreditHistory?.defaultCredit + this.subscriptionData?.subscriptionCreditHistory?.additionalCredit) == 0) && this.subscriptionData?.isPremium != true && this.subscriptionData?.subscriptionStatus == 'canceled')
      || (this.subscriptionData?.isPremium == true && this.subscriptionData?.subscriptionStatus == 'active' && ((this.subscriptionData?.subscriptionCreditHistory?.defaultCredit + this.subscriptionData?.subscriptionCreditHistory?.additionalCredit) == 0))) {
      this.getSubscriptionAlertPopup('isUnlockSafetyScore');
    } else {
      let uri = null;
      let newParams = {
        dotNumber: dotNumber
      };
      if (newParams) uri = this.commonService.getAPIUriFromParams(newParams);
      let APIparams = {
        apiKey: AppSettings.APIsNameArray.CARRIER.ECARRIERCHECK,
        uri: uri,
      };
      this.commonService.getList(APIparams).subscribe(
        (ServerRes) => {
          if (ServerRes?.success == true) {
            this.showUnLockButton = false;
            this.eCarrierCheckData = ServerRes?.response;
            this.laneBidRecord.carrier.shipperCarrierScoreMapping = true;
            this.laneBidRecord.carrier.carrierSafetyScore={
              id : 71,
              authority : ServerRes.response.authority,
              riskLevel : ServerRes.response.riskLevel,
              riskLabel:ServerRes.response.riskLabel,
              recentCrashes :ServerRes.response.recentCrashesCount,
              dotNumber : ServerRes.response.profile?.dotNumber,
              recentInspections :ServerRes.response.recentInspectionsCount
            };
            this.loading = false;

          }
          else if (ServerRes.isCreditAvailable == false && ServerRes?.success == false) {
            const dialogRef = this.dialog.open(NetworkPopupComponent, {
              disableClose: true,
              backdropClass: AppSettings.backdropClass,
              width: '500px',
              data: { openPop: 'eCarreirCreadits', },

            });
            dialogRef.afterClosed().subscribe((result) => {
              this.loading = false;
            });
            this.loading = false;
          }
          else if (ServerRes?.success == false) {
            this.alertService.showNotificationMessage(
              'danger',
              'bottom',
              'right',
              'txt_d',
              'check_circle',
              'Outside Carrier',
              ServerRes?.message
            );
            this.loading = false
          }
        },
        (error) => {      
          this.alertService.showNotificationMessage(
            'danger',
            'bottom',
            'right',
            'txt_d',
            'check_circle',
            'Outside Carrier',
            error?.error?.message
          );
        this.loading = false;
      });
    }
  }

}
