interface Scripts {
  name: string;
  src: string;
}
import { environment } from "src/environments/environment";

export const ScriptStore: Scripts[] = [
  {
    name: 'heremap_1',
    src: 'https://js.api.here.com/v3/3.1/mapsjs-core.js',
  },

  {
    name: 'GoogleAnalytics',
    src: '/assets/js/tagManager-'+environment.pointType + '.js?id=' + environment.googleAnalyticsKey,
  },
 
  {
    name: 'stripe',
    src: 'https://js.stripe.com/v3/',
  },
  {
    name: 'dashJs',
    src: 'assets/js/dashJs.js',
  },
  {
    name: 'mapbox-gl',
    src: 'assets/css/mapbox-gl.css',
  },
  {
    name: 'mapbox-gl-geocoder',
    src: 'assets/css/mapbox-gl-geocoder.css',
  },
];
