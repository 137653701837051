<div class="payment_add_card mb-0" id="card-info">
  <div class="row" *ngIf="skeletonLoaderCard">
    <div class="col-md-3 cus_md5" *ngFor="let cardRecord of cardListing" (click)="cardRecord?.expiry ? '':selectCard(cardRecord?.id)">
      <div class="payment_cardAdded pointer" [ngClass]="currentCardActive === cardRecord?.id ? 'active_card_child' : ''">
        <span *ngIf="cardListing?.length > 1" class="icon-ic-cross fs_18 fsm_5 remove_card"
          (click)="removeCard(cardRecord?.id)"><i class="fa-solid fa-xmark"></i></span>
        <div class="card_container pointer">
          <div class="card_imgIcon">
            <img class="img-fluid notifications" id="{{cardRecord?.card?.brand}}1x" src="{{serverPathForUrl}}/portal/images/after-login/img_trans.gif">
          </div>
          <span class="mb-2 fs_16 fsb_6 txt_b">{{cardRecord?.billing_details?.name}}</span>
          <p class="mb-2 fs_16 fsb_6 txt_b">xxxx xxxx xxxx {{cardRecord?.card.last4 ? cardRecord?.card?.last4 : "-"}}</p>
          <p class="mb-0 fs_14 fsn_4 txt_lb">Valid thru {{cardRecord?.card?.exp_month ? cardRecord?.card?.exp_month : "-"}} / {{cardRecord?.card?.exp_year ? cardRecord?.card?.exp_year : "-"}} <span class="txt_d fsm_5">{{cardRecord?.expiry ? '(Expired)': ''}}</span>
          </p>
        </div>
      </div>
    </div>
    <div class="col-md-3 cus_md5">
      <div class="add_bill_info pointer mb-3" (click)="addPaymentCard()">
        <span class="material-icons fs_30 txt_p mb-1">control_point</span>
        <p class=" mb-0 fs_12 fsb_6 txt_p">Add Card</p>
      </div>
    </div>
  </div>
  <div *ngIf="skeletonLoaderCard" class="text-center">
    <u *ngIf="has_more && cardListing.length" class="fs_16 fsm_5 txt_p pointer" (click)="MoreCards()">View More</u>
    <p *ngIf="!has_more && cardListing.length" class="fs_16 fsm_5 txt_lb">No More Cards</p>
  </div>
  <div class="row" *ngIf="!skeletonLoaderCard">
    <div class="col-md-3 cus_md5" *ngFor="let fake of generateFake(4)">
      <div class="paycark_skelaton">
        <div class="loader_mb_5">
          <ngx-skeleton-loader count="1" [theme]="{ width: '30%', height: '40px'}"></ngx-skeleton-loader>
        </div>
        <div class="loader_mb_0">
          <ngx-skeleton-loader count="1" [theme]="{ width: '90%', height: '12px'}"></ngx-skeleton-loader>
        </div>
        <div class="loader_mb_0">
          <ngx-skeleton-loader count="1" [theme]="{ width: '90%', height: '12px'}"></ngx-skeleton-loader>
        </div>
      </div>
    </div>
  </div>
</div>