<div class="container_body_l">
    <div class="l_detail_card">
      <div class="d-flex align-items-center">
        <div class="map_img">
          <img src="{{loadDetail?.routeImageUrlSm}}" class="img-fluid">
        </div>
        <div class="l_label_value">
          <p class="l_label fs_14 txt_lb mb-2">Load Name</p>
          <p class="l_value fs_18 fsm_5 text_truncate pointer mb-2"
            matTooltip="{{loadDetail?.title}}">{{loadDetail?.title ? loadDetail?.title : '-'}}</p>
          <div>
            <span class="truck_lo_status">{{loadDetail?.equipmentName ? loadDetail?.equipmentName : '-'}}</span>
            <span class="shipment_complete_date">{{loadDetail?.statusLabel}} at {{loadDetail?.updatedAt | date: 'MMM dd, yyyy'}} at {{loadDetail?.updatedAt | date:'HH:mm'}}</span>
          </div>
        </div>
      </div>
      <div class="d-flex">
       <div class="l_label_value">
        <p class="l_label fs_14 txt_lb mb-2">Created On</p>
        <p class="l_value mb-0">{{loadDetail?.createdAt | date: 'MMM dd, yyyy'}} at {{loadDetail?.createdAt | date:'HH:mm'}}</p>
      </div>
    </div>
    </div>
  </div>
  
  <div class="" style="position: relative;" *ngIf="sidePanelProfileDataId && userType==='CARRIER'">
    <div class="side-show-panel">
      <div class="side_bar_con">
        <app-shipper-side-panel [sidePanelProfileData]="sidePanelProfileData" [sidePanelProfileDataId]="sidePanelProfileDataId" [userType]="userType" 
        (closeSidePanel)="closePanel($event)"></app-shipper-side-panel>
      </div>
    </div>
  </div>