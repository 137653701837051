import { Location } from '@angular/common';
import { Component, HostListener, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/commons/service/common.service';
import { AppSettings } from 'src/app/commons/setting/app_setting';
import { SharedService } from 'src/app/commons/service/shared.service';
import { AlertService } from 'src/app/commons/service/alert.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-confirmed-rate-history',
  templateUrl: './confirmed-rate-history.component.html',
  styleUrls: ['./confirmed-rate-history.component.scss']
})
export class ConfirmedRateHistoryComponent implements OnInit {
  public sidePanelProfileDataId: any;
  public postedLanesCount:any;
  public availableLanes:any;
  public confirmCount:any;
  public confirmedLanesCount:any;
  public archivedLanesCount:any;
  public subTabMenu: any;
  public clearAllEnable = false;
  public userType: any;
  public phStates: any;
  public currentUrlTab: any;
  public equipmentListing: any = [];
  public currentRouteParams: any;
  public params: any;
  public cancelRequestUser: any = null;
  public searchBoxError = false;
  public isLane = true;
  public filterCheck = true;
  public countSpace = 1;
  page = 1;
  public currentSearchStatus: any;
  public equipmentTypeFilter: any;
  public equipmentName: any;
  public equipmentNameShow: any;
  public stateShow: any;
  public stateName: any;
  totalPage = 1;
  public titleFilter: any;
  public currentStatus = 'confirmRateHistory';
  public equipmentTypeId: any;
  public tabCount: any;
  public lanesList = [];
  public tabActionDisabled = false;
  public getTotalHeight: any;
  public skeletonLoader = false;
  public subscriptionData:any;
  public searchModel: any = { pickupLocation: '', dropoffLocation: '' };
  public totalLanes: any;
  loading: boolean;
  public spinnerLoader = false;
  public dataNotFound = false;
  stateId: any;
  public serverPathForUrl: any;

  constructor(
    public location: Location,
    public commonService: CommonService,
    public dialog: MatDialog,
    private router: Router,
    private sharedService: SharedService,
    private alertService: AlertService,
  ) { }

  
  @HostListener('window:wheel', ['$event'])
  onWindowScroll(event) {
    this.getTotalHeight = window.innerHeight + window.scrollY;
    if (
      $(window).scrollTop() + 1 >=
      $(document).height() - $(window).height()
    ) {
      if (
        this.page !== this.totalPage &&
        this.page >= 1 &&
        this.totalPage &&
        this.lanesList.length > 0
      ) {
        this.page = this.page + 1;
        this.spinnerLoader = true;
        this.dataNotFound = false;
      } else if (this.spinnerLoader === false) {
        this.dataNotFound = true;
      }
    }
  }

  ngOnInit(): void {
    this.userType=localStorage.getItem('user_type');
    this.serverPathForUrl = environment.serverPathForUrl;
    this.params = {
      limit: 12,
    };
    this.getStates();
    let configValue = this.sharedService.getConfig();
    if (configValue !== undefined) {
      this.getConfigSet(configValue);
    }
    this.commonService.configData.subscribe((userData) => {
      this.getConfigSet(userData);
    });

    this.filterByStatus(this.currentStatus);
    
    if (this.userType === 'SHIPPER') {
      this.getSubscriptionAlert();
    }
  }

   // Function for generate duplicate loaders 
   public generateFake(count: number): Array<number> {
      const indexes = [];
      for (let i = 0; i < count; i++) {
        indexes.push(i);
      }
      return indexes;
    }

 // Function to get data from config API 
  getConfigSet(configValue: any) {
     this.equipmentListing = configValue.equipmentTypes;
  }

  // To filter tabs according to currentStatus which is included in route
   filterByStatus(event: any) {
    this.dataNotFound = false;
    this.page = 1;
    if (event !== null) {
      if (localStorage.getItem('user_type') === 'SHIPPER') {
        if (event === 'confirmRateHistory') {
          this.currentRouteParams = 'confirmRateHistory';
          this.currentStatus = 'confirmRateHistory';
          this.currentUrlTab = 'confirmRateHistory';
          this.subTabMenu = 'confirmRateHistory';
        }
      }
      this.currentStatus = event;
    }
    this.applyFilterSubmit();
  }

  // To get states list from API call
   getStates(){
      this.commonService.getStateList().subscribe((serverRes) => {
        if (serverRes.success == true) {
         this.phStates = serverRes.response;
        } else if (serverRes.success == false) {
          this.alertService.showNotificationMessage(
            'danger',
            'bottom',
            'right',
            'txt_d',
            'check_circle',
            'States',
            'Unexpected Error.'
          );
        }
      });
    }

      // To get Equipment Type
  equipmentSelected(equipment: any) {
    this.filterCheck = false;
    this.searchModel.equipmentId = parseInt(equipment.id);
    this.equipmentName = equipment.label;
    this.equipmentTypeId = parseInt(equipment.id);
  }

  // To get state name
  stateSelected(state: any) {
    this.filterCheck = false;
    this.searchModel.stateId = state.sortname;
    this.stateName = state.name;
    this.stateId = state.sortname;
  }

    
  /*Remove filtered data by removing single filter value.*/
  singleFilterClose(event) {
    if (event === 'title') {
      this.titleFilter = null;
      this.params.title = null;
      this.searchModel.title = null; 
    }
    if (event === 'equipment') {
      this.equipmentTypeFilter = null;
      this.params.equipmentType = null;
      this.searchModel.equipmentType = null;
      this.searchModel.equipmentId = null;
      this.equipmentNameShow = null;
      this.equipmentName = null;
      this.equipmentTypeId = null;
    }
    if (event === 'state') {
      this.params.state = null;
      this.params.stateId = null;
      this.searchModel.state = null;
      this.searchModel.stateId = null;
      this.stateShow = null;
      this.stateName = null;
      this.stateId = null;
    }
    if (this.searchModel.equipmentId == null && this.searchModel.stateId == null && this.searchModel.title == null){
      this.filterCheck = true;
    }
    this.lanesList = [];
    this.tabCount = '';
    this.page = 1;
    this.skeletonLoader = false;
    if (this.equipmentName) {
      this.currentSearchStatus = 'search';
    } else {
      this.currentSearchStatus = '';
    }
    this.laneList(this.params);
  }

  applyFilterSubmit(){
    this.skeletonLoader = false;
      this.lanesList = [];
      this.tabCount = '';
      this.page = 1;
      this.clearAllEnable = true;
      if (this.searchModel.title) {
        this.params.title = this.searchModel.title;
        this.titleFilter = this.searchModel.title;
      } else {
        this.params.title = null;
        this.titleFilter = null;
        this.searchModel.title=null
      }
      if (this.equipmentName) {
        this.params.equipmentType = this.searchModel.equipmentId;
        this.equipmentNameShow = this.equipmentName;
        this.equipmentTypeId = this.searchModel.equipmentId;
      } else {
        this.params.equipmentType = null;
        this.equipmentNameShow = null;
        this.equipmentTypeId = null;
      }
      if (this.stateName) {
        this.params.stateId = this.searchModel.stateId;
        this.searchModel.stateId = this.searchModel.stateId;
        this.stateShow = this.stateName; 
      } else {
        this.params.stateId = null;
        this.stateShow = null;
        this.stateId = null;
      }
      if (this.searchModel.equipmentId == null && this.searchModel.stateId == null && this.searchModel.title == null){
        this.filterCheck = true;
      }
      else{
        this.filterCheck = false;
      }
      if (
        this.searchModel.title ||
        this.equipmentName ||
        this.stateName
      ) {
        this.currentSearchStatus = 'search';
      } else {
        this.currentSearchStatus = '';
      }
    this.laneList(this.params);
  }

  
    /*Search filter by keyup value*/
      searchQuantity(event: any) {
        let searchStr = event.target.value;
        this.searchModel.title = searchStr;
        if(searchStr){
          this.filterCheck=false;
        }
        else if(this.titleFilter){
          this.filterCheck=false;
        }
        else{
          this.filterCheck=true;
    
        }
       
        let lastword = searchStr.charAt(searchStr.length - 1);
        if (lastword === ' ') {
          this.countSpace = this.countSpace + 1;
        } else {
          this.countSpace = 0;
        }
        if (this.countSpace >= 2 || (lastword === ' ' && searchStr.length === 1)) {
          this.searchModel.title = this.searchModel.title.substring(
            0,
            this.searchModel.title.length - 1
          );
          this.searchBoxError = true;
        } else {
          this.searchBoxError = false;
        }
      }


    // To clear all the data from filter popup 
    clearFilter(event) {
      this.clearAllEnable=false;
      this.params.title=null;
      this.equipmentTypeFilter = null;
      this.searchModel.equipmentId = null;
      this.params.equipmentType = null;
      this.equipmentName = null;
      this.searchModel.title = null;
      this.params.state = null;
      this.stateName = null
      this.searchModel.stateId = null;
      this.params.stateId = null;
      this.stateId = null;
  
      if (event){
        this.currentStatus = event;
      }else{
        this.currentStatus = this.currentStatus;
        this.applyFilterSubmit();
  
      }
    }

  // To get lanes list from API
  laneList(params = {}) {
    this.tabCount = '';
    this.lanesList = [];
    this.page = 1;
    let uri = null;
    var APIparams = this.getAPIParam(null);
     this.router.navigate(['/recurring-lanes/' + this.currentStatus], {
      queryParams: {
        equipmentType: this.params?.equipmentType,
        equipmentName: this.equipmentName,
        title: this.params?.title,
        state: this.stateName,
        stateId: this.params?.stateId,
      },
     });
    this.cancelRequestUser = this.commonService.getList(APIparams).subscribe(
      (ServerRes) => {
        if (ServerRes.success === true) {
          this.cancelRequestUser = null;
          this.tabActionDisabled = false;
          this.totalLanes = ServerRes.response.totalLanes;
          this.totalPage = ServerRes.response.totalPage;
          this.tabCount = ServerRes.response?.tabCount;
          this.confirmedLanesCount = ServerRes.response?.tabCount?.confirmedLanes;
          this.postedLanesCount = ServerRes.response.tabCount?.postedLanes;
          this.archivedLanesCount = ServerRes.response.tabCount?.archivedLanes;
          this.availableLanes = ServerRes.response?.tabCount?.availableLanes;
          this.confirmCount = ServerRes.response?.tabCount?.confirmedLanes;

          this.skeletonLoader = true;
          let result = ServerRes.response.lanes;
          if (result && result.length > 0) {
            for (let v = 0; v < result.length; v++) {
            }
            this.lanesList = result;
          } else {
            this.lanesList = [];
          }
          this.loading = false;
        } else {
          this.lanesList = [];
          this.totalLanes = 0;
          this.loading = false;
          this.totalPage = 0;
          this.tabCount = 0;
          this.skeletonLoader = true;
          this.tabActionDisabled = false;
        }
      },
      (error) => {
        this.skeletonLoader = true;
        this.lanesList = [];
        this.totalLanes = 0;
        this.loading = false;
        this.totalPage = 0;
        this.tabCount = 0;
        this.tabActionDisabled = false;
      }
    );
  }
       // To get required params for API 
    getAPIParam(str) {
      let APIparams, params;
      params = {
        limit: 12,
        page: this.page,
          state: this.stateName,
          equipmentType: this.params?.equipmentType,
          equipmentName: this.equipmentName,
          title: this.params?.title,
          orderBy: 'DESC',
          tabType: this.currentStatus,
      };
      APIparams = {
        apiKey: AppSettings.APIsNameArray.SHIPMENT.NEWLANELIST,
        uri: this.commonService.getAPIUriFromParams(params),
      };
      return APIparams;
    }
    
    // Get more data by pagination for lane.
    getMoreData(str) {
      this.spinnerLoader = true;
      var APIparams = this.getAPIParam(str);
      this.commonService.getList(APIparams).subscribe((ServerRes) => {
        let result = ServerRes.response.lanes;
        this.totalLanes = ServerRes.response.totalLanes;
        this.spinnerLoader = false;
        if (ServerRes.response.lanes && ServerRes.response.lanes.length > 0) {
          for (let v = 0; v < result.length; v++) {
            if (result[v]) this.lanesList.push(result[v]);
          }
        }
      });
    }

  // function for page scrolling
  onScroll() {
    if (this.page < this.totalPage) {
      this.page = this.page + 1;
      this.getMoreData(null);
    }
  }

   // Functions to get subscription alert popup
   getSubscriptionAlert() {
     this.commonService.getSubscriptionAlert().subscribe((serverRes) => {
       this.subscriptionData =  serverRes.response;
    });
   }


}
