import { Component, Input, ViewEncapsulation, OnChanges } from '@angular/core';
import { SharedService } from '../../commons/service/shared.service';

@Component({
  selector: 'app-order-progress',
  templateUrl: './order-progress.component.html',
  styleUrls: ['./order-progress.component.css'],
  encapsulation: ViewEncapsulation.None,
})

export class OrderProgressComponent implements  OnChanges {
  @Input() updatedAt: any;
  @Input() statusBars: any;
  @Input() statusLabel: any;

  counter(i: number) {
    return new Array(i);
  }

  constructor(
    public sharedService: SharedService
  ) {}

  ngOnChanges() {}

  toltipPick() {
    let levelValue = 'order';
    return levelValue;
  }

  toltipDrop() {
    let levelValue = 'bidding';
    return levelValue;
  }

  toltipDeliverd() {
    let levelValue = 'confirmed';
    return levelValue;
  }

}
