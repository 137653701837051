import { Injectable, EventEmitter } from '@angular/core';
import { Subscription } from 'rxjs/internal/Subscription';
@Injectable({
  providedIn: 'root',
})
export class EventEmitterService {
  nameChange = new EventEmitter();
  subsVar: Subscription;

  constructor() {}

  userProfileName() {
    this.nameChange.emit();
  }
}
