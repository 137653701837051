import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AppSettings } from '../../commons/setting/app_setting';
import { CommonService } from 'src/app/commons/service/common.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-storage-profile-side-panel',
  templateUrl: './storage-profile-side-panel.component.html',
  styleUrls: ['./storage-profile-side-panel.component.css']
})

export class StorageProfileSidePanelComponent implements OnInit {
  public logout: any;
  public storageSidePanelData:any;
  public loading = false;
  @Output() closeSidePanel = new EventEmitter();
  @Input() userType:any
  @Input() sidePanelProfileData: any;
  @Input() sidePanelProfileDataId:any
  @Input() findUserType: any;
  @Input() showSidePanel: any;
  @Input() currentUrlTab: any;
  @Input() isUserSidePanel:any;
  @Input() getUseProfileNewTab: any;
  public serverPathForUrl: any;

  constructor(
    public commonService: CommonService,
    public dialog: MatDialog
  ) {}

  ngOnInit() {
    this.logout = localStorage.getItem('login');
    this.serverPathForUrl = environment.serverPathForUrl;
    this.userType = localStorage.getItem('user_type');
    if(this.sidePanelProfileDataId){
      this.getApi();
    }
  }

  closePanel(value: any, type: any) {
    this.closeSidePanel.emit({ value, type });
  }

  websiteOpen(showSidePanel: any) {
    if (showSidePanel?.websiteUrl) {
      if (
        showSidePanel?.websiteUrl.indexOf('http://') == 0 ||
        showSidePanel?.websiteUrl.indexOf('https://') == 0
      ) {
        window.open(showSidePanel?.websiteUrl, '_blank');
      } else {
        window.open('//' + showSidePanel?.websiteUrl, '_blank');
      }
    } else if (showSidePanel?.storage.websiteUrl) {
      if (
        showSidePanel?.storage.websiteUrl.indexOf('http://') == 0 ||
        showSidePanel?.storage.websiteUrl.indexOf('https://') == 0
      ) {
        window.open(showSidePanel?.storage.websiteUrl, '_blank');
      } else {
        window.open('//' + showSidePanel?.storage.websiteUrl, '_blank');
      }
    }
  }

  getApi(){
    this.loading = true;
    let uri = null;
    let newParams = {
      storageId: this.sidePanelProfileDataId
    };
    if (newParams) uri = this.commonService.getAPIUriFromParams(newParams);
    let APIparams = {
      apiKey: AppSettings.APIsNameArray.COLDSTORAGE.GET,
      uri: uri,
    };
    this.commonService.getList(APIparams).subscribe(
       (ServerRes) => {
        this.storageSidePanelData=ServerRes.response;
      })
  }
  
}

