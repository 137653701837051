<div class="accountNot_connected">
    <div class="shipmetList_con shipment_creat_by_me">
      <div class="shipment_list_body tab_background">
        <div class="container">
          <div class="shipment_list_container cus_matTab {{currentStatus}}">
            <div class="ship_avance_src">
              <div style="display: flex;">
                <div class="advance_src">
                  <div class="user_search">
                    <div class="srch_top network_src src_cancel_icons m-0">
                      <mat-form-field appearance="none">
                        <input matInput placeholder="Search" maxlength="64" [(ngModel)]="searchModel.title" #title="ngModel" [ngModelOptions]="{standalone: true}" (keyup)="searchQuantity($event)" autocomplete="off" class="custom_input border-none">
                      </mat-form-field>
                    </div>
                  </div>
                  <div class="search_action" (click)="applyFilterSearch()">
                    <img src="{{serverPathForUrl}}/portal/images/search-filter-icon/filter_seach_icon.svg">
                  </div>
                </div>
                <div class="advance_filter" (click)="advanceFilter()">
                  <span class="advance_filter_icon">
                    <img src="{{serverPathForUrl}}/portal/icon/active_load_filtter_icon.svg" class="img-fluid">
                  </span>
                </div>
              </div>
            </div>
            <div class="label_one">
              <div class="second_label_tab">
                <div class="tabLabels" *ngIf="currentStatus">
                   <div *ngIf="!skeletonLoader">
                    <ngx-skeleton-loader [theme]="{ width: '87px', height: '14px' }" class="loader_m_0 skt_btn_loader mb-2"></ngx-skeleton-loader>
                    <ngx-skeleton-loader [theme]="{ width: '87px', height: '14px' }" class="loader_m_0 skt_btn_loader mb-2"></ngx-skeleton-loader>
                    <ngx-skeleton-loader [theme]="{ width: '87px', height: '14px' }" class="loader_m_0 skt_btn_loader mb-2"></ngx-skeleton-loader>
                    <ngx-skeleton-loader [theme]="{ width: '87px', height: '14px' }" class="loader_m_0 skt_btn_loader mb-2"></ngx-skeleton-loader>
                   </div>
                  <div *ngIf="skeletonLoader">
                    <span class="tabLabel" (click)="subTabMenuFilter('inProgress')" [ngClass]="currentStatus === 'inProgress' ? 'tabActive' : ''">
                       <span>In - Transit</span>
                       <span *ngIf="tabCount"> ({{tabCount.inProgress}})</span>
                    </span>
                    <span class="tabLabel" (click)="subTabMenuFilter('upcoming')" [ngClass]="(currentStatus === 'upcoming' || currentStatus === 'pending') ? 'tabActive' : ''">
                      <span>Upcoming</span>
                      <span *ngIf="tabCount"> ({{tabCount.upcoming + tabCount.pending}})</span>
                    </span>
                    <span class="tabLabel" (click)="subTabMenuFilter('past')" [ngClass]="currentStatus === 'past' ? 'tabActive' : ''">
                       <span>Completed</span>
                       <span *ngIf="tabCount"> ({{tabCount.past}})</span>
                    </span>
                    <span class="tabLabel" (click)="subTabMenuFilter('expired')" [ngClass]="currentStatus === 'expired' ? 'tabActive' : ''">
                        <span>Expired</span>
                        <span *ngIf="tabCount"> ({{tabCount.expired}})</span>
                    </span>
                   </div>
                </div>
              </div>
                  
             <div class="upcomming_sub_tabs" *ngIf="currentStatus === 'upcoming' || currentStatus === 'pending'">
               <div *ngIf="!skeletonLoader">
                 <ngx-skeleton-loader [theme]="{ width: '87px', height: '14px' }" class="loader_m_0 skt_btn_loader mb-2"></ngx-skeleton-loader>
                 <ngx-skeleton-loader [theme]="{ width: '87px', height: '14px' }" class="loader_m_0 skt_btn_loader mb-2"></ngx-skeleton-loader>
               </div>
              <div *ngIf="skeletonLoader">
               <span class="tabLabel" (click)="subTabMenuFilter('upcoming')" [ngClass]="currentStatus === 'upcoming' ? 'tabActive' : ''">
                 <span>Assigned</span>
                 <span *ngIf="tabCount"> ({{tabCount.upcoming}})</span>
               </span>
  
               <span class="tabLabel"  *ngIf="userType == 'SHIPPER'" (click)="subTabMenuFilter('pending')" [ngClass]="currentStatus === 'pending' ? 'tabActive' : ''">
                 <span>Carrier Needs to Assign Driver</span>
                 <span *ngIf="tabCount"> ({{tabCount.pending}})</span>
               </span>

               <span class="tabLabel" *ngIf="userType == 'CARRIER'" (click)="subTabMenuFilter('pending')" [ngClass]="currentStatus === 'pending' ? 'tabActive' : ''">
                <span>Waiting on driver to accept</span>
                <span *ngIf="tabCount"> ({{tabCount.pending}})</span>
              </span>
              </div>
             </div>

             <app-load-view-filter [titleFilter]="titleFilter" [title]="title" [pickupLocationShow]="pickupLocationShow" [dropoffLocationShow]="dropoffLocationShow" [pickupDateFilter]="pickupDateFilter" [dropOffDateFilter]="dropDateFilter" [driverNameShow]="driverNameShow" [statusLabelShow]="statusLabelShow" [carrierNameShow]="carrierNameShow" [equipmentNameShow]="equipmentNameShow" [selfId]="selfId" (clearOneFilter)="singleFilterClose($event)"></app-load-view-filter>
             <div class="row mx-auto" *ngIf="skeletonLoader && shipmentList?.length > 0" infiniteScroll [infiniteScrollDistance]="3" [infiniteScrollThrottle]="100" 
              (scrolled)="onScroll()" [scrollWindow]="false" style="overflow-y: auto; height: 70vh;">
              <div class="col-lg-3 col-md-6" *ngFor="let spotRateActiveLoad of shipmentList">
                <app-shipment-created-by-me-card [shipmentRecord]="spotRateActiveLoad" [currentStatus]="currentStatus" [currentRouteParams]="currentRouteParams" [subscriptionData]="subscriptionData" (assingDriver)="shipmentAssignDriver($event)" (eventPassAction)="eventAction($event)"></app-shipment-created-by-me-card>
              </div>
            </div>
             <div class="row mx-auto" *ngIf="skeletonLoader && shipmentList?.length === 0">
              <div class="col-md-12">
                <app-load-empty-screen [currentStatus]="currentStatus" [currentSearchStatus]="currentSearchStatus"></app-load-empty-screen>
              </div>
            </div>
  
              <div class="row mx-auto" *ngIf="!skeletonLoader && shipmentList?.length === 0">
                <div class="col-lg-3 col-md-6" *ngFor="let fake of generateFake(4)">
                  <app-load-skeleton-card></app-load-skeleton-card>
                </div>
              </div>
           </div>
        
            <!-- spinner loader -->
            <div class="content_loader mt-3 mb-4" *ngIf="shipmentList.length > 0 && spinnerLoader">
              <span class="loader_image">
                <img src="{{serverPathForUrl}}/portal/images/after-login/cl-icons/loader-blue.svg" class="img-fluid">
              </span>
            </div>
            <div *ngIf="shipmentList.length > 0 && dataNotFound && page > 1" class="noMore_record">
              <span>There are no more records to show.</span>
            </div>
          </div>
         </div>
         <div class="net_filter_popup" *ngIf="showAdvancedFilter">
          <div>
            <div class="container filtr_top_arrow">
              <div class="custum_input_css" style="margin: 0 0 12px;">
                  <div class="row">
                    <div class="col-md-12">
                      <div style="margin: 0 35px 0 0;">
                        <p class="fs_16 fsm_5 txt_b f_clash" style="margin: 0 0 20px;" *ngIf="userType==='SHIPPER'">Carrier Details</p>
                        <p class="fs_16 fsm_5 txt_b f_clash" style="margin: 0 0 20px;" *ngIf="userType==='CARRIER'">Driver Details</p>
                        <div class="row">
                          <div class="col-md-6 ic_dropdown" *ngIf="userType==='SHIPPER'">
                            <p class="fs_14 fsn_4 txt_lb mb-0">Select Carrier</p>
                            <mat-form-field appearance="none" class="removeappearance" class="drop_bg">
                              <mat-select placeholder="Select carrier" class="form-control custom_input" [(ngModel)]="searchModel.carrierId" #carrierId="ngModel" [ngModelOptions]="{standalone: true}">
                                <mat-option (click)="emptyCarrier()">Select Carrier</mat-option>
                                <mat-option *ngFor="let carrierRecord of carrierList" [value]="carrierRecord.userId" (click)="carrierSelected(carrierRecord)">{{ carrierRecord.legalName }}</mat-option>
                              </mat-select>
                            </mat-form-field>
                          </div>
  
                          <div class="col-md-6 ic_dropdown" *ngIf="userType==='CARRIER'">
                            <p class="fs_14 fsn_4 txt_lb mb-0">Select Driver</p>
                            <mat-form-field appearance="none" class="removeappearance" class="drop_bg">
                              <mat-select [disabled]="driverDisabled" placeholder="Select driver" class="form-control custom_input" [(ngModel)]="searchModel.driver" #driver="ngModel" [ngModelOptions]="{standalone: true}">
                                <mat-option (click)="driverEmpty()">Select Driver</mat-option>
                                <mat-option *ngFor="let driverRecord of driverList" [value]="driverRecord.userId" (click)="driverSelected(driverRecord)">{{ driverRecord.name }}</mat-option>
                              </mat-select>
                            </mat-form-field>
                          </div>
                          <div *ngIf="selectedIndex===0" class="col-md-6 ic_dropdown">
                            <p class="fs_14 fsn_4 txt_lb mb-0">Shipment Status</p>
                            <mat-form-field appearance="none" class="removeappearance" class="drop_bg">
                              <mat-select placeholder="Shipment Status" class="form-control custom_input" [(ngModel)]="searchModel.status" #status="ngModel" [ngModelOptions]="{standalone: true}">
                                <mat-option (click)="statusEmpty()">Shipment Status</mat-option>
                                <ng-container *ngFor="let statusType of finalStatus">
                                  <mat-option *ngIf="statusType.isActiveForRoles.includes(userType)" [value]="statusType.slugName" (click)="statusSelected(statusType)">{{statusType.labelName}}</mat-option>
                                </ng-container>
                              </mat-select>
                            </mat-form-field>
  
                          </div>
                          <div class="col-md-6 ic_dropdown">
                            <p class="fs_14 fsn_4 txt_lb mb-0">Equipment Type</p>
                            <mat-form-field appearance="none" class="removeappearance" class="drop_bg">
                              <mat-select placeholder="Equipment Type" class="form-control custom_input" [(ngModel)]="searchModel.equipmentId" #equipmentId="ngModel" [ngModelOptions]="{standalone: true}">
                                <mat-option (click)="equipmentEmpty()">Equipment Type</mat-option>
                                <mat-option *ngFor="let equip of equipmentListing" [value]="equip.id" (click)="equipmentSelected(equip)">{{equip.label}}</mat-option>
                              </mat-select>
                            </mat-form-field>
                          </div>
                          <div class="col-md-6" *ngIf="userType==='CARRIER'">
                            <p class="fs_14 fsn_4 txt_lb mb-0" style="opacity: 0;">A</p>
                            <div class="custom_checkbox" style="margin: 14px 0 0;">
                              <mat-checkbox (change)="selfSelected($event)" [(ngModel)]="selfchecked" [disabled]="checkSelfDisabled" [checked]="selfchecked">Shipment Assigned to Self</mat-checkbox>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="net_filter_action m-0">
                <div class="container">
                  <div class="row">
                    <div class="action_btn">
                      <span class="pointer txt_p fs_16 fsm_5 f_clash" (click)="clearRecordFilter('clear')">Clear All</span>
                      <div>
                        <span (click)="closeFilterPopup()" class="cancel_btn f_clash pointer">Cancel</span>
                        <button type="button" class="btn custum_button button_success" (click)="applyFilterSubmit()">Apply</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
  
        <div class="" style="position: relative;" *ngIf="sidePanelData">
          <div class="side-show-panel">
            <div class="side_bar_con">
              <app-shipment-created-by-me-side-panel [shipmentDetail]="sidePanelData" [currentStatus]="currentStatus" [currentRouteParams]="currentRouteParams" [subscriptionData]="subscriptionData" (closeSidePanel)="closePanel($event)" (refreshList)="refreshListLoad()"></app-shipment-created-by-me-side-panel>
            </div>
          </div>
        </div>
  
        <div class="" style="position: relative;" *ngIf="sidePanelProfileDataId && userType==='SHIPPER'">
          <div class="side-show-panel">
            <div class="side_bar_con">
              <app-carrier-side-panel [sidePanelProfileData]="sidePanelProfileData" [sidePanelProfileDataId]="sidePanelProfileDataId" [subscriptionData]="subscriptionData" [userType]="userType" 
              (closeSidePanel)="closePanel($event)"></app-carrier-side-panel>
            </div>
          </div>
        </div>
  
        <div class="" style="position: relative;" *ngIf="sidePanelProfileDataId && userType==='CARRIER'">
          <div class="side-show-panel">
            <div class="side_bar_con" *ngFor="let spotRateActiveLoad of shipmentList">
              <app-driver-side-panel [shipmentDetail]="spotRateActiveLoad" [sidePanelProfileData]="sidePanelProfileData" [sidePanelProfileDataId]="sidePanelProfileDataId" [userType]="userType" 
              (closeSidePanel)="closePanel($event)"></app-driver-side-panel>
            </div>
          </div>
        </div>
  
      </div>
    </div>
    <ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '14px',fullScreenBackdrop:true }"></ngx-loading>
    <!-- MY CODE -->