<div class="edit_Multi_Stop_form" *ngIf="data.openPop==='showShipmentDetail'">
	<div class="header_popup">
		<p class="fs_20 fsm_5 txt_b f_clash mb-0 line_before">{{data?.shipmentDetail?.type | titlecase}} Point
			{{data?.shipmentDetail?.locationOrder}}</p>
		<span class="close_popup_Icon" (click)="onClose()">
			<img src="{{serverPathForUrl}}/portal/icon/ic_close_white.svg" class="close_Icon">
		</span>
	</div>

	<div class="v_scrollBar popup_vh" style="max-height: 80vh; padding: 0 24px; margin: 0 -24px;">
		<div class="row">
			<div class="col-md-3">
				<div class="con_wrapper_row">
					<div class="row_label">Business Name</div>
					<div class="row_value">{{data?.shipmentDetail?.businessName ? data?.shipmentDetail?.businessName : '-'}}</div>
				</div>
			</div>
			<div class="col-md-3">
				<div class="con_wrapper_row">
					<div class="row_label">Location</div>
					<div class="row_value">{{data?.shipmentDetail?.locationLongName}}</div>
				</div>
			</div>
			<div class="col-md-3">
				<div class="con_wrapper_row">
					<div class="row_label">ZIP Code</div>
					<div class="row_value">{{data?.shipmentDetail?.zip}}</div>
				</div>
			</div>
			<div class="col-md-3">
				<div class="con_wrapper_row">
					<div class="row_label">Date & Time</div>
					<div class="row_value" *ngIf="data?.shipmentDetail?.date">{{data?.shipmentDetail?.date | date: 'MMM dd, yyyy'}} {{data?.shipmentDetail?.timeSlot}} {{data?.shipmentDetail?.timeZone}}</div>
				</div>
			</div>
			<div class="col-md-3">
				<div class="con_wrapper_row">
					<div class="row_label">Contact Number</div>
					<div class="row_value d-flex">
						<span *ngIf="data?.shipmentDetail?.countryCode && data?.shipmentDetail?.phone"
							class="ctry_flag"> <img [src]="countryFlag" name="cla">
							<span>+{{countryCode }}</span>
						</span>
						<span>{{data?.shipmentDetail?.phone ? data?.shipmentDetail?.phone : '-'}}</span>
					</div>
				</div>
			</div>
			<div class="col-md-3">
				<div class="con_wrapper_row">
					<div class="row_label">Contact Name</div>
					<div class="row_value">{{data?.shipmentDetail?.contactName ? data?.shipmentDetail?.contactName : '-'}}</div>
				</div>
			</div>
			<div class="col-md-12">
				<div class="con_wrapper_row">
					<div class="row_label">Special Req</div>
					<div class="row_value">{{data?.shipmentDetail?.specialRequirement ?
						data?.shipmentDetail?.specialRequirement : '-'}}</div>
				</div>
			</div>
		</div>
		<div *ngIf="data?.shipmentDetail && data?.shipmentDetail?.commodity.length > 0">
			<p class="fs_21 fsm_5 txt_b f_clash mb_24">Commodity</p>
			<div class="comdty_box" *ngFor="let newShipmentGoods of data?.shipmentDetail?.commodity">
				<div class="row">
					<div class="col-md-3">
						<div class="con_wrapper_row">
							<p class="row_label">Commodity</p>
							<p class="row_value">{{newShipmentGoods?.commodity ? newShipmentGoods?.commodity: '-'}}</p>
						</div>
					</div>
					<div class="col-md-3">
						<div class="con_wrapper_row">
							<p class="row_label">Packaging Type</p>
							<p class="row_value">{{newShipmentGoods?.packagingType ? newShipmentGoods?.packagingType :
								'-'}}</p>
						</div>
					</div>
					<div class="col-md-3">
						<div class="con_wrapper_row">
							<p class="row_label">Quantity</p>
							<p class="row_value">{{newShipmentGoods?.quantity ? newShipmentGoods?.quantity : '-'}}</p>
						</div>
					</div>
					<div class="col-md-3">
						<div class="con_wrapper_row">
							<p class="row_label">Dimension</p>
							<p class="row_value">{{newShipmentGoods?.dimension ? newShipmentGoods?.dimension : '-'}}</p>
						</div>
					</div>
					<div class="col-md-3">
						<div class="con_wrapper_row">
							<p class="row_label">Weight</p>
							<p class="row_value">{{newShipmentGoods?.weight ? newShipmentGoods?.weight : '-'}} <span
									*ngIf="newShipmentGoods?.weight">lbs</span></p>
						</div>
					</div>
					<div class="col-md-3">
						<div class="con_wrapper_row">
							<p class="row_label">$ Value</p>
							<p class="row_value">{{newShipmentGoods?.value ? newShipmentGoods?.value : '-'}}</p>
						</div>
					</div>
					<div class="col-md-3">
						<div class="con_wrapper_row">
							<p class="row_label"># SKU</p>
							<p class="row_value">{{newShipmentGoods.sku ? newShipmentGoods.sku : '-'}}</p>
						</div>
					</div>
					<div class="col-md-3">
						<div class="con_wrapper_row">
							<p class="row_label">Uploaded Image</p>
							<div class="row_value" *ngIf="newShipmentGoods?.media">
								<div class="img_vew">
									<img src="{{newShipmentGoods?.media}}" class="img-fluid">

									<a href="{{newShipmentGoods?.media}}" target="_blank" class="txt_p">View Image</a>
								</div>
							</div>
							<p class="row_value" *ngIf="!newShipmentGoods?.media">-</p>
						</div>
					</div>
					<div class="col-md-3">
						<div class="con_wrapper_row">
							<p class="row_label">Handle With Care</p>
							<p class="row_value">{{newShipmentGoods?.handleWithCare ? 'Yes' : 'No'}}</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

<div class="edit_Multi_Stop_form" *ngIf="data.openPop==='showLansShipmentDetail'">
	<div class="header_popup">
		<p class="fs_20 fsm_5 txt_b f_clash mb-0 line_before">{{data?.shipmentDetail?.type | titlecase}} Point
			{{data?.shipmentDetail?.locationOrder}}</p>
		<span class="close_popup_Icon" (click)="onClose()">
			<img src="{{serverPathForUrl}}/portal/icon/ic_close_white.svg" class="close_Icon">
		</span>
	</div>

	<div class="v_scrollBar popup_vh" style="max-height: 80vh; padding: 0 24px; margin: 0 -24px;">
		<div class="row">
			<div class="col-md-3">
				<div class="con_wrapper_row">
					<div class="row_label">Location</div>
					<div class="row_value"
						*ngIf="((userType=='SHIPPER') || (userType=='CARRIER' && data?.laneDetail?.status=='3' && data?.laneDetail?.bidReceived))">
						{{data?.shipmentDetail?.locationLongName}}</div>
					<span class="row_value"
						*ngIf="((userType=='CARRIER' && data?.laneDetail?.status=='2') || (userType=='CARRIER' && data?.laneDetail?.status=='3' && !data?.laneDetail?.bidReceived))">{{(data?.shipmentDetail?.city
						? data?.shipmentDetail?.city : data?.shipmentDetail?.state) +", "+data?.shipmentDetail?.state +", "+ data?.shipmentDetail?.countryName}}</span>
				</div>
			</div>
			<div class="col-md-3">
				<div class="con_wrapper_row">
					<div class="row_label">ZIP Code</div>
					<div class="row_value">{{data?.shipmentDetail?.zip}}</div>
				</div>
			</div>
		</div>
	</div>
</div>