<div class="wrapper">
  <div class="sidebar lg_sidebar" data-color="white" data-background-color="white" id="sidebar">
    <div class="sidebar-background"></div>
  </div>
  <div class="main-panel">
    <nav class="navbar lg_sidebar_hide" *ngIf="userAccessTokan && checkNavbar==='after_login'">
      <div class="container">
        <div class="mobile_view">
          <span class="head_logo">
            <img class="img-fluid"
              src="{{serverPathForUrl}}/portal/images/before-login/LA-logo-patant-min.png?v=1">
          </span>
          <div class="sign_outBtn" (click)="callToLogout()">
            <span class="menu_icon">
              <img src="{{serverPathForUrl}}/portal/images/after-login/cl-icons/ic-logout.svg"
                class="img-fluid">
            </span>
            <span class="fs_16 fsm_5 txt_b">Sign Out</span>
          </div>
        </div>
      </div>
    </nav>
    <div class="wapper_body container-fluid">
      <div class="row">
        <div class="wapper_body_l col-md-2 p-0"
          [ngClass]="hideLeftMenu && userAccessTokan && checkNavbar==='after_login' ? 'wapper_body_l_show' : 'wapper_body_l_hide'">
          <app-left-menu [hideLeftMenu]="hideLeftMenu"></app-left-menu>
        </div>
        <div class="wapper_body_r col-md-10 p-0">
          <div class="wapper_body_rt" *ngIf="userAccessTokan && checkNavbar==='after_login'">
            <app-top-navbar [hideLeftMenu]="hideLeftMenu"
              class="sm_navbar_hide"></app-top-navbar>
          </div>
          <div class="wapper_body_rb">
            <router-outlet></router-outlet>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
