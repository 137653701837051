// import { MapsAPILoader } from '@agm/core';
import {
  GermanAddress,
} from '@angular-material-extensions/google-maps-autocomplete';
import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import * as _ from 'lodash';
import * as moment from 'moment';
import { ImageCroppedEvent, LoadedImage, base64ToFile } from 'ngx-image-cropper';
import { environment } from '../../../environments/environment';
import { AlertService } from '../../commons/service/alert.service';
import { CommonService } from '../../commons/service/common.service';
import { SharedService } from '../../commons/service/shared.service';
import { AppSettings } from '../../commons/setting/app_setting';
import { StatusSetting } from '../../commons/setting/status_setting';
import PlaceResult = google.maps.places.PlaceResult;
declare var _: _.groupBy;

export interface DialogData {
  openPop: any;
  contain: any;
  title: any;
  buttontext: any;
  successMeg: any;
  status: any;
  driverName: any;
  driverId: any;
  name: any;
  expiredDate: any;
  date: any;
  documentUrl: any;
  documentId: any;
  id: any;
  documentType: any;
  userDetail: any;
  regionData:any;
  index:any;
  selectedRegion:any;
}

@Component({
  selector: 'app-user-popup',
  templateUrl: './user-popup.component.html',
  styleUrls: ['./user-popup.component.css'],
})

export class UserPopupComponent implements OnInit {
  public maxYear = moment().year();
  public disableDriver: boolean = true;
  public noteShow = false;
  public searchModel: any = {};
  public documentType = false;
  public phStates: any;
  public userType: any;
  public maStates: any;
  public imgURL: any;
  public storageLatitude: any;
  public storageLongitude: any;
  public imagmessage: any;
  public imagePath: any;
  public disbu: any;
  public name: any; 
  public expiryDate: any; 
  public certifcateVerify: FormGroup;
  public contractForm: FormGroup;
  public carrierContactInfo: FormGroup;
  public storageContactInfo: FormGroup;
  public addAutoNot: boolean;
  public mscDetail: FormGroup;
  public caBussinessServices: FormGroup;
  public caBussinessServicesIndustrie: FormGroup;
  public emailPattern = '^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$';
  public caBussiSerOther: FormGroup;
  public shiBussiSerOther: FormGroup;
  public workerCompensationForm: FormGroup;
  public generalLiabilityForm: FormGroup;
  public profileImageForm: FormGroup;
  public driverProfileImageForm: FormGroup;
  public signatureForm: FormGroup;
  public addChangeDetected = false;
  public selectedCountry: any;
  public selectedState: any;
  public countryList: any;
  public maCountryList:any;
  public pickupCountryName: any;
  public equipmentList: any = [];
  public fetchEquipment: any;
  public industriesList: any;
  public getRecord: any;
  public lanLocation: number = 1;
  public searchCtrl: any;
  public searchBlank: any;
  public fontList: any;
  public selectedFont: any;
  public searchCtrlValue: any;
  public addOption = false;
  public checkValueLane = true;
  public checkValueRegion = true;
  public loading = false;
  public minDate = new Date();
  public minDateBefore = new Date();
  public mailingSection = false;
  public addDocUpload: FormGroup;
  public editDocUpload: FormGroup;
  public documentSubmmited=false;
  public submitted = false;
  public checked: boolean = true;
  public signatureSkeleton = true;
  public telephone:any;
  public selectEquipmentValue = [];
  public page = 1;
  public columnSize: any;
  public serverPathForUrl: any;
  public latitude: number;
  public longitude: number;
  public address: string;
  public geoCoder;
  public shipperContract: any;
  public checkUserType = false;
  public searchRestriction: any;
  public countSpaceDbaName = 0;
  public countSpaceEin = 0;
  public checkBdaName;
  public checkEIN;
  public imgName: any;
  public imageChangedEvent: any = '';
  public croppedImage: any = '';
  public showCropper: boolean = false;
  public showImgName: boolean = false;
  public showProfileCropper: boolean = true;
  public showCoverCropper: boolean = true;
  public onlyCropper: boolean = false;
  public ifGotCountry: boolean = true;
  public defualtPickupCountryFlag:any;
  public selectRegionForm: FormGroup;
  public regionListlength: any;
  public regionListGroup: any;
  public regionsList:any = [];
  public statesList :any = [];
  public selectedStates=[];
  public selectedStatesList:any =[];
  public selectedRegiosList:any;
  public validWebsiteUrl = /^(?:https?:\/\/(?:www\.)?|https:(?:\/\/)?)?\w+(?:[-.]\w+)+(?:\/[^\/\s]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;
  public selectedCheckedEquipment: any = [];
  industryError: boolean = false;
  zipCodeErrorMsg: string;

  constructor(
    public dialogRef: MatDialogRef<UserPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public dialog: MatDialog,
    private formBuilder: FormBuilder,
    private commonService: CommonService,
    public alertService: AlertService,
    private datePipe: DatePipe,
    // private mapsAPILoader: MapsAPILoader,
    public sharedService: SharedService,
    private cd: ChangeDetectorRef,
  ) { }

  ngOnInit() {
    if (this.data.openPop === 'carrierContactInfo') {
      const body = document.getElementsByTagName('body')[0];
      body.classList.add('userProfile_sugBox');
    }
    this.userType = localStorage.getItem('user_type');
    this.serverPathForUrl = environment.serverPathForUrl;
    if (localStorage.getItem('user_type') === 'SHIPPER' || localStorage.getItem('user_type') === 'CARRIER') {
      this.searchRestriction = environment.searchMultipleRestriction;
    } else {
      this.searchRestriction = environment.searchRestriction;
    }

    this.shipperContract =
      'https://s3.us-east-2.amazonaws.com/staticdev.laneaxis.com/shipper/contract_document/laneAxisShipperCarrierContractTemplate.pdf';

    let configValue = this.sharedService.getConfig();
    if (configValue !== undefined) {
      this.getConfigSet(configValue);
    }

    this.commonService.configData.subscribe((userData) => {
      this.getConfigSet(userData);
    });
    if (this.data.openPop === 'signature') {
      this.signatureSkeleton = true;
    }
    // this.mapsAPILoader.load().then(() => {
    //   this.geoCoder = new google.maps.Geocoder();
    // });
    if(this.userType=='STORAGE'){
      this.selecteCountryFlag(this.data.userDetail?.countryCode ? this.data.userDetail?.countryCode : 'US')

    }
    this.getUserRecord();
    if (localStorage.getItem('user_type') === 'SHIPPER') {
      this.checkUserType = true;
    } else {
      this.checkUserType = false;
    }

    if (this.data.openPop == 'carrierContactInfo' || this.data.openPop == 'storageContactInfo') {
      this.getStates();
    }
    this.zipCodeErrorMsg = AppSettings.zipCodeError;
    this.fontList = StatusSetting.fontList;
    let days = 1;
    this.minDateBefore.setDate(this.minDateBefore.getDate() + days);

    this.contractForm = this.formBuilder.group({
      contractDocument: ['', [Validators.required]],
    });

    this.profileImageForm = this.formBuilder.group({
      profileImage: ['', [Validators.required]],
    });

    this.driverProfileImageForm = this.formBuilder.group({
      profileImage: ['', [Validators.required]],
    });

    this.signatureForm = this.formBuilder.group({
      name: [''],
      type: ['', [Validators.required]],
    });

    this.workerCompensationForm = this.formBuilder.group({
      workerCompensation: ['', [Validators.required]],
    });

    this.generalLiabilityForm = this.formBuilder.group({
      generalLiability: ['', [Validators.required]],
    });

    this.certifcateVerify = this.formBuilder.group({
      certificate: ['', [Validators.required]],
      insurance_expiry_date: ['', [Validators.required]],
      insuranceAmount: ['',[
          Validators.required,
          Validators.pattern(/^[0-9]{0,}$/),
          Validators.maxLength(10),
        ]
      ],
      insuranceCompany: ['', [Validators.required, Validators.maxLength(32)]],
      insuranceEmail: [
       '',
        [
          Validators.required,
          Validators.email,
          Validators.pattern(this.emailPattern),
          Validators.maxLength(128),
        ]
      ],
    });

    this.addDocUpload = this.formBuilder.group({
      document: ['', [Validators.required]],
      description: ['', [Validators.required]],
      shipmentId: [this.data.id, [Validators.required]],
    });
    if (this.data.title) {
      this.editDocUpload = this.formBuilder.group({
        description: [this.data.title.description, [Validators.required]],
      });
    }
    
    // Region form group
    this.selectRegionForm = this.formBuilder.group({
      region: ['',],
      state: ['',],
      
    });
    if(this.data.openPop==='regionAdd' || this.data.openPop==='regionEdit'){
      this.getApiRegions({});
    }

    this.carrierContactInfo = this.formBuilder.group({
      companyName: [
        '',
        [
          Validators.required,
          Validators.maxLength(64),
          Validators.pattern(/^([a-zA-Z0-9&-@]+\s?)*$/),
        ],
      ],
      dotNumber: ['', [Validators.pattern(/^-?([1-9]\d*)?$/)]],
      firstName: [
        '',
        [
          Validators.required,
          Validators.maxLength(64)
        ],
      ],
      lastName: [
        '',
        [
          Validators.required,
          Validators.maxLength(64)
        ],
      ],
      emailAddress: [''],
      telephone: [''],
      countryCode : ['US',[]],

      fax: ['', [Validators.maxLength(16)]],
      socialProfile: [
        '',
        this.checkUserType
          ? [
            Validators.pattern(
              '^(http://www.|https://www.|http://|https://)[a-z0-9]+([-.]{1}[a-z0-9]+)*.[a-z]{2,5}(:[0-9]{1,5})?(/.*)?$'
            ),
          ]
          : [],
      ],
      jobTitle: [
        '',
        this.checkUserType
          ? [
            Validators.required,
            Validators.maxLength(128),
            Validators.pattern(/^([a-zA-Z0-9]+\s?)*$/),
          ]
          : [],
      ],
      phyAddres1: [
        '',
        [
          Validators.required,
          Validators.maxLength(255)
         
        ],
      ],
      phyAddres2: [
        '',
        [
          Validators.maxLength(255)
        ]
      ],
      phyPostalCode: [
        '',
        [
          Validators.required,
          Validators.maxLength(8),
          Validators.minLength(4),
          Validators.pattern(/^[a-z0-9]+([-\s]{1}[a-z0-9]+)*$/i)
        ],
      ],
      phyCity: [
        '',
        [
          Validators.required,
          Validators.maxLength(32),
          // Validators.pattern(/^([a-zA-Z0-9]+\s?)*$/),
        ],
      ],
      phyState: ['', [Validators.required]],
      phyCountry: [''],
      mailAddres1: [
        '',
        [
          Validators.required,
          Validators.maxLength(255),
        ],
      ],
      mailAddres2: [
        '',
        [Validators.maxLength(255)],
      ],
      mailPostalCode: [
        '',
        [
          Validators.required,
          Validators.minLength(4),
          Validators.maxLength(8),
          Validators.pattern(/^[a-z0-9]+([-\s]{1}[a-z0-9]+)*$/i)
        ],
      ],
      mailCity: [
        '',
        [
          Validators.required,
          Validators.maxLength(32),
        ],
      ],
      mailState: ['', [Validators.required]],
      mailCountry: [''],
    });

    this.storageContactInfo = this.formBuilder.group({
      companyName: [
        '',
        [
          Validators.required,
          Validators.maxLength(64),
          Validators.pattern(/^([a-zA-Z0-9&-@]+\s?)*$/),
        ],
      ],
      firstName: [
        '',
        [
          Validators.required,
          Validators.maxLength(64),
          //Validators.pattern(/^([a-zA-Z]+\s?)*$/),
        ],
      ],
      lastName: [
        '',
        [
          Validators.required,
          Validators.maxLength(64),
          //Validators.pattern(/^([a-zA-Z]+\s?)*$/),
        ],
      ],
      emailAddress: [''],
      telephone: [''],
      countryCode : ['US',[]],

      fax: ['', [Validators.maxLength(16)]],
      socialProfile: [
        '', [
          Validators.pattern('^(http://www.|https://www.|http://|https://)[a-z0-9]+([-.]{1}[a-z0-9]+)*.[a-z]{2,5}(:[0-9]{1,5})?(/.*)?$'),
        ],
      ],
      companyWebUrl: [
        '', [Validators.pattern(this.validWebsiteUrl)],
      ],
      phyAddres1: [
        '',
        [
          Validators.required,
          Validators.maxLength(255)

          
        ],
      ],
      phyAddres2: [
        '',
        [
          Validators.maxLength(255)
        ]
      ],
      phyPostalCode: [
        '',
        [
          Validators.required,
          Validators.minLength(4),
          Validators.maxLength(8),
          Validators.pattern(/^[a-z0-9]+([-\s]{1}[a-z0-9]+)*$/i)
        ],
      ],
      phyCity: [
        '',
        [
          Validators.required,
          Validators.maxLength(32),

        ],
      ],
      phyState: ['', [Validators.required]],
      phyCountry: [''],
    });

    this.mscDetail = this.formBuilder.group({
      mcs150Mileage: ['', [Validators.maxLength(16), Validators.pattern(/^-?([1-9]\d*)?$/)]],
      mcs150MileageYear: ['', [Validators.pattern(/^-?([1-9]\d*)?$/)]],
      mcs150Date: [''],
    });

    this.caBussinessServices = this.formBuilder.group({
      carrierOperation: [''],
      truckLoadType: [''],
    });
    this.caBussinessServicesIndustrie = this.formBuilder.group({
      industries: [''],
      industry:['',[Validators.maxLength(64)]]
    })

    this.caBussiSerOther = this.formBuilder.group({
      truckTotal: ['', [Validators.pattern(/^-?([1-9]\d*)?$/)]],
      driverTotal: ['', [Validators.pattern(/^-?([1-9]\d*)?$/)]],
      hmFlag: [''],
      pcFlag: [''],
      dbaName: ['', [Validators.maxLength(64)]],
      einId: ['', [Validators.maxLength(16)]],
      socialProfile: [
        '',
        [
          Validators.pattern(
            '^(http://www.|https://www.|http://|https://)[a-z0-9]+([-.]{1}[a-z0-9]+)*.[a-z]{2,5}(:[0-9]{1,5})?(/.*)?$'
          ),
        ],
      ],
      companyWebUrl: [
        '', [Validators.pattern(this.validWebsiteUrl)],
      ],
    });

    this.shiBussiSerOther = this.formBuilder.group({
      companyEmail: [
        '',
        [
          Validators.required,
          Validators.email,
          Validators.maxLength(128),
          Validators.pattern(this.emailPattern),
        ],
      ],
      companyRevenue: ['', [Validators.pattern(/^-?([1-9]\d*)?$/)]],
      companyWebUrl: ['',[Validators.pattern(this.validWebsiteUrl)],
      ],
      socialProfile: [
        '',
        [
          Validators.pattern(
            '^(http://www.|https://www.|http://|https://)[a-z0-9]+([-.]{1}[a-z0-9]+)*.[a-z]{2,5}(:[0-9]{1,5})?(/.*)?$'
          ),
        ],
      ],
    });
    if (this.data.openPop === 'storageContactInfo') {
      this.getStoragedata();
      const body = document.getElementsByTagName('body')[0];
      body.classList.add('userProfile_sugBox');
    }
    if (this.data.openPop === 'signature' && this.userType === 'STORAGE') {
      this.getStoragedata();
      const body = document.getElementsByTagName('body')[0];
      body.classList.add('userProfile_sugBox');
    }
  }

  getConfigSet(configValue: any) {
    this.fetchEquipment = configValue.equipmentTypes;
    this.countryList = configValue.countries;
    this.maCountryList=configValue.countries;
  }

  phyAddress($event: GermanAddress) {
    if ($event.country.short == 'US') {
      this.phStates = StatusSetting.usState;
    }
    else if ($event.country.short == 'CA') {
      this.phStates = StatusSetting.caState;
    }
    else if ($event.country.short == 'MX') {
      this.phStates = StatusSetting.mxState;
    }
    this.carrierContactInfo.get('phyAddres1').setValue('');
    this.carrierContactInfo.get('phyAddres2').setValue('');
    this.carrierContactInfo.get('phyCity').setValue('');
    this.carrierContactInfo.get('phyState').setValue('');
    this.carrierContactInfo.get('phyPostalCode').setValue('');

    this.carrierContactInfo.get('phyAddres1').setValue($event.name);
    this.carrierContactInfo.get('phyAddres2').setValue($event.sublocality);
    this.carrierContactInfo.get('phyCity').setValue($event.locality.long);
    this.carrierContactInfo.get('phyState').setValue($event.state.long);
    if (this.carrierContactInfo.get('phyCountry').value) {
      this.carrierContactInfo.controls['phyCountry'].disable();
    }

    if ($event.country.short === 'US') {
      this.pickupCountryName = $event.country.short;
      this.selectedCountry = this.countryList.filter(
        (item) => item.country === $event.country.short);
        
      this.selectedState = this.phStates.filter((item) => item.sortname === $event.state.short);
    } else {
      this.selectedCountry = this.countryList.filter(
        (item) => item.country === $event.country.long);
      this.pickupCountryName = $event.country.long;
      this.selectedState = this.phStates.filter((item) => item.sortname === $event.state.short);
    }
    this.carrierContactInfo.get('phyCountry').setValue(this.selectedCountry[0].country);
    this.carrierContactInfo.get('phyState').setValue(this.selectedState[0].sortname);
    this.carrierContactInfo.get('phyPostalCode').setValue($event.postalCode);
    this.addAutoNot = false;
    this.addChangeDetected = false;
  }

  getStates(){
    this.commonService.getStateList().subscribe((serverRes) => {
      if (serverRes.success == true) {
       this.phStates = serverRes.response;
       this.maStates = serverRes.response;
      } else if (serverRes.success == false) {
        this.alertService.showNotificationMessage(
          'danger',
          'bottom',
          'right',
          'txt_d',
          'check_circle',
          'States',
          'Unexpected Error.'
        );
      }
    });
  }

  phyAddressStorage($event: GermanAddress) {
    this.storageContactInfo.get('phyAddres1').setValue('');
    this.storageContactInfo.get('phyAddres2').setValue('');
    this.storageContactInfo.get('phyCity').setValue('');
    this.storageContactInfo.get('phyState').setValue('');
    this.storageContactInfo.get('phyPostalCode').setValue('');
    this.storageLatitude = null;
    this.storageLongitude = null;
    this.storageContactInfo.get('phyAddres1').setValue($event.name);
    this.storageContactInfo.get('phyAddres2').setValue($event.sublocality);
    this.storageContactInfo.get('phyCity').setValue($event.locality.long);
    this.storageContactInfo.get('phyState').setValue($event.state.short);
    this.storageContactInfo.get('phyCountry').setValue('US');
    this.storageLatitude = $event.geoLocation.latitude;
    this.storageLongitude = $event.geoLocation.longitude;
    this.storageContactInfo.get('phyPostalCode').setValue($event.postalCode);
    this.addAutoNot = false;
    this.addChangeDetected = false;
  }

  keyPress(event: any) {
    const pattern = /[0-9\+\-\ ]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode !== 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  keyPressOnlyNumber(event: any) {
    const pattern = /^[0-9]*$/;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode !== 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  keyPasteDriverTotal(event: any) {
    const pattern = /^[0-9]*$/;
    if (!pattern.test(event.target.value)) {
      return this.caBussiSerOther.get('driverTotal').setValue('');
    }
  }

  keyPressOnlyNumberTrucks(event: any) {
    const pattern = /^[0-9]*$/;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode !== 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  keyPressOnlyNumberRevenue(event: any) {
    const pattern = /^[0-9]*$/;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode !== 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  keyPasteTrucks(event: any) {
    const pattern = /^[0-9]*$/;
    if (!pattern.test(event.target.value)) {
      return this.shiBussiSerOther.get('truckTotal').setValue('');
    }
  }

  closePopup() {
    this.commonService.userData.next(null);
    this.dialogRef.close({ event: 'close' });
    const body = document.getElementsByTagName('body')[0];
    body.classList.remove('userProfile_sugBox');
  }

  donePopup() {
    this.dialogRef.close({ event: 'OK' });
  }

  selectFont(font: any) {
    this.selectedFont = font;
    this.signatureForm.get('type').setValue(font);
  }

  generateFake(count: number): Array<number> {
    const indexes = [];
    for (let i = 0; i < count; i++) {
      indexes.push(i);
    }
    return indexes;
  }

  getUserRecord() {
    let uri = null;
    let newparams = {};
    let userType = null;
    if (localStorage.getItem('user_type') === 'CARRIER') {
      userType = AppSettings.APIsNameArray.CARRIER.GET;
    } else {
      userType = AppSettings.APIsNameArray.SHIPPER.GET;
    }
    if (newparams) uri = this.commonService.getAPIUriFromParams(newparams);
    let APIparams = {
      apiKey: userType,
      uri: uri,
    };
    this.commonService.getList(APIparams).subscribe(
      (ServerRes) => {
        if (ServerRes.success === true) {
          this.getRecord = ServerRes.response;
          this.sharedService.setAddress(ServerRes.response);
          this.commonService.userData.next(ServerRes.response);
          this.selecteCountryFlag(ServerRes.response.countryCode ? ServerRes.response.countryCode : 'US')
          if (this.data.openPop === 'signature') {
            this.signatureSkeleton = false;
          }
          if (ServerRes.response.equipmentType) {
            this.selectEquipmentValue = ServerRes.response.equipmentType;
          }
          if (
            this.data.openPop === 'carrierBussinessServices' ||
            this.data.openPop === 'shipperBussinessServices'
          ) {
           
            this.alredySelectetEquipment();
            this.getIndusData(null);
          }

          if (ServerRes.response.phyCity && ServerRes.response.phyState) {
            if (
              ServerRes.response.phyStreet ===
              ServerRes.response.mailingStreet &&
              ServerRes.response.phyAddressLine2 ===
              ServerRes.response.mailingAddressLine2 &&
              ServerRes.response.phyZipShort ===
              ServerRes.response.mailingZipShort &&
              ServerRes.response.phyCity === ServerRes.response.mailingCity &&
              ServerRes.response.phyState === ServerRes.response.mailingState
            ) {
              this.checked = true;
              this.mailingSection = true;
            } else {
              this.checked = false;
              this.mailingSection = false;
            }
          } else {
            this.checked = false;
            this.mailingSection = false;
          }
          let companyName;
          if (ServerRes.response && ServerRes.response.legalName) {
            companyName = ServerRes.response.legalName;
          } else {
            companyName = ServerRes.response.companyName;
          }

          this.carrierContactInfo = this.formBuilder.group({
            companyName: [
              companyName,
              [
                Validators.required,
                Validators.pattern(/^([a-zA-Z0-9&-@]+\s?)*$/),
                Validators.maxLength(64),

              ],
            ],
            dotNumber: [
              ServerRes.response.dotNumber,
              [Validators.pattern(/^-?([1-9]\d*)?$/)],
            ],
            firstName: [
              ServerRes.response.firstName,
              [
                Validators.required,
                Validators.maxLength(64),
              ],
            ],
            lastName: [
              ServerRes.response.lastName,
              [
                Validators.required,
                Validators.maxLength(64),
              ],
            ],
            emailAddress: [
              ServerRes.response.emailAddress
                ? ServerRes.response.emailAddress
                : ServerRes.response.email,
            ],
            telephone: [
              this.telephone
                ? this.telephone
                : ServerRes.response.phone,
            ],
            countryCode : [ServerRes.response.countryCode ? ServerRes.response.countryCode :'US',[]],
            fax: [ServerRes.response.fax, [Validators.maxLength(16)]],
            socialProfile: [
              ServerRes.response.contactPersonLinkedin,
              this.checkUserType
                ? [
                  Validators.pattern(
                    '^(http://www.|https://www.|http://|https://)[a-z0-9]+([-.]{1}[a-z0-9]+)*.[a-z]{2,5}(:[0-9]{1,5})?(/.*)?$'
                  ),
                ]
                : [],
            ],
            jobTitle: [
              ServerRes.response.jobTitle,
              this.checkUserType
                ? [
                  Validators.required,
                  Validators.maxLength(128),
                  Validators.pattern(/^([a-zA-Z0-9]+\s?)*$/),
                ]
                : [],
            ],
            phyAddres1: [
              ServerRes.response.phyStreet
                ? ServerRes.response.phyStreet
                : ServerRes.response.phyAddressLine1,
              [
                Validators.required,
              ],
            ],
            phyAddres2: [
              ServerRes.response.phyAddressLine2,
            ],
            phyPostalCode: [
              ServerRes.response.phyZipShort
                ? ServerRes.response.phyZipShort
                : ServerRes.response.phyZip,
              [
                Validators.required,
                Validators.minLength(4),
                Validators.maxLength(8),
                Validators.pattern(/^[a-z0-9]+([-\s]{1}[a-z0-9]+)*$/i)
              ],
            ],
            phyCity: [
              ServerRes.response.phyCity,
              [
                Validators.required,
                Validators.maxLength(32),
              ],
            ],
            phyState: [ServerRes.response.phyState, [Validators.required]],
            phyCountry: [
              ServerRes.response.phyCountry
                ? ServerRes.response.phyCountry
                : 'US',
            ],
            mailAddres1: [
              ServerRes.response.mailingStreet
                ? ServerRes.response.mailingStreet
                : ServerRes.response.mailingAddressLine1,
              [
                Validators.required,
                Validators.maxLength(255),
              ],
            ],
            mailAddres2: [
              ServerRes.response.mailingAddressLine2,
              [
                Validators.maxLength(255),
              ],
            ],
            mailPostalCode: [
              ServerRes.response.mailingZipShort
                ? ServerRes.response.mailingZipShort
                : ServerRes.response.mailingZip,
              [
                Validators.required,
                Validators.minLength(4),
                Validators.maxLength(8),
                Validators.pattern(/^[a-z0-9]+([-\s]{1}[a-z0-9]+)*$/i)
              ],
            ],
            mailCity: [
              ServerRes.response.mailingCity,
              [
                Validators.required,
                Validators.maxLength(32),
              ],
            ],
            mailState: [ServerRes.response.mailingState, [Validators.required]],
            mailCountry: [
              ServerRes.response.mailingCountry
                ? ServerRes.response.mailingCountry
                : 'US',
            ],
          });

          this.minDate = ServerRes.response.mcs150Date;
          this.mscDetail = this.formBuilder.group({
            mcs150Mileage: [
              ServerRes.response.mcs150Mileage,
              [Validators.pattern(/^-?([1-9]\d*)?$/),Validators.maxLength(16)],
            ],
            mcs150MileageYear: [
              ServerRes.response.mcs150MileageYear,
              [
                Validators.pattern(/^-?([1-9]\d*)?$/),
                Validators.max(this.maxYear),
              ],
            ],
            mcs150Date: [ServerRes.response.mcs150Date],
          });

          this.searchCtrl = ServerRes.response.usSic1987Description;
          this.caBussinessServices = this.formBuilder.group({
            carrierOperation: [ServerRes.response.carrierOperation],
            truckLoadType: [
              ServerRes.response.truckLoadType
                ? ServerRes.response.truckLoadType.id
                : '',
            ],
          });
          this.caBussinessServicesIndustrie = this.formBuilder.group({
            industries: [''],
            industry: [ ServerRes.response.industry,
            [Validators.maxLength(64)]]
          })

          let showLane;
          let laneSelect;
          if (ServerRes.response.lane === 1) {
            showLane = '2';
            laneSelect = '1';
            this.lanLocation = 2;
            this.checkValueLane = false;
          } else {
            showLane = '1';
            laneSelect = '0';
            this.lanLocation = 1;
            this.checkValueLane = true;
          }
          this.searchCtrl = ServerRes.response.usSic1987Description;

          let totalDriver;
          if (
            ServerRes.response.driverTotal === 'Owner Operator' ||
            ServerRes.response.driverTotal === 'Owner operator'
          ) {
            totalDriver = 1;
          } else {
            totalDriver = ServerRes.response.driverTotal;
          }
          this.caBussiSerOther = this.formBuilder.group({
            hmFlag: [ServerRes.response.hmFlag],
            pcFlag: [ServerRes.response.pcFlag],
            dbaName: [ServerRes.response.dbaName, [Validators.maxLength(64)]],
            einId: [ServerRes.response.einId, [Validators.maxLength(16)]],
            socialProfile: [
              ServerRes.response.linkedin,
              [
                Validators.pattern(
                  '^(http://www.|https://www.|http://|https://)[a-z0-9]+([-.]{1}[a-z0-9]+)*.[a-z]{2,5}(:[0-9]{1,5})?(/.*)?$'
                ),
              ],
            ],
            companyWebUrl: [
              ServerRes.response.websiteUrl,
               [Validators.pattern(this.validWebsiteUrl)],
            ],
            truckTotal: [
              ServerRes.response.truckTotal,
              [Validators.pattern(/^-?([1-9]\d*)?$/)],
            ],
            driverTotal: [totalDriver, [Validators.pattern(/^-?([1-9]\d*)?$/)]],
          });
          if (ServerRes.response.dbaName) {
            this.checkBdaName = ServerRes.response.dbaName;
          }
          if (ServerRes.response.einId) {
            this.checkEIN = ServerRes.response.einId;
          }

          let million = '';
          if (ServerRes.response.revenueUsd) {
            million = this.convertMilionBillon(ServerRes.response.revenueUsd);
          }

          this.shiBussiSerOther = this.formBuilder.group({
            companyEmail: [
              ServerRes.response.companyEmail,
              [
                Validators.required,
                Validators.email,
                Validators.maxLength(128),
                Validators.pattern(this.emailPattern),
              ],
            ],
            companyRevenue: [ServerRes.response.revenueUsd, [Validators.pattern(/^-?([1-9]\d*)?$/)]],
            companyWebUrl: [
              ServerRes.response.websiteUrl,[Validators.pattern(this.validWebsiteUrl)],
            ],
            socialProfile: [
              ServerRes.response.linkedin,
              [
                Validators.pattern(
                  '^(http://www.|https://www.|http://|https://)[a-z0-9]+([-.]{1}[a-z0-9]+)*.[a-z]{2,5}(:[0-9]{1,5})?(/.*)?$'
                ),
              ],
            ],
          });
          let name;
          if (ServerRes.response.firstName || ServerRes.response.lastName) {
            if (ServerRes.response.lastName) {
              name =
                ServerRes.response.firstName +
                ' ' +
                ServerRes.response.lastName;
            } else {
              name = ServerRes.response.firstName;
            }
            if (name && name.length > 12) {
              this.columnSize = 12;
            } else {
              this.columnSize = 6;
            }
          }

          this.signatureForm = this.formBuilder.group({
            name: [name, [Validators.required]],
            type: ['', [Validators.required]],
          });
        }
      },
      (error) => { }
    );
  }

  convertMilionBillon(revenueUsd: any) {
    let million
    if (revenueUsd >= 1000) {
        million = (revenueUsd / 1000)
        million =Number(million.toString().match(/^\d+(?:\.\d{0,2})?/)) + 'B';
    } else if (revenueUsd != null) {
        million = revenueUsd + 'M'
    } else {
        million = null
    }
    return million

  }

  alredySelectetEquipment() {
    for (let v = 0; v < this.fetchEquipment.length; v++) {
      if (this.getRecord && this.getRecord.equipmentType !== null) {
        let foundMatch = false; // Flag to track if a match is found
        for (let j = 0; j < this.getRecord.equipmentType.length; j++) {
          if (this.getRecord.equipmentType[j] == this.fetchEquipment[v].label) {
          
            this.fetchEquipment[v].isChecked = 'checked';
            foundMatch = true; // Set flag to true when match is found
            break;
          }
        }
        // If no match was found, set isChecked to 'unchecked'
        if (!foundMatch) {
          this.fetchEquipment[v].isChecked = 'unchecked';
        }
      } else {
        this.fetchEquipment[v].isChecked = 'unchecked';
      }
    }
    this.equipmentList = this.fetchEquipment;
    this.cd.detectChanges();
  }

  selectEquipmentType(id: any, checked: any) {
    for (let v = 0; v < this.equipmentList.length; v++) {
      if (
        this.equipmentList[v].id === id &&
        this.equipmentList[v].isChecked === 'unchecked'
      ) {
        this.equipmentList[v].isChecked = 'checked';
        this.selectEquipmentValue.push(this.equipmentList[v].label);
      } else if (
        this.equipmentList[v].id === id &&
        this.equipmentList[v].isChecked === 'checked' &&
        this.selectEquipmentValue.length > 1
      ) {
        this.equipmentList[v].isChecked = 'unchecked';
        this.selectEquipmentValue.pop();
      }
    }
  }
  
  searchIndustry(event: any) {
    let searchStr = event.target.value;
    if (searchStr.length > 1) {
      this.getIndusData(searchStr);
    } else {
      this.getIndusData(null);
    }
  }

  clearIndurties() {
    if (this.getRecord.industries) {
    } else {
      this.searchCtrl = null;
    }
    this.searchBlank = null;
    this.getIndusData(null);
  }

  getAPIInIndParam(searchStr) {
    let APIparams, params;
    if (searchStr) params = { limit: 10, page: this.page, search: searchStr };
    else params = { limit: 10, page: this.page };
    APIparams = {
      apiKey: AppSettings.APIsNameArray.OTHER.SICCODE,
      uri: this.commonService.getAPIUriFromParams(params),
    };
    return APIparams;
  }

  getIndusData(searchStr) {
    this.page = 1;
    var APIparams = this.getAPIInIndParam(searchStr);
    this.commonService.getList(APIparams).subscribe((ServerRes) => {
      if (
        ServerRes.response.allSicCode &&
        ServerRes.response.allSicCode.length > 0
      ) {
        this.industriesList = _.groupBy(
          ServerRes.response.allSicCode,
          'section'
        );
      } else {
        this.industriesList = [];
      }
    });
  }

  checkIndustOption(event: any) {
    this.searchCtrl = event.value.value;
    this.searchCtrlValue = event.value;
  }

  /*Update carrier business information */
  caBussiSerSave({ value, valid }) {
    this.submitted = true;
    if (valid) {
      const formData = new FormData();
      this.loading = true;
      let carrierOperation;
      let TruckLoadType;
      if (this.userType === 'CARRIER') {
        if (value.carrierOperation === 'Interstate') {
          carrierOperation = '1';
        } else if (value.carrierOperation === 'Intrastate Hazmat') {
          carrierOperation = '2';
        } else {
          carrierOperation = '3';
        }
        formData.append('carrierOperation', carrierOperation);
      }    
      
      if (value.truckLoadType) {
        formData.append('truckLoadType', value.truckLoadType);
      }
      
      for (let v = 0; v < this.equipmentList.length; v++) {
        if (this.equipmentList[v].isChecked === "checked") {
          this.selectedCheckedEquipment.push(this.equipmentList[v].slug);
        }
      }
      formData.append("equipmentType", JSON.stringify(this.selectedCheckedEquipment));
    
      let urlType = null;
      let methodType = null;
      if (localStorage.getItem('user_type') === 'CARRIER') {
        urlType = AppSettings.APIsNameArray.CARRIER.UPDATE;
      } else {
        urlType = AppSettings.APIsNameArray.SHIPPER.UPDATE;
      }

      let APIparams = {
        apiKey: urlType,
        uri: '',
        postBody: formData,
      };
      if (localStorage.getItem('user_type') === 'CARRIER') {
        methodType = this.commonService.post(APIparams);
      } else {
        methodType = this.commonService.put(APIparams);
      }

      methodType.subscribe(
        (success) => {
          if (success.success === true) {
            this.loading = false;
            let truckloadName;
            if (value.truckLoadType == 1) {
              truckloadName = 'Full Truckload (FTL)';
            } else if (value.truckLoadType == 2) {
              truckloadName = 'Less Than Truckload (LTL)';
            } else if (value.truckLoadType == 3) {
              truckloadName = 'Both (FTL & LTL)';
            }

            var dimensions = null;
            if (value.truckLoadType) {
              dimensions = 'truckloadType=' + truckloadName;
            }
            if (value.carrierOperation) {
              const revenue = 'Operation=' + value.carrierOperation;
              dimensions = dimensions ? dimensions + '&' + revenue : revenue;
            }
            var obj = {
              eventName: 'update_business_services',
              event_category: 'Profile',
              userType: localStorage.getItem('user_type'),
              EmailID: localStorage.getItem('email'),
              Dimensions: dimensions ? dimensions : '-',
              EquipmentType: this.selectedCheckedEquipment ? this.selectedCheckedEquipment: '-',
            };
            this.sharedService.track(obj);
            this.dialogRef.close({ event: 'Ok' });
            this.alertService.showNotificationMessage(
              'success',
              'bottom',
              'right',
              'txt_s',
              'check_circle',
              'User Business',
              'You have successfully updated user business information.'
            );
          } else if (success.success === false) {
            this.loading = false;
            this.alertService.showNotificationMessage(
              'danger',
              'bottom',
              'right',
              'txt_d',
              'check_circle',
              'User Business',
              'You have not successfully updated user business information.'
            );
          }
        },
        (error) => {
          this.alertService.showNotificationMessage(
            'danger',
            'bottom',
            'right',
            'txt_g',
            'error',
            'Unexpected Error',
            AppSettings.ERROR
          );
          this.loading = false;
        }
      );
    }
  }

  caBussiSerSaveIndustrie({ value, valid }){
    this.submitted = true;
    if ((this.caBussinessServicesIndustrie.get('industry').value == null || this.caBussinessServicesIndustrie.get('industry').value == '' ) && this.userType != 'CARRIER') {
      this.industryError = true;
    } else {
      this.industryError = false;
    }
    if (valid && this.industryError == false) {
      const formData = new FormData();
      this.loading = true;
      let industries;
      let industry;
      let usSic1987Description;
      if (this.searchCtrlValue) {
        if (this.searchCtrlValue.id !== undefined) {
          industries = this.searchCtrlValue.id;
          usSic1987Description = this.searchCtrlValue.value;

        } else {
          industries = this.getRecord.industries;
          usSic1987Description = this.getRecord.usSic1987Description;
        }
      } else {
        if (
          this.getRecord.industries !== null &&
          this.getRecord.usSic1987Description !== null
        ) {
          industries = this.getRecord.industries;
          usSic1987Description = this.getRecord.usSic1987Description;

        } else {
          industries = '';
          usSic1987Description = '';

        }
      }
      if (
        this.caBussinessServicesIndustrie.get('industry').value === null ||
        this.caBussinessServicesIndustrie.get('industry').value === undefined
      ) {
        industry = '';
      } else {
        industry = this.caBussinessServicesIndustrie.get('industry').value;
      }

      formData.append('industries', industries);
      formData.append('industry', industry);
      formData.append('usSic1987Description', usSic1987Description);

      let urlType = null;
      let methodType = null;
      if (localStorage.getItem('user_type') === 'CARRIER') {
        urlType = AppSettings.APIsNameArray.CARRIER.UPDATE;
      } else {
        urlType = AppSettings.APIsNameArray.SHIPPER.UPDATE;
      }
      let APIparams = {
        apiKey: urlType,
        uri: '',
        postBody: formData,
      };
      if (localStorage.getItem('user_type') === 'CARRIER') {
        methodType = this.commonService.post(APIparams);
      } else {
        methodType = this.commonService.put(APIparams);
      }

      methodType.subscribe(
        (success) => {
          if (success.success === true) {
            this.loading = false;
            
            var dimensions = null;
            if (usSic1987Description) {
              dimensions = 'Industry=' + usSic1987Description;
            }
            var obj = {
              eventName: 'update_business_services',
              event_category: 'Profile',
              userType: localStorage.getItem('user_type'),
              EmailID: localStorage.getItem('email'),
              Dimensions: dimensions ? dimensions : '-',
              EquipmentType: this.selectedCheckedEquipment ? this.selectedCheckedEquipment: '-',
            };
            this.sharedService.track(obj);
            this.dialogRef.close({ event: 'Ok' });
            this.alertService.showNotificationMessage(
              'success',
              'bottom',
              'right',
              'txt_s',
              'check_circle',
              'User Business',
              'You have successfully updated user business information.'
            );
          } else if (success.success === false) {
            this.loading = false;
            this.alertService.showNotificationMessage(
              'danger',
              'bottom',
              'right',
              'txt_d',
              'check_circle',
              'User Business',
              'You have not successfully updated user business information.'
            );
          }
        },
        (error) => {
          this.alertService.showNotificationMessage(
            'danger',
            'bottom',
            'right',
            'txt_g',
            'error',
            'Unexpected Error',
            AppSettings.ERROR
          );
          this.loading = false;
        }
      );
    }
  }

  checkSpacedbaName(word: any) {
    let lastword = word.charAt(word.length - 1);
    if (lastword === ' ') {
      this.countSpaceDbaName = this.countSpaceDbaName + 1;
    } else {
      this.countSpaceDbaName = 0;
    }
    if (
      this.countSpaceDbaName >= 2 ||
      (lastword === ' ' && word.length === 1)
    ) {
      this.checkBdaName = this.checkBdaName.substring(
        0,
        this.checkBdaName.length - 1
      );
    }
  }

  checkSpaceEin(word: any) {
    let lastword = word.charAt(word.length - 1);
    if (lastword === ' ') {
      this.countSpaceEin = this.countSpaceEin + 1;
    } else {
      this.countSpaceEin = 0;
    }
    if (this.countSpaceEin >= 2 || (lastword === ' ' && word.length === 1)) {
      this.checkEIN = this.checkEIN.substring(0, this.checkEIN.length - 1);
    }
  }

  /*Update carrier business information */
  caBussiSerOtherSave({ value, valid }) {
    if (valid) {
      const formData = new FormData();
      let hmFlag;
      let pcFlag;
      let dbaName;
      let einId;
      let truckTotal;
      let driverTotal;
      let companyWebUrl;
      if (this.caBussiSerOther.get('hmFlag').value === 'Yes') {
        hmFlag = 1;
      } else {
        hmFlag = 0;
      }
      if (this.caBussiSerOther.get('pcFlag').value === 'Yes') {
        pcFlag = 1;
      } else {
        pcFlag = 0;
      }
      if (
        this.caBussiSerOther.get('dbaName').value === null ||
        this.caBussiSerOther.get('dbaName').value === undefined
      ) {
        dbaName = '';
      } else {
        dbaName = this.caBussiSerOther.get('dbaName').value;
      }

      if (
        this.caBussiSerOther.get('einId').value === null ||
        this.caBussiSerOther.get('einId').value === undefined
      ) {
        einId = '';
      } else {
        einId = this.caBussiSerOther.get('einId').value;
      }

      if (
        this.caBussiSerOther.get('truckTotal').value === null ||
        this.caBussiSerOther.get('truckTotal').value === undefined
      ) {
        truckTotal = '';
      } else {
        truckTotal = this.caBussiSerOther.get('truckTotal').value;
      }
      if (
        this.caBussiSerOther.get('driverTotal').value === null ||
        this.caBussiSerOther.get('driverTotal').value === undefined
      ) {
        driverTotal = '';
      } else {
        driverTotal = this.caBussiSerOther.get('driverTotal').value;
      }
      let socialProfile;
      if (!this.caBussiSerOther.get('socialProfile').value) {
        socialProfile = '';
      } else {
        socialProfile = this.caBussiSerOther.get('socialProfile').value;
      }

      if (this.caBussiSerOther.get('companyWebUrl').value === null || this.caBussiSerOther.get('companyWebUrl').value === undefined) 
      {
        companyWebUrl = '';
      } else {
        companyWebUrl = this.caBussiSerOther.get('companyWebUrl').value;
      }

      formData.append('hmFlag', hmFlag);
      formData.append('pcFlag', pcFlag);
      formData.append('dbaName', dbaName);
      formData.append('einId', einId);
      formData.append('truckTotal', truckTotal);
      formData.append('driverTotal', driverTotal);
      formData.append('linkedin', socialProfile);
      formData.append('websiteUrl', companyWebUrl);

      this.loading = true;
      let APIparams = {
        apiKey: AppSettings.APIsNameArray.CARRIER.UPDATE,
        uri: '',
        postBody: formData,
      };
      this.commonService.post(APIparams).subscribe(
        (success) => {
          if (success.success === true) {
            this.loading = false;
            var dimensions = null;
            if (value.driverTotal) {
              dimensions = 'numberOfDriver=' + driverTotal;
            }
            if (value.truckTotal) {
              const trucks = 'numberOfTrucks=' + value.truckTotal;
              dimensions = dimensions ? dimensions + '&' + trucks : trucks;
            }
            if (value.dbaName) {
              const dbaName = 'dbaName=' + value.dbaName;
              dimensions = dimensions ? dimensions + '&' + dbaName : dbaName;
            }
            if (value.einId) {
              const einId = 'einId=' + value.einId;
              dimensions = dimensions ? dimensions + '&' + einId : einId;
            }
            if (value.hmFlag) {
              const Hazmat = 'Hazmat=' + value.hmFlag;
              dimensions = dimensions ? dimensions + '&' + Hazmat : Hazmat;
            } 
            if (value.pcFlag) {
              const passengerCarrierUnit = 'passengerCarrierUnit=' + value.pcFlag;
              dimensions = dimensions ? dimensions + '&' + passengerCarrierUnit : passengerCarrierUnit;
            }
            if (value.socialProfile) {
              const socialProfile = 'socialProfile=' + value.socialProfile;
              dimensions = dimensions ? dimensions + '&' + socialProfile : socialProfile;
            }
            if (value.companyWebUrl) {
              const companyWebUrl = 'companyWebUrl=' + value.companyWebUrl;
              dimensions = dimensions ? dimensions + '&' + companyWebUrl : companyWebUrl;
            }
            var obj = {
              eventName: 'update_business_services',
              event_category: 'Profile',
              userType: localStorage.getItem('user_type'),
              EmailID: localStorage.getItem('email'),
              Dimensions: dimensions ? dimensions : '-',
              EquipmentType: this.selectedCheckedEquipment ? this.selectedCheckedEquipment: '-',
            };
            this.sharedService.track(obj);
            this.dialogRef.close({ event: 'Ok' });
            this.alertService.showNotificationMessage(
              'success',
              'bottom',
              'right',
              'txt_s',
              'check_circle',
              'User Business',
              'You have successfully updated carrier business information.'
            );
          } else if (success.success === false) {
            this.loading = false;
            this.alertService.showNotificationMessage(
              'danger',
              'bottom',
              'right',
              'txt_d',
              'check_circle',
              'User Business',
              'You have not successfully updated carrier business information.'
            );
          }
        },
        (error) => {
          this.alertService.showNotificationMessage(
            'danger',
            'bottom',
            'right',
            'txt_g',
            'error',
            'Unexpected Error',
            AppSettings.ERROR
          );
          this.loading = false;
        }
      );
    }
  }

  /*Update carrier business information */
  shiBussiSerOtherSave({ value, valid }) {
    if (valid) {
      const formData = new FormData();
      let companyEmail;
      let companyRevenue;
      let companyWebUrl;
      let socialProfile;
      if (
        this.shiBussiSerOther.get('companyEmail').value === null ||
        this.shiBussiSerOther.get('companyEmail').value === undefined
      ) {
        companyEmail = '';
      } else {
        companyEmail = this.shiBussiSerOther.get('companyEmail').value;
      }

      if (
        this.shiBussiSerOther.get('companyRevenue').value === null ||
        this.shiBussiSerOther.get('companyRevenue').value === undefined
      ) {
        companyRevenue = '';
      } else {
        companyRevenue = this.shiBussiSerOther.get('companyRevenue').value;
      }

      if (
        this.shiBussiSerOther.get('companyWebUrl').value === null ||
        this.shiBussiSerOther.get('companyWebUrl').value === undefined
      ) {
        companyWebUrl = '';
      } else {
        companyWebUrl = this.shiBussiSerOther.get('companyWebUrl').value;
      }

      if (!this.shiBussiSerOther.get('socialProfile').value) {
        socialProfile = '';
      } else {
        socialProfile = this.shiBussiSerOther.get('socialProfile').value;
      }

      formData.append('companyEmail', companyEmail);
      formData.append('revenueUsd', companyRevenue);
      formData.append('websiteUrl', companyWebUrl);
      formData.append('linkedin', socialProfile);

      this.loading = true;
      let APIparams = {
        apiKey: AppSettings.APIsNameArray.SHIPPER.UPDATE,
        uri: '',
        postBody: formData,
      };
      this.commonService.put(APIparams).subscribe(
        (success) => {
          if (success.success === true) {
            this.loading = false;
            var dimensions = null;
            if (value.companyRevenue) {
              dimensions = 'companyRevenue=' + value.companyRevenue;
            }
            if (value.companyEmail) {
              const companyEmail = 'companyEmail=' + value.companyEmail;
              dimensions = dimensions ? dimensions + '&' + companyEmail : companyEmail;
            }
            if (value.companyWebUrl) {
              const companyWebUrl = 'companyWebUrl=' + value.companyWebUrl;
              dimensions = dimensions ? dimensions + '&' + companyWebUrl : companyWebUrl;
            }
            if (value.socialProfile) {
              const socialProfile = 'socialProfile=' + value.socialProfile;
              dimensions = dimensions ? dimensions + '&' + socialProfile : socialProfile;
            }
            var obj = {
              eventName: 'update_business_services',
              event_category: 'Profile',
              userType: localStorage.getItem('user_type'),
              EmailID: localStorage.getItem('email'),
              Dimensions: dimensions ? dimensions : '-',
              EquipmentType: this.selectedCheckedEquipment ? this.selectedCheckedEquipment: '-',
            };
            this.sharedService.track(obj);
            this.dialogRef.close({ event: 'Ok' });
            this.alertService.showNotificationMessage(
              'success',
              'bottom',
              'right',
              'txt_s',
              'check_circle',
              'User Business',
              'You have successfully updated user business information.'
            );
          } else if (success.success === false) {
            this.loading = false;
            this.alertService.showNotificationMessage(
              'danger',
              'bottom',
              'right',
              'txt_d',
              'check_circle',
              'User Business',
              'You have not successfully updated user business information.'
            );
          }
        },
        (error) => {
          this.alertService.showNotificationMessage(
            'danger',
            'bottom',
            'right',
            'txt_g',
            'error',
            'Unexpected Error',
            AppSettings.ERROR
          );
          this.loading = false;
        }
      );
    }
  }

  /*upload user profile of insurance*/
  profileImageSave({ value, valid }) {
    if (valid) {
      this.loading = true;
      const formData = new FormData();
      if (this.data.openPop === 'profileImage') {
        formData.append(
          'profileImage',
          this.profileImageForm.get('profileImage').value
        );
      }
      if (this.data.openPop === 'coverImage') {
        formData.append(
          'coverImage',
          this.profileImageForm.get('profileImage').value
        );
        formData.append('mediaType', 'COVER_IMAGE');
      }
      if (this.userType === 'STORAGE') {
        formData.append('companyPhone', this.data.userDetail.phone);
      }

      let urlType = null;
      let methodType = null;
      if (localStorage.getItem('user_type') === 'CARRIER') {
        urlType = AppSettings.APIsNameArray.CARRIER.UPDATE;
      } else if (localStorage.getItem('user_type') === 'SHIPPER') {
        urlType = AppSettings.APIsNameArray.SHIPPER.UPDATE;
      } else if (localStorage.getItem('user_type') === 'STORAGE') {
        urlType = AppSettings.APIsNameArray.COLDSTORAGE.UPDATE;
      }

      let APIparams = {
        apiKey: urlType,
        uri: '',
        postBody: formData,
      };
      if (localStorage.getItem('user_type') === 'CARRIER') {
        methodType = this.commonService.post(APIparams);
      } else {
        methodType = this.commonService.put(APIparams);
      }

      methodType.subscribe(
        (success) => {
          if (success.success === true) {

            this.commonService.profileUpdate.next(null);

            this.loading = false;
            this.dialogRef.close({ event: 'Ok'});
            if (success.response.profileImage !== null) {
              localStorage.setItem(
                'userProfileImage',
                success.response.profileImage
              );
            }
          } else if (success.success === false) {
            this.loading = false;
          }
        },
        (error) => {
          this.alertService.showNotificationMessage(
            'danger',
            'bottom',
            'right',
            'txt_g',
            'error',
            'Unexpected Error',
            AppSettings.ERROR
          );
          this.loading = false;
        }
      );
    }
  }

   /*upload driver profile image*/
   driverProfileImageSave({ value, valid }) {
    if (valid) {
      this.loading = true;
      const formData = new FormData();
      formData.append('profileImage', this.driverProfileImageForm.get('profileImage').value);
      formData.append('driverId', this.data.driverId);
      formData.append('name', this.data.driverName);

      let urlType = null;
      let methodType = null;
      if (localStorage.getItem('user_type') === 'CARRIER') {
        urlType = AppSettings.APIsNameArray.DRIVER.UPDATE;
      } 
      let APIparams = {
        apiKey: urlType,
        uri: '',
        postBody: formData,
      };

      methodType = this.commonService.put(APIparams);
      methodType.subscribe(
        (success) => {
          if (success.success === true) {
            this.loading = false;
            this.dialogRef.close({ event: 'Ok'});
          }
        },
        (error) => {
          this.alertService.showNotificationMessage(
            'danger',
            'bottom',
            'right',
            'txt_g',
            'error',
            'Unexpected Error',
            AppSettings.ERROR
          );
          this.loading = false;
        }
      );
    }
  }


  /*Contract document uploda*/
  onSelectedContract(event) {
    if (event.target.files.length === 0) return;
    let mimeType = event.target.files[0].type;
    if (mimeType.match(/pdf\/*/) === null) {
      this.imagmessage = 'Only supported pdf file.';
      this.imgURL = '';
      return;
    }
    var reader = new FileReader();
    this.name = event.target.files[0].name;
    this.disbu = false;
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = (_event) => {
      this.imgURL = reader.result;
    };
    if (event.target.files.length) {
      const file = event.target.files[0];
      this.contractForm.get('contractDocument').setValue(file);
    }
  }

  /*upload shipper multiple documents*/
  uploadDocumentMulti({ value, valid }) {
    if (valid) {
      this.documentSubmmited=true;
      this.loading = true;
      const formData = new FormData();
      formData.append('document', this.addDocUpload.get('document').value);
      formData.append(
        'description',
        this.addDocUpload.get('description').value
      );
      formData.append('shipmentId', this.data.id);
      let APIparams = {
        apiKey: AppSettings.APIsNameArray.SHIPMENT.SHIPPERDOC,
        uri: '',
        postBody: formData,
      };
      this.commonService.postMediaMethod(APIparams).subscribe(
        (success) => {
          this.documentSubmmited=false;
          if (success.success === true) {
            this.alertService.showNotificationMessage(
              'success',
              'bottom',
              'right',
              'txt_s',
              'check_circle',
              'Shipper Document',
              'You have successfully uploaded a document.'
            );
            this.loading = false;
            this.dialogRef.close({ event: 'Ok' });
          } else if (success.success === false) {
            this.loading = false;
            this.alertService.showNotificationMessage(
              'danger',
              'bottom',
              'right',
              'txt_d',
              'check_circle',
              'Shipper Document',
              'You have not successfully uploaded a document.'
            );
          }
        },
        (error) => {
          this.documentSubmmited=false;
          this.alertService.showNotificationMessage(
            'danger',
            'bottom',
            'right',
            'txt_g',
            'error',
            'Unexpected Error',
            AppSettings.ERROR
          );
        }
      );
    }
  }

  /*upload certificate of insurance*/
  contractUploadDocument({ value, valid }) {
    if (valid) {
      this.loading = true;
      const formData = new FormData();
      formData.append(
        'mediaContractDoc',
        this.contractForm.get('contractDocument').value
      );
      formData.append('mediaType', 'SHIPPER_CONTRACT_DOCUMENT');
      let APIparams = {
        apiKey: AppSettings.APIsNameArray.SHIPPER.UPDATE,
        uri: '',
        postBody: formData,
      };
      this.commonService.put(APIparams).subscribe(
        (success) => {
          if (success.success === true) {
            localStorage.setItem('is_contract', '1');
            this.alertService.showNotificationMessage(
              'success',
              'bottom',
              'right',
              'txt_s',
              'check_circle',
              'Contract Document',
              'You have successfully uploaded a contract document.'
            );
            this.loading = false;
            this.dialogRef.close({ event: 'Ok' });
          } else if (success.success === false) {
            this.loading = false;
            this.alertService.showNotificationMessage(
              'danger',
              'bottom',
              'right',
              'txt_d',
              'check_circle',
              'Contract Document',
              'You have error while uploading a contract document.'
            );
          }
        },
        (error) => {
          this.alertService.showNotificationMessage(
            'danger',
            'bottom',
            'right',
            'txt_g',
            'error',
            'Unexpected Error',
            AppSettings.ERROR
          );
          this.loading = false;
        }
      );
    }
  }

  // certifcate insurace
  onSelectedFileCertifcate(event) {
    if (event.target.files.length === 0) return;
    var mimeType = event.target.files[0].type;
    var reader = new FileReader();
    this.imagePath = event.target.files;
    this.name = event.target.files[0].name;
    this.disbu = false;
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = (_event) => {
      this.imgURL = reader.result;
    };
    this.imagmessage = '';
    if (event.target.files.length) {
      const file = event.target.files[0];
      this.certifcateVerify.get('certificate').setValue(file);
    }
  }

  /*Upload certificate of insurance*/
  certifcateUpload({ value, valid }) {
    if (valid) {
      this.loading = true;
      const formData = new FormData();
       this.expiryDate = this.datePipe.transform(
        new Date(value.insurance_expiry_date),
        'MM/dd/yyyy'
      );
      formData.append(
        'mediaCoi',
        this.certifcateVerify.get('certificate').value
      );
      formData.append('coiExpiryDate', this.expiryDate);
      formData.append(
        "insuranceAmount",
        this.certifcateVerify.get("insuranceAmount").value
      );
      formData.append(
        "insuranceCompanyName",
        this.certifcateVerify.get("insuranceCompany").value
      );
      formData.append(
        "insuranceCompanyEmail",
        this.certifcateVerify.get("insuranceEmail").value
      );
      let APIparams = {
        apiKey: AppSettings.APIsNameArray.CARRIER.UPDATE,
        uri: '',
        postBody: formData,
      };
      this.commonService.post(APIparams).subscribe(
        (success) => {
          if (success.success === true) {
             this.getUserRecord();
            this.loading = false;
            var dimensions = null;
            if (this.expiryDate) {
              dimensions = 'expiryDate=' + this.expiryDate;
            } 
            if (this.certifcateVerify.get("insuranceAmount").value) {
              const insuranceAmount = 'insuranceAmount=' + this.certifcateVerify.get("insuranceAmount").value;
              dimensions = dimensions ? dimensions + '&' + insuranceAmount : insuranceAmount;
            }
            if (this.certifcateVerify.get("insuranceCompany").value) {
              const insuranceCompanyName = 'insuranceCompanyName=' + this.certifcateVerify.get("insuranceCompany").value;
              dimensions = dimensions ? dimensions + '&' + insuranceCompanyName : insuranceCompanyName;
            }
            if (this.certifcateVerify.get("insuranceEmail").value) {
              const insuranceCompanyEmail = 'insuranceCompanyEmail=' + this.certifcateVerify.get("insuranceEmail").value;
              dimensions = dimensions ? dimensions + '&' + insuranceCompanyEmail : insuranceCompanyEmail;
            }
            var obj = {
              eventName: 'update_doc_COI',
              event_category: 'Profile',
              userType: localStorage.getItem('user_type'),
              EmailID: localStorage.getItem('email'),
              Dimensions: dimensions ? dimensions : '-',
            };
            this.sharedService.track(obj);
            this.dialogRef.close({ event: 'Ok' });
          } else if (success.success === false) {
            this.loading = false;
            this.alertService.showNotificationMessage(
              'danger',
              'bottom',
              'right',
              'txt_d',
              'check_circle',
              'Certificate insurance',
              'You have not successfully uploaded your document.'
            );
          }
        },
        (error) => {
          this.alertService.showNotificationMessage(
            'danger',
            'bottom',
            'right',
            'txt_g',
            'error',
            'Unexpected Error',
            AppSettings.ERROR
          );
          this.loading = false;
        }
      );
    }
  }

  // worker compenstion
  onSelectedWorkerCompensation(event) {
    if (event.target.files.length === 0) return;
    var mimeType = event.target.files[0].type;
    var reader = new FileReader();
    this.imagePath = event.target.files;
    this.name = event.target.files[0].name;
    this.disbu = false;
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = (_event) => {
      this.imgURL = reader.result;
    };
    this.imagmessage = '';
    if (event.target.files.length) {
      const file = event.target.files[0];
      this.workerCompensationForm.get('workerCompensation').setValue(file);
    }
  }

  /*upload worker compensation*/
  workerCompensationSave({ value, valid }) {
    if (valid) {
      this.loading = true;
      const formData = new FormData();
      formData.append(
        'mediaCompensationDoc',
        this.workerCompensationForm.get('workerCompensation').value
      );
      formData.append('mediaType', 'COMPENSATION_DOCUMENT');
      let APIparams = {
        apiKey: AppSettings.APIsNameArray.CARRIER.UPDATE,
        uri: '',
        postBody: formData,
      };
      this.commonService.post(APIparams).subscribe(
        (success) => {
          if (success.success === true) {
            var obj = {
              eventName: 'update_doc_workersCompensation',
              event_category: 'Profile',
              userType: localStorage.getItem('user_type'),
              EmailID: localStorage.getItem('email'),
            };
            this.sharedService.track(obj);
            this.alertService.showNotificationMessage(
              'success',
              'bottom',
              'right',
              'txt_s',
              'check_circle',
              'Worker Compensation',
              'You have successfully uploaded a worker compensation document.'
            );
            this.loading = false;
            this.dialogRef.close({ event: 'Ok' });
          } else if (success.success === false) {
            this.loading = false;
            this.alertService.showNotificationMessage(
              'danger',
              'bottom',
              'right',
              'txt_d',
              'check_circle',
              'Worker Compensation',
              'You have error while uploading a worker compensation document.'
            );
          }
        },
        (error) => {
          this.alertService.showNotificationMessage(
            'danger',
            'bottom',
            'right',
            'txt_g',
            'error',
            'Unexpected Error',
            AppSettings.ERROR
          );
          this.loading = false;
        }
      );
    }
  }

  onNoConfirmClick() {
    this.dialogRef.close({ event: 'fail' });
  }

  // worker compenstion
  onSelectedFileGeneral(event) {
    if (event.target.files.length === 0) return;
    var mimeType = event.target.files[0].type;
    var reader = new FileReader();
    this.imagePath = event.target.files;
    this.name = event.target.files[0].name;
    this.disbu = false;
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = (_event) => {
      this.imgURL = reader.result;
    };
    this.imagmessage = '';
    if (event.target.files.length) {
      const file = event.target.files[0];
      this.generalLiabilityForm.get('generalLiability').setValue(file);
    }
  }

  onSelectedFile(event) {
    if (event.target.files.length === 0) return;
    var reader = new FileReader();
    this.name = event.target.files[0].name;
    this.disbu = false;
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = (_event) => {
      this.imgURL = reader.result;
    };
    if (event.target.files.length) {
      const file = event.target.files[0];
      this.addDocUpload.get('document').setValue(file);
    }
  }

  /*upload worker compensation*/
  generalLiabilitySave({ value, valid }) {
    if (valid) {
      this.loading = true;
      const formData = new FormData();
      formData.append(
        'mediaLiabilityDoc',
        this.generalLiabilityForm.get('generalLiability').value
      );
      formData.append('mediaType', 'LIABILITY_DOCUMENT');
      let APIparams = {
        apiKey: AppSettings.APIsNameArray.CARRIER.UPDATE,
        uri: '',
        postBody: formData,
      };
      this.commonService.post(APIparams).subscribe(
        (success) => {
          if (success.success === true) {
            var obj = {
              eventName: 'update_doc_general_liablity',
              event_category: 'Profile',
              userType: localStorage.getItem('user_type'),
              EmailID: localStorage.getItem('email'),
            };
            this.sharedService.track(obj);
            this.alertService.showNotificationMessage(
              'success',
              'bottom',
              'right',
              'txt_s',
              'check_circle',
              'General Liability',
              'You have successfully uploaded a general liability document.'
            );
            this.loading = false;
            this.dialogRef.close({ event: 'Ok' });
          } else if (success.success === false) {
            this.loading = false;
            this.alertService.showNotificationMessage(
              'danger',
              'bottom',
              'right',
              'txt_d',
              'check_circle',
              'General Liability',
              'You have error while uploading a general liability.'
            );
          }
        },
        (error) => {
          this.alertService.showNotificationMessage(
            'danger',
            'bottom',
            'right',
            'txt_g',
            'error',
            'Unexpected Error',
            AppSettings.ERROR
          );
          this.loading = false;
        }
      );
    }
  }

  //Address check value
  setAddress(event) {
    if (event.checked) {
      this.carrierContactInfo.get('mailAddres1').setValue(this.carrierContactInfo.get('phyAddres1').value);
      this.carrierContactInfo.get('mailAddres2').setValue(this.carrierContactInfo.get('phyAddres2').value);
      this.carrierContactInfo.get('mailPostalCode').setValue(this.carrierContactInfo.get('phyPostalCode').value);
      this.carrierContactInfo.get('mailCity').setValue(this.carrierContactInfo.get('phyCity').value);
      this.carrierContactInfo.get('mailState').setValue(this.carrierContactInfo.get('phyState').value);
      this.carrierContactInfo.get('mailCountry').setValue(this.carrierContactInfo.get('phyCountry').value);
      this.mailingSection = true;
    } else {
      this.carrierContactInfo.get('mailAddres1');
      this.carrierContactInfo.get('mailAddres2');
      this.carrierContactInfo.get('mailPostalCode');
      this.carrierContactInfo.get('mailCity');
      this.carrierContactInfo.get('mailState');
      this.carrierContactInfo.get('mailCountry');
      this.mailingSection = false;
    }
  }

  pickAutoCompleteSelected(result: PlaceResult) {
    for (let i = 0; i < result.address_components.length; i++) {
      if (result.address_components[i].types[0] == "postal_code") {
        if (result.address_components[i].long_name) {
          this.carrierContactInfo.get('phyPostalCode').setValue(result.address_components[i].long_name);
        } else {
          this.carrierContactInfo.get('phyPostalCode').setValue('');
        }
      }
      if (result.address_components[i].types[0] == "postal_code_prefix") {
        if (result.address_components[i].long_name) {
          this.carrierContactInfo.get('phyPostalCode').setValue(result.address_components[i].long_name);
        } else {
          this.carrierContactInfo.get('phyPostalCode').setValue('');
        }
      }
    }
  }

  carrerInfoSave({ value, valid }) {
    this.submitted = true;
    if (!this.addAutoNot && valid) {
      this.loading = true;
      const formData = new FormData();
      if (this.mailingSection === true) {
        this.carrierContactInfo
          .get('mailAddres1')
          .setValue(this.carrierContactInfo.get('phyAddres1').value);
        this.carrierContactInfo
          .get('mailAddres2')
          .setValue(this.carrierContactInfo.get('phyAddres2').value);
        this.carrierContactInfo
          .get('mailPostalCode')
          .setValue(this.carrierContactInfo.get('phyPostalCode').value);
        this.carrierContactInfo
          .get('mailCity')
          .setValue(this.carrierContactInfo.get('phyCity').value);
        this.carrierContactInfo
          .get('mailState')
          .setValue(this.carrierContactInfo.get('phyState').value);
        this.carrierContactInfo
          .get('mailCountry')
          .setValue(this.carrierContactInfo.get('phyCountry').value);
      } else {
        this.carrierContactInfo.get('mailAddres1').value;
        this.carrierContactInfo.get('mailAddres2').value;
        this.carrierContactInfo.get('mailPostalCode').value;
        this.carrierContactInfo.get('mailCity').value;
        this.carrierContactInfo.get('mailState').value;
        this.carrierContactInfo.get('mailCountry').value;
      }
      let phyAdd1;
      let phyAdd2;
      let phyZip;
      let phyCity,
        phyState,
        phyCountry,
        mailAdd1,
        mailAdd2,
        mailZip,
        mailCity,
        mailState,
        mailCountry,
        fax,
        jobTitle,
        socialProfile;

      if (
        this.carrierContactInfo.get('phyAddres1').value === null ||
        this.carrierContactInfo.get('phyAddres1').value === undefined
      ) {
        phyAdd1 = '';
      } else {
        phyAdd1 = this.carrierContactInfo.get('phyAddres1').value;
      }
      if (
        this.carrierContactInfo.get('phyAddres2').value === null ||
        this.carrierContactInfo.get('phyAddres2').value === undefined
      ) {
        phyAdd2 = '';
      } else {
        phyAdd2 = this.carrierContactInfo.get('phyAddres2').value;
      }
      if (
        this.carrierContactInfo.get('phyPostalCode').value === null ||
        this.carrierContactInfo.get('phyPostalCode').value === undefined
      ) {
        phyZip = '';
      } else {
        phyZip = this.carrierContactInfo.get('phyPostalCode').value;
      }
      if (
        this.carrierContactInfo.get('phyCity').value === null ||
        this.carrierContactInfo.get('phyCity').value === undefined
      ) {
        phyCity = '';
      } else {
        phyCity = this.carrierContactInfo.get('phyCity').value;
      }
      if (
        this.carrierContactInfo.get('phyState').value === 'null' ||
        this.carrierContactInfo.get('phyState').value === null ||
        this.carrierContactInfo.get('phyState').value === undefined
      ) {
        phyState = '';
      } else {
        phyState = this.carrierContactInfo.get('phyState').value;
      }
      if (
        this.carrierContactInfo.get('phyCountry').value === 'null' ||
        this.carrierContactInfo.get('phyCountry').value === null ||
        this.carrierContactInfo.get('phyCountry').value === undefined
      ) {
        phyCountry = '';
      } else {
        phyCountry = this.carrierContactInfo.get('phyCountry').value;
      }

      if (
        this.carrierContactInfo.get('mailAddres1').value === null ||
        this.carrierContactInfo.get('mailAddres1').value === undefined
      ) {
        mailAdd1 = '';
      } else {
        mailAdd1 = this.carrierContactInfo.get('mailAddres1').value;
      }
      if (
        this.carrierContactInfo.get('mailAddres2').value === null ||
        this.carrierContactInfo.get('mailAddres2').value === undefined
      ) {
        mailAdd2 = '';
      } else {
        mailAdd2 = this.carrierContactInfo.get('mailAddres2').value;
      }

      if (
        this.carrierContactInfo.get('mailPostalCode').value === null ||
        this.carrierContactInfo.get('mailPostalCode').value === undefined
      ) {
        mailZip = '';
      } else {
        mailZip = this.carrierContactInfo.get('mailPostalCode').value;
      }

      if (
        this.carrierContactInfo.get('mailCity').value === null ||
        this.carrierContactInfo.get('mailCity').value === undefined
      ) {
        mailCity = '';
      } else {
        mailCity = this.carrierContactInfo.get('mailCity').value;
      }
      if (
        this.carrierContactInfo.get('mailState').value === 'null' ||
        this.carrierContactInfo.get('mailState').value === null ||
        this.carrierContactInfo.get('mailState').value === undefined
      ) {
        mailState = '';
      } else {
        mailState = this.carrierContactInfo.get('mailState').value;
      }
      if (
        this.carrierContactInfo.get('mailCountry').value === 'null' ||
        this.carrierContactInfo.get('mailCountry').value === null ||
        this.carrierContactInfo.get('mailCountry').value === undefined
      ) {
        mailCountry = '';
      } else {
        mailCountry = this.carrierContactInfo.get('mailCountry').value;
      }

      if (
        this.carrierContactInfo.get('fax').value === null ||
        this.carrierContactInfo.get('fax').value === undefined
      ) {
        fax = '';
      } else {
        fax = this.carrierContactInfo.get('fax').value;
      }
      if (
        this.carrierContactInfo.get('jobTitle').value === null ||
        this.carrierContactInfo.get('jobTitle').value === undefined
      ) {
        jobTitle = '';
      } else {
        jobTitle = this.carrierContactInfo.get('jobTitle').value;
      }

      if (!this.carrierContactInfo.get('socialProfile').value) {
        socialProfile = '';
      } else {
        socialProfile = this.carrierContactInfo.get('socialProfile').value;
      }

      if (this.userType === 'CARRIER') {
        formData.append(
          'legalName',
          this.carrierContactInfo.get('companyName').value
        );
        formData.append(
          'dotNumber',
          this.carrierContactInfo.get('dotNumber').value
        );
        formData.append(
          'countryCode',
          this.carrierContactInfo.get('countryCode').value
        );
        formData.append('fax', fax);
        formData.append('phyStreet', phyAdd1);
        formData.append('phyZipShort', phyZip);
        formData.append('mailingStreet', mailAdd1);
        formData.append('mailingZipShort', mailZip);
      }
      if (this.userType === 'SHIPPER') {
        formData.append(
          'companyName',
          this.carrierContactInfo.get('companyName').value
        );
       
        formData.append('title', this.carrierContactInfo.get('jobTitle').value);
        formData.append('phyAddressLine1', phyAdd1);
        formData.append('phyZip', phyZip);
        formData.append('mailingAddressLine1', mailAdd1);
        formData.append('mailingZip', mailZip);
        formData.append('contactPersonLinkedin', socialProfile);
      }

      formData.append(
        'firstName',
        this.carrierContactInfo.get('firstName').value
      );
      formData.append(
        'lastName',
        this.carrierContactInfo.get('lastName').value
      );
      formData.append('phyAddressLine2', phyAdd2);
      formData.append('phyCity', phyCity);
      formData.append('phyState', phyState);
      formData.append('phyCountry', phyCountry);
      formData.append('mailingAddressLine2', mailAdd2);
      formData.append('mailingCity', mailCity);
      formData.append('mailingState', mailState);
      formData.append('mailingCountry', mailCountry);
      let urlType;
      let methodType;
      if (localStorage.getItem('user_type') === 'CARRIER') {
        urlType = AppSettings.APIsNameArray.CARRIER.UPDATE;
      } else {
        urlType = AppSettings.APIsNameArray.SHIPPER.UPDATE;
      }

      let APIparams = {
        apiKey: urlType,
        uri: '',
        postBody: formData,
      };
      if (localStorage.getItem('user_type') === 'CARRIER') {
        methodType = this.commonService.post(APIparams);
      } else {
        methodType = this.commonService.put(APIparams);
      }

      methodType.subscribe(
        (success) => {
          this.loading = false;
          if (success.success === true) {
            var dimensions = null;
            if (this.carrierContactInfo.get('companyName').value) {
              dimensions = 'companyName=' + this.carrierContactInfo.get('companyName').value;
            }
            if (this.carrierContactInfo.get('dotNumber').value) {
              const dotNumber = 'dotNumber=' + this.carrierContactInfo.get('dotNumber').value;
              dimensions = dimensions ? dimensions + '&' + dotNumber : dotNumber;
            }
            if (this.carrierContactInfo.get('firstName').value) {
              const firstName = 'firstName=' + this.carrierContactInfo.get('firstName').value;
              dimensions = dimensions ? dimensions + '&' + firstName : firstName;
            }
            if (this.carrierContactInfo.get('lastName').value) {
              const lastName = 'lastName=' + this.carrierContactInfo.get('lastName').value;
              dimensions = dimensions ? dimensions + '&' + lastName : lastName;
            }
            if (this.carrierContactInfo.get('fax').value) {
              const fax = 'fax=' + this.carrierContactInfo.get('fax').value;
              dimensions = dimensions ? dimensions + '&' + fax : fax;
            }
            if (this.carrierContactInfo.get('phyAddres1').value) {
              const physicalAdress = 'physicalAdress=' + this.carrierContactInfo.get('phyAddres1').value;
              dimensions = dimensions ? dimensions + '&' + physicalAdress : physicalAdress;
            }
            if (this.carrierContactInfo.get('phyPostalCode').value) {
              const physicalZipCode = 'physicalZipCode=' + this.carrierContactInfo.get('phyPostalCode').value;
              dimensions = dimensions ? dimensions + '&' + physicalZipCode : physicalZipCode;
            }
            if (this.carrierContactInfo.get('phyCity').value) {
              const physicalCity = 'physicalCity=' + this.carrierContactInfo.get('phyCity').value;
              dimensions = dimensions ? dimensions + '&' + physicalCity : physicalCity;
            }
            if (this.carrierContactInfo.get('phyState').value) { 
              const physicalState = 'physicalState=' + this.carrierContactInfo.get('phyState').value;
              dimensions = dimensions ? dimensions + '&' + physicalState : physicalState;
            }
            if (this.carrierContactInfo.get('phyCountry').value) {
              const physicalCountry = 'physicalCountry=' + this.carrierContactInfo.get('phyCountry').value;
              dimensions = dimensions ? dimensions + '&' + physicalCountry : physicalCountry;
            }
            if (this.carrierContactInfo.get('jobTitle').value) {
              const jobTitle = 'jobTitle=' + this.carrierContactInfo.get('jobTitle').value;
              dimensions = dimensions ? dimensions + '&' + jobTitle : jobTitle;
            }
            var obj = {
              eventName: 'update_contact_info',
              event_category: 'Profile',
              userType: localStorage.getItem('user_type'),
              EmailID: localStorage.getItem('email'),
              Dimensions: dimensions ? dimensions : '-',
              EquipmentType: this.selectedCheckedEquipment ? this.selectedCheckedEquipment: '-',
            };
            this.sharedService.track(obj);
            const body = document.getElementsByTagName('body')[0];
            body.classList.remove('userProfile_sugBox');
            this.commonService.profileUpdate.next(null);
            this.alertService.showNotificationMessage(
              'success',
              'bottom',
              'right',
              'txt_s',
              'check_circle',
              'User Info',
              'You have successfully updated user detail'
            );
            this.loading = false;
            this.dialogRef.close({ event: 'Ok' });
          } else if (success.success === false) {
            this.alertService.showNotificationMessage(
              'danger',
              'bottom',
              'right',
              'txt_s',
              'check_circle',
              'User Info',
              'You have successfully updated user detail'
            );
            this.loading = false;
          }
        },
        (error) => {
          this.loading = false;
          this.alertService.showNotificationMessage(
            'danger',
            'bottom',
            'right',
            'txt_g',
            'error',
            'Unexpected Error',
            AppSettings.ERROR
          );
        }
      );
    }
  }

  // Msc Detail
  addMsc({ value, valid }) {
    if (valid) {
      this.loading = true;
      const formData = new FormData();
      let mcs150MileageYear;
      let mcs150Mileage;
      let mcs150Dated;
      const mcs150Date = this.mscDetail.get('mcs150Date').value;
      if (
        mcs150Date !== 'Invalid Da' &&
        mcs150Date !== '' &&
        typeof mcs150Date !== undefined
      ) {
        mcs150Dated = this.datePipe.transform(
          new Date(mcs150Date),
          'MM/dd/yyyy'
        );
      } else {
        mcs150Dated = '';
      }
      if (
        this.mscDetail.get('mcs150MileageYear').value === null ||
        this.mscDetail.get('mcs150MileageYear').value === undefined
      ) {
        mcs150MileageYear = '';
      } else {
        mcs150MileageYear = this.mscDetail.get('mcs150MileageYear').value;
      }
      if (
        this.mscDetail.get('mcs150Mileage').value === null ||
        this.mscDetail.get('mcs150Mileage').value === undefined
      ) {
        mcs150Mileage = '';
      } else {
        mcs150Mileage = this.mscDetail.get('mcs150Mileage').value;
      }

      formData.append('mcs150Date', mcs150Dated);
      formData.append('mcs150MileageYear', mcs150MileageYear);
      formData.append('mcs150Mileage', mcs150Mileage);

      let APIparams = {
        apiKey: AppSettings.APIsNameArray.CARRIER.UPDATE,
        uri: '',
        postBody: formData,
      };
      this.commonService.post(APIparams).subscribe(
        (success) => {
          if (success.success === true) {
            var dimensions = null;
            if (mcs150Dated) {
              dimensions = 'mcsDate=' + mcs150Dated;
            }
            if (mcs150MileageYear) {
              const mcsMilegeYear = 'mcsMilegeYear=' + mcs150MileageYear;
              dimensions = dimensions ? dimensions + '&' + mcsMilegeYear : mcsMilegeYear;
            }
            if (mcs150Mileage) {
              const mcsMileage = 'mcsMilege=' + mcs150Mileage;
              dimensions = dimensions ? dimensions + '&' + mcsMileage : mcsMileage;
            }
            var obj = {
              eventName: 'update_mcs_info',
              event_category: 'Profile',
              userType: localStorage.getItem('user_type'),
              EmailID: localStorage.getItem('email'),
              Dimensions: dimensions ? dimensions : '-',
              EquipmentType: this.selectedCheckedEquipment ? this.selectedCheckedEquipment: '-',
            };
            this.sharedService.track(obj);
            this.alertService.showNotificationMessage(
              'success',
              'bottom',
              'right',
              'txt_s',
              'check_circle',
              'MCS Detail',
              'You have successfully updated the MCS detail.'
            );
            this.loading = false;
            this.dialogRef.close({ event: 'Ok' });
          } else if (success.success === false) {
            this.loading = false;
            this.alertService.showNotificationMessage(
              'danger',
              'bottom',
              'right',
              'txt_s',
              'check_circle',
              'MCS Detail',
              'You have not successfully a current MCS detail.'
            );
          }
        },
        (error) => {
          this.loading = false;
          this.alertService.showNotificationMessage(
            'danger',
            'bottom',
            'right',
            'txt_g',
            'error',
            'Unexpected Error',
            AppSettings.ERROR
          );
        }
      );
    }
  }

  // Msc Detail
  signatureSave({ value, valid }) {
    this.submitted = true;
    if (valid) {
      this.loading = true;
      const formData = new FormData();
      let urlType;
      let methodType;
      if (localStorage.getItem('user_type') === 'CARRIER') {
        urlType = AppSettings.APIsNameArray.CARRIER.UPDATE;
      } else if (localStorage.getItem('user_type') === 'SHIPPER') {
        urlType = AppSettings.APIsNameArray.SHIPPER.UPDATE;
      } else if (localStorage.getItem('user_type') === 'STORAGE') {
        urlType = AppSettings.APIsNameArray.COLDSTORAGE.UPDATE;
      }
      let APIparams = {
        apiKey: urlType,
        uri: '',
        postBody: formData,
      };
      if (localStorage.getItem('user_type') === 'CARRIER') {
        methodType = this.commonService.post(APIparams);
      } else {
        methodType = this.commonService.put(APIparams);
      }

      formData.append('signatureFont', this.signatureForm.get('type').value);
      methodType.subscribe(
        (success) => {
          if (success.success === true) {
            this.alertService.showNotificationMessage(
              'success',
              'bottom',
              'right',
              'txt_s',
              'check_circle',
              'Signature',
              'You have successfully updated the signature'
            );
            this.loading = false;
            this.dialogRef.close({ event: 'Ok' });
          } else if (success.success === false) {
            this.loading = false;
            this.alertService.showNotificationMessage(
              'danger',
              'bottom',
              'right',
              'txt_s',
              'check_circle',
              'Signature',
              'Signature updation is failed'
            );
          }
        },
        (error) => {
          this.loading = false;
          this.alertService.showNotificationMessage(
            'danger',
            'bottom',
            'right',
            'txt_g',
            'error',
            'Unexpected Error',
            AppSettings.ERROR
          );
        }
      );
    }
  }

  // Connection
  addNote() {
    this.noteShow = !this.noteShow;
    this.searchModel.message=null;
  }

  sendInvite() {
    this.dialogRef.close({ message: this.searchModel.message, event: 'Ok' });
  }

  /*Contract and Certificate Insurace*/
  acceptDoc() {
    this.loading = true;
    let postBody = null;
    if (localStorage.getItem('user_type') === 'CARRIER') {
      postBody = { shipperId: this.data.id, contractId: this.data.documentId };
    }
    if (localStorage.getItem('user_type') === 'SHIPPER') {
      postBody = { carrierId: this.data.id, contractId: this.data.documentId };
    }

    let APIparams = {
      apiKey: AppSettings.APIsNameArray.DOCUMENT.ACCEPT,
      uri: '',
      postBody: postBody,
    };
    this.commonService.post(APIparams).subscribe(
      (success) => {
        if (success.success === true) {
          this.documentType = true;
          this.loading = false;
        } else if (success.success === false) {
          this.loading = false;
          this.documentType = false;
          this.alertService.showNotificationMessage(
            'danger',
            'bottom',
            'right',
            'txt_d',
            'check_circle',
            'Accept to Upload new Document',
            'Some reseaon document is not accepted.'
          );
        }
      },
      (error) => {
        this.documentType = false;
        this.alertService.showNotificationMessage(
          'danger',
          'bottom',
          'right',
          'txt_g',
          'error',
          'Unexpected Error',
          AppSettings.ERROR
        );
        this.loading = false;
      }
    );
  }

  /*Contract*/
  acceptContractDocument() {
    this.loading = true;
    let postBody = null;
    let APIparams = {
      apiKey: AppSettings.APIsNameArray.DOCUMENT.SHIPPERCONTRACT,
      uri: '',
      postBody: postBody,
    };
    this.commonService.put(APIparams).subscribe(
      (success) => {
        if (success.success === true) {
          this.dialogRef.close({ event: 'Ok' });
          this.loading = false;
        } else if (success.success === false) {
          this.loading = false;
          this.alertService.showNotificationMessage(
            'danger',
            'bottom',
            'right',
            'txt_d',
            'check_circle',
            'Accept Contract',
            'Some reseaon document is not accepted.'
          );
        }
      },
      (error) => {
        this.alertService.showNotificationMessage(
          'danger',
          'bottom',
          'right',
          'txt_g',
          'error',
          'Unexpected Error',
          AppSettings.ERROR
        );
        this.loading = false;
      }
    );
  }

  getStoragedata() {
    this.getIndusData(null);
    this.storageContactInfo
      .get('companyName')
      .setValue(this.data.userDetail.companyName);
    this.storageContactInfo
      .get('firstName')
      .setValue(this.data.userDetail.firstName);
    this.storageContactInfo
      .get('lastName')
      .setValue(this.data.userDetail.lastName);
    this.storageContactInfo
      .get('telephone')
      .setValue(this.data.userDetail.phone);
      this.storageContactInfo
      .get('countryCode')
      .setValue(this.data.userDetail.countryCode);
    this.storageContactInfo
      .get('emailAddress')
      .setValue(this.data.userDetail.email);
    this.storageContactInfo.get('fax').setValue(this.data.userDetail.fax);
    this.storageContactInfo
      .get('socialProfile')
      .setValue(this.data.userDetail.linkedin);
    this.storageContactInfo
      .get('companyWebUrl')
      .setValue(this.data.userDetail.websiteUrl);
    this.storageContactInfo
      .get('phyAddres1')
      .setValue(this.data.userDetail.phyAddressLine1);
    this.storageContactInfo
      .get('phyAddres2')
      .setValue(this.data.userDetail.phyAddressLine2);
    this.storageContactInfo
      .get('phyPostalCode')
      .setValue(this.data.userDetail.phyZip);
    this.storageContactInfo
      .get('phyCity')
      .setValue(this.data.userDetail.phyCity);
    this.storageContactInfo
      .get('phyState')
      .setValue(this.data.userDetail.phyState);
    this.storageContactInfo.get('phyCountry').setValue('US');
    this.searchCtrl = this.data.userDetail.usSic1987Description;

    this.storageLatitude = this.data.userDetail.phyLatitude;
    this.storageLongitude = this.data.userDetail.phyLongitude;
    this.getRecord = this.data.userDetail;
    let name =
      this.data.userDetail.firstName + ' ' + this.data.userDetail.lastName;
    this.signatureForm = this.formBuilder.group({
      name: [name, [Validators.required]],
      type: ['', [Validators.required]],
    });
    this.signatureSkeleton = false;
  }

  storageInfoSave({ value, valid }) {
    this.submitted = true;
    if (valid) {
      this.loading = true;
      const formData = new FormData();
      let phyAdd1;
      let phyAdd2;
      let phyZip;
      let industries,
        usSic1987Description,
        phyCity,
        phyState,
        phyCountry,
        fax,
        socialProfile,
        companyWebUrl;

      if (this.searchCtrlValue?.id) {
        industries = this.searchCtrlValue.id;
      } else {
        industries = null;
      }

      if (this.searchCtrl) {
        usSic1987Description = this.searchCtrl;
      } else {
        usSic1987Description = '';
      }

      if (
        this.storageContactInfo.get('phyAddres1').value === null ||
        this.storageContactInfo.get('phyAddres1').value === undefined
      ) {
        phyAdd1 = '';
      } else {
        phyAdd1 = this.storageContactInfo.get('phyAddres1').value;
      }
      if (
        this.storageContactInfo.get('phyAddres2').value === null ||
        this.storageContactInfo.get('phyAddres2').value === undefined
      ) {
        phyAdd2 = '';
      } else {
        phyAdd2 = this.storageContactInfo.get('phyAddres2').value;
      }
      if (
        this.storageContactInfo.get('phyPostalCode').value === null ||
        this.storageContactInfo.get('phyPostalCode').value === undefined
      ) {
        phyZip = '';
      } else {
        phyZip = this.storageContactInfo.get('phyPostalCode').value;
      }
      if (
        this.storageContactInfo.get('phyCity').value === null ||
        this.storageContactInfo.get('phyCity').value === undefined
      ) {
        phyCity = '';
      } else {
        phyCity = this.storageContactInfo.get('phyCity').value;
      }
      if (
        this.storageContactInfo.get('phyState').value === 'null' ||
        this.storageContactInfo.get('phyState').value === null ||
        this.storageContactInfo.get('phyState').value === undefined
      ) {
        phyState = '';
      } else {
        phyState = this.storageContactInfo.get('phyState').value;
      }
      if (
        this.storageContactInfo.get('phyCountry').value === 'null' ||
        this.storageContactInfo.get('phyCountry').value === null ||
        this.storageContactInfo.get('phyCountry').value === undefined
      ) {
        phyCountry = '';
      } else {
        phyCountry = this.storageContactInfo.get('phyCountry').value;
      }

      if (
        this.storageContactInfo.get('fax').value === null ||
        this.storageContactInfo.get('fax').value === undefined
      ) {
        fax = '';
      } else {
        fax = this.storageContactInfo.get('fax').value;
      }

      if (!this.storageContactInfo.get('socialProfile').value) {
        socialProfile = '';
      } else {
        socialProfile = this.storageContactInfo.get('socialProfile').value;
      }

      if (!this.storageContactInfo.get('companyWebUrl').value) {
        companyWebUrl = '';
      } else {
        companyWebUrl = this.storageContactInfo.get('companyWebUrl').value;
      }

      formData.append('fax', fax);
      formData.append('phyZipShort', phyZip);
      formData.append(
        'companyName',
        this.storageContactInfo.get('companyName').value
      );
      formData.append('phyAddressLine1', phyAdd1);
      formData.append('phyZip', phyZip);

      formData.append('phyLatitude', this.storageLatitude);
      formData.append('phyLongitude', this.storageLongitude);
      formData.append('linkedin', socialProfile);
      formData.append('websiteUrl', companyWebUrl);

      formData.append(
        'firstName',
        this.storageContactInfo.get('firstName').value
      );
      formData.append(
        'lastName',
        this.storageContactInfo.get('lastName').value
      );

      formData.append('phyAddressLine2', phyAdd2);
      formData.append('phyCity', phyCity);
      formData.append('phyState', phyState);
      formData.append('phyCountry', phyCountry);
      formData.append(
        'companyPhone',
        this.storageContactInfo.get('telephone').value
      );
     
      if (industries) formData.append('industries', industries);
      if (usSic1987Description)
        formData.append('usSic1987Description', usSic1987Description);

      let urlType;
      let methodType;

      urlType = AppSettings.APIsNameArray.COLDSTORAGE.UPDATE;

      let APIparams = {
        apiKey: urlType,
        uri: '',
        postBody: formData,
      };
      methodType = this.commonService.put(APIparams);

      methodType.subscribe(
        (success) => {
          this.loading = false;
          if (success.success === true) {
            const body = document.getElementsByTagName('body')[0];
            body.classList.remove('userProfile_sugBox');
            this.commonService.profileUpdate.next(null);

            this.loading = false;
            this.dialogRef.close({ event: 'Ok' });
          } else if (success.success === false) {
            this.loading = false;
          }
        },
        (error) => {
          this.loading = false;
          this.alertService.showNotificationMessage(
            'danger',
            'bottom',
            'right',
            'txt_g',
            'error',
            'Unexpected Error',
            AppSettings.ERROR
          );
        }
      );
    }
  }

  /*upload certificate of insurance*/
  uploadEditDocumentMulti({ value, valid }) {
    if (valid) {
      this.loading = true;
      const formData = new FormData();
      formData.append(
        'description',
        this.editDocUpload.get('description').value
      );
      formData.append('docId', this.data.title.id);
      let APIparams = {
        apiKey: AppSettings.APIsNameArray.SHIPMENT.SHIPPERDOCEDIT,
        uri: '',
        postBody: formData,
      };
      this.commonService.put(APIparams).subscribe(
        (success) => {
          if (success.success === true) {
            this.alertService.showNotificationMessage(
              'success',
              'bottom',
              'right',
              'txt_s',
              'check_circle',
              'Shipper Document Title',
              'You have successfully edited title.'
            );
            this.loading = false;
            this.dialogRef.close({ event: 'Ok' });
          } else if (success.success === false) {
            this.loading = false;
            this.alertService.showNotificationMessage(
              'danger',
              'bottom',
              'right',
              'txt_d',
              'check_circle',
              'Shipper Document Title',
              'You have not successfully edited title.'
            );
          }
        },
        (error) => {
          this.alertService.showNotificationMessage(
            'danger',
            'bottom',
            'right',
            'txt_g',
            'error',
            'Unexpected Error',
            AppSettings.ERROR
          );
        }
      );
    }
  }

  fileChangeEvent(event: any) {
    this.imageChangedEvent = event;
    this.imagePath = event.target.files;
    this.name = event.target.files[0].name;
    this.onlyCropper = true;

    if (event.target.files.length === 0) return;
    if (
      event.target.files[0].type !== 'image/png' &&
      event.target.files[0].type !== 'image/jpeg' &&
      event.target.files[0].type !== 'image/jpg'
    ) {
      this.imagmessage = 'Only Images are allowed ( JPG | PNG | JPEG)';
      this.name = '';
      return;
    }
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
    let file = base64ToFile(this.croppedImage);
    const myFile = new File([file], this.name, {
      type: file.type,
    });
    this.imgName = myFile.name;
    this.showImgName = true;
    if (this.data.openPop === 'coverImage') {
      this.showCoverCropper = true;
      this.showProfileCropper = false;
      this.profileImageForm.get('profileImage').setValue(file);
    }
    if (this.data.openPop === 'profileImage') {
      this.showCoverCropper = false;
      this.showProfileCropper = true;
      this.profileImageForm.get('profileImage').setValue(file);
    }
    if (this.data.openPop === 'driverProfileImage') {
      this.showCoverCropper = false;
      this.showProfileCropper = true;
      this.driverProfileImageForm.get('profileImage').setValue(file);
    }
  }

  imageLoaded(image: LoadedImage) {
    this.showCropper = true;
  }

//  Region related funcanalities
  getApiRegions(params){
    let uri = null;
    let newparams = {
    'limit':200,
  }
  if(params)
    uri = this.commonService.getAPIUriFromParams(newparams);
    let APIparams = {
      apiKey: AppSettings.APIsNameArray.SHIPPER.REGIONS,
      uri: uri,
    };
  this.commonService.getList(APIparams).subscribe((ServerRes) => {
    if(ServerRes.response.allRegions && ServerRes.response.allRegions.length > 0){
      let regionListValue = ServerRes.response.allRegions;
      this.regionListlength=ServerRes.response.allRegions;
      this.regionsList = regionListValue.map( (value) => value.region).filter( (value, index, _regionListValue) => _regionListValue.indexOf(value) == index);

      this.regionListGroup = _.groupBy(ServerRes.response.allRegions);
      if(this.data.openPop==='regionEdit'){
        this.checkRegion(this.data.regionData.region);
        this.selecteRegionState();
      }
    } else{
      this.regionListGroup = []
    }
  })
}

checkState(event, index) {
  this.checkValueRegion = false;
  this.addOption = true;
  this.selectedStates=event.value;
  this.selectedStatesList = event.source.triggerValue;
}

selecteRegionState(){
  this.checkValueRegion = false;
  this.selectRegionForm = this.formBuilder.group({
    region: [this.data.regionData.region,],
    state: [this.data.regionData.stateId,],
  });
  this.selectedStatesList = this.data.regionData.stateNameArray.join(', ');
  this.selectedStates = this.data.regionData.stateId;
}

checkRegion(event) {
  this.selectedRegiosList=event;
  this.statesList=[];
  this.selectedStatesList=''
  this.checkValueRegion=true;
  for(var i=0; i < this.regionListlength.length; i++){
    if(this.regionListlength[i].region ===  event){
      this.statesList.push({
        seletedState : this.regionListlength[i].state,
        seletedId : this.regionListlength[i].id
      });
    } 
  }    
}

regionFormSubmit({ value, valid }) {
  let regionSelected = {
  region : value.region,
  stateId : this.selectedStates,
  stateNameArray : this.selectedStatesList.split(","),
  }
  this.dialogRef.close({
    regionSelected:regionSelected,
    index:this.data.index,
  });
}

  checkArray(region:any){
    let checkType;
    if(region){
      if(this.data.selectedRegion.includes(region)){
         if(this.data.regionData && this.data.regionData.region && this.data.selectedRegion.includes(this.data.regionData.region)){
          checkType = true;
         }else{
          checkType = true;
         } 
      }else{
        checkType = false;
      }
      return checkType;
    }
 
    return checkType;
  }

  selecteCountryFlag(event: any) {
    if(event){
      const getRecord = this.countryList?.filter((item) => item.countryAbbr == event);
    if(getRecord){
     this.defualtPickupCountryFlag =  getRecord[0]?.flag;
    }
    }
   }

}
