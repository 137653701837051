 <div *ngIf="data?.openPop ==='subscriptionAlert'">
    <div class="header_popup"> 
       <p class="fs_20 fsb_6 txt_b line_before " *ngIf="(data?.subscriptionData?.isPremium != true || data?.subscriptionData?.subscriptionStatus == 'canceled') && data?.type != 'isUnlockSafetyScore'">
         Upgrade To Shipper Premium Plan</p>
       <p class="fs_20 fsb_6 txt_b line_before " *ngIf="data?.subscriptionData?.subscriptionCreditHistory?.defaultCredit === 0 && data?.subscriptionData?.subscriptionCreditHistory?.additionalCredit===0 
       && data?.type == 'isUnlockSafetyScore'">Purchase Additional Safety Report Credits</p>
       <span class="close_popup_Icon" (click)="onNoClick()">
         <img src="{{serverPathForUrl}}/portal/icon/ic_close_white.svg" class="close_Icon">
       </span>
    </div>
    <div class="text-center">
      <p class="fs_16 fsn_4 txt_lb mb_24" *ngIf="(data?.subscriptionData?.isPremium != true || data?.subscriptionData?.subscriptionStatus == 'canceled') && data?.type != 'isUnlockSafetyScore'">
         Upgrade To Shipper Premium Plan for further activities.</p>
      <p class="fs_16 fsn_4 txt_lb mb_24" *ngIf="data?.subscriptionData?.subscriptionCreditHistory?.defaultCredit === 0 && data?.subscriptionData?.subscriptionCreditHistory?.additionalCredit===0 
         && data?.type == 'isUnlockSafetyScore'">The Shipper have no default and additional credits.</p>
       <div class="text-centerQW">
        <button type="button" class="btn custum_button button_primary fs_16 fsm_5">
           <span *ngIf="(data?.subscriptionData?.isPremium != true || data?.subscriptionData?.subscriptionStatus == 'canceled') && data?.type != 'isUnlockSafetyScore'"
              (click)="gotoPriemum()">
              <span class="">Upgrade Now</span>
           </span>
           <span *ngIf="data?.subscriptionData?.subscriptionCreditHistory?.defaultCredit === 0 && data?.subscriptionData?.subscriptionCreditHistory?.additionalCredit===0 && data?.type == 'isUnlockSafetyScore'"
              (click)="gotoPriemumAdditional()">
              <span class="">Purchase Additional Safety Report Credits</span>
           </span>
        </button>
     </div>
    </div>
 </div>