<div class="row">
    <div class="col-sm-6">
      <div class="skelaton_con">
        <ngx-skeleton-loader count="1" [theme]="{ width: '70%', height: '22px'}">
        </ngx-skeleton-loader>
        <ngx-skeleton-loader count="1" [theme]="{ width: '100%', height: '15px'}">
        </ngx-skeleton-loader>
        <ngx-skeleton-loader count="1" [theme]="{ width: '100%', height: '15px'}">
        </ngx-skeleton-loader>
        <ngx-skeleton-loader count="1" [theme]="{ width: '100%', height: '15px'}">
        </ngx-skeleton-loader>
        <ngx-skeleton-loader count="1" [theme]="{ width: '100%', height: '15px'}">
        </ngx-skeleton-loader>
        <ngx-skeleton-loader count="1" [theme]="{ width: '100%', height: '15px'}">
        </ngx-skeleton-loader>
      </div>
    </div>
    <div class="col-sm-6">
      <div class="skelaton_con">
        <ngx-skeleton-loader count="1" [theme]="{ width: '70%', height: '22px'}">
        </ngx-skeleton-loader>
        <ngx-skeleton-loader count="1" [theme]="{ width: '100%', height: '15px'}">
        </ngx-skeleton-loader>
        <ngx-skeleton-loader count="1" [theme]="{ width: '100%', height: '15px'}">
        </ngx-skeleton-loader>
        <ngx-skeleton-loader count="1" [theme]="{ width: '100%', height: '15px'}">
        </ngx-skeleton-loader>
        <ngx-skeleton-loader count="1" [theme]="{ width: '100%', height: '15px'}">
        </ngx-skeleton-loader>
        <ngx-skeleton-loader count="1" [theme]="{ width: '100%', height: '15pxs'}">
        </ngx-skeleton-loader>
      </div>
    </div>
    <div class="col-md-12">
      <div class="skelaton_con">
        <ngx-skeleton-loader count="1" [theme]="{ width: '70%', height: '22px'}">
        </ngx-skeleton-loader>
        <ngx-skeleton-loader count="1" [theme]="{ width: '100%', height: '15px'}">
        </ngx-skeleton-loader>
        <ngx-skeleton-loader count="1" [theme]="{ width: '100%', height: '15px'}">
        </ngx-skeleton-loader>
        <ngx-skeleton-loader count="1" [theme]="{ width: '100%', height: '15px'}">
        </ngx-skeleton-loader>
        <ngx-skeleton-loader count="1" [theme]="{ width: '100%', height: '15px'}">
        </ngx-skeleton-loader>
      </div>
    </div>
  </div>
  