<div class="shipmnt-side-panel">
  <div class="side_contaier">
    <div class="side_contaiert">
      <div class="header_bar">
        <div class="side_headerl" *ngIf="orderDetail">
          <a class="pointer" target="_blank" (click)="openTabShipment(this.orderDetail?.id, 'newTab')">
            <u class="fs_14 fsn_4 txt_p ">Open in New tab</u></a>
        </div>
        <div class="side_headerr" (click)="closePanel(orderDetail,'close')">
          <span class="icon-ic-cross fs_24 txt_lb pointer"><i class="fa-solid fa-xmark"></i></span>
        </div>
      </div>
      <div class="side_content hidden_scrollBar" *ngIf="!sidePanelLoader">
        <div class="ship_detail_head">
          <div class="side_con_header">
            <div class="id_action">
              <div>
                <p class="id_label">Order number</p>
                <p class="id_value ">#{{orderDetail?.uniqueId}}</p>
              </div>
              <div class="" style="display: flex; align-items: center;">
                <span class="ch_statue">
                  <div class="ship_status">
                    <span class="status_label" [ngStyle]="{'background-color': orderDetail?.tripStatus?.Color }"
                      style="color:white">{{orderDetail?.tripStatus?.Text}}
                      <div *ngIf="orderDetail?.tripStatus && !orderDetail?.tripStatus?.Text">
                        <img src="{{serverPathForUrl}}/portal/images/after-login/LaneAxis-loader.gif"
                          style="max-height: 16px;">
                      </div>
                    </span>
                  </div>
                </span>
              </div>
            </div>
            <div class="line_status_con">
              <div class="line_view_status" *ngIf="orderDetail">
                <app-order-progress [statusLabel]="orderDetail?.statusLabel" [statusBars]="orderDetail?.statusBars"
                  [statusSlug]="orderDetail?.statusSlug" [updatedAt]="orderDetail?.updatedAt"
                  [statusType]="orderDetail?.status"></app-order-progress>
              </div>
            </div>
          </div>
          <div class="ship_other_detail">
            <div class="row">
              <div class="col-md-12">
                <div class="l_label_value">
                  <p class="l_label">Order Name</p>
                  <p class="l_value fs_20 text_truncate txt_cap ">{{orderDetail?.title ? orderDetail?.title :
                    '-'}}</p>
                </div>
              </div>
              <div class="col-md-12">
                <p class="l_label">Your Shipper</p>
                <div class="l_label_value">
                  <div class="llv_l">
                    <span class="shipr_img" *ngIf="orderDetail?.shipper?.profileImage">
                      <img src="{{orderDetail?.shipper.profileImage}}" class="img-fluid">
                    </span>
                    <span class="initial_name initial_r_40 "
                      *ngIf="!orderDetail?.shipper?.profileImage">{{orderDetail?.shipper?.companyName |
                      acronymOfName}}</span>
                    <div>
                      <p class="fs_14 fsb_6 txt_b mb-0 ms-2 text_truncate pointer " style="max-width: 150px;"
                        matTooltip="{{orderDetail?.shipper?.companyName}}">{{orderDetail?.shipper?.companyName ?
                        orderDetail?.shipper?.companyName : '-' }}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="l_label_value">
                  <p class="l_label">Consignee Name</p>
                  <p class="l_value ">{{orderDetail?.consingneeName ? orderDetail?.consingneeName : '-'}}</p>
                </div>
              </div>
              <div class="col-md-6">
                <div class="l_label_value">
                  <p class="l_label">Consignee Email</p>
                  <p class="l_value ">{{orderDetail?.consingneeEmail ? orderDetail?.consingneeEmail : '-' }}</p>
                </div>
              </div>
              <div class="col-md-6">
                <div class="l_label_value">
                  <p class="l_label">Updated On</p>
                  <p class="l_value">{{orderDetail?.updatedAt | date: 'MMM dd, yyyy HH:mm'}}</p>
                </div>
              </div>
              <div class="col-md-6" *ngIf="userType==='SHIPPER'">
                <div class="l_label_value">
                  <p class="l_label">Created Date</p>
                  <p class="l_value">{{orderDetail?.createdAt | date: 'MMM dd, yyyy HH:mm'}}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="tabs_details_con">
          <div class="tab_header" id="list-tab" role="tablist">
            <button [ngClass]="currentTabShow==='orders' ? 'activeProfileTab':''" class="tab_header_btn "
              (click)="currentTabActive('orders')">Details</button>
            <button [ngClass]="currentTabShow==='storageService' ? 'activeProfileTab':''" class="tab_header_btn "
              (click)="currentTabActive('storageService')">Storage Services</button>
            <button *ngIf="bidTabIndicator" [ngClass]="currentTabShow==='storageOrderQuotes' ? 'activeProfileTab':''"
              class="tab_header_btn " (click)="currentTabActive('storageOrderQuotes')">Order Quotes</button>
          </div>
          <div class="sidePanel_tab v_scrollBar">
            <div *ngIf="currentTabShow ==='orders'">
              <div *ngIf="orderDetail && !skeletonLoader">
                <div class="detail_card_con">
                  <p class="line_before ">Pickup Information</p>
                  <div class="con_wrapper" *ngFor="let pickupPoint of orderDetail?.pickupAddress; let i=index;">
                    <div class="con_wrapper_row">
                      <div class="row_label">Location</div>
                      <div class="row_value">{{pickupPoint.locationLongName ? pickupPoint.locationLongName : '-'}}</div>
                    </div>
                    <div class="con_wrapper_row">
                      <div class="row_label">ZIP Code</div>
                      <div class="row_value">{{pickupPoint.zip}}</div>
                    </div>
                    <div class="con_wrapper_row">
                      <div class="row_label">Date & Time</div>
                      <div class="row_value">
                        {{pickupPoint.date | date: 'MMM dd, yyyy'}} at {{pickupPoint.timeSlot}}
                        {{pickupPoint.timeZone}}
                      </div>
                    </div>
                    <div class="con_wrapper_row">
                      <div class="row_label">Contact Number</div>
                      <div class="row_value">{{pickupPoint.contact | phone : pickupPoint?.countryCode}}</div>
                    </div>
                    <div class="con_wrapper_row">
                      <div class="row_label">Appointment Time</div>
                      <div class="row_value"> {{pickupPoint?.isAppointment ? pickupPoint?.appointmentTimeSlot : ' - '}}
                      </div>
                    </div>
                    <div class="con_wrapper_row mb-0">
                      <div class="row_label">Special Req</div>
                      <div class="row_value">{{pickupPoint.specialRequirement ? pickupPoint.specialRequirement : '-'}}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="detail_card_con">
                  <p class="line_before ">Dropoff Information</p>
                  <div class="con_wrapper" *ngFor="let dropPoint of orderDetail?.dropoffAddress;let i=index;">
                    <div class="con_wrapper_row">
                      <div class="row_label">Location</div>
                      <div class="row_value">{{dropPoint.locationLongName ? dropPoint.locationLongName : '-'}}</div>
                    </div>
                    <div class="con_wrapper_row">
                      <div class="row_label">ZIP Code</div>
                      <div class="row_value">{{dropPoint.zip}}</div>
                    </div>
                    <div class="con_wrapper_row">
                      <div class="row_label">Date & Time</div>
                      <div class="row_value">{{dropPoint.date | date: 'MMM dd, yyyy'}} at {{dropPoint.timeSlot}}
                        {{dropPoint.timeZone}}</div>
                    </div>
                    <div class="con_wrapper_row">
                      <div class="row_label">Contact Number</div>
                      <div class="row_value">{{dropPoint.contact | phone : dropPoint?.countryCode}}</div>
                    </div>
                    <div class="con_wrapper_row">
                      <div class="row_label">Appointment Time</div>
                      <div class="row_value"> {{dropPoint?.isAppointment ? dropPoint?.appointmentTimeSlot : ' - '}}
                      </div>
                    </div>
                    <div class="con_wrapper_row mb-0" style="flex: 2;">
                      <div class="row_label">Special Req</div>
                      <div class="row_value">{{dropPoint.specialRequirement ? dropPoint.specialRequirement : '-'}}</div>
                    </div>
                  </div>
                </div>

                <div class="detail_card_con">
                  <p class="line_before ">Payment Method</p>
                  <div class="con_wrapper">
                    <div class="con_wrapper_rowQW mb-1">
                      <p class="fs_16 fsn_4 txt_lb">You have selected <span *ngIf="orderDetail?.terms === '2'">
                          ACH</span> <span *ngIf="orderDetail?.terms === '1'"> Credit/Debit Card</span> as your
                        payment method. Please be aware the payment method cannot be changed once you authorized the
                        payment on Storage order.</p>
                      <div class="row_value">
                        <img
                          src="{{serverPathForUrl}}/portal/images/after-login/cl-icons/ic-currency-usd.svg"
                          class="img-fluid me-2">
                        <span class="fsb_6 " *ngIf="orderDetail?.paymentMethod === 1">USD Money</span>
                        <span class="fsb_6 " *ngIf="orderDetail?.paymentMethod === 2">USDC Money</span>
                      </div>
                    </div>
                    <div class="con_wrapper_row mb-0">
                      <div class="row_label">
                        <img
                          src="{{serverPathForUrl}}/portal/images/after-login/cl-icons/ic-currency-usd.svg"
                          class="img-fluid me-2">
                        <span class="txt_b fsb_6 " *ngIf="orderDetail?.terms === '2'">ACH Payment</span>
                        <span class="txt_b fsb_6 " *ngIf="orderDetail?.terms === '1'">Credit/Debit Card</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div *ngIf="currentTabShow ==='storageService'">
              <div *ngIf="orderDetail && !skeletonLoader">
                <div class="storage_detail_con">
                  <p class="line_before ">Storage Details</p>
                  <div class="storage_detail_cards">
                    <div class="sd_card" *ngFor="let storageService of orderDetail?.storageServices;">
                      <div class="row">
                        <div class="col-md-4 sd_card_row">
                          <p class="sd_card_lable">Storage Service</p>
                          <p class="sd_card_value">{{storageService.service ? storageService.service : '-'}}</p>
                        </div>
                        <div class="col-md-4 sd_card_row">
                          <p class="sd_card_lable">Storage Category</p>
                          <p class="sd_card_value">{{storageService.category ? storageService.category : '-'}}</p>
                        </div>
                        <div class="col-md-4 sd_card_row">
                          <p class="sd_card_lable">Temperature</p>
                          <p class="sd_card_value">{{storageService.temperature ? storageService.temperature + " F" :
                            '-'}} </p>
                        </div>
                        <div class="col-md-4 sd_card_row">
                          <p class="sd_card_lable">Pallet Size</p>
                          <p class="sd_card_value" *ngIf="storageService?.width">{{storageService?.width}} *
                            {{storageService.length}} * {{storageService.height}} inches</p>
                          <p class="sd_card_value" *ngIf="!storageService?.width">-</p>
                        </div>
                        <div class="col-md-4 sd_card_row">
                          <p class="sd_card_lable">Quantity</p>
                          <p class="sd_card_value">{{storageService.quantity ? storageService.quantity : '-' }}
                            {{storageService?.type}}</p>
                        </div>
                        <div class="col-md-4 sd_card_row">
                          <p class="sd_card_lable">Weight</p>
                          <p class="sd_card_value">{{storageService?.weight ? storageService?.weight + " Lbs" : '-'}}
                          </p>
                        </div>
                        <div class="col-md-4 sd_card_row">
                          <p class="sd_card_lable">Duration</p>
                          <p class="sd_card_value">{{storageService?.days ? storageService?.days + " Days" : '-'}} </p>
                        </div>
                        <div class="col-md-4 sd_card_row">
                          <p class="sd_card_lable">Stackable</p>
                          <p class="sd_card_value">{{storageService?.stackable ? storageService?.stackable : '-'}}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="bids_info" *ngIf="currentTabShow === 'storageOrderQuotes'">
              <div *ngIf="!bidHistorySekeletonLoader">
                <div class="detail_card_con" *ngIf="currentBid?.length === 0">
                  <p class="fs_16 fsm_5 txt_lb">You have not entered a quote/bid yet for this shipment. Use the 'Enter Quote/Bid' button to submit your first quote/bid to the Shipper.</p>
                  <div class="crnt_bid_action">
                    <button *ngIf="connectStripe === 'active' && userType === 'STORAGE'" type="button"
                      class="btn button_primary custum_button" (click)="bid()">Enter Quote/Bid</button>
                    <button *ngIf="connectStripe !== 'active' && userType === 'STORAGE'" type="button"
                      class="btn button_primary custum_button" (click)="viewOrderDetail(orderDetail?.id)">Enter
                      Quote/Bid</button>
                  </div>
                </div>

                <!-- current Bid -->
                <div class="detail_card_con" *ngIf="currentBid?.length > 0">
                  <p class="line_before mb-3 "> Current Quote/Bid </p>
                  <div class="bidsInfo_con">
                    <div class="bidsInfo_conT" style="align-items: start;">
                      <div class="bidsInfo_conTl">
                        <span class="bids_img">
                          <img *ngIf="currentBid[0]?.profileImage" src="{{currentBid[0]?.profileImage}}"
                            class="img-fluid">
                          <span *ngIf="!currentBid[0]?.profileImage"
                            class="initial_name initial_r_30">{{currentBid[0]?.userName | acronymOfName}}</span>
                        </span>
                        <div class="conTltxt">
                          <p class="fs_16 fsb_6 txt_b mb-0 d-flex">
                            <span class="text_truncate "
                              style="display: inline-block; max-width: 205px;">{{currentBid[0]?.userName ?
                              currentBid[0]?.userName : '-'}}</span>
                            <span *ngIf="currentId === currentBid[0]?.actionBy" class="ms-1 ">(Me)</span>
                          </p>
                          <p class="fs_12 fsn_4 txt_lb mb-0">{{currentBid[0]?.createdAt | date: 'MMM dd, yyyy'}} at
                            {{currentBid[0]?.createdAt | date: 'HH:mm:ss'}}</p>
                        </div>
                      </div>
                      <div class="bidsInfo_conTr">
                        <p class="fs_11 fsn_4 txt_lb mb-0">{{currentBid[0]?.statusLabel | uppercase}}</p>
                        <p class="fsb_7 txt_lb mb-0 usdUdsd contract_offerRate">
                          <span *ngIf="orderDetail?.paymentMethod === 2" class="ms-1 usdc_iconS"></span>
                          <span *ngIf="orderDetail?.paymentMethod === 2">{{currentBid[0]?.bidAmount |
                            sixDecimalPipe}}</span>
                          <span *ngIf="orderDetail?.paymentMethod === 1">${{currentBid[0]?.bidAmount |
                            twoDecimalPipe}}</span>
                        </p>
                      </div>
                    </div>
                    <div class="bidsMess" *ngIf="currentBid[0]?.note">
                      <img
                        src="{{serverPathForUrl}}/portal/images/after-login/cl-icons/ic-message-black.svg"
                        class="img-fluid">
                      <p class="fs_12 fsn_4 txt_b mb-0 text_truncate" matTooltip="{{currentBid[0]?.note}}">
                        <i>{{currentBid[0]?.note}}</i>
                      </p>
                    </div>

                    <div class="bids_action" *ngIf="currentBid[0]?.status <= 1">
                      <p class="actionl" *ngIf="checkWithdrawCondition(currentBid[0]?.actionBy)"
                        (click)="cancelBid(currentBid[0]?.id,currentBid[0]?.bidAmount,currentBid[0]?.note,orderDetail?.shipper?.companyName,orderDetail?.paymentMethod)">
                        <img
                          src="{{serverPathForUrl}}/portal/images/after-login/cl-icons/ic-withdraw-bid.svg"
                          class="img-fluid">
                        <span class="fs_14 fsm_5 txt_d ms-2">Withdraw</span>
                      </p>
                      <p class="adisabled actionl"
                        *ngIf="userId !== currentBid[0]?.actionBy && currentBid[0]?.statusLabel ==='Awaiting confirmation'">
                        <img
                          src="{{serverPathForUrl}}/portal/images/after-login/cl-icons/ic-withdraw-bid.svg"
                          class="img-fluid"><span class="fs_14 fsm_5 txt_p ms-2">Withdraw</span>
                      </p>

                      <p class="actionr" (click)="redBid(currentBid[0])">
                        <img
                          src="{{serverPathForUrl}}/portal/images/after-login/cl-icons/ic-rebid-bid.svg"
                          class="img-fluid">
                        <span class="fs_14 fsm_5 txt_p ms-2">Counter quote/bid</span>
                      </p>
                      <p class="actionl" *ngIf="userId !== currentBid[0]?.actionBy "
                        (click)="confirmBid(currentBid[0])">
                        <img
                          src="{{serverPathForUrl}}/portal/images/after-login/cl-icons/ic-accept-bid.svg"
                          class="img-fluid">
                        <span class="fs_14 fsm_5 txt_s ms-2">Confirm</span>
                      </p>
                      <p class="actionr" *ngIf="connectStripe !== 'active' && userType==='STORAGE'"
                        (click)="checkStripeStatus()">
                        <img
                          src="{{serverPathForUrl}}/portal/images/after-login/cl-icons/ic-rebid-bid.svg"
                          class="img-fluid">
                        <span class="fs_14 fsm_5 txt_p ms-2">Counter quote/bid</span>
                      </p>
                    </div>
                    <div class="bids_action bid_confirm" *ngIf="currentBid[0]?.status > 1">
                      <span *ngIf="currentBid[0]?.status === 2 || currentBid[0]?.status === 3" class="txt_p"
                        matTooltip="Confirmed">Confirmed</span>
                    </div>
                  </div>
                </div>

                <!-- Accept Bid -->
                <div class="detail_card_con" *ngIf="acceptBidList?.length > 0">
                  <p class="line_before mb-3 ">Accepted Quote/Bid </p>
                  <div class="bidsInfo_con">
                    <div class="bidsInfo_conT">
                      <div class="bidsInfo_conTl">
                        <span class="bids_img">
                          <img *ngIf="acceptBidList[0]?.profileImage" src="{{acceptBidList[0]?.profileImage}}"
                            class="img-fluid">
                          <span *ngIf="!acceptBidList[0]?.profileImage"
                            class="initial_name initial_r_30">{{acceptBidList[0]?.userName | acronymOfName}}</span>
                        </span>
                        <div class="conTltxt">
                          <p class="fs_16 fsb_6 txt_b mb-0 d-flex">
                            <span class="text_truncate "
                              style="display: inline-block; max-width: 205px;">{{acceptBidList[0]?.userName ?
                              acceptBidList[0]?.userName : '-'}}</span>
                            <span *ngIf="currentId === acceptBidList[0]?.actionBy" class="ms-1 ">(Me)</span>
                          </p>
                          <p class="fs_12 fsn_4 txt_lb mb-0">{{acceptBidList[0]?.createdAt | date: 'MMM dd, yyyy'}} at
                            {{acceptBidList[0]?.actionTs | date: 'HH:mm:ss'}}</p>
                        </div>
                      </div>
                      <div class="bidsInfo_conTr">
                        <p class="fs_11 fsn_4 txt_lb mb-0">{{acceptBidList[0]?.statusLabel | uppercase}}</p>
                        <p class="fsb_7 mb-0 usdUdsd contract_offerRate">
                          <span *ngIf="orderDetail?.paymentMethod === 2" class="ms-1 usdc_iconS"></span>
                          <span *ngIf="orderDetail?.paymentMethod === 2">{{acceptBidList[0]?.bidAmount |
                            sixDecimalPipe}}</span>
                          <span *ngIf="orderDetail?.paymentMethod === 1">${{acceptBidList[0]?.bidAmount |
                            twoDecimalPipe}}</span>
                         
                        </p>
                      </div>
                    </div>
                    <div class="bidsMess" *ngIf="acceptBidList[0]?.note">
                      <img
                        src="{{serverPathForUrl}}/portal/images/after-login/cl-icons/ic-message-black.svg"
                        class="img-fluid">
                      <p class="fs_12 fsn_4 txt_b mb-0 text_truncate" matTooltip="{{acceptBidList[0]?.note}}">
                        <i>{{acceptBidList[0]?.note}}</i>
                      </p>
                    </div>
                  </div>
                </div>

                <!-- Previous Bid -->
                <div class="detail_card_con" *ngIf="previousBid?.length > 0">
                  <p class="line_before mb-3 ">Previous Quotes/Bids</p>
                  <div class="bidsInfo_con ope_50" *ngFor="let historyRecord of previousBid;let j=index;">
                    <div class="bidsInfo_conT">
                      <div class="bidsInfo_conTl">
                        <span class="bids_img">
                          <img *ngIf="historyRecord?.profileImage" src="{{historyRecord?.profileImage}}"
                            class="img-fluid">
                          <span *ngIf="!historyRecord?.profileImage"
                            class="initial_name initial_r_30">{{historyRecord?.userName | acronymOfName}}</span>
                        </span>
                        <div class="conTltxt">
                          <p class="fs_16 fsb_6 txt_b mb-0 d-flex">
                            <span class="text_truncate "
                              style="display: inline-block; max-width: 205px;">{{historyRecord.userName ?
                              historyRecord.userName : '-'}}</span>
                            <span *ngIf="currentId === historyRecord.actionBy" class="ms-1 ">(Me)</span>
                          </p>
                          <p class="fs_12 fsn_4 txt_lb mb-0">{{historyRecord.createdAt | date: 'MMM dd, yyyy'}} at
                            {{historyRecord.createdAt | date: 'HH:mm'}}</p>
                        </div>
                      </div>
                      <div class="bidsInfo_conTr">
                        <p class="fs_11 fsn_4 txt_lb mb-0">{{historyRecord.statusLabel | uppercase}}</p>
                        <p class="fsb_7 txt_b mb-0 usdUdsd contract_offerRate">
                          <span *ngIf="orderDetail?.paymentMethod === 2" class="df_c">
                            <span class="ms-1 usdc_iconS"></span>
                            <span>{{historyRecord.bidAmount | sixDecimalPipe}}</span>
                          </span>
                          <span *ngIf="orderDetail?.paymentMethod === 1">${{historyRecord.bidAmount |
                            twoDecimalPipe}}</span>
                         
                        </p>
                      </div>
                    </div>
                    <div class="bidsMess" *ngIf="historyRecord?.note">
                      <img
                        src="{{serverPathForUrl}}/portal/images/after-login/cl-icons/ic-message-black.svg"
                        class="img-fluid">
                      <p class="fs_12 fsn_4 txt_b mb-0 text_truncate" matTooltip="{{historyRecord?.note}}">
                        <i>{{historyRecord?.note}}</i>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <!-- skelaton Bid-->
              <div class="skt_bidHistory" *ngIf="bidHistorySekeletonLoader">
                <div class="skt_currentBid">
                  <ngx-skeleton-loader count="1" [theme]="{ width: '88%', height: '20px'}" class="loader_mt_10"
                    style="padding: 0 24px;"></ngx-skeleton-loader>
                  <div class="skt_bgBidCard bg_w mb-1">
                    <div class="skt_bgBidCardt">
                      <div class="skt_bgBidCardtl">
                        <ngx-skeleton-loader count="1" [theme]="{ width: '40px', height: '45px'}" class="loader_mb_0">
                        </ngx-skeleton-loader>
                      </div>
                      <div class="skt_bgBidCardtr">
                        <ngx-skeleton-loader count="1" [theme]="{ width: '100%', height: '20px'}" class="loader_mb_5">
                        </ngx-skeleton-loader>
                        <ngx-skeleton-loader count="1" [theme]="{ width: '100%', height: '16px'}" class="loader_mb_0">
                        </ngx-skeleton-loader>
                      </div>
                    </div>
                    <div class="skt_bgBidCardb">
                      <ngx-skeleton-loader count="1" [theme]="{ width: '100%', height: '16px'}" class="loader_mb_0">
                      </ngx-skeleton-loader>
                    </div>
                    <div class="skt_bgBidCardt"
                      style="margin: 12px 0 0; border-top: 1px solid #c8dec9; padding: 12px 0 0;">
                      <div class="skt_bgBidCardtl" style="width: 48%; text-align: center;">
                        <ngx-skeleton-loader count="1" [theme]="{ width: '100%', height: '42px'}" class="loader_m_0">
                        </ngx-skeleton-loader>
                      </div>
                      <div class="skt_bgBidCardtr" style="width: 48%; text-align: center;">
                        <ngx-skeleton-loader count="1" [theme]="{ width: '100%', height: '42px'}" class="loader_m_0">
                        </ngx-skeleton-loader>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- Skelaton Previous Bid -->
                <div class="skt_previousBid">
                  <ngx-skeleton-loader count="1" [theme]="{ width: '88%', height: '20px'}" class="loader_mt_10"
                    style="padding: 0 24px;"></ngx-skeleton-loader>
                  <div class="skt_bgBidCard bg_w ope_7 mb-3" style="border-bottom: 4px solid #EDEDED;">
                    <div class="skt_bgBidCardt">
                      <div class="skt_bgBidCardtl">
                        <ngx-skeleton-loader count="1" [theme]="{ width: '40px', height: '45px'}" class="loader_mb_0">
                        </ngx-skeleton-loader>
                      </div>
                      <div class="skt_bgBidCardtr">
                        <ngx-skeleton-loader count="1" [theme]="{ width: '100%', height: '20px'}" class="loader_mb_5">
                        </ngx-skeleton-loader>
                        <ngx-skeleton-loader count="1" [theme]="{ width: '100%', height: '16px'}" class="loader_mb_0">
                        </ngx-skeleton-loader>
                      </div>
                    </div>
                    <div class="skt_bgBidCardb">
                      <ngx-skeleton-loader count="1" [theme]="{ width: '100%', height: '16px'}" class="loader_mb_0">
                      </ngx-skeleton-loader>
                    </div>
                  </div>
                  <div class="skt_bgBidCard bg_w ope_7 mb-3" style="border-bottom: 4px solid #EDEDED;">
                    <div class="skt_bgBidCardt">
                      <div class="skt_bgBidCardtl">
                        <ngx-skeleton-loader count="1" [theme]="{ width: '40px', height: '45px'}" class="loader_mb_0">
                        </ngx-skeleton-loader>
                      </div>
                      <div class="skt_bgBidCardtr">
                        <ngx-skeleton-loader count="1" [theme]="{ width: '100%', height: '20px'}" class="loader_mb_5">
                        </ngx-skeleton-loader>
                        <ngx-skeleton-loader count="1" [theme]="{ width: '100%', height: '16px'}" class="loader_mb_0">
                        </ngx-skeleton-loader>
                      </div>
                    </div>
                    <div class="skt_bgBidCardb">
                      <ngx-skeleton-loader count="1" [theme]="{ width: '100%', height: '16px'}" class="loader_mb_0">
                      </ngx-skeleton-loader>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--side panel loader-->
      <div *ngIf="sidePanelLoader">
        <div class="skt_shipDetailHead dark_skt">
          <ngx-skeleton-loader count="1" [theme]="{ width: '100%', height: '36px'}" class="loader_mb_5">
          </ngx-skeleton-loader>
          <ngx-skeleton-loader count="1" [theme]="{ width: '100%', height: '24px'}" class="loader_mb_5">
          </ngx-skeleton-loader>

          <ngx-skeleton-loader count="1" [theme]="{ width: '100%', height: '20px'}" class="loader_mt_10 loader_mb_5">
          </ngx-skeleton-loader>
          <ngx-skeleton-loader count="1" [theme]="{ width: '100%', height: '20px'}" class="loader_mb_5">
          </ngx-skeleton-loader>

          <ngx-skeleton-loader count="1" [theme]="{ width: '100%', height: '20px'}" class="loader_mt_10 loader_mb_5">
          </ngx-skeleton-loader>
          <ngx-skeleton-loader count="1" [theme]="{ width: '100%', height: '20px'}" class="loader_mb_5">
          </ngx-skeleton-loader>

          <ngx-skeleton-loader count="1" [theme]="{ width: '100%', height: '42px'}" class="loader_mt_10 loader_mb_5">
          </ngx-skeleton-loader>
        </div>
        <!-- skelaton loader for Shipment Details -->
        <div class="skelaton_shipper_view">
          <div class="row">
            <div class="col-sm-6">
              <div class="list-view">
                <div class="list-card-block pb-1">
                  <h3 class="mb-2">
                    <ngx-skeleton-loader count="1" [theme]="{ width: '70%', height: '22px'}"></ngx-skeleton-loader>
                  </h3>
                  <div class="list-lable-wrapper mb-1">
                    <div class="list-lable-placeholder">
                      <ngx-skeleton-loader count="1" [theme]="{ width: '100%', height: '15px'}"></ngx-skeleton-loader>
                      <ngx-skeleton-loader count="1" [theme]="{ width: '100%', height: '15px'}"></ngx-skeleton-loader>
                      <ngx-skeleton-loader count="1" [theme]="{ width: '100%', height: '15px'}"></ngx-skeleton-loader>
                      <ngx-skeleton-loader count="1" [theme]="{ width: '100%', height: '15px'}"></ngx-skeleton-loader>
                      <ngx-skeleton-loader count="1" [theme]="{ width: '100%', height: '15px'}"></ngx-skeleton-loader>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="list-view">
                <div class="list-card-block pb-1">
                  <h3 class="mb-2">
                    <ngx-skeleton-loader count="1" [theme]="{ width: '70%', height: '22px'}"></ngx-skeleton-loader>
                  </h3>
                  <div class="list-lable-wrapper mb-1">
                    <div class="list-lable-placeholder">
                      <ngx-skeleton-loader count="1" [theme]="{ width: '100%', height: '15px'}"></ngx-skeleton-loader>
                      <ngx-skeleton-loader count="1" [theme]="{ width: '100%', height: '15px'}"></ngx-skeleton-loader>
                      <ngx-skeleton-loader count="1" [theme]="{ width: '100%', height: '15px'}"></ngx-skeleton-loader>
                      <ngx-skeleton-loader count="1" [theme]="{ width: '100%', height: '15px'}"></ngx-skeleton-loader>
                      <ngx-skeleton-loader count="1" [theme]="{ width: '100%', height: '15px'}"></ngx-skeleton-loader>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="list-view">
            <div class="list-card-block pb-1">
              <h3 class="mb-3">
                <ngx-skeleton-loader count="1" [theme]="{ width: '100%', height: '22px'}"></ngx-skeleton-loader>
              </h3>
              <div class="list-lable-wrapper mb-3">
                <div class="list-lable-placeholder">
                  <ngx-skeleton-loader count="1" [theme]="{ width: '100%', height: '15px'}"></ngx-skeleton-loader>
                  <ngx-skeleton-loader count="1" [theme]="{ width: '100%', height: '15px'}"></ngx-skeleton-loader>
                  <ngx-skeleton-loader count="1" [theme]="{ width: '100%', height: '15px'}"></ngx-skeleton-loader>
                </div>
                <div class="list-lable-placeholder">
                  <ngx-skeleton-loader count="1" [theme]="{ width: '100%', height: '15px'}"></ngx-skeleton-loader>
                  <ngx-skeleton-loader count="1" [theme]="{ width: '100%', height: '15px'}"></ngx-skeleton-loader>
                  <ngx-skeleton-loader count="1" [theme]="{ width: '100%', height: '15px'}"></ngx-skeleton-loader>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="side_contaierb">
      <button class="pro_view_btn btn custum_button button_primary w-100"
        (click)="openTabShipment(this.orderDetail?.id, 'sameTab')">View Full Details</button>
    </div>
  </div>
</div>