import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { CommonService } from '../../commons/service/common.service';
import { SharedService } from '../../commons/service/shared.service';
import { environment } from 'src/environments/environment';

export interface DialogData {
  name: string;
  openPop: any;
  shipmentDetail: any;
  type: any;
  status: any;
  laneDetail: any;
}

@Component({
  selector: 'app-load-detail-commodity',
  templateUrl: './load-detail-commodity.component.html',
  styleUrls: ['./load-detail-commodity.component.scss']
})

export class LoadDetailCommodityComponent implements OnInit {
  public countryFlag:any;
  public countryList:any;
  public userType:any;
  public countryCode:any;
  public serverPathForUrl: any;

  constructor(
    private commonService: CommonService,
    public router: Router,
    private sharedService: SharedService,
    public dialogRef: MatDialogRef<LoadDetailCommodityComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) { }

  ngOnInit() {
    this.userType = localStorage.getItem('user_type');
    let configValue = this.sharedService.getConfig();
    this.serverPathForUrl = environment.serverPathForUrl;
    if (configValue !== undefined) {
      this.countryList = configValue.countries;
    }
    this.commonService.configData.subscribe((userData) => {
      this.countryList = configValue.countries;
    });
    if(this.data.shipmentDetail?.countryCode =='CA' || this.data.shipmentDetail?.countryCode =='US'){
      this.countryCode=+1;
    }
    else{
      this.countryCode=+52;
    }
    if(this.data && this.data?.shipmentDetail?.countryCode && this.data?.shipmentDetail?.phone){
      this.selecteCountryFlag(this.data?.shipmentDetail?.countryCode);
    }
  }

  selecteCountryFlag(event: any) {
    const getRecord = this.countryList.filter((item) => item.countryAbbr == event);
    this.countryFlag = getRecord[0]?.flag;
  }
  
  onClose(): void {
    this.dialogRef.close({ event: 'close' });
  }


}
