import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoadComponent } from './load/load.component';
import { TrackYourLoadComponent } from './track-your-load/track-your-load.component';
import { DisputeComponent } from './pages/view/dispute/dispute.component';

const routes: Routes = [
  {
    path: 'track-your-load',
    component: TrackYourLoadComponent,
  },
  {
    path: 'dispute/:id',
    component: DisputeComponent,
  },
  {
    path: 'load',
    component: LoadComponent,
    children: [
      { path: '', redirectTo: 'list', },
      
      { path: 'view', loadChildren: () =>
      import('./pages/view/view.module').then((mod) => mod.ViewModule),
      },
      { path: 'create', loadChildren: () =>
      import('./pages/create/create.module').then((mod) => mod.CreateModule),
      },
      { path: 'edit', loadChildren: () =>
      import('./pages/edit/edit.module').then((mod) => mod.EditModule),
      }
    ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class LoadRoutingModule { }
