import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface DialogData {
  type: string;
}

@Component({
  selector: 'app-payment-popup',
  templateUrl: './payment-popup.component.html',
  styleUrls: ['./payment-popup.component.css'],
})

export class PaymentPopupComponent implements OnInit {
  public selectedData = null;
  public popupData = [
    {
      icon: 'check_circle',
      status: 'paid',
      color: 'var(--clGrna)',
      heading: 'Transaction completed successfully',
      subheading:
        'Your transaction to buy USDC has been completed successfully.',
    },
    {
      icon: 'cancel',
      status: 'failed',
      color: 'var(--clReda)',
      heading: 'Transaction has been failed.',
      subheading:
        'Your transaction to buy USDC has been failed. Please try again',
    },
    {
      icon: 'check_circle',
      status: 'pending',
      color: 'var(--clyloa)',
      heading: 'Transaction is pending',
      subheading:
        'Your transaction to buy USDC is in pending state. Please wait.',
    },
    {
      icon: 'check_circle',
      status: 'confirmed',
      color: 'var(--clGrna)',
      heading: 'Transaction has been confirmed',
      subheading: 'Your transaction to buy USDC is in confirmed state.',
    },
    {
      icon: 'cancel',
      status: 'withdraw_failed',
      heading: 'Transaction has been failed',
      subheading:
        'Your transaction to withdraw USDC has been failed. Please try again',
    },
    {
      icon: 'check_circle',
      status: 'withdraw_pending',
      heading: 'Transaction is pending',
      subheading:
        'Your transaction to withdraw USDC is in pending state. Please wait.',
    },
  ];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public dialogRef: MatDialogRef<PaymentPopupComponent>
  ) {}

  ngOnInit() {
    if (this.data && this.data.type)
      this.selectedData = this.popupData.filter(
        (x) => x.status === this.data.type
      )
        ? this.popupData.filter((x) => x.status === this.data.type)[0]
        : {};
  }

  closePopup() {
    this.dialogRef.close({ event: 'fail' });
  }
  
}
