import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CommonService } from '../../commons/service/common.service';
import { AppSettings } from '../../commons/setting/app_setting';
import { AlertService } from '../../commons/service/alert.service';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { SharedService } from '../../commons/service/shared.service';
import * as _ from 'lodash';
declare var _: _.groupBy;
import { environment } from 'src/environments/environment';

export interface DialogData {
  openPop: string;
  contain: string;
  title: string;
  driverId: any;
  id: any;
  driverName: any;
  status: any;
  successMeg: any;
  buttontext: any;
  buttonnew: any;
  inviteDriverId: any;
  phone: any;
  countryCode: any;
}

@Component({
  selector: 'app-driver-popup',
  templateUrl: './driver-popup.component.html',
  styleUrls: ['./driver-popup.component.css'],
})
export class DriverPopupComponent implements OnInit {
  public status: boolean = false;
  public disableDriver: boolean = true;
  public submitted = false;
  public addDocUpload: FormGroup;
  public driverStatus: FormGroup;
  public loading = false;
  public params: any;
  public driverName: any;
  public country: any;
  public inviteDriver: FormGroup;
  public driverEdit: FormGroup;
  public numOnly = '[0-9]*';
  public hideImageRemove = false;
  public driveInviteData: any;
  public mobileemailerror: any;
  public driverIdNew: any;
  public name: any;
  public disbu: any;
  public imgURL: any;
  public defualtPickupCountryFlag: any;
  public userType: any;
  public getRecord: any;
  public countryList: any;
  public defualtPickupCountryFlag1: any = 'https://s3.us-east-2.amazonaws.com/staticdev.laneaxis.com/portal/images/shipment-countries-flags/US.png';
  public serverPathForUrl: any;

  constructor(
    private formBuilder: FormBuilder,
    private alertService: AlertService,
    private sharedService: SharedService,
    private commonService: CommonService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<DriverPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
  ) { }

  ngOnInit() {
    this.userType = localStorage.getItem('userType')
    let configValue = this.sharedService.getConfig();
    if (configValue !== undefined) {
      this.getConfigSet(configValue);
    }

    this.commonService.configData.subscribe((userData) => {
      this.getConfigSet(userData);
    });
    this.EditDriverCountryCode(this.data?.countryCode);
    this.serverPathForUrl = environment.serverPathForUrl;
    this.addDocUpload = this.formBuilder.group({
      document: ['', [Validators.required]],
      description: ['', [Validators.required, Validators.maxLength(64)]],
      driverId: [this.data.driverId, [Validators.required]],
    });

    this.driverStatus = this.formBuilder.group({
      name: [this.data.driverName, [Validators.required, Validators.pattern(/^([a-zA-Z0-9]+\s?)*$/), Validators.maxLength(64)]],
      status: [this.data.status, [Validators.required]],
      driverId: [this.data.driverId, [Validators.required]],
    });

    this.inviteDriver = this.formBuilder.group({
      name: ['', [Validators.required, Validators.pattern(/^([a-zA-Z0-9]+\s?)*$/), Validators.maxLength(64)],],
      mobile: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(10), Validators.pattern(/^[0-9]{0,}$/),],],
      countryCode: ['US', [Validators.required]],
    });

    this.driverEdit = this.formBuilder.group({
      name: [this.data.driverName, [Validators.required, Validators.pattern(/^([a-zA-Z0-9]+\s?)*$/), Validators.maxLength(64)]],
      phone: [this.data.phone, [Validators.required, Validators.minLength(10), Validators.maxLength(10), Validators.pattern(this.numOnly)]],
      countryCode: [this.data?.countryCode, [Validators.required]],
      driverId: [this.data.driverId, [Validators.required]],
    });
  }

  // Function to get data from config API 
  getConfigSet(configValue: any) {
    this.countryList = configValue.countries;
    this.defualtPickupCountryFlag = this.countryList[0]?.flag;
  }

  // API call for driver edit form submit
  driverSubmit({ value, valid }) {
    this.submitted = true;
    if (valid) {
      this.loading = true;
      const formData = new FormData();
      formData.append('driverId', this.data.driverId);
      formData.append('phone', this.driverEdit.get('phone').value);
      formData.append('countryCode', this.driverEdit.get('countryCode').value);
      formData.append('name', this.driverEdit.get('name').value);
      let APIparams = {
        apiKey: AppSettings.APIsNameArray.DRIVER.UPDATE,
        uri: '',
        postBody: formData,
      };
      this.commonService.put(APIparams).subscribe(
        async (success) => {
          if (success.success === true) {
            var dimensions = null;
            if (value.name) {
              dimensions = 'driverName=' + value.name;
            }
            if (this.driverEdit.get('phone').value) {
              const phoneNumber = 'phoneNumber=' + this.driverEdit.get('phone').value;
              dimensions = dimensions ? dimensions + '&' + phoneNumber : phoneNumber;
            }
            var obj = {
              eventName: 'update_driver',
              event_category: 'driver_details',
              userType: localStorage.getItem('user_type'),
              EmailID: localStorage.getItem('email'),
              Dimensions: dimensions ? dimensions : '-',
            };
            this.sharedService.track(obj);
            this.alertService.showNotificationMessage(
              'success',
              'bottom',
              'right',
              'txt_s',
              'check_circle',
              'Driver Profile',
              'You have successfully updated the driver’s profile.'
            );
            this.loading = false;
            this.dialogRef.close({ event: 'Ok' });
          } else if (success.success === false) {
            this.loading = false;
            this.alertService.showNotificationMessage(
              'danger',
              'bottom',
              'right',
              'txt_d',
              'cancel',
              'Driver Profile',
              success.message
            );
          }
        },
        (error) => {
          this.alertService.showNotificationMessage(
            'danger',
            'bottom',
            'right',
            'txt_g',
            'error',
            'Unexpected Error',
            AppSettings.ERROR
          );
          this.loading = false;
        }
      );
    }
  }

  /*API call for send invitation to driver*/
  inviteDriverSubmit({ value, valid }) {

    this.submitted = true;
    if (valid) {
      this.driverName = value.name;
      this.loading = true;
      let APIparams = {
        apiKey: AppSettings.APIsNameArray.DRIVER.INVITE,
        uri: '',
        postBody: value,
      };
      this.commonService.post(APIparams).subscribe(
        (ServerRes) => {
          if (ServerRes.success === true) {
            this.driverIdNew = ServerRes.response.driverId;
            var dimensions = null;
            if (value.name) {
              dimensions = 'driverName=' + value.name;
            }
            if (this.inviteDriver.get('mobile').value) {
              const phoneNumber = 'phoneNumber=' + this.inviteDriver.get('mobile').value;
              dimensions = dimensions ? dimensions + '&' + phoneNumber : phoneNumber;
            }
            var obj = {
              eventName: 'add_new_driver',
              event_category: 'driver_details',
              userType: localStorage.getItem('user_type'),
              EmailID: localStorage.getItem('email'),
              Dimensions: dimensions ? dimensions : '-',
            };
            this.sharedService.track(obj);
            this.loading = false;
            this.dialogRef.close({
              event: 'Ok',
              driverName: this.driverName,
              inviteDriverId: this.driverIdNew,
            });
          } else if (ServerRes.success === false) {
            if (ServerRes.response) {
              this.driveInviteData = ServerRes.response;
              this.mobileemailerror = ServerRes.response.message;
            } else {
              this.mobileemailerror = ServerRes.message;
            }
            this.loading = false;
          }
        },
        (error) => {
          this.alertService.showNotificationMessage(
            'danger',
            'bottom',
            'right',
            'txt_g',
            'error',
            'Unexpected Error',
            AppSettings.ERROR
          );
          this.loading = false;
        }
      );
    }
  }

  /*send invitation to the driver*/
  reInviteAssociat() {
    this.loading = true;
    let postBody = {
      driverId: this.driveInviteData.driverId,
      driverName: this.driveInviteData.driverName,
      driverPhone: this.driveInviteData.driverPhone,
      driverEmail: this.driveInviteData.driverEmail,
    };
    let APIparams = {
      apiKey: AppSettings.APIsNameArray.DRIVER.ASSOCIATEINVITE,
      uri: '',
      postBody: postBody,
    };
    this.commonService.post(APIparams).subscribe(
      (success) => {
        if (success.success === true) {
          this.loading = false;
          this.dialogRef.close({
            event: 'reInvite',
            driverName: this.driveInviteData.driverName,
          });
        } else if (success.success === false) {
          this.alertService.showNotificationMessage(
            'danger',
            'bottom',
            'right',
            'txt_d',
            'cancel',
            'Driver Profile',
            'Driver invitation is failed'
          );
          this.loading = false;
        }
      },
      (error) => {
        this.alertService.showNotificationMessage(
          'danger',
          'bottom',
          'right',
          'txt_g',
          'error',
          'Unexpected Error',
          AppSettings.ERROR
        );
        this.loading = false;
      }
    );
  }

  deleteRecordBy() {
    this.dialogRef.close({ event: 'Ok' });
  }

  // To close popup
  onNoClick(): void {
    this.dialogRef.close({ event: 'fail' });
  }

  // To close popup
  goToDriverList() {
    this.dialogRef.close({ event: 'Ok' });
  }

  // To close popup
  successInviteDriver() {
    this.dialogRef.close({ event: 'OK' });
  }

  // API call to upload driver document
  uploadDocument({ value, valid }) {
    if (valid) {
      this.loading = true;
      const formData = new FormData();
      formData.append('document', this.addDocUpload.get('document').value);
      formData.append(
        'description',
        this.addDocUpload.get('description').value
      );
      formData.append('driverId', this.addDocUpload.get('driverId').value);
      let APIparams = {
        apiKey: AppSettings.APIsNameArray.DRIVER.DOCADD,
        uri: '',
        postBody: formData,
      };
      this.commonService.postMediaMethod(APIparams).subscribe(
        (success) => {
          if (success.success === true) {
            this.alertService.showNotificationMessage(
              'success',
              'bottom',
              'right',
              'txt_s',
              'check_circle',
              'Driver Profile',
              'You have successfully uploaded a document.'
            );
            this.loading = false;
            this.dialogRef.close({ event: 'Ok' });
          } else if (success.success === false) {
            this.loading = false;
            this.alertService.showNotificationMessage(
              'danger',
              'bottom',
              'right',
              'txt_d',
              'check_circle',
              'Driver Profile',
              'You have not successfully uploaded a document.'
            );
          }
        },
        (error) => {
          this.alertService.showNotificationMessage(
            'danger',
            'bottom',
            'right',
            'txt_g',
            'error',
            'Unexpected Error',
            AppSettings.ERROR
          );
        }
      );
    }
  }

  // API call to delete driver document
  deleteRecord(id: any, driverId: any) {
    let uri = '';
    let params = {
      docId: id,
      driverId: driverId,
    };
    //get dynamic URI for APIs
    if (params) uri = this.commonService.getAPIUriFromParams(params);
    let APIparams = {
      apiKey: AppSettings.APIsNameArray.DRIVER.DELETE,
      uri: uri,
    };
    this.commonService.delete(APIparams).subscribe(
      (success) => {
        if (success.success === true) {
          this.alertService.showNotificationMessage(
            'success',
            'bottom',
            'right',
            'txt_s',
            'check_circle',
            'Driver Profile',
            'You have successfully removed a document.'
          );
          this.loading = false;
          this.dialogRef.close({ event: 'Ok' });
        }
      },
      (error) => {
        this.alertService.showNotificationMessage(
          'danger',
          'bottom',
          'right',
          'txt_g',
          'error',
          'Unexpected Error',
          AppSettings.ERROR
        );
      }
    );
  }

  // To remove image
  removeImage() {
    this.hideImageRemove = true;
  }

  // To remove image/cancel
  removeImageCancel() {
    this.hideImageRemove = false;
  }

  // Confirmation to remove image
  removeImageYes() {
    this.dialogRef.close({ event: 'removeImage' });
  }

  // To open selected file
  onSelectedFile(event) {
    if (event.target.files.length === 0) return;
    var reader = new FileReader();
    this.name = event.target.files[0].name;
    this.disbu = false;
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = (_event) => {
      this.imgURL = reader.result; //no use in html
    };
    if (event.target.files.length) {
      const file = event.target.files[0];
      this.addDocUpload.get('document').setValue(file);
    }
  }

  // To get input value for phone number by keypress event
  keyPress(event: any) {
    const pattern = /^[0-9]*$/;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode !== 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  // To close popup
  onNoConfirmClick() {
    this.dialogRef.close({ event: 'close' });
  }

  // To close popup
  onConfirmationClick() {
    this.dialogRef.close({ event: 'Ok' });
  }

  // To edit driver's country code
  EditDriverCountryCode(event: any) {
    this.getRecord = this.countryList.filter((item) => item?.countryAbbr == event);
    this.defualtPickupCountryFlag = this.getRecord[0]?.flag;
  }

  // To add driver's country code
  AddDriverCountryCode(event: any) {
    this.getRecord = this.countryList.filter((item) => item?.countryAbbr == event);
    this.defualtPickupCountryFlag1 = this.getRecord[0]?.flag;
  }

}
