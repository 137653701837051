<div *ngIf="data.openPop ==='driverSendInviteSuccess'">
   <form class="mb-0 custum_input_css inputInPopup" [formGroup]="inviteDriver"
      (ngSubmit)="inviteDriverSubmit(inviteDriver)" #myForm="ngForm" autocomplete="off">
      <div class="inviteDriver_popQW">
         <div class="header_popup">
            <p class="fs_20 fsb_6 txt_b line_before " style="margin: 0 0 0 -24px;">Add New Driver</p>
            <span class="close_popup_Icon" (click)="onNoClick()">
               <img src="{{serverPathForUrl}}/portal/icon/ic_close_white.svg" class="close_Icon">
            </span>
         </div>
         <div class="">
            <p class="fs_14 fsn_4 txt_lb mb-1">Name <span class="txt_d"> *</span></p>
            <mat-form-field appearance="none">
               <input matInput type="text" formControlName="name" placeholder="Enter Name"
                  class="form-control custom_input" maxlength="64" />
               <mat-error class="error_mess" *ngIf="inviteDriver.get('name').errors?.['required']">Name is required.
               </mat-error>
               <mat-error class="error_mess" *ngIf="inviteDriver.get('name').errors?.['pattern']">Please enter valid
                  information.</mat-error>
                  <mat-error *ngIf="inviteDriver.get('name').errors?.['maxlength']" class="error_mess">Only 64
                     characters are allowed.</mat-error>
            </mat-form-field>
         </div>

         <div class="selection_optionZQ custom_checkbox">
           
            <p class="fs_14 fsn_4 txt_lb mb-1">Mobile Number <span class="txt_d"> *</span> <img
                [src]="defualtPickupCountryFlag1" name="cla" width="20" height="13px" style="margin-left: 5px;">
            </p>
            <div class="num_country_code ic_dropdown edit_location_inputes">
              <mat-form-field appearance="none" class="frm_cunty_code">
                <div class="country_code_fld">
                  <mat-select formControlName="countryCode" (selectionChange)="AddDriverCountryCode($event.value)" class="form-control custom_input" placeholder="+1">
                    <mat-option *ngFor="let cou of countryList" [value]="cou.countryAbbr">
                    <img [src]="cou.flag" name="cla" height="16px">
                    <span class="ms-2">+{{cou.countryCode}}</span>
                    </mat-option>
                  </mat-select>
                  <mat-error *ngIf="submitted && inviteDriver.get('countryCode').errors?.['required']" class="error_mess er_mess_flag_drop">Country code is required.</mat-error>
                </div>
              </mat-form-field>
              <mat-form-field appearance="none">
               <input matInput type="text" class="form-control custom_input" formControlName="mobile"
                  placeholder="Enter mobile number" (keypress)="keyPress($event)" minlength="10" maxlength="10" />
               <mat-error class="error_mess" *ngIf="inviteDriver.get('mobile').errors?.['required']">Mobile Number is required.</mat-error>
               <mat-error class="error_mess" *ngIf="inviteDriver.get('mobile').errors?.['maxlength']">Mobile Number should be 10 digit.</mat-error>
               <mat-error class="error_mess" *ngIf="inviteDriver.get('mobile').errors?.['minlength']">Mobile Number should be 10 digit.</mat-error>
               <mat-error *ngIf="(inviteDriver.get('mobile').errors?.['pattern']) && !(inviteDriver.get('mobile').errors?.['minlength'])">
                  Please enter valid information.</mat-error>
            </mat-form-field>
            </div>
          </div>
         <p class="fs_14 fsn_4 txt_d" style="margin: -1rem 0 2rem" *ngIf="mobileemailerror && !inviteDriver.get('mobile').errors?.['required']">{{mobileemailerror}}</p>
         <div class="text-center">
            <button type="submit" *ngIf="!driveInviteData"
               class="btn custum_button button_primary w-100 fs_16 fsm_5 "
               style="margin: 0 0 1.5rem; height: 40px;">Send Invite</button>
            <button type="button" *ngIf="driveInviteData" (click)="reInviteAssociat()"
               class="btn custum_button button_primary w-100 fs_16 fsm_5"
               style="margin: 0 0 1.5rem; height: 40px;">Invite</button>
            <span class="fs_16 fsm_5 txt_lb pointer px-4 py-2 " type="submit" (click)="onNoClick()">Cancel</span>
         </div>
      </div>
   </form>
</div>

<div *ngIf="data.openPop ==='driverEdit'">
   <form class="mb-0 custum_input_css inputInPopup" [formGroup]="driverEdit" (ngSubmit)="driverSubmit(driverEdit)"
      autocomplete="off">
      <div class="inviteDriver_popQW">
         <div class="header_popup">
            <p class="fs_20 fsb_6 txt_b line_before ">Update Driver</p>
            <span class="close_popup_Icon" (click)="onNoClick()">
               <img src="{{serverPathForUrl}}/portal/icon/ic_close_white.svg" class="close_Icon">
            </span>
         </div>
         <div class="">
            <p class="fs_14 fsn_4 txt_lb mb-1">Name <span class="txt_d"> *</span></p>
            <mat-form-field appearance="none">
               <input matInput type="text" formControlName="name" placeholder="Enter Name"
                  class="form-control custom_input" maxlength="64" />
               <mat-error class="error_mess" *ngIf="driverEdit.get('name').errors?.['required']">Name is required.
               </mat-error>
               <mat-error *ngIf="driverEdit.get('name').errors?.['maxlength']" class="error_mess">Only 64
                  characters are allowed.</mat-error>
            </mat-form-field>
         </div>

         <div class="">
            <p class="fs_14 fsn_4 txt_lb mb-1">Mobile Number<span class="txt_d"> *</span>
              <img [src]="defualtPickupCountryFlag" name="cla" class="ms-2 c_flag_img">
            </p>
            <div class="num_country_code ic_dropdown edit_location_inputes">
              <mat-form-field appearance="none" class="frm_cunty_code">
                <div class="country_code_fld">
                  <mat-select formControlName="countryCode" (selectionChange)="EditDriverCountryCode($event.value)" class="form-control custom_input" placeholder="+1">
                    <mat-option *ngFor="let cou of countryList" [value]="cou.countryAbbr">
                    <img [src]="cou.flag" name="cla" height="16px">
                    <span class="ms-2">+{{cou.countryCode}}</span>
                    </mat-option>
                  </mat-select>
                  <mat-error *ngIf="submitted && driverEdit.get('countryCode').errors?.['required']" class="error_mess er_mess_flag_drop">Country code is required.</mat-error>
                </div>
              </mat-form-field>
              <mat-form-field appearance="none">
               <input matInput type="text" class="form-control custom_input" formControlName="phone"
                  placeholder="Enter mobile number" (keypress)="keyPress($event)" minlength="10" maxlength="10" />
               <mat-error class="error_mess" *ngIf="driverEdit.get('phone').errors?.['required']">Mobile Number is
                  required.</mat-error>
               <mat-error class="error_mess" *ngIf="driverEdit.get('phone').errors?.['maxlength']">Mobile Number should
                  be 10 digits.</mat-error>
               <mat-error class="error_mess" *ngIf="driverEdit.get('phone').errors?.['minlength']">Mobile Number should
                  be 10 digits.</mat-error>
            </mat-form-field>
            </div>
          </div>
         <p class="fs_14 fsn_4 txt_d" style="margin: -1rem 0 2rem" *ngIf="mobileemailerror">{{mobileemailerror}}?</p>
         <div class="text-center">
            <button type="submit" class="btn custum_button button_primary w-100 fs_16 fsm_5"
               style="margin: 0 0 1.5rem; height: 40px;">Update</button>
            <span class="fs_16 fsm_5 txt_lb pointer  px-4 py-2" type="submit" (click)="onNoClick()">Cancel</span>
         </div>
      </div>
   </form>
</div>

<div class="text-center" *ngIf="data.openPop ==='driverSuccess'" style="margin: 0; padding: 1rem;">
   <p class="fs_16 fsn_4 txt_lb" style="margin: 0 0 1rem; line-height: 24px;">{{data.successMeg}}</p>
   <p class="fs_16 fsn_4 txt_b" style="margin: 0 0 2rem; line-height: 24px;">{{data.driverName}}</p>
   <div class="text-center">
      <button type="submit" class="btn custum_button button_primary w-100 fs_16 fsm_5" (click)="onNoClick()"
         style="margin: 0 0 1.5rem; height: 40px; border-radius: 30px; box-shadow: none;">Done</button>
   </div>
</div>

<div class="text-center" *ngIf="data.openPop ==='InvtedSuccess'">
   <div class="text-center" style="margin: 0; padding: 1rem;">
      <span class="material-icons" style="margin: 0 0 1.5rem;font-size: 48px;color: var(--clGrna);">check_circle</span>
      <p class="fs_20 fsb_6 txt_b " style="margin: 0 0 2rem;">Congratulations</p>
      <p class="fs_16 fsn_4 txt_lb" style="margin: 0 0 2rem; line-height: 24px;">
         <span>You have added </span>
         <span class="txt_p">{{data.driverName}}</span>
         <span> in the drivers list.</span>
      </p>
      <div class="text-center">
         <button type="submit" class="btn custum_button button_success w-100 text-capitalize fs_16 fsm_5"
            (click)="successInviteDriver()"
            style="margin: 0 0 1.5rem; height: 40px; border-radius: 30px; box-shadow: none;">OK</button>
      </div>
   </div>
</div>

<div class="text-center" *ngIf="data.openPop ==='InvtedAssociateSuccess'">
   <div class="text-center" style="margin: 0; padding: 1rem;">
      <span class="material-icons" style="margin: 0 0 1.5rem;font-size: 48px;color: var(--clGrna);">check_circle</span>
      <p class="fs_20 fsb_6 txt_b " style="margin: 0 0 2rem;">Congratulations</p>
      <p class="fs_16 fsn_4 txt_lb" style="margin: 0 0 2rem; line-height: 24px;">
         <span>Invitation to </span>
         <span class="txt_b">{{data.driverName}}</span>
         <span> has been sent successfully.</span>
      </p>
      <div class="text-center">
         <button type="submit" class="btn custum_button button_success w-100 text-capitalize fs_16 fsm_5"
            (click)="successInviteDriver()"
            style="margin: 0 0 1.5rem; height: 40px; border-radius: 30px; box-shadow: none;">OK</button>
      </div>
   </div>
</div>

<div class="text-center" *ngIf="data.openPop ==='reInviteSuccess'">
   <div class="text-center" style="margin: 0; padding: 1rem;">
      <span class="material-icons" style="margin: 0 0 1.5rem;font-size: 48px;color: var(--clGrna);">check_circle</span>
      <p class="fs_20 fsb_6 txt_b " style="margin: 0 0 2rem;">Congratulations</p>
      <p class="fs_16 fsn_4 txt_lb" style="margin: 0 0 2rem; line-height: 24px;">
         <span>A reminder has been sent.</span>

      </p>
      <div class="text-center">
         <button type="submit" class="btn custum_button button_success w-100 text-capitalize fs_16 fsm_5"
            (click)="successInviteDriver()"
            style="margin: 0 0 1.5rem; height: 40px; border-radius: 30px; box-shadow: none;">OK</button>
      </div>
   </div>
</div>

<div class="" *ngIf="data.openPop ==='connectionSuccess'">
   <div class="" style="margin: 0; padding: 1rem;">
      <p class="fs_20 fsb_6 txt_b " style="margin: 0 0 2rem;">Connection Request</p>
      <p class="fs_16 fsn_4 txt_lb" style="margin: 0 0 2rem; line-height: 24px;">{{data.successMeg}}</p>
      <div class="text-center">
         <button type="submit" class="btn custum_button button_primary w-100 fs_16 fsm_5" (click)="onNoClick()"
            style="margin: 0 0 1.5rem; height: 40px; border-radius: 30px; box-shadow: none;">Done</button>

      </div>
   </div>
</div>
<!-- confirm section -->
<div *ngIf="data.openPop ==='confirm'">
   <div class="text-center" style="margin: 0; padding: 1rem;">
      <p class="fs_20 fsb_6 txt_b  line_before">Other Documents</p>
      <p class="fs_16 fsn_4 txt_lb" style="margin: 0 0 2rem; line-height: 24px;"> {{data.contain}}</p>
      <div class="text-center">
         <button type="button" class="btn custum_button button_primary w-100 fs_16 fsm_5"
            (click)="deleteRecord(data.id,data.driverId)"
            style="margin: 0 0 1.5rem; height: 40px; border-radius: 30px; box-shadow: none;">Yes</button>
         <span class="fs_16 fsm_5 txt_lb pointer  px-4 py-2" (click)="onNoClick()">Cancel</span>
      </div>
   </div>
</div>
<!-- Assign Driver-->
<div *ngIf="data.openPop ==='confirmBy'">
   <div class="header_popup">
      <p class="fs_20 fsb_6 txt_b mb-0 line_before ">Assign Driver</p>
      <span class="close_popup_Icon" (click)="onNoClick()">
         <img src="{{serverPathForUrl}}/portal/icon/ic_close_white.svg" class="close_Icon">
      </span>
   </div>
   <div class="p-3">
      <p class="fs_16 fsn_4 txt_lb mb_24">
         Assign Load id “<span class="txt_b fsm_5">#{{data.id}}</span>” to <span
            class="txt_b fsm_5">{{data.driverName}}</span> Driver ?
      </p>
      <div class="btn_action">
         <button type="button" (click)="deleteRecordBy()" class="btn custum_button button_primary btn_primary mb_16">
            {{data.buttontext}}</button>
         <span class="btn_trans txt_lb pointer" (click)="onNoClick()">
            <span *ngIf="data.buttonnew" class="">{{data.buttonnew}}</span>
            <span *ngIf="!data.buttonnew" class="">Cancel</span>
         </span>
      </div>
   </div>
</div>
<!-- confirm section -->
<div *ngIf="data.openPop ==='cdlImagesConfirm'">
   <div class="text-center cdl_front_img" style="margin: 0; padding: 1rem;">
      <p class="line_before ">CDL Documents</p>
      <p class="fs_16 fsn_4 txt_lb" style="margin: 0 0 2rem; line-height: 24px;">
         {{data.contain}}
      </p>
      <div class="text-center">
         <button type="button" class="btn custum_button button_primary w-100 fs_16 fsm_5" (click)="goToDriverList()"
            style="margin: 0 0 1.5rem; height: 40px; border-radius: 30px; box-shadow: none;"> {{data.buttontext}}
         </button>
         <span class="fs_16 fsm_5 txt_lb pointer  px-4 py-2" (click)="onNoClick()">Cancel</span>
      </div>
   </div>
</div>
<!-- confirm section -->
<div *ngIf="data.openPop ==='shipmentConfirm'">
   <div class="text-center" style="margin: 0; padding: 1rem;">
      <p class="fs_20 fsb_6 txt_b " style="margin: 0 0 2rem;"> {{data.title}}</p>
      <p class="fs_16 fsn_4 txt_lb" style="margin: 0 0 2rem; line-height: 24px;">
         Assign <span class="fsm_5 txt_b">{{data.driverName}}</span> to this shipment?
      </p>
      <div class="text-center">
         <button type="button" class="btn custum_button button_primary w-100 fs_16 fsm_5" (click)="goToDriverList()"
            style="margin: 0 0 1.5rem; height: 40px; border-radius: 30px; box-shadow: none;"> {{data.buttontext}}
         </button>
         <span class="fs_16 fsm_5 txt_lb pointer  px-4 py-2" (click)="onNoClick()">Cancel</span>
      </div>
   </div>
</div>
<!-- Success popu -->
<div class="inviteDriver_con" *ngIf="data.openPop ==='shipmentSuccess'">
   <div class="text-center" style="margin: 0; padding: 1rem;">
      <span class="material-icons" style="margin: 0 0 1.5rem;font-size: 48px;color: var(--clGrna);">check_circle</span>
      <p class="fs_20 fsb_6 txt_b " style="margin: 0 0 2rem;">Congratulations</p>
      <p class="fs_16 fsn_4 txt_lb" style="margin: 0 0 2rem; line-height: 24px;">
         <span>Load with ID </span>
         <span class="fsm_5 txt_b"> #{{data.shipmentUniqueId}}</span>
         <span>has been successfully created and assigned to</span>
         <span class="fsm_5 txt_b">{{data.driverName}}</span>.
      </p>
      <p class="fs_16 fsn_4 txt_lb" style="margin: 0 0 2rem; line-height: 24px;"> {{data.contain}}</p>
      <div class="text-center">
         <button type="submit" class="btn custum_button button_success w-100 text-capitalize fs_16 fsm_5"
            [routerLink]="['/shipment/list/pending']" (click)="onNoClick()"
            style="margin: 0 0 1.5rem; height: 40px; border-radius: 30px; box-shadow: none;">OK</button>
         <span class="fs_16 fsn_4 txt_p pointer  px-4 py-2" [routerLink]="['/shipment/view/',data.shipmentId]"
            (click)="onNoClick()">View Details</span>

      </div>
   </div>
</div>
<!-- Undo -->
<div *ngIf="data.openPop ==='undoAddress'">
   <div class="text-center" style="margin: 0; padding: 1rem;">
      <p class="fs_20 fsb_6 txt_b " style="margin: 0 0 2rem;">Canceled Shipment</p>
      <p class="fs_16 fsn_4 txt_lb" style="margin: 0 0 2rem; line-height: 24px;">{{data.contain}}</p>
      <div class="text-center">

         <button type="submit" class="btn custum_button button_success w-100 text-capitalize fs_16 fsm_5"
            (click)="onNoClick()"
            style="margin: 0 0 1.5rem; height: 40px; border-radius: 30px; box-shadow: none;">Done</button>
      </div>
   </div>
</div>

<div *ngIf="data.openPop === '10'">
   <div class="text-center popup_activity" *ngIf="disableDriver">
      <p class="fs_14 fsm_5 txt_b header_mess">Are You Sure to Disable this driver ?</p>
      <div class="text-center mt-4">
         <button type="button" class="btn custum_button button_primary me-4" (click)="confirmAtion()">Yes
            Disable</button>
         <span class="fs_10 txt_p fsm_5 pointer " (click)="onNoClickCancel()">Cancel</span>
      </div>
   </div>
</div>

<div *ngIf="data.openPop === '6'">
   <div class="text-center profile_pic_upload" *ngIf="!hideImageRemove">
      <div class="d-flex justify-content-center">
         <div class="pe-4">
            <input type="file" name="fileName" id="fileName" class="inputfile" (change)="onSeleted($event)"
               accept='image/*' style="display: none;">
            <label for="fileName" class="m-0">
               <span class="material-icons fs_20 pointer">desktop_windows</span>
            </label>
            <p class="fs_12 txt_p fsn_4">Select form your PC</p>
         </div>
         <div class="ps-4">
            <span class="material-icons fs_20 pointer" (click)="removeImage()">delete_outline</span>
            <p class="fs_12 txt_p fsn_4">Remove Photo</p>
         </div>
      </div>
      <p class="text-danger" *ngIf="imagmessage">{{imagmessage}}</p>
      <span class="fs_12 txt_p fsm_5 pointer " (click)="onNoClick()"><u>Cancel</u></span>
      <p class="img_size_validation fs_10 txt_p fsm_5 m-0"><span class="txt_d fs_12">*</span> Profile Image must be in
         500*500</p>
   </div>
   <div class="text-center popup_activity" *ngIf="hideImageRemove">
      <p class="fs_14 fsm_5 txt_b header_mess">Are you sure to remove this profile image?</p>
      <div class="text-center mt-4">
         <button type="button" class="btn custum_button button_primary me-4" (click)="removeImageYes()">Yes</button>
         <span class="fs_10 txt_p fsm_5 pointer " (click)="removeImageCancel()">Cancel</span>
      </div>
   </div>
</div>

<!-- Revoke Driver -->
<div *ngIf="data.openPop ==='driverRevoke'">
   <div class="header_popup">
      <p class="fs_20 fsb_6 txt_b mb-0 line_before ">Revoke Driver</p>
      <span class="close_popup_Icon" (click)="onNoConfirmClick()">
         <img src="{{serverPathForUrl}}/portal/icon/ic_close_white.svg" class="close_Icon">
      </span>
   </div>
   <div class="text-center">
      <p class="fs_16 fsn_4 txt_lb mb_24">Revoke this driver? "<span class="fsm_5 txt_b">{{data.driverName}}</span>"</p>
      <div class="btn_action">
         <button type="submit" class="btn custum_button button_primary btn_primary mb_16"
            (click)="onConfirmationClick()">OK</button>
         <span class="btn_trans txt_lb pointer " (click)="onNoConfirmClick()">Cancel</span>
      </div>
   </div>
</div>
<div *ngIf="data.openPop === 'uploadeDocument'" class="pdf_doc_upload">
   <form [formGroup]="addDocUpload" (ngSubmit)="uploadDocument(addDocUpload)" class="mb-0">
      <div class="header_popup">
         <p class="fs_20 fsb_6 txt_b mb-0 line_before ">Upload New Document</p>
         <span class="close_popup_Icon" (click)="onNoConfirmClick()">
            <img src="{{serverPathForUrl}}/portal/icon/ic_close_white.svg" class="close_Icon">
         </span>
      </div>
      <div class="">
         <p class="fs_12 fsn_4 txt_lb input_label">Document Name <span class="txt_d"> *</span></p>
         <mat-form-field appearance="none">
            <input matInput type="text" formControlName="description" placeholder="Enter document name"
               class="form-control custom_input" maxlength="64" />
            <mat-error class="error_mess" *ngIf="addDocUpload.get('description').errors?.['required']">Name is required.
            </mat-error>
            <mat-error *ngIf="addDocUpload.get('description').errors?.['maxlength']" class="error_mess">Only 64
               characters are allowed.</mat-error>
         </mat-form-field>
      </div>
      <div class="">
         <p class="fs_12 fsn_4 txt_lb input_label">Upload Image</p>
         <div class="upload_document">
            <mat-form-field appearance="none" class="custom_matSuffix_icon">
               <input matInput type="text" formControlName="document" value="{{name}}" placeholder="Browse image"
                  class="form-control custom_input" readonly="readonly">
               <span class="material-iconsicon">
                  <input class="inputfile" id="file" name="file" type="file" (change)="onSelectedFile($event)"
                     style="display: none;">
                  <label class="btn btn-primary py-0 mb-3" for="file"
                     style="background: #f5f5f5; border-radius: 0 4px 4px 0;">
                     <i class="fa-solid fa-upload fs_16 txt_lb"></i>
                  </label>
               </span>
            </mat-form-field>
         </div>
      </div>
      <div class="btn_action">
         <button type="submit" class="btn custum_button button_primary btn_primary mb_16" [disabled]="disbu">Add
            Document</button>
         <span class="btn_trans txt_lb pointer " (click)="onNoConfirmClick()">Cancel</span>
      </div>
   </form>
</div>
<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '14px',fullScreenBackdrop:true }"></ngx-loading>