import { ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { SharedService } from '../service/shared.service';
import { MatDialog } from '@angular/material/dialog';
import { RoutePlanComponent } from 'src/app/loads-shared/route-plan/route-plan.component';

@Injectable({
  providedIn: 'root',
})
export class PendingChangesGuard
  implements CanDeactivate<RoutePlanComponent>
{

 constructor(
   private sharedService: SharedService,
   public dialog: MatDialog,
 ){}

  canDeactivate(
    component: RoutePlanComponent,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState: RouterStateSnapshot
  ) {
    
    if (currentRoute.params['type'] === 'route-plan' && this.sharedService.getGuardValue()) {
      if (confirm('WARNING: You have unsaved changes, press Cancel and save these changes, or press OK to leave this page and discard your changes.')) {
        this.sharedService.setGuardValueFalse();
        return true;
        this.sharedService.stopTimer();
      } else {
        return false;
      }
    } else {
      this.sharedService.stopTimer();
      return true;
    }
  }

  
}
