import { GermanAddress } from '@angular-material-extensions/google-maps-autocomplete';
import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { CommonService } from 'src/app/commons/service/common.service';
import { SharedService } from 'src/app/commons/service/shared.service';
import { PopupComponent } from 'src/app/shared/popup/popup.component';
import { environment } from 'src/environments/environment';
import { AlertService } from 'src/app/commons/service/alert.service';
import { AppSettings } from 'src/app/commons/setting/app_setting';
import PlaceResult = google.maps.places.PlaceResult;

@Component({
  selector: 'app-lane-create',
  templateUrl: './lane-create.component.html',
  styleUrls: ['./lane-create.component.scss']
})
export class LaneCreateComponent implements OnInit {
  public addNewLaneWayPoint: any = {}
  public notSaved: boolean = true;
  public createNewLaneForm: FormGroup;
  public ifPickupLoadInfo: boolean = false;
  public communityDataSubmiited=false;
  public checkEmptyValue: any;
  public notVerifiedIndex=[];
  public sameLocation: boolean = false;
  public loading = false;
  public wayPoints: any;
  public usTimeZoneList = {};
  public totalMiles:any;
  public laneDetail: any;
  public timeZoneSelectedPick = '';
  public timeZoneSelectedDrop = '';
  public countryList: any;
  public defualtPickupCountryFlag: any;
  public pickupFullLocation: any;
  public pickAutoNot: boolean;
  public pickupChangeDetected = false;
  public checkErrorMessage = false;
  public pickupLocationOrder=0;
  public dropoffLocationOrder=0;
  public shortNameErrorPick: any;
  public shortNameErrorDrop: any;
  public shortNameDropValue = false;
  public deliveryLatitude: number;
  public deliveryLongitude: number;
  public disableDropOff=true;
  public pickCardArrays: any = [];
  public submitted: boolean = false;
  public dropAutoNot: boolean;
  public trailerSpecsRequipment: any = [];
  public dropoffCityError: any;
  public dropupChangeDetected = false;
  public dropShortName: any;
  public delivery: any;
  public searchRestriction: any;
  public selectedAddressData:any;
  public shortNameValue = false;
  public pickupCityError: any;
  public selectedPostalCode:any;
  public pickUp: any;
  public equipmentLengthList: any;
  public shipmentPingRate = '3';
  public equipmentListing: any;
  public pickupShortName: any;
  public disabledAssign = false;
  public dropoffFullLoaction: any;
  public pickupCity: any;
  public pickupState: any;
  public dropoffCity: any;
  public dropoffState: any;
  public totalPickups=[];
  public totaldropups=[];
  public pickupLatitude: any;
  public sequence: any;
  public pickupLongitude: any;
  public pinCode: string;
  public fequeryMonthShow : any;
  public selectedWeek : any = [];
  public laneDurations = [1,3,6,12];
  pickStreetLocation: string;
  dropStreetLocation: string;

  constructor(
    private formBuilder: FormBuilder,
    private sharedService: SharedService,
    private commonService: CommonService,
    private router: Router,
    private route: ActivatedRoute,
    private alertService: AlertService,
    public dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    let configValue = this.sharedService.getConfig();
    if (configValue !== undefined) {
      this.shipmentPingRate = configValue.onShipmentPingRate;
      this.getConfigSet(configValue);
    }
    this.commonService.configData.subscribe((userData) => {
      this.getConfigSet(userData);
    });

    this.searchRestriction = environment.searchMultipleRestriction;
    this.createNewLaneForm = this.formBuilder.group({
      laneName: ['', [Validators.required, Validators.maxLength(64),]],
      pickupLocation: ['', [Validators.required, Validators.maxLength(256), Validators.pattern(/^[^\s].*/),]],
      dropLocation: ['', [Validators.required, Validators.maxLength(256), Validators.pattern(/^[^\s].*/),]],
      equipmentType: ['', [Validators.required]],
      equipmentTypeLength: ['', this.checkEmptyValue === 'NoLength' ? [] : [Validators.required]],
      miles: ['', [Validators.maxLength(64),]],
      frequency: ['', [Validators.required]],
      frequencyWeek: [''],
      price: ['', [Validators.maxLength(10), Validators.min(1)]],
      rateType: ['1',''],
      lockPeriod: [,[Validators.required]],
      commodityForm: this.formBuilder.array([]),
      })
      this.createNewLaneForm.get('dropLocation').disable()
      if (this.route.snapshot.params['laneId']) {
        this.wayPointList();   
    }
  }

  // Function to get data from config API 
  getConfigSet(configValue: any) {
    this.trailerSpecsRequipment = configValue.shipmentTruckSpecs;
    this.equipmentListing = configValue.equipmentTypes;
    this.countryList = configValue.countries;
    this.defualtPickupCountryFlag = this.countryList[0].flag;
    this.fequeryMonthShow =configValue.laneFrequencyDays;
    for (let j = 0; j < this.fequeryMonthShow.length; j++) {
        this.fequeryMonthShow[j].isChecked = 'unchecked';
    }
  }

  // To select Weekly Frequency for create lane 
  selectWeekDays(data:any,index:any) {
    for (let v = 0; v < this.fequeryMonthShow.length; v++) {
      if (this.fequeryMonthShow[v].day === data.day && this.fequeryMonthShow[v].isChecked === 'unchecked') {
        this.fequeryMonthShow[v].isChecked = 'checked';
        this.selectedWeek.push(this.fequeryMonthShow[v].day);
        this.createNewLaneForm.get('frequencyWeek').clearValidators();
        this.createNewLaneForm.get('frequencyWeek').setValue(true);
      } else if (this.fequeryMonthShow[v].day === data.day && this.fequeryMonthShow[v].isChecked === 'checked'){
        this.fequeryMonthShow[v].isChecked = 'unchecked';
        this.selectedWeek.splice(this.selectedWeek.indexOf(data.day), 1);
        if(this.selectedWeek.length==0){
          this.createNewLaneForm.get('frequencyWeek').setValue('');
          this.createNewLaneForm.get('frequencyWeek').updateValueAndValidity();
        }
      }
    }
  }

  // API call for getting lane waypoint list
  wayPointList(){
    this.loading = true;
    let uri = null;
    let newParams = {
      laneId: this.route.snapshot.params['laneId'],
    };
    if (newParams) uri = this.commonService.getAPIUriFromParams(newParams);
    let APIparams = {
      apiKey: AppSettings.APIsNameArray.SHIPMENT.LANEWAYPOINTS,
      uri: uri,
    };
    this.commonService.getList(APIparams).subscribe(
       (ServerRes) => {
      this.wayPoints = ServerRes.response.waypoints
      this.laneRecordGet();
      })
  }

  // API call for getting lane details
  laneRecordGet() {
      this.loading = true;
      let uri = null;
      let newParams = {
        id: this.route.snapshot.params['laneId'],
      };
      if (newParams) uri = this.commonService.getAPIUriFromParams(newParams);
      let APIparams = {
        apiKey: AppSettings.APIsNameArray.SHIPMENT.LANEGET,
        uri: uri,
      };
      this.commonService.getList(APIparams).subscribe(
        async (ServerRes) => {
          if (ServerRes.success === true) {
           this.laneDetail = ServerRes.response;
 
          this.laneDetail.waypoints = this.wayPoints
          const equipmentType = this.equipmentListing?.filter(
            (item) => item.label ==this.laneDetail.equipmentName
          );
         let equipmentLength; 
          if (equipmentType) {
            const mapped = Object.keys(equipmentType[0].length).map((key) => ({
              lengthKey: key,
              lengthValue: equipmentType[0].length[key],
            }));
            this.equipmentLengthList = mapped;
      
            equipmentLength = this.equipmentLengthList.filter(
              (item) => item.lengthKey ==this.laneDetail.equipmentType ? item : this.equipmentLengthList[0]
            );
          }
      this.createNewLaneForm = this.formBuilder.group({
      laneName: [this.laneDetail.title, [Validators.required, Validators.maxLength(64),]],
      pickupLocation: [this.laneDetail.waypoints[0].locationLongName, [Validators.required, Validators.maxLength(256), Validators.pattern(/^[^\s].*/),]],
      dropLocation: [this.laneDetail.waypoints[this.laneDetail.waypoints.length - 1].locationLongName, [Validators.required, Validators.maxLength(256), Validators.pattern(/^[^\s].*/),]],
      equipmentType: [equipmentType[0]?.id, [Validators.required]],
      equipmentTypeLength: [equipmentLength[0].lengthValue , [Validators.required]],
      miles: [(this.laneDetail.miles), [Validators.maxLength(64),]],
      frequency: [this.laneDetail.monthlyFrequency, [Validators.required]],
      price: [this.laneDetail.estimatedPrice, [Validators.maxLength(64), Validators.min(1)]],
      rateType: [this.laneDetail.rateType],
      lockPeriod:[this.laneDetail.lockPeriod, [Validators.required]],
      commodityForm: this.formBuilder.array([]),
      })

      if (this.laneDetail.waypoints[0].commodity && this.laneDetail.waypoints[0].commodity.length > 0) {
        this.waypointEditFormArray(this.laneDetail.waypoints[0].commodity);
        this.ifPickupLoadInfo = true;
      } else {
        this.ifPickupLoadInfo = false;
      }

      this.selectedWeek = []
      this.selectedWeek = this.laneDetail?.frequencyDays;

      if(this.laneDetail.frequencyDays)
     { 
      for (let j = 0; j < this.fequeryMonthShow?.length; j++) {
        for (let i = 0; i < this.laneDetail?.frequencyDays?.length; i++) 
        {
          if(this.laneDetail.frequencyDays[i]== this.fequeryMonthShow[j].day)
          this.fequeryMonthShow[j].isChecked = 'checked';
        }
    }}

      for(let i=0; i < this.laneDetail.waypoints.length ; i++)
      {
        let data = {
          locationLongName : this.pickupFullLocation,
          cityState: this.pickupCity + ',' + this.pickupState,
          zip:this.laneDetail.waypoints[i]?.zip,
          locality:{long:this.laneDetail.waypoints[i]?.city},
          state:{long:this.laneDetail.waypoints[i]?.state},
          locationOrder:this.laneDetail.waypoints[i]?.locationOrder,
          countryName:this.laneDetail.waypoints[i]?.countryName ? this.laneDetail.waypoints[i]?.countryName : 'US',
          sequence:i,
          id:this.laneDetail.waypoints[i]?.id ? this.laneDetail.waypoints[i]?.id : '',
          geoLocation : {latitude : this.laneDetail.waypoints[i]?.latitude,longitude :this.laneDetail.waypoints[i]?.longitude},
        }

        if(this.laneDetail.waypoints[i].type == 'pickup')
        {
          this.pickupFullLocation =this.laneDetail.waypoints[i]?.locationLongName
          this.pickupCity =this.laneDetail.waypoints[i]?.city
          this.pickupState =this.laneDetail.waypoints[i]?.state
          this.totalPickups.push(this.laneDetail.waypoints[i])
          this.pickupLocationOrder = this.laneDetail.waypoints[i]?.locationOrder;
          this.selectedPostalCode=data.zip;
          this.getPickCardDetails(data)
        }
        else 
        {
          this.totaldropups.push(this.laneDetail.waypoints[i])
          this.dropoffFullLoaction =this.laneDetail.waypoints[i]?.locationLongName
          this.dropoffCity =this.laneDetail.waypoints[i]?.city
          this.dropoffState =this.laneDetail.waypoints[i]?.state
          this.dropoffLocationOrder = this.laneDetail.waypoints[i]?.locationOrder
          this.selectedPostalCode=data.zip;
          this.getDropCardDetails(data)
        }
      }
     
        this.loading = false;
          } else {
            this.loading = false;
          }
        },
        (error) => {
          this.loading = false;
        }
      );
  }

  // To edit commodity details 
  waypointEditFormArray(item) {
    const formArray = new FormArray([]);
    for (let record of item) {
      formArray.push(this.formBuilder.group({
        commodity: [record.commodity, [Validators.required,Validators.maxLength(128)]],
        weight: [record.weight, ],
        handleWithCare:[record.handleWithCare ? record.handleWithCare : false],
      }));
    }
    this.createNewLaneForm.setControl('commodityForm', formArray);
  }

 // Get required payload for waypoint
  payLoadWaypoints(data){
    let newDataobject = {     
    "latitude": data.latitude,
    "longitude": data.longitude,
    "locationLongName": data.locationLongName,
    "city": data.city,
    "state": data.state,
    "type": data.type,
    "zip":  data.zip ? data.zip.toString() : data.zip,
    "sequence": data.sequence,
    "locationOrder": data.locationOrder,
    "commodity":data.commodity,
    "countryName":data.countryName,
}
Object.keys(newDataobject).forEach(key => {
  if (newDataobject[key] === null || newDataobject[key] === '') {
    delete newDataobject[key];
  }
});
return newDataobject;
}

// To check route between origin to destination and form validation
  checkRoute({ value, valid }){
    this.submitted=true;
    this.communityDataSubmiited=true;
      this.pickCardArrays[0].commodity = []; 
      if(this.commodityForm.length > 0){
        for (let i =0; i< this.commodityForm.length; i++) {
          const commodityElementForm = this.commodityForm.at(i);
          if (commodityElementForm.valid) {
              var commodityElement = {
                commodity : commodityElementForm.get('commodity').value,
                weight : commodityElementForm.get('weight').value,
                handleWithCare : commodityElementForm.get('handleWithCare').value ? commodityElementForm.get('handleWithCare').value : false,
                type: "pickup"
              }
              this.pickCardArrays[0].commodity.push(commodityElement);
          } 
        }
     }
    if (valid && this.pickCardArrays[this.pickCardArrays.length - 1].wayType == 'drop') {
    const dialogRef = this.dialog.open(PopupComponent, {
      disableClose: true,
      backdropClass: AppSettings.backdropClass,
      width:'1100px',
      data: {
        openPop: 'routeVerify',
        contain: this.pickCardArrays
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
   
        if(result.event.status == 'Ok')
       { ;
        this.totalMiles = Math.ceil(result.event.miles/1609.344)
        this.laneFormSubmit({ value, valid })
      }
       else 
       {

        for(let i=0; i <= result.event.length ; i++)
        {
          this.notVerifiedIndex[i]='ok' 
        }

        for(let i=0; i < result.event.length; i ++)
        {
        {
          if (i == 0 && result.event[i]!='Verified' && result.event.length == 1)
          {
            this.notVerifiedIndex[i] = 'error'
            this.notVerifiedIndex[i+1] = 'error'
          }
          else if (i == 0 && result.event[i]!='Verified' && result.event[i+1]=='Verified')
          {
            this.notVerifiedIndex[i] = 'error'
          }
          else if (i == 0 && result.event[i]=='Verified'  && result.event[i+1]!='Verified')
          {
            this.notVerifiedIndex[i+2] = 'error'
          }
        
            else if(i != result.event.length-1 && i > 0 && result.event[i]!='Verified' && result.event[i+1] == 'Verified' && result.event[i-1] == 'Verified')
          {
            this.notVerifiedIndex[i+1]='error'
         }
         else if (i != result.event.length-1 && i > 0 && result.event[i]!='Verified' && result.event[i+1] == 'Verified' && result.event[i-1] != 'Verified')
         {
          this.notVerifiedIndex[i]='error'
         }
         else if (i != result.event.length-1 && i > 0 && result.event[i]!='Verified' && result.event[i+1] != 'Verified' && result.event[i-1] == 'Verified')
         {
          this.notVerifiedIndex[i+1]='error'
         }
         else if (i != result.event.length-1 && i > 0 && result.event[i]!='Verified' && result.event[i+1] == 'Verified' && result.event[i-1] != 'Verified')
         {
          this.notVerifiedIndex[i]='error'
         }
         else if (i != result.event.length-1 && i > 0 && result.event[i]!='Verified' && result.event[i+1] != 'Verified' && result.event[i-1] != 'Verified')
         {
          this.notVerifiedIndex[i]='error'
          this.notVerifiedIndex[i+1]='error'
         }
         else if (i == result.event.length-1 && i > 0 && (result.event[i]!='Verified' && result.event[i-1] == 'Verified'))
         {
          this.notVerifiedIndex[i+1]='error'
         }
         else if (i == result.event.length-1 && i > 0 && (result.event[i]!='Verified' && result.event[i-1] != 'Verified'))
         {
          this.notVerifiedIndex[i+1]='error'
         }
         else 
         {
          this.notVerifiedIndex[i+1]='ok'

         }
        }
       }
       }
       
      }
    });
  }
  }

  // Submit Lane form data for creating new lane
   laneFormSubmit({ value, valid }) {
    this.loading = true;
    this.submitted=true;
    this.communityDataSubmiited=true;
    this.pickCardArrays[0].commodity = []; 
    for (let i =0; i< this.commodityForm.value.length; i++) {
      const commodityElementForm = this.commodityForm.at(i);
      if (commodityElementForm.valid) {
           var commodityElement = {
            commodity : commodityElementForm.get('commodity').value,
            weight : commodityElementForm.get('weight').value,
            handleWithCare : commodityElementForm.get('handleWithCare').value ? commodityElementForm.get('handleWithCare').value : false,
            type: "pickup"
           }
           this.pickCardArrays[0].commodity.push(commodityElement);
    }}
    if (valid && this.pickCardArrays[this.pickCardArrays.length - 1].wayType == 'drop') {
      this.submitted=false;
      this.addNewLaneWayPoint.waypoints=[]

      for(let i =0; i< this.pickCardArrays.length; i++)
     {
       this.addNewLaneWayPoint.waypoints.push(this.payLoadWaypoints(this.pickCardArrays[i]))
      }

      if (this.createNewLaneForm.get('laneName').value) {
        this.addNewLaneWayPoint.title = this.createNewLaneForm.get('laneName').value;
      }
      if (this.createNewLaneForm.get('miles').value) {
        this.addNewLaneWayPoint.miles = this.createNewLaneForm.get('miles').value;
      }

      this.addNewLaneWayPoint.monthlyFrequency = this.createNewLaneForm.get('frequency').value ? this.createNewLaneForm.get('frequency').value : '';
   
      if (this.createNewLaneForm.get('price').value) {
      this.addNewLaneWayPoint.estimatedPrice = this.createNewLaneForm.get('price').value;
      }
      
      this.addNewLaneWayPoint.equipmentType = this.createNewLaneForm.get('equipmentType').value ? this.createNewLaneForm.get('equipmentType').value : '';
      this.addNewLaneWayPoint.equipmentLength = this.createNewLaneForm.get('equipmentTypeLength').value ? this.createNewLaneForm.get('equipmentTypeLength').value : '';
      this.addNewLaneWayPoint.rateType = this.createNewLaneForm.get('rateType').value ? this.createNewLaneForm.get('rateType').value : '1';
      this.addNewLaneWayPoint.lockPeriod=this.createNewLaneForm.get('lockPeriod').value;
      this.addNewLaneWayPoint.miles = this.totalMiles
  
      if(this.selectedWeek && this.selectedWeek.length>0){
        this.addNewLaneWayPoint.frequencyDays = this.selectedWeek;
      }
      
      let APIparams = {
        apiKey: AppSettings.APIsNameArray.SHIPMENT.CREATELANES,
        uri: '',
        postBody: this.addNewLaneWayPoint,
      };
      this.commonService.post(APIparams).pipe(finalize(() => {
            // this.saving = false;
          })
        )
        .subscribe(
          (success) => {
            if (success.success === true) {
                this.loading=false;
                const dialogRef = this.dialog.open(PopupComponent, {
                  disableClose: true,
                  backdropClass: AppSettings.backdropClass,
                  width:'601px',
                  data: {
                    openPop: 'verifiedRoute',
                    id:success.response.uniqueId
                  },
                });
                dialogRef.afterClosed().subscribe((result) => {
                  if (result) {
                   
                  }
                });
            
              // this.loading = false;
            } else if (success.success === false) {
              this.loading=false;
              if (success.error) {
                this.alertService.showNotificationMessage(
                  'danger',
                  'bottom',
                  'right',
                  'txt_d',
                  'cancel',
                  'Create Order Fail',
                  AppSettings.CARRIER_CREATE_SHIPMENT_ERROR
                );
              }

            }
          },
          (error) => {
            this.loading=false;
            if (error.error.forceCarRoute && error.error.forceCarRoute === true) {
              this.alertService.showNotificationMessage(
                'danger',
                'bottom',
                'right',
                'txt_d',
                'cancel',
                'Shipment Create Fail',
                error.error.message
              );
            } else if (error.error.forceCarRoute === false) {
              this.alertService.showNotificationMessage(
                'danger',
                'bottom',
                'right',
                'txt_d',
                'cancel',
                'Shipment Create Fail',
                error.error.message
              );
            } else {
              this.alertService.showNotificationMessage(
                'danger',
                'bottom',
                'right',
                'txt_d',
                'cancel',
                'Shipment Create Fail',
                error.error.message
              );
            }
          }
        );
    }
  }

  // To get commodity form as a FormArray
  get commodityForm() {
    return this.createNewLaneForm.get('commodityForm') as FormArray;
  }

  // To add commodity detail
  addLoadInformation() {
    this.ifPickupLoadInfo = true;
      let control = <FormArray>this.createNewLaneForm.controls.commodityForm;
      if(control.length===0){
        this.addCommunityField();
    }
  }

  // To add multiple commodity detail
  addCommunityField() {
    this.communityDataSubmiited=false;
    let control = <FormArray>this.createNewLaneForm.controls.commodityForm;
    control.push(
      this.formBuilder.group({
        commodity: ['', [Validators.required,Validators.maxLength(128)]],
        weight: ['', ],
        handleWithCare:[false],
      }))
      this.createNewLaneForm.setControl('commodityForm', control);   
  }

  // Remove commodity field  
  removeCommunityField(index:any) {
    let control = <FormArray>this.createNewLaneForm.controls.commodityForm;
    control.removeAt(index);
    if(control.length===0){
      this.ifPickupLoadInfo=false;
    }
  }

  // To select equipmentLength according to it's equipmentType 
  selectEquipmentLength(event) {
    const equipmentLength = this.equipmentListing.filter(
      (item) => item.id === event.value
    );
    if (event.value === 9) {
      this.checkEmptyValue = '0';
      this.createNewLaneForm.get('equipmentTypeLength').setValue(this.checkEmptyValue);
    } else {
      this.checkEmptyValue = '';
      this.createNewLaneForm
        .get('equipmentTypeLength')
        .setValue(this.checkEmptyValue);
      if (equipmentLength) {
        const mapped = Object.keys(equipmentLength[0].length).map((key) => ({
          lengthKey: key,
          lengthValue: equipmentLength[0].length[key],
        }));
        this.equipmentLengthList = mapped;
      }
    }
  }

  // To select pickup location by google autocomplete.
  pickUpLocAuto($event: GermanAddress) {
    this.selectedAddressData = $event;

    // this.sameLocation = false;
  

  }

  // To set values and validations after autocomplete for pickup/dropOff location 
  pickAutoCompleteSelected(result: PlaceResult) {
    let checklocality=false;
    this.createNewLaneForm.get('pickupLocation').setValue(result.formatted_address);
    this.pickupFullLocation = result.formatted_address;
    this.pickAutoNot = false;
    this.pickupChangeDetected = false;
    
    this.pickupLatitude = result.geometry.location.lat();
    this.pickupLongitude = result.geometry.location.lng();
  
    for (let i = 0; i < result.address_components.length; i++) {
      if (result.address_components[i].types[0] == "locality" || result.address_components[i].types[1] == "sublocality") {
        if (result.address_components[i].long_name) {
          this.shortNameErrorPick = '';
          this.shortNameValue = false;
        } 
        this.pickStreetLocation = result.address_components[i].long_name;
        checklocality=true; 
      }
      else if(result.address_components[i].types[0] == "administrative_area_level_2"){
        this.pickupCity = result.address_components[i].long_name;
      }
      else if(result.address_components[i].types[0] == "administrative_area_level_1"){
        this.pickupState = result.address_components[i].long_name;
      }
      else if(result.address_components[i].types[0] == "country"){
        for (let x in this.usTimeZoneList) {
          if (result.address_components[i].short_name === x) {
            this.timeZoneSelectedPick = this.usTimeZoneList[x];
          }
        }
      
      }
      else if(result.address_components[i].types[0] == "postal_code"){
        this.pinCode = result.address_components[i].long_name;
        if (result.address_components[i].long_name) {
          this.selectedPostalCode = result.address_components[i].long_name;
        }
       else{
        if(!checklocality){
          this.shortNameErrorPick = 'Please select city level address.';
          this.shortNameValue = true;
        }
        }
       
      }
    }
    if (this.pickupCity !== undefined) {
      this.pickupShortName = this.pickupCity;
    } else {
      if (this.pickupState) {
        this.pickupShortName = this.pickupState;
      } else {
        this.pickupShortName = '';
      }
    }
    if (this.pickupShortName !== '') {
      this.pickupShortName = this.pickupShortName + ', ';
    }
    if (this.pickupState !== undefined) {
      this.pickupShortName = this.pickupShortName + this.pickupState;
    }
    if(checklocality){
      this.pickupLocationOrder = this.pickupLocationOrder + 1;
      this.getPickCardDetails(this.selectedAddressData);
    }
    this.pickupCityError = '';
    if (this.disabledAssign || this.shortNameValue) {
      this.checkErrorMessage = true;
    } else {
      this.checkErrorMessage = false;
    }
    if (this.shortNameValue === true || this.shortNameDropValue === true) {
      this.checkErrorMessage = true;
    }
   if(this.pickCardArrays[0]?.wayType == 'pick')
   {
    this.createNewLaneForm.get('pickupLocation').setValidators([ Validators.maxLength(256), Validators.pattern(/^[^\s].*/)])
   }
   else 
   {
    this.createNewLaneForm.get('pickupLocation').setValidators([Validators.required, Validators.maxLength(256), Validators.pattern(/^[^\s].*/)])
   }
  }
  
  // To set values in cards after pickup location select 
  getPickCardDetails(data){
    let pickDetails: any = {};
    pickDetails = {
      wayType:'pick',
      title: "Pickup Point",
      type:'pickup',
      locationLongName : this.pickupFullLocation,
      streetAdress: this.pickStreetLocation,
      cityState: this.pickupCity + ', ' + this.pickupState,
      zip:this.selectedPostalCode,
      city: data.locality.long ? data.locality.long : data.sublocality,
      state:data.state.long,
      countryName:(data?.country?.short ? data?.country?.short :'US'),
      sequence:this.pickCardArrays.length+1,
      locationOrder: this.route.snapshot.params['laneId'] ? this.pickupLocationOrder : this.totalPickups.length+1,
      latitude : data.geoLocation.latitude,
      longitude : data.geoLocation.longitude
    }

    Object.keys(pickDetails).forEach(key => {
      if (!pickDetails[key] || pickDetails[key] === null || pickDetails[key] === '') {
        delete pickDetails[key];
      }
    });

  this.selectedPostalCode=''; 
  this.totalPickups.push(pickDetails);
  this.pickCardArrays.push(pickDetails); 
  if(this.pickCardArrays.length)
  {
    this.disableDropOff= false;
    this.createNewLaneForm.get('dropLocation').enable()
  }
  }

  // To select dropOff location by google autocomplete.
  dropOffLocAuto($event: GermanAddress) {
    this.selectedAddressData = $event;
    for (let x in this.usTimeZoneList) {
      if ($event.state.short === x) {
        this.timeZoneSelectedDrop = this.usTimeZoneList[x];
      }
    }
    this.createNewLaneForm.get('dropLocation').setValue($event.displayAddress);
    this.dropoffFullLoaction = $event.displayAddress;
    this.dropStreetLocation = $event.vicinity;
    this.dropoffCity = $event.locality.long ? $event.locality.long : $event.sublocality;
    this.dropoffState = $event.state.long;
    this.deliveryLatitude = $event.geoLocation.latitude;
    this.deliveryLongitude = $event.geoLocation.longitude;
    this.sameLocation = false;
    this.dropAutoNot = false;
    this.dropupChangeDetected = false;
    if (this.dropoffCity !== undefined) {
      this.dropShortName = this.dropoffCity;
    } else {
      if (this.dropoffState) {
        this.dropShortName = this.dropoffState;
      } else {
        this.dropShortName = '';
      }
    }
    if (this.dropShortName !== '') {
      this.dropShortName = this.dropShortName + ', ';
    }
    if (this.dropoffState !== undefined) {
      this.dropShortName = this.dropShortName + this.dropoffState;
    }
  }

  // To set values and validations after autocomplete for dropOff location 
  dropAutoCompleteSelected(result: PlaceResult) {
    let checklocalityDrop=false;
    for (let i = 0; i < result.address_components.length; i++) {
      if (result.address_components[i].types[0] == "locality" || result.address_components[i].types[1] == "sublocality") {
        if (result.address_components[i].long_name) {
          this.shortNameErrorDrop = '';
          this.shortNameValue = false;
        } 
        checklocalityDrop=true; 
      }else if (result.address_components[i].types[0] == "postal_code") {
        if (result.address_components[i].long_name) {
          this.selectedPostalCode = result.address_components[i].long_name;
        }
       break;
      } else{
        if(!checklocalityDrop){
          this.shortNameErrorDrop = 'Please select city level address.';
          this.shortNameValue = true;

        }
      }
    }
    if(checklocalityDrop){
      this.dropoffLocationOrder = this.dropoffLocationOrder+1;
      this.getDropCardDetails(this.selectedAddressData);
    }
   
    if (this.disabledAssign || this.shortNameValue) {
      this.checkErrorMessage = true;
    } else {
      this.checkErrorMessage = false;
    }
    if (this.shortNameValue === true || this.shortNameDropValue === true) {
      this.checkErrorMessage = true;
    }
    if(this.pickCardArrays[this.pickCardArrays.length-1]?.wayType == 'drop')
    {
     this.createNewLaneForm.get('dropLocation').setValidators([ Validators.maxLength(256), Validators.pattern(/^[^\s].*/)])
    }
    else 
    {
     this.createNewLaneForm.get('dropLocation').setValidators([Validators.required, Validators.maxLength(256), Validators.pattern(/^[^\s].*/)])
    }
  
  }

  // To set values in cards after dropOff location select 
  getDropCardDetails(data){
    let dropDetails: any = {};
    dropDetails = {
      wayType:'drop',
      title: "Dropoff Point",
      type:'dropoff',
      locationLongName : this.dropoffFullLoaction,
      streetAdress: this.dropStreetLocation,
      cityState: this.dropoffCity + ', ' + this.dropoffState,
      zip: this.selectedPostalCode,
      city: data.locality.long ? data.locality.long : data.sublocality,
      state:data.state.long,
      countryName:(data?.country?.short ? data?.country?.short :'US'),
      sequence:this.pickCardArrays.length+1,
      locationOrder: this.route.snapshot.params['laneId'] ? this.dropoffLocationOrder : this.totaldropups.length+1,
      latitude : data.geoLocation.latitude,
      longitude : data.geoLocation.longitude
    }
    Object.keys(dropDetails).forEach(key => {
      if (!dropDetails[key] || dropDetails[key] === null || dropDetails[key] === '') {
        delete dropDetails[key];
      }
    });
    this.selectedPostalCode=''; 
    this.totaldropups.push(dropDetails)
    this.pickCardArrays.push(dropDetails); 
  }

// To get time in milliseconds for change detection 
 sleep(milliseconds) {
  const date = Date.now();
  let currentDate = null;
  do {
    currentDate = Date.now();
  } while (currentDate - date < milliseconds);
}

// Function for change detection in pickup/dropOff
noAutoselection(loc: any) {
  if (loc === 'pick') {
    this.pickupChangeDetected = true;
  }
  if (loc === 'drop') {
    this.dropupChangeDetected = true;
  }
}

// Function for change detection in pickup/dropOff auto complete
noFocusOut(pickdrop: any) {
  this.sleep(500);
  if (pickdrop === 'pick' && this.pickupChangeDetected) {
    this.pickAutoNot = true;
  }
  if (pickdrop === 'drop' && this.dropupChangeDetected) {
    this.dropAutoNot = true;
  }
}

// To remove added card
removeWayPointCard(index: any, type:any,locationOrder:any) {
  const dialogRef = this.dialog.open(PopupComponent, {
    disableClose: true,
    backdropClass: AppSettings.backdropClass,
    width: AppSettings.popWidth,
    data: {
      openPop: 'removeLocation',
      index: index,
      type:type,
      locationOrder:locationOrder,
    },
  });
  dialogRef.afterClosed().subscribe((result) => {
    if (result.event == 'cardRemoveSuccessFully') {
      this.pickCardArrays.splice(index, 1);
      if(type==='pickup'){
        this.totalPickups.length = this.totalPickups.length-1
      }else if(type==='dropoff'){
        this.totaldropups.length = this.totaldropups.length-1
      }
      this.checkUpdateLocationUpdate();
    }
  });
}

// Loction update after waypoint card removal 
checkUpdateLocationUpdate(){
  this.dropoffLocationOrder = 0;
  this.pickupLocationOrder = 0;
  this.sequence=0;
  for (let i = 0; i < this.pickCardArrays.length; i++){
    this.sequence = this.sequence + 1;   
    if(this.pickCardArrays[i].type==='pickup'){
      this.pickupLocationOrder=this.pickupLocationOrder + 1;
      this.pickCardArrays[i].locationOrder = this.pickupLocationOrder;
      this.pickCardArrays[i].sequence = this.sequence;
    }     
    if(this.pickCardArrays[i].type==='dropoff'){
    this.dropoffLocationOrder=this.dropoffLocationOrder + 1;    
    this.pickCardArrays[i].locationOrder= this.dropoffLocationOrder;  
    this.pickCardArrays[i].sequence = this.sequence;

    }     
  }
}

// Select Rate type Quote/Bid Rate or Fixed Rate
changeRateType(event: any) {
  if (event.value == '1') {
    this.createNewLaneForm.get('rateType').setValue("1");
    this.createNewLaneForm.get('price').setValidators([Validators.maxLength(10), Validators.min(1)])
    this.createNewLaneForm.get('price').setValue(null)
  } else {
    this.createNewLaneForm.get('rateType').setValue("2");
    this.createNewLaneForm.get('price').setValidators([Validators.required,Validators.maxLength(10), Validators.min(1)])
    this.createNewLaneForm.get('price').setValue(null)
  }
}

// For adding multiple pickup and dropOff location 
addMoreLocations(event){
  if (event == 'pick'){
    this.createNewLaneForm.get('pickupLocation').setValue('');
  }
  if (event == 'drop'){
    this.createNewLaneForm.get('dropLocation').setValue('');
  }
}

// For edit waypoint card details 
editWayPointCard(waypoint, index){  
  const dialogRef = this.dialog.open(PopupComponent, {
  disableClose: true,
  backdropClass: AppSettings.backdropClass,
  width: '600px',
  data: {
    openPop: 'editLaneLocation',
    index: index,
    title:waypoint.locationLongName
  },
});
dialogRef.afterClosed().subscribe((result) => {
  this.updateEditedLanes(result.event.data, result.event.zipCode, waypoint,index)
});
}

// To update pickup and dropOff details 
updateEditedLanes(data,zipCode,waypoint,index){
  if(waypoint.wayType == 'drop')
  {
    this.dropoffFullLoaction = data.displayAddress;
    let dropDetails: any = {};
    this.dropoffCity =data.locality.long;
    this.dropoffState = data.state.long;
  dropDetails = {
    wayType:'drop',
    title: "Dropoff Point",
    type:'dropoff',
    locationLongName : this.dropoffFullLoaction,
    streetAdress: this.dropStreetLocation,
    cityState: this.dropoffCity + ', ' + this.dropoffState,
    zip: zipCode,
    city:data.locality.long,
    state:data.state.long,
    sequence:waypoint.sequence,
    countryName:(data?.country?.short ? data?.country?.short :'US'),
    locationOrder:waypoint.locationOrder,
    latitude : data.geoLocation.latitude,
    longitude : data.geoLocation.longitude
  }
  Object.keys(dropDetails).forEach(key => {
    if (dropDetails[key] === null || dropDetails[key] === '') {
      delete dropDetails[key];
    }
  });
  this.totaldropups[ waypoint.locationOrder]=dropDetails;
  this.pickCardArrays[index] = dropDetails; 
  if(index == this.pickCardArrays.length -1 || index == this.pickCardArrays.length -2 )
{
  this.createNewLaneForm.get('dropLocation').setValue(data.displayAddress)
}
}else if (waypoint.wayType == 'pick')
  {
    this.pickupFullLocation = data.displayAddress;
    let pickDetails: any = {};
    this.pickupCity =data.locality.long;
    this.pickupState = data.state.long;

  pickDetails = {
    wayType:'pick',
    title: "Pickup Point",
    type:'pickup',
    locationLongName : this.pickupFullLocation,
    streetAdress: this.pickStreetLocation,
    cityState: this.pickupCity + ', ' + this.pickupState,
    zip:zipCode,
    city:data.locality.long,
    state:data.state.long,
    sequence:waypoint.sequence,
    locationOrder:waypoint.locationOrder,
    countryName:(data?.country?.short ? data?.country?.short :'US'),
    latitude : data.geoLocation.latitude,
    longitude : data.geoLocation.longitude
  }
  Object.keys(pickDetails).forEach(key => {
    if (pickDetails[key] === null || pickDetails[key] === '') {
      delete pickDetails[key];
    }
  });
  this.totalPickups[pickDetails.locationOrder]=pickDetails;
  this.pickCardArrays[index]=pickDetails; 
  if(index == this.pickCardArrays.length -1 || index == this.pickCardArrays.length -2 ){
   this.createNewLaneForm.get('pickupLocation').setValue(data.displayAddress)
  }
 }
}

// Move back to lane list page
moveBack()
{
  this.router.navigateByUrl('/booking/lane/postedLanes');
}

// To set value for duration of lane
durationSelection(selection)
{
 this.createNewLaneForm.get('lockPeriod').setValue(selection.value)
}


}

