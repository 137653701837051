import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../commons/guard/auth.guard';
import { PendingChangesGuard } from '../commons/guard/pending-changes.guard';
import { ActiveLanesComponent } from './active-lanes/active-lanes.component';
import { CreateLanesComponent } from './create-lanes/create-lanes.component';
import { ConfirmedRateHistoryComponent } from './confirmed-rate-history/confirmed-rate-history.component';
import { LaneViewComponent } from './lane-view/lane-view.component';
import { EditLaneComponent } from './edit-lane/edit-lane.component';
import { LaneCreateComponent } from './lane-create/lane-create.component';
import { ConfirmLaneListComponent } from './confirm-lane-list/confirm-lane-list.component';
import { LoadByLaneComponent } from './load-by-lane/load-by-lane.component';

const routes: Routes = [
  {
    path: 'recurring-lanes/create/shipper',
    component: LaneCreateComponent,
    canActivate: [AuthGuard],
    canDeactivate: [PendingChangesGuard],
  },
  {
    path: 'recurring-lanes/create/shipper/:laneId',
    component: LaneCreateComponent,
    canActivate: [AuthGuard],
    canDeactivate: [PendingChangesGuard],
  },
  {
    path: 'recurring-lanes/confirm-list',
    component: ConfirmLaneListComponent,
    canActivate: [AuthGuard],
    canDeactivate: [PendingChangesGuard],
  },
  {
    path: 'recurring-lanes/create/load/:laneId',
    component: LoadByLaneComponent,
    canActivate: [AuthGuard],
    canDeactivate: [PendingChangesGuard],
  },
  {
    path: 'recurring-lanes/active-lanes/:tabType',
    component: ActiveLanesComponent,
    canActivate: [AuthGuard],
    canDeactivate: [PendingChangesGuard],
  },
  {
    path: 'recurring-lanes/active-lanes/:tabType', children:[{path:'view', loadChildren: () =>
      import('../load/pages/view/view.module').then((mod) => mod.ViewModule)}]
  },
  {
    path: 'recurring-lanes/:available-lanes/:tabType',
    component: CreateLanesComponent,
    canActivate: [AuthGuard],
    canDeactivate: [PendingChangesGuard],
  },

  {
    path: 'recurring-lanes/edit/shipper/:laneId',
    component: EditLaneComponent,
    canActivate: [AuthGuard],
    canDeactivate: [PendingChangesGuard],
  },
 
  {
    path: 'recurring-lanes/create-lanes/:tabType',
    children:[{path:'view/:laneId/:type',component: LaneViewComponent,
    canActivate: [AuthGuard],
    canDeactivate: [PendingChangesGuard],}]
  },
  {
    path: 'create-lanes/:tabType',
    children:[{path:'load/view', loadChildren: () =>
      import('../load/pages/view/view.module').then((mod) => mod.ViewModule)}]
  },

  {
    path: 'recurring-lanes/available-lanes/:tabType',
    children:[{path:'view/:laneId/:type',component: LaneViewComponent,
    canActivate: [AuthGuard],
    canDeactivate: [PendingChangesGuard],}]
  },
  {
    path: 'available-lanes/:tabType',
    children:[{path:'load/view', loadChildren: () =>
      import('../load/pages/view/view.module').then((mod) => mod.ViewModule)}]
  },

  {
    path: 'recurring-lanes/active-lanes/inProgressByLane',
    children:[{path:'load/view', loadChildren: () =>
      import('../load/pages/view/view.module').then((mod) => mod.ViewModule)}]
  },
  {
    path: 'active-lanes/:tabType',
    children:[{path:'load/view', loadChildren: () =>
      import('../load/pages/view/view.module').then((mod) => mod.ViewModule)}]
  },

  {
    path: 'recurring-lanes/:create-lanes/:tabType',
    component: CreateLanesComponent,
    canActivate: [AuthGuard],
    canDeactivate: [PendingChangesGuard],
  },
  {
    path: 'recurring-lanes/:tabType',
    component: ConfirmedRateHistoryComponent,
    canActivate: [AuthGuard],
    canDeactivate: [PendingChangesGuard],
  },
  {
    path: 'recurring-lanes/:tabType',
    children:[{path:'view/:laneId/:type',component: LaneViewComponent,
    canActivate: [AuthGuard],
    canDeactivate: [PendingChangesGuard],}]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class RecurringLanesRoutingModule { }
