import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { CommonService } from '../../commons/service/common.service';
import { AppSettings } from '../../commons/setting/app_setting';
import { AlertService } from '../../commons/service/alert.service';
import { SharedService } from '../../commons/service/shared.service';
import { Router } from '@angular/router';
export interface DialogData {
  type: any;
}

@Component({
  selector: 'app-refresh-popup',
  templateUrl: './refresh-popup.component.html',
  styleUrls: ['./refresh-popup.component.css'],
})
export class RefreshPopupComponent implements OnInit {
  public loading = false;
  constructor(
    private commonService: CommonService,
    public dialog: MatDialog,
    private router: Router,
    private alertService: AlertService,
    public sharedService: SharedService,
    public dialogRef: MatDialogRef<RefreshPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) { }

  ngOnInit() {
    this.sharedService.closePopupMultipleTime.subscribe((userData) => {
      this.dialogRef.close();
    });
  }

  logOutFun() {
    this.sharedService.changeFirebaseToken.next(null);
    if (localStorage.getItem('uniqueId') && localStorage.getItem('fcmToken')) {
      this.deleteDeviceTokan();
    }
    localStorage.clear();
    sessionStorage.clear();
    this.router.navigate(['/']);
    this.dialogRef.close();
    this.sharedService.configLoginCallUrl.next(null);
    this.sharedService.setAddress(null);
  }

  refreshTokan() {
    this.loading = true;
    localStorage.setItem('access_token', localStorage.getItem('refresh_token'));
    localStorage.removeItem('refresh_token');
    let value = '';
    let APIparams = {
      apiKey: AppSettings.APIsNameArray.NOTIFICATION.REFRESHTOKEN,
      uri: '',
      postBody: value,
    };
    this.commonService.post(APIparams).subscribe(
      (success) => {
        if (success.success === true) {
          this.loading = false;
          localStorage.setItem('access_token', success.response.access);
          localStorage.setItem('refresh_token', success.response.refresh);

          sessionStorage.setItem('refresh_token', success.response.refresh);
          sessionStorage.setItem('access_token', success.response.access);
          this.dialogRef.close();
          if (this.router.url === '/dashboard') {
            location.reload();
          } else {
            this.router.navigate(['/dashboard']);
          }
        } else if (success.success === false) {
          this.loading = false;
          this.dialogRef.close();
          this.alertService.showNotification(
            'danger',
            'bottom',
            'right',
            success.message
          );
        }
      },
      (error) => {
        this.loading = false;
        this.dialogRef.close();
        localStorage.removeItem('user_type');
        localStorage.removeItem('userProfileImage');
        localStorage.removeItem('access_token');
        localStorage.removeItem('userName');
        localStorage.removeItem('user_id');
        localStorage.removeItem('refresh_token');
        localStorage.removeItem('showExpiryPopup');
        localStorage.removeItem('email_verified');
        localStorage.removeItem('onboarding');

        sessionStorage.clear();
        this.router.navigate(['/']);
      }
    );
  }

  deleteDeviceTokan() {
    let value = {
      deviceId: localStorage.getItem('uniqueId'),
      token: localStorage.getItem('fcmToken'),
    };
    let APIparams = {
      apiKey: AppSettings.APIsNameArray.NOTIFICATION.DEVICETOKENDELETE,
      postBody: value,
    };
    this.commonService.post(APIparams).subscribe(
      (success) => {
        if (success.success === true) {
        } else if (success.success === false) {
          this.alertService.showNotification(
            'danger',
            'bottom',
            'right',
            success.message
          );
        }
      },
      (error) => {
        this.alertService.showNotification(
          'danger',
          'bottom',
          'right',
          AppSettings.ERROR
        );
      }
    );
  }
}
