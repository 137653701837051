<div class="progragess_line_status">
	<div class="pro_single_line">
		<div class="progragess_lines">
			<span class="lines" [ngStyle]="{'background-color': statusBars?.bar1 }"></span> 
			<div  class="st_hoverInfo info_barOne">
				<span class="tringel_icon" [ngStyle]="{'background-color': statusBars.bar1 }"></span>
				<p class="st_info" [ngStyle]="{'background-color': statusBars?.bar1 }">{{toltipPick()}}</p>
			</div>
		</div>

		<div class="progragess_lines">
			<span class="lines" [ngStyle]="{'background-color': statusBars?.bar2 }"></span>
			<div  class="st_hoverInfo info_barTwo">
				<span class="tringel_icon" [ngStyle]="{'background-color': statusBars?.bar2 }"></span>
				<p class="st_info" [ngStyle]="{'background-color': statusBars?.bar2 }">{{toltipDrop()}}</p>
			</div>
		</div>
		
		<div class="progragess_lines">
			<span class="lines" [ngStyle]="{'background-color': statusBars?.bar3 }"></span>
			<div class="st_hoverInfo info_barThree">
				<span class="tringel_icon" [ngStyle]="{'background-color': statusBars?.bar3 }"></span>
				<p class="st_info" [ngStyle]="{'background-color': statusBars?.bar3 }">{{toltipDeliverd()}}</p>
			</div>
		</div>	
	</div>
</div>
 <p class="m-0 fs_12 fsm_5 txt_p" *ngIf="statusLabel"> ORDER STATUS: {{statusLabel}} at {{updatedAt | date:  'MMM dd, yyyy'}} at {{updatedAt | date:'HH:mm'}} </p>