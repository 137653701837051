<nav class="navbar navbar-color-on-scroll navbar-transparent fixed-top navbar-expand-lg web_nab" color-on-scroll="100" id="sectionsNav">
    <div class="container">
      <div class="collapse navbar-collapse cus_nav">
        <div class="cus_navl">
          <div class="nav_left_part">
            <a (click)="loginPage()" class="header_logo pointer">
              <img src="{{logo}}"   alt="logo" class="img-fluid">
            </a> 
          </div>
          <div class="header_menu">
            <ul class="nav ms-auto" id="accordion">
              <li class="nav-item"  [ngClass]="currentTab === 'SHIPPER' ? 'active' : 'css-class-2'">
              </li>
              <li class="nav-item" [ngClass]="currentTab === 'CARRIER' ? 'active' : 'css-class-2'">
              </li>
              <li class="nav-item" [ngClass]="currentTab === 'track' ? 'active' : 'css-class-2'" (click)="activeTab('track')">
              </li>
            </ul>
          </div>
        </div>
        <div class="cus_navr">
          <button type="button" *ngIf="!userLogin" class="btn custum_button button_primary nav_btn" (click)="loginPage()">Sign In</button>
        </div>
      </div>
    </div>
  </nav>
  
  <div class="mobile_nab">
    <nav class="mob_menu">
      <div>
        <input id="responsive-menu" type="checkbox">
        
        <div class="menu_header">
          <img src="{{logo}}"  alt="logo" class="img-fluid res_logo">
          <label for="responsive-menu">
            <span id="menu-icon"></span>
          </label>
        </div>
  
        <div id="overlay"></div>
        <ul>
          <li>
            <button type="button" class="btn custum_button button_primary nav_btn px-4"  (click)="loginPage()">Sign In</button>
          </li>
        </ul>
      </div>
    </nav>
  </div>
  