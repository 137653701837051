<div class="user_pro_banner mt_30" >
    <div class="lane_booked_card_r1">
      <div class="carriersN_img card_pro_img" [ngClass]="laneBidRecord?.carriers?.legalName !=this.userName ? 'pointer' : ''"  (click)="eventActionProfileSidePanel(laneBidRecord,'profile_side_panel')">
        <span *ngIf="laneBidRecord?.isPreferred === 1" class="material-icons star_marked">star</span>
        <img *ngIf="laneBidRecord?.carriers?.profileImage" src="{{laneBidRecord?.carriers?.profileImage}}" class="carriersN_img">
        <span class="initial_name initial_r_32" *ngIf="!laneBidRecord?.carriers?.profileImage" >{{laneBidRecord?.carriers?.legalName | acronymOfName}}</span>
      </div>
  
      <div class="ms-3" [ngClass]="laneBidRecord?.carriers?.legalName !=this.userName ? 'pointer' : ''"  >
        <div class="cr_name_tag" (click)="eventActionProfileSidePanel(laneBidRecord,'profile_side_panel')">
          <span class="fs_18 fw_500 txt_b text-truncate" matTooltip="{{laneBidRecord?.carriers?.legalName ? laneBidRecord?.carriers?.legalName : '-'}}">{{laneBidRecord?.carriers?.legalName ? laneBidRecord?.carriers?.legalName : '-'}}</span>
           <img *ngIf="laneBidRecord?.carriers?.subscription" src="{{serverPathForUrl}}/portal/images/after-login/premium_plan_icon.png"
            class="img-fluid verified_icon">
            <span *ngIf="laneBidRecord?.isConnected" class="status_label"><i class="fa-solid fa-check"></i> Connected</span>
        </div>
        <div class="unlock_btn" *ngIf="userType=='SHIPPER' && !laneBidRecord?.carriers?.shippercarriersScoreMapping" (click)="unlockSafetyScore(laneBidRecord,laneBidRecord?.carriers?.dotNumber,'unlockSafetyScore')">
          <button><img src="\assets\icon\unlock_btn_icon.svg" class="mr-1">Unlock Safety Score</button>
        </div>

        <div class="mt-2" *ngIf="userType=='SHIPPER' && laneBidRecord?.carriers?.shippercarriersScoreMapping">
          <div class="ecarriers_check_box">
            <div>
              <span class="d-block pointer">
                <img src="\assets\icon\info_icon_green.svg" matTooltip="{{ecarriersCheckRisk?.lowRisk}}" *ngIf="laneBidRecord?.carriers?.carriersSafetyScore?.riskLevel=='4'">
                <img src="\assets/icon/ecarriers_info_light_red.svg" matTooltip="{{ecarriersCheckRisk?.highRisk}}" *ngIf="laneBidRecord?.carriers?.carriersSafetyScore?.riskLevel=='2'">
                <img src="\assets/icon/ingo_icon_yellow.svg" matTooltip="{{ecarriersCheckRisk?.elevatedRisk}}" *ngIf="laneBidRecord?.carriers?.carriersSafetyScore?.riskLevel=='3'">
                <img src="\assets/icon/ecarriers_info_light_pure_red.svg" matTooltip="{{ecarriersCheckRisk?.disqualified}}" *ngIf="laneBidRecord?.carriers?.carriersSafetyScore?.riskLevel=='1'">
              </span>
              <span class="fs_10 txt_b">{{laneBidRecord?.carriers?.carriersSafetyScore?.riskLabel ? laneBidRecord?.carriers?.carriersSafetyScore?.riskLabel : '-'}}</span>
            </div>
    
            <div *ngIf="laneBidRecord?.carriers?.carriersSafetyScore?.authority">
              <span class="d-block"><img src="\assets\icon\ecarriers_succes_icon.svg"></span>
              <span class="fs_8 txt_b">Authority</span>
            </div>
    
            <div>
              <span class="d-block"><img src="\assets\icon\ecarriers_crases_icon_red.svg"></span>
              <span class="fs_10 txt_b">Crashes<span class="text_red ms-1">({{laneBidRecord?.carriers?.carriersSafetyScore?.recentCrashes ? laneBidRecord?.carriers?.carriersSafetyScore?.recentCrashes : '0'}})</span></span>
            </div>
    
            <div>
              <span class="d-block"><span class="c_nu">{{laneBidRecord?.carriers?.carriersSafetyScore?.recentInspections ? laneBidRecord?.carriers?.carriersSafetyScore?.recentInspections : '0'}}</span></span>
              <span class="fs_10 txt_b">Inspection</span>
            </div>
            <div style="text-align: right;" class="view_snapshot_btn pointer" (click)="ecarrierscheck(laneBidRecord?.carriers)">
              <button class="fs_8 txt_p fsm_5">View Safety Report</button></div>
          </div>
        </div>
      </div>
    </div>
    
    <div>
      <p class="txt_p cu_bid">Confirmed Rate</p>
      <p class="price_p">
        <span>${{laneBidRecord?.lastBid | twoDecimalPipe}}</span>
        <span class="txt_lb">
          <app-rate-per-mile class="fs_14 fsm_5 mt-1" [amount]="laneBidRecord?.lastBid" [miles]="laneBidRecord?.lane?.miles"></app-rate-per-mile>
        </span>
      </p>
      <div class="text-truncate user_mess" style="text-align: center;" matTooltip="{{laneBidRecord?.note ? laneBidRecord?.note : ''}}">{{laneBidRecord?.note ? laneBidRecord?.note : '-'}}</div>
    </div>
  <div *ngIf="laneBidRecord?.shipmentStatus" class="d_flex_w_27">
    <span class="confi_btn"><span class="txt_green">{{laneBidRecord?.shipmentStatus ? laneBidRecord?.shipmentStatus : '-'}}</span></span>
  </div>
  
  <div class="" style="position: relative;" *ngIf="sidePanelProfileDataId">
    <div class="side-show-panel">
      <div class="side_bar_con">
        <app-carriers-side-panel [sidePanelProfileData]="sidePanelProfileData" [sidePanelProfileDataId]="sidePanelProfileDataId" [userType]="userType" (closeSidePanel)="closePanel($event)"></app-carriers-side-panel>
      </div>
    </div>
  </div>
  </div>
  <ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '14px',fullScreenBackdrop:true }"></ngx-loading>
  
  