import { Location } from '@angular/common';
import { AfterViewInit, Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Event, NavigationEnd, Router } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';
import { environment } from '../environments/environment';
import { CommonService } from './commons/service/common.service';
import { MessagingService } from './commons/service/messaging.service';
import { ScriptService } from './commons/service/script.service';
import { SharedService } from './commons/service/shared.service';
import { AppSettings } from './commons/setting/app_setting';
declare let gtag;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit, AfterViewInit {
  public oneConfirm: any;
  public showHideLeftmenu:any;
  public step:any;
  public hideLeftMenu=false
  public userRecord:any
  public cishow: any;
  public shownave = true;
  mobile_menu_visible: any = 0;
  public sidebutton: boolean;
  public userNameUpdate: any;
  public tokanValid = false;
  public userProfileImageUpdate: any;
  public userAccessTokan: any;
  public serverPathForUrl: any;
  public checkNavbar: any;
  public oldToken: any;
  public userName: any;
  public loadAPI: Promise<any>;
  public subscription: any;
  public contractDoc: any;

  constructor(
    private commonService: CommonService,
    public dialog: MatDialog,
    private router: Router,
    private route:ActivatedRoute,
    public location: Location,
    public sharedService: SharedService,
    private messagingService: MessagingService,
    private scriptService: ScriptService,
    private deviceService: DeviceDetectorService,
  ) { }

  ngOnInit() {
    this.showHideLeftmenu=localStorage.getItem('hideLeftmenu');
    if(localStorage.getItem('hideLeftmenu')=='true'){
      this.hideLeftMenu=true;
    }
    if(window.location.href.includes('track-your-load')){
      this.hideLeftMenuForTrackYourLoad();
    }
    this.serverPathForUrl = environment.serverPathForUrl;
    this.userAccessTokan = localStorage.getItem('access_token');
      
    this.checkNavbar = localStorage.getItem('login');
    this.userName = localStorage.getItem('userName');
    this.commonService.sendTokanUpdate.subscribe((userData) => {
      this.userAccessTokan = localStorage.getItem('access_token');
      this.checkNavbar = localStorage.getItem('login');
      this.userName = localStorage.getItem('userName');
    });
    this.getConfig();
     this.commonService.userData.subscribe((userData) => {
      let data:any=userData;
      if(data==true){
        this.hideLeftMenu=true
      }
      if(userData && data !=true && localStorage.getItem('login')=='after_login'){
         this.contractDoc=userData;
         this.hideShowleftMenu(userData)
      }
      this.userName = localStorage.getItem('userName');
    });

    this.sharedService.configLoginCallUrl.subscribe((userData) => {
      this.hideLeftMenu=false
      this.userAccessTokan = localStorage.getItem('access_token');
      this.checkNavbar = null;
    });
    const isMobile = this.deviceService.isMobile();
    if (isMobile) {
      this.scriptService.load().then((data) => {
      });
    } else {
      this.notifyMe();
      this.scriptService.load().then((data) => {
      });
    }

    if(environment.pointType==='prod'){
      this.sharedService.checkHotjarScriptLoaded();
    }
  }

  notifyMe() {
    if (Notification && Notification.permission == 'denied') {
      if (localStorage.getItem('fcmToken') !== null || localStorage.getItem('lastfcmToken') !== null) {
        this.deleteDeviceTokan();
        localStorage.setItem('addedApiCalled', 'denied');
      }
    }
    else if (Notification.permission == 'granted') {
      if (localStorage.getItem('addedApiCalled') !== 'granted') {
        this.getFCMToken();
      } else {
      }
    }
  }

  ngAfterViewInit() {
        const isMobile = this.deviceService.isMobile();
    if (isMobile) {
    } else {
      this.router.events.subscribe((event: Event) => {
        if (event instanceof NavigationEnd) {

          let urlData = event.url.split('?');
          const urlTree = this.router.parseUrl(event.url);

          if(urlTree && urlTree.root && urlTree.root.children && urlTree.root.children.primary && urlTree.root.children.primary.segments[3] && urlTree.root.children.primary.segments[3].path=='summary'){

          }else{ 
            if(localStorage.getItem('mapSummary') == "calling"){
              this.commonService.mapClearApiCalling.next(null);
            }          
          }

          if(urlData[0]=='/booking/lane/availableLanes' || urlData[0]=='/booking/lane/confirmedLanes' || urlData[0]=='/booking/lane/postedLanes' || urlData[0]=='/booking/lane/archivedLanes'){
            const body = document.getElementsByTagName('body')[0];
            body.classList.remove('booking_live_shipment');
            body.classList.add('booking_lanes');
          }else if(urlData[0]=='/booking/currentBids' || urlData[0]=='/booking/confirmedBids' || urlData[0]=='/booking/offerRate' || urlData[0]=='/booking/offerRateConfirmed' || urlData[0]=='/booking/availableToBid' || urlData[0]=='/booking/awardedBids' || urlData[0]=='/booking/nonAwardedBids'){
            const body = document.getElementsByTagName('body')[0];
            body.classList.remove('booking_lanes');
            body.classList.add('booking_live_shipment');
          }else{
            const body = document.getElementsByTagName('body')[0];
            body.classList.remove('booking_lanes');
            body.classList.remove('booking_live_shipment');
        
          }
          this.sharedService.startTimer();
          gtag('js', new Date());
          gtag('config', environment.googleAnalyticsKey, {
            page_path: event.urlAfterRedirects,
          });
        }
      });
    }

  }

  getFCMToken() {
    this.messagingService.requestPermission();
  }

  getConfig() {
    let uri = null;
    let newParams = {};
    if (newParams) uri = this.commonService.getAPIUriFromParams(newParams);
    let APIparams = {
      apiKey: AppSettings.APIsNameArray.OTHER.CONFIG,
      uri: uri,
    };
    this.commonService.getList(APIparams).subscribe((ServerRes) => {
      if (ServerRes.success === true) {
        this.sharedService.setConfig(ServerRes.response);
        this.commonService.configData.next(ServerRes.response);
      }
    });
  }

  deleteDeviceTokan() {
    let token;
    if (localStorage.getItem('lastfcmToken')) {
      token = localStorage.getItem('lastfcmToken');
    } else {
      token = localStorage.getItem('fcmToken');
    }
    let value = {
      deviceId: localStorage.getItem('uniqueId'),
      token: token,
    };
    let APIparams = {
      apiKey: AppSettings.APIsNameArray.NOTIFICATION.DEVICETOKENDELETE,
      uri: '',
      postBody: value,
    };
    this.commonService.post(APIparams).subscribe(
      (success) => {
        if (success.success === true) {
          localStorage.removeItem("lastfcmToken");
        }
      },
    );
  }

  callToLogout() {
    this.commonService.callToLogout.next(null);
    localStorage.removeItem('user_type');
    localStorage.removeItem('premium');
    localStorage.removeItem('tag');
    localStorage.removeItem('userProfileImage');
    localStorage.removeItem('access_token');
    localStorage.removeItem('userName');
    localStorage.removeItem('user_id');
    localStorage.removeItem('refresh_token');
    localStorage.removeItem('showExpiryPopup');
    localStorage.removeItem('email_verified');
    localStorage.removeItem('phone_verified');
    localStorage.removeItem('onboarding');
    sessionStorage.clear();
    localStorage.clear();
    this.checkNavbar = null;
    this.router.navigate(['/']);
  }

  hideShowleftMenu(userDtail){
    if (localStorage.getItem('user_type') == 'CARRIER') {
      if (userDtail?.subscription === null && !(this.route.snapshot['_routerState'].url?.includes('monthly') || this.route.snapshot['_routerState'].url?.includes('yearly'))) {
        this.hideLeftMenu=false;
        this.router.navigateByUrl('carrier/subscription-plan');
        const body = document.getElementsByTagName('body')[0];
        body.classList.add('before_login_user');
      }
      else if(userDtail?.subscription === null && (this.route.snapshot['_routerState'].url?.includes('monthly') || this.route.snapshot['_routerState'].url?.includes('yearly'))) {
        this.hideLeftMenu=false
        this.router.navigateByUrl(this.route.snapshot['_routerState'].url);
     }else if((!userDtail?.phyAddressLine1 || !userDtail?.phyCity || !userDtail?.phyState || !userDtail?.phyZip || !userDtail?.equipmentType
        || !userDtail?.preferredRegions || !userDtail?.signatureFont || !userDtail?.profileImage || !userDtail?.coverImage || !userDtail?.insuranceAmount || !userDtail?.insuranceCompanyName
        || !userDtail?.insuranceCompanyEmail) && userDtail?.subscription != null) {
          this.hideLeftMenu=false
          const body = document.getElementsByTagName('body')[0];
          body.classList.add('before_login_user');
          this.router.navigateByUrl('/carrier/onboarding');
      }
      else{
        this.hideLeftMenu=true;
      }
    } 
    else if (localStorage.getItem('user_type') == 'SHIPPER') {
      if (userDtail?.subscription == null && !(this.route.snapshot['_routerState'].url?.includes('monthly') || this.route.snapshot['_routerState'].url?.includes('yearly')))
       {
        this.hideLeftMenu=false
        const body = document.getElementsByTagName('body')[0];
        body.classList.add('before_login_user');
         this.router.navigateByUrl('shipper/subscription-plan');
       } 
       else if (userDtail?.subscription == null && (this.route.snapshot['_routerState'].url?.includes('monthly') || this.route.snapshot['_routerState'].url?.includes('yearly')))
       {
        this.hideLeftMenu=false;
         this.router.navigateByUrl(this.route.snapshot['_routerState'].url);
       } 
       else{
        this.hideLeftMenu=true
       }

      if ((!userDtail?.phyAddressLine1 || !userDtail?.phyCity || !userDtail?.phyState || !userDtail?.phyZip || !userDtail?.equipmentType
        || !userDtail?.preferredRegions || !userDtail?.signatureFont || !userDtail?.profileImage 
        || !userDtail?.contractDocument) && userDtail?.subscription != null)
      {
        this.hideLeftMenu=false
        const body = document.getElementsByTagName('body')[0];
        body.classList.add('before_login_user');
          this.router.navigateByUrl('/shipper/onboarding');
      } 
      else if ((userDtail?.phyAddressLine1 || userDtail?.phyCity || userDtail?.phyState || userDtail?.phyZip || userDtail?.equipmentType
        || userDtail?.preferredRegions || userDtail?.signatureFont || userDtail?.profileImage 
        || userDtail?.contractDocument) && userDtail?.subscription != null){
        this.hideLeftMenu=true

      }
    }
  }

  hideLeftMenuForTrackYourLoad(){
    if (window.location.href.includes('track-your-load') || localStorage.getItem('login')=='before_login') {
      this.hideLeftMenu=false
      const body = document.getElementsByTagName('body')[0];
      body.classList.add('before_login_user');
    } 
  }

  public generateFake(count: number): Array<number> {
    const indexes = [];
    for (let i = 0; i < count; i++) {
      indexes.push(i);
    }
    return indexes;
  }
  
}

