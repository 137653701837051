<div class="sign_in_header" *ngIf="!newTokan">
  <app-login-navbar></app-login-navbar>
</div>

<div class="sign_in_body" *ngIf="!newTokan">
 
  <div class="sign_in_box">
   
    <div class="signin_inner">
      <div class="tuto_position">
        <span><img src="assets\icon\tuto_icon_blue.png" class="tuto_icon"></span> <span class="btn tuto_in_btn" (click)="videoTutorialSignIn()">Sign In Tutorial </span>
       </div>
      <div class="sign_in_con">
        <form class="custum_input_css" [formGroup]="loginForm" (ngSubmit)="loginFormSubmit(loginForm)">
          <div class="si_box">
            <p class="sf_form_tlt">Sign in to your account</p>
            <div class="input_field_box">
              <p class="fs_14 fsn_4 txt_lb mb-1">Email Address <span class="txt_d">*</span></p>
              <mat-form-field class="" id="custom_input_2">
                <input matInput type="text" formControlName="username" placeholder="Enter email address" maxlength="129" class="form-control custom_input" autocomplete="off" readonly onfocus="this.removeAttribute('readonly');"/>
                <mat-error *ngIf="submitted && loginForm.get('username').errors?.['required']" class="error_mess"> Email is required.</mat-error>
                <mat-error *ngIf="loginForm.get('username').errors?.['pattern']" class="error_mess">Please enter valid email address.</mat-error>
                <mat-error *ngIf="(loginForm.get('username').errors?.['maxlength']) && !(loginForm.get('username').errors?.['pattern'])" class="error_mess">Email must not exceed 128 characters.</mat-error>
              </mat-form-field>
            </div>
            <div class="input_field_box">
              <p class="fs_14 fsn_4 txt_lb mb-1">Password  <span class="txt_d">*</span></p>
              <mat-form-field appearance="none" class="ic_password" id="custom_input_2">
                <input matInput [type]="hide ? 'password' : 'text'" formControlName="password" [(ngModel)]="passwordSpaceCheck" (keyup)="checkSpace($event)" placeholder="Enter password" class="form-control custom_input"  minlength="6" maxlength="17"/>
                <button *ngIf="loginForm.value.password" mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide" type="button">
                  <mat-icon class="material-icons ff_im fs_18 txt_lb">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                </button>
                <mat-error *ngIf="submitted && loginForm.get('password').errors?.['required']" class="error_mess">Password is required.</mat-error>
                <mat-error *ngIf="loginForm.get('password').errors?.['minlength']" class="error_mess">Password must be at least 6 characters long.</mat-error>
                <mat-error *ngIf="loginForm.get('password').errors?.['maxlength']" class="error_mess">Only 16 characters are allowed.</mat-error>
              </mat-form-field>
              <small class="remember_password error_mess" *ngIf="loginmessageError">{{loginmessageError}}</small>
            </div>
            <mat-hint class="forgot_txt" (click)="forGotPassword()">Forgot your password?</mat-hint>
            <div class="form_btm_actn">
              <button class="btn custum_button button_primary" type="submit">Sign in</button>
            </div>
          </div>
        </form>
      </div>
      <p class="sign_up_txt">
        <span>Don't have an account?</span> 
        <u (click)="singUp()">Sign up</u>
      </p>
    </div>
  </div>
  <app-footer></app-footer>
</div>
<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '14px',fullScreenBackdrop:true }"></ngx-loading>