export const STATUS_COLOR_CODES = {
  BLUE: '#3675dd',
  GREEN: '#4CD79E',
  RED: '#DD6B73',
  YELLOW: '#EAAD04',
};

export const STATUS_CODE = {
  REACHEDATDROPOFFGEOFENCE: 42,
  ATDROPOFFLOCATION: 43,
  ATPICKUPLOCATION: 23,
  VERIFYING_DOCUMENTS_PICKUP_LOCATION: 26,
};

export const STATUS = {
  ON_TIME: 'On Time',
  DELAYED: 'Delayed',
  POT_DELAYED: 'Potentially Delayed',
  NO_ROUTE: 'No Route',
  LOCATION_DISABLED: 'Location Disabled',
};
