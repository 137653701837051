import { Component, OnInit, Inject , ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';

export interface DialogData {
  openPop: string;
}
@Component({
  selector: 'app-popup-info',
  templateUrl: './popup-info.component.html',
  styleUrls: ['./popup-info.component.css'],
})
export class PopupInfoComponent implements OnInit {
  @ViewChild('iframe') iframe:any;
  public loader = false;
  public subscripeUrl:any;
  public subcribeurl = "https://scribehow.com/embed/How_to_Sign_In__gHDY9MiDSj-WWxiEwXLzdg?as=scrollable";
  public serverPathForUrl: any;

  constructor(
    public dialog: MatDialog,
    private sanitizer:DomSanitizer,
    public dialogRef: MatDialogRef<PopupInfoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {}

  // Function to close popup
  ngOnInit() {
    this.serverPathForUrl = environment.serverPathForUrl;
    if(this.data.openPop=='videoTutorail'){
      this.loader = true;
      this.subscripeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.subcribeurl);
    }
  }

  ngAfterViewInit() {
    this.iframe.nativeElement.addEventListener('load', this.onLoad.bind(this));
  }
  
  onLoad(e) {
    setTimeout(() => {
      this.loader=false;
    }, 500);
  }

  onNoClick(): void {
    this.dialogRef.close({ event: 'fail' });
  }
}
