import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterSearch',
  pure: false,
})
export class FilterPipe implements PipeTransform {
  transform(items: any[], data): any {
    if (data) return items.filter((item) => item.name.indexOf(data) !== -1);
    else return items;
  }
}
