<div class="crt_Ship_body">
    <div class="pg_head">
      <div class="container">
        <p class="pg_head_title mb-0">File A Dispute</p>
      </div>
    </div>
    <div class="card_container">
      <form class="mb-0 custum_input_css" autocomplete="off" [formGroup]="disputeForm"
        (ngSubmit)="createDispute(disputeForm)" class="mb-0 custum_input_css" autocomplete="off">
        <div class="container">
          <div class="row">
            <div class="col-md-6">
              <div class="crt_card">
                <p class="line_before">Load Details</p>
                <div class="row">
                  <div class="col-md-6">
                    <p class="fs_14 fsn_4 txt_lb space_field">Load Id <span class="txt_d"> *</span></p>
                    <div class="autocomplete-container">
                      <mat-form-field appearance="none" class="removeappearance">
                        <input matInput placeholder="Enter Load Id" class="form-control custom_input"
                          formControlName="uniqueId" required>
                      </mat-form-field>
                    </div>
                  </div>
  
                  <div class="col-md-6 ic_calander">
                    <p class="fs_14 fsn_4 txt_lb space_field">Load Date of Delivery<span class="txt_d"> *</span></p>
                    <mat-form-field appearance="none" class="removeappearance" class="drop_bg cal_input">
                      <input matInput formControlName="deliveryDate" [matDatepicker]="dp3" name="shipmentDate"
                        placeholder="Select Date" class="form-control custom_input">
                      <mat-datepicker-toggle matSuffix [for]="dp3"></mat-datepicker-toggle>
                      <mat-datepicker #dp3 disabled="false"></mat-datepicker>
                      <mat-error *ngIf=" disputeForm.get('deliveryDate').errors?.['required']" class="error_mess">Delivery
                        date is required.</mat-error>
                    </mat-form-field>
                  </div>
                  <div class="col-md-6 ic_calander">
                    <p class="fs_14 fsn_4 txt_lb space_field">Payment Date<span class="txt_d"> *</span></p>
                    <mat-form-field appearance="none" class="removeappearance" class="drop_bg cal_input">
                      <input matInput formControlName="paymentDate" [matDatepicker]="dp2" placeholder="Select Date"
                        class="form-control custom_input">
                      <mat-datepicker-toggle matSuffix [for]="dp2"></mat-datepicker-toggle>
                      <mat-datepicker #dp2 disabled="false"></mat-datepicker>
                    </mat-form-field>
                  </div>
                  <div class="col-md-6">
                    <p class="fs_14 fsn_4 txt_lb space_field">Proof of Delivery<span class="txt_d"> *</span></p>
  
                    <div class="pod_con">
                      <a href="{{getDisputeDetail?.deliveryDoc}}" target="_blank">Shipment Invoice</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="crt_card">
                <p class="line_before">Shipper & Carrier Information</p>
                <div class="row">
                  <div class="col-md-6">
                    <p class="fs_14 fsn_4 txt_lb space_field">Shipper Company<span class="txt_d"> *</span></p>
                    <div>
                      <mat-form-field appearance="none" class="removeappearance">
                        <input matInput placeholder="Enter Shipper Company" class="form-control custom_input"
                          formControlName="shipperCompany" required>
                      </mat-form-field>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <p class="fs_14 fsn_4 txt_lb space_field">Shipper Name<span class="txt_d"> *</span></p>
                    <mat-form-field appearance="none" class="removeappearance">
                      <input matInput type="text" formControlName="shipperName" placeholder="Enter Shipper Name"
                        maxlength="65" class="form-control custom_input" />
                      <mat-error *ngIf="disputeForm.get('shipperName').errors?.['maxlength']" class="error_mess">Shipper
                        name should be less than 64 character</mat-error>
                    </mat-form-field>
                  </div>
                  <div class="col-md-6">
                    <p class="fs_14 fsn_4 txt_lb space_field">Carrier Company</p>
                    <mat-form-field appearance="none" class="removeappearance">
                      <input matInput type="text" formControlName="carrierCompany" placeholder="Enter Carrier Company"
                        class="form-control custom_input" />
                    </mat-form-field>
                  </div>
                  <div class="col-md-6">
                    <p class="fs_14 fsn_4 txt_lb space_field">Carrier Name</p>
                    <mat-form-field appearance="none" class="removeappearance">
                      <input matInput type="text" formControlName="carrierName" placeholder="Enter Carrier Name"
                        class="form-control custom_input" />
                    </mat-form-field>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="crt_card">
            <p class="line_before">Dispute Details</p>
            <div class="row">
              <div class="col-md-6">
                <div class="ic_dropdown">
                  <p class="fs_14 fsn_4 txt_lb space_field">Dispute Reason<span class="txt_d"> *</span></p>
                  <mat-form-field appearance="none" class="removeappearance" class="drop_bg">
                    <mat-select placeholder="Select Dispute Reason" class="form-control custom_input"
                      formControlName="disputeReason">
                      <mat-option *ngFor="let reasonData of reasonList" [value]="reasonData.id"
                        (click)="disputeData(reasonData)">{{reasonData.reason}}</mat-option>
                    </mat-select>
                    <mat-error *ngIf=" disputeForm.get('disputeReason').errors?.['required']" class="error_mess">Please
                      choose any dispute reason.</mat-error>
                  </mat-form-field>
                </div>
                <div class="">
                  <p class="fs_14 fsn_4 txt_lb space_field">Upload Document<span class="txt_d"> *</span></p>
                 
  
                  <div class="upload_dock reg_upload_dock">
                    <mat-form-field appearance="none" class="removeappearance" class="custom_matSuffix_icon">
                      <input matInput type="text" formControlName="uploadDocument" value="{{name}}"
                        placeholder="Upload Document" class="form-control custom_input" readonly="readonly">
                      <mat-error *ngIf=" disputeForm.get('uploadDocument').errors?.['required']" class="error_mess"
                        style="margin: 16px 0 0;">Dispute document is required.</mat-error>
                      <mat-hint class="fs_10 fsm_5 mat-hint" style="margin: 16px 0 0;">
                        <i>PDF, PNG or JPG files only. Max file size 10mb. For two documents combine them in one pdf to
                          upload.</i>
                      </mat-hint>
                    </mat-form-field>
                    <div>
                      <input accept="application/pdf" class="inputfile" id="file" name="file" type="file"
                        (change)="onSelectedFile($event)" style="display: none;">
                      <label class="btn btn-primary py-0 mb-3" for="file"
                        style="background: #f5f5f5; border-radius: 0 4px 4px 0;">
                        <i class="fa-solid fa-upload fs_16 txt_lb"></i>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <p class="fs_14 fsn_4 txt_lb space_field dispute_detailt">
                  <span>Dispute in Details<span class="txt_d"> *</span></span>
                  <span>(150 characters)</span>
                </p>
                <mat-form-field appearance="none" class="removeappearance">
                  <textarea matInput type="text" formControlName="discription" placeholder="Enter description"
                    class="form-control custom_input" rows="5" maxlength="121" style="min-height: 132px;"></textarea>
                  <mat-error *ngIf=" disputeForm.get('discription').errors?.['required']" class="error_mess">Dispute
                    detail is required.</mat-error>
                </mat-form-field>
              </div>
            </div>
            <div class="dispute_reasons">
              <p class="fs_16 fsm_5 txt_b mb-2">Dispute Reason: <span *ngIf="disputeDetailReason">{{ disputeDetailReason
                  }}.</span></p>
             
            </div>
          </div>
          <div class="ctr_ship_btns">
            <button type="submit" class="btn custum_button button_primary" id="next" name="next">Submit</button>
          </div>
        </div> <!-- Close Container -->
      </form>
    </div>
  </div>
  
  <ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '14px',fullScreenBackdrop:true }"></ngx-loading>