import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/commons/service/common.service';
import { AppSettings } from 'src/app/commons/setting/app_setting';
import { PopupComponent } from 'src/app/shared/popup/popup.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-lane-shipper-list',
  templateUrl: './lane-shipper-list.component.html',
  styleUrls: ['./lane-shipper-list.component.scss']
})
export class LaneShipperListComponent implements OnInit {
  @Output() eventPassAction = new EventEmitter();
  @Input() laneShipperDetail: any;
  @Input() currentStatus: any;
  @Input() isArchived: any;
  @Input() laneDetail: any
  public userName:any
  public sidePanelProfileDataId:any;
  public sidePanelProfileData:any;
  public userType: any;
  public selfUserId: any;
  public type = 'listing';
  public premiumType: any;
  public serverPathForUrl: any;

  constructor(
    private router: Router,
    public dialog: MatDialog,
    public commonService: CommonService,
  ) { }

  ngOnInit(): void {
    this.userType = localStorage.getItem('user_type');
    this.userName = localStorage.getItem('userName');
    this.selfUserId = localStorage.getItem('user_id');
    this.premiumType =  localStorage.getItem('premium');
    this.serverPathForUrl = environment.serverPathForUrl;
  }

   // For Navigation to buy Invite credits 
   buyCredit(){
    if (localStorage.getItem('premium') == '1') {
      this.router.navigate(['/carrier/subscription-overview/additional-invites']);
    } else if (localStorage.getItem('premium') == '0'){
      this.router.navigate(['/carrier/premium-plan']);
    }
  }

  // Sending events to the child component by event emitter
  eventAction(event: any, type: any) {
    if (this.userType ==='CARRIER'){
      if(type == 'firstLaneBid' || type == 'connectInviteLaneFixRate' || type == 'laneAcceptBidForFixRate')
      {
        const dialogRef = this.dialog.open(PopupComponent, {
          backdropClass: AppSettings.backdropClass,
          width: AppSettings.popWidth,
          disableClose: true,
          data: {
            openPop: 'equipmentNotMatch',
            contain : this.laneDetail
          },
        });
        dialogRef.afterClosed().subscribe((result) => {
          if (result.event == 'Ok') {
            this.eventPassAction.emit({ event, type });
          }
          else 
          {
            this.router.navigate(['booking/lane/availableLanes'])
          }
        });
      }
      else 
      {
        this.eventPassAction.emit({ event, type });
      }
     
    }
  }

  // Sending events to the child component by event emitter for profile side panel
  eventActionProfileSidePanel(event: any, type: any){
    if(event?.lastBid?.actionBy !== this.selfUserId && event?.lastBid && event?.lastBid?.status==2){
      this.sidePanelProfileData=event;
      this.sidePanelProfileDataId=event?.shipper?.id
      this.eventPassAction.emit({ event, type });
    }
  }

  // Close side panel
  closePanel(event: any) {
    this.sidePanelProfileDataId = '';
  }

}
