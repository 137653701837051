<div class="notification_body">
   <div class="wallet_header">
      <div class="">
         <div class="header_title">
            Notification Control
         </div>
      </div>
   </div>
   <div class="notification_con_box">
      <div class="notification_list cus_matTab">
         <mat-tab-group [selectedIndex]="selectedIndex" (click)="onTabChanged($event)">
            <mat-tab label="Notification">
               <div class="notification_white_box">
                  <div>
                     <p class="notification_title txt_bf">Manage Notification Frequency</p>
                  </div>
                  <form [formGroup]="newInvitionNetwork" (ngSubmit)="newInvitionNetworkForm(newInvitionNetwork)"
                     autocomplete="off">
                     <div class="col-md-4">
                        <mat-form-field appearance="none" class="removeappearance" class="drop_bg ic_dropdown ">
                           <mat-select placeholder="Please select option " class="form-control custom_input"
                              formControlName="email">
                              <mat-option value="every-event">Every event</mat-option>
                              <mat-option value="once-a-day">Once a day</mat-option>
                              <mat-option value="once-a-week">Once a week</mat-option>
                           </mat-select>
                           <mat-error *ngIf="newInvitionNetwork.get('email').errors?.['required']"
                              class="error_mess">Please select email option. </mat-error>
                        </mat-form-field>
                     </div>
                     <p class="invitation_gtxt">The invitation email will be sent to you according to the invitation email you select.</p>
                     <div class="button_box">
                        <div class="Update_button">
                           <button class="btn custum_button button_primary button_rounded" type="submit">Update</button>
                        </div>
                     </div>
                  </form>
               </div>
            </mat-tab>
         </mat-tab-group>

         <ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '14px',fullScreenBackdrop:true }"></ngx-loading>
      </div>
   </div>
</div>