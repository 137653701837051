<div *ngIf="data.openPop ==='fileDispute'">
    <div class="header_popup">
      <p class="fs_20 fsm_5 txt_b line_before">Filing A Dispute</p>
      <span class="close_popup_Icon" (click)="onNoClick()">
        <img src="{{serverPathForUrl}}/portal/icon/ic_close_white.svg" class="close_Icon">
      </span>
    </div>
    <div class="bid_amount">
      <p class="bid_amountTxt">LOAD NAME AND ID</p>
      <p class="bid_amountValue">{{data.title}}</p>
      <p class="bid_amountValue fs_16 fsb_7 mt-1">
        <span>#{{data.uniqueId}}</span>
      </p>
    </div>
      <p class="fs_16 fsn_4 txt_lb" style="margin: 0 0 1rem; line-height: 24px;">
       <span>You have chosen to file a dispute regarding this</span>
       <span class="txt_p"> {{data.title}} #{{data.uniqueId}}.</span>
      </p>
      <p class="fs_16 fsn_4 txt_lb" style="margin: 0 0 1rem; line-height: 24px;">
         <span>When a dispute arises, we encourage both parties to communicate directly with each other in order to reach a timely resolution. If you would like to proceed with filing a dispute,</span> 
         <span class="txt_lb"> LaneAxis</span>
         <span>  and/or a third party mediator will investigate the claim and seek a mutually agreed upon resolution. If necessary, a legally binding final decision will be issued.</span>
       </p>
      <p class="fs_16 fsn_4 txt_lb" style="margin: 0 0 1rem; line-height: 24px;">
         <span>No payment will be released until the dispute is resolved. The timeline for resolving disputes or issuing final judgments will vary from case to case.</span>
       </p>
      <p class="fs_16 fsn_4 txt_lb" style="margin: 0 0 2rem; line-height: 24px;">
         <span>Would you still like to file a dispute?</span>
       </p>
      <div class="text-center">
      <button type="submit" (click)="disputRedirect()" class="btn custum_button button_primary w-100 fs_16 fsn_4"  style="margin: 0 0 1.5rem; height: 40px; border-radius: 30px; box-shadow: none;">Yes, File it</button>
      <span class="fs_16 fsn_4 txt_lb pointer px-4 py-2" (click)="onNoClick()">No</span>
      </div>
  </div>
  
  
  <!-- Success popu -->
  <div *ngIf="data.openPop ==='disputeSuccess'">
     <div class="text-center" style="margin: 0; padding: 1rem;">
        <span class="material-icons" style="margin: 0 0 1.5rem;font-size: 48px;color: var(--clGrna);">check_circle</span>
        <p class="fs_20 fsm_5 txt_b" style="margin: 0 0 2rem;">Congratulations</p>
        <p class="fs_16 fsn_4 txt_lb" style="margin: 0 0 2rem; line-height: 24px;">
           <span>You have successfully filed a dispute regarding </span> 
             <span class="txt_b"> {{data.title}} # {{data.uniqueId}}</span>
             <span> load.</span>
        </p>
        <p class="fs_16 fsn_4 txt_lb" style="margin: 0 0 2rem; line-height: 24px;"> 
             <span class="txt_b">LaneAxis </span>
           <span> and/or a third party mediator will investigate the claim and seek a mutually agreed upon resolution.</span>
        </p>
        <div class="text-center">
           <button type="submit" class="btn custum_button button_success w-100 text-capitalize fs_16 fsm_5" (click)="onNoClick()" [routerLink]="['/dispute/newDispute']" style="margin: 0 0 1.5rem; height: 40px; border-radius: 30px; box-shadow: none;">OK</button>
           <span class="fs_16 fsn_4 txt_p pointer px-4 py-2" [routerLink]="['/load/view', data.shipmentId,'dispute']" (click)="onNoClick()">View Details</span>
        </div>
     </div>
  </div>