import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CreateLoadsComponent } from './create-loads/create-loads.component';
import { AuthGuard } from '../commons/guard/auth.guard';
import { PendingChangesGuard } from '../commons/guard/pending-changes.guard';
import { ActiveLoadsComponent } from './active-loads/active-loads.component';

const routes: Routes = [
  {
    path: 'created-loads/:tabType',
    component: CreateLoadsComponent,
    canActivate: [AuthGuard],
    canDeactivate: [PendingChangesGuard],
  },
  {
    path: 'created-loads/:tabType',
    children:[{path:'load/view', loadChildren: () =>
      import('../load/pages/view/view.module').then((mod) => mod.ViewModule)}]
  },
  {
    path: 'available-loads/:tabType',
    component: CreateLoadsComponent,
    canActivate: [AuthGuard],
    canDeactivate: [PendingChangesGuard],
  },
  {
    path: 'available-loads/:tabType',
    children:[{path:'load/view', loadChildren: () =>
      import('../load/pages/view/view.module').then((mod) => mod.ViewModule)}]
  },
  {
    path: 'spot-rate-loads/active-loads/:tabType',
    component: ActiveLoadsComponent,
    canActivate: [AuthGuard],
    canDeactivate: [PendingChangesGuard],
  },
  {
    path: 'spot-rate-loads/active-loads/:tabType',  children:[{path:'load/view', loadChildren: () =>
      import('../load/pages/view/view.module').then((mod) => mod.ViewModule)}]
  },

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SpotLoadsRoutingModule { }
