<div class="si_footer">
  <div class="container">
    <div class="ftr_list">
      <ul>
        <li><a href="https://laneaxis.com/terms-and-conditions" target="_blank" class="ftr_ilk">Terms And Conditions</a></li>
        <li><a href="https://laneaxis.com/legal-privacy-policy" target="_blank" class="ftr_ilk">Privacy Policy</a></li>
        <li><a href="https://laneaxis.com/legal-community-guidelines" target="_blank" class="ftr_ilk">Community Guidelines</a></li>
        <li><a href="https://www.termsfeed.com/live/301013dd-e496-49b5-baaf-c0f8be000c4e" target="_blank" class="ftr_ilk">CCPA Privacy Laws</a></li>
        <li><a href="https://laneaxis.com/get-in-touch" target="_blank" class="ftr_ilk">Contact Us Page</a></li>
      </ul>
    </div>
  </div>
</div>