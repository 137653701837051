import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { environment } from '../../../environments/environment';
import { CommonService } from '../../commons/service/common.service';
import { SharedService } from '../../commons/service/shared.service';
import { AppSettings } from '../../commons/setting/app_setting';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { AlertService } from 'src/app/commons/service/alert.service';

@Component({
  selector: 'app-rating',
  templateUrl: './rating.component.html',
  styleUrls: ['./rating.component.scss']
})
export class RatingComponent implements OnInit {
  @Input() loadDetail: any;
  public ratingOwnerDetails:any
  @Input() currentTabShow: any;
  @Input() shipmentId: any;
  @Input() status: any;
  @Input() userType: any;
  @Output() refreshShipment: EventEmitter<any> = new EventEmitter();
  public serverPathForUrl: any;
  public disabledButton = true;
  public textValue: any;
  public ratingForm: FormGroup;
  public loading = false;
  stars = [{ id: 1, selected: false },
  { id: 2, selected: false },
  { id: 3, selected: false },
  { id: 4, selected: false },
  { id: 5, selected: false }
  ]
  selectedValue: number;

  constructor(
    private alertService: AlertService,
    private fb: FormBuilder,
    public sharedService: SharedService,
    public commonService: CommonService,
  ) { }

  ngOnInit(): void {
    this.userType = localStorage.getItem('user_type');
    this.serverPathForUrl = environment.serverPathForUrl;
    this.ratingForm = this.fb.group({
      review: ['', [Validators.maxLength(255)]],
    });
  }

  countStar(star) {
    let selectedIndex = star ? star.id : 0;
    this.stars.map(x => x.selected = false);
    for (let i = 0; i < star.id; i++) {
      this.stars[i].selected = true;
    }
    this.selectedValue = star.id;
    this.disabledButton = false;
  }

  getRefreshShipment(){
    this.refreshShipment.emit('refresh');
  }

  ratingFormSubmit({ value, valid }) {
    if (valid) {
      this.loading = true;
      this.disabledButton = false;
      let postBody = null;
      postBody = {
        shipmentId: this.shipmentId,
        note: this.ratingForm.get('review').value,
        rate: this.selectedValue
      };
      let APIparams = {
        apiKey: AppSettings.APIsNameArray.RATING.ADD,
        uri: '',
        postBody: postBody,
      };
      this.commonService.post(APIparams).subscribe(
        (success) => {
          if (success.success === true) {
            this.alertService.showNotificationMessage(
              'success',
              'bottom',
              'right',
              'txt_s',
              'check_circle',
              'Review and Rating',
              'Rating received. Thank you!'
            );
            this.loading = false;
            this.getRefreshShipment();
          } else if (success.success === false) {
            this.alertService.showNotificationMessage(
              'danger',
              'bottom',
              'right',
              'txt_d',
              'cancel',
              'Review and Rating',
              success.message
            );
          }
          this.loading = false;
        },
        (error) => {
          this.alertService.showNotificationMessage(
            'danger',
            'bottom',
            'right',
            'txt_g',
            'error',
            'Unexpected Error',
            AppSettings.ERROR
          );
          this.loading = false;
        }
      );
    }
  }

}
