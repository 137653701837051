import { Renderer2, RendererFactory2, Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { ScriptFacebookStore } from '../interface/script.store/script-facebook.store';

declare global {
  interface Window {
    Appcues: any;
    dataLayer: any;
  }
}

@Injectable({
  providedIn: 'root',
})
export class FacebookPixelService {
  private scripts: any = [];
  private renderer: Renderer2;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    rendererFactory: RendererFactory2
  ) {
    this.renderer = rendererFactory.createRenderer(null, null);

    ScriptFacebookStore.forEach((script: any) => {
      let scriptsInstance: any = {};
      scriptsInstance[script.name] = {
        loaded: false,
        src: script.src,
      };
      this.scripts.push(scriptsInstance);
    });
  }

  load() {
    var promises: any[] = [];
    this.scripts.forEach((script: any) =>
      promises.push(this.loadScript(script))
    );
    return Promise.all(promises);
  }

  public loadScript(script: any) {
    return new Promise((resolve, reject) => {
      let scriptName = Object.keys(script)[0];
      var scriptElement;
      if (scriptName == 'facebookPixel') {
        scriptElement = this.appendImageTag(
          this.renderer,
          script[scriptName].src
        );
      }
      scriptElement.onload = () => {
        resolve({ script: scriptName, loaded: true, status: 'Loaded' });
      };
    }).then((result: any) => {
      this.checkResolverFunction(result);
    });
  }

  checkResolverFunction(response) {
    if (response.script == 'facebookPixel')
      this.facebookResolver(response.script);
    else return { script: response.script, loaded: true, status: 'Loaded' };
  }

  facebookResolver(scriptName) {
    (function (f: any, b, e, v, n, t, s) {
      if (f.fbq) return;
      n = f.fbq = function () {
        n.callMethod
          ? n.callMethod.apply(n, arguments)
          : n.queue.push(arguments);
      };
      if (!f._fbq) f._fbq = n;
      n.push = n;
      n.loaded = !0;
      n.version = '2.0';
      n.queue = [];
      t = b.createElement(e);
      t.async = !0;
      t.src = v;
      s = b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t, s);
    })(
      window,
      document,
      'script',
      'https://connect.facebook.net/en_US/fbevents.js'
    );
    (window as any).fbq.disablePushState = true; //not recommended, but can be done
    (window as any).fbq('init', '753986152142037');
    (window as any).fbq('track', 'PageView');
  }

  public appendScriptTag(renderer: Renderer2, src: string) {
    const script = renderer.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.src = src;
    renderer.appendChild(this.document.body, script);
    return script;
  }

  public appendImageTag(renderer: Renderer2, src: string) {
    const imgData = renderer.createElement('img');
    imgData.style.display = 'none';
    imgData.style.height = '1';
    imgData.style.width = '1';
    imgData.src = src;
    renderer.appendChild(this.document.body, imgData);
    return imgData;
  }

  public appendCSSTag(renderer: Renderer2, src: string) {
    const style = renderer.createElement('link');
    style.id = 'css-styling';
    style.rel = 'stylesheet';
    style.href = src;
    renderer.appendChild(this.document.head, style);
    return style;
  }
}
