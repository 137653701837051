import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import * as moment from 'moment-timezone';
import { environment } from '../../../environments/environment';
import { AlertService } from '../../commons/service/alert.service';
import { CommonService } from '../../commons/service/common.service';
import { HereMapsService } from '../../commons/service/here-maps.service';
import { SharedService } from '../../commons/service/shared.service';
import { AppSettings } from '../../commons/setting/app_setting';
import { GermanAddress } from '@angular-material-extensions/google-maps-autocomplete';
import { PopupComponent } from 'src/app/shared/popup/popup.component';

export interface DialogData {
  name: string;
  condition: any;
  templateId: any;
  shipmentId: any;
  uniqueId: any;
  status: any;
  msg: any;
  title: any;
  amount: any;
  typeShipment: any;
  occurence: any;
  note: any;
  shipmentDetail: any;
  laneDetail: any;
  laneId:any;
  isArchived: any;
  bidId: any;
  preferredRegions: any;
  laneBidId:any;
  bidFor: any;
  userName: any;
  userNameOther: any;
  openPop: any;
  type: any;
  miles: any;
  addressLine1: any;
  addressLine2: any;
  state: any;
  city: any;
  zipCode: any;
  country: any;
  userId: any;
  paymentMethod: any;
  eventType: any;
  email: any;
  contractDocument: any;
  bidingStatus: any;
  actionByOffer: any;
  freightRate: any;
  newStatusLabel: any;
  orderId: any;
  storageId: any;
  orderDetail: any;
  companyName: any;
  ratingOwnerDetail: any;
  isDisabled: any;
  inputAmout:any;
}

@Component({
  selector: 'app-load-popup',
  templateUrl: './load-popup.component.html',
  styleUrls: ['./load-popup.component.css']
})
export class LoadPopupComponent implements OnInit {
  public loading: any;
  public templateShipment: FormGroup;
  public redibForm: FormGroup;
  public timeSpentFirstBid: any;
  public bidForm: FormGroup;
  public laneBidForm: FormGroup;
  public newLaneBidForm: FormGroup;
  public overBidForm: FormGroup;
  public counterBiddForm: FormGroup;
  public submitted = false;
  public afterDeduction: any;
  public totalPage = 1;
  public selectedRegion:any;
  public page = 1;
  public regionSelectedData: any;
  public streetValue: any;
  public streetValue2: any;
  public skeletonLoader = false;
  public city: any;
  public country: any;
  public searchModel: any = {};
  public documentCheck = true;
  public timeSpent: any;
  public searchBoxError = false;
  public currentId: any;
  public addressForm: FormGroup;
  public states: any;
  public mailAdd = false;
  public currentStatus = 'relavent';
  public countSpace = 0;
  public countSpace2 = 0;
  public countSpace3 = 0;
  public phpAdd = false;
  public userType: any;
  public userId:any;
  public bidHistorySekeletonLoader = false; 
  public previousBidHistory = [];
  public previousLaneBidHistory = [];
  public spinnerLoaderBid = false;
  public serverPathForUrl: any;
  public checkUserType: any;
  public premiumType: any;
  public rateList: any;
  public searchRestriction: any;
  public latitude: any;
  public longitude: any;
  public selfUserId: any;
  previousBid: any;
  shipmentDetail: any;
  zipCodeErrorMsg: string;
  public selectedCountry: any;
  public countryList: any;

  constructor(
    private formBuilder: FormBuilder,
    private alertService: AlertService,
    private commonService: CommonService,
    public router: Router,
    public dialog: MatDialog,
    private hereMapsService: HereMapsService,
    private sharedService: SharedService,
    public dialogRef: MatDialogRef<LoadPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) { }

  ngOnInit() {
    this.userType = localStorage.getItem('user_type');
    this.selfUserId = localStorage.getItem('user_id');
    this.zipCodeErrorMsg = AppSettings.zipCodeError;
    if (this.data.openPop === 'ConfettiPopupShow') {
      setTimeout(() => {
        this.dialogRef.close({ event: 'Ok' });
      }, 2000);
    }
    let configValue = this.sharedService.getConfig();
    if (configValue !== undefined) {
      this.getConfigSet(configValue);
    }

    this.commonService.configData.subscribe((userData) => {
      this.getConfigSet(userData);
    });
  
    if (this.data.type === 'Mailing Address' || this.data.type === 'Physical Address' || this.data.type === 'Both Address') {

      const body = document.getElementsByTagName('body')[0];
      body.classList.add('userProfile_sugBox');

      if (this.data.type === 'Mailing Address' || this.data.type === 'Mailing Address') {
        this.mailAdd = true;
      }
      if (this.data.type === 'Physical Address') {
        this.phpAdd = true;
      }
      if (this.data.type === 'Both Address') {
        this.mailAdd = true;
        this.phpAdd = true;
      }
     if (this.data.openPop == 'addAddress' || this.data.openPop == 'editAddress') {
       this.getStates();
     }
    }

    if (localStorage.getItem('user_type') === 'CARRIER') {
      this.checkUserType = true;
    } else {
      this.checkUserType = false;
    }

    this.serverPathForUrl = environment.serverPathForUrl;
    this.userType = localStorage.getItem('user_type');
    this.userId = localStorage.getItem('user_id');
    this.premiumType =  localStorage.getItem('premium');

    if (localStorage.getItem('user_type') === 'SHIPPER' || localStorage.getItem('user_type') === 'CARRIER') {
      this.searchRestriction = environment.searchMultipleRestriction;
    } else {
      this.searchRestriction = environment.searchRestriction;
    }

    this.currentId = localStorage.getItem('user_id');
    if (this.data.openPop === 'bidHistoryShow') {
      this.getHistoryData();
    }
    if (this.data.openPop === 'showOrderPreviousHistory') {
      this.getPrevHistoryData();
    }
    if (this.data.openPop === 'laneBidHistoryShow') {
      this.getlaneBidHistoryData();
    }
    this.templateShipment = this.formBuilder.group({
      title: [this.data.title ? this.data.title : '', [Validators.required]],
    });

    let bidMininumValue;
    if (
      this.data.shipmentDetail &&
      this.data.shipmentDetail.truckLoadType &&
      this.data.shipmentDetail.truckLoadType.slug === 'FTL'
    ) {
      bidMininumValue = this.data.shipmentDetail.miles * 5;
    } else if (
      this.data.shipmentDetail &&
      this.data.shipmentDetail.truckLoadType &&
      this.data.shipmentDetail.truckLoadType.slug === 'LTL'
    ) {
      bidMininumValue = this.data.shipmentDetail.miles * 8;
    } else {
    }

    this.redibForm = this.formBuilder.group({
      rebidAmount: [
        this.data.type =='bidAssist'? this.data.inputAmout:'',
        this.checkUserType
          ? [
            Validators.required,
            Validators.pattern(
              '^([0-9]*[1-9][0-9]*(.[0-9]+)?|[0]*.[0-9]*[1-9][0-9]*)$'
            ),
          ]
          : [
            Validators.required,
            Validators.pattern(
              '^([0-9]*[1-9][0-9]*(.[0-9]+)?|[0]*.[0-9]*[1-9][0-9]*)$'
            ),
          ],
      ],
      rebidNote: [''],   
    });

    this.bidForm = this.formBuilder.group({
      bidAmount: [this.data.type =='bidAssist'? this.data.inputAmout : '',
        this.checkUserType
          ? [
            Validators.required,
            Validators.pattern(
              '^([0-9]*[1-9][0-9]*(.[0-9]+)?|[0]*.[0-9]*[1-9][0-9]*)$'
            ),
          ]
          : [
            Validators.required,
            Validators.pattern(
              '^([0-9]*[1-9][0-9]*(.[0-9]+)?|[0]*.[0-9]*[1-9][0-9]*)$'
            ),
          ],
      ],
      bidNote: [''],
    });
    this.newLaneBidForm = this.formBuilder.group({
      laneBidAmount: [this.data.type =='bidAssist'? this.data.inputAmout : '', [Validators.required]],
      laneBidNote: [''],
    });

    let newAmount;
    if (this.data.amount) {
      newAmount = parseFloat(this.data.freightRate) + parseFloat('0.01');
    }

    this.overBidForm = this.formBuilder.group({
      bidAmount: [this.data.type =='bidAssist' ? this.data.amount: '', [Validators.required, Validators.min(newAmount)]],
      bidNote: [''],
    });

    let newFrieghtAmount;
    if (this.data.amount) {
      newFrieghtAmount = parseFloat(this.data.freightRate);
    }

    this.counterBiddForm = this.formBuilder.group({
      bidAmount: [this.data.type =='bidAssist' ? this.data.amount : '',[Validators.required, Validators.min(this.data.freightRate)]],
      bidNote: [''],
    });

    this.addressForm = this.formBuilder.group({
      addressType: [this.data.type, [Validators.required]],
      street: [
        this.data.addressLine1,
        [Validators.required, Validators.maxLength(255)],
      ],
      addressline2: [this.data.addressLine2, [Validators.maxLength(255)]],
      stateBy: [this.data.state, [Validators.required]],
      cityBy: [
        this.data.city,
        [
          Validators.required,
          Validators.maxLength(32),
        ],
      ],
      zipCode: [
        this.data.zipCode,
        [
          Validators.required,
          Validators.minLength(4),
          Validators.maxLength(8),
          Validators.pattern(/^[a-z0-9]+([-\s]{1}[a-z0-9]+)*$/i),
        ],
      ],
      country: [this.data.country],
    });
    
    if(this.data.city){
      this.city = this.data.city;
    }
    if(this.data.country){
      this.country = this.data.country;

    }
    this.streetValue = this.data.addressLine1 ? this.data.addressLine1 : '';
    this.streetValue2 = this.data.addressLine2 ? this.data.addressLine2 : '';
   
    this.getPriceLaneaxis();
    if (this.data.openPop === 'showReviews') {
      this.getRatingList();
    }

    if(this.data?.laneDetail && this.data?.preferredRegions?.length > 0){
      this.manageRegions();
    }
  }

  onNoClick(): void {
    this.dialogRef.close({ event: 'Ok' });
  }

  onNoClickPopup(): void {
    this.dialogRef.close({ event: 'Close' });
  }

  onClose(): void {
    this.dialogRef.close({ event: 'close' });
  }

  createNow() {
    this.dialogRef.close({ event: 'createNow' });
  }

  payPage() {
    this.dialogRef.close({ event: 'Oklahoma' });
  }

  createShipSubmit({ value, valid }) {
    if (valid) {
      this.dialogRef.close({ event: 'Ok', title: value.title });
    }
  }

  shipmentConfirm() {
    this.dialogRef.close({ event: 'shipmentConfirmation' });
  }

  shipmentConfirmed() {
    this.dialogRef.close({ event: 'shipperFinalPost' });
  }

  getStates(){
    this.commonService.getStateList().subscribe((serverRes) => {
      if (serverRes.success == true) {
       this.states = serverRes.response;
      } else if (serverRes.success == false) {
        this.alertService.showNotificationMessage(
          'danger',
          'bottom',
          'right',
          'txt_d',
          'check_circle',
          'States',
          'Unexpected Error.'
        );
      }
    });
  }

  saveAddress({ value, valid }) {
    if (valid) {
      this.loading = true;
      const formData = new FormData();
      if (value.addressType === 'Physical Address') {
        if (value.addressline2) {
          formData.append('phyAddressLine2', value.addressline2);
        }
        if (localStorage.getItem('user_type') === 'CARRIER') {
          formData.append('phyStreet', value.street);
          formData.append('phyZipShort', value.zipCode);
        } else {
          formData.append('phyAddressLine1', value.street);
          formData.append('phyZip', value.zipCode);
        }
        formData.append('phyCity', value.cityBy);
        formData.append('phyState', value.stateBy);
        formData.append('phyCountry', value.country);
      } else {
        if (value.addressline2) {
          formData.append('mailingAddressLine2', value.addressline2);
        }

        if (localStorage.getItem('user_type') === 'CARRIER') {
          formData.append('mailingStreet', value.street);
          formData.append('mailingZipShort', value.zipCode);
        } else {
          formData.append('mailingAddressLine1', value.street);
          formData.append('mailingZip', value.zipCode);
        }
        formData.append('mailingCity', value.cityBy);
        formData.append('mailingState', value.stateBy);
        formData.append('mailingCountry', value.country);
      }

      let getURL;
      let methodType;
      if (localStorage.getItem('user_type') === 'CARRIER') {
        getURL = AppSettings.APIsNameArray.CARRIER.UPDATE;
      }
      if (localStorage.getItem('user_type') === 'SHIPPER') {
        getURL = AppSettings.APIsNameArray.SHIPPER.UPDATE;
      }
      if (localStorage.getItem('user_type') === 'STORAGE') {
        getURL = AppSettings.APIsNameArray.COLDSTORAGE.UPDATE;
      }

      let APIparams = {
        apiKey: getURL,
        uri: '',
        postBody: formData,
      };

      if (localStorage.getItem('user_type') === 'CARRIER') {
        methodType = this.commonService.post(APIparams);
      } else {
        methodType = this.commonService.put(APIparams);
      }

      methodType.subscribe(
        (success) => {
          if (success.success === true) {
            this.loading = false;
            if (this.data.eventType == 'Subscription') {
              var dimensions = null;
              if (this.data.type) {
                dimensions = 'addressType=' + this.data.type;
              }
              if (this.data.addressLine1) {
                const addressLine1 = 'addressLine1=' + this.data.addressLine1;
                dimensions = dimensions ? dimensions + '&' + addressLine1 : addressLine1;
              }
              if (this.data.zipCode) {
                const zipCode = 'zipCode=' + this.data.zipCode;
                dimensions = dimensions ? dimensions + '&' + zipCode : zipCode;
              }
              let eventName;
              if (this.data.openPop === 'editAddress'){
                eventName = 'update_address';
              } else if (this.data.openPop === 'addAddress') {
                eventName = 'add_address';
              }
              var obj = {
                eventName: eventName,
                event_category: 'Subscription',
                userType: localStorage.getItem('user_type'),
                EmailID: localStorage.getItem('email'),
                Dimensions: dimensions ? dimensions : '-',
              };
              this.sharedService.track(obj);
            }
            this.dialogRef.close({ event: 'Address' });
          } else if (success.success === false) {
            this.loading = false;
            this.alertService.showNotificationMessage(
              'danger',
              'bottom',
              'right',
              'txt_d',
              'cancel',
              'Address',
              success.message
            );
          }
        },
        (error) => {
          this.alertService.showNotificationMessage(
            'danger',
            'bottom',
            'right',
            'txt_d',
            'cancel',
            'Address',
            AppSettings.ERROR
          );
          this.loading = false;
        }
      );
    }
  }

  /*Upload certificate of insurance*/
  templateDelete() {
    this.loading = true;
    let postBody = {
      templateId: this.data.templateId,
      status: '0',
    };
    let APIparams = {
      apiKey: AppSettings.APIsNameArray.TEMPLATE.DELETE,
      uri: '',
      postBody: postBody,
    };
    this.commonService.put(APIparams).subscribe(
      (success) => {
        if (success.success === true) {
          this.loading = false;
          this.dialogRef.close({
            event: 'Ok',
            id: this.data.templateId,
            status: this.data.status,
          });
        } else if (success.success === false) {
          this.alertService.showNotification(
            'danger',
            'bottom',
            'right',
            success.message
          );
          this.loading = false;
        }
      },
      (error) => {
        this.alertService.showNotification(
          'danger',
          'bottom',
          'right',
          AppSettings.ERROR
        );
        this.loading = false;
      }
    );
  }

  /*upload certificate of insurance*/
  templateUndo() {
    this.loading = true;
    let postBody = {
      templateId: this.data.templateId,
      status: '1',
    };
    let APIparams = {
      apiKey: AppSettings.APIsNameArray.TEMPLATE.DELETE,
      uri: '',
      postBody: postBody,
    };
    this.commonService.put(APIparams).subscribe(
      (success) => {
        if (success.success === true) {
          this.dialogRef.close({ event: 'Ok' });
        } else if (success.success === false) {
          this.alertService.showNotification(
            'danger',
            'bottom',
            'right',
            success.message
          );
        }
      },
      (error) => {
        this.alertService.showNotification(
          'danger',
          'bottom',
          'right',
          AppSettings.ERROR
        );
      }
    );
  }

  redibFormSubmit({ value, valid }) {
    this.submitted = true;
    if (valid) {
      if (this.data.orderId) {
        this.orderFormSubmit(value);
      } else {
        this.biddingFormSubmit(value);
      }
    }
  }

  orderFormSubmit(value) {
    let userId;
    if (this.userType === 'STORAGE') {
      userId = this.data.bidFor;
    }
    if (this.userType === 'SHIPPER') {
      userId = this.data.bidFor;
    }
    let eventName;
    if (this.data.openPop === 'rebid') {
      eventName = 'counter_bid';
    }
    if (this.data.openPop === 'bid') {
      eventName = 'first_bid';
    }
    this.submitted = true;
    this.loading = true;
    let amount;
    let note;
    if (value.rebidAmount && value.rebidNote) {
      let startingOfAmountReplaceZero = value.rebidAmount.replace(/^0+/, '');
      let getFinalAmount = parseFloat(startingOfAmountReplaceZero);
      amount = getFinalAmount;
      note = value.rebidNote;
    } else {
      let startingOfAmountReplaceZero = value.bidAmount.replace(/^0+/, '');
      let getFinalAmount = parseFloat(startingOfAmountReplaceZero);
      amount = getFinalAmount;
      note = value.bidNote;
    }
    let postBody;
    let APIparams;
    postBody = {
      orderId: this.data.orderId,
      bidAmount: amount,
      notes: note,
      bidFor: userId,
    };
    APIparams = {
      apiKey: AppSettings.APIsNameArray.STORAGEORDER.BIDADD,
      uri: '',
      postBody: postBody,
    };
    this.commonService.post(APIparams).subscribe(
      (success) => {
        if (success.success === true) {
          if (this.data.openPop === 'bid') {
            let shipmentCreateTime: any = this.data.orderDetail.createdAt;
            let now: any = new Date().toUTCString();
            var a = moment(shipmentCreateTime);
            var b = moment(now);
            this.timeSpent = this.hereMapsService.msToTime(b.diff(a));
            this.timeSpentFirstBid = this.timeSpent[0];
          }
          let shipmentType = 'ORDER';
          let SourceLocation = '-';
          if (
            this.data.orderDetail &&
            this.data.orderDetail.pickupLocationLongName
          ) {
            SourceLocation = this.data.orderDetail.pickupLocationLongName;
          }
          if (this.data.orderDetail && this.data.orderDetail.pickupLocation) {
            SourceLocation = this.data.orderDetail.pickupLocation;
          }
          let targetLocation = '-';
          if (
            this.data.orderDetail &&
            this.data.orderDetail.dropLocationLongName
          ) {
            targetLocation = this.data.orderDetail.dropLocationLongName;
          }
          if (this.data.orderDetail && this.data.orderDetail.dropLocation) {
            targetLocation = this.data.orderDetail.dropLocation;
          }
          let bidAmount = 'bidAmount=' + amount;
          var obj = {
            eventName: eventName,
            event_category: 'Order',
            Occurence: this.data.occurence ? this.data.occurence : '-',
            ShipmentID: this.data.orderId,
            userType: localStorage.getItem('user_type'),
            EmailID: localStorage.getItem('email'),
            Dimensions: bidAmount ? bidAmount : '-',
            ShipmentType: shipmentType ? shipmentType : '-',
            TimeSpent: this.timeSpentFirstBid ? this.timeSpentFirstBid : '-',
            EquipmentType: '-',
            SourceLocation: SourceLocation ? SourceLocation : '-',
            TargetLocation: targetLocation ? targetLocation : '-',
          };
          this.sharedService.track(obj);
          this.dialogRef.close({
            event: 'Ok',
            amount: amount,
            orderId: this.data.orderId,
          });
          this.loading = false;
        } else if (success.success === false) {
          this.alertService.showNotificationMessage(
            'danger',
            'bottom',
            'right',
            'txt_d',
            'cancel',
            'Bid',
            success.message
          );
          this.loading = false;
        }
      },
      (error) => {
        this.loading = false;
        this.alertService.showNotificationMessage(
          'danger',
          'bottom',
          'right',
          'txt_d',
          'cancel',
          'Bid',
          AppSettings.ERROR
        );
      }
    );
  }

  biddingFormSubmit(value) {
    let userId;
    if (this.userType === 'CARRIER') {
      userId = this.data.bidFor;
    }
    if (this.userType === 'SHIPPER') {
      userId = this.data.bidFor;
    }
    let eventName;
    if (this.data.openPop === 'rebid') {
      eventName = 'counter_bid';
    }
    if (this.data.openPop === 'bid') {
      eventName = 'first_bid';
    }
    this.submitted = true;
    this.loading = true;
    let amount;
    let note;
    if (value.rebidAmount && value.rebidNote) {
      let startingOfAmountReplaceZero = value.rebidAmount.replace(/^0+/, '');
      let getFinalAmount = parseFloat(startingOfAmountReplaceZero);
      amount = getFinalAmount;
      note = value.rebidNote;
    } else {
      let startingOfAmountReplaceZero = value.bidAmount.replace(/^0+/, '');
      let getFinalAmount = parseFloat(startingOfAmountReplaceZero);
      amount = getFinalAmount;
      note = value.bidNote;
    }
    let postBody;
    let APIparams;
    postBody = {
      shipmentId: this.data.shipmentId,
      bidAmount: amount,
      note: note,
      bidFor: userId,
    };
    APIparams = {
      apiKey: AppSettings.APIsNameArray.BIDS.ADD,
      uri: '',
      postBody: postBody,
    };
    this.commonService.post(APIparams).subscribe(
      (success) => {
        if (success.success === true) {
          if (this.data.openPop === 'bid') {
            let shipmentCreateTime: any = this.data.shipmentDetail.createdAt;
            let now: any = new Date().toUTCString();
            var a = moment(shipmentCreateTime);
            var b = moment(now);
            this.timeSpent = this.hereMapsService.msToTime(b.diff(a));
            this.timeSpentFirstBid = this.timeSpent[0];
          }
          let shipmentType = '-';
          if (
            this.data.shipmentDetail &&
            this.data.shipmentDetail.isOfferRate === 'Y'
          ) {
            shipmentType = 'OfferRate';
          }
          if (
            this.data.shipmentDetail &&
            this.data.shipmentDetail.isOfferRate === 'N'
          ) {
            shipmentType = 'Bidding';
          }
          let SourceLocation = '-';
          if (
            this.data.shipmentDetail &&
            this.data.shipmentDetail.pickupLocationLongName
          ) {
            SourceLocation = this.data.shipmentDetail.pickupLocationLongName;
          }
          if (
            this.data.shipmentDetail &&
            this.data.shipmentDetail.pickupLocation
          ) {
            SourceLocation = this.data.shipmentDetail.pickupLocation;
          }
          let targetLocation = '-';
          if (
            this.data.shipmentDetail &&
            this.data.shipmentDetail.dropLocationLongName
          ) {
            targetLocation = this.data.shipmentDetail.dropLocationLongName;
          }
          if (
            this.data.shipmentDetail &&
            this.data.shipmentDetail.dropLocation
          ) {
            targetLocation = this.data.shipmentDetail.dropLocation;
          }
          let bidAmount = 'bidAmount=' + amount;
          var obj = {
            eventName: eventName,
            event_category: 'Bidding',
            Occurence: this.data.occurence ? this.data.occurence : '-',
            ShipmentID: this.data.shipmentId,
            userType: localStorage.getItem('user_type'),
            EmailID: localStorage.getItem('email'),
            Dimensions: bidAmount ? bidAmount : '-',
            ShipmentType: shipmentType ? shipmentType : '-',
            TimeSpent: this.timeSpentFirstBid ? this.timeSpentFirstBid : '-',
            EquipmentType: this.data.shipmentDetail.equipmentName
              ? this.data.shipmentDetail.equipmentName
              : '-',
            SourceLocation: SourceLocation ? SourceLocation : '-',
            TargetLocation: targetLocation ? targetLocation : '-',
          };
          this.sharedService.track(obj);
          this.dialogRef.close({
            event: 'Ok',
            amount: amount,
            shipmentId: this.data.shipmentId,
          });
          this.loading = false;
        } else if (success.success === false) {
          this.alertService.showNotificationMessage(
            'danger',
            'bottom',
            'right',
            'txt_d',
            'cancel',
            'Bid',
            success.message
          );
          this.loading = false;
        }
      },
      (error) => {
        this.loading = false;
        this.alertService.showNotificationMessage(
          'danger',
          'bottom',
          'right',
          'txt_d',
          'cancel',
          'Bid',
          AppSettings.ERROR
        );
      }
    );
  }

  overBidFormSubmit({ value, valid }) {
    let userId;
    if (this.userType === 'CARRIER') {
      userId = this.data.bidFor;
    }
    if (this.userType === 'SHIPPER') {
      userId = this.data.bidFor;
    }
    this.submitted = true;
    if (valid) {
      this.loading = true;
      let amount;
      let note;
      if (value.rebidAmount && value.rebidNote) {
        let startingOfAmountReplaceZero = value.rebidAmount.replace(/^0+/, '');
        let getFinalAmount = parseFloat(startingOfAmountReplaceZero);
        amount = getFinalAmount;
        note = value.rebidNote;
      } else {
        let startingOfAmountReplaceZero = value.bidAmount.replace(/^0+/, '');
        let getFinalAmount = parseFloat(startingOfAmountReplaceZero);
        amount = getFinalAmount;
        note = value.bidNote;
      }
      let eventName;
      if (this.data.openPop === 'overBids') {
        eventName = 'over_bids';
      }
      if (this.data.openPop === 'rebidOverBids') {
        eventName = 'rebid_over_bids';
      }
      let postBody = {
        shipmentId: this.data.shipmentId,
        bidAmount: amount,
        note: note,
        bidFor: userId,
      };
      let APIparams = {
        apiKey: AppSettings.APIsNameArray.BIDS.ADD,
        uri: '',
        postBody: postBody,
      };
      this.commonService.post(APIparams).subscribe(
        (success) => {
          if (success.success === true) {
            let shipmentType = '-';
            if (
              this.data.shipmentDetail &&
              this.data.shipmentDetail.isOfferRate === 'Y'
            ) {
              shipmentType = 'OfferRate';
            }
            if (
              this.data.shipmentDetail &&
              this.data.shipmentDetail.isOfferRate === 'N'
            ) {
              shipmentType = 'Bidding';
            }
            let SourceLocation = '-';
            if (
              this.data.shipmentDetail &&
              this.data.shipmentDetail.pickupLocationLongName
            ) {
              SourceLocation = this.data.shipmentDetail.pickupLocationLongName;
            }
            if (
              this.data.shipmentDetail &&
              this.data.shipmentDetail.pickupLocation
            ) {
              SourceLocation = this.data.shipmentDetail.pickupLocation;
            }
            let targetLocation = '-';
            if (
              this.data.shipmentDetail &&
              this.data.shipmentDetail.dropLocationLongName
            ) {
              targetLocation = this.data.shipmentDetail.dropLocationLongName;
            }
            if (
              this.data.shipmentDetail &&
              this.data.shipmentDetail.dropLocation
            ) {
              targetLocation = this.data.shipmentDetail.dropLocation;
            }
            let bidAmount = 'bidAmount=' + amount;
            var obj = {
              eventName: eventName,
              event_category: 'Bidding',
              Occurence: this.data.occurence ? this.data.occurence : '-',
              ShipmentID: this.data.shipmentId,
              userType: localStorage.getItem('user_type'),
              EmailID: localStorage.getItem('email'),
              Dimensions: bidAmount ? bidAmount : '-',
              ShipmentType: shipmentType ? shipmentType : '-',
              EquipmentType: this.data.shipmentDetail.equipmentName
                ? this.data.shipmentDetail.equipmentName
                : '-',
              SourceLocation: SourceLocation ? SourceLocation : '-',
              TargetLocation: targetLocation ? targetLocation : '-',
            };
            this.sharedService.track(obj);
            this.dialogRef.close({
              event: 'Ok',
              amount: amount,
              shipmentId: this.data.shipmentId,
            });
            this.loading = false;
          } else if (success.success === false) {
            if (success.response?.bid === 'shipmentOverBidAlreadyDone') {
              this.dialogRef.close({
                event: 'Ok',
                amount: amount,
                shipmentId: this.data.shipmentId,
              });
            }
            this.alertService.showNotificationMessage(
              'danger',
              'bottom',
              'right',
              'txt_d',
              'cancel',
              'Bid',
              success.message
            );
            this.loading = false;
          }
        },
        (error) => {
          this.loading = false;
          this.alertService.showNotificationMessage(
            'danger',
            'bottom',
            'right',
            'txt_d',
            'cancel',
            'Bid',
            AppSettings.ERROR
          );
        }
      );
    }
  }

  getRatingList() {
    this.skeletonLoader = true;
    let uri = null;
    let paramsNew = {
      limit: 10,
      tabType: this.currentStatus,
    }
    paramsNew[this.data.ratingOwnerDetail.key] = this.data.ratingOwnerDetail.id;
    if (paramsNew) uri = this.commonService.getAPIUriFromParams(paramsNew);
    let APIparams = {
      apiKey: AppSettings.APIsNameArray.RATING.RATINGLIST,
      uri: uri,
    };
    this.commonService.getList(APIparams).subscribe(
      (ServerRes) => {
        if (ServerRes.response) {
          this.skeletonLoader = false;
          this.rateList = ServerRes.response;
        } else {
        }
      },
      (err) => {

        this.alertService.showNotificationMessage(
          'danger',
          'bottom',
          'right',
          'txt_g',
          'error',
          'Unexpected Error',
          AppSettings.ERROR
        );
      }
    );
  }

  getPriceLaneaxis() {
    let uri = null;
    let paramsNew = {};
    if (paramsNew) uri = this.commonService.getAPIUriFromParams(paramsNew);
    let APIparams = {
      apiKey: AppSettings.APIsNameArray.NEWPAYMENT.FEES,
      uri: uri,
    };
    this.commonService.getList(APIparams).subscribe(
      (ServerRes) => {
        if (ServerRes.response) {
          if (
            this.data.shipmentDetail &&
            this.data.shipmentDetail.terms === 'LaneAxis Terms'
          ) {
            if (this.userType === 'CARRIER') {
              let laneAxisPercent,
                laneaxisStripePercent,
                stripeFixed,
                StripeAfterPercent;

              laneAxisPercent = parseFloat(
                ServerRes.response.payOrder.usd.carrier.laneAxis.percent
              );
              laneaxisStripePercent = parseFloat(
                ServerRes.response.payOrder.usd.carrier.stripe.acDebitInPercent
              );
              stripeFixed = parseFloat(
                ServerRes.response.payOrder.usd.carrier.stripe.fixed
              );
              StripeAfterPercent = parseFloat(
                ServerRes.response.payOrder.usd.carrier.stripe.percent
              );

              let deduction =
                this.data.amount -
                (this.data.amount * (laneAxisPercent + laneaxisStripePercent)) /
                100;

              deduction =
                deduction -
                (deduction * StripeAfterPercent) / 100 -
                StripeAfterPercent;
              this.afterDeduction = deduction;
            }
          } else if (
            this.data.shipmentDetail &&
            this.data.shipmentDetail.terms === 'Shipper Terms'
          ) {
            if (this.userType === 'CARRIER') {
              let laneAxisPercent,
                stripeFixed,
                StripeAfterPercent;

              laneAxisPercent = parseFloat(
                ServerRes.response.payOrder.usdAch.carrier.laneAxis.percent
              );
              stripeFixed = parseFloat(
                ServerRes.response.payOrder.usdAch.carrier.stripe.fixed
              );
              StripeAfterPercent = parseFloat(
                ServerRes.response.payOrder.usdAch.carrier.stripe.percent
              );

              let deduction =
                this.data.amount - (this.data.amount * laneAxisPercent) / 100;

              deduction =
                deduction -
                (deduction * StripeAfterPercent) / 100 -
                StripeAfterPercent;

              this.afterDeduction = deduction;
            }
          }
        } else {
        }
      },
      (err) => {

        this.alertService.showNotificationMessage(
          'danger',
          'bottom',
          'right',
          'txt_g',
          'error',
          'Unexpected Error',
          AppSettings.ERROR
        );
      }
    );
  }

  counterBidSubmit({ value, valid }) {
    let userId;
    if (this.userType === 'CARRIER') {
      userId = this.data.bidFor;
    }
    if (this.userType === 'SHIPPER') {
      userId = this.data.bidFor;
    }
    this.submitted = true;
    if (valid) {
      this.loading = true;
      let amount;
      let note;
      if (value.rebidAmount && value.rebidNote) {
        let startingOfAmountReplaceZero = value.rebidAmount.replace(/^0+/, '');
        let getFinalAmount = parseFloat(startingOfAmountReplaceZero);
        amount = getFinalAmount;
        note = value.rebidNote;
      } else {
        let startingOfAmountReplaceZero = value.bidAmount.replace(/^0+/, '');
        let getFinalAmount = parseFloat(startingOfAmountReplaceZero);
        amount = getFinalAmount;
        note = value.bidNote;
      }
      let eventName;
      if (this.data.openPop === 'counterBid') {
        eventName = 'counter_bid';
      }
      let postBody = {
        shipmentId: this.data.shipmentId,
        bidAmount: amount,
        note: note,
        bidFor: userId,
      };
      let APIparams = {
        apiKey: AppSettings.APIsNameArray.BIDS.ADD,
        uri: '',
        postBody: postBody,
      };
      this.commonService.post(APIparams).subscribe(
        (success) => {
          if (success.success === true) {
            let shipmentType = '-';
            if (
              this.data.shipmentDetail &&
              this.data.shipmentDetail.isOfferRate === 'Y'
            ) {
              shipmentType = 'OfferRate';
            }
            if (
              this.data.shipmentDetail &&
              this.data.shipmentDetail.isOfferRate === 'N'
            ) {
              shipmentType = 'Bidding';
            }
            let SourceLocation = '-';
            if (
              this.data.shipmentDetail &&
              this.data.shipmentDetail.pickupLocationLongName
            ) {
              SourceLocation = this.data.shipmentDetail.pickupLocationLongName;
            }
            if (
              this.data.shipmentDetail &&
              this.data.shipmentDetail.pickupLocation
            ) {
              SourceLocation = this.data.shipmentDetail.pickupLocation;
            }
            let targetLocation = '-';
            if (
              this.data.shipmentDetail &&
              this.data.shipmentDetail.dropLocationLongName
            ) {
              targetLocation = this.data.shipmentDetail.dropLocationLongName;
            }
            if (
              this.data.shipmentDetail &&
              this.data.shipmentDetail.dropLocation
            ) {
              targetLocation = this.data.shipmentDetail.dropLocation;
            }
            let bidAmount = 'bidAmount=' + amount;
            var obj = {
              eventName: eventName,
              event_category: 'Bidding',
              Occurence: this.data.occurence ? this.data.occurence : '-',
              ShipmentID: this.data.shipmentId,
              userType: localStorage.getItem('user_type'),
              EmailID: localStorage.getItem('email'),
              Dimensions: bidAmount ? bidAmount : '-',
              ShipmentType: shipmentType ? shipmentType : '-',
              EquipmentType: this.data.shipmentDetail.equipmentName
                ? this.data.shipmentDetail.equipmentName
                : '-',
              SourceLocation: SourceLocation ? SourceLocation : '-',
              TargetLocation: targetLocation ? targetLocation : '-',
            };
            this.sharedService.track(obj);
            this.dialogRef.close({
              event: 'Ok',
              amount: amount,
              shipmentId: this.data.shipmentId,
            });
            this.loading = false;
          } else if (success.success === false) {
            if (success.response?.bid === 'shipmentCounterBidAlreadyDone') {
              this.dialogRef.close({
                event: 'Ok',
                amount: amount,
                shipmentId: this.data.shipmentId,
              });
            }
            this.alertService.showNotificationMessage(
              'danger',
              'bottom',
              'right',
              'txt_d',
              'cancel',
              'Bid',
              success.message
            );
            this.loading = false;
          }
        },
        (error) => {
          this.loading = false;
          this.alertService.showNotificationMessage(
            'danger',
            'bottom',
            'right',
            'txt_d',
            'cancel',
            'Bid',
            AppSettings.ERROR
          );
        }
      );
    }
  }



  openDriverAlertPopupBid(functionToCall) {
    const dialogRef = this.dialog.open(PopupComponent, {
      backdropClass: AppSettings.backdropClass,
      width: AppSettings.popWidth,
      height:'600px',
      disableClose: true,
      data: {
        openPop: 'driverMobileNotification',
        contain:event
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result.event == 'Ok') {

        if(functionToCall == 'offerRate' )
        {
          this.acceptOffer();
        }
        if( functionToCall == 'confirm' || functionToCall == 'acceptAndConfirm' )
        {
          this.accetpBid(functionToCall)
        }
      }
    });
  }

  acceptOffer() {
    let postBody;
    this.submitted = true;
    this.loading = true;
    let amount;
    let note;
    if (this.data.actionByOffer === 'confirmOfferPrice') {
      postBody = {
        shipmentId: this.data.shipmentId ? this.data.shipmentId : this.data.shipmentDetail?.id,
        bidAmount: this.data.amount,
        note: note,
        bidFor: this.data.bidFor,
        action: 'confirmOfferPrice',
      };
    } else {
      postBody = {
        shipmentId: this.data.shipmentId ? this.data.shipmentId : this.data.shipmentDetail?.id,
        bidAmount: this.data.amount,
        note: note,
        bidFor: this.data.bidFor,
      };
    }
    let eventName;
    if (this.data.openPop === 'acceptOfferRate') {
      eventName = 'accept_offer_rate';
    }
    let APIparams = {
      apiKey: AppSettings.APIsNameArray.BIDS.ADD,
      uri: '',
      postBody: postBody,
    };
    this.commonService.post(APIparams).subscribe(
      (success) => {
        if (success.success === true) {
          let shipmentType = '-';
          if (
            this.data.shipmentDetail &&
            this.data.shipmentDetail.isOfferRate === 'Y'
          ) {
            shipmentType = 'OfferRate';
          }
          if (
            this.data.shipmentDetail &&
            this.data.shipmentDetail.isOfferRate === 'N'
          ) {
            shipmentType = 'Bidding';
          }
          let SourceLocation = '-';
          if (
            this.data.shipmentDetail &&
            this.data.shipmentDetail.pickupLocationLongName
          ) {
            SourceLocation = this.data.shipmentDetail.pickupLocationLongName;
          }
          if (
            this.data.shipmentDetail &&
            this.data.shipmentDetail.pickupLocation
          ) {
            SourceLocation = this.data.shipmentDetail.pickupLocation;
          }
          let targetLocation = '-';
          if (
            this.data.shipmentDetail &&
            this.data.shipmentDetail.dropLocationLongName
          ) {
            targetLocation = this.data.shipmentDetail.dropLocationLongName;
          }
          if (
            this.data.shipmentDetail &&
            this.data.shipmentDetail.dropLocation
          ) {
            targetLocation = this.data.shipmentDetail.dropLocation;
          }
          let bidAmount = 'bidAmount=' + amount;
          var obj = {
            eventName: eventName,
            event_category: 'Bidding',
            Occurence: this.data.occurence ? this.data.occurence : '-',
            ShipmentID: this.data.shipmentId,
            userType: localStorage.getItem('user_type'),
            EmailID: localStorage.getItem('email'),
            Dimensions: '-',
            ShipmentType: shipmentType ? shipmentType : '-',
            EquipmentType: this.data.shipmentDetail.equipmentName
              ? this.data.shipmentDetail.equipmentName
              : '-',
            SourceLocation: SourceLocation ? SourceLocation : '-',
            TargetLocation: targetLocation ? targetLocation : '-',
          };
          this.sharedService.track(obj);
          this.data.actionByOffer = null;
          this.dialogRef.close({
            event: 'Ok',
            amount: amount,
            shipmentId: this.data.shipmentId,
          });
          this.loading = false;
        } else if (success.success === false) {
          if (success.response?.bid === 'shipmentAlreadyConfirmed') {
            this.data.actionByOffer = null;
            this.dialogRef.close({
              event: 'Ok',
              amount: amount,
              shipmentId: this.data.shipmentId,
            });
          } else if (success.response?.bid === 'shipmentOverBidAlreadyDone') {
            this.data.actionByOffer = null;
            this.dialogRef.close({
              event: 'Ok',
              amount: amount,
              shipmentId: this.data.shipmentId,
            });
          }
          this.alertService.showNotificationMessage(
            'danger',
            'bottom',
            'right',
            'txt_d',
            'cancel',
            'Bid',
            success.message
          );
          this.loading = false;
        }
      },
      (error) => {
        this.loading = false;
        this.alertService.showNotificationMessage(
          'danger',
          'bottom',
          'right',
          'txt_d',
          'cancel',
          'Bid',
          AppSettings.ERROR
        );
      }
    );
  }


  withdrawBid() {
    if (this.data.orderId) {
      this.withdrawBidOrder();
    } else {
      this.withdrawBidShipment();
    }
  }

  withdrawBidOrder() {
    this.loading = true;
    let postBody = {
      bidId: this.data.bidId,
      orderId: this.data.orderId,
      uniqueId: this.data.orderDetail.uniqueId,
    };
    let APIparams = {
      apiKey: AppSettings.APIsNameArray.STORAGEORDER.WITHDRAW,
      uri: '',
      postBody: postBody,
    };
    this.commonService.post(APIparams).subscribe(
      (success) => {
        if (success.success === true) {
          this.dialogRef.close({ event: 'Ok' });
          this.loading = false;
        } else if (success.success === false) {
          this.alertService.showNotificationMessage(
            'danger',
            'bottom',
            'right',
            'txt_d',
            'cancel',
            'Bid',
            success.message
          );
          this.loading = false;
        }
      },
      (error) => {
        this.loading = false;
        this.alertService.showNotificationMessage(
          'danger',
          'bottom',
          'right',
          'txt_d',
          'cancel',
          'Bid',
          AppSettings.ERROR
        );
      }
    );
  }

  withdrawBidShipment() {
    this.loading = true;
    let postBody = {
      bidId: this.data.bidId,
      shipmentId: this.data.shipmentId,
      uniqueId: this.data.uniqueId,
    };
    let APIparams = {
      apiKey: AppSettings.APIsNameArray.BIDS.WITHDRAL,
      uri: '',
      postBody: postBody,
    };
    this.commonService.post(APIparams).subscribe(
      (success) => {
        if (success.success === true) {
          let shipmentType = '-';
          if (
            this.data.shipmentDetail &&
            this.data.shipmentDetail.isOfferRate === 'Y'
          ) {
            shipmentType = 'OfferRate';
          }
          if (
            this.data.shipmentDetail &&
            this.data.shipmentDetail.isOfferRate === 'N'
          ) {
            shipmentType = 'Bidding';
          }
          let SourceLocation = '-';
          if (
            this.data.shipmentDetail &&
            this.data.shipmentDetail.pickupLocationLongName
          ) {
            SourceLocation = this.data.shipmentDetail.pickupLocationLongName;
          }
          if (
            this.data.shipmentDetail &&
            this.data.shipmentDetail.pickupLocation
          ) {
            SourceLocation = this.data.shipmentDetail.pickupLocation;
          }
          let targetLocation = '-';
          if (
            this.data.shipmentDetail &&
            this.data.shipmentDetail.dropLocationLongName
          ) {
            targetLocation = this.data.shipmentDetail.dropLocationLongName;
          }
          if (
            this.data.shipmentDetail &&
            this.data.shipmentDetail.dropLocation
          ) {
            targetLocation = this.data.shipmentDetail.dropLocation;
          }
          let bidAmount = 'bidAmount=' + this.data.amount;
          var obj = {
            eventName: 'withdraw_bid',
            event_category: 'Bidding',
            Occurence: this.data.occurence ? this.data.occurence : '-',
            ShipmentID: this.data.shipmentId,
            userType: localStorage.getItem('user_type'),
            EmailID: localStorage.getItem('email'),
            Dimensions: bidAmount ? bidAmount : '-',
            ShipmentType: shipmentType ? shipmentType : '-',
            EquipmentType: this.data.shipmentDetail.equipmentName
              ? this.data.shipmentDetail.equipmentName
              : '-',
            SourceLocation: SourceLocation ? SourceLocation : '-',
            TargetLocation: targetLocation ? targetLocation : '-',
          };
          this.sharedService.track(obj);
          this.dialogRef.close({ event: 'Ok' });
          this.loading = false;
        } else if (success.success === false) {
          this.alertService.showNotificationMessage(
            'danger',
            'bottom',
            'right',
            'txt_d',
            'cancel',
            'Bid',
            success.message
          );
          this.loading = false;
        }
      },
      (error) => {
        this.loading = false;
        this.alertService.showNotificationMessage(
          'danger',
          'bottom',
          'right',
          'txt_d',
          'cancel',
          'Bid',
          AppSettings.ERROR
        );
      }
    );
  }

  accetpBid(type: any) {
    this.loading = true;
    let eventName;
    if (type === 'accept') {
      eventName = 'accept_bid';
      this.accetpBidData(type, eventName)
    }
    if (type === 'acceptAndConfirm') {
      eventName = 'accept_and_confirm';
      this.dialogRef.close({ event: 'accept_and_confirm' });
    }
    if (type === 'confirm') {
      eventName = 'confirm_shipment';
      this.accetpBidData(type, eventName)
    }
    if (type === 'acceptBidConfirmOffer') {
      eventName = 'accept_Bid_Confirm_Offer';
      this.accetpBidData(type, eventName)
    }
  }
  accetpBidData(type: any,eventName:any) {
    this.loading = true;
    let postBody = {
      bidId: this.data.bidId,
      shipmentId: this.data.shipmentId,
      uniqueId: this.data.uniqueId,
      action: type,
    };
    let APIparams = {
      apiKey: AppSettings.APIsNameArray.BIDS.ACCEPT,
      uri: '',
      postBody: postBody,
    };
    this.commonService.post(APIparams).subscribe(
      (success) => {
        if (success.success === true) {
          let shipmentType = '-';
          if (
            this.data.shipmentDetail &&
            this.data.shipmentDetail.isOfferRate === 'Y'
          ) {
            shipmentType = 'OfferRate';
          }
          if (
            this.data.shipmentDetail &&
            this.data.shipmentDetail.isOfferRate === 'N'
          ) {
            shipmentType = 'Bidding';
          }
          let SourceLocation = '-';
         
          if (
            this.data.shipmentDetail &&
            this.data.shipmentDetail.pickupLocation
          ) {
            SourceLocation = this.data.shipmentDetail.pickupLocation;
          }
          if (
            this.data.shipmentDetail &&
            this.data.shipmentDetail.pickupLocationLongName
          ) {
            SourceLocation = this.data.shipmentDetail.pickupLocationLongName;
          }
          let targetLocation = '-';
          
          if (
            this.data.shipmentDetail &&
            this.data.shipmentDetail.dropLocation
          ) {
            targetLocation = this.data.shipmentDetail.dropLocation;
          }
          if (
            this.data.shipmentDetail &&
            this.data.shipmentDetail.dropLocationLongName
          ) {
            targetLocation = this.data.shipmentDetail.dropLocationLongName;
          }
          let bidAmount = 'bidAmount=' + this.data.amount;
          var obj = {
            eventName: eventName,
            event_category: 'Bidding',
            Occurence: this.data.occurence ? this.data.occurence : '-',
            ShipmentID: this.data.shipmentId,
            userType: localStorage.getItem('user_type'),
            EmailID: localStorage.getItem('email'),
            Dimensions: bidAmount ? bidAmount : '-',
            ShipmentType: shipmentType ? shipmentType : '-',
            EquipmentType: this.data.shipmentDetail.equipmentName
              ? this.data.shipmentDetail.equipmentName
              : '-',
            SourceLocation: SourceLocation ? SourceLocation : '-',
            TargetLocation: targetLocation ? targetLocation : '-',
          };
          this.sharedService.track(obj);
          this.dialogRef.close({ event: 'Ok' });
          this.loading = false;
        } else if (success.success === false) {
          if (success.response?.bid === 'shipmentAlreadyConfirmed') {
            this.dialogRef.close({ event: 'Ok' });
          }
          this.alertService.showNotificationMessage(
            'danger',
            'bottom',
            'right',
            'txt_d',
            'cancel',
            'Bid',
            success.message
          );
          this.loading = false;
        }
      },
      (error) => {
        this.loading = false;
        this.alertService.showNotificationMessage(
          'danger',
          'bottom',
          'right',
          'txt_d',
          'cancel',
          'Bid',
          AppSettings.ERROR
        );
      }
    );
  }

  acceptBidShipper() {
    this.loading = true;
    let postBody = {
      bidId: this.data.bidId,
      shipmentId: this.data.shipmentId,
      uniqueId: this.data.uniqueId,
      action: 'accept',
    };
    let APIparams = {
      apiKey: AppSettings.APIsNameArray.BIDS.ACCEPT,
      uri: '',
      postBody: postBody,
    };
    this.commonService.post(APIparams).subscribe(
      (success) => {
        if (success.success === true) {
          let shipmentType = '-';
          if (
            this.data.shipmentDetail &&
            this.data.shipmentDetail.isOfferRate === 'Y'
          ) {
            shipmentType = 'OfferRate';
          }
          if (
            this.data.shipmentDetail &&
            this.data.shipmentDetail.isOfferRate === 'N'
          ) {
            shipmentType = 'Bidding';
          }
          let SourceLocation = '-';
          if (
            this.data.shipmentDetail &&
            this.data.shipmentDetail.pickupLocationLongName
          ) {
            SourceLocation = this.data.shipmentDetail.pickupLocationLongName;
          }
          if (
            this.data.shipmentDetail &&
            this.data.shipmentDetail.pickupLocation
          ) {
            SourceLocation = this.data.shipmentDetail.pickupLocation;
          }
          let targetLocation = '-';
          if (
            this.data.shipmentDetail &&
            this.data.shipmentDetail.dropLocationLongName
          ) {
            targetLocation = this.data.shipmentDetail.dropLocationLongName;
          }
          if (
            this.data.shipmentDetail &&
            this.data.shipmentDetail.dropLocation
          ) {
            targetLocation = this.data.shipmentDetail.dropLocation;
          }
          let bidAmount = 'bidAmount=' + this.data.amount;
          var obj = {
            eventName: 'accept_bid',
            event_category: 'Bidding',
            Occurence: this.data.occurence ? this.data.occurence : '-',
            ShipmentID: this.data.shipmentId,
            userType: localStorage.getItem('user_type'),
            EmailID: localStorage.getItem('email'),
            Dimensions: bidAmount ? bidAmount : '-',
            ShipmentType: shipmentType ? shipmentType : '-',
            EquipmentType: this.data.shipmentDetail.equipmentName
              ? this.data.shipmentDetail.equipmentName
              : '-',
            SourceLocation: SourceLocation ? SourceLocation : '-',
            TargetLocation: targetLocation ? targetLocation : '-',
          };
          this.sharedService.track(obj);
          this.dialogRef.close({ event: 'shipperAccept' });
          this.loading = false;
        } else if (success.success === false) {
          this.alertService.showNotificationMessage(
            'danger',
            'bottom',
            'right',
            'txt_d',
            'cancel',
            'Bid',
            success.message
          );
          this.loading = false;
        }
      },
      (error) => {
        this.loading = false;
        this.alertService.showNotificationMessage(
          'danger',
          'bottom',
          'right',
          'txt_d',
          'cancel',
          'Bid',
          AppSettings.ERROR
        );
      }
    );
  }

  confirmAssign() {
    let shipmentType = '-';
    if (
      this.data.shipmentDetail &&
      this.data.shipmentDetail.isOfferRate === 'Y'
    ) {
      shipmentType = 'OfferRate';
    }
    if (
      this.data.shipmentDetail &&
      this.data.shipmentDetail.isOfferRate === 'N'
    ) {
      shipmentType = 'Bidding';
    }
    let SourceLocation = '-';
    if (
      this.data.shipmentDetail &&
      this.data.shipmentDetail.pickupLocationLongName
    ) {
      SourceLocation = this.data.shipmentDetail.pickupLocationLongName;
    }
    if (this.data.shipmentDetail && this.data.shipmentDetail.pickupLocation) {
      SourceLocation = this.data.shipmentDetail.pickupLocation;
    }
    let targetLocation = '-';
    if (
      this.data.shipmentDetail &&
      this.data.shipmentDetail.dropLocationLongName
    ) {
      targetLocation = this.data.shipmentDetail.dropLocationLongName;
    }
    if (this.data.shipmentDetail && this.data.shipmentDetail.dropLocation) {
      targetLocation = this.data.shipmentDetail.dropLocation;
    }
    let bidAmount = 'bidAmount=' + this.data.amount;
    var obj = {
      eventName: 'confirm_and_assign',
      event_category: 'Bidding',
      Occurence: this.data.occurence ? this.data.occurence : '-',
      ShipmentID: this.data.shipmentId,
      userType: localStorage.getItem('user_type'),
      EmailID: localStorage.getItem('email'),
      Dimensions: bidAmount ? bidAmount : '-',
      ShipmentType: shipmentType ? shipmentType : '-',
      EquipmentType: this.data.shipmentDetail.equipmentName
        ? this.data.shipmentDetail.equipmentName
        : '-',
      SourceLocation: SourceLocation ? SourceLocation : '-',
      TargetLocation: targetLocation ? targetLocation : '-',
    };
    this.sharedService.track(obj);
    this.dialogRef.close({ event: 'shipperConfirmAssign' });
  }

  confirmAssignOrder() {
    this.dialogRef.close({ event: 'orderConfirmAssign' });
  }

  // Order Previous bid history
  getPrAPIParam() {
    let APIparams, params;
    params = {
      orderId: this.data.orderId,
      storageId: this.data.storageId,
      limit: 10,
      page: this.page,
    };

    APIparams = {
      apiKey: AppSettings.APIsNameArray.STORAGEORDER.PREVIOUS,
      uri: this.commonService.getAPIUriFromParams(params),
    };
    return APIparams;
  }

  onPreScroll() {
    if (this.page !== this.totalPage) {
      this.page = this.page + 1;
      this.spinnerLoaderBid = true;
      this.getPreviousHistoryMoreData();
    }
  }
  //Previous bid history
  getPrevHistoryData() {
    this.bidHistorySekeletonLoader = true;
    this.page = 1;
    var APIparams = this.getPrAPIParam();
    this.commonService.getList(APIparams).subscribe((ServerRes) => {
      this.bidHistorySekeletonLoader = false;
      if (ServerRes.response && ServerRes.response) {
        for (let v = 0; v < ServerRes.response.length; v++) {
          ServerRes.response[v].createdAt = new Date(
            ServerRes.response[v]['createdAt'] + ' ' + 'UTC'
          );
        }
        this.previousBidHistory = ServerRes.response;
        this.totalPage = ServerRes.totalPage;
      } else {
        this.previousBidHistory = [];
        this.totalPage = 0;
      }
    });
  }

  getPreviousHistoryMoreData() {
    var APIparams = this.getHiAPIParam();
    this.commonService.getList(APIparams).subscribe((ServerRes) => {
      this.spinnerLoaderBid = false;
      if (ServerRes.response && ServerRes.response.length > 0) {
        for (let v = 0; v < ServerRes.response.length; v++) {
          if (ServerRes.response[v])
            this.previousBidHistory.push(ServerRes.response[v]);
        }
      }
    });
  }

  accetpOrderBid() {
    this.loading = true;
    let postBody = {
      bidId: this.data.bidId,
      orderId: this.data.orderId,
      action: 'confirm',
    };
    let APIparams = {
      apiKey: AppSettings.APIsNameArray.STORAGEORDER.ACCEPT,
      uri: '',
      postBody: postBody,
    };
    this.commonService.post(APIparams).subscribe(
      (success) => {
        if (success.success === true) {
          this.dialogRef.close({ event: 'Ok' });
          this.loading = false;
        } else if (success.success === false) {
          this.alertService.showNotificationMessage(
            'danger',
            'bottom',
            'right',
            'txt_d',
            'cancel',
            'Bid',
            success.message
          );
          this.loading = false;
        }
      },
      (error) => {
        this.loading = false;
        this.alertService.showNotificationMessage(
          'danger',
          'bottom',
          'right',
          'txt_d',
          'cancel',
          'Bid',
          AppSettings.ERROR
        );
      }
    );
  }

  getHiAPIParam() {
    let APIparams, params;
    params = {
      limit: 10,
      carrierId: this.data.userId,
      shipmentId: this.data.shipmentId,
      page: this.page,
    };
    APIparams = {
      apiKey: AppSettings.APIsNameArray.BIDS.PREHISTORY,
      uri: this.commonService.getAPIUriFromParams(params),
    };
    return APIparams;
  }

  getLaneHiAPIParam() {
    let APIparams, params;
    params = {
      limit: 10,
      carrierId: this.data.userId,
      laneId: this.data.laneId,
      page: this.page,
    };
    APIparams = {
      apiKey: AppSettings.APIsNameArray.BIDS.LANEBIDHISTORY,
      uri: this.commonService.getAPIUriFromParams(params),
    };
    return APIparams;
  }


  onHiScroll() {
    if (this.page !== this.totalPage) {
      this.page = this.page + 1;
      this.spinnerLoaderBid = true;
      this.getHistoryMoreData();
    }
  }

  //pre carrier
  getHistoryData() {
    this.bidHistorySekeletonLoader = true;
    this.page = 1;
    var APIparams = this.getHiAPIParam();
    this.commonService.getList(APIparams).subscribe((ServerRes) => {
      this.bidHistorySekeletonLoader = false;
      if (ServerRes.response) {
        for (let v = 0; v < ServerRes.response.length; v++) {
          ServerRes.response[v].actionTs = new Date(
            ServerRes.response[v]['actionTs'] + ' ' + 'UTC'
          );
        }
        this.previousBidHistory = ServerRes.response;
        this.totalPage = ServerRes.totalPage;
      } else {
        this.previousBidHistory = [];
        this.totalPage = 0;
      }
    });
  }

  getHistoryMoreData() {
    var APIparams = this.getHiAPIParam();
    this.commonService.getList(APIparams).subscribe((ServerRes) => {
      this.spinnerLoaderBid = false;
      if (ServerRes.response && ServerRes.response.length > 0) {
        for (let v = 0; v < ServerRes.response.length; v++) {
          if (ServerRes.response[v])
            this.previousBidHistory.push(ServerRes.response[v]);
        }
      }
    });
  }

  getLaneHistoryMoreData() {
    var APIparams = this.getHiAPIParam();
    this.commonService.getList(APIparams).subscribe((ServerRes) => {
      this.spinnerLoaderBid = false;
      if (ServerRes.response && ServerRes.response.length > 0) {
        for (let v = 0; v < ServerRes.response.length; v++) {
          if (ServerRes.response[v])
            this.previousLaneBidHistory.push(ServerRes.response[v]);
        }
      }
    });
  }

  getlaneBidHistoryData() {
    this.bidHistorySekeletonLoader = true;
    this.page = 1;
    var APIparams = this.getLaneHiAPIParam();
    this.commonService.getList(APIparams).subscribe((ServerRes) => {
      this.bidHistorySekeletonLoader = false;
      if (ServerRes.response) {
        for (let v = 0; v < ServerRes.response.length; v++) {
          ServerRes.response[v].actionTs = new Date(
            ServerRes.response[v]['actionTs'] + ' ' + 'UTC'
          );
        }
        this.previousLaneBidHistory = ServerRes.response;
        this.totalPage = ServerRes.totalPage;
      } else {
        this.previousLaneBidHistory = [];
        this.totalPage = 0;
      }
    });
  }

  onLaneHiScroll() {
    if (this.page !== this.totalPage) {
      this.page = this.page + 1;
      this.spinnerLoaderBid = true;
      this.getLaneHistoryMoreData();
    }
  }

  generateFake(count: number): Array<number> {
    const indexes = [];
    for (let i = 0; i < count; i++) {
      indexes.push(i);
    }
    return indexes;
  }

  checkSpace(word: any) {
    if (this.streetValue.length >= 1) {
      word = this.streetValue;
    }
    let lastword = word.charAt(word.length - 1);
    if (lastword === ' ') {
      this.countSpace = this.countSpace + 1;
    } else {
      this.countSpace = 0;
    }
    if (this.countSpace >= 2 || (lastword === ' ' && word.length === 1)) {
      this.streetValue = this.streetValue.substring(
        0,
        this.streetValue.length - 1
      );
    } else {
      this.searchBoxError = false;
    }
  }

  checkSpace2(word: any) {
    if (this.streetValue2.length >= 1) {
      word = this.streetValue2;
    }
    let lastword = word.charAt(word.length - 1);
    if (lastword === ' ') {
      this.countSpace2 = this.countSpace2 + 1;
    } else {
      this.countSpace2 = 0;
    }
    if (this.countSpace2 >= 2 || (lastword === ' ' && word.length === 1)) {
      this.streetValue2 = this.streetValue2.substring(
        0,
        this.streetValue2.length - 1
      );
      this.searchBoxError = true;
    } else {
      this.searchBoxError = false;
    }
  }

  checkSpace3(word: any) {
    if (this.city.length >= 1) {
      word = this.city;
    }
    let lastword = word.charAt(word.length - 1);
    if (lastword === ' ') {
      this.countSpace3 = this.countSpace3 + 1;
    } else {
      this.countSpace3 = 0;
    }
    if (this.countSpace3 >= 2 || (lastword === ' ' && word.length === 1)) {
      this.city = this.city.substring(0, this.city.length - 1);
      this.searchBoxError = true;
    } else {
      this.searchBoxError = false;
    }
  }

  priceAmount(price: any) {
    return parseFloat(price).toFixed(0);
  }

  orderDelete() {
    this.dialogRef.close({
      event: 'delete',
    });
  }

  pickAutoCompleteSelected(result: any) {
    for (let i = 0; i < result.address_components.length; i++) {
      if (result.address_components[i].types[0] == "postal_code") {
        if (result.address_components[i].long_name) {
          this.addressForm.get('zipCode').setValue(result.address_components[i].long_name);
        } else {
          this.addressForm.get('zipCode').setValue('');
        }
      }
    }
  }
 
  phyAddresSearch($event: GermanAddress) {
    this.addressForm.get('street').setValue('');
    this.addressForm.get('addressline2').setValue('');
    this.addressForm.get('cityBy').setValue('');
    this.addressForm.get('stateBy').setValue('');
    this.addressForm.get('country').setValue('');
    this.latitude = null;
    this.longitude = null;
    this.addressForm.get('street').setValue($event.name);
    this.addressForm.get('addressline2').setValue($event.sublocality);
    this.addressForm.get('cityBy').setValue($event.locality.long);
    this.addressForm.get('stateBy').setValue($event.state.short);
    if(this.addressForm.get('country').value){
      this.addressForm.controls['country'].disable();
    }
    if ($event.country.short === 'US') {
      this.selectedCountry = this.countryList.filter(
        (item) => item.country === $event.country.short);
    }
     else {
      this.selectedCountry = this.countryList.filter(
        (item) => item.country === $event.country.long);
    }
    this.addressForm.get('country').setValue(this.selectedCountry[0].country);
    this.latitude = $event.geoLocation.latitude;
    this.longitude = $event.geoLocation.longitude;
    this.addressForm.get('zipCode').setValue($event.postalCode);
  }

  getConfigSet(configValue: any) {
    this.countryList = configValue.countries;
  }

  newLaneBidFormSubmit({value,valid}){
    let userId;
    if (this.userType === 'CARRIER') {
      userId = this.data.bidFor;
    }
    if (this.userType === 'SHIPPER') {
      userId = this.data.bidFor;
    }
    this.submitted = true;
    if(valid) {
    this.loading = true;
    let amount;
    let note;
    if (value.rebidAmount && value.rebidNote) {
      let startingOfAmountReplaceZero = value.rebidAmount.replace(/^0+/, '');
      let getFinalAmount = parseFloat(startingOfAmountReplaceZero);
      amount = getFinalAmount;
      note = value.rebidNote;
    } else {
      let startingOfAmountReplaceZero = value.laneBidAmount.replace(/^0+/, '');
      let getFinalAmount = parseFloat(startingOfAmountReplaceZero);
      amount = getFinalAmount;
      note = value.laneBidNote;
    }
    let postBody;
    let APIparams;
    postBody = {
      laneId: this.data.laneId,
      bidAmount: amount,
      note: note,
      bidFor: userId,
    };
    APIparams = {
      apiKey: AppSettings.APIsNameArray.BIDS.LANEADD,
      uri: '',
      postBody: postBody,
    };
    this.commonService.post(APIparams).subscribe(
      (success) => {
        if (success.success === true) {
          this.loading = false;
          this.data.isDisabled = true;
          this.dialogRef.close({
            event: 'Ok',
            amount: amount,
            shipmentId: this.data.laneId,
          });
        } else if (success.success === false) {
          this.alertService.showNotificationMessage(
            'danger',
            'bottom',
            'right',
            'txt_d',
            'cancel',
            'Bid',
            success.message
          );
          this.loading = false;
        }
      },
      (error) => {
        this.loading = false;
        this.alertService.showNotificationMessage(
          'danger',
          'bottom',
          'right',
          'txt_d',
          'cancel',
          'Bid',
          AppSettings.ERROR
        );
      }
    );
    }
  }
  
  accetpBidLane(type: any) {
    this.loading = true;
    let postBody = {
      bidId: this.data.laneBidId,
      laneId: this.data.laneId,
      action: type,
    };
    let APIparams = {
      apiKey: AppSettings.APIsNameArray.BIDS.LANEACCEPT,
      uri: '',
      postBody: postBody,
    };
    this.commonService.post(APIparams).subscribe(
      (success) => {
        if (success.success === true) {
          const dialogRef = this.dialog.open(PopupComponent, {
            backdropClass: AppSettings.backdropClass,
            width: AppSettings.popWidth,
            disableClose: true,
            data: {
              openPop: 'confirmLanenotShipment',
            },
          });
          dialogRef.afterClosed().subscribe((result) => {
            if (result) {
              if (result.event === 'Ok') {
                this.dialogRef.close({ event: 'Ok' });
              }
            }
          });
       
          this.loading = false;
          this.data.isDisabled = true;
        } else if (success.success === false) {
         
          this.alertService.showNotificationMessage(
            'danger',
            'bottom',
            'right',
            'txt_d',
            'cancel',
            'Bid',
            success.message
          );
          this.loading = false;
        }
      },
      (error) => {
        this.loading = false;
        this.alertService.showNotificationMessage(
          'danger',
          'bottom',
          'right',
          'txt_d',
          'cancel',
          'Bid',
          AppSettings.ERROR
        );
      }
    );
  }

  regionAddSpace(regionType){
    return regionType.join(', ');
  }

  manageRegions(){
    this.regionSelectedData=[];
    this.selectedRegion =[];
    if (this.data.laneDetail && this.data.preferredRegions) {
      let newStateid = [];
      let newStateName = [];
      let data = this.data.preferredRegions;
      var regions = {};
      for (var i = 0; i < data.length; i++) {
        var regionName = data[i].region;
        if (!regions[regionName]) {
          regions[regionName] = [];
        }
        regions[regionName].push({
          stateId: data[i].id,
          stateName: data[i].state,
        });
      }
      for (let regionGroupName in regions) {
        for (let key in regions[regionGroupName]) {
          newStateid.push(regions[regionGroupName][key]["stateId"]);
          newStateName.push(regions[regionGroupName][key]["stateName"]);
        }
        this.selectedRegion.push(regionGroupName)
        this.regionSelectedData.push({
          region: regionGroupName,
          stateId: newStateid,
          stateNameArray: newStateName,
        });
        newStateid = [];
        newStateName = [];
      }
    }
  }

  buyCredit(){
    if (localStorage.getItem('premium') == '1') {
      this.router.navigate(['/carrier/subscription-overview/additional-invites']);
    } else if (localStorage.getItem('premium') == '0'){
      this.router.navigate(['/carrier/premium-plan']);
    }
    this.onClose();
  }

  checkDocument($event) {
    this.documentCheck = $event.checked;
  }

  sendAcceptInvite(type) {
    if (this.userType == 'CARRIER') {
      if (type.laneDetail.rateType == '1' || type.rateType == 'acceptForCarrierList') { 
        this.accetpBidLane('accept');
      }
      if (type.laneDetail.rateType == '2') { 
         this.accetpBidLane('acceptAndConfirm');
      }
    }
    if (this.userType == 'SHIPPER') {
      this.accetpBidLane('accept');
    }
  }

  getArchivedLane() {
    this.loading = true;
    let uri = null;
    let postBody = {
      id: this.data.laneId,
      isArchive: this.data.isArchived
    };
    let APIparams = {
      apiKey: AppSettings.APIsNameArray.SHIPMENT.EDITLANES,
      uri: '',
      postBody: postBody,
    };
    this.commonService.put(APIparams).subscribe(
      (success) => {
        if (success.success === true) {
          this.dialogRef.close({ 
            event: 'Ok',
            success: success.success
           });
          this.alertService.showNotificationMessage(
            'success',
            'bottom',
            'right',
            'txt_s',
            'check_circle',
            'Archive Lane',
            'Lane Archived successfully.'
          );
        } else if (success.success === false) {
          this.alertService.showNotification(
            'danger',
            'bottom',
            'right',
            success.message
          );
        }
      },
      (error) => {
        this.alertService.showNotification(
          'danger',
          'bottom',
          'right',
          AppSettings.ERROR
        );
      }
    );
  }


}

