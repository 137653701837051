import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { CommonService } from '../../commons/service/common.service';
import { AppSettings } from '../../commons/setting/app_setting';
import { AlertService } from '../../commons/service/alert.service';
import { SharedService } from '../../commons/service/shared.service';
import { MatDialog } from '@angular/material/dialog';
import { Router } from "@angular/router";
import { UserGuidePopupComponent } from '../../user-guide/user-guide-popup/user-guide-popup.component';

@Component({
  selector: 'app-top-banner-guide',
  templateUrl: './top-banner-guide.component.html',
  styleUrls: ['./top-banner-guide.component.css'],
})
export class TopBannerGuideComponent implements OnInit {
  @Input() moduleType : any;
   public  userTutorial : any=[];
  public showBanner = true;
  @Output() outputUserName = new EventEmitter<string>();

  constructor(
    private router: Router,
    public sharedService: SharedService,
    public dialog: MatDialog,
    public commonService: CommonService,
    public alertService: AlertService
  ) { }

  ngOnInit() {
    if(localStorage.getItem('user_type')){
      this.getData(null)
    }
  
    let isCookieExists = this.getCookie(this.moduleType);
    if(isCookieExists === "false"){
      this.showBanner = false; 
    }
  }

  viewAllGuides(){
      if(localStorage.getItem('user_type')=="CARRIER"){
        this.router.navigate(['/tutorial/carrier/booking/bid-on-lane']);
      }else if(localStorage.getItem('user_type')=="SHIPPER"){
        this.router.navigate(['/tutorial/carrier/booking/create-a-lane']);
      }
  }
  
  getCookie(name) {
    let cookie = {};
     if(document.cookie){
      document.cookie.split(';').forEach(function(el) {
        let [k,v] = el.split('=');
         cookie[k.trim()] = v;
      })
      return cookie[name];
    }
  }
  
  dissMissBanner(){
    const dialogRef = this.dialog.open(UserGuidePopupComponent, {
      disableClose: true,
      backdropClass: AppSettings.backdropClass,
      width: '400px',
      data: {
        openPop: "dissMissConfirmation",
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if(result.event=="dissMiss"){
        this.showBanner=false;
         document.cookie = this.moduleType+'=false'; 
      }else{
      }
    });
  }

  getAPIParam(str) {
    let APIparams, params;
    APIparams = {
      apiKey: AppSettings.APIsNameArray.SHIPPER.home,
      uri: this.commonService.getAPIUriFromParams(params),
    };
    return APIparams;
  }

  getData(str) {
    var APIparams = this.getAPIParam(str);
    this.commonService.getList(APIparams).subscribe((ServerRes) => {
      if(ServerRes.response){        
        if(localStorage.getItem('user_type')=="SHIPPER"){
          for (const key in ServerRes.response?.shipperTutorial) {
            if (ServerRes.response?.shipperTutorial.hasOwnProperty(key) && key===this.moduleType) {
              for(let record of ServerRes.response?.shipperTutorial[key]['items']){
                if(record.ismoduleFeatured==1){
                  this.userTutorial.push(record);
                 }
              }
              break;
            }else if(this.moduleType=='Recommended Guides'){
              for (let record of ServerRes.response?.shipperTutorial[key]["items"]) {
                if(record.isSystemFeatured==1){
                  this.userTutorial.push(record);
                 }
              }
            }
          }
        }else if(localStorage.getItem('user_type')=="CARRIER"){
          for (const key in ServerRes.response?.carrierTutorial) {
            if (ServerRes.response?.carrierTutorial.hasOwnProperty(key) && key===this.moduleType) {
              for(let record of ServerRes.response?.carrierTutorial[key]['items']){
                if(record.ismoduleFeatured==1){
                  this.userTutorial.push(record);
                 }
              }
              break;
            }else if(this.moduleType=='Recommended Guides'){
              for (let record of ServerRes.response?.carrierTutorial[key]["items"]) {
                if(record.isSystemFeatured==1){
                 this.userTutorial.push(record);
                }
             }
            }
          }
        }else if(localStorage.getItem('user_type')=="DRIVER"){
          for (const key in ServerRes.response.driverTutorial) {
            if (ServerRes.response.driverTutorial.hasOwnProperty(key) && key===this.moduleType) {
              for(let record of ServerRes.response.driverTutorial[key]['items']){
                if(record.ismoduleFeatured==1){
                  this.userTutorial.push(record);
                 }
              }
              break;
            }else if(this.moduleType=='Recommended Guides'){
              for (let record of ServerRes.response.driverTutorial[key]["items"]) {
                if(record.isSystemFeatured==1){
                 this.userTutorial.push(record);
                }
             }
            }
          }
        }
      }
    });
  }

  showChildTabData(record){
    this.outputUserName.emit(record);
  }

}
