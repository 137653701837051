<div class="sign_in_header">
  <app-login-navbar></app-login-navbar>
</div>
<div class="sign_in_body">
  <div class="sign_in_box">
    <div class="signin_inner">
      <div class="sign_in_con">
        <form [formGroup]="verifyOtp" (ngSubmit)="verifyOtpSubmit(verifyOtp)" class="custum_input_css" autocomplete="off">
          <div class="email_otp_box">
            <p class="sf_form_tlt" *ngIf="isOtpVerify">Verify your mobile number</p>
            <p class="sf_form_tlt" *ngIf="!isOtpVerify">Verify your email address</p>

            <p class="fs_14 fsn_4 txt_lb" style="margin: 0 0 1rem;">We've sent a 6-digit verification code to <span class="fsm_5 txt_b">{{showVerifyWay}}</span>.  If you are registered in our system please check your inbox (or possible spam folder) and enter the code you received below to confirm your  
              <span *ngIf="isOtpVerify">Mobile number</span> 
              <span *ngIf="!isOtpVerify">Email address</span>.
            </p>

            <div class="input_field_box">
              <p class="fs_14 fsn_4 txt_lb mb-1">Verification code <span class="txt_d">*</span></p>
              <mat-form-field appearance="none" class="resend_otp_space">
                <input matInput type="text" placeholder="Enter verification code" id="OnboardingStep3_flow012_step_1" formControlName="verifyUserOtp" name="verifyUserOtp" class="form-control custom_input" maxlength="6" (input)="keyPaste($event)" (keypress)="keyPress($event)"/>
                <mat-error *ngIf="!oopsError && !showMessage && verifyOtpSubmmited && verifyOtp.get('verifyUserOtp').errors?.['required']" class="OTP_no_error error_mess">Verification is required.</mat-error>
                <mat-error *ngIf="!oopsError && !showMessage && verifyOtpSubmmited && verifyOtp.get('verifyUserOtp').errors?.['pattern']" class="OTP_no_error error_mess">Verification code should be number.</mat-error>
              </mat-form-field>
              <p class="resend_otp_box fs_14 fsm_5">
                <i class="txt_p pointer " (click)="resendOTP()">Resend <span *ngIf="!isOtpVerify">email</span> <span *ngIf="isOtpVerify">phone</span> verification code.</i>
                <span *ngIf="spinnerLoading" class="spinner-border spinner_border text-primary" role="status">
                  <span class="sr-only">
                    <!-- Loading... -->
                  </span>
                </span>
                <small class="fs_14 fsn_4 txt_s" *ngIf="showMessage">{{showMessage}}</small>
                <small class="error_mess" *ngIf="oopsError">{{oopsError}}</small>
              </p>
            </div>
            <p class="fs_14 fsn_4" style="margin: .8rem 0;">
              Having trouble? <span class="txt_p pointer" (click)="verifyChangeWay()">Verify in another way.</span>
            </p>
            <div class="form_btm_actn">
              <button class="btn custum_button button_primary" type="submit">Verify</button>
              <span class="d-block mt-4 fs_16 fsm_5 txt_p text-center"><u class="pointer " (click)="goToLoginReset()">Save & Exit</u></span>
            </div>
          </div>
        </form>
      </div>
      <p class="sign_up_txt">
        <span>Don't have an account?</span> 
        <u (click)="singUp()">Sign up</u>
      </p>
    </div>
  </div>
  <app-footer></app-footer>
</div>
<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '14px',fullScreenBackdrop:true }"></ngx-loading>