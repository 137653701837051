import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css'],
})
export class FooterComponent implements OnInit {
  public userLogin = false;
  public isLogin: any;
  constructor(private router: Router) { }

  ngOnInit() {
    this.isLogin = localStorage.getItem('login');
    if (localStorage.getItem('access_token') !== null) {
      this.userLogin = true;
    } else {
      this.userLogin = false;
    }
  }

  loginPage() {
    this.router.navigate(['/']);
  }
}
