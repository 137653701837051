<div class="shipment_details">
    <p class="panel_card_tlt">Pickup Information(s)<span class="fs_16 fsm_5 txt_lb">(Total {{pickupWayPoint?.length}} Pickup)</span></p>
    <div *ngIf="loadDetail && !skeletonLoader">
      <div class="updated_card">
        <div class="row pick_cards">
          <div class="col-md-6" *ngFor="let pickupData of pickupWayPoint; let pickupIndex = index">
            <div class="detail_card_con ms-0" [ngClass]="pickupData?.pickupViewInfo===pickupIndex ? 'pickupHideShow' : ''">
              <span class="card_counts">{{pickupIndex + 1}}</span>
              <div class="con_wrapper">
                <div class="con_wrapper_row">
                  <div class="row_label">Business Name</div>
                  <div class="row_value text_truncate pointer" matTooltip="{{pickupData?.businessName}}">{{pickupData?.businessName ? pickupData?.businessName : '-'}}</div>
                </div>
                <div class="con_wrapper_row">
                  <div class="row_label">
                    <span class="tlt_line">Pickup Location Point {{pickupIndex + 1}}</span>
                  </div>
                  <div class="row_value loca_vlu txt_two_line" *ngIf="userType==='SHIPPER'">{{pickupData?.locationLongName}}</div>
                  <div class="row_value loca_vlu txt_two_line" *ngIf="userType==='CARRIER' && loadDetail?.parentStatus !== 1 && loadDetail?.parentStatus !== 3 && loadDetail?.parentStatus !== 4 && loadDetail?.parentStatus !== 5">
                    {{pickupData?.locationLongName}}
                  </div>
                  <div class="row_value loca_vlu txt_two_line" *ngIf="userType==='CARRIER' && (loadDetail?.parentStatus === 1 || loadDetail?.parentStatus === 3 || loadDetail?.parentStatus === 4 || loadDetail?.parentStatus === 5)">
                    {{pickupData?.locationLongName ? pickupData?.locationLongName : '-'}}
                  </div>
                </div>
                <div class="con_wrapper_row">
                  <div class="row_label">ZIP Code</div>
                  <div class="row_value">{{pickupData?.zip}}</div>
                </div>
                <div class="con_wrapper_row">
                  <div class="row_label">Date & Time</div>
                  <div class="row_value" *ngIf="pickupData?.date" style="word-break: break-word;">
                    <span>{{pickupData?.date | date: 'MMM dd, yyyy'}} {{pickupData?.timeSlot}} {{pickupData?.timeZone}}</span>
                  </div>
                </div>
                <div class="con_wrapper_row crd_btm_actns" (click)="showDetailCommodity(pickupData)">
                  <span class="view_card_url">View More Information</span>
                </div>
              </div>
            </div>
          </div>
        </div>
  
        <p class="panel_card_tlt mt-3">Dropoff Information(s)<span class="fs_16 fsm_5 txt_lb">(Total {{dropoffWayPoint?.length}} Dropoff)</span></p>
        <div class="row drop_cars">
          <div class="col-md-6" *ngFor="let dropoffData of dropoffWayPoint;let dropoffIndex = index">
            <div class="detail_card_con ms-0" [ngClass]="dropoffData?.dropoffViewInfo===dropoffIndex ? 'pickupHideShow' : ''">
              <span class="card_counts">{{dropoffIndex + 1}}</span>
              <div class="con_wrapper">
                <div class="con_wrapper_row">
                  <div class="row_label">Business Name</div>
                  <div class="row_value text_truncate pointer" matTooltip="{{dropoffData?.businessName}}">{{dropoffData?.businessName ? dropoffData?.businessName : '-'}}</div>
                </div>
                <div class="con_wrapper_row">
                  <div class="row_label">
                    <span class="tlt_line">Dropoff Location Point {{dropoffIndex + 1}}</span>
                  </div>
                  <div class="row_value loca_vlu txt_two_line" *ngIf="userType==='SHIPPER'">{{dropoffData?.locationLongName}}</div>
                  <div class="row_value loca_vlu txt_two_line" *ngIf="userType==='CARRIER' && loadDetail?.parentStatus !== 1 && loadDetail?.parentStatus !== 3 && loadDetail?.parentStatus !== 4 && loadDetail?.parentStatus !== 5">
                    {{dropoffData?.locationLongName}}
                  </div>
                  <div class="row_value loca_vlu txt_two_line" *ngIf="userType==='CARRIER' && (loadDetail?.parentStatus === 1 || loadDetail?.parentStatus === 3 || loadDetail?.parentStatus === 4 || loadDetail?.parentStatus === 5)">
                    {{dropoffData?.locationLongName ? dropoffData?.locationLongName : '-'}}
                  </div>
                </div>
                <div class="con_wrapper_row">
                  <div class="row_label">ZIP Code</div>
                  <div class="row_value">{{dropoffData?.zip}}</div>
                </div>
                <div class="con_wrapper_row">
                  <div class="row_label">Date & Time</div>
                  <div class="row_value" *ngIf="dropoffData?.date" style="word-break: break-word;">
                    <span>{{dropoffData?.date | date: 'MMM dd, yyyy'}} {{dropoffData?.timeSlot}} {{dropoffData?.timeZone}}</span>
                  </div>
                </div>
                <div class="con_wrapper_row crd_btm_actns" (click)="showDetailCommodity(dropoffData)">
                  <span class="view_card_url">View More Information</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  
      <div class="detail_card_con" *ngIf="userType==='CARRIER' && !loadDetail?.shipperId">
        <p class="line_before">Shipper Details</p>
        <div class="con_wrapper">
          <div class="row">
            <div class="col-md-3">
              <div class="con_wrapper_row">
                <div class="row_label">
                  <div class="mb-2">Shipper name</div>
                  <div class="row_value">
                    {{loadDetail?.shipperName ? loadDetail?.shipperName: '-'}}
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-3">
              <div class="con_wrapper_row">
                <div class="row_label">
                  <div class="mb-2">Shipper Email</div>
                  <div class="row_value">{{loadDetail?.shipperEmail ?
                    loadDetail?.shipperEmail : '-'}}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  
      <div class="detail_card_con">
        <p class="line_before">Equipment Details</p>
        <div class="con_wrapper">
          <div class="row">
            <div class="col-md-3">
              <div class="con_wrapper_row">
                <div class="row_label">
                  <div class="mb-2">Equipment Type</div>
                  <div class="row_value">
                    {{loadDetail?.equipmentName ? loadDetail?.equipmentName: '-'}}
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-3">
              <div class="con_wrapper_row">
                <div class="row_label">
                  <div class="mb-2">Equipment Length</div>
                  <div class="row_value">{{loadDetail?.equipmentLength ?
                    loadDetail?.equipmentLength +' Feet' : '-'}}</div>
                </div>
              </div>
            </div>
            <div class="col-md-3">
              <div class="con_wrapper_row">
                <div class="row_label">
                  <div class="mb-2">Purchase Order</div>
                  <div class="row_value">{{loadDetail.purchaseOrderId ? loadDetail.purchaseOrderId : '-'}}
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-3">
              <div class="con_wrapper_row">
                <div class="row_label">
                  <div class="mb-2">Accessorials</div>
                  <div class="row_value">{{trailSpecialRequipment(loadDetail?.trailerSpecs)}}</div>
                </div>
              </div>
            </div>
            <div class="col-md-3" *ngIf="isTemprature">
              <div class="con_wrapper_row">
                <div class="row_label">
                  <div class="mb-2">Temperature Controlled</div>
                  <div class="row_value">{{loadDetail?.temperature ? loadDetail?.temperature: '-'}}
                    <span *ngIf="loadDetail?.temperature">F</span></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  
      <div class="picupDrop_Container" *ngIf="loadDetail?.isOfferRate === 'N' && loadDetail?.shippers?.id && userType === 'CARRIER'">
          <div class="detail_card_con ms-0" style="width:40%">
            <p class="line_before mb-3">Payment Terms</p>
            <div class="con_wrapper">
              <div class="con_wrapper_row">
                <div class="row_value v_center" *ngIf="loadDetail?.paymentMethod === 1">
                  <img
                    src="https://s3.amazonaws.com/staticprod.laneaxis.com/portal/images/after-login/cl-icons/ic-currency-usd.svg"
                    class="img-fluid me-1">
                  <span class="ms-2 fsb_6">USD Money</span>
                </div>
              </div>
              <div class="con_wrapper_row">
                <div class="row_value">
                  <span class="fsb_6 me-2">{{loadDetail?.terms}}</span>
                </div>
                <div class="row_label" *ngIf="loadDetail.terms === 'Shipper Terms'">
                  {{checkCardDAy(loadDetail?.stDays)}}</div>
              </div>
            </div>
          </div>
          <div class="detail_card_con me-0" style="width:60%" *ngIf="!loadDetail?.isOfferRate || loadDetail?.isOfferRate==='N'">
            <p class="line_before mb-3">Freight Rate</p>
          
            <div class="con_wrapper">
              <div class="con_wrapper_row" style="justify-content: space-between;">
                <div class="row_label">Expected Rate</div>
                <div class="row_value text-end ex_rate contract_offerRate">
                  <span *ngIf="loadDetail?.paymentMethod === 1">${{loadDetail?.freightRate | twoDecimalPipe}}</span>
                  <app-rate-per-mile *ngIf="loadDetail?.paymentMethod === 1" class="fs_12 fsm_5 txt_lb" [amount]="loadDetail?.freightRate" [miles]="loadDetail?.miles"></app-rate-per-mile>
                </div>
              </div>
            </div>
          </div>
      </div>
  
      <div class="picupDrop_Container" *ngIf="loadDetail?.isOfferRate === 'Y' && userType==='CARRIER'">
        <div class="detail_card_con ms-0 w-50">
          <p class="line_before mb-3">Payment Terms </p>
          <div class="con_wrapper">
            <div class="con_wrapper_row">
              <div class="row_value v_center">
                <img *ngIf="loadDetail?.paymentMethod === 1"
                  src="https://s3.amazonaws.com/staticprod.laneaxis.com/portal/images/after-login/cl-icons/ic-currency-usd.svg"
                  class="img-fluid me-1" >
                <img *ngIf="loadDetail?.paymentMethod === 2"
                  src="https://s3.amazonaws.com/staticprod.laneaxis.com/portal/images/after-login/cl-icons/ic-currency-usdc.svg"
                  class="img-fluid me-1" >
                <span class="ms-2 fsb_6" *ngIf="loadDetail?.paymentMethod === 1">USD Money</span>
                <span class="ms-2 fsb_6" *ngIf="loadDetail?.paymentMethod === 2">USDC Money</span>
              </div>
            </div>
            <div class="con_wrapper_row">
              <div class="row_value">
                <span class="fsb_6 me-2">{{loadDetail?.terms ? loadDetail?.terms : '-'}}</span>
              </div>
              <div class="row_label" *ngIf="loadDetail.terms === 'Shipper Terms'">
                {{checkCardDAy(loadDetail?.stDays)}}</div>
            </div>
          </div>
        </div>
        <div class="detail_card_con me-0 w-50">
          <p class="line_before mb-3" *ngIf="loadDetail?.isOfferRate==='Y'">Contracted Rate
          </p>
          <div class="con_wrapper" *ngIf="loadDetail?.isOfferRate==='Y'">
            <div class="con_wrapper_row" style="justify-content: space-between;">
              <div class="row_label ">Offer Rate</div>
              <div class="row_value text-end ex_rate contract_offerRate">
                <span *ngIf="loadDetail?.paymentMethod===1">${{loadDetail?.freightRate | twoDecimalPipe}}</span>
                <app-rate-per-mile *ngIf="loadDetail?.paymentMethod === 1" class="fs_12 fsm_5 txt_lb" [amount]="loadDetail?.freightRate" [miles]="loadDetail?.miles"></app-rate-per-mile>
              </div>
            </div>
          </div>
        </div>
      </div>
  
      <div class="picupDrop_Container" *ngIf="userType==='SHIPPER'">
        <div class="detail_card_con ms-0" style="width:40%">
          <p class="line_before mb-3">Payment Terms</p>
          <div class="con_wrapper">
            <div class="con_wrapper_row">
              <div class="row_value v_center" *ngIf="loadDetail?.paymentMethod === 1">
                <img
                  src="{{serverPathForUrl}}/portal/images/after-login/cl-icons/ic-currency-usd.svg"
                  class="img-fluid me-1">
                <span class="ms-2 fsb_6">USD Money</span>
              </div>
            </div>
            <div class="con_wrapper_row">
              <div class="row_value">
                <span class="fsb_6 me-2">{{loadDetail?.terms}}</span>
              </div>
              <div class="row_label" *ngIf="loadDetail.terms === 'Shipper Terms'">
                {{checkCardDAy(loadDetail?.stDays)}}</div>
            </div>
          </div>
        </div>
        <div class="detail_card_con me-0" style="width:60%">
          <p class="line_before mb-3" *ngIf="!loadDetail?.isOfferRate || loadDetail?.isOfferRate==='N'">Freight Rate</p>
          <p class="line_before mb-3" *ngIf="loadDetail?.isOfferRate==='Y'">Contracted Rate</p>
          <p class="fs_15 fsn_4 txt_lb mb-4" *ngIf="!loadDetail?.isOfferRate || loadDetail?.isOfferRate==='N'"> The expected Shipment rate has been set and Carriers bidding on the load can view the expected shipment rate.</p>
          <div class="con_wrapper" *ngIf="loadDetail?.isOfferRate==='Y'">
            <div class="con_wrapper_row" style="justify-content: space-between;">
              <div class="row_label">Offer Rate</div>
              <div class="row_value text-end ex_rate contract_offerRate">
                <span *ngIf="loadDetail?.paymentMethod === 1">$ {{loadDetail?.freightRate | twoDecimalPipe}}</span>
                <app-rate-per-mile *ngIf="loadDetail?.paymentMethod === 1" class="fs_12 fsm_5 txt_lb" [amount]="loadDetail?.freightRate" [miles]="loadDetail?.miles"></app-rate-per-mile>
              </div>
            </div>
          </div>
          <div class="con_wrapper" *ngIf="!loadDetail?.isOfferRate || loadDetail?.isOfferRate==='N'">
            <div class="con_wrapper_row" style="justify-content: space-between;">
              <div class="row_label me-2">Expected Rate</div>
              <div class="row_value text-end ex_rate contract_offer_Rate" style="align-items: end;">
                <span *ngIf="loadDetail?.paymentMethod === 1">${{loadDetail?.freightRate | twoDecimalPipe}}</span>
                <app-rate-per-mile *ngIf="loadDetail?.paymentMethod === 1" class="fs_12 fsm_5 txt_lb" [amount]="loadDetail?.freightRate" [miles]="loadDetail?.miles"></app-rate-per-mile>
              </div>
            </div>
            <div class="con_wrapper_row" style="justify-content: space-between;">
              <div class="row_label">Actual Rate</div> 
              <div class="row_value text-end">
                <p class="ex_rate mb-0 contract_offerRate">
                  <span *ngIf="loadDetail?.paymentMethod === 1 && loadDetail?.bids?.bidAmount && loadDetail?.bids?.statusLabel=='Shipment Booked'">
                    <span *ngIf="loadDetail?.bids?.bidAmount">$</span>{{loadDetail?.bids?.bidAmount ? (loadDetail?.bids?.bidAmount  | twoDecimalPipe) : '-'}}</span>
                  <app-rate-per-mile *ngIf="loadDetail?.paymentMethod === 1 && loadDetail?.bids?.bidAmount"
                    class="fs_12 fsm_5 txt_lb" [amount]="loadDetail?.bids?.bidAmount" [miles]="loadDetail?.miles">
                  </app-rate-per-mile>
                </p>
                <p class="mb-0 fs_12 txt_lb fsn_4 mt-1 save_ex"
                  *ngIf="loadDetail.freightRate && loadDetail?.bids && loadDetail?.bids?.statusLabel=='Shipment Booked' && loadDetail?.bids?.bidAmount < loadDetail?.freightRate">
                  <span class="me-1">(You Save </span>
                  <span
                    *ngIf="loadDetail?.paymentMethod === 1 ">
                    ${{saveAmount(loadDetail?.bids?.bidAmount,loadDetail?.freightRate) | twoDecimalPipe}})</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>