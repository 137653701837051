import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'dotOfMiddle',
  pure: true,
})
export class DotOfMiddlePipe implements PipeTransform {
  transform(value: any, args?: any): any {
    if (value) {
      let firstString = value.slice(0, 5);
      let lastString = value.substr(value.length - 5);
      return firstString + '.....' + lastString;
    } else {
      return '-';
    }
  }
}
