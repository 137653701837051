import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { CommonService } from './common.service';
import { AppSettings } from '../../commons/setting/app_setting';

declare var $: any;
@Injectable({
    providedIn: 'root',
})
export class WaypointService {
    constructor(private http: HttpClient, private router: Router,
        public commonService: CommonService,) { }

    async wayPointGet(ShipmentId: any) {
        return new Promise((resolve, reject) => {
            let uri = null;
            let newParams = {
                shipmentId: ShipmentId,
            };
            if (newParams) uri = this.commonService.getAPIUriFromParams(newParams);
            let APIparams = {
                apiKey: AppSettings.APIsNameArray.SHIPMENT.WAYPOINTLIST,
                uri: uri,
            };
            this.commonService.getList(APIparams).subscribe((ServerRes) => {
                if (ServerRes.success === true) {
                
                    resolve(ServerRes.response.waypoints);
                } else {
                }
            });
        })
    }
}