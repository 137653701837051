import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';
import { AlertService } from 'src/app/commons/service/alert.service';
import { CommonService } from 'src/app/commons/service/common.service';
import { AppSettings } from 'src/app/commons/setting/app_setting';
import { PopupComponent } from 'src/app/shared/popup/popup.component';

@Component({
  selector: 'app-add-to-carrier',
  templateUrl: './add-to-carrier.component.html',
  styleUrls: ['./add-to-carrier.component.scss'],
})

export class AddToCarrierComponent implements OnInit {

  @Input() assignedCarriers: any;
  @Input() shipmentDetail: any;
  @Input() allPreCheckCarrier: any;
  skeletonLoader = true;
  visibility = 'private';
  pristineCheck= false;
  public loading = false;
  public privatePublic: boolean = false;
  public totalPage = 1;
  public page = 1;
  public carrierList: any;
  public totalCarriers:any;
  public checkIntereminate : boolean = false;
  public allAddCarrierSkeloton = false;
  public carrierListAdd: any;
  public currentCarrierTab ;
  public carrierType;
  isDescp: boolean = false;
  columnp: string = '';
  public serverPathForUrl: any;
  public selectedIndex = 0;
  public userType: any;
  public buttonClicked = false;
  public allCheckCarrier = [];
  public moreRecordLoader = false;
  public masterSelected: boolean = false;
  public isAllCarrier: boolean= false;
  public userId: any;
  public shipmentId: any;
  public addshipment: any = {};

  constructor(
    private route: ActivatedRoute,
    public commonService: CommonService,
    public dialog: MatDialog,
    private alertService: AlertService,
  ) { }

  async ngOnInit() {
    this.shipmentId = this.route.snapshot.params['loadId'];
    this.userType = localStorage.getItem('user_type');
    this.userId = localStorage.getItem('user_id');
    this.serverPathForUrl = environment.serverPathForUrl;
    this.currentCarrierTab = this.shipmentDetail.selectionType;
    this.carrierType =this.shipmentDetail.selectionType
    this.getCarrierData(this.shipmentDetail.selectionType);

    if (this.shipmentDetail?.visibility == 'private') {
      this.privatePublic = true;
    } else {
      this.privatePublic = false;
    }
  }

  // To get First letters of company/legal name 
  getInitials(name) {
    let acronym;
    if (name) {
      acronym = name.split(/\s/).reduce((response, word) => (response += word.slice(0, 1)), '');
      if (acronym && acronym?.length >= 2) acronym = acronym.substr(0, 2);
    } else acronym = '';
    return acronym;
  }

  // To get Equipment Type
  getEquipmentType(eqipment: any) {
    let equipmentReturn = '-';
    if (eqipment !== null) {
      let dequipmentReturn = eqipment.toString();
      equipmentReturn = dequipmentReturn.replace(/,/g, ', ');
    }
    return equipmentReturn;
  }

  // For selecting all the available carriers 
  onSelectAll(){
    this.pristineCheck = true;
    this.isAllCarrier = true;
    this.ckeckAllCarrierData();
  }


  // To get required Params for API 
  getAPICarrierParam(type) {
    let APIparams, params;
    params = {
      limit: 7,
      tabType: type,
      isLastShipment: 1,
      isTotalMiles: 1,
      page: this.page,
      laneId: this.shipmentDetail.laneId,
    }
    APIparams = {
      apiKey: AppSettings.APIsNameArray.SHIPMENTFORSHIPPER.CARRIER,
      uri: this.commonService.getAPIUriFromParams(params),
    };
    return APIparams;
  }

  // selecting checkbox for carriers
    checkAlreadyCarrier(type) {
      if(this.shipmentDetail.selectionType == 'preferred' && type == 'preferred'){
        this.carrierType = 'preferred';
        if(this.shipmentDetail.isAllCarrier){
        this.onSelectAll();
        this.pristineCheck =false;
        }else{
          this.allPreCheckCarrier=this.shipmentDetail.assignedCarriers ? this.shipmentDetail.assignedCarriers : [];
          for (let caIndex in this.carrierListAdd) {
            for (let sIndex in this.allPreCheckCarrier) {
              if (this.carrierListAdd[caIndex].userId ===this.allPreCheckCarrier[sIndex]) {
                this.carrierListAdd[caIndex].isSelected = true;
                this.allPreCheckCarrier.push(this.carrierListAdd[caIndex].userId);
              }
            }
          }
        }
        this.checkDpulicate();
      } else if(this.shipmentDetail.selectionType == 'confirmedOnLane' && type == 'confirmedOnLane'){
          this.carrierType = 'confirmedOnLane';
        if(this.shipmentDetail.isAllCarrier){
          this.onSelectAll();
          this.pristineCheck =false;
        }else{
          this.allPreCheckCarrier=this.shipmentDetail.assignedCarriers ? this.shipmentDetail.assignedCarriers : [];
          for (let caIndex in this.carrierListAdd) {
            for (let sIndex in this.allPreCheckCarrier) {
              if (this.carrierListAdd[caIndex].userId ===this.allPreCheckCarrier[sIndex]) {
                this.carrierListAdd[caIndex].isSelected = true;
                this.allPreCheckCarrier.push(this.carrierListAdd[caIndex].userId);
              }
            }
          }
        } 
        this.checkDpulicate();
      } else if (this.shipmentDetail.selectionType == 'connected' && type == 'connected'){
        this.carrierType = 'connected';
        if(this.shipmentDetail.isAllCarrier){
          this.onSelectAll();
        this.pristineCheck =false;
        } else{
          this.allPreCheckCarrier=this.shipmentDetail.assignedCarriers ? this.shipmentDetail.assignedCarriers : [];
          for (let caIndex in this.carrierListAdd) {
            for (let sIndex in this.allPreCheckCarrier) {
              if (this.carrierListAdd[caIndex].userId ===this.allPreCheckCarrier[sIndex]) {
                this.carrierListAdd[caIndex].isSelected = true;
                this.allPreCheckCarrier.push(this.carrierListAdd[caIndex].userId);
              }
            }
          }
        }
        this.checkDpulicate();
      } else{
         this.allPreCheckCarrier = [];
      }
     }

  //To check duplicate carriers
  checkDpulicate() {
    let checkDuplicate: any;
    let allCarrierDupiicate = [];
    let newCheckEqul = [];
    allCarrierDupiicate = this.allPreCheckCarrier;
    checkDuplicate = allCarrierDupiicate?.filter(function (item, pos) {
      return allCarrierDupiicate.indexOf(item) === pos;
    });
    this.allPreCheckCarrier = [];
    this.allPreCheckCarrier = checkDuplicate;
    for (let caIndex in this.carrierListAdd) {
      for (let sIndex in this.allPreCheckCarrier) {
        if (
          this.carrierListAdd[caIndex].userId ===
          this.allPreCheckCarrier[sIndex]
        ) {
          newCheckEqul.push(this.carrierListAdd[caIndex].userId);
          if (this.carrierListAdd?.length === newCheckEqul?.length) {
            this.masterSelected = true;
          }
        }
      }
    }
  }

  // To get carrier data from list 
  getCarrierData(type: any) {
    this.allAddCarrierSkeloton = true;
    this.page = 1;
    var APIparams = this.getAPICarrierParam(type);
    this.commonService.getList(APIparams).subscribe((ServerRes) => {
      this.allAddCarrierSkeloton = false;
      this.pristineCheck=false;
      if (ServerRes.response && ServerRes.response?.length > 0) {
        for (let v = 0; v < ServerRes.response?.length; v++) {
          ServerRes.response[v].isSelected = false;
        }
        this.carrierListAdd = ServerRes.response;
        this.totalPage = ServerRes.totalPages;
        this.totalCarriers = ServerRes.totalCarriers;
        if (this.privatePublic) {
         this.checkAlreadyCarrier(type);
        }
      } else {
        this.carrierListAdd = [];
        this.totalPage = 0;
        this.selectedIndex = 0;
        this.allPreCheckCarrier=[];
        this.allCheckCarrier=[];
      }
    });
  }

  // All carrier/preffered carrier toggle 
  changeCarrier(event) {
    if(!this.pristineCheck)
    {this.masterSelected = false;
    if (event.value == "preferred") {
      this.currentCarrierTab = 'preferred';
      this.onClearAll();
      this.getCarrierData('preferred');
    } else if (event.value == "connected"){
      this.currentCarrierTab = 'connected';
      this.onClearAll();
      this.getCarrierData('connected');
    }
    else if (event.value == "confirmedOnLane"){
      this.currentCarrierTab = 'confirmedOnLane';
      this.onClearAll();
      this.getCarrierData('confirmedOnLane');
    }}
    else 
    {
      this.openAlertPopup(event)
    }
  }

  // Aler popup for navigating away from the section  
  openAlertPopup(event)
  {
    const dialogRef = this.dialog.open(PopupComponent, {
      backdropClass: AppSettings.backdropClass,
      width: AppSettings.popWidth,
      disableClose: true,
      data: {
        openPop: 'alertForCheckBox',
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        if (result.event === 'ok') {
          this.pristineCheck = false;
          this.changeCarrier(event);
        }
      else  {
          this.pristineCheck = true;
          this.carrierType=this.currentCarrierTab
        }
      }
    });
  }

  
//  Scroll for carrier list 
  onCarrierDataScroll() {
    if (this.page !== this.totalPage) {
      this.moreRecordLoader = true;
      this.page = this.page + 1;
      this.getCarrierAddMoreData();
    }
  }

  // Load more carrier data on scroll 
  getCarrierAddMoreData() {
    var APIparams = null;
    APIparams = this.getAPICarrierParam(this.currentCarrierTab);
    this.commonService.getList(APIparams).subscribe((ServerRes) => {
      if (ServerRes.response && ServerRes.response?.length > 0) {
        for (let v = 0; v < ServerRes.response?.length; v++) {
          if (ServerRes.response[v]) {
            let isElementExists =  this.carrierListAdd.some(x=> x.userId == ServerRes.response[v].userId)
            if(!isElementExists){
              if(this.isAllCarrier){
                ServerRes.response[v].isSelected = true;
                this.allPreCheckCarrier.push(ServerRes.response[v].userId);
              }else{
                for (let sIndex in this.allPreCheckCarrier) {
                  if (ServerRes.response[v].userId ===this.allPreCheckCarrier[sIndex]) {
                    ServerRes.response[v].isSelected = true;
                  }
                }
              }
              this.carrierListAdd.push(ServerRes.response[v]);
            } 
          }
        }
        this.moreRecordLoader = false;
      }
    });
  }

  // To clear all selected carrier 
  onClearAll(){
    this.isAllCarrier = false;
    this.masterSelected = false;
    this.clearAllCarrierData();
  }

 // To clear all carrier data 
  clearAllCarrierData() {
    this.masterSelected = false;
  }

  // To clear all selected carrier including scroll
  onClearAllData(){
    this.isAllCarrier = false;
    this.clearAllCarrierDataMore();
  }

  // To clear all selected carrier for scroll
  clearAllCarrierDataMore() {
    this.masterSelected = false;
    for (let carrierIndex in this.carrierListAdd) {
      for (let cIndex in this.allPreCheckCarrier) {
        if (
          this.allPreCheckCarrier[cIndex] ===
          this.carrierListAdd[carrierIndex].userId
        ) {
          this.carrierListAdd[carrierIndex].isSelected = false;
          this.allPreCheckCarrier.splice(parseInt(cIndex), 1);
        }
      }
    }
  }

  // Function for check all the availbale carriers 
  ckeckAllCarrierData() {
    this.masterSelected=true;
    this.checkIntereminate=false;
    this.allPreCheckCarrier = []; 
    
    this.carrierListAdd.forEach(element => {
     let ifElementExists = this.allPreCheckCarrier.includes(element.userId)
     if(!ifElementExists){
       this.allPreCheckCarrier.push(element.userId);
       element.isSelected = true;
     }
    });
  }

  // change Text of visibility from private to public for api. 
  changeVisibilityText(event){
    if(event.checked){
      this.addshipment.isVisibility='private';
      this.privatePublic=true;
    }else{
      this.addshipment.isVisibility='public';
      this.privatePublic=false;
      this.buttonClicked = false;
    }
  }

  // Function for generate duplicate loaders 
  public generateFake(count: number): Array<number> {
    const indexes = [];
    for (let i = 0; i < count; i++) {
      indexes.push(i);
    }
    return indexes;
  }

  // To select carriers from checkbox 
  selectCarrierData(event, userId) {
    this.pristineCheck=true;
    if(event===true) {
    
      let ifElementExists = this.allPreCheckCarrier?.includes(userId)
      if(!ifElementExists){
      this.allPreCheckCarrier?.push(userId);
      this.carrierListAdd.map(x=>x.userId === userId).isSelected = true;
      }
      this.checkInterMediated();
    } else {
      this.removeElementFromArray(this.allPreCheckCarrier, userId);
      this.checkInterMediated();
      if(this.isAllCarrier)
      {this.isAllCarrier = false; 
    }}

  }

  // To select all carriers from checkbox 
  checkInterMediated(){
    if(this.carrierListAdd?.length===this.allPreCheckCarrier?.length){
       this.checkIntereminate=false;
      this.masterSelected=true;
    }else if(this.allPreCheckCarrier?.length > 0){
      this.masterSelected=true;
      this.checkIntereminate=true;

   }else if (this.allPreCheckCarrier?.length===0){
      this.masterSelected = false;
      this.checkIntereminate = false;  
      this.clearAllCarrierData();
    }
  }

  // To remove carriers from checkbox 
  removeElementFromArray(array: Array<String>, removeElement) {
    let indexOfElement = array.indexOf(removeElement);
    this.allPreCheckCarrier.splice(indexOfElement, 1);
  }

  //update button function for assign carrier.
  assigCarrier() {
    this.buttonClicked=true;
    this.allAddCarrierSkeloton=true;
    let allCarrier = [];
    let filteredArray;
    if (this.allCheckCarrier || this.allPreCheckCarrier) {
      allCarrier = this.allCheckCarrier.concat(this.allPreCheckCarrier);
      filteredArray = allCarrier.filter(function (item, pos) {
        return allCarrier.indexOf(item) === pos;
      });
    }
    this.addshipment.selectionType = this.currentCarrierTab == 'preferred' ? 'preferred' :  this.currentCarrierTab == 'connected' ? 'connected':'confirmedOnLane'; 
    if(this.isAllCarrier){
      this.addshipment.isAllCarrier = true; 
      delete this.addshipment.carrierIds;
    }
    else{
      this.addshipment.isAllCarrier = false; 
      if (this.allPreCheckCarrier?.length > 0) {
        this.addshipment.carrierIds = this.allPreCheckCarrier;
      } 
    }
    this.addshipment.shipmentId = this.shipmentDetail.id;
    if(this.privatePublic){
      this.addshipment.isVisibility='private';     
    }else{
      this.addshipment.isVisibility='public';     
    } 
    if (!this.privatePublic) {
      delete this.addshipment.carrierIds;
      delete this.addshipment.selectionType;
    }

    let APIparams = {
      apiKey: AppSettings.APIsNameArray.SHIPMENTFORSHIPPER.ASSIGNCARRIER,
      uri: '',
      postBody: this.addshipment,
    };
    this.commonService.post(APIparams).subscribe(
      async (success) => {
      this.pristineCheck=false;
        this.buttonClicked=false;
        if (success.success === true) {
          this.allPreCheckCarrier = [];
          this.shipmentDetail = await this.shipmentGet();
          this.pristineCheck=false;
          if (this.currentCarrierTab === 'preferred') {
            this.getCarrierData('preferred');
          } else  if (this.currentCarrierTab === 'connected')  {
            this.getCarrierData('connected');
          } else 
          {
            this.getCarrierData('confirmedOnLane');
          }
          this.alertService.showNotificationMessage(
            'success',
            'bottom',
            'right',
            'txt_s',
            'check_circle',
            'Carrier Updated',
            'Carrier updated successfully.'
          );
          this.loading = false;
          this.allAddCarrierSkeloton = false;
        } else if (success.success === false) {
          this.alertService.showNotificationMessage(
            'danger',
            'bottom',
            'right',
            'txt_d',
            'cancel',
            'Carrier Updated',
            success.message
          );
          this.loading = false;
          this.allAddCarrierSkeloton = false;
        }
      },
      (error) => {
        this.alertService.showNotificationMessage(
          'danger',
          'bottom',
          'right',
          'txt_d',
          'cancel',
          'Carrier Updated',
          AppSettings.ERROR
        );
        this.loading = false;
        this.allAddCarrierSkeloton = false;
      }
    );
  }

  // sorting Data of carriers
  sortCarriers(property) {
    this.isDescp = !this.isDescp; //change the direction
    this.columnp = property;
    let direction = this.isDescp ? -1 : 1;
    this.carrierListAdd.sort(function (a, b) {
      if(property==='lastLoad'){
        if (a[property]?.date < b[property]?.date) {
          return -1 * direction;
        } else if (a[property]?.date > b[property]?.date) {
          return 1 * direction;
        } else {
          return 0;
        }
      }
      else{
        if (a[property] < b[property]) {
          return -1 * direction;
        } else if (a[property] > b[property]) {
          return 1 * direction;
        } else {
          return 0;
        }
      }
      
    });
  }

  // To call API for shipment get 
  shipmentGet() {
    return new Promise((resolve, reject) => {
      let uri = null;
      let newParams = {
        id: this.route.snapshot.params['loadId'],
      };
      if (newParams) uri = this.commonService.getAPIUriFromParams(newParams);
      let APIparams = {
        apiKey: AppSettings.APIsNameArray.SHIPMENT.NEWGET,
        uri: uri,
      };
      this.commonService.getList(APIparams).subscribe(
        async (ServerRes) => {
          if (ServerRes.success === true) {
            this.shipmentDetail = ServerRes.response;
            this.carrierType = this.shipmentDetail.selectionType;
            if (this.shipmentDetail.assignedCarriers) {
              this.assignedCarriers = ServerRes.response.assignedCarriers;
              this.allPreCheckCarrier = this.shipmentDetail.assignedCarriers;
            }
            resolve(ServerRes.response);
          } else {
            resolve(null);
            this.skeletonLoader = false;
          }
        },
        (error) => {
          this.skeletonLoader = false;
          resolve(null);
        }
      );
    });
  } 

}
