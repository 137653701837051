import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AlertService } from 'src/app/commons/service/alert.service';
import { CommonService } from 'src/app/commons/service/common.service';
import { AppSettings } from 'src/app/commons/setting/app_setting';
import { SharedService } from 'src/app/commons/service/shared.service';
import { HereMapsService } from 'src/app/commons/service/here-maps.service';
import { StatusSetting } from 'src/app/commons/setting/status_setting';
import { MatDialog } from '@angular/material/dialog';
import { NetworkPopupComponent } from 'src/app/network-shared/network-popup/network-popup.component';
import { SubscriptionPopupComponent } from 'src/app/shared/subscription-popup/subscription-popup.component';
import { DisputePopupComponent } from '../dispute-popup/dispute-popup.component';
import { environment } from 'src/environments/environment';
import * as moment from 'moment';
import { PopupComponent } from 'src/app/shared/popup/popup.component';
declare var require: any;

export interface DialogData {
  name: string;
}

@Component({
  selector: 'app-spot-active-loads-card',
  templateUrl: './spot-active-loads-card.component.html',
  styleUrls: ['./spot-active-loads-card.component.scss']
})
export class SpotActiveLoadsCardComponent implements OnInit {
  @Input() shipmentRecord: any;
  @Input() currentStatus: any;
  @Input() currentRouteParams: any;
  @Input() subscriptionData: any;
  @Output() assingDriver = new EventEmitter();
  @Output() eventPassAction = new EventEmitter();
  @Output() refreshList = new EventEmitter();
  public userType: any;
  public eCarrierCheckRisk:any;
  public eCarrierCheckData:any
  public loading = false;
  public userId: any;
  public ratingOwnerDetail: {};
  waypointIntransitStatus: any;
  waypointCompletedStatus: any;
  intransitStatus: any;
  public checkTimeDispute = false;
  public serverPathForUrl: any;
  public id: any;
  public currentlocation: any;
  public currentTabShow: any;
  bidHistorySekeletonLoader: boolean;
  public etaInformation: any;
  public waypoint: any; 
  gifLoader: boolean;
  public isHomePage: boolean = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private alertService: AlertService,
    public commonService: CommonService,
    private cd: ChangeDetectorRef,
    private hereMapsService: HereMapsService,
    private sharedService: SharedService,
    public dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    this.eCarrierCheckRisk=StatusSetting.eCarrierCheckToolTip;
    this.serverPathForUrl = environment.serverPathForUrl;
    this.userType = localStorage.getItem('user_type');
    this.userId =  localStorage.getItem('user_id');
    if (this.userType === 'CARRIER') {
      if(this.shipmentRecord?.drivers?.userId != this.userId){
        this.ratingOwnerDetail = {
          key: 'driverId',
          id: this.shipmentRecord?.drivers?.userId,
        }}
        else{
        this.ratingOwnerDetail = {
        key: 'carrierId',
        id: this.shipmentRecord?.carriers?.userId,
      }
      }     
    }
    if (this.userType === 'SHIPPER' && this.shipmentRecord?.carriers?.userId) {
      this.ratingOwnerDetail = {
        key: 'carrierId',
        id: this.shipmentRecord?.carriers?.userId,
      }
    }
    this.getUpdatedWayPoints();
    this.getWaypointStatus();
  }


  eventActionProfileSidePanel(event: any, type: any){
    if(type==='profile_side_panel'){
      this.eventPassAction.emit({ event, type });
    }
  }

    // Multiple event for sidepanel.
    eventAction(event: any, type: any) {
      this.eventPassAction.emit({ event, type });
    }

    private getFullPath(route: ActivatedRoute): string {
      const segments = [];
      while (route) {
        if (route.snapshot.url.length) {
          segments.unshift(...route.snapshot.url.map(segment => segment.path));
        }
        route = route.parent;
      }
      
      return segments.join('/');
    }
  
  
   openNewTabRecord(shipmentRecord: any) {
    const fullPath = this.getFullPath(this.route);
    if (shipmentRecord &&  shipmentRecord.status === 15) {
      let newCurrentStatus = 'summary';
      const url = this.router.serializeUrl(
        this.router.createUrlTree([
          fullPath+ `/load/view/${shipmentRecord.id}/${newCurrentStatus}`,
        ])
      );
      window.open(url, '_blank');
    }else if (shipmentRecord && shipmentRecord.status === 59) {
      let newCurrentStatus = 'details';
      const url = this.router.serializeUrl(
        this.router.createUrlTree([
          fullPath+ `/load/view/${shipmentRecord.id}/${newCurrentStatus}`,
        ])
      );
      window.open(url, '_blank');
    } 
    else if (shipmentRecord && shipmentRecord.status === 3) {
      let newCurrentStatus = 'details';
      const url = this.router.serializeUrl(
        this.router.createUrlTree([
          fullPath+ `/load/view/${shipmentRecord.id}/${newCurrentStatus}`,
        ])
      );
      window.open(url, '_blank');
    }
    else {
      const url = this.router.serializeUrl(
        this.router.createUrlTree([
          fullPath+ `/load/view/${shipmentRecord.id}/${this.currentRouteParams}`,
        ])
      );
      window.open(url, '_blank');
    }
   }
  
   // Function to get Track Shipment Condition 
   checkWebTrackShipmentCondition() {
   if (
      this.shipmentRecord &&
      this.shipmentRecord.shippers &&
      this.shipmentRecord.shippers.email &&
      this.shipmentRecord.status !== '59'
    ) {
      return true;
    } else if (
      this.shipmentRecord &&
      this.shipmentRecord.shipperEmail &&
      this.shipmentRecord.status !== '59'
    ) {
      return true;
    } else {
      return false;
    }
   }
  
   getWebTrackingLink() {
    if (
      this.shipmentRecord &&
      this.shipmentRecord.shippers &&
      this.shipmentRecord.shippers.email
    ) {
      var url = environment.domainPoint + '/load/track-your-load?load_id=' + this.shipmentRecord.uniqueId 
      // var url = 'http://localhost:4200' + '/load/track-your-load?load_id=' + this.shipmentRecord.uniqueId 

    } 
    else if (this.shipmentRecord && this.shipmentRecord.shipperEmail) {
      url = environment.domainPoint + '/load/track-your-load?load_id=' + this.shipmentRecord.uniqueId 
      // url = 'http://localhost:4200' + '/load/track-your-load?load_id=' + this.shipmentRecord.uniqueId 
    }
    this.copyWebTrackingLink(url);
   }


   //copying web tracking link
   copyWebTrackingLink(val: string) {
    let selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.alertService.showNotificationMessage(
      'success',
      'bottom',
      'right',
      'txt_s',
      'check_circle',
      'Web Tracking Link',
      'Web tracking link copied.'
    );
  }
  
   getWaypointStatus(){
    this.waypointIntransitStatus = this.shipmentRecord.waypoints.filter((x: { status: string; }) => x.status === 'In-Transit'); 
    this.intransitStatus = this.waypointIntransitStatus[0]?.status;
    this.waypointCompletedStatus = this.shipmentRecord.waypoints.filter((x: { status: string; }) => x.status === 'Completed');
    this.waypointCompletedStatus = this.waypointCompletedStatus[this.waypointCompletedStatus.length-1];
  }
  
  getUpdatedWayPoints() {
    if (this.shipmentRecord &&  this.shipmentRecord.waypoints && this.shipmentRecord.waypoints[0]?.date) {
        this.shipmentRecord.waypoints[0].date = this.getDateFormat(this.shipmentRecord.waypoints[0]?.date?.toString());
    }
    if (this.shipmentRecord &&  this.shipmentRecord.waypoints && this.shipmentRecord.waypoints[1]?.date) {
      this.shipmentRecord.waypoints[1].date = this.getDateFormat(this.shipmentRecord.waypoints[1]?.date?.toString());
    }
  }
  
  getDateFormat(inputDate: any) {
    if (inputDate !== '' && typeof inputDate !== undefined) {
      return inputDate.replaceAll('-', '/');
    } else {
      return '';
    }
  }
  
  checkMessageShipmentCondition() {
    if (
      this.shipmentRecord &&
      this.shipmentRecord.shippers &&
      this.shipmentRecord.shippers.email &&
      this.shipmentRecord.status !== '59'
    ) {
      return true;
    } else if (
      this.shipmentRecord &&
      this.shipmentRecord.shipperEmail &&
      this.shipmentRecord.status !== '59'
    ) {
      return true;
    } else {
      return false;
    }
  }

  checkEditShipmentCondition() {
    if (this.userType === 'SHIPPER' && this.shipmentRecord && this.shipmentRecord.shippers &&
      (this.shipmentRecord.statusSlug === 'readyToBid' || this.shipmentRecord.statusSlug === 'offerRate' ||
        this.shipmentRecord.statusSlug === 'expired' || this.currentStatus === 'inProgressByLoads' || this.currentStatus === 'upcomingByLoads' || this.currentStatus === 'pendingByLoads')) {
      return true;
    } else if (this.userType === 'CARRIER' && this.shipmentRecord && this.shipmentRecord.carriers && !this.shipmentRecord.shippers &&
      (this.shipmentRecord.statusSlug === 'expired' || this.currentStatus === 'inProgressByLoads' || this.currentStatus === 'upcomingByLoads' || this.currentStatus === 'pendingByLoads' ||
        !(this.currentStatus === 'pastByLoads' || this.currentStatus === 'dispute'))) {
      return true;
    } else {
      return false;
    }
  }
  
  checkCancelShipmentCondition() {
    if (
      this.userType === 'SHIPPER' &&
      this.shipmentRecord &&
      this.shipmentRecord.shippers &&
      this.shipmentRecord.status !== '15' &&
      ( this.currentStatus === 'inProgressByLoads' ||
        this.currentStatus === 'upcomingByLoads' ||
        this.currentStatus === 'pendingByLoads')
    ) {
      return true;
    } else if (
      this.userType === 'CARRIER' &&
      this.shipmentRecord &&
      this.shipmentRecord.carriers &&
      this.shipmentRecord.status !== '15' &&
      !this.shipmentRecord.shippers &&
      (this.currentStatus === 'inProgressByLoads' ||
        this.currentStatus === 'upcomingByLoads' ||
        this.currentStatus === 'pendingByLoads')
    ) {
      return true;
    } else {
      return false;
    }
  }

  checkFileDisputeShipmentCondition() {
      if (this.shipmentRecord && this.shipmentRecord.updatedAt) {
        let days = 1;
        let deliveredDate = new Date(this.shipmentRecord.updatedAt + ' ' + 'UTC');
        deliveredDate.setDate(deliveredDate.getDate() + days);
        let now = new Date();
        const deliveryDateTime: any =
          moment(deliveredDate).format('YYYY-MM-DD HH:mm');
        const currentDateTime: any = moment(now).format('YYYY-MM-DD HH:mm');
        if (deliveryDateTime > currentDateTime) {
          this.checkTimeDispute = true;
        } else {
          this.checkTimeDispute = false;
        }
      } else {
        this.checkTimeDispute = false;
      }
  
      if (
        this.shipmentRecord &&
        this.checkTimeDispute &&
        this.shipmentRecord.shippers &&
        this.shipmentRecord.status === 51
      ) {
        return true;
      } else {
        return false;
      }
  }

  disputeShipment($event, id: any, uniqueId: any, title: any) {
    $event.stopPropagation();
    const dialogRef = this.dialog.open(DisputePopupComponent, {
      backdropClass: AppSettings.backdropClass,
      width: '600px',
      data: {
        openPop: 'fileDispute',
        shipmentId: id,
        uniqueId: uniqueId,
        title: title,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        if (result.event === 'Ok') {
        }
      }
    });
  }

  shipmentAssignDriver(event) {
    this.assingDriver.emit(event);
  }

  disputeRespond() {
    const fullPath = this.getFullPath(this.route);
    this.router.navigate([fullPath+'/load/view', this.shipmentRecord.id,'response']);
  }

  redirectToEdit() {
    this.router.navigate(['/load/edit', this.shipmentRecord.id]);
  }

  redirectToMessages(value) {
    if (value == 'Active') {
      this.router.navigate(['/messages/active_shipment/All/'], {
        queryParams: {activeChat: 'true', id: this.shipmentRecord?.id, title: this.shipmentRecord?.title},
      });
    }
    if (value == 'All') {
      this.router.navigate(['/messages/shipment/All/'], {
        queryParams: {activeChat: 'true', id: this.shipmentRecord?.id, title: this.shipmentRecord?.title},
      });
    }
  }

    /*Cancel Shipment*/
    newShipmentCancel(id: any, uniqueId: any) {
      let confirmby: any;
      let buttontext = "Cancel";
      if ((this.userType === 'SHIPPER') && (this.currentStatus === 'pendingByLoads' || this.currentStatus === 'upcomingByLoads' || this.currentStatus === 'inProgressByLoads')) {
        confirmby = 'confirmCancelShipment';
        this.cancelShipmentPayment(confirmby, id, uniqueId, buttontext);
      } else {
        confirmby = 'confirmcancel';
        this.cancelShipment(confirmby, id, uniqueId, buttontext);
      }
    }

    cancelShipmentPayment(confirmby: any, id: any, uniqueId: any, buttontext: any) {
      let buttonnew = 'Go Back';
      const dialogRef = this.dialog.open(PopupComponent, {
        backdropClass: AppSettings.backdropClass,
        width: AppSettings.popWidth,
        data: {
          openPop: confirmby,
          id: id,
          uniqueId: uniqueId,
          buttontext: buttontext,
          buttonnew: buttonnew,
        },
      });
      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          if (result.event === 'Ok') {
            this.cancelShipmentSubmitPayment(id, 'firstTimeCall');
          }
        }
      });
    }

      //Cancel shipment popup
  cancelShipment(
    confirmby: any,
    id: any,
    uniqueId: any,
    buttontext: any
  ) {
    let buttonnew = 'Go Back';
    const dialogRef = this.dialog.open(PopupComponent, {
      backdropClass: AppSettings.backdropClass,
      width: AppSettings.popWidth,
      data: {
        openPop: confirmby,
        id: id,
        uniqueId: uniqueId,
        buttontext: buttontext,
        buttonnew: buttonnew,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        if (result.event === 'Ok') {
          this.cancelShipmentSubmit(id, 'firstTimeCall');
        }
      }
    });
  }

  //Cancel shipment api called.
  cancelShipmentSubmitPayment(id: any, check: any) {
    this.loading = true;
    let postBody = null;
    let action = '';
    if (check === 'firstTimeCall') {
      action = 'cancel';
    } else {
      action = 'assign';
    }
    let driverId;
    if (this.shipmentRecord.drivers) {
      postBody = {
        shipmentId: this.shipmentRecord.id,
        driverId: driverId,
        carrierId: localStorage.getItem('user_id'),
        action: action,
      };
    } else {
      postBody = {
        shipmentId: this.shipmentRecord.id,
        carrierId: localStorage.getItem('user_id'),
        action: action,
      };
    }
    let APIparams = {
      apiKey: AppSettings.APIsNameArray.NEWPAYMENT.CANCELSHIPMENTPAYMENT,
      uri: '',
      postBody: postBody,
    };
    this.commonService.post(APIparams).subscribe(
      async (success) => {
        if (success.success === true) {
          this.id = id;
          this.getCurrentStatus('action');
          this.refreshList.emit();
          if (check === 'firstTimeCall') {
          } else {
            this.alertService.showNotificationMessage(
              'success',
              'bottom',
              'right',
              'txt_s',
              'check_circle',
              'Cancel Shipment',
              success.message
            );
          }
          this.loading = false;
        } else if (success.success === false) {
          this.alertService.showNotificationMessage(
            'danger',
            'bottom',
            'right',
            'txt_d',
            'cancel',
            'Cancel Shipment',
            success.message
          );
          this.loading = false;
        }
      },
      (error) => {
        this.loading = false;
        this.alertService.showNotificationMessage(
          'danger',
          'bottom',
          'right',
          'txt_d',
          'cancel',
          'Cancel Shipment',
          AppSettings.ERROR
        );
      }
    );
  }

    //Cancel shipment api called.
    cancelShipmentSubmit(id: any, check: any) {
      this.loading = true;
      let postBody = null;
      let action = '';
      if (check === 'firstTimeCall') {
        action = 'cancel';
      } else {
        action = 'assign';
      }
      let driverId;
      if (this.shipmentRecord.drivers) {
        postBody = {
          shipmentId: this.shipmentRecord.id,
          driverId: driverId,
          carrierId: localStorage.getItem('user_id'),
          action: action,
        };
      } else {
        postBody = {
          shipmentId: this.shipmentRecord.id,
          carrierId: localStorage.getItem('user_id'),
          action: action,
        };
      }
      let APIparams = {
        apiKey: AppSettings.APIsNameArray.SHIPMENT.SHIPMENTCANCEL,
        uri: '',
        postBody: postBody,
      };
      this.commonService.post(APIparams).subscribe(
        async (success) => {
          if (success.success === true) {
            this.id = id;
            this.getCurrentStatus('action');
            this.refreshList.emit();
            if (check === 'firstTimeCall') {
            } else {
              this.alertService.showNotificationMessage(
                'success',
                'bottom',
                'right',
                'txt_s',
                'check_circle',
                'Cancel Shipment',
                success.message
              );
            }
            this.loading = false;
          } else if (success.success === false) {
            this.alertService.showNotificationMessage(
              'danger',
              'bottom',
              'right',
              'txt_d',
              'cancel',
              'Cancel Shipment',
              success.message
            );
            this.loading = false;
          }
        },
        (error) => {
          this.loading = false;
          this.alertService.showNotificationMessage(
            'danger',
            'bottom',
            'right',
            'txt_d',
            'cancel',
            'Cancel Shipment',
            AppSettings.ERROR
          );
        }
      );
    }

    async getCurrentStatus(type: any) {
      if (type === 'details') {
        
        if (!this.shipmentRecord) {
          this.shipmentRecord = await this.shipmentGet();
        } else {
          if (
            this.shipmentRecord &&
            this.shipmentRecord.tripStatus &&
            this.shipmentRecord.Text !== ''
          )
           {
            this.getTripStatus(this.shipmentRecord.tripStatus);}
        }
        if (this.shipmentRecord && this.shipmentRecord.createdAt) {
          this.shipmentRecord.createdAt = new Date(
            this.shipmentRecord.createdAt + ' ' + 'UTC'
          );
        }
        if (this.shipmentRecord && this.shipmentRecord.updatedAt) {
          this.shipmentRecord.updatedAt = new Date(
            this.shipmentRecord.updatedAt + ' ' + 'UTC'
          );
        }
      } else if (type === 'action') {
        this.shipmentRecord = await this.shipmentGet();
      }
      if (this.currentStatus === 'inProgressByLoads' || this.currentStatus === 'pendingByLoads')
        this.currentTabShow = 'driver';
      if (this.currentStatus === 'upcomingByLoads' || this.currentStatus === 'expired') {
        this.currentTabShow = 'details';
        this.bidHistorySekeletonLoader = false;
      }
      if (this.currentStatus === 'pastByLoads') {
        if (this.shipmentRecord.status !== 59 && this.shipmentRecord.status !== 15) {
          this.currentTabShow = 'document';
        } else {
          this.currentTabShow = 'details';
          this.bidHistorySekeletonLoader = false;
        }
      }
    }

    shipmentGet() {
      this.bidHistorySekeletonLoader = true;
      return new Promise((resolve, reject) => {
        let uri = null;
        let newParams = {
          id: this.id,
        };
        if (newParams) uri = this.commonService.getAPIUriFromParams(newParams);
        let APIparams = {
          apiKey: AppSettings.APIsNameArray.SHIPMENT.NEWGET,
          uri: uri,
        };
        this.commonService.getList(APIparams).subscribe(async (ServerRes) => {
          if (ServerRes.success === true) {
            this.bidHistorySekeletonLoader = false;
            ServerRes.response.dropStatus = this.waypoint[this.waypoint.length - 1].status;
            ServerRes.response.dropTimeZone = this.waypoint[this.waypoint.length - 1].timeZone;
            ServerRes.response.dropTimeslot = this.waypoint[this.waypoint.length - 1].timeSlot;
            ServerRes.response.dropDate = this.waypoint[this.waypoint.length - 1].date;
            this.shipmentRecord = ServerRes.response;
            if (
              this.shipmentRecord && this.sharedService.checkEligiblityForTripStatus(ServerRes.response.status) && !this.isHomePage) {
              if (ServerRes.response.status < 42) {
                this.gifLoader = true;
                this.hereMapsService
                  .calculateMultiRoute(this.shipmentRecord, this.waypoint)
                  .then((hereMapsRoutingResponse) => {
                    if (hereMapsRoutingResponse) {
                      var tripStatus = this.hereMapsService.calculateTripStatus(
                        hereMapsRoutingResponse,
                        this.shipmentRecord
                      );
                      if (tripStatus) this.getTripStatus(tripStatus);
                    }
                  });
              } else {
                var tripStatus =
                  this.hereMapsService.calculateRoutewithCallingHereMaps(
                    ServerRes.response
                  );
                this.getTripStatus(tripStatus);
              }
            } else {
              this.etaInformation = null;
            }
            resolve(ServerRes.response);
          } else {
            resolve(null);
            this.bidHistorySekeletonLoader = false;
          }
        });
      });
    }

    getTripStatus(tripStatus) {
      var moment = require('moment-timezone');
      if (tripStatus) {
        this.shipmentRecord.tripStatus = tripStatus;
        this.showPosition(
          this.shipmentRecord.tripStatus.latitude,
          this.shipmentRecord.tripStatus.longitude
        );
        this.gifLoader = false;
        if (tripStatus.ETA) {
          let dropTimeZone = this.hereMapsService.getTimeZone(
            this.shipmentRecord.dropTimeZone
          );
          let currentTime = moment().tz(dropTimeZone).format('MM/DD/YYYY HH:mm');
          let currentTS = Date.parse(currentTime);
          this.etaInformation = tripStatus.ETA + currentTS;
        }
      } else {
        this.etaInformation = null;
      }
    }

     // show user current location
  showPosition(locationLat, locationLong) {
    let that = this;
    let geocoder = new google.maps.Geocoder();
    let selatlong = { lat: locationLat, lng: locationLong };
    geocoder.geocode({ location: selatlong }, function (results, status) {
      if (status === google.maps.GeocoderStatus.OK) {
        let newname = '';
        if (results[0].address_components[3] !== undefined) {
          newname = results[0].address_components[3].long_name;
        } else {
          newname = '';
        }
        that.currentlocation = results[0].formatted_address;
        that.cd.detectChanges();
      } else if (status === google.maps.GeocoderStatus.OVER_QUERY_LIMIT) {
        that.currentlocation = '-';
        that.cd.detectChanges(); // return addressDriver;
      } else {
      }
    });
  }

  ecarriercheck(dotNumbe) {
    const dialogRef = this.dialog.open(NetworkPopupComponent, {
      disableClose: true,
      backdropClass: AppSettings.backdropClass,
      width:'1000px',
      height:'calc(100vh - 100px)',
      data: { openPop: 'ecarriercheck',dotNumber : dotNumbe },
    });
    dialogRef.afterClosed().subscribe((result) => {
    });
  }

  getSubscriptionAlertPopup(type){
    const dialogRef = this.dialog.open(SubscriptionPopupComponent, {
      disableClose: true,
      backdropClass: AppSettings.backdropClass,
      width: type == 'isUnlockSafetyScore' ? '500px' : AppSettings.popWidth,
      data: { 
        openPop: 'subscriptionAlert',
        subscriptionData: this.subscriptionData,
        type: type
       },
    });
    dialogRef.afterClosed().subscribe((result) => {
     this.loading=false;

    });
    this.loading=false;

  }

  unlockSafetyScore(dotNumber,type){
    this.loading=true
    if (this.userType == 'SHIPPER' && (((this.subscriptionData?.subscriptionCreditHistory?.defaultCredit + this.subscriptionData?.subscriptionCreditHistory?.additionalCredit)==0) && this.subscriptionData?.isPremium != true && this.subscriptionData?.subscriptionStatus == 'canceled') 
    || (this.subscriptionData?.isPremium == true && this.subscriptionData?.subscriptionStatus == 'active' && ((this.subscriptionData?.subscriptionCreditHistory?.defaultCredit + this.subscriptionData?.subscriptionCreditHistory?.additionalCredit)==0)))
    {
      this.getSubscriptionAlertPopup('isUnlockSafetyScore');
    } else {
    let uri = null;
    let newParams = {
      dotNumber: dotNumber
    };
    if (newParams) uri = this.commonService.getAPIUriFromParams(newParams);
    let APIparams = {
      apiKey: AppSettings.APIsNameArray.CARRIER.ECARRIERCHECK,
      uri: uri,
    };
    this.commonService.getList(APIparams).subscribe(
       (ServerRes) => {
        if(ServerRes?.success==true){
          this.eCarrierCheckData=ServerRes?.response;
          if(this.eCarrierCheckData?.profile?.equipmentType){
            this.shipmentRecord.carriers.equipmentType=ServerRes?.response?.profile?.equipmentType  
          }  
          this.shipmentRecord.carriers.shipperCarrierScoreMapping = true;
          this.shipmentRecord.carriers.carrierSafetyScore={
            id : ServerRes?.response?.profile.id,
            authority : ServerRes.response.authority,
            riskLevel : ServerRes.response.riskLevel,
            riskLabel:ServerRes.response.riskLabel,
            recentCrashes :ServerRes.response.recentCrashesCount,
            dotNumber : ServerRes.response.profile?.dotNumber,
            recentInspections :ServerRes.response.recentInspectionsCount
          };
          this.loading=false
        }
        else if(ServerRes.isCreditAvailable==false && ServerRes?.success==false){
          const dialogRef = this.dialog.open(NetworkPopupComponent, {
            disableClose: true,
            backdropClass: AppSettings.backdropClass,
            width:'500px',
            data: { openPop: 'eCarreirCreadits', },
          });
          dialogRef.afterClosed().subscribe((result) => {
            this.loading=false;
          });
         this.loading=false
        }
        else if(ServerRes?.success==false){
          this.alertService.showNotificationMessage(
            'danger',
            'bottom',
            'right',
            'txt_d',
            'check_circle',
            'Outside Carrier',
            ServerRes?.message
          );
         this.loading=false
        }
      },
      (error) => {      
        this.alertService.showNotificationMessage(
          'danger',
          'bottom',
          'right',
          'txt_d',
          'check_circle',
          'Outside Carrier',
          error?.error?.message?.message
        );
      this.loading = false;
    });
    }
  }

}
