import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../commons/guard/auth.guard';
import { PendingChangesGuard } from '../commons/guard/pending-changes.guard';
import { DisputeComponent } from './dispute/dispute.component';

const routes: Routes = [
  {
    path: 'dispute/:tabType',
    component: DisputeComponent,
    canActivate: [AuthGuard],
    canDeactivate: [PendingChangesGuard],
  },
  
  {
    path: 'dispute/:tabType',
    children:[{path:'load/view', loadChildren: () =>
      import('../load/pages/view/view.module').then((mod) => mod.ViewModule)}]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DisputeRoutingModule { }
