import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CommonService } from '../../commons/service/common.service';
import { SharedService } from '../../commons/service/shared.service';
import { AppSettings } from '../../commons/setting/app_setting';
import { MatDialog } from '@angular/material/dialog';
import { DeviceDetectorService, DeviceInfo} from 'ngx-device-detector';
import { environment } from '../../../environments/environment';
import { Location } from '@angular/common';
import* as DeviceDetector from "device-detector-js"
import { PopupInfoComponent } from '../popup-info/popup-info.component';
declare var require: any;

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.css']
})

export class SignInComponent implements OnInit {
  public loginForm: FormGroup;
  public hide = true;
  public attemptCount = 0;
  public loading = false;
  public submitted = false;
  public userDetails: any;
  public isSubscription: any;
  public loginmessageError:any;
  public currentTabActive = 'login';
  public verifyEmail: any;
  public newTokan: any;
  public passwordSpaceCheck: string = null;
  public countSpace = 1;
  deviceInfo:DeviceInfo
  public searchBoxError = false;
  public emailPattern = '^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$';
  
  constructor(private formBuilder: FormBuilder,
    private commonService: CommonService,
    private router: Router,
    public dialog: MatDialog,
    public sharedService: SharedService,
    private deviceService: DeviceDetectorService,
    public location: Location) { 
      this.checkTokenRedirect()
    }

  ngOnInit(): void {
    const body = document.getElementsByTagName('body')[0];
    body.classList.add('before_login_user');
    this.loginForm = this.formBuilder.group({
      username: ['', [Validators.required, Validators.email, Validators.maxLength(128), Validators.pattern(this.emailPattern)]],
      password: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(16), Validators.pattern(/^[\S]+$/)]]
    });

    this.loginForm.valueChanges.subscribe(x => {
      if(this.loginForm.invalid){
       this.loginmessageError=null;
      }
    });

    let userDetail;
    userDetail = this.sharedService.getAddress();

    if (userDetail !== null) {
      this.userDetails = userDetail;
      this.userInfo(userDetail);
    }
    this.commonService.userData.subscribe((userData) => {
      if (userData && this.userDetails === undefined) {
        this.userInfo(userData);
      }
    });

  }

  // To navigate URL for sign in page 
  checkTokenRedirect(){
    this.newTokan = localStorage.getItem('access_token');
    if (
      localStorage.getItem('access_token') &&
      localStorage.getItem('login') === 'after_login' &&
      this.router.url !== '/'
    ) {
      this.router.navigate([this.router.url]);
    } else if (
      localStorage.getItem('access_token') &&
      localStorage.getItem('login') === 'after_login'
    ) {
      this.redirectByUser();
    }else{
       if(localStorage.getItem('onboarding') === 'signUp'){
        if (localStorage.getItem('premium') == '0') {
          this.router.navigateByUrl('/subscription-plan');
        }else{
          this.router.navigateByUrl('/sign-up-verify-email');
        }
       }else{
        this.router.navigate(['/']); 
       }
    }
  }

  // To navigate URL after sign in  
  redirectByUser() {
    const isMobile = this.deviceService.isMobile();
    if (!isMobile) {
      if (localStorage.getItem('user_type') === 'CARRIER') {
        this.commonService.sendTokanUpdate.next(null);
        this.router.navigateByUrl('/network/my-network/connected');
      } else if (localStorage.getItem('user_type') === 'SHIPPER' && localStorage.getItem('tag') === 'GTFO' && localStorage.getItem('premium') == '0') {

      } else if (localStorage.getItem('user_type') === 'SHIPPER') {
        this.commonService.sendTokanUpdate.next(null);
        this.router.navigateByUrl('/network/my-network/connected');
      } else if (localStorage.getItem('user_type') === 'STORAGE') {
        this.commonService.sendTokanUpdate.next(null);
        this.router.navigateByUrl('/storage/location/list');
      }
    } else {
      this.commonService.sendTokanUpdate.next(null);
      this.router.navigateByUrl('/dashboard/limit-mobile-view');
    }
  }

  // Function to get user details 
  userInfo(userDtail:any){
    if(userDtail){
      this.showOnboarding(userDtail)
      this.isSubscription = userDtail.subscription;
    }
  }

  // To navigate forgot password page
  forGotPassword(){
    this.router.navigate(['/forgot-password']); 
  }

  // To navigate sign-up page
  singUp(){
    window.open(environment.laneAxisDotCom + '/sign-up/', 'blank'); 
  }
  
  // Function for submit login form
  loginFormSubmit({ value, valid }) {
    this.submitted = true;
    this.loginmessageError=null;
    if (valid) {
      this.loading = true;
      let data = require('../../../../package.json');
      const getInfo = this.deviceService.getDeviceInfo();
      const deviceDetector = new DeviceDetector();
      let userAgent = navigator.userAgent
      const device = deviceDetector.parse(userAgent);
      value.deviceId = localStorage.getItem('uniqueId');
      value.deviceType = '3';
      value.osVersion = (device.device.type +' ('+device.os.name +'), '+device.client.name +' ('+device.client.version+')');
      value.appVersion = data.version;
      let APIparams = {
        apiKey: AppSettings.APIsNameArray.AUTH.NELOGIN,
        uri: '',
        postBody: value,
      };
      this.commonService.post(APIparams).subscribe(
        (success) => {
          if (success.success === true) {
            if (success.response.userType === 'CARRIER' || success.response.userType === 'SHIPPER' || success.response.userType === 'STORAGE') {
              var obj = {
                eventName: 'login',
                event_category: 'Login',
                userType: success.response.userType,
                EmailID: success.response.email,
                attemptCount: this.attemptCount + 1,
              };
              this.sharedService.track(obj);
              if (!success.response.emailVerified && !success.response.phoneVerified) {
                localStorage.setItem('user_type', success.response.userType);
                localStorage.setItem('access_token', success.response.verifyOtpToken);
                localStorage.setItem('email_verified', success.response.emailVerified);
                localStorage.setItem('phone_verified', success.response.phoneVerified);
                localStorage.setItem('refresh_token', success.response.refresh);
                localStorage.setItem('onboarding', 'signUp');
                if (localStorage.getItem('user_type') === 'CARRIER') {
                  localStorage.setItem('premium', success.response.premium);
                  localStorage.setItem('is_trial', success.response.isTrial);
                  localStorage.setItem('is_free_tracking', success.response.isFreeTracking);
                  localStorage.setItem('is_dba', success.response.isDba);
                  this.currentTabActive = 'user_otp_verify';
                  this.verifyEmail = success.response.email;
                  this.router.navigateByUrl('/sign-up-verify-email');
                } else if (localStorage.getItem('user_type') === 'SHIPPER' && success.response.premium === 0 && success.response.tag === 'GTFO') {
                  this.router.navigateByUrl('/subscription-plan');
                  localStorage.setItem('premium', success.response.premium);
                  localStorage.setItem('tag', success.response.tag);
                  if (success.response.premium === 1) {
                    this.router.navigateByUrl('/sign-up-verify-email');
                  }
                  this.verifyEmail = success.response.email;
                } else if (localStorage.getItem('user_type') === 'SHIPPER') {
                  localStorage.setItem('is_contract', success.response.isContract);
                  localStorage.setItem('premium', success.response.premium);
                  this.verifyEmail = success.response.email;
                  this.router.navigateByUrl('/sign-up-verify-email');
                } else if (localStorage.getItem('user_type') === 'STORAGE') {
                  this.verifyEmail = success.response.email;
                  this.router.navigateByUrl('/sign-up-verify-email');
                }
              } else {
                localStorage.setItem('user_type', success.response.userType);
                localStorage.setItem('access_token', success.response.access);
                localStorage.setItem('email_verified',success.response.emailVerified);
                localStorage.setItem('phone_verified', success.response.phoneVerified);
                localStorage.setItem('user_id', success.response.userId);
                localStorage.setItem('email', success.response.email);
                localStorage.setItem('refresh_token', success.response.refresh);
                if (success.response.premium == '0' && success.response.tag === 'GTFO') {
                  localStorage.setItem('onboarding', 'signIn');
                  localStorage.setItem('login', 'before_login');
                } else {
                  localStorage.setItem('login', 'after_login');
                }
                success.error = '';
                this.newTokan = success.response.access;
                if (!success.response.emailVerified) {
                  localStorage.setItem('onboarding', 'signIn');
                }
                if (localStorage.getItem('user_type') === 'CARRIER') {
                  localStorage.setItem('premium', success.response.premium);
                  localStorage.setItem('is_dba', success.response.isDba);
                  this.commonService.sendTokanUpdate.next(null);
                }else if (localStorage.getItem('user_type') === 'SHIPPER' && success.response.premium === 0 && success.response.tag === 'GTFO') {
                  localStorage.setItem('premium', success.response.premium);
                  localStorage.setItem('tag', success.response.tag);
                  if (success.response.premium === 1) {
                    this.router.navigateByUrl('/sign-up-verify-email');
                  } else {
                    this.router.navigateByUrl('/subscription-plan');
                  }
                  this.verifyEmail = success.response.email;
                }else if (localStorage.getItem('user_type') === 'SHIPPER') {
                  if (success.response.tag === 'GTFO') {
                    localStorage.setItem('tag', success.response.tag);
                    localStorage.setItem('premium', success.response.premium);
                  }
                  localStorage.setItem('is_contract', success.response.isContract);
                  localStorage.setItem('premium', success.response.premium);
                  this.commonService.sendTokanUpdate.next(null);
                } else {
                  this.commonService.sendTokanUpdate.next(null);
                }
                this.redirectUseLogin();
              }
            } else {
              this.loginmessageError = 'You are not authorized to sign-in.';
              this.loading = false;
            }

          } else {
            this.loading = false;
            this.loginmessageError = success.message;
          }
        },
        (error) => {
          this.loading = false;
          if (error.status === 401) {
            this.loginmessageError = 'Invalid Email/Password';
            this.attemptCount = this.attemptCount + 1;
            var obj = {
              eventName: 'login_failed',
              event_category: 'Login',
              attemptCount: this.attemptCount,
              EmailID: this.loginForm.get('username').value,
            };
            this.sharedService.track(obj);
          }
        }
      );
    }
  }

  // To check space in input box 
  checkSpace(event: any) {
    let word = event.target.value;
    let lastword = word.charAt(word.length - 1);
    if (lastword === ' ') {
      this.countSpace = this.countSpace + 1;
    } else {
      this.countSpace = 0;
    }
    if (this.countSpace >= 2 || (lastword === ' ' && word.length === 1)) {
      this.passwordSpaceCheck = this.passwordSpaceCheck.substring(
        0,
        this.passwordSpaceCheck.length - 1
      );
      this.searchBoxError = true;
    } else {
      this.searchBoxError = false;
    }
  }

  // To navigate user after log in 
  redirectUseLogin() {
    const isMobile = this.deviceService.isMobile();
    if (localStorage.getItem('user_type') === 'SHIPPER' && localStorage.getItem('tag') === 'GTFO' && localStorage.getItem('premium') == '0') {
      this.router.navigateByUrl('/subscription-plan');
    } else if (localStorage.getItem('user_type') === 'CARRIER' && !isMobile) {
      this.showOnboarding(true)
    } else if (localStorage.getItem('user_type') === 'CARRIER' && isMobile && this.isSubscription == null) {
      this.showOnboarding(true)
   }  else if (localStorage.getItem('user_type') === 'SHIPPER' && !isMobile) {
      this.showOnboarding(true)
   }  else if (localStorage.getItem('user_type') === 'SHIPPER' && isMobile && this.isSubscription == null) {
      this.showOnboarding(true)
   }  else if (localStorage.getItem('user_type') === 'STORAGE' && !isMobile) {
      this.router.navigateByUrl('/storage/location/list');
   }
    this.loading = false;
  }

  videoTutorialSignIn(){
      const dialogRef = this.dialog.open(PopupInfoComponent, {
        disableClose: true,
        backdropClass: AppSettings.backdropClass,
        width: '720px',
        data: {
          openPop: "videoTutorail",
        },
      });
      dialogRef.afterClosed().subscribe((result) => {
      });
  }

  showOnboarding(userDtail){
    if(localStorage.getItem('user_type')=='SHIPPER'){
      if ((!userDtail?.phyAddressLine1 || !userDtail?.phyCity || !userDtail?.phyState || !userDtail?.phyZip || !userDtail?.equipmentType
        || !userDtail?.preferredRegions || !userDtail?.signatureFont || !userDtail?.profileImage 
        || !userDtail?.contractDocument) && userDtail?.subscription != null)
      {   localStorage.setItem('hideLeftmenu','false')
          this.router.navigateByUrl('/shipper/onboarding');
      } 
      else if ((userDtail?.phyAddressLine1 || userDtail?.phyCity || userDtail?.phyState || userDtail?.phyZip || userDtail?.equipmentType
        || userDtail?.preferredRegions || userDtail?.signatureFont || userDtail?.profileImage 
        || userDtail?.contractDocument) && userDtail?.subscription != null){
          const body = document.getElementsByTagName('body')[0];
          body.classList.remove('before_login_user');
          this.router.navigateByUrl('/network/my-network/connected');
          localStorage.setItem('hideLeftmenu','true')
      }
    }
    if(localStorage.getItem('user_type')=='CARRIER'){
      if ((!userDtail?.phyAddressLine1 || !userDtail?.phyCity || !userDtail?.phyState || !userDtail?.phyZip || !userDtail?.equipmentType
        || !userDtail?.preferredRegions || !userDtail?.signatureFont || !userDtail?.profileImage || !userDtail?.coverImage || !userDtail?.insuranceAmount || !userDtail?.insuranceCompanyName
        || !userDtail?.insuranceCompanyEmail) && userDtail?.subscription != null) {
          localStorage.setItem('hideLeftmenu','false')
        this.router.navigateByUrl('/carrier/onboarding');
      }
      else if ((userDtail?.phyAddressLine1 || userDtail?.phyCity || userDtail?.phyState || userDtail?.phyZip || userDtail?.equipmentType
        || userDtail?.preferredRegions || userDtail?.signatureFont || userDtail?.profileImage || userDtail?.coverImage || userDtail?.insuranceAmount || userDtail?.insuranceCompanyName
        || userDtail?.insuranceCompanyEmail) && userDtail?.subscription != null){
          localStorage.setItem('hideLeftmenu','true')
          const body = document.getElementsByTagName('body')[0];
         body.classList.remove('before_login_user');
        this.router.navigateByUrl('/network/my-network/connected')
      }
    }
  
  }
  
}
