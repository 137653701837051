<div class="right_container_scroller">
    <div class="container">
      <div class="common_header">
        <div class="container">
          <p class="headdign">
            <img (click)='backHistory()' src="{{serverPathForUrl}}/portal/icon/ic_left_arrow_black.svg" class="mr_5 pointer">
            <span class="fs_18 fsb_6 txt_b " matTooltip="Create Load By Lane">Create Load By Lane</span>
          </p>
        </div>
      </div>
    
      <div class="rec_bid_heading_title">Select a Lane from the list below to create a Load for your confirmed lane.</div>
      <div class="p_14">
        <div class="filter_lanes_input_bx" *ngIf="skeletonLoader">
          <div class="row">
            <div class="col-md-3 ic_dropdown">
              <p class="fs_14 fsn_4 txt_lb mb-1">Equipment Type</p>
              <mat-form-field appearance="none" class="removeappearance" class="drop_bg">
                <mat-select placeholder="Select Equipment Type" class="form-control custom_input"
                  [(ngModel)]="searchModel.equipmentId" #equipmentId="ngModel" [ngModelOptions]="{standalone: true}">
                  <mat-option *ngFor="let equip of equipmentListing" [value]="equip?.id"
                    (click)="equipmentSelected(equip)">{{equip?.label}}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
    
            <div class="col-md-3 ic_dropdown">
              <p class="fs_14 fsn_4 txt_lb mb-1">State</p>
              <mat-form-field appearance="none" class="">
                <mat-select class="form-control custom_input" placeholder="Select State" [(ngModel)]="searchModel.stateId"
                  #stateId="ngModel" [ngModelOptions]="{standalone: true}">
                  <mat-option class="mat_dropdown" *ngFor="let state of phStates" [value]="state?.sortname"
                    (click)="stateSelected(state)" class="mat_dropdown"> {{state?.name}}</mat-option>
                </mat-select>
                <mat-error class="error_mess"></mat-error>
              </mat-form-field>
            </div>
    
            <div class="col-md-3">
              <p class="fs_14 fsn_4 txt_lb mb-1">Search</p>
              <mat-form-field appearance="none">
                <input matInput placeholder="Search by Lane Name and Lane ID" maxlength="64" [(ngModel)]="searchModel.title"
                  #title="ngModel" [ngModelOptions]="{standalone: true}" (keyup)="searchQuantity($event)" autocomplete="off"
                  class="custom_input">
              </mat-form-field>
            </div>
    
            <div class="col-md-12">
              <div class="filter_btn">
                <span class="cn_btn" (click)="clearFilter()">Clear All</span>
                <span class="ap_btn" *ngIf="!filterCheck" (click)="applyFilterSubmit()">Apply Filter</span>
                <span class="ap_btn opacity-50 bg_green adisabled" *ngIf="filterCheck">Apply Filter</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="filter_lanes_input_bx" *ngIf="!skeletonLoader " style="min-height: 155px;">
        <ngx-skeleton-loader [theme]="{ width: '100%', height: '14px' }" class=""
          style="margin-bottom: 15px;"></ngx-skeleton-loader>
        <ngx-skeleton-loader [theme]="{ width: '100%', height: '40px' }" class=""
          style="margin-bottom: 20px;"></ngx-skeleton-loader>
        <ngx-skeleton-loader [theme]="{ width: '100%', height: '14px' }" class=""></ngx-skeleton-loader>
      </div>
      <div class="container mt_140">
        <app-load-view-filter [titleFilter]="titleFilter" [equipmentNameShow]="equipmentNameShow"
          [stateShow]="stateShow" [isLane]="isLane" (clearOneFilter)="singleFilterClose($event)"
          class="lane_filtter confirm_lane_filtter"></app-load-view-filter>
        <div class="" *ngIf="skeletonLoader && lanesList?.length > 0">
          <div class="rec_bid_card pointer" *ngFor="let laneRecord of lanesList" (click)="laneByCreateShipment(laneRecord)">
            <div class="">
              <div class="d-flex align-items-center">
                <span class="rec_bid_title" matTooltip="{{laneRecord?.title}}">{{laneRecord?.title}}</span>
              </div>
              <div class="keypoints"><span>{{laneRecord?.equipmentName}}</span>
                <span>{{laneRecord?.miles}} Miles</span>
              </div>
              <div class="sc_locations ">
                <div class="sc_loca_picup">
                  <span class="icon-ic-pickupq loca_iconFlag">
                    <img src="/assets/icon/new-flag-card.svg"
                      class="img-fluid w_20"></span>
                  <div class="p_loca_time">
                    <p class="loca_picup "
                      matTooltip="Carretera a Garcia Km 1.3 Parque Stiva Santa Catarina N.L., Mexico">
                      {{laneRecord?.waypoints[0]?.['locationLongName']}}</p>
                  </div>
                </div>
                <div class="sc_loca_drop"><span class="loca_icon">
                  <img src="/assets/icon/new-location-icon.svg" class="img-fluid w_15">
                </span>
                  <div class="d_loca_time">
                    <p class="loca_drop text_truncate "
                      matTooltip="581 State Fair Blvd, Syracuse, NY 13209, United States">
                      {{laneRecord?.waypoints[1]?.['locationLongName']}}</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="blue_box">
              <p>Confirmed<br>Carriers</p>
              <p style="font-size: 30px; margin: 0px;">{{laneRecord?.confirmedCarriers ? laneRecord?.confirmedCarriers :
                "0"}}</p>
            </div>
          </div>
        </div>
        <div class="" *ngIf="!skeletonLoader && lanesList?.length === 0">
          <div class="row mx-auto" *ngFor="let fake of generateFake(3)">
            <app-load-skeleton-card></app-load-skeleton-card>
          </div>
        </div>
        <div class="row mx-auto" *ngIf="skeletonLoader && lanesList?.length === 0">
          <div class="col-md-12">
            <app-load-empty-screen [currentStatus]="currentStatus" [currentSearchStatus]="currentSearchStatus"
              class="confirm_lane_empty"></app-load-empty-screen>
          </div>
        </div>
        <div class="content_loader mt-3 mb-4" *ngIf="lanesList?.length > 0 && spinnerLoader">
          <span class="loader_image">
            <img src="{{serverPathForUrl}}/portal/images/after-login/cl-icons/loader-blue.svg" class="img-fluid">
          </span>
        </div>
        <div *ngIf="lanesList?.length > 0 && dataNotFound" class="noMore_record">
          <span style="margin: 10px 0px;">There are no more records to show.</span>
        </div>
      </div>
    </div>
    </div>