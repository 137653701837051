import { Location } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CommonService } from '../../commons/service/common.service';

@Component({
  selector: 'app-load-tab',
  templateUrl: './load-tab.component.html',
  styleUrls: ['./load-tab.component.scss']
})
export class LoadTabComponent implements OnInit {
  @Input() loadDetail: any;
  @Input() currentTabShow: any;
  @Input() userType: any;
  @Output() selectedTab = new EventEmitter();

  constructor(
    public commonService: CommonService,
    public dialog: MatDialog,
    public location: Location,
  ) { }

  ngOnInit(): void {
  }
  
  // Function for selecting active tab
  currentTabActive(type: any) {
    this.selectedTab.emit(type);
  }

}
