import {  NgModule } from '@angular/core';
import { RecurringLanesRoutingModule } from './recurring-lanes-routing.module';
import { ActiveLanesComponent } from './active-lanes/active-lanes.component';
import { CommonModule, DatePipe } from '@angular/common';
import { MatTabsModule } from '@angular/material/tabs';
import { NgxLoadingModule } from 'ngx-loading';
import { AgmCoreModule } from '@agm/core';
import { MatGoogleMapsAutocompleteModule } from '@angular-material-extensions/google-maps-autocomplete';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../shared/shared.module';
import { MaterialModule } from '../material.module';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { LoadsSharedModule } from '../loads-shared/loads-shared.module';
import { CreateLanesComponent } from './create-lanes/create-lanes.component';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { ConfirmedRateHistoryComponent } from './confirmed-rate-history/confirmed-rate-history.component';
import { LaneViewComponent } from './lane-view/lane-view.component';
import { LaneCarrierListComponent } from './lane-carrier-list/lane-carrier-list.component';
import { LaneShipperListComponent } from './lane-shipper-list/lane-shipper-list.component';
import { ConfirmedLoadListComponent } from './confirmed-load-list/confirmed-load-list.component';
import { EditLaneComponent } from './edit-lane/edit-lane.component';
import { LaneCreateComponent } from './lane-create/lane-create.component';
import { ConfirmLaneListComponent } from './confirm-lane-list/confirm-lane-list.component';
import { LoadByLaneComponent } from './load-by-lane/load-by-lane.component';
import { Loader } from '@googlemaps/js-api-loader';
import { NgxGpAutocompleteModule } from '@angular-magic/ngx-gp-autocomplete';

@NgModule({
  declarations: [
    ActiveLanesComponent,
    CreateLanesComponent,
    ConfirmedRateHistoryComponent,
    LaneViewComponent,
    LaneCarrierListComponent,
    LaneShipperListComponent,
    ConfirmedLoadListComponent,
    EditLaneComponent,
    LaneCreateComponent,
    ConfirmLaneListComponent,
    LoadByLaneComponent,
  ],
  imports: [
    NgxLoadingModule.forRoot({}),
    NgxSkeletonLoaderModule.forRoot({}),
    NgxMatSelectSearchModule,
    CommonModule,
    RecurringLanesRoutingModule,
    MatTabsModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    LoadsSharedModule,
    // AgmCoreModule.forRoot({
    //   apiKey: 'AIzaSyAMMBwZYg03hSwJEnODnIWf14YJKjWz_2A',
    //   libraries: ['places'],
    // }),
    MatGoogleMapsAutocompleteModule,
    MaterialModule,
    InfiniteScrollModule,
    SharedModule,
    NgxGpAutocompleteModule
  ],
 
  providers: [DatePipe,  { provide: Loader,useValue: new Loader({
    apiKey: 'AIzaSyAMMBwZYg03hSwJEnODnIWf14YJKjWz_2A',
    libraries: ['places']
  }) }],
  schemas: [],

})
export class RecurringLanesModule { }
