<div class="rvw_rtg_viw_edt">
    <div class="rating_box">
      <div class="rating_stars">
        <span class="rs_box txt_lb" *ngFor="let star of rating">
          <span class="material-icons txt_y">star</span>
        </span>
        <span class="rs_box txt_lb" *ngIf="isRatingHalf">
          <span class="material-icons txt_y">star_half</span>
        </span>
        <span class="rs_box txt_lb" *ngFor="let star of ratingEmpty">
          <span class="txt_lb"><span class="material-icons">star_border</span></span>
        </span>
      </div>
      <span *ngIf="userType==='SHIPPER' && ratingReview?.shipperNote">{{ratingReview?.shipperNote}}</span>
      <span *ngIf="userType==='CARRIER' && ratingReview?.carrierNote">{{ratingReview?.carrierNote}}</span>
    </div>
    <p *ngIf="ratingReview?.totalReview !== 1 && type !== 'listing' && rate" class="total_reviews" (click)="openReview()"> {{ratingReview?.totalReview ===1 ? 'Total Review' : 'Total Reviews '}}{{ratingReview?.totalReview}} </p>
    <p *ngIf="ratingReview?.totalReview === 1 && type !== 'listing' && rate" class="total_reviews" (click)="openReview()">Total {{ratingReview?.totalReview}} Review</p>
</div>