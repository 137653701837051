import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CommonService } from 'src/app/commons/service/common.service';
import { AppSettings } from 'src/app/commons/setting/app_setting';
import { NetworkPopupComponent } from 'src/app/network-shared/network-popup/network-popup.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-shipper-side-panel',
  templateUrl: './shipper-side-panel.component.html',
  styleUrls: ['./shipper-side-panel.component.css']
})

export class ShipperSidePanelComponent implements OnInit {
  @Input() userType:any;
  @Input() findUserType: any;
  @Input() sidePanelProfileData: any;
  @Input() sidePanelProfileDataId:any
  @Output() closeSidePanel = new EventEmitter();
  @Input() getUseProfileNewTab: any;
  public ratingOwnerDetail: {};
  public regionSelectedData:any;
  public loading = false;
  public profileSidePanelData:any;
  convertedRevenue: any;
  public serverPathForUrl: any;

  constructor(
    public dialog: MatDialog,
    public commonService: CommonService,
  ) { }

  ngOnInit() {
    this.serverPathForUrl = environment.serverPathForUrl;
    if (this.userType === 'CARRIER' && this.sidePanelProfileData) {
      this.ratingOwnerDetail = {
        key: 'shipperId',
        id: this.sidePanelProfileData?.shippers?.userId,
        ratingPlace: 'network'
      }
    }
    if (this.userType === 'SHIPPER' && this.sidePanelProfileData?.shippers?.userId) {
      this.ratingOwnerDetail = {
        key: 'carrierId',
        id: this.sidePanelProfileData?.userId,
        ratingPlace: 'network'
      }
    }

    if (this.userType === 'STORAGE' && (this.sidePanelProfileData?.shippers?.userId || this.sidePanelProfileData?.shipper?.userId) ) {
      this.ratingOwnerDetail = {
        key: 'shipperId',
        id: this.sidePanelProfileData?.shipper?.userId ? this.sidePanelProfileData?.shipper?.userId : this.sidePanelProfileData?.userId,
        ratingPlace: 'network'
      }
    }

    if (this.userType === 'CARRIER' && this.sidePanelProfileData?.shipper?.userId) {
      this.ratingOwnerDetail = {
        key: 'shipperId',
        id: this.sidePanelProfileData?.shipper?.userId,
        ratingPlace: 'network'
      }
    }
    if (this.userType === 'SHIPPER' && this.sidePanelProfileData?.shipper?.userId) {
      this.ratingOwnerDetail = {
        key: 'carrierId',
        id: this.sidePanelProfileData?.userId,
        ratingPlace: 'network'
      }
    }

    if (this.sidePanelProfileData?.revenueUsd) {
      this.convertedRevenue = this.convertMilionBillon(this.sidePanelProfileData?.revenueUsd);
    }

    if(this.sidePanelProfileDataId){
      this.getApi();
    }    

  }

  eqipmentSpace(equipmentType){
    return equipmentType.join(', ');
  }
 
  closePanel(value: any, type: any) {
    this.closeSidePanel.emit({ value, type });
  }

  convertMilionBillon(revenueUsd: any) {
    let million
    if (revenueUsd >= 1000) {
        million = (revenueUsd / 1000)
        million =Number(million.toString().match(/^\d+(?:\.\d{0,2})?/)) + 'B';
    } else if (revenueUsd != null) {
        million = revenueUsd + 'M'
    } else {
        million = null
    }
    return million
    
  }

  userLoginPopup(UserType: any) {
    const dialogRef = this.dialog.open(NetworkPopupComponent, {
      disableClose: true,
      backdropClass: AppSettings.backdropClass,
      width: AppSettings.popWidth,
      data: { openPop: 'required_Login', type: UserType },
    });
  }

  getApi(){
    this.loading = true;
    let uri = null;
    let newParams = {
      id: this.sidePanelProfileDataId
    };
    if (newParams) uri = this.commonService.getAPIUriFromParams(newParams);
    let APIparams = {
      apiKey: AppSettings.APIsNameArray.MANAGE_CONNECTION.GETPROFILE,
      uri: uri,
    };
    this.commonService.getList(APIparams).subscribe(
       (ServerRes) => {
        this.profileSidePanelData=ServerRes.response;
      })
  }

}


