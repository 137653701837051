import { Component, Input, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';
import { StatusSetting } from '../../commons/setting/status_setting';

@Component({
  selector: 'app-load-empty-screen',
  templateUrl: './load-empty-screen.component.html',
  styleUrls: ['./load-empty-screen.component.css'],
})
export class LoadEmptyScreenComponent implements OnInit {
  public serverPathForUrl: any;
  @Input() currentStatus: any;
  @Input() currentSearchStatus: any;
  public shipmentEmptyData = [];

  constructor() { }

  ngOnInit() {
    this.serverPathForUrl = environment.serverPathForUrl;
    if (this.currentSearchStatus) {
      if (localStorage.getItem('user_type') === 'CARRIER') {
        this.shipmentEmptyData = StatusSetting.emptyScreenStatusCarrier.filter(
          (item) => item.slug === this.currentSearchStatus
        );
      } else if (localStorage.getItem('user_type') === 'SHIPPER') {
        this.shipmentEmptyData = StatusSetting.emptyScreenStatusShipper.filter(
          (item) => item.slug === this.currentSearchStatus
        );
      }
    } else {
      if (localStorage.getItem('user_type') === 'CARRIER') {
        this.shipmentEmptyData = StatusSetting.emptyScreenStatusCarrier.filter(
          (item) => item.slug === this.currentStatus
        );
      } else if (localStorage.getItem('user_type') === 'SHIPPER') {
        this.shipmentEmptyData = StatusSetting.emptyScreenStatusShipper.filter(
          (item) => item.slug === this.currentStatus
        );
      }
    }
  }
  
}
