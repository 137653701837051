<div class="side_contaier">
	<div class="side_contaiert">
		<div class="header_bar" *ngIf="currentUrlTab !=='mapview'">
			<div class="side_headerl">
				<a *ngIf="userType==='SHIPPER'" [routerLink]="['/storage/profile/' + storageSidePanelData?.id]"
					target="_blank"><u class="fs_14 fsm_5 txt_p ">Open in New tab</u></a>
			</div>
			<div class="side_headerr" *ngIf="logout=== 'after_login'" (click)="closePanel(storageSidePanelData,'close')">
				<span class="icon-ic-cross fs_24 txt_lb pointer"><i class="fa-solid fa-xmark"></i></span>
			</div>
		</div>

		<div class="map_card_back" *ngIf="currentUrlTab === 'mapview'">
			<span class="material-icons back_icon txt_w"
				(click)="closePanel(storageSidePanelData,'changeView')">arrow_back</span>
		</div>
		<div class="map_card_back" *ngIf="logout=== 'before_login'">
			<span class="material-icons back_icon txt_w" (click)="closePanel(storageSidePanelData,'close')">arrow_back</span>
		</div>

		<div class="side_content v_scrollBar">
			<div class="header_con">
				<div class="header_img">
					<img *ngIf="storageSidePanelData?.coverImage" [src]="storageSidePanelData?.coverImage" class="img-fluid">
					<img *ngIf="storageSidePanelData?.storage?.coverImage" [src]="storageSidePanelData?.storage?.coverImage"
						class="img-fluid">
				</div>
				<div class="header_info">
					<div class="head_img">
						<div class="img_Con miW_84">
							<span class="user_image"
								*ngIf="storageSidePanelData?.storage && storageSidePanelData?.storage?.profileImage">
								<img [src]="storageSidePanelData?.storage?.profileImage" class="img-fluid">
							</span>

							<span *ngIf="storageSidePanelData?.profileImage" class="user_image">
								<img [src]="storageSidePanelData?.profileImage" class="img-fluid">
							</span>
							<span class="missed_img "
								*ngIf="storageSidePanelData?.storage && storageSidePanelData?.storage?.companyName && !storageSidePanelData?.storage?.profileImage">{{storageSidePanelData?.storage?.companyName
								| acronymOfName}}</span>
							<span *ngIf="storageSidePanelData?.companyName && !storageSidePanelData?.profileImage"
								class="missed_img ">{{storageSidePanelData?.companyName | acronymOfName}}</span>
						</div>
						<p class="head_name " *ngIf="storageSidePanelData?.companyName">{{storageSidePanelData?.companyName}}
						</p>
						<p class="head_name "
							*ngIf="storageSidePanelData?.storage && storageSidePanelData?.storage?.companyName">
							{{storageSidePanelData?.storage?.companyName}}</p>
					</div>
					<div class="revanu_loca">
						<p class="location">
							<span class="loca_lable">Location: </span>
							<span class="loca_value" *ngIf="!storageSidePanelData?.phyState && !storageSidePanelData?.phyCity"> -
							</span>
							<span class="loca_value" *ngIf="storageSidePanelData?.phyState || storageSidePanelData?.phyCity"> {{
								storageSidePanelData?.phyCity }}<span
									*ngIf="storageSidePanelData?.phyState && storageSidePanelData?.phyCity">,</span> {{
								storageSidePanelData?.phyState}}</span>
						</p>
					</div>
					<p class="accept_mess" *ngIf="storageSidePanelData?.note">
						<span class="fsm_5 me-1" *ngIf="storageSidePanelData.legalName">{{storageSidePanelData.legalName}}</span>
						<span class="fsm_5 me-1" *ngIf="storageSidePanelData.companyName">{{storageSidePanelData.companyName}}</span>
						<i class="fsn_4">{{storageSidePanelData?.note}}</i>
					</p>
				</div>
			</div>
			<div class="contact_info_container">
				<div class="info_conT">
					<p class="mb-0 fs_20 fsb_6 txt_b line_before ">Contact Info</p>
				</div>
				<div class="info_conB">
					<div class="info_conBrow">
						<span class="">
							<img src="{{serverPathForUrl}}/portal/images/after-login/cl-icons/ic-user-tc.svg"
								class="img-fluid">
						</span>
						<span class="ms-2 fs_14 fsm_5"
							*ngIf="storageSidePanelData?.firstName || storageSidePanelData?.lastName">{{storageSidePanelData?.firstName}}
							{{storageSidePanelData?.lastName}}</span>
						<span class="ms-2 fs_14 fsm_5"
							*ngIf="!storageSidePanelData?.firstName && !storageSidePanelData?.lastName">-</span>
					</div>
					<div class="info_conBrow">
						<span class="">
							<img src="{{serverPathForUrl}}/portal/images/after-login/cl-icons/ic-phone-tc.svg"
								class="img-fluid">
						</span>
						<p class="mb-0">
							<span class="ms-2 fs_14 fsm_5" *ngIf="storageSidePanelData?.phone">{{storageSidePanelData?.phone |
								phone}}</span>
							<span class="ms-2 fs_14 fsm_5" *ngIf="!storageSidePanelData?.phone">-</span>
						</p>

					</div>
					<div class="info_conBrow" *ngIf="storageSidePanelData?.phyState && storageSidePanelData?.phyCity">
						<span class="">
							<img src="{{serverPathForUrl}}/portal/images/after-login/cl-icons/ic-address-tc.svg"
								class="img-fluid">
						</span>
						<span class="ms-2 fs_14 fsm_5">{{storageSidePanelData?.phyAddressLine1 ?
							storageSidePanelData?.phyAddressLine1+"," :""}} {{storageSidePanelData?.phyAddressLine2 ?
							storageSidePanelData?.phyAddressLine2+"," :""}} {{storageSidePanelData?.phyCity ?
							storageSidePanelData?.phyCity+",": ""}} {{storageSidePanelData?.phyState ? storageSidePanelData?.phyState+",":
							""}} {{storageSidePanelData?.phyZip ? storageSidePanelData?.phyZip+"," :""}}
							{{storageSidePanelData?.phyCountry}}</span>
					</div>

					<div class="info_conBrowIn">
						<div class="linked_icon">

							<span class="info_social"
								*ngIf="storageSidePanelData?.linkedin || storageSidePanelData?.storage?.linkedin">
								<img class="img-fluid"
									src="{{serverPathForUrl}}/portal/images/after-login/cl-icons/ic-linkedin.svg">
							</span>

							<span class="info_social unactive_icon"
								*ngIf="!storageSidePanelData?.linkedin && !storageSidePanelData?.storage?.linkedin">
								<img class="img-fluid"
									src="{{serverPathForUrl}}/portal/images/after-login/cl-icons/ic-linkedin.svg">
							</span>

							<p class="fs_14 fsm_5 txt_p mb-0"
								*ngIf="storageSidePanelData?.linkedin || storageSidePanelData?.storage?.linkedin">
								<a *ngIf="storageSidePanelData?.linkedin" href="{{storageSidePanelData?.linkedin}}"
									target="_blank">LinkedIn Profile</a>
								<a *ngIf="storageSidePanelData?.storage?.linkedin" href="{{storageSidePanelData?.storage?.linkedin}}"
									target="_blank">LinkedIn Profile</a>
							</p>
							<p class="fs_14 fsm_5 txt_lb mb-0"
								*ngIf="!storageSidePanelData?.linkedin && !storageSidePanelData?.storage?.linkedin">LinkedIn Profile
							</p>
						</div>
					</div>

					<div class="info_conBrowIn mt-3">
						<div class="web_icon">
							<span class="info_social"
								*ngIf="storageSidePanelData?.websiteUrl || storageSidePanelData?.storage?.websiteUrl">
								<img class="img-fluid"
									src="https://s3.us-east-2.amazonaws.com/staticdev.laneaxis.com/storage/ic_icon/website-icon.png">
							</span>

							<span class="info_social"
								*ngIf="!storageSidePanelData?.websiteUrl && !storageSidePanelData?.storage?.websiteUrl">
								<img class="img-fluid"
									src="https://s3.us-east-2.amazonaws.com/staticdev.laneaxis.com/storage/ic_icon/website-icon.png">
							</span>

							<p class="fs_14 fsm_5 txt_p mb-0 pointer"
								*ngIf="storageSidePanelData?.websiteUrl || storageSidePanelData?.storage?.websiteUrl"
								(click)="websiteOpen(storageSidePanelData)">
								<span> Website </span>
							</p>

							<p class="fs_14 fsm_5 txt_lb mb-0"
								*ngIf="!storageSidePanelData?.websiteUrl && !storageSidePanelData?.storage?.websiteUrl">Website</p>
						</div>
					</div>
				</div>
			</div>
			<div class="contact_info_container pt-0" *ngIf!="isUserSidePanel">
				<div class="info_conT"> 
					<p class="mb-0 fs_20 fsb_6 txt_b line_before">
						<img src="{{serverPathForUrl}}/portal/images/after-login/cl-icons/ic-business-service.svg"
							class="img-fluid" style="width: 22px;">
						<span style="margin: 0 0 0 10px;" class="">Storage Services </span>
					</p>
				</div>

				<div class="buss_infoSide">
					<div class="row">
						<div class="col-md-6">
							<div class="sp_icon">
								<span class="sm_icon">
									<img src="https://s3.us-east-2.amazonaws.com/staticdev.laneaxis.com/storage/ic_icon/ic_frozen.svg"
										class="img-fluid">
								</span>
								<p class="buss_lable">Frozen</p>
							</div>
						</div>
						<div class="col-md-6">
							<span
								class="lo_list_icn {{storageSidePanelData?.isFrozen=='1' ? 'Yes' : 'No'}} }}">{{storageSidePanelData?.isFrozen=='1'
								? 'Yes' : 'No'}}</span>
						</div>
					</div>
					<div class="row">
						<div class="col-md-6">
							<div class="sp_icon">
								<span class="sm_icon">
									<img src="https://s3.us-east-2.amazonaws.com/staticdev.laneaxis.com/storage/ic_icon/ic_refrigerated.svg"
										class="img-fluid">
								</span>
								<p class="buss_lable">Refregirated</p>
							</div>
						</div>
						<div class="col-md-6">
							<span
								class="lo_list_icn {{storageSidePanelData?.isRefregirated=='1' ? 'Yes' : 'No'}} }}">{{storageSidePanelData?.isRefregirated=='1'
								? 'Yes' : 'No'}}</span>
						</div>
					</div>
					<div class="row">
						<div class="col-md-6">
							<div class="sp_icon">
								<span class="sm_icon">
									<img src="https://s3.us-east-2.amazonaws.com/staticdev.laneaxis.com/storage/ic_icon/ic_heated.svg"
										class="img-fluid">
								</span>
								<p class="buss_lable">Heated</p>
							</div>
						</div>
						<div class="col-md-6">
							<span
								class="lo_list_icn {{storageSidePanelData?.isHeated=='1' ? 'Yes' : 'No'}} }}">{{storageSidePanelData?.isHeated=='1'
								? 'Yes' : 'No'}}</span>
						</div>
					</div>
					<div class="row">
						<div class="col-md-6">
							<div class="sp_icon">
								<span class="sm_icon">
									<img src="https://s3.us-east-2.amazonaws.com/staticdev.laneaxis.com/storage/ic_icon/ic_dry_good.svg"
										class="img-fluid">
								</span>
								<p class="buss_lable">Dry Goods</p>
							</div>
						</div>
						<div class="col-md-6">
							<span
								class="lo_list_icn {{storageSidePanelData?.isDryGoods=='1' ? 'Yes' : 'No'}} }}">{{storageSidePanelData?.isDryGoods=='1'
								? 'Yes' : 'No'}}</span>
						</div>
					</div>
				</div>
			</div>
			<!-- --------------------------- -->
			<div class="contact_info_container pt-0" *ngIf!="isUserSidePanel">
				<div class="info_conT">
					<p class="mb-0 fs_20 fsb_6 txt_b line_before">
						<img src="{{serverPathForUrl}}/portal/images/after-login/cl-icons/ic-business-service.svg"
							class="img-fluid" style="width: 22px;">
						<span style="margin: 0 0 0 10px;" class="">Fulfilment Services</span>
					</p>
				</div>

				<div class="buss_infoSide">
					<div class="row">
						<div class="col-md-6">
							<div class="sp_icon">
								<span class="sm_icon">
									<img src="https://s3.us-east-2.amazonaws.com/staticdev.laneaxis.com/storage/ic_icon/ic_receiving.svg"
										class="img-fluid">
								</span>
								<p class="buss_lable">Receiving</p>
							</div>
						</div>
						<div class="col-md-6">
							<span
								class="lo_list_icn {{storageSidePanelData?.isReceiving=='1' ? 'Yes' : 'No'}} }}">{{storageSidePanelData?.isReceiving=='1'
								? 'Yes' : 'No'}}</span>
						</div>
					</div>
					<div class="row">
						<div class="col-md-6">
							<div class="sp_icon">
								<span class="sm_icon">
									<img src="https://s3.us-east-2.amazonaws.com/staticdev.laneaxis.com/storage/ic_icon/ic_outbound.svg"
										class="img-fluid">
								</span>
								<p class="buss_lable">Outbound</p>
							</div>
						</div>
						<div class="col-md-6">
							<span
								class="lo_list_icn {{storageSidePanelData?.isOutbound=='1' ? 'Yes' : 'No'}} }}">{{storageSidePanelData?.isOutbound=='1'
								? 'Yes' : 'No'}}</span>
						</div>
					</div>

					<div class="row">
						<div class="col-md-6">
							<div class="sp_icon">
								<span class="sm_icon">
									<img src="https://s3.us-east-2.amazonaws.com/staticdev.laneaxis.com/storage/ic_icon/ic_pick_pack.svg"
										class="img-fluid">
								</span>
								<p class="buss_lable">Pick And Pack</p>
							</div>
						</div>
						<div class="col-md-6">
							<span
								class="lo_list_icn {{storageSidePanelData?.pickAndPack=='1' ? 'Yes' : 'No'}} }}">{{storageSidePanelData?.pickAndPack=='1'
								? 'Yes' : 'No'}}</span>
						</div>
					</div>
					<div class="row">
						<div class="col-md-6">
							<div class="sp_icon">
								<span class="sm_icon">
									<img src="https://s3.us-east-2.amazonaws.com/staticdev.laneaxis.com/storage/ic_icon/ic_inventory_counts.svg"
										class="img-fluid">
								</span>
								<p class="buss_lable">Inventory Counts</p>
							</div>
						</div>
						<div class="col-md-6">
							<span
								class="lo_list_icn {{storageSidePanelData?.inventoryCounts=='1' ? 'Yes' : 'No'}} }}">{{storageSidePanelData?.inventoryCounts=='1'
								? 'Yes' : 'No'}}</span>
						</div>
					</div>
				</div>
			</div>
			<div class="info_conT" *ngIf!="isUserSidePanel">
				<p class="mb-0 fs_20 fsb_6 txt_b line_before">
					<img src="{{serverPathForUrl}}/portal/images/after-login/cl-icons/ic-business-service.svg"
						class="img-fluid" style="width: 22px;">
					<span style="margin: 0 0 0 10px;" class="">Shipment Service </span>
				</p>
			</div>
			<div class="buss_infoSide" *ngIf!="isUserSidePanel">
				<div class="row">
					<div class="col-md-6">
						<div class="sp_icon">
							<span class="sm_icon">
								<img src="https://s3.us-east-2.amazonaws.com/staticdev.laneaxis.com/storage/ic_icon/ic_negotiated_rates.svg"
									class="img-fluid">
							</span>
							<p class="buss_lable">Negotiated Rates</p>
						</div>
					</div>
					<div class="col-md-6">
						<span
							class="lo_list_icn {{storageSidePanelData?.negotiatedRates=='1' ? 'Yes' : 'No'}} }}">{{storageSidePanelData?.negotiatedRates=='1'
							? 'Yes' : 'No'}}</span>
					</div>
				</div>

			</div>
		</div>
	</div>
	<div class="side_contaierb">
		<button class="pro_view_btn btn custum_button button_primary w-100"
		[routerLink]="['/storage/profile/' + storageSidePanelData?.id]">View full Profile</button>
	</div>
</div>
