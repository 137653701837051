<div class="custom_nav" >
  <div *ngIf="hideLeftMenu">
  <div class="cus_nav_panel" *ngIf="userDataForBanner" >
    <div class="btn_crt_ship" id="ship_two" *ngIf="isCreateNewLoad">
      <span class="btn_crt">
        <button type="button" class="create_load_btn" (click)="createShipmentCheck()">
          <img src="{{serverPathForUrl}}/portal/icon/ic_add_white.svg" class="add_icon">
          <span class=" fs_16 fw_500 ms-2"> Create New Load</span>
        </button>
      </span>
    </div>
    <div class="btn_crt_ship" *ngIf="userType === 'SHIPPER' && isCreateNewLaneLoad">
      <span class="btn_crt">
        <button type="button" class="create_load_btn ms-3" (click)="confirmLaneList()">
          <i class="fa-solid fa-plus fs_20 fs_m_5 me-2"></i>
          <span class=" fs_16 fw_500">Convert Lane to Load</span>
        </button>
        <button type="button" class="create_load_btn" (click)="createLane()">
          <i class="fa-solid fa-plus fs_20 fs_m_5 me-2"></i>
          <span class=" fs_16 fw_500">Create New Lane</span>
        </button>
      </span>
    </div>
    <div class="btn_crt_ship" id="ship_two" (click)="driverSendInvite()" *ngIf="isAddNewDriver && userType == 'CARRIER'">
      <span class="btn_crt">
        <button type="button" class="create_load_btn"><img src="https://s3.us-east-1.amazonaws.com/staticprod.laneaxis.com/portal/icon/ic_add_white.svg"><span class="ms-2">Add New Driver</span> </button>
      </span>
    </div>
    <div class="cnp_box_l pointer" (click)="naviageToProfile('profile')">
      <div class="profle_box">
        <span class="user_name text_truncate" matTooltip="{{userName}}">{{userName}}</span>
        <span class="profile_btn">View Profile</span>
      </div>
      <div class="user_immg_box">
        <span class="profile_img" *ngIf="proImage">
          <img [src]="proImage" class="img-fluid pointer">
        </span>
        <span class="initial_name initial_c_34 " *ngIf="!proImage && userName !==null">{{userName | acronymOfName}}</span>
      </div>
    </div>
    <div class="cnp_box_c">
      <div class="custom_menu">
        <div class="nav" id="accordion">
          <div class="pointer" [class.add_nav_notifications]="isMenuOpen">
            <div class="notificationDrop_menu">
              <span class="notificationDrop_menu_tolg" #toggleButton1 (click)="toggleMenu()">
                <img src="{{serverPathForUrl}}/portal/icon/ic_bell_notification_black.svg" class="icon icon-ic-notifications notifications fs_24">
                <span class="badge custom_badge" *ngIf="allUnreadNotification"></span>
              </span>
              <div class="menu nav_notifications" *ngIf="isMenuOpen" #menu>
                <div class="notification_header fs_18 fsb_6 txt_b">
                  <span>Notifications</span>
                  <div style="position: relative;">
                    <div data-toggle="dropdown">
                      <div class="second_lable_dropdown">
                        <span class="icon icon-ic-dots list_option"></span>
                        <div class="dropdown-menu" style="min-width: 11rem;">
                          <span class="dropdown-item pointer " (click)="markAllRed()"> Mark all as Read</span>
                          <span class="dropdown-item pointer " (click)="clickRefresh()"> Refresh </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="" id="bell_notification_dropdown" >
                  <mat-tab-group animationDuration="0ms" [selectedIndex]="selectedIndex" 
                    (click)="notifictionList($event.target.outerText)">
                    <mat-tab label="Bookings"> 
                      <ng-template mat-tab-label>
                      <span [ngClass]="totalUnreadTabs['bookings'] ? 'well_inner_noti' : ''">Bookings</span>
                      </ng-template>
                    </mat-tab>
                    <mat-tab *ngIf="userType!=='STORAGE'" label="Shipments">
                      <ng-template mat-tab-label>
                        <span [ngClass]="totalUnreadTabs['shipments'] ? 'well_inner_noti' : ''">Shipments</span>
                        </ng-template>
                    </mat-tab>
                    <mat-tab label="Network">
                      <ng-template mat-tab-label>
                        <span [ngClass]="totalUnreadTabs['network'] ? 'well_inner_noti' : ''">Network</span>
                        </ng-template>
                    </mat-tab>
                    <mat-tab *ngIf="userType==='CARRIER'" label="Drivers">
                      <ng-template mat-tab-label>
                        <span [ngClass]="totalUnreadTabs['drivers'] ? 'well_inner_noti' : ''">Drivers</span>
                        </ng-template>
                    </mat-tab>
                    <mat-tab label="Others">
                      <ng-template mat-tab-label>
                        <span [ngClass]="totalUnreadTabs['others'] ? 'well_inner_noti' : ''">Others</span>
                        </ng-template>
                    </mat-tab>
                  </mat-tab-group>
                </div>
                <p class="missed_noti" *ngIf="newnotificationList?.length===0 && notifcionsklitor">No Notifications Yet</p>
                <div>
                  <div class="list_scroll notification_scroll" infiniteScroll [infiniteScrollDistance]="3"
                    [infiniteScrollThrottle]="100" (scrolled)="onScroll()" [scrollWindow]="false"
                    *ngIf="notifcionsklitor && newnotificationList?.length > 0">
                    <div class="dropdown-item" *ngFor="let notiList of newnotificationList;let i=index"
                      [ngClass]="notiList?.isRead===1 ? 'readed_notification' : 'unreaded_notification'"
                      id="setnotification_{{i}}">
                      <div class="notification_menu fs_12 fsm_5 txt_b pointer"
                        (click)="readFun(notiList?.id,notiList?.isRead,notiList?.data.url)">
                        <div class="notification_menu_l">
                          <span [ngClass]="notiList?.data?.notiClass !=='' ? notiList?.data?.notiClass : 'laSq_logo'"
                            class="notification_icon">
                            <img src="{{notiList?.data?.mapIconPng}}" class="img-fluid">
                          </span>
                        </div>
                        <div class="notification_menu_r">
                          <div class="" style="width: 100%;">
                            <!-- notification_menu_r1 -->
                            <div class="noti_row_title_date">
                              <div class="newtast noti_row_title " [innerHTML]="notiList?.data?.title"></div>
                              <!-- getInitials -->
                              <span *ngIf="notiList?.createdAt" class="txt_g noti_row_date">{{notiList?.createdAt | dateAgo }}</span>
                            </div>
                            <div style="max-width: 22rem;">
                              <div class="newtast1 txt_lb fs_12 text_truncateQW" [innerHTML]="notiList?.data?.description">
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="notification_actionbtn" *ngIf="notiList?.data?.slug=== 'invited'">
                        <span *ngIf="userType==='CARRIER'">
                          <button *ngIf="notiList?.data?.actionTaken===1" class="btn custum_button button_primary"
                            (click)="notificationAction(notiList?.id,notiList?.data.shipperId,'CONNECT')">Accept</button>
                          <button *ngIf="notiList?.data?.actionTaken===1"
                            class="btn custum_button button_primary txt_p ms-3"
                            (click)="notificationAction(notiList?.id,notiList?.data?.shipperId,'REJECT')"
                            style="background: #3787e61a">Decline</button>
                        </span>
                        <span *ngIf="userType==='SHIPPER'">
                          <button *ngIf="notiList?.data?.actionTaken===1" class="btn custum_button button_primary"
                            (click)="notificationAction(notiList?.id,notiList?.data.carrierId,'CONNECT')">Accept</button>
                          <button *ngIf="notiList?.data?.actionTaken===1"
                            class="btn custum_button button_primary txt_p ms-3"
                            (click)="notificationAction(notiList?.id,notiList?.data?.carrierId,'REJECT')"
                            style="background: #3787e61a">Decline</button>
                        </span>
                        <p *ngIf="notiList?.data?.actionTaken===0" class="fs_12 txt_s fsm_5 mt-2 mb-0"
                          style="display: flex; align-items: center;">
                          <span class="material-icons fs_16 me-1">check_circle_outline</span>
                          <span>You have responded</span>
                        </p>
                      </div>
        
                    </div>
                    <div class="nav_spiner" *ngIf="spinnderLoader">
                      <div class="spinner-border txt_p" role="status">
                        <span class="sr-only">
                          <!-- Loading... -->
                        </span>
                      </div>
                    </div>
                  </div>
                  <div *ngIf="notifcionsklitor">
                    <div *ngIf="userType==='CARRIER'" class="see_more_notification text-center bg_w txt_p fs_14 fsm_5"
                      (click)="toggleMenu()" [routerLink]="['/carrier/notification']">
                      <span class="">See All</span>
                    </div>
        
                    <div *ngIf="userType==='SHIPPER'" class="see_more_notification text-center bg_w txt_p fs_14 fsm_5"
                      (click)="toggleMenu()" [routerLink]="['/shipper/notification']">
                      <span class="">See All</span>
                    </div>
        
                    <!-- disable -link class added to disable this see all -->
                    <div *ngIf="userType==='STORAGE'" class="see_more_notification text-center bg_w txt_p fs_14 fsm_5"
                      (click)="toggleMenu()" [routerLink]="['/storage/notification']">
                      <span class="">See All</span>
                    </div>
        
                  </div>
        
                </div>
                <!-- Skelaton Loader Start -->
                <div *ngIf="!notifcionsklitor">
                  <div class="nav_noti_skton" *ngFor="let fake of generateFake(7)">
                    <div class="dropdown-item bg_w" style="padding: 5px 10px;">
                      <div class="notification_menu">
                        <div class="notification_menu_l" style="width: 15%; float: left;">
                          <ngx-skeleton-loader appearance="circle" [theme]="{ width: '40px', height: '40px'}">
                          </ngx-skeleton-loader>
                        </div>
                        <div class="loader_mb_0" style="width: 70%; float: left;">
                          <ngx-skeleton-loader count="1" [theme]="{ width: '70%', height: '12px'}"></ngx-skeleton-loader>
                          <br>
                          <ngx-skeleton-loader count="1" [theme]="{ width: '55%', height: '12px'}"></ngx-skeleton-loader>
                        </div>
                        <div style="width: 15%; text-align: end; padding-top: 8px;">
                          <ngx-skeleton-loader count="1" [theme]="{ width: '80%', height: '12px'}"></ngx-skeleton-loader>
                        </div>
                      </div>
                    </div>
                  </div>
                </div><!-- Skelaton Loader End -->
              </div>
            </div>
            <a class="nav-link notifications_txt" [routerLink]="['/carrier/view-notifications']">
              <span class="">Notifications</span>
              <span class="badge custom_badge" *ngIf="totalNotificationCount">{{totalNotificationCount}} </span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="!hideLeftMenu">
  <div class="cus_nav_panel" *ngIf="userDataForBanner" >
    <div class="lsp_head">
      <div class="la_logo_img">
        <img class="img-fluid" src="{{serverPathForUrl}}/portal/images/after-login/laneaxis-logo.svg">
      </div>
    </div>
    <div class="cnp_box_l pointer">
      <div class="profle_box">
        <span class="user_name text_truncate" matTooltip="{{userName}}">{{userName}}</span>
      </div>
      <div class="user_immg_box">
        <span class="profile_img" *ngIf="proImage">
          <img [src]="proImage" class="img-fluid pointer">
        </span>
        <span class="initial_name initial_c_34 " *ngIf="!proImage && userName !==null">{{userName | acronymOfName}}</span>
      </div>
    </div>
    <div class="profle_box pointer" (click)="logoutUser()">
      <span class="logout_btn text_truncate">Logout</span>
    </div>
  </div>
</div>
 
</div>