

<div class="text-center">
  <div mat-dialog-content class="mat-typography">
    <div class="popup_typo">
      <div class="text-center" style="margin: 0; padding: 2rem 1rem;">
        <p class="fs_20 fsm_5 txt_b" style="margin: 0 0 2rem;">Your Session Has Expired.</p>
        <p class="fs_16 fsn_4 txt_lb" style="margin: 0 0 2rem; line-height: 24px;">Please Sign in again to access LaneAxis network portal.</p>
        <div class="text-center">
          <button type="button" class="btn custum_button button_primary w-100 fs_16 fsn_4" (click)="logOutFun()" style="margin: 0 0 1.5rem; height: 40px; border-radius: 30px; box-shadow: none;">Sign Out</button>
        </div>
      </div>
    </div>   
  </div>
</div>
