import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CommonService } from '../../commons/service/common.service';
import { AppSettings } from '../../commons/setting/app_setting';
import { SharedService } from '../../commons/service/shared.service';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { environment } from '../../../environments/environment';
import { LoadPopupComponent } from 'src/app/loads-shared/load-popup/load-popup.component';

export interface shipInfo {
  amount: any;
}

@Component({
  selector: 'app-order-side-panel',
  templateUrl: './order-side-panel.component.html',
  styleUrls: ['./order-side-panel.component.css'],
})

export class OrderSidePanelComponent implements OnInit {
  @Input() orderDetail: any;
  @Input() sidePanelOrderId: any;
  @Input() currentStatus: any;
  @Input() shipmentDetailHome: any;
  @Input() id: any;
  @Input() connectStripe: any;
  sidePanelLoader: boolean = false;
  @Output() closeSidePanel = new EventEmitter();
  @Output() refreshList = new EventEmitter();
  public userType: any;
  public skeletonLoader = true;
  public shipperId: any;
  public currentTabShow: any;
  public page = 1;
  public bidHistorySekeletonLoader = false;
  public currentId: any;
  public currentBid: any;
  public acceptBidList: any;
  public previousBid: any;
  public previousBidHistory: any;
  public serverPathForUrl: any;
  public detailTabIndicator: boolean = false;
  public serviceStorageTabIndicator: boolean = false;
  public bidTabIndicator: boolean = false;
  public userId: any;
  public isMLWallet: any;
  public isHomePage: boolean = false;

  constructor(
    private router: Router,
    public commonService: CommonService,
    public dialog: MatDialog,
    public location: Location,
    public sharedService: SharedService
  ) {}

  ngOnInit() {
    this.skeletonLoader = false;
    if (this.shipmentDetailHome) {
      this.id = this.shipmentDetailHome;
      this.isHomePage = true;
    } else {
      if (this.orderDetail) {
        this.id = this.orderDetail.id;
        this.isHomePage = false;
      }
    }
    this.userType = localStorage.getItem('user_type');
    this.userId = localStorage.getItem('user_id');
    this.connectStripe = localStorage.getItem('stripeConenct');
    this.isMLWallet = localStorage.getItem('isMLWallet');
    if (this.isMLWallet === true || this.isMLWallet === 'true') {
      this.isMLWallet = true;
    } else {
      this.isMLWallet = false;
    }
    if (this.currentStatus === 'orders' || this.currentStatus === 'bidding') {
      this.detailTabIndicator = true;
      this.serviceStorageTabIndicator = true;
    } else {
      this.detailTabIndicator = false;
      this.serviceStorageTabIndicator = false;
    }
    this.bidTabIndicator =
      (this.currentStatus === 'orders' || this.currentStatus === 'bidding') &&
      this.userType === 'STORAGE';

    this.getCurrentStatus('orders');

    this.serverPathForUrl = environment.serverPathForUrl;
    
  }

  orderGet() {
    this.sidePanelLoader = true;
    return new Promise((resolve, reject) => {
      let uri = null;
      let newParams;
      if (this.sidePanelOrderId) {
        newParams = {
          id: this.sidePanelOrderId,
        };
      } else {
        newParams = {
          id: this.orderDetail.id,
        };
      }

      if (newParams) uri = this.commonService.getAPIUriFromParams(newParams);
      let APIparams = {
        apiKey: AppSettings.APIsNameArray.STORAGEORDER.GET,
        uri: uri,
      };
      this.commonService.getList(APIparams).subscribe(async (ServerRes) => {
        if (ServerRes.success === true) {
          this.sidePanelLoader = false;
          this.orderDetail = ServerRes.response.orders;
          resolve(ServerRes.response.orders);
        } else {
          resolve(null);
          this.sidePanelLoader = false;
        }
      });
    });
  }

  async getCurrentStatus(type: any) {
    this.currentTabShow = 'orders';
    this.orderDetail = await this.orderGet();
    if (this.orderDetail && this.orderDetail.createdAt) {
      this.orderDetail.createdAt = new Date(
        this.orderDetail.createdAt + ' ' + 'UTC'
      );
    }
    if (this.orderDetail && this.orderDetail.updatedAt) {
      this.orderDetail.updatedAt = new Date(
        this.orderDetail.updatedAt + ' ' + 'UTC'
      );
    }
    if (this.orderDetail) {
      for (let p = 0; p < this.orderDetail.pickupAddress.length; p++) {
        this.orderDetail.pickupAddress[p].date = this.orderDetail.pickupAddress[
          p
        ].date.replace(/\-/g, '/');
      }
      for (let d = 0; d < this.orderDetail.dropoffAddress.length; d++) {
        this.orderDetail.dropoffAddress[d].date =
          this.orderDetail.dropoffAddress[d].date.replace(/\-/g, '/');
      }
    }
  }

  closePanel(value: any, type: any) {
    this.closeSidePanel.emit({ value, type });
  }

  async currentTabActive(type: any) {
    if (type === 'orders') {
      this.currentTabShow = type;
      this.skeletonLoader = false;
    }
    if (type === 'storageService') {
      this.currentTabShow = type;
      this.skeletonLoader = false;
    }
    if (type === 'storageOrderQuotes') {
      this.currentTabShow = 'storageOrderQuotes';
      this.orderQuotesHistory();
    }
  }

  // Order history data
  orderQuotesHistory() {
    this.bidHistorySekeletonLoader = true;
    let uri = null;
    let params = {
      orderId: this.orderDetail.id,
    };
    if (params) uri = this.commonService.getAPIUriFromParams(params);
    let APIparams = {
      apiKey: AppSettings.APIsNameArray.STORAGEORDER.QUATES,
      uri: uri,
    };
    this.commonService.getList(APIparams).subscribe(
      (ServerRes) => {
        this.bidHistorySekeletonLoader = false;
        if (ServerRes.success === true) {
          if (ServerRes.response.currentBid.length > 0) {
            for (let v = 0; v < ServerRes.response.currentBid.length; v++) {
              ServerRes.response.currentBid[v].createdAt = new Date(
                ServerRes.response.currentBid[v]['createdAt'] + ' ' + 'UTC'
              );
            }
            this.currentBid = ServerRes.response.currentBid;
            this.previousBid = ServerRes.response.previousBid;
            if (this.previousBid.length > 0) {
              for (let v = 0; v < this.previousBid.length; v++) {
                this.previousBid[v].createdAt = new Date(
                  this.previousBid[v]['createdAt'] + ' ' + 'UTC'
                );
              }
            }
            this.previousBidHistory = this.previousBid;
          } else {
            this.previousBid = ServerRes.response.previousBid;
            this.previousBidHistory = this.previousBid;
            this.currentBid = ServerRes.response.currentBid;
          }
        } else {
          this.currentBid = [];
          this.previousBid = [];
          this.previousBidHistory = [];
        }
      },
      (error) => {
        this.currentBid = [];
        this.previousBid = [];
        this.previousBidHistory = [];
      }
    );
  }

  viewOrderDetail(id: any) {
    this.router.navigate(['/storage-order/view', id, 'storageOrderQuotes']);
  }

  checkWithdrawCondition(actionByUserID) {
    let user_id = localStorage.getItem('user_id');
    if (user_id === actionByUserID && this.userType === 'STORAGE') return true;
    else return false;
  }

  // Cancel bid popup
  cancelBid(
    bidId: any,
    amount: any,
    note: any,
    userName: any,
    paymentMethod: any
  ) {
    const dialogRef = this.dialog.open(LoadPopupComponent, {
      backdropClass: AppSettings.backdropClass,
      disableClose: true,
      width: AppSettings.popWidth,
      data: {
        openPop: 'cancelreBid',
        userName: userName,
        bidId: bidId,
        amount: amount,
        note: note,
        orderId: this.orderDetail.id,
        uniqueId: this.orderDetail.uniqueId,
        paymentMethod: paymentMethod,
        occurence: 'side_panel',
        orderDetail: this.orderDetail,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        if (result.event === 'Ok') {
          this.orderQuotesHistory();
          this.refreshList.emit();
        }
      }
    });
  }

  bid() {
    const dialogRef = this.dialog.open(LoadPopupComponent, {
      backdropClass: AppSettings.backdropClass,
      width: AppSettings.popWidth,
      data: {
        openPop: 'bid',
        userNameOther: this.orderDetail.shipper.companyName,
        userName: this.orderDetail.shipper.companyName,
        orderId: this.orderDetail.id,
        bidFor: this.orderDetail.shipper.userId,
        amount: 0,
        paymentMethod: this.orderDetail.paymentMethod,
        occurence: 'main',
        orderDetail: this.orderDetail,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        if (result.event === 'Ok') {
          this.orderQuotesHistory();
          this.refreshList.emit();
        }
      }
    });
  }

  // Rebid bid popup
  redBid(data: any) {
    let userId;
    if (this.userType === 'STORAGE') {
      userId = data.shipperId;
    } else {
      userId = data.storageId;
    }
    const dialogRef = this.dialog.open(LoadPopupComponent, {
      backdropClass: AppSettings.backdropClass,
      disableClose: true,
      width: AppSettings.popWidth,
      data: {
        openPop: 'rebid',
        userNameOther: this.orderDetail.shipper.companyName,
        userName: data.userName,
        amount: data.bidAmount,
        note: data.note,
        orderId: this.orderDetail.id,
        bidFor: userId,
        paymentMethod: this.orderDetail.paymentMethod,
        occurence: 'side_panel',
        shipmentDetail: this.orderDetail,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        if (result.event === 'Ok') {
          this.refreshList.emit();
          this.orderQuotesHistory();
        }
      }
    });
  }

  confirmBid(record: any) {
    let getUserName;
    getUserName = this.orderDetail.shipper.companyName;
    const dialogRef = this.dialog.open(LoadPopupComponent, {
      backdropClass: AppSettings.backdropClass,
      disableClose: true,
      width: AppSettings.popWidth,
      data: {
        openPop: 'acceptOrderConfirm',
        userName: getUserName,
        userNameOther: this.orderDetail.shipper.companyName,
        amount: record.bidAmount,
        note: null,
        bidId: parseInt(record.id),
        orderId: this.orderDetail.id,
        uniqueId: this.orderDetail.uniqueId,
        paymentMethod: this.orderDetail.paymentMethod,
        bidingStatus: record.statusLabel,
        occurence: 'main',
        orderDetail: this.orderDetail,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        if (result.event === 'Ok') {
          this.refreshList.emit();
          this.orderQuotesHistory();
        }
      }
    });
  }

  openTabShipment(id: any, tabType:any) {
    if (this.orderDetail && this.orderDetail.statusSlug === 'confirmed') {
      let newCurrentStatus = 'order';
      const url = this.router.serializeUrl(
        this.router.createUrlTree([
          `/storage-order/view/${id}/${newCurrentStatus}`,
        ])
      );
      if(tabType=='newTab')
      window.open(url, '_blank');
      else
      window.open(url, '_self');
    } else {
      let newCurrentStatus = 'storageOrderQuotes';
      const url = this.router.serializeUrl(
        this.router.createUrlTree([
          `/storage-order/view/${id}/${newCurrentStatus}`,
        ])
      );
      if(tabType=='newTab')
      window.open(url, '_blank');
      else
      window.open(url, '_self');
    }
  }

}
