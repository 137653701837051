import { Component, Input, ViewEncapsulation,OnChanges,OnInit } from '@angular/core';
import { SharedService } from '../../commons/service/shared.service';

@Component({
  selector: 'app-progress-load',
  templateUrl: './progress-load.component.html',
  styleUrls: ['./progress-load.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ProgressLoadComponent implements OnInit {
  @Input() fillColor: string;
  @Input() statusType: number;
  @Input() pageType: string;
  @Input() statusSlug: any;
  @Input() updatedAt: any;
  @Input() deliverydate: any;
  @Input() statusBars: any;
  @Input() statusLabel: any;
  public showShipmentStatus: any;
  @Input() showSection: any;
  counter(i: number) {
    return new Array(i);
  }
  
  constructor(public sharedService: SharedService) { }

  
  ngOnChanges() {
    if (this.statusSlug) {
      this.showShipmentStatus = this.sharedService.getShipmentBannerStatus(
        this.statusSlug,
        this.updatedAt,
        this.deliverydate
      );
    }
  }

  ngOnInit() {
    if (this.statusSlug) {
      this.showShipmentStatus = this.sharedService.getShipmentBannerStatus(
        this.statusSlug,
        this.updatedAt,
        this.deliverydate
      );
    }
  }

}
