import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder,FormArray } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { Location } from '@angular/common';
import { AlertService } from 'src/app/commons/service/alert.service';
import { AppSettings } from 'src/app/commons/setting/app_setting';
import { CommonService } from 'src/app/commons/service/common.service';
import { StatusSetting } from 'src/app/commons/setting/status_setting';
import { PopupComponent } from 'src/app/shared/popup/popup.component';
import { environment } from 'src/environments/environment';
import { SharedService } from 'src/app/commons/service/shared.service';
import { MatStepper } from '@angular/material/stepper';
import { ViewChild } from '@angular/core';
import { PaymentService } from 'src/app/payment/payment.service';
import { GermanAddress } from '@angular-material-extensions/google-maps-autocomplete';
import PlaceResult = google.maps.places.PlaceResult;
import { KeyValue } from '@angular/common';
import * as moment from 'moment';
import { HereMapsService } from 'src/app/commons/service/here-maps.service';

@Component({
  selector: 'app-load-by-lane',
  templateUrl: './load-by-lane.component.html',
  styleUrls: ['./load-by-lane.component.scss'],
  providers: [PaymentService],
})
export class LoadByLaneComponent implements OnInit {
  public equipmentListing: any;
  public publicTab: any;
  public freightRate: any;
  public privatePublic: boolean = false;
  public emailPattern = '^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$';
  pristineCheck= false;
  public params: any;
  public laneEquipmentType:any;
  public laneEquipmentLength:any;
  public pickupDate: any;
  public pickupTimeArr: any;
  public pickupTZ: any;
  public shipmentForm: FormGroup;
  public shipmentFormFinal: FormGroup;
  public numOnly = '[0-9]*';
  public timeZoneSelectedPick = '';
  public pickupCity: any;
  public pickupState: any;
  public pickupFullLocation: any;
  public pickupLatitude: any;
  public pickupLongitude: any;
  public sameCityMust = false;
  public pickUp: any;
  public pickupCityError: any;
  public carrierType= 'connected';
  public currentCarrierTab = 'connected';
  public disabledAssign = false;
  public pickAutoNot: boolean;
  public pickupChangeDetected = false;
  public minDate: any = new Date();
  public maxDate: any;
  public updateFirstError = []
  public matchId: number;
  public oldCity:any;
  public oldState:any;
  public equipmentTypeV: any;
  public loading = false;
  public addshipment: any = {};
  public saving = false;
  public manageSection = 'shipment';
  public activeStatus = 'shipment';
  public totalPage = 1;
  public selectedPingRate: any;
  public showNextBlowSection = false;
  public disableZip = true;
  public communityDataSubmiited=false;
  public page = 1;
  public carrierList: any;
  public allCarrierSkeloton = false;
  public prpage = 1;
  public isShipmentWithOrderID = false;
  public masterSelected: boolean = false;
  public sameLocation: boolean = false;
  public usTimeZoneList = {};
  public buttonClicked = false;
  public allPreCheckCarrier = [];
  public isAllCarrier:boolean= false;
  public pickupTimeZone: any;
  public dropTimeZone: any;
  public isDraft: any;
  public carrierIds: any;
  finalSubmission =false;
  public visibility: any;
  isDesc: boolean = false;
  column: string = '';
  isDescp: boolean = false;
  columnp: string = '';
  public serverPathForUrl: any;
  public selectedIndex = 0;
  public searchRestriction: any;
  public shipmentDetailsForm: FormGroup;
  public wayPointForm: FormGroup;
  public isDeailsSave: boolean = false;
  public isPrefereedCarrier: boolean = false;
  public selectedCarrierText: any = 'All_Carriers';
  public carrierDetailsShow: boolean = true;
  public selectedCarrierShow: boolean = false;
  public wayPoints: any;
  public addFreightRateShow: boolean = false;
  public pickupInstructionError: boolean = false;
  public dropOffInstructionError: boolean = false;
  public moreRecordLoader: boolean = false;
  public selectedCurrency: any;
  public checkErrorMessage = false;
  public shortNameError: any;
  public orderDetail: any;
  public shortNameValue = false;
  public buttonLoaderSave: boolean = false;
  public buttonLoader: boolean = false;
  public shortNameDropValue = false;
  public equipmentLengthList: any;
  public changeOfferRateBidding = false;
  public laneDetail: any;
  public changeTruckLoadType = false;
  public offerRateStatus = 'N';
  public truckLoadTypeStatus: any;
  public checkEmptyValue: any;
  public paymentTerms = true;
  public trailerSpecsRequipmentSlug: any = [];
  public purchaseOrderId: any;
  public paymentTermsValue: any;
  public type: any;
  public orderId: any;
  public percentACH: any;
  public URLwithQueryParams: any;
  public shipmentShipperEmail: string = '';
  public shipmentWithOrderURL: any;
  public freightSubmit = false;
  public laneaxisPrice: any;
  public showTdays: any;
  public cardListing: any;
  public skeletonLoaderCard = true;
  public numberOfDays = 'N';
  public finalAmount: any;
  public dollorPerMiles = 40;
  public getRouteResponse: any;
  public pingRateStatus = 1;
  public shipmentPingSubmitted=false;
  public shipmentPingRate = '3';
  public pingRateStatusCheck: boolean = false;
  public trailerSpecsRequipment: any = [];
  public countryList: any;
  public defualtPickupCountryFlag: any;
  public newSelectPickupCountryCode: any;
  public pickupCountryName: any;
  public selectedCountry: any;
  public newPickupCountryName:any
  public ifPickupLoadInfo: boolean = false;
  public isNotDropOFF: boolean = true;
  public pickCardArrays: any = [];
  public dropCardArrays: any = [];
  public ifSaveNextValid: boolean = true;
  public notSaved: boolean = true;
  public multipleWayPointMakeData: any = {}
  public setWayPointValue: any;
  public setWayPointPickupOrder = 0;
  public setWayPointDroppupOrder = 0;
  public setWayPointSquance = 0;
  public hideButtonSection: boolean = true;
  public editSectionShow: boolean = false;
  public selectedEditIndex: any;
  public editSelecteData: any;
  public editCountryCodeData:any;
  public selectedTrailerSpces: any;
  public submitted: boolean = false;
  public checkCrossButton :boolean = false;
  public checkRouteDisabled: boolean = false;
  public isTemprature = false;
  public imagePath:any;
  public imgURL:any;
  public currentIndex:any;
  public showErrorImage:any;
  public totalCarriers:any;
  public checkIntereminate : boolean = false;
  zipCodeErrorMsg: string;
  public firstStorageOrder:any;
  public shipmentSubmitted =false;
  @ViewChild('stepper', { static: false }) private myStepper: MatStepper;

  constructor(
    private formBuilder: FormBuilder,
    private commonService: CommonService,
    private router: Router,
    private location: Location,
    private alertService: AlertService,
    public dialog: MatDialog,
    public paymentService: PaymentService,
    private sharedService: SharedService,
    private hereMapsService: HereMapsService,
    private route: ActivatedRoute,
  ) { }

  originalOrder = (
    a: KeyValue<number, string>,
    b: KeyValue<number, string>
  ): number => {
    return 0;
  };

  async ngOnInit() {
    this.selectedCurrency = 'USD';
    let configValue = await this.sharedService.getConfig();
    if (configValue !== undefined) {
      this.shipmentPingRate = configValue.onShipmentPingRate;
      this.getConfigSet(configValue);
    }
    this.commonService.configData.subscribe((userData) => {
      this.getConfigSet(userData);
    });

    this.zipCodeErrorMsg = AppSettings.zipCodeError;
    this.URLwithQueryParams = this.router.url.replace(/%20/g, '+');
    this.shipmentWithOrderURL = this.getParams(this.URLwithQueryParams);
    if (this.shipmentWithOrderURL) {
      this.type = this.shipmentWithOrderURL['type'];
      this.firstStorageOrder=this.shipmentWithOrderURL['type'];
      this.orderId = this.shipmentWithOrderURL['orderId'];
      if (this.type && this.orderId) {
        this.isShipmentWithOrderID = true;
        this.getOrderDetails();
      }
    }
    this.clearAllStepsShow();
    this.serverPathForUrl = environment.serverPathForUrl;
    this.searchRestriction = environment.searchMultipleRestriction;
    this.params = {
      limit: 10,
    };
    this.pickupTimeArr = StatusSetting.pickUpTime;
    this.pickupTZ = StatusSetting.newTimeZoneList;
    this.usTimeZoneList = StatusSetting.NewStateTimeZoneList;
    this.checkTimePeriod();
    this.wayPointForm = this.formBuilder.group({
      pickupBussinessName: ['', [Validators.maxLength(64),]],
      pickupContactName: ['', [Validators.maxLength(64),]],                                    
      pickupLocation: ['', [Validators.required, Validators.maxLength(256), Validators.pattern(/^[^\s].*/),]],
      pickupPincode: ['', [Validators.required, Validators.minLength(4), Validators.maxLength(8), Validators.pattern(/^[a-z0-9]+([-\s]{1}[a-z0-9]+)*$/i)]],
      pickupDate: ['', [Validators.required]],
      pickupTime: ['', [Validators.required]],
      pickupTimeZone: ['', [Validators.required]],
      wayPointType: ['pickup',],
      pickupCountryCode: ['US'],
      pickupCountryName: [''],
      pickPhoneNumber: ['', [Validators.minLength(10), Validators.maxLength(10), Validators.pattern(/^([0-9]+\s?)*$/),]],
      pickInstructions: ['', [Validators.maxLength(516)]],
      commodityForm: this.formBuilder.array([]),
      })

    this.shipmentDetailsForm = this.formBuilder.group({
      equipmentType: ['', [Validators.required]],
      equipmentTypeLength: ['', this.checkEmptyValue === 'NoLength' ? [] : [Validators.required],],
      purchaseOrderId: ['', [Validators.maxLength(64),]],
      trailerSpecs: [''],
      temperature: ['',[Validators.min(-126), Validators.max(136), Validators.pattern(/^-?(0|[1-9]\d*)?$/)]],
      pingRateValue: ['', [Validators.max(60),],],
      pingRateStatus: ['']
    });

    this.shipmentFormFinal = this.formBuilder.group({
      paymentMethod: [''],
      terms: [''],
      stDays: [''],
      freightRate: ['', [Validators.required,Validators.maxLength(10),Validators.min(1),
        Validators.pattern(/^\d+(\.\d+)?$/),],],
    });
    this.wayPointList() 
 
  }
  
  // To get commodity form as a FormArray
  get commodityForm() {
    return this.wayPointForm.get('commodityForm') as FormArray;
  }

// storage order detail api calling 
  getOrderDetails() {
    let uri = null;
    let newParams = {
      id: this.orderId,
    };
    if (newParams) uri = this.commonService.getAPIUriFromParams(newParams);
    let APIparams = {
      apiKey: AppSettings.APIsNameArray.STORAGEORDER.GET,
      uri: uri,
    };
    this.commonService.getList(APIparams).subscribe((ServerRes) => {
      if (ServerRes.success === true) {
        this.orderDetail = ServerRes.response.orders;
        this.storageMakeCard();
      }
    });
  }

// storage order through make card 
  storageMakeCard(){
    let locationType:any;
    if(this.type==='pickup'){
      locationType=this.orderDetail.dropoffAddress[0];
      this.multipleWayPointMakeData.type = 'dropoff';
    }else{
      locationType=this.orderDetail.pickupAddress[0];
      this.multipleWayPointMakeData.type = 'pickup';
    }
    this.pickupCountryName = 'US';
    if(this.countryList && this.countryList.length > 0){
      this.selectedCountry = this.countryList.filter((item) => item.country === 'US');
      this.defualtPickupCountryFlag = this.countryList[0].flag;
      this.newSelectPickupCountryCode = this.countryList[0].countryAbbr;
    }

    this.pickupFullLocation = locationType.locationLongName;
    this.pickupCity = locationType.city;
    this.pickupState = locationType.state;
    this.pickupLatitude = locationType.latitude;
    this.pickupLongitude = locationType.longitude;
    this.multipleWayPointMakeData.businessName =locationType.businessName;
    this.multipleWayPointMakeData.latitude = locationType.latitude;
    this.multipleWayPointMakeData.longitude = locationType.longitude;
    this.multipleWayPointMakeData.locationLongName = locationType.locationLongName;
    this.multipleWayPointMakeData.city = locationType.city ? locationType.city : locationType.city;
    this.multipleWayPointMakeData.state = locationType.state;
    this.multipleWayPointMakeData.countryName = 'US';
    this.multipleWayPointMakeData.timeSlot = locationType.timeSlot;
    this.multipleWayPointMakeData.timeZone = locationType.timeZone;
    this.multipleWayPointMakeData.zip = locationType.zip.toString();
    this.multipleWayPointMakeData.date = moment(locationType.date).format('MM/DD/YYYY');
    if(locationType.contact){
      this.multipleWayPointMakeData.countryCode = 1;
      this.multipleWayPointMakeData.phone = locationType.contact ;
      this.multipleWayPointMakeData.contactName ='';
    }
    this.multipleWayPointMakeData.commodity =[];
    this.multipleWayPointMakeData.specialRequirement = locationType.specialRequirement;
    if(this.type==='pickup'){
      this.setWayPointDroppupOrder = this.setWayPointDroppupOrder + 1;
      this.multipleWayPointMakeData.locationOrder = this.setWayPointDroppupOrder;
      this.setWayPointSquance = 2;
      this.multipleWayPointMakeData.sequence = this.setWayPointSquance;
    }else{
      this.firstStorageOrder=null;
      this.setWayPointPickupOrder = this.setWayPointPickupOrder + 1;
      this.multipleWayPointMakeData.locationOrder = this.setWayPointPickupOrder;
      this.setWayPointSquance = this.setWayPointSquance + 1;
      this.multipleWayPointMakeData.sequence = this.setWayPointSquance;
    }
     this.pickCardArrays.push(this.multipleWayPointMakeData);
     this.multipleWayPointMakeData = {};
     this.selectedEditIndex=null;
  }

  // To get required params for URL 
  getParams(url) {
    var params = {};
    var parser = document.createElement('a');
    parser.href = url;
    var query = parser.search.substring(1);
    var vars = query.split('&');
    for (var i = 0; i < vars.length; i++) {
      var pair = vars[i].split('=');
      params[pair[0]] = decodeURIComponent(pair[1]);
    }
    return params;
  }

  // Function to get data from config API 
  getConfigSet(configValue: any) {
    this.trailerSpecsRequipment = configValue.shipmentTruckSpecs;
    this.equipmentListing = configValue.equipmentTypes;
    this.countryList = configValue.countries;
    this.defualtPickupCountryFlag = this.countryList[0].flag;
  }
  
// Route calculate through Here maps 
  async checkMilesRouteBetweenTwoPoint(newlatitude,newlongitude,getCurrentLatitude,getCurrentLongitude) {
    return await this.hereMapsService.getRouteFound(newlatitude,newlongitude,getCurrentLatitude,getCurrentLongitude, 'truck').then(
      (hereMapsRoutingResponse) => {
        let miles:any; 
           if(hereMapsRoutingResponse.response.route[0].summary.distance==0){
            miles = 1 ;
           }else{
            miles = Math.ceil(hereMapsRoutingResponse.response.route[0].summary.distance / 1609.344)
           }
        return { 'miles': miles };
      },
      async (error) => {
        return await this.hereMapsService.getRouteFound(newlatitude,newlongitude,getCurrentLatitude,getCurrentLongitude, 'car').then(
          (hereMapsRoutingResponse) => {
            let miles:any; 
            if(hereMapsRoutingResponse.response.route[0].summary.distance==0){
             miles = 1 ;
            }else{
             miles = Math.ceil(hereMapsRoutingResponse.response.route[0].summary.distance / 1609.344)
            }
         return { 'carRoutemiles': miles, forceCarRoute: true };
          },
          (error) => {
            return { forceCarRoute: false };
          })
      });
  }

 // Check route popup show.
  checkRouteFound(getRoute:any){
    this.buttonLoaderSave=false;
    this.buttonLoader=false;
    if (this.getRouteResponse.forceCarRoute === true) {
      this.loading = false;
      this.carRouteOption('true');
      this.checkRouteDisabled=true;
    } else if (this.getRouteResponse.forceCarRoute === false) {
      this.carRouteOption('false');
      this.checkRouteDisabled=true;
      this.loading = false;
    } else {
      this.checkRouteDisabled=true;
      this.loading = false;
      this.alertService.showNotificationMessage('danger','bottom','right','txt_d','cancel','Route Fail','Some region route calcuation is failed');
    }
  }

  // To select pickup location by google autocomplete.
  async pickUpLocAuto($event: GermanAddress) {
    if( $event.locality.short !=this.oldCity && $event.vicinity !=this.oldCity && this.oldState != $event.state.long)
    { 
     this.sameCityMust=true;
     return 0
   }
   this.sameCityMust=false;
     for (let x in this.usTimeZoneList) {
      if ($event.state.short === x) {
        this.timeZoneSelectedPick = this.usTimeZoneList[x];
      }
    }
    this.wayPointForm.get('pickupLocation').setValue($event.displayAddress);
  
    if ($event.country.short === 'US') {
      this.newPickupCountryName = $event.country.short;
      this.selectedCountry = this.countryList.filter(
        (item) => item.country === $event.country.short);
    } else {
      this.selectedCountry = this.countryList.filter(
        (item) => item.country === $event.country.long);
      this.newPickupCountryName = $event.country.long;
    }
    this.defualtPickupCountryFlag = this.selectedCountry[0].flag;
    this.wayPointForm.get('pickupCountryCode').setValue(this.selectedCountry[0].countryAbbr);
    this.wayPointForm.get('pickupCountryName').setValue(this.selectedCountry[0].country);
    this.newSelectPickupCountryCode = this.selectedCountry[0].countryAbbr;
    this.pickupFullLocation = $event.displayAddress;
    this.pickupCity = $event.locality.short ? $event.locality.short : $event.vicinity;
    this.pickupState = $event.state.long;
    this.pickupLatitude = $event.geoLocation.latitude;
    this.pickupLongitude = $event.geoLocation.longitude;
    this.sameLocation = false;
    this.pickAutoNot = false;
    this.pickupChangeDetected = false;
    this.pickUp = $event.displayAddress;
    if ($event.postalCode) {
      this.wayPointForm.get('pickupPincode').setValue($event.postalCode);
    } else {
      this.wayPointForm.get('pickupPincode').setValue('');
    }
  }

  // To set values and validations after autocomplete for pickup/dropOff location 
  pickAutoCompleteSelected(result: PlaceResult) {
    for (let i = 0; i < result.address_components.length; i++) {
      if (result.address_components[i].types[0] == "locality" || result.address_components[i].types[1] == "sublocality") {
        if (result.address_components[i].long_name) {
          this.shortNameError = '';
          this.shortNameValue = false;
        } else {
          this.shortNameError = 'Please select city level address.';
          this.shortNameValue = true;
        }
      }else if(result.address_components[i].types[0] == "postal_code"){
        if (result.address_components[i].long_name) {
            this.wayPointForm.get('pickupPincode').setValue(result.address_components[i].long_name);
        }else{
            this.wayPointForm.get('pickupPincode').setValue('');
        }
      }
    }
    this.pickupCityError = '';
    if (this.disabledAssign || this.sameLocation || this.shortNameValue) {
      this.checkErrorMessage = true;
    } else {
      this.checkErrorMessage = false;
    }
    if (this.shortNameValue === true || this.shortNameDropValue === true) {
      this.checkErrorMessage = true;
    }
  }

  // To select country code
  selectPickCountryCode(event: any) {
    const getRecord = this.countryList.filter((item) => item.countryAbbr == event.value);
    this.defualtPickupCountryFlag = getRecord[0].flag;
    this.newSelectPickupCountryCode = getRecord[0].countryAbbr;
    this.pickupCountryName = getRecord[0].country;
  }

// Add more commodity
  async addContactField(field: any) {
    if (field == 'pick' || field == 'pickup') {
      this.setWayPointValue = 'pickup';
      this.ifSaveNextValid = true;
    } else if (field == 'drop' || field == 'dropoff') {
      this.setWayPointValue = 'dropoff';
      this.ifSaveNextValid = false;
    }
    this.buttonLoader=true;
    this.submitted=false;
   let checkRoutMatch = await this.checkRouteValidation();
   if(checkRoutMatch==true){
     this.addNewLocationData(null);
   }
  }

// checkout route validation through here maps.
  async checkRouteValidation(){
    if(this.pickCardArrays.length > 0){
      let getCurrentLatitude=this.pickupLatitude;
      let getCurrentLongitude=this.pickupLongitude;
      let newlatitude ;
      let newlongitude ;    
      if(this.selectedEditIndex=='1'){
        newlatitude = this.pickCardArrays[0].latitude;
        newlongitude = this.pickCardArrays[0].longitude;
      }else if(this.selectedEditIndex > 0){
        newlatitude = this.pickCardArrays[this.selectedEditIndex - 1].latitude;
        newlongitude = this.pickCardArrays[this.selectedEditIndex - 1].longitude;
      }else {
        newlatitude = this.pickCardArrays[this.pickCardArrays.length - 1].latitude;
        newlongitude = this.pickCardArrays[this.pickCardArrays.length - 1].longitude;
      }
      this.getRouteResponse = await this.checkMilesRouteBetweenTwoPoint(newlatitude,newlongitude,getCurrentLatitude,getCurrentLongitude);
      if ((this.getRouteResponse && this.getRouteResponse.miles) || (this.addshipment.forceCarRoute==true)) {
        this.checkRouteDisabled=false;
        return true;
      }else{
        this.checkRouteFound(this.getRouteResponse);
        return false;
      }
   }else{
   return true;
   }
  }

  // Change location type Pickup/DropOff
  changeLocationType(event: any) {
    if (event.value == 'dropoff') {
      this.ifSaveNextValid = false;
    } else {
      this.ifSaveNextValid = true;
    }
  }

  // Add more new locations
  addNewLocationData(field) {
    this.makeWayPointData();
    this.buttonLoader=false;
  }

  // To make waypoint data values
  makeWayPointData() {
    if (this.wayPointForm.get('pickupBussinessName').value) {
      this.multipleWayPointMakeData.businessName = this.wayPointForm.get('pickupBussinessName').value;
    }
    this.multipleWayPointMakeData.latitude = this.pickupLatitude;
    this.multipleWayPointMakeData.longitude = this.pickupLongitude;
    this.multipleWayPointMakeData.locationLongName = this.wayPointForm.getRawValue().pickupLocation ;
    this.multipleWayPointMakeData.city = this.pickupCity ? this.pickupCity : this.pickupState;
    this.multipleWayPointMakeData.state = this.pickupState;
    this.multipleWayPointMakeData.countryName = this.pickupCountryName;
    this.multipleWayPointMakeData.type = this.wayPointForm.get('wayPointType').value;
    this.multipleWayPointMakeData.timeSlot = this.wayPointForm.get('pickupTime').value;
    this.multipleWayPointMakeData.timeZone = this.wayPointForm.get('pickupTimeZone').value;
    this.multipleWayPointMakeData.zip = this.wayPointForm.get('pickupPincode').value.toString();
    this.multipleWayPointMakeData.commodity = this.wayPointForm.value.commodityForm;
    this.multipleWayPointMakeData.date = moment(this.wayPointForm.get('pickupDate').value).format('MM/DD/YYYY');
    if ((this.wayPointForm.get('pickupCountryCode').value) && (this.wayPointForm.get('pickPhoneNumber').value)) {
      this.multipleWayPointMakeData.countryCode = this.newSelectPickupCountryCode;
    }
    if (this.wayPointForm.get('pickPhoneNumber').value) {
      this.multipleWayPointMakeData.phone = this.wayPointForm.get('pickPhoneNumber').value;
    }
    if (this.wayPointForm.get('pickupContactName').value) {
      this.multipleWayPointMakeData.contactName = this.wayPointForm.get('pickupContactName').value;
    }
    if (this.wayPointForm.get('pickInstructions').value) {
      this.multipleWayPointMakeData.specialRequirement = this.wayPointForm.get('pickInstructions').value;
    }
    if (this.wayPointForm.get('wayPointType').value === 'pickup') {
      this.setWayPointPickupOrder = this.setWayPointPickupOrder + 1;
      this.multipleWayPointMakeData.locationOrder = this.setWayPointPickupOrder;
    } else if (this.wayPointForm.get('wayPointType').value === 'dropoff') {
      this.setWayPointDroppupOrder = this.setWayPointDroppupOrder + 1;
      this.multipleWayPointMakeData.locationOrder = this.setWayPointDroppupOrder;
    }


    if(this.firstStorageOrder==='pickup'){
      this.pickCardArrays.unshift(this.multipleWayPointMakeData);
      this.firstStorageOrder=null;
      this.multipleWayPointMakeData.sequence = 1 ;
    }else{
      this.pickCardArrays.push(this.multipleWayPointMakeData);
      this.setWayPointSquance = this.setWayPointSquance + 1;
      this.multipleWayPointMakeData.sequence = this.setWayPointSquance;
    }
    if (this.wayPointForm.get('wayPointType').value === 'dropoff') {
      this.isNotDropOFF = false;
    } else {
      if(this.firstStorageOrder==='pickup'){
        this.isNotDropOFF = false;
      }else{
        this.isNotDropOFF = true;
      }
    }
    this.wayPointForm.reset();
 
    this.multipleWayPointMakeData = {};
    this.selectedEditIndex=null;
    this.wayPointForm.get('wayPointType').setValue(this.setWayPointValue)
    this.wayPointForm.get('pickupCountryCode').setValue('US');
    this.selecteCountryFlag('US')
    this.ifPickupLoadInfo = false;
    this.checkCloseButton();
    this.manageDatePreviousDate();
    this.removeCommunityData();
   
  }

  // To select country flag according to country code
  async selecteCountryFlag(event: any) {
    let countryCode:any;
    if(event=='US'){
      countryCode='US';
    }
   else if(event=='Canada' || event=='CA'){
      countryCode='CA';

    }
    else if(event=='Mexico' || event=='MX'){
      countryCode='MX';
    }
    const getRecord =  await this.countryList.filter((item) => event.length==2 ? item.countryAbbr == countryCode : item.country == countryCode);
    if(getRecord.length)
    {
       this.defualtPickupCountryFlag =   getRecord[0].flag;
    this.newSelectPickupCountryCode =  getRecord[0].countryAbbr;
    this.pickupCountryName =   getRecord[0].country;
    this.wayPointForm.get('pickupCountryCode').setValue( getRecord[0].countryAbbr);
  }
  }

  // To edit waypoint card
  async editWayPointCard(wayPoint: any, index: any){
    (this.pickCardArrays)
    if(index>0 && !this.pickCardArrays[index-1].date)
    {
      this.updateFirstError[index]=true
      return 0
    }
    else 
    {
      this.updateFirstError[index]=false
    }
    this.editSelecteData = wayPoint;
    if (wayPoint) {
      if(wayPoint.countryCode && wayPoint.phone){
        this.selecteCountryFlag(wayPoint.countryCode);
      }
      else{
        this.selecteCountryFlag(wayPoint.countryName);
      }
      
      if(wayPoint.countryName =='US'){
        this.editCountryCodeData='US';
      }
      else if(wayPoint.countryName =='Canada'){
        this.editCountryCodeData='CA';

      }
      else if(wayPoint.countryName =='Mexico'){
        this.editCountryCodeData='MX';

      }
    }
    if(index)
    {
      this.multipleWayPointMakeData.commodity = null
    }
  
    this.selectedEditIndex = index;
    this.pickupCountryName = wayPoint.countryName;
    this.multipleWayPointMakeData.sequence = this.editSelecteData.sequence;
    this.pickupCity = wayPoint.city;
    this.oldCity = wayPoint.city;
    this.oldState = wayPoint.state;
    this.pickupState = wayPoint.state;
    this.pickupLatitude = wayPoint.latitude;
    this.pickupLongitude = wayPoint.longitude;
    this.timeZoneSelectedPick = wayPoint.timeZone;
    this.wayPointForm.get('wayPointType').setValue(wayPoint.type);
    this.wayPointForm.get('pickupBussinessName').setValue(wayPoint.businessName);
    this.wayPointForm.get('pickupLocation').setValue(wayPoint.locationLongName);
    this.wayPointForm.get('pickupPincode').setValue(wayPoint.zip);
    this.wayPointForm.get('pickupDate').setValue(wayPoint.date ? new Date(wayPoint.date): '');
    this.wayPointForm.get('pickupTime').setValue(wayPoint.timeSlot ? wayPoint.timeSlot : '');
    this.wayPointForm.get('pickupTimeZone').setValue(wayPoint.timeZone ? wayPoint.timeZone :'');
    if(wayPoint?.countryCode && wayPoint?.phone){
      this.wayPointForm.get('pickupCountryCode').setValue(wayPoint.countryCode);
    }
    else{
      this.wayPointForm.get('pickupCountryCode').setValue(this.editCountryCodeData);
    }
    this.wayPointForm.get('pickupCountryName').setValue(wayPoint.countryName);
    this.wayPointForm.get('pickPhoneNumber').setValue(wayPoint.phone);
    this.wayPointForm.get('pickupContactName').setValue(wayPoint.contactName);
    this.wayPointForm.get('pickInstructions').setValue(wayPoint.specialRequirement);
    this.sameCityMust = false;
  
    if (wayPoint.zip)
    {
      this.disableZip =true;
    }
    else 
    {
      this.disableZip =false;
    }
    if (wayPoint.commodity && wayPoint.commodity.length > 0) {
      if(!index)
      this.setValueCommodity(wayPoint.commodity);
      this.ifPickupLoadInfo = true;
    } else {
      this.ifPickupLoadInfo = false;
    }
    this.notSaved = true;
    this.editSectionShow = true;
    this.hideButtonSection = true;
    this.checkCloseButton();
    if(this.pickCardArrays.length > 1 ){
      this.manageDatePreviousDate();
    }
  }

  // To modify the full address 
  modifyAddress(full, zip)
  {
    let country =  full.split(',')[ full.split(',').length-1]; 
    let full_address = (full.split(',')[0]+ ', '+full.split(',')[1]+ full.split(',')[2].split(' ')[0]+ ', '+ zip + ', '+country)
    return full_address;
  }

  // Function for update waypoint details
  updateWayPoint() {
    if(this.editSelecteData.type === 'pickup' && this.wayPointForm.get('wayPointType').value === 'pickup') {
      if (this.editSelecteData.locationOrder) {
        this.multipleWayPointMakeData.locationOrder = this.editSelecteData.locationOrder;
      } else {
        this.setWayPointPickupOrder = this.setWayPointPickupOrder + 1;
        this.multipleWayPointMakeData.locationOrder = this.setWayPointPickupOrder;
      }
    } else if (this.editSelecteData.type === 'pickup' && this.wayPointForm.get('wayPointType').value === 'dropoff') {
      this.setWayPointDroppupOrder = this.setWayPointDroppupOrder + 1;
      this.multipleWayPointMakeData.locationOrder = this.setWayPointDroppupOrder;
    } else if (this.editSelecteData.type === 'dropoff' && this.wayPointForm.get('wayPointType').value === 'dropoff') {
      if (this.editSelecteData.locationOrder) {
        this.multipleWayPointMakeData.locationOrder = this.editSelecteData.locationOrder;
      } else {
        this.setWayPointDroppupOrder = this.setWayPointDroppupOrder + 1;
        this.multipleWayPointMakeData.locationOrder = this.setWayPointDroppupOrder;
      }
    } else if (this.editSelecteData.type === 'dropoff' && this.wayPointForm.get('wayPointType').value === 'pickup') {
      this.setWayPointPickupOrder = this.setWayPointPickupOrder + 1;
      this.multipleWayPointMakeData.locationOrder = this.setWayPointPickupOrder;
    }
    this.multipleWayPointMakeData.sequence = this.editSelecteData.sequence;
    if (this.wayPointForm.get('pickupBussinessName').value) {
      this.multipleWayPointMakeData.businessName = this.wayPointForm.get('pickupBussinessName').value;
    }
    this.multipleWayPointMakeData.latitude = this.pickupLatitude;
    this.multipleWayPointMakeData.longitude = this.pickupLongitude;
    this.multipleWayPointMakeData.locationLongName = this.modifyAddress(this.wayPointForm.getRawValue().pickupLocation,this.wayPointForm.get('pickupPincode').value.toString());
    this.multipleWayPointMakeData.city = this.pickupCity ? this.pickupCity : this.pickupState;
    this.multipleWayPointMakeData.state = this.pickupState;
    this.multipleWayPointMakeData.countryName = this.newPickupCountryName;
    this.multipleWayPointMakeData.type = this.wayPointForm.get('wayPointType').value;
    this.multipleWayPointMakeData.timeSlot = this.wayPointForm.get('pickupTime').value;
    this.multipleWayPointMakeData.timeZone = this.wayPointForm.get('pickupTimeZone').value;
    this.multipleWayPointMakeData.zip = this.wayPointForm.get('pickupPincode').value.toString();
    this.multipleWayPointMakeData.date = moment(this.wayPointForm.get('pickupDate').value).format('MM/DD/YYYY');
    this.multipleWayPointMakeData.commodity = this.wayPointForm.value.commodityForm;
    if ((this.wayPointForm.get('pickupCountryCode').value) && (this.wayPointForm.get('pickPhoneNumber').value)) {
      this.multipleWayPointMakeData.countryCode = this.newSelectPickupCountryCode;
    }
    if (this.wayPointForm.get('pickPhoneNumber').value) {
      this.multipleWayPointMakeData.phone = this.wayPointForm.get('pickPhoneNumber').value;
    }
    if (this.wayPointForm.get('pickupContactName').value) {
      this.multipleWayPointMakeData.contactName = this.wayPointForm.get('pickupContactName').value;
    }
    if (this.wayPointForm.get('pickInstructions').value) {
      this.multipleWayPointMakeData.specialRequirement = this.wayPointForm.get('pickInstructions').value;
    }
    this.pickCardArrays[this.selectedEditIndex] = this.multipleWayPointMakeData;
    this.wayPointForm.reset();
    const formArray = new FormArray([]);
    this.wayPointForm.setControl('commodityForm', formArray);
    this.multipleWayPointMakeData = {};
    this.wayPointForm.get('wayPointType').setValue(this.setWayPointValue)
    this.wayPointForm.get('pickupCountryCode').setValue('US');
    this.selecteCountryFlag('US');
    this.checkUpdateLocationUpdate();
    this.selectedEditIndex=null;
  }

  // Submit wayPoint form data for creating new shipment by lane
 async wayPointFormSubmit({ value, valid }) {
    let point: any; 
    this.submitted=true;
    this.communityDataSubmiited=true;
    if (valid) {
      this.buttonLoaderSave=true;
      this.submitted=false;
      if (this.editSectionShow) {
        if (this.selectedEditIndex === 0 && this.pickCardArrays[0].type === 'pickup' && value.wayPointType === 'dropoff') {
          point = 1
          this.waypointInformationPopup(point);
          this.buttonLoaderSave=false;
        } else if (this.selectedEditIndex === this.pickCardArrays.length-1 && this.pickCardArrays[this.pickCardArrays.length - 1].type === 'dropoff' && value.wayPointType === 'pickup') {
          point = 6
          this.waypointInformationPopup(point);
          this.buttonLoaderSave=false;
        } else {
          if(this.selectedEditIndex === 0 && this.pickCardArrays.length===1){
            this.updateWayPoint();
            this.notSaved = false;
            this.hideButtonSection = false;
            this.buttonLoaderSave=false;
          }else{
          
              if(this.selectedEditIndex === this.pickCardArrays.length-1 && value.wayPointType === 'dropoff'){
                this.checkCloseButton();
                this.updateWayPoint();
                this.showBelowSection();
                }else{
                this.updateWayPoint();
                this.notSaved = false;
                this.hideButtonSection = false;
              }
              this.buttonLoaderSave=false;
          }          
        }
       
      } else {
        
        if(this.firstStorageOrder){
          let checkRoutMatch = await this.checkRouteValidation();
          if(checkRoutMatch==true){
          this.addNewLocationData(null);
          this.showNextBlowSection = true;
          this.notSaved = false;
          this.hideButtonSection = false;
          }
          this.buttonLoaderSave=false;
        }else{   
        
        if (this.pickCardArrays.length > 0 && this.pickCardArrays[0].type === 'pickup' && value.wayPointType === 'dropoff' && (this.pickCardArrays[this.pickCardArrays.length - 1].type === 'dropoff' || value.wayPointType === 'dropoff')) {
          let checkRoutMatch = await this.checkRouteValidation();
          if(checkRoutMatch==true){
            this.addNewLocationData(null);
            this.showNextBlowSection = true;
            this.notSaved = false;
            this.hideButtonSection = false;
          }
          this.buttonLoaderSave=false;
        } else {
          this.buttonLoaderSave=false;
          if (this.pickCardArrays.length == '0' && value.wayPointType === 'pickup') {
            point = 2;
            this.waypointInformationPopup(point);
          } else if (this.pickCardArrays.length > 0 && this.pickCardArrays[0].type === 'pickup' && this.pickCardArrays[this.pickCardArrays.length - 1].type === 'dropoff' && value.wayPointType === 'pickup') {
            let point = 3;
            this.waypointInformationPopup(point);
          }else if (this.pickCardArrays.length > 0 && this.pickCardArrays.filter((el) => el.type === 'dropoff' ) &&  this.pickCardArrays[0].type === 'pickup' && value.wayPointType === 'pickup') {
            let point = 3;
            this.waypointInformationPopup(point);
          }
           else if (this.pickCardArrays.length > 0 && this.pickCardArrays[0].type === 'pickup' && value.wayPointType === 'pickup') {
            let point = 4;
            this.waypointInformationPopup(point);
          } else {
            let point = 5;
            this.waypointInformationPopup(point);
          }
        }
      }
      }
    }
  }

  // To check cross button
  checkCloseButton(){
    if(this.pickCardArrays[0].type==='pickup' &&  this.pickCardArrays[this.pickCardArrays.length - 1].type==='dropoff' && !this.editSectionShow){
      this.checkCrossButton=true;
    }else{
      this.checkCrossButton=false;
    }
  }

 // Waypoint Information Popup for saving waypoint data
  waypointInformationPopup(point) {
    const dialogRef = this.dialog.open(PopupComponent, {
      disableClose: true,
      backdropClass: AppSettings.backdropClass,
      width: AppSettings.popWidth,
      data: {
        openPop: 'waypointInform',
        point: point,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
    });
  }

  // To close waypoint popup
  waypointPopupClose() {
    const dialogRef = this.dialog.open(PopupComponent, {
      disableClose: true,
      backdropClass: AppSettings.backdropClass,
      width: AppSettings.popWidth,
      data: {
        openPop: 'waypointContinuesStope',
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
       if(result.event==='Yes'){
        this.showBelowSection();
       }else{

       }
    });
  }

  // To show/hide waypoint detail section
  showBelowSection(){
    this.notSaved=false;
    this.hideButtonSection=false;
    this.showNextBlowSection=true;
    this.isNotDropOFF = false;
  }

  // To reset waypoint form data
  wayPointFormResetValue(){
    this.wayPointForm.reset();
  }

  // To update waypoint location type
  checkUpdateLocationUpdate(){
    let pickupValue=0;
    let dropValue=0;
    for (let i = 0; i < this.pickCardArrays.length; i++){
      if(this.pickCardArrays[i].type==='pickup'){
        pickupValue= pickupValue + 1;
        this.pickCardArrays[i].locationOrder = pickupValue;
      }     
      if(this.pickCardArrays[i].type==='dropoff'){
      dropValue= dropValue + 1;
      this.pickCardArrays[i].locationOrder= dropValue;  
      }     
    }
  }

  // Submit shipment Details form data for creating new shipment by lane
  async shipmentDetailsFormSubmit({ value, valid }) {
    this.shipmentPingSubmitted=true;
    if (valid) {
    this.shipmentSubmitted=true;
      if (this.pickCardArrays[0].type === 'pickup' && this.pickCardArrays[this.pickCardArrays.length - 1].type === 'dropoff') {
        this.publicTab = true;
        this.checkSubmitDateValidation();
      }else{
        this.waypointInformationPopup('3');      
      }
    }
  }


  
  // To check date validtions on form submit
  checkSubmitDateValidation() {
    let firstIndex = 0;
    let secondIndex = 0;
    for (let i = firstIndex; i < this.pickCardArrays.length - 1; i++) {
      let secondIndex = i + parseInt("1");
      for (let j = secondIndex; j <= secondIndex; j++) {
        var startTime = this.pickCardArrays[i].timeSlot.split(/[ -]+/);
        let start = startTime[0];
        var endTime = this.pickCardArrays[j]?.timeSlot?.split(/[ -]+/);
        let end;
        if(endTime[1]=='00:00'){
           end = '24:00';
        }else{
         end = endTime[1];
        }
        let firstDate = moment(this.pickCardArrays[i].date).format('YYYY-MM-DD');
        let secondDate = moment(this.pickCardArrays[j].date).format('YYYY-MM-DD');
        if (firstDate > secondDate) {
          this.pickCardArrays[i]['same'] = i;
          this.pickCardArrays[i]['disabledPoint'] = true;
        } else if ((firstDate === secondDate) && (start > end || start === end || this.pickCardArrays[i].timeSlot === this.pickCardArrays[j].timeSlot)) {
          this.pickCardArrays[j]['same'] = j;
          this.pickCardArrays[j]['disabledPoint'] = true;
        } else {
          this.pickCardArrays[j]['disabledPoint'] = false;
        }
      }
    }
    let validationValid = this.pickCardArrays.filter((el) => { return el.disabledPoint === true })
    if (validationValid.length > 0) {
    } else {
      this.checkRouteSubmitForm();
    }
  }

  // To check miles through Here maps  
  async checkMilesRoute() {
    return await this.hereMapsService.calculateMultiRouteFind(this.pickCardArrays, 'truck').then(
      (hereMapsRoutingResponse) => {
        let miles =Math.ceil(hereMapsRoutingResponse.response.route[0].summary.distance / 1609.344);
        return { 'miles': miles };
        
      },
      async (error) => {
        return await this.hereMapsService.calculateMultiRouteFind(this.pickCardArrays, 'car').then(
          (hereMapsRoutingResponse) => {
            let miles = Math.ceil(hereMapsRoutingResponse.response.route[0].summary.distance / 1609.344);
            return { 'carRoutemiles': miles, forceCarRoute: true };
          },
          (error) => {
            return { forceCarRoute: false };
          })
      });
  }

  // Form submit after route checking
  async checkRouteSubmitForm() {
    this.loading = true;
    this.getRouteResponse = await this.checkMilesRoute();
    this.shipmentSubmitted=false;
    this.shipmentPingSubmitted=false;
      if (this.getRouteResponse && this.getRouteResponse.miles) {
        this.finalAmount = this.getRouteResponse.miles * this.dollorPerMiles;
      }else if(this.getRouteResponse && this.getRouteResponse.carRoutemiles){
        this.finalAmount = this.getRouteResponse.carRoutemiles * this.dollorPerMiles;
      }
      this.shipmentFormFinal
        .get('freightRate')
        .setValidators([Validators.required,
        Validators.maxLength(10),
        Validators.min(1),
        Validators.pattern(/^\d+(\.\d+)?$/)]);

      if (this.isDeailsSave) {
        this.addShipmentDetailsBody();
        this.callSaveShipmentDataApi(null);
      } else {
        this.location.replaceState('/shipment/create/shipper/step-second');
        this.myStepper.next();
        this.activeStatus = 'freightRate';
        this.clearAllStepsShow();
        this.showSelectedStepDetails('carrierDetailsShow');
        this.changeBiddingFixedRate({checked:(this.laneDetail.rateType=='2'?true:false)})
        this.shipmentFormFinal.get('freightRate').setValue(this.laneDetail.estimatedPrice)
      }
    this.loading = false;
  }

 // To Get required payload for shipment create
  addShipmentDetailsBody() {
    for (let value of this.pickCardArrays) {
      delete value.disabledPoint;
    }
    this.addshipment.waypoints = this.pickCardArrays.concat(this.dropCardArrays);
    this.addshipment.equipmentType = parseInt(this.shipmentDetailsForm.get('equipmentType').value);
    
    if (this.shipmentDetailsForm.get('equipmentTypeLength').value !== 'NoLength' && this.shipmentDetailsForm.get('equipmentTypeLength').value !== '0') {
      this.addshipment.equipmentLength = parseFloat(this.shipmentDetailsForm.get('equipmentTypeLength').value);
    } else {
      this.addshipment.equipmentLength = '0'
    };

    this.addshipment.purchaseOrderId = this.shipmentDetailsForm.get('purchaseOrderId').value;
    this.addshipment.trailerSpecs = this.shipmentDetailsForm.get('trailerSpecs').value;
    this.addshipment.temperature = this.shipmentDetailsForm.get('temperature').value ? this.shipmentDetailsForm.get('temperature').value : '';
    this.addshipment.isOfferRate = this.offerRateStatus;

    if (this.changeTruckLoadType == false) {
      this.truckLoadTypeStatus = 1;
      this.addshipment.truckLoadType = 1;
    }else{
      this.addshipment.truckLoadType = this.truckLoadTypeStatus;
    }

    if (this.pingRateStatus === 2) {
      this.selectedPingRate = parseInt(this.shipmentDetailsForm.get('pingRateValue').value) * 60;
      this.addshipment.pingRateValue = this.selectedPingRate;
      this.addshipment.pingRateStatus = this.pingRateStatus;
    } else {
      this.selectedPingRate = parseInt(this.shipmentPingRate) * 60;
      this.addshipment.pingRateValue = this.selectedPingRate;
      this.addshipment.pingRateStatus = this.pingRateStatus;
    }

    this.addshipment.isDraft = this.isDraft;
    this.addshipment.truckLoadType = this.truckLoadTypeStatus;
    if (this.changeTruckLoadType === false) {
      this.truckLoadTypeStatus = 1;
      this.addshipment.truckLoadType = 1;
    }
    this.addshipment.stPercent = this.percentACH;
    this.addshipment.isOfferRate = this.offerRateStatus;
    // this.addshipment.visibility = 'private';
    if(this.privatePublic){
      this.addshipment.visibility='private';     
    }else{
      this.addshipment.visibility='public';     
    } 
  }
  
  // To navigate previous step for shipment create by lane
  goPrevMatStep(stepper: MatStepper, stepShow: any) {
    if (stepShow === 'addFreightRateShow') {
      this.activeStatus = 'freightRate';
      this.freightRate = false;
      this.publicTab = true;
      if (this.isShipmentWithOrderID) {
        this.location.replaceState(
          '/shipment/create/shipper/step-second?type=' +
          this.type +
          '&orderId=' +
          this.orderId
        );
      } else {
        this.location.replaceState('/shipment/create/shipper/step-second');
      }
    }
    if (stepShow === 'carrierDetailsShow') {
      this.publicTab = false;
      this.freightRate = true;
      this.activeStatus = 'shipment';
      this.location.replaceState('/shipment/create/shipper');
    }
    if (stepShow === 'clearAll') {
      this.activeStatus = 'shipment';
    }
    if (stepShow !== 'clearAll') {
      this.showSelectedStepDetails(stepShow);
    }
    stepper.previous();
  }


  //Next button of create shipment
  isDetailsSave(isDeailsSave: any) {
    this.getPriceLaneaxis();
    this.wayPointForm
      .get('pickupDate')
      .setValidators([Validators.required]);
    this.wayPointForm.get('pickupDate').updateValueAndValidity();
    this.wayPointForm
      .get('pickupTime')
      .setValidators([Validators.required]);
    this.wayPointForm.get('pickupTime').updateValueAndValidity();
    this.isDeailsSave = isDeailsSave;
    this.pickupFullLocation = this.pickUp;
  }

  // API call for shipment creation 
  callSaveShipmentDataApi(event) {
    this.loading = true;
    let APIparams = {
      apiKey: AppSettings.APIsNameArray.SHIPMENTFORSHIPPER.CREATSHIPMENT,
      uri: '',
      postBody: this.addshipment,
    };
    this.commonService
      .post(APIparams)
      .pipe(
        finalize(() => {
          this.saving = false;
        })
      )
      .subscribe(
        (success) => {
          if (success.success === true) {
            if (success.response.overlapShipments) {
              this.alertService.showNotificationMessage(
                'danger',
                'bottom',
                'right',
                'txt_d',
                'cancel',
                'Shipment Create',
                success.message
              );
            } else {
              this.confirmSuccess(
                success.response.uniqueId,
                success.response.shipmentId
              );
            }
            this.loading = false;
          } else if (success.success === false) {
            this.buttonClicked=false;
            if (success.error) {
              this.alertService.showNotificationMessage(
                'danger',
                'bottom',
                'right',
                'txt_d',
                'cancel',
                'Create Shipment Fail',
                AppSettings.CREATE_SHIPMENT_ERROR
              );
              const validationErrors = success.error;
              Object.keys(validationErrors).forEach((prop) => {
                const formControl = this.shipmentDetailsForm.get(prop);
                const shipmentFormControlFinal =
                  this.shipmentFormFinal.get(prop);
                if (formControl || shipmentFormControlFinal) {
                  formControl.setErrors({
                    serverError: validationErrors[prop],
                  });
                }
              });
            }
            this.loading = false;
            if (event === 'assign') {
              this.alertService.showNotificationMessage(
                'danger',
                'bottom',
                'right',
                'txt_d',
                'cancel',
                'Shipment Create',
                success.message
              );
            }
            this.loading = false;
          }
        },
        (error) => {
            this.loading = false;
            this.alertService.showNotificationMessage(
              'danger',
              'bottom',
              'right',
              'txt_d',
              'cancel',
              'Shipment Create Fail',
              error.error.message
            );
        }
      );
  }

  // To get the value of equipment type
  getEquipmentType(eqipment: any) {
    let equipmentReturn = '-';
    if (eqipment !== null) {
      let dequipmentReturn = eqipment.toString();
      equipmentReturn = dequipmentReturn.replace(/,/g, ', ');
    }
    return equipmentReturn;
  }

  // To check available time period 
  checkTimePeriod() {
    let now = new Date();
    this.minDate=new Date();
    const currentPickDate: any = moment(now).format('YYYY-MM-DD');
    const ctTime: any = moment(now).format('H:m');
    let checkeCurrentTime = ctTime.split(':');
    if (checkeCurrentTime[0].match(/22/g) || checkeCurrentTime[0].match(/23/g)) {
      let days = 1;
      this.minDate.setDate(this.minDate.getDate() + days);
    }
  }

  // Funtion for date manage
  manageDatePreviousDate(){
   let lastValue;
    if(this.editSectionShow){
      if(this.selectedEditIndex==0){
        let now = new Date();
        lastValue = this.pickCardArrays[this.selectedEditIndex];
        const currentPickDate: any = moment(now).format('YYYY-MM-DD');
        this.minDate = moment(currentPickDate).format('YYYY-MM-DD');
        this.checkTimePeriod()
      }else{
        let beforeSelectedIndex = this.selectedEditIndex - 1
        lastValue = this.pickCardArrays[this.selectedEditIndex];
        let getBeforeLastValue = this.pickCardArrays[beforeSelectedIndex];
        let checkeCurrentTime = getBeforeLastValue?.timeSlot?.split('-');
        this.checkTweintry(checkeCurrentTime,getBeforeLastValue);
      }
      
      this.checkManageLastDate();
    }else{
       lastValue =this.pickCardArrays[this.pickCardArrays.length - 1];
       this.minDate = moment(lastValue.date).format('YYYY-MM-DD');
      let checkeCurrentTime = lastValue.timeSlot.split('-');
      this.checkTweintry(checkeCurrentTime,lastValue);
    }  
  } 

  // Check available date before last selected date
  checkTweintry(checkeCurrentTime:any,lastValue){
    if(checkeCurrentTime[0].trim() >= '22:00'){
      let updateDate = moment(lastValue.date).add(1, 'd');
      this.minDate = moment(updateDate).format('YYYY-MM-DD');
    }else{
      this.minDate = moment(lastValue.date).format('YYYY-MM-DD');
    }
  }

  // Function for manage last date 
  checkManageLastDate(){
    let now = new Date();
    const currentPickDate: any = moment(now).format('YYYY-MM-DD');
    const ctTime: any = moment(now).format('HH:mm');
    const hours: any = moment(now).format('HH');
    const pickupDatec: any = moment(this.editSelecteData.date).format('YYYY-MM-DD');
    let  afterSelectedIndex;
    let lastArrayValue;
    if(this.selectedEditIndex==0){
      if(this.pickCardArrays.length > 0){
        afterSelectedIndex = this.selectedEditIndex + 1;
        lastArrayValue =this.pickCardArrays[afterSelectedIndex];
        this.maxDate = moment(lastArrayValue.date).format('YYYY-MM-DD');
      }
      for (let pickupIndex in this.pickupTimeArr) {
        let x = this.pickupTimeArr[pickupIndex].time.split('-');
        if (x[0] > ctTime) {
          this.matchId = this.pickupTimeArr[pickupIndex].id;
        }
        // if user's selection date is greater then today's date
        if (pickupDatec > currentPickDate) {
          // this is for two hour window if user select tomorrow's first slot between 10pm to 11.59.
            this.pickupTimeArr[pickupIndex].disabled = false;
            this.pickupTimeArr[pickupIndex].time =
            this.pickupTimeArr[pickupIndex].time;
        } else {
          if (this.pickupTimeArr[pickupIndex].id === this.matchId) {
            this.pickupTimeArr[pickupIndex].disabled = false;
            this.pickupTimeArr[pickupIndex].time =
              this.pickupTimeArr[pickupIndex].time;
          } else {
            this.pickupTimeArr[pickupIndex].disabled = true;
            this.pickupTimeArr[pickupIndex].time =
              this.pickupTimeArr[pickupIndex].time;
          }
        }
      }
      this.pickupTimeArr = this.pickupTimeArr;
    }else{
      let beforeSelectedIndex = this.selectedEditIndex - 1;
      let lastValue = this.pickCardArrays[beforeSelectedIndex];
      let checkeCurrentTime = lastValue.timeSlot.split('-');
      let totalLengthArray = this.pickCardArrays.length;
      let lengthIndex=totalLengthArray-1;
      let lastArrayValue
      if(this.selectedEditIndex==lengthIndex){
        this.maxDate = null;
      }else{
        let afterSelectedIndex = this.selectedEditIndex + 1;
        lastArrayValue =this.pickCardArrays[afterSelectedIndex];
        this.maxDate = moment(lastArrayValue.date).format('YYYY-MM-DD');
      }
      const lastWaypointData: any = moment(lastValue.date).format('YYYY-MM-DD');      
      if(lastWaypointData > pickupDatec){
        for (let pickupIndex in this.pickupTimeArr) {
          let x = this.pickupTimeArr[pickupIndex].time.split('-');
          if (x[0].trim() > checkeCurrentTime[0].trim()) {
            this.matchId = this.pickupTimeArr[pickupIndex].id;
          }
          if (this.pickupTimeArr[pickupIndex].id === this.matchId) {
            this.pickupTimeArr[pickupIndex].disabled = false;
            this.pickupTimeArr[pickupIndex].time =
              this.pickupTimeArr[pickupIndex].time;
          } else {
            this.pickupTimeArr[pickupIndex].disabled = true;
            this.pickupTimeArr[pickupIndex].time =
              this.pickupTimeArr[pickupIndex].time;
          }
        }
      }else if(lastWaypointData===pickupDatec){
        for (let pickupIndex in this.pickupTimeArr) {
          let x = this.pickupTimeArr[pickupIndex].time.split('-');
          if (x[0].trim() > checkeCurrentTime[0].trim()) {
            this.matchId = this.pickupTimeArr[pickupIndex].id;
          }
          if (this.pickupTimeArr[pickupIndex].id === this.matchId) {
            this.pickupTimeArr[pickupIndex].disabled = false;
            this.pickupTimeArr[pickupIndex].time =
              this.pickupTimeArr[pickupIndex].time;
          } else {
            this.pickupTimeArr[pickupIndex].disabled = true;
            this.pickupTimeArr[pickupIndex].time =
              this.pickupTimeArr[pickupIndex].time;
          }
        }
      }else{
        for (let pickupIndex in this.pickupTimeArr) {
        this.pickupTimeArr[pickupIndex].disabled = false;
        this.pickupTimeArr[pickupIndex].time =
          this.pickupTimeArr[pickupIndex].time;
        }
      }
      this.pickupTimeArr = this.pickupTimeArr;
    }
  }

  // To pick waypoint date 
  pickDateEvent(event1: any) {
    const pickupDatec: any = moment(event1.value).format('YYYY-MM-DD');
    let now = new Date();
    const currentPickDate: any = moment(now).format('YYYY-MM-DD');
    const ctTime: any = moment(now).format('HH:mm');
    const hours: any = moment(now).format('HH');
    this.wayPointForm.get('pickupTime').setValue('');
    if(this.editSectionShow){
      if(this.selectedEditIndex==0){
         let lastValue =this.pickCardArrays[this.selectedEditIndex];
        // let checkeCurrentTime = lastValue.timeSlot.split('-');
        for (let pickupIndex in this.pickupTimeArr) {
          let x = this.pickupTimeArr[pickupIndex].time.split('-');
          if (x[0] > ctTime) {
            this.matchId = this.pickupTimeArr[pickupIndex].id;
          }
          // if user's selection date is greater then today's date
          if (pickupDatec > currentPickDate) {
              this.pickupTimeArr[pickupIndex].disabled = false;
              this.pickupTimeArr[pickupIndex].time =
              this.pickupTimeArr[pickupIndex].time;
          } else {
            if (this.pickupTimeArr[pickupIndex].id === this.matchId) {
              this.pickupTimeArr[pickupIndex].disabled = false;
              this.pickupTimeArr[pickupIndex].time =
                this.pickupTimeArr[pickupIndex].time;
            } else {
              this.pickupTimeArr[pickupIndex].disabled = true;
              this.pickupTimeArr[pickupIndex].time =
                this.pickupTimeArr[pickupIndex].time;
            }
          }
        }
        this.pickupTimeArr = this.pickupTimeArr;
      }else{
        let beforeSelectedIndex = this.selectedEditIndex - 1;
        let totalLengthArray = this.pickCardArrays.length;
        let lengthIndex=totalLengthArray-1;
        let lastArrayValue
        if(this.selectedEditIndex===lengthIndex){
        }else{
          let afterSelectedIndex = this.selectedEditIndex + 1;
          lastArrayValue =this.pickCardArrays[afterSelectedIndex];
          this.maxDate = moment(lastArrayValue.date).format('YYYY-MM-DD');
        }
        let lastValue = this.pickCardArrays[beforeSelectedIndex];
        let checkeCurrentTime = lastValue.timeSlot.split('-');
        const lastWaypointData: any = moment(lastValue.date).format('YYYY-MM-DD');      
        if(lastWaypointData > pickupDatec){
          for (let pickupIndex in this.pickupTimeArr) {
            let x = this.pickupTimeArr[pickupIndex].time.split('-');
            if (x[0].trim() > checkeCurrentTime[1].trim()) {
              this.matchId = this.pickupTimeArr[pickupIndex].id;
            }
            if (this.pickupTimeArr[pickupIndex].id === this.matchId) {
              this.pickupTimeArr[pickupIndex].disabled = false;
              this.pickupTimeArr[pickupIndex].time =
                this.pickupTimeArr[pickupIndex].time;
            } else {
              this.pickupTimeArr[pickupIndex].disabled = true;
              this.pickupTimeArr[pickupIndex].time =
                this.pickupTimeArr[pickupIndex].time;
            }
          }
        }else if(lastWaypointData===pickupDatec){
          for (let pickupIndex in this.pickupTimeArr) {
            let x = this.pickupTimeArr[pickupIndex].time.split('-');
            if (x[0].trim() > checkeCurrentTime[0].trim()) {
              this.matchId = this.pickupTimeArr[pickupIndex].id;
            }
            if (this.pickupTimeArr[pickupIndex].id === this.matchId) {
              this.pickupTimeArr[pickupIndex].disabled = false;
              this.pickupTimeArr[pickupIndex].time =
                this.pickupTimeArr[pickupIndex].time;
            } else {
              this.pickupTimeArr[pickupIndex].disabled = true;
              this.pickupTimeArr[pickupIndex].time =
                this.pickupTimeArr[pickupIndex].time;
            }
          }
        }else{
          for (let pickupIndex in this.pickupTimeArr) {
          this.pickupTimeArr[pickupIndex].disabled = false;
          this.pickupTimeArr[pickupIndex].time =
            this.pickupTimeArr[pickupIndex].time;
          }
        }
        this.pickupTimeArr = this.pickupTimeArr;
      }
    }else{
      if(this.pickCardArrays.length > 0){
        let lastValue =this.pickCardArrays[this.pickCardArrays.length - 1];
        let checkeCurrentTime = lastValue.timeSlot.split('-');
        let lastWaypointData: any = moment(lastValue.date).format('YYYY-MM-DD');   
        if(lastWaypointData > pickupDatec){
          for (let pickupIndex in this.pickupTimeArr) {
            let x = this.pickupTimeArr[pickupIndex].time.split('-');
            if (x[0].trim() > checkeCurrentTime[0].trim()) {
              this.matchId = this.pickupTimeArr[pickupIndex].id;
              break;
            }
            if (this.pickupTimeArr[pickupIndex].id === this.matchId) {
              this.pickupTimeArr[pickupIndex].disabled = false;
              this.pickupTimeArr[pickupIndex].time =
                this.pickupTimeArr[pickupIndex].time;
            } else {
              this.pickupTimeArr[pickupIndex].disabled = true;
              this.pickupTimeArr[pickupIndex].time =
                this.pickupTimeArr[pickupIndex].time;
            }
          }
        }else if(lastWaypointData === pickupDatec){
          for (let pickupIndex in this.pickupTimeArr) {
            let x = this.pickupTimeArr[pickupIndex].time.split('-');
            if (x[0].trim() > checkeCurrentTime[0].trim()) {
              this.matchId = this.pickupTimeArr[pickupIndex].id;
            }
            if (this.pickupTimeArr[pickupIndex].id === this.matchId) {
              this.pickupTimeArr[pickupIndex].disabled = false;
              this.pickupTimeArr[pickupIndex].time =
                this.pickupTimeArr[pickupIndex].time;
            } else {
              this.pickupTimeArr[pickupIndex].disabled = true;
              this.pickupTimeArr[pickupIndex].time =
                this.pickupTimeArr[pickupIndex].time;
            }
          }
        }else{
          for (let pickupIndex in this.pickupTimeArr) {
          this.pickupTimeArr[pickupIndex].disabled = false;
          this.pickupTimeArr[pickupIndex].time =
            this.pickupTimeArr[pickupIndex].time;
          }
        }
        this.pickupTimeArr = this.pickupTimeArr;
      }else{
        for (let pickupIndex in this.pickupTimeArr) {
          let x = this.pickupTimeArr[pickupIndex].time.split('-');
          if (x[0] > ctTime) {
            this.matchId = this.pickupTimeArr[pickupIndex].id;
          }
          // if user's selection date is greater then today's date
          if (pickupDatec > currentPickDate) {
              this.pickupTimeArr[pickupIndex].disabled = false;
              this.pickupTimeArr[pickupIndex].time =
                this.pickupTimeArr[pickupIndex].time;
          } else {
            if (this.pickupTimeArr[pickupIndex].id === this.matchId) {
              this.pickupTimeArr[pickupIndex].disabled = false;
              this.pickupTimeArr[pickupIndex].time =
                this.pickupTimeArr[pickupIndex].time;
            } else {
              this.pickupTimeArr[pickupIndex].disabled = true;
              this.pickupTimeArr[pickupIndex].time =
                this.pickupTimeArr[pickupIndex].time;
            }
          }
        }
        this.pickupTimeArr = this.pickupTimeArr;
      }
    }
  }

  // To remove all selected carriers 
  removeElementFromArray(array: Array<String>, removeElement) {
    let indexOfElement = array.indexOf(removeElement);
      this.allPreCheckCarrier.splice(indexOfElement, 1);
  }

  // To clear all steps for shipment create by lane 
  clearAllStepsShow() {
    this.carrierDetailsShow = false;
    this.selectedCarrierShow = false;
    this.addFreightRateShow = false;
  }

  // To show the selected step details
  showSelectedStepDetails(selectedStepShow: any) {
    if (selectedStepShow === 'carrierDetailsShow') {
      this.carrierDetailsShow = true;
    } else if (selectedStepShow === 'selectedCarrierShow') {
      this.selectedCarrierShow = true;
    }
    if (selectedStepShow === 'addFreightRateShow') {
      this.addFreightRateShow = true;
    }
  }

  // Funtion for setting values for commodity 
  setValueCommodity(item:any) {
    const formArray = new FormArray([]);
    for (let record of item) {
      formArray.push(this.formBuilder.group({
        commodity: [record.commodity, [Validators.required,Validators.maxLength(128)]],
        packagingType: [record?.packagingType, [Validators.required]],
        quantity: [record?.quantity, [Validators.required]],
        dimension: [record.dimension, ],
        weight: [record.weight, ],
        value: [record.value, ],
        sku: [record.sku, [Validators.maxLength(15), Validators.pattern("^[A-Za-z0-9_-]*$")]],
        media: [record.media, ],
        handleWithCare:[record.handleWithCare],
      }));
    }
    this.wayPointForm.setControl('commodityForm', formArray);
  }
  
  // To add multiple commodity detail
  addCommunityField() {
    this.communityDataSubmiited=false;
      let control = <FormArray>this.wayPointForm.controls.commodityForm;
      control.push(
        this.formBuilder.group({
          commodity: [, [Validators.required,Validators.maxLength(128)]],
          packagingType: ['', [Validators.required]],
          quantity: ['', [Validators.required]],
          dimension: ['',],
          length: ['', ],
          width: ['', ],
          height: ['', ],
          weight: ['', ],
          value: ['', ],
          sku: ['', [Validators.maxLength(15), Validators.pattern("^[A-Za-z0-9_-]*$")]],
          media: ['',],
          handleWithCare:[''],
        }))
        this.wayPointForm.setControl('commodityForm', control);     
  }

  // Remove commodity field  
  removeCommunityField(index:any) {
    let control = <FormArray>this.wayPointForm.controls.commodityForm;
    control.removeAt(index);
    if(control.length===0){
      this.ifPickupLoadInfo=false;
    }
  }

  // To remove added media into commodity
  removeMedia(index){
    this.wayPointForm['controls']['commodityForm']['controls'][index]['controls'].media.patchValue('');
    this.wayPointForm['controls']['commodityForm']['controls'][index]['controls'].mediaShow.patchValue('');
  }

  // Remove commodity field  
  removeCommunityData(){
    const arr = <FormArray>this.wayPointForm.controls.commodityForm;
    arr.controls = [];
    this.commodityForm.reset();
    this.commodityForm.clear();
  }

  // To add commodity detail
  addLoadInformation() {
    this.ifPickupLoadInfo = true;
     this.communityDataSubmiited=false;
      let control = <FormArray>this.wayPointForm.controls.commodityForm;
      if(control.length===0){
        this.addCommunityField();
    }
  }

  // To upload image for commodity
  onSelectedUploadeImage(event:any, index :any) {
    this.currentIndex=index;
    if (event.target.files.length === 0) return;
    var mimeType = event.target.files[0].type;
    if (mimeType.match(/image\/*/) === null) {
      this.showErrorImage = 'Only supported Images file.';
      return;
    }else{
      this.showErrorImage=null;
    }
    var reader = new FileReader();
    this.imagePath = event.target.files;
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = (_event) => {
      this.imgURL = reader.result;
    };
    if (event.target.files.length) {
       const file = event.target.files[0];
      this.commodityMedia(file,index);
    }
  }

  // API call for commodity media
  commodityMedia(file:any,index:any) {
      const formData = new FormData();
      formData.append('media',file);
      let APIparams = {
        apiKey: AppSettings.APIsNameArray.SHIPMENT.COMMODITYMEDIA,
        uri: '',
        postBody: formData,
      };
      this.commonService.post(APIparams).subscribe(
        (success) => {
          this.currentIndex=null;
          if(success.success === true) { 
          this.wayPointForm['controls']['commodityForm']['controls'][index]['controls'].media.patchValue(success.response.location);
          } else if (success.success === false) {
          }
        },
        (error) => {
        }
      );
  }

  // To get Special Req from input field by event
  keyInstructionPress(event: any, type: any) {
    let valueGet = event.target.value;
    if (valueGet.length === 120) {
      if (type === 'pickup') {
        this.pickupInstructionError = true;
      } else {
        this.dropOffInstructionError = true;
      }
    } else {
      if (type === 'pickup') {
        this.pickupInstructionError = false;
      } else {
        this.dropOffInstructionError = false;
      }
    }
  }

  // Submit shipment Freight Rate for creating new shipment
  shipmentFreightRateSubmit({ value, valid }) {
    this.freightSubmit = true;
    if (valid) {
      if (this.isShipmentWithOrderID) {
        this.location.replaceState(
          '/shipment/create/shipper/step-third?type=' +
          this.type +
          '&orderId=' +
          this.orderId
        );
      } else {
        this.location.replaceState('/shipment/shipper/create/step-third');
      }
      this.freightRate = true;
      this.isDraft = 'N';
      this.addShipmentDetailsBody();
      this.addshipment.freightRate = this.shipmentFormFinal.get('freightRate').value;
      this.addshipment.paymentMethod = 1;
      if (this.paymentTerms === false) {
        this.addshipment.terms = this.shipmentFormFinal.get('terms').value;
        this.addshipment.stDays = this.shipmentFormFinal.get('stDays').value;
      } else {
        this.addshipment.terms = 1;
      }
      // this.privatePublic = true;
      if (this.isDeailsSave) {
        this.loading = true;
        this.callSaveShipmentDataApi(null);
        this.freightSubmit=false;
      } else {
        this.myStepper.next();
        this.allCarrierSkeloton = true;
        this.clearAllStepsShow();
        this.showSelectedStepDetails('addFreightRateShow');
        this.clearAllCarrierData();
        this.getCarrierListData(this.getAPIParam());
        this.activeStatus = 'selectedCarrier';
        this.freightSubmit=false;
        this.masterSelected=false;
        this.allPreCheckCarrier=[];
        this.checkIntereminate=false;
        this.isAllCarrier = false; 
      }
      let gaBidding;
      if (this.changeOfferRateBidding === true) {
        gaBidding = 'OfferRateNo';
      } else {
        gaBidding = 'Bidding';
      }
      if (this.addshipment.overBid === 'Y') {
        gaBidding = 'OfferRateYes';
      }
      this.addshipment.laneId =  this.route.snapshot.params['laneId']
    }
  }

  // To get required params for API 
  getAPIParam() {
    let APIparams, params;
    params = {
      limit: 10,
      tabType: 'connected',
      isLastShipment: 1,
      isTotalMiles: 1,
      page: this.page,
      laneId: this.route.snapshot.params['laneId'],
    };
    APIparams = {
      apiKey: AppSettings.APIsNameArray.SHIPMENTFORSHIPPER.CARRIER,
      uri: this.commonService.getAPIUriFromParams(params),
    };
    return APIparams;
  }

  // Function to get the list of carriers
  getCarrierListData(APIparams: any) {
    this.page = 1;
    this.commonService.getList(APIparams).subscribe((ServerRes) => {
      this.allCarrierSkeloton = false;
      if (ServerRes.response && ServerRes.response.length > 0) {
        for (let v = 0; v < ServerRes.response.length; v++) {
          ServerRes.response[v].isSelected = false;
        }
        this.carrierList = ServerRes.response;
        this.totalCarriers = ServerRes.totalCarriers;
        this.totalPage = ServerRes.totalPages;
      } else {
        this.carrierList = [];
        this.totalPage = 0;
        this.selectedIndex = 0;
      }
    });
  }

  // To get required params for API 
  getPreAPIParam() {
    let APIparams, params;
    params = {
      limit: 10,
      tabType: 'preferred',
      isLastShipment: 1,
      isTotalMiles: 1,
      page: this.prpage,
      laneId: this.route.snapshot.params['laneId'],
    };
    APIparams = {
      apiKey: AppSettings.APIsNameArray.SHIPMENTFORSHIPPER.CARRIER,
      uri: this.commonService.getAPIUriFromParams(params),
    };
    return APIparams;
  }

  // To get required params for API for confirmedOnLane tabType
  getConfirmedAPIParam() {
    let APIparams, params;
    params = {
      limit: 10,
      tabType: 'confirmedOnLane',
      laneId: this.route.snapshot.params['laneId'],
      isLastShipment: 1,
      isTotalMiles: 1,
      page: this.prpage,
    };
    APIparams = {
      apiKey: AppSettings.APIsNameArray.SHIPMENTFORSHIPPER.CARRIER,
      uri: this.commonService.getAPIUriFromParams(params),
    };
    return APIparams;
  }

  // To select carriers by its userId
  selectCarrierData(event, userId) {
    this.pristineCheck=true;
    if(event===true) {
      let addValue = this.allPreCheckCarrier.push(userId);
      this.checkInterMediated();
    } else {
      var removeValue = this.removeElementFromArray(this.allPreCheckCarrier, userId);
      this.checkInterMediated();
    }
    if(this.isAllCarrier)
    this.isAllCarrier = false; 
  }

  // To select all carriers from checkbox 
  checkInterMediated(){
    if(this.carrierList.length===this.allPreCheckCarrier.length){
       this.checkIntereminate=false;
      this.masterSelected=true;
    }else if(this.allPreCheckCarrier.length > 0){
      this.masterSelected=true;
      this.checkIntereminate=true;

   }else if (this.allPreCheckCarrier.length===0){
      this.masterSelected = false;
      this.checkIntereminate = false;  
      this.clearAllCarrierData();
    }
  }

  // For selecting all the available carriers 
  onSelectAll(){
    this.isAllCarrier = true;
    this.pristineCheck = true;
    this.ckeckAllCarrierData();
  }

  // To clear all selected carrier 
  onClearAll(){
    this.isAllCarrier = false;
    this.clearAllCarrierData()
  }

 
  // Child function of selectAllCareerData
    ckeckAllCarrierData() {
       this.masterSelected=true;
       this.checkIntereminate=false;
      for (let carrierIndex in this.carrierList) {
        this.allPreCheckCarrier.push(this.carrierList[carrierIndex].userId);
        this.carrierList[carrierIndex].isSelected = true;
      }
    }

    // To clear all carrier data 
  clearAllCarrierData() {
    this.masterSelected = false;
    this.allPreCheckCarrier = [];
    for (let carrierIndex in this.carrierList) {
      this.carrierList[carrierIndex].isSelected = false;
    }
  }

  // Preferred carriers or all carriers toogle function.
  changeCarrier(event) {
    if(!this.pristineCheck) {
    this.masterSelected=false;
    this.isAllCarrier=false;
    this.checkIntereminate=false;
    this.allPreCheckCarrier=[];
    this.allCarrierSkeloton = true;
    this.page = 1;
    this.prpage = 1;
    if (event.value == "preferred") {
      this.isPrefereedCarrier = true;
      this.selectedCarrierText = 'Preferred_Carriers';
      this.currentCarrierTab = 'preferred';
      this.getCarrierListData(this.getPreAPIParam());
    } else if (event.value == "connected"){
      this.isPrefereedCarrier = false;
      this.selectedCarrierText = 'All_Carriers';
      this.currentCarrierTab = 'connected';
      this.getCarrierListData(this.getAPIParam());
    }
    else if (event.value == "confirmed"){
      this.isPrefereedCarrier = false;
      this.selectedCarrierText = 'Confirmed_Carriers';
      this.currentCarrierTab = 'confirmed';
      this.getCarrierListData(this.getConfirmedAPIParam());
  }
}
    else 
    {
      this.openAlertPopup(event)
    }
  }

  // Aler popup for navigating away from the section  
  openAlertPopup(event)
  {
    const dialogRef = this.dialog.open(PopupComponent, {
      backdropClass: AppSettings.backdropClass,
      disableClose: true,
      width: AppSettings.popWidth,
      data: {
        openPop: 'alertForCheckBox',
       
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        if (result.event === 'ok') {
          this.pristineCheck = false;
          this.changeCarrier(event);
        }
      else {
          this.pristineCheck = true;
          this.carrierType=this.currentCarrierTab
        }
      }
    });
  }

  //  pagination scroll event for carrier list
  onCarrierDataScroll() {
    if ( !this.moreRecordLoader && this.page !== this.totalPage) {
      this.moreRecordLoader = true;
      this.page = this.page + 1;
      this.prpage = this.prpage + 1;
      this.getCarrierMoreData();
    }
  }

  // Load more carrier data on scroll 
  getCarrierMoreData() {
    var APIparams = null;
    if (this.selectedCarrierText == 'Preferred_Carriers') {
      APIparams = this.getPreAPIParam();
    } else if (this.selectedCarrierText == 'All_Carriers') {
      APIparams = this.getAPIParam();
    }
    else 
    {
      APIparams = this.getConfirmedAPIParam();
    }
    this.commonService.getList(APIparams).subscribe((ServerRes) => {
      if (ServerRes.response && ServerRes.response.length > 0) {
        for (let v = 0; v < ServerRes.response.length; v++) {
          if(this.isAllCarrier){
            ServerRes.response[v].isSelected = true;
            this.allPreCheckCarrier.push(ServerRes.response[v].userId);
          }
            this.carrierList.push(ServerRes.response[v]);
          
        }
        this.masterSelected=false;
        this.checkIntereminate=true;
        this.moreRecordLoader = false;
        
        //  this.checkPreCarrierMoreLoad();
      }
    });
  }

  //Sort carriers data
  sortCarriers(property) {
    this.isDescp = !this.isDescp; //change the direction
    this.columnp = property;
    let direction = this.isDescp ? 1 : -1;
    this.carrierList.sort(function (a, b) {
      if (a[property] < b[property]) {
        return -1 * direction;
      } else if (a[property] > b[property]) {
        return 1 * direction;
      } else {
        return 0;
      }
    });
  }

  // For automatic scroll on top
  setScrollTop() {
    let top = document.getElementById('topnav');
    if (top !== null) {
      top.scrollIntoView();
      top = null;
    }
  }

  //Shipment data api confirm sucess message popup.
  confirmSuccess(shipmentUniqueId: any, shipmentId: any): void {
    const dialogRef = this.dialog.open(PopupComponent, {
      backdropClass: AppSettings.backdropClass,
      width: AppSettings.popWidth,
      data: {
        openPop: 'shipmentBylaneSuccessShipper',
        shipmentUniqueId: shipmentUniqueId,
        shipmentId: shipmentId,
        isDraft: this.isDraft,
        offerRate: this.changeOfferRateBidding,
        truckLoad: this.changeTruckLoadType,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        if (result.event === 'success') {
          this.createAnother();
        }
      }
    });
  }

  // To create another shipment 
  createAnother(){
    this.pickCardArrays=[];
    this.pickCardArrays.length = 0;
    this.setWayPointPickupOrder = 0;
    this.setWayPointDroppupOrder = 0;
    this.setWayPointSquance = 0;
    this.shipmentDetailsForm.reset();
    this.shipmentDetailsForm.updateValueAndValidity();
    this.shipmentFormFinal.reset();
    this.shipmentFormFinal.updateValueAndValidity();
    this.notSaved = true;
    this.checkCrossButton=false;
    this.showNextBlowSection = false;
    this.hideButtonSection=true;
    this.editSectionShow = false;
    this.editSelecteData=null;
    this.selectedTrailerSpces="";
    this.pickupTimeArr = [];
    this.pickupTimeArr = StatusSetting.pickUpTime;
    this.minDate=new Date();
    this.wayPointForm.reset();
    this.multipleWayPointMakeData = {};
    this.selectedEditIndex=null;
    this.wayPointForm.get('pickupCountryCode').setValue('US');
    this.selecteCountryFlag('US');
    this.wayPointForm.get('wayPointType').setValue('pickup');
    this.location.replaceState('/load/create/shipper');
  }

  //To check route option by popup.
  carRouteOption(carRouteState: string): void {
    let popupName;
    if (carRouteState === 'true') {
      popupName = 'forceCarRoute';
    } else if (carRouteState === 'false') {
      popupName = 'noRoute';
    }
    const dialogRef = this.dialog.open(PopupComponent, {
      backdropClass: AppSettings.backdropClass,
      width: AppSettings.popWidth,
      data: {
        openPop: popupName,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        if (result.event === 'carSelected') {
          this.addshipment.forceCarRoute = true;
        } else if (result.event === 'addressChange'){
        }
      }
    });
  }
  
  // Function for generate duplicate loaders 
  generateFake(count: number): Array<number> {
    const indexes = [];
    for (let i = 0; i < count; i++) {
      indexes.push(i);
    }
    return indexes;
  }

  // To get First letters of company/legal name 
  getInitials(name) {
    let acronym;
    if (name) {
      acronym = name
        .split(/\s/)
        .reduce((response, word) => (response += word.slice(0, 1)), '');
      if (acronym && acronym.length >= 2) acronym = acronym.substr(0, 2);
    } else acronym = '';
    return acronym;
  }

  //Get price detail for laneaxis
  getPriceLaneaxis() {
    let uri = null;
    //get dynamic URI for APIs
    let paramsNew = {};
    if (paramsNew) uri = this.commonService.getAPIUriFromParams(paramsNew);
    let APIparams = {
      apiKey: AppSettings.APIsNameArray.NEWPAYMENT.FEES,
      uri: uri,
    };
    this.commonService.getList(APIparams).subscribe(
      (ServerRes) => {
        if (ServerRes.response) {
          this.laneaxisPrice = ServerRes.response;
          this.showTdays =
            this.laneaxisPrice.payOrder.usdAch.shipper.laneAxis.terms;
        } else {
          this.laneaxisPrice = null;
          this.showTdays = null;
        }
      },
      (err) => {
        this.laneaxisPrice = null;
        this.showTdays = null;
        this.alertService.showNotificationMessage(
          'danger',
          'bottom',
          'right',
          'txt_g',
          'error',
          'Unexpected Error',
          AppSettings.ERROR
        );
      }
    );
  }

  // To change payment terms
  changePaymentTerms(event) {
    if (event.value === 2) {
      this.getCardListing();
      this.getPriceLaneaxis();
      this.paymentTermsValue = event.value;
      this.shipmentFormFinal.get('stDays').setValidators([Validators.required]);
      this.shipmentFormFinal.get('stDays').updateValueAndValidity();
      this.shipmentFormFinal.get('terms').setValue(this.paymentTermsValue);
    } else {
      this.paymentTerms = true;
      this.paymentTermsValue = event.value;
      this.shipmentFormFinal.get('terms').setValue(this.paymentTermsValue);
      this.shipmentFormFinal.get('stDays').clearValidators();
      this.shipmentFormFinal.get('stDays').setValue('');
      this.numberOfDays = 'N';
    }
  }

  // For Payment Scheduled from delivery
  selectScheduled(event) {
    this.numberOfDays = event.value;
    for (const [key, value] of Object.entries(this.showTdays)) {
      if (this.numberOfDays === key) {
        this.percentACH = value;
        break;
      }
    }
  }
  
  // API call for Cards list
  getCardListing(params = {}) {
    this.skeletonLoaderCard = false;
    let uri = null;
    let paramsNew = {
      type: 'us_bank_account',
    };
    if (paramsNew) uri = this.commonService.getAPIUriFromParams(paramsNew);
    let APIparams = {
      apiKey: AppSettings.APIsNameArray.ACHPAYMENT.ACHLIST,
      uri: uri,
    };
    this.commonService.getList(APIparams).subscribe(
      (ServerRes) => {
        if (ServerRes.success === true) {
          this.cardListing = ServerRes.response.data;
          if (this.cardListing.length > 0) {
            this.paymentTerms = false;
            this.skeletonLoaderCard = true;
          } else {
            this.paymentTermsValue = 1;
            this.shipmentFormFinal.get('terms').setValue(1);
            this.shipmentFormFinal.get('stDays').clearValidators();
            this.shipmentFormFinal.get('stDays').setValue('');
            this.paymentTerms = true;
            this.skeletonLoaderCard = true;
          }
        } else {
          this.paymentTerms = true;
          this.cardListing = [];
          this.skeletonLoaderCard = true;
        }
      },
      (error) => {
        this.skeletonLoaderCard = true;
        this.cardListing = [];
        this.paymentTerms = true;
      }
    );
  }

  // To change shipment type for bidding or offer rate
  changeBiddingFixedRate(event) {
    if (event.checked === true) {
      this.changeOfferRateBidding = true;
      this.addshipment.isOfferRate = 'Y';
      this.offerRateStatus = 'Y';
      this.addshipment.overBid = 'N';
    } else {
      this.changeOfferRateBidding = false;
      this.addshipment.overBid = '';
      this.addshipment.isOfferRate = 'N';
      this.offerRateStatus = 'N';
      this.addshipment.overBid = null;
    }
  }

  // To select TruckLoadType for shipment
  selectTruckLoadType(event) {
    if (event.checked === true) {
      this.changeTruckLoadType = true;
      this.truckLoadTypeStatus = 2;
      this.addshipment.truckLoadType = 2;
    } else {
      this.changeTruckLoadType = false;
      this.truckLoadTypeStatus = 1;
      this.addshipment.truckLoadType = 1;
    }
  }

  // Shipment detail save 
  shipmentConfirmationSaveDetail() {
    this.finalSubmission = true;
      this.selectedCarrierSaveDetails();
  }

  // Preselected carriers checkbox
  selectedCarrierSaveDetails() {
      this.buttonClicked = true;
    if (this.allPreCheckCarrier.length > 0 && !this.isAllCarrier) {
      this.addshipment.carrierIds = this.allPreCheckCarrier;
    }
    for (let i = 0; i < this.pickCardArrays.length; i++) {
      for (let j = 0; j < this.pickCardArrays[i].commodity.length; j++) {
        if (this.pickCardArrays[i].commodity[j].dimension == null || this.pickCardArrays[i].commodity[j].dimension=='') {
          delete this.pickCardArrays[i].commodity[j].dimension
        }
        if (this.pickCardArrays[i].commodity[j].weight == null || this.pickCardArrays[i].commodity[j].weight=='') {
          delete this.pickCardArrays[i].commodity[j].weight
        }
        if (this.pickCardArrays[i].commodity[j].sku == null || this.pickCardArrays[i].commodity[j].sku=='') {
          delete this.pickCardArrays[i].commodity[j].sku
        }
        if (this.pickCardArrays[i].commodity[j].value == null || this.pickCardArrays[i].commodity[j].value=='') {
          delete this.pickCardArrays[i].commodity[j].value
        }
        if (this.pickCardArrays[i].commodity[j].handleWithCare == null || this.pickCardArrays[i].commodity[j].handleWithCare=='') {
          delete this.pickCardArrays[i].commodity[j].handleWithCare
        }
        if (this.pickCardArrays[i].commodity[j].media == null || this.pickCardArrays[i].commodity[j].media=='') {
          delete this.pickCardArrays[i].commodity[j].media
        }
        delete this.pickCardArrays[i].commodity[j].length
        delete this.pickCardArrays[i].commodity[j].height
        delete this.pickCardArrays[i].commodity[j].width
      }
    }
    if (this.pickCardArrays.length > 0) {
      this.addshipment.waypoints = this.pickCardArrays
    }
  
      this.addshipment.isAllCarrier = this.isAllCarrier ? 'true' : 'false'
      this.addshipment.selectionType = this.carrierType=='preferred' ? 'preferred' : this.carrierType=='connected' ? 'connected' : 'confirmedOnLane';
    
    this.callSaveShipmentDataApi(null);
    let gaBidding;
    if (this.changeOfferRateBidding === true) {
      gaBidding = 'OfferRateNo';
    } else {
      gaBidding = 'Bidding';
    }
    if (this.addshipment.overBid === 'Y') {
      gaBidding = 'OfferRateYes';
    }

    if(this.privatePublic){
      this.addshipment.visibility='private';     
    } else{
      this.addshipment.visibility='public';     
    } 

   var dimensions = null;
    if (this.pickupTimeZone) {
      dimensions = 'pickupTimeZone=' + this.pickupTimeZone;
    }
    if (this.dropTimeZone) {
      const dropTimeZone = 'dropTimeZone=' + this.dropTimeZone;
      dimensions = dimensions ? dimensions + '&' + dropTimeZone : dropTimeZone;
    }
    if (this.addshipment.equipmentLength) {
      const equipmentTypeLength =
        'equipmentTypeLength=' + this.addshipment.equipmentLength;
      dimensions = dimensions
        ? dimensions + '&' + equipmentTypeLength
        : equipmentTypeLength;
    }
    if (this.selectedCurrency) {
      const selectedCurrency = 'paymentType=' + this.selectedCurrency;
      dimensions = dimensions
        ? dimensions + '&' + selectedCurrency
        : selectedCurrency;
    }
    if (this.addshipment.visibility) {
      const visibility = 'visibility=' + this.addshipment.visibility;
      dimensions = dimensions ? dimensions + '&' + visibility : visibility;
    }
    var obj = {
      eventName: 'shipper_create_shipment',
      event_category: 'Shipment',
      userType: localStorage.getItem('user_type'),
      EmailID: localStorage.getItem('email'),
      Dimensions: dimensions ? dimensions : '-',
      ShipmentType: gaBidding,
      EquipmentType: this.equipmentTypeV ? this.equipmentTypeV : '-',
      userLocation: this.shipmentDetailsForm.value.pickupLocation,
      TargetLocation: this.shipmentDetailsForm.value.deliveryLocation,
    };
    this.sharedService.track(obj);
  }

  // To set ping rate for shipment status check
  setPingRate(event: any) {
    this.pingRateStatusCheck = event.checked;
    if (event.checked === true) {
      this.shipmentPingSubmitted=false;
      this.pingRateStatus = 2;
      this.shipmentDetailsForm.get('pingRateValue').setValue('');
      this.shipmentDetailsForm
        .get('pingRateValue')
        .setValidators([Validators.required, Validators.max(60)]);
    } else {
      this.shipmentPingSubmitted=false;

      this.pingRateStatus = 1;
      this.shipmentDetailsForm.get('pingRateValue').clearValidators();
      this.shipmentDetailsForm.get('pingRateValue').setValue('');
      this.shipmentDetailsForm.get('pingRateValue').markAsUntouched();
    }
  }

  // Select Trailer Specs/Requirements
  selectTrailerSpecs(event) {
    this.selectedTrailerSpces = event.source.triggerValue;
    if (this.selectedTrailerSpces.includes('Temp-controlled')) {
      this.isTemprature = true;
    } else {
      this.isTemprature = false;
      this.shipmentDetailsForm.get('temperature').setValue(null);
    }
  }

  // To select equipmentLength according to it's equipmentType 
  selectEquipmentLength(event) {
    const equipmentLength = this.equipmentListing.filter(
      (item) => item.id === event.value
    );
    if (event.value === 9) {
      this.checkEmptyValue = '0';
      this.shipmentDetailsForm
        .get('equipmentTypeLength')
        .setValue(this.checkEmptyValue);
    } else {
      this.checkEmptyValue = '';
      this.shipmentDetailsForm
        .get('equipmentTypeLength')
        .setValue(this.checkEmptyValue);
      if (equipmentLength) {
        const mapped = Object.keys(equipmentLength[0].length).map((key) => ({
          lengthKey: key,
          lengthValue: equipmentLength[0].length[key],
        }));
        this.equipmentLengthList = mapped;
      }
    }
  }

  // To get time in milliseconds for change detection 
  sleep(milliseconds) {
    const date = Date.now();
    let currentDate = null;
    do {
      currentDate = Date.now();
    } while (currentDate - date < milliseconds);
  }

  // Function for change detection in pickup/dropOff
  noAutoselection(loc: any) {
    if (loc === 'pick') {
      this.pickupChangeDetected = true;
      this.pickAutoNot = false;
    }
  }

  // Function for change detection in pickup/dropOff auto complete
  noFocusOut(pickdrop: any) {
    this.sleep(500);
    if (pickdrop === 'pick' && this.pickupChangeDetected) {
      this.pickAutoNot = true;
    }
  }

    // back to previous page
    backHistory(){
      history.back()
    }

  // To get input value for phone number by event
  keyPaste(event: any) {
    const pattern = /[0-9\+\-\ ]/;
    if (!pattern.test(event.target.value)) {
      return this.wayPointForm.get('pickPhoneNumber').setValue('');
    }
  }

  // To get input value for phone number by keypress event
  keyPress(event: any) {
    const pattern = /[0-9\+\-\ ]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode !== 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  // API call for getting lane waypoint list
  wayPointList()
  {
    this.loading = true;
    let uri = null;
    let newParams = {
      laneId: this.route.snapshot.params['laneId'],
    };
    if (newParams) uri = this.commonService.getAPIUriFromParams(newParams);
    let APIparams = {
      apiKey: AppSettings.APIsNameArray.SHIPMENT.LANEWAYPOINTS,
      uri: uri,
    };
    this.commonService.getList(APIparams).subscribe(
       (ServerRes) => {
    
      this.wayPoints = ServerRes.response.waypoints
      for(let i=0; i < this.wayPoints.length ; i++)
      {
        this.updateWayPointFromAPI(this.wayPoints[i],i)
      }

      this.laneRecordGet();
      this.editWayPointCard(this.wayPoints[0],0)

      this.loading = false;
      })
  }

  // change Text of visibility from private to public for api. 
  changeVisibilityText(event){
    if(event.checked){
      this.addshipment.visibility='private';
      this.privatePublic=true;
    }else{
      this.addshipment.visibility='public';
      this.privatePublic=false;
    }
  }

  // To update waypoint from waypoint list API
  updateWayPointFromAPI(waypoints:any,i:any) {
    if(waypoints.type === 'pickup' ) {
      if (waypoints.locationOrder) {
        this.setWayPointPickupOrder = this.setWayPointPickupOrder + 1;
        this.multipleWayPointMakeData.locationOrder = waypoints.locationOrder;
      }
    } else if (waypoints.type === 'dropoff') {
      this.setWayPointDroppupOrder = this.setWayPointDroppupOrder + 1;
      this.multipleWayPointMakeData.locationOrder = waypoints.locationOrder;
    } else if (waypoints.type === 'dropoff') {
        this.multipleWayPointMakeData.locationOrder = waypoints.locationOrder;
        this.setWayPointDroppupOrder = this.setWayPointDroppupOrder + 1;
      } 
    this.multipleWayPointMakeData.sequence = waypoints.sequence;
    if (this.wayPointForm.get('pickupBussinessName').value) {
      this.multipleWayPointMakeData.businessName = this.wayPointForm.get('pickupBussinessName').value;
    }
    this.multipleWayPointMakeData.latitude = waypoints.latitude;
    this.multipleWayPointMakeData.longitude =waypoints.longitude;
    this.multipleWayPointMakeData.locationLongName = waypoints.locationLongName;
    this.multipleWayPointMakeData.city = waypoints.city ? waypoints.city : waypoints.state;
    this.multipleWayPointMakeData.state = waypoints.state;

    if(this.countryList && this.countryList.length > 0){
      this.selectedCountry = this.countryList.filter((item) => item.country === (waypoints.countryName ? waypoints.countryName : 'US'));
      this.defualtPickupCountryFlag = this.countryList[i]?.flag;
      this.newSelectPickupCountryCode = this.countryList[i]?.countryAbbr;
    }

    this.multipleWayPointMakeData.countryName = waypoints.countryName ? waypoints.countryName : 'US'

    this.multipleWayPointMakeData.type = waypoints.type;
    this.multipleWayPointMakeData.timeSlot =  null;
    this.multipleWayPointMakeData.timeZone = null;
    this.multipleWayPointMakeData.zip = waypoints.zip
    this.multipleWayPointMakeData.date = null;
    if(i==0)
  {
      this.multipleWayPointMakeData.commodity = waypoints.commodity
  }
 
  
    if ((this.wayPointForm.get('pickupCountryCode').value) && (this.wayPointForm.get('pickPhoneNumber').value)) {
      this.multipleWayPointMakeData.countryCode = this.newSelectPickupCountryCode;
    }
    if (this.wayPointForm.get('pickPhoneNumber').value) {
      this.multipleWayPointMakeData.phone = null;
    }
    if (this.wayPointForm.get('pickupContactName').value) {
      this.multipleWayPointMakeData.contactName = null;
    }
    if (this.wayPointForm.get('pickInstructions').value) {
      this.multipleWayPointMakeData.specialRequirement = null;
    }
    this.pickCardArrays[i] = this.multipleWayPointMakeData;
    this.wayPointForm.reset();
    this.multipleWayPointMakeData = {};
    this.wayPointForm.get('wayPointType').setValue(this.setWayPointValue)
    this.maxDate = null;
    this.minDate = new Date()
    this.wayPointForm.get('pickupCountryCode').setValue('US');
    this.selecteCountryFlag('US');
    this.checkUpdateLocationUpdate();
    this.selectedEditIndex=null;
  }

  // API call for getting lane details
  laneRecordGet() {
      this.loading = true;
      let uri = null;
      let newParams = {
        id: this.route.snapshot.params['laneId'],
      };
      if (newParams) uri = this.commonService.getAPIUriFromParams(newParams);
      let APIparams = {
        apiKey: AppSettings.APIsNameArray.SHIPMENT.LANEGET,
        uri: uri,
      };
      this.commonService.getList(APIparams).subscribe(
        async (ServerRes) => {
          if (ServerRes.success === true) {
           this.laneDetail = ServerRes.response;

          this.laneDetail.waypoints = this.wayPoints

          this.changeBiddingFixedRate({checked:(this.laneDetail.rateType=='1' ? true : false)})
          
          this.laneEquipmentType = this.equipmentListing?.filter(
            (item) => item.label ==this.laneDetail.equipmentName
          );

         let equipmentLength 

          if (this.laneEquipmentType) {
            const mapped = Object.keys(this.laneEquipmentType[0].length).map((key) => ({
              lengthKey: key,
              lengthValue: this.laneEquipmentType[0].length[key],
            }));
            this.equipmentLengthList = mapped;
      
            this.laneEquipmentLength = this.equipmentLengthList.filter(
              (item) => item.lengthKey ==this.laneDetail.equipmentType ? item : this.equipmentLengthList[0]
            );
            this.shipmentDetailsForm.get('equipmentType').setValue(this.laneEquipmentType[0]?.id)   
            if (this.laneEquipmentType[0].id === 9) {
              this.checkEmptyValue = '0';
              this.shipmentDetailsForm.get('equipmentTypeLength').setValue(this.checkEmptyValue);
              this.shipmentDetailsForm.get('equipmentTypeLength').setValidators(Validators.minLength(0));
                this.shipmentDetailsForm.get('equipmentTypeLength').updateValueAndValidity();
            } else {
             this.shipmentDetailsForm.get('equipmentTypeLength').setValue(this.laneEquipmentLength[0].lengthValue) 
            }
          }
          let terms;
          if (ServerRes.response.terms === 'Shipper Terms') {
            terms = '2';
            this.paymentTerms = false;
            this.numberOfDays = ServerRes.response.stDaysNumber;
          } else {
            terms = '1';
            this.paymentTerms = true;
          }
        this.shipmentShipperEmail = ServerRes.response.shipperEmail;
          if(ServerRes.response.trailerSpecs){
            for (let index = 0; index < this.trailerSpecsRequipment.length; index++) {
              for (let subIndex = 0; subIndex < ServerRes.response.trailerSpecs.length; subIndex++) {
                if(ServerRes.response.trailerSpecs[subIndex]===this.trailerSpecsRequipment[index].label){
                  this.trailerSpecsRequipmentSlug.push(this.trailerSpecsRequipment[index].slug);            
                }
              }
            }
          }  
          if (ServerRes.response.temperature) {
            this.isTemprature = true;
          } 

          this.shipmentDetailsForm = this.formBuilder.group({
            title: [
              ServerRes.response.title,
              [Validators.required, Validators.maxLength(128)],
            ],
            equipmentType: [
              ServerRes.response.equipmentType,
              [Validators.required],
            ],
            equipmentTypeLength: [
              ServerRes.response.equipmentLength,
              [this.checkEmptyValue === 'NoLength' ? [] : [Validators.required]],
            ],
            purchaseOrderId: [ServerRes.response.purchaseOrderId, [Validators.maxLength(64),]],
            trailerSpecs: [this.trailerSpecsRequipmentSlug],
            temperature: [ServerRes.response.temperature, [Validators.min(-126), Validators.max(136), Validators.pattern(/^-?(0|[1-9]\d*)?$/)]],
            pickupBussinessName: ['', [Validators.maxLength(64),]],
            dropBussinessName: ['', [Validators.maxLength(64),]],
            pingRateValue: [( this.laneDetail?.pingRateValue) / 60, [
              Validators.max(60),
            ],],
            pingRateStatus: [this.pingRateStatus],
  
            shipperName: [
              ServerRes.response.shipperName
                ? ServerRes.response.shipperName
                : '',
              [Validators.maxLength(64)],
            ],
            shipperEmail: [
              this.shipmentShipperEmail,
              [
                Validators.email,
                Validators.pattern(this.emailPattern),
                Validators.maxLength(128),
              ],
            ],
            terms: [terms],
            stDays: [
              ServerRes.response.terms == 'Shipper Terms'
                ? ServerRes.response.stDaysNumber.toString()
                : '',
            ],
            freightRate: [
              ServerRes.response.freightRate,
              ServerRes.response.freightRate
                ? [
                  Validators.required,
                  Validators.maxLength(10),
                  Validators.min(1),
                  Validators.max(this.finalAmount),
                  Validators.pattern(/^\d+(\.\d+)?$/),
                ]
                : [Validators.maxLength(128)],
            ],
            paymentMethod: [ServerRes.response.paymentMethod],
          });
    
      for(let i=0; i < this.laneDetail.waypoints.length ; i++)
      {
        let data = {
          locationLongName : this.laneDetail.waypoints[i]?.locationLongName,
          cityState: this.laneDetail.waypoints[i]?.city + ',' + this.laneDetail.waypoints[i]?.state,
          zip:this.laneDetail.waypoints[i]?.zip,
          locality:{long:this.laneDetail.waypoints[i]?.city},
          state:{long:this.laneDetail.waypoints[i]?.state},
          locationOrder:this.laneDetail.waypoints[i]?.locationOrder,
          sequence:this.laneDetail.waypoints[i]?.sequence,
          geoLocation : {latitude : this.laneDetail.waypoints[i]?.latitude,longitude :this.laneDetail.waypoints[i]?.longitude},
        }

        this.editWayPointCard(this.pickCardArrays[0],0)
      }
     
      this.loading = false;
          } else {
            this.loading = false;
          }
        },
        (error) => {
          this.loading = false;
        }
      );
  }

  // To combine the dimension of length, width and hieght
  dimensionCombiner(index)
  {
    let commodityForm =  this.wayPointForm.get('commodityForm').value;
    let dimension = (commodityForm[index].length ? commodityForm[index].length :'000') + 'x'+ (commodityForm[index].width ? commodityForm[index].width :'000') +'x'+(commodityForm[index].height ? commodityForm[index].height :'000')
    
    if (!commodityForm[index].length && !commodityForm[index].width && !commodityForm[index].height)
    {
      let control = <FormArray>this.wayPointForm.controls.commodityForm;
      control.at(index).get('length').setValidators([])
      control.at(index).get('height').setValidators([])
      control.at(index).get('width').setValidators([])
      this.wayPointForm.setControl('commodityForm', control);     
    }
    else if(!commodityForm[index].length || !commodityForm[index].width || !commodityForm[index].height)
    {
      let control = <FormArray>this.wayPointForm.controls.commodityForm;
      control.at(index).get('length').setValidators([Validators.required])
      control.at(index).get('height').setValidators([Validators.required])
      control.at(index).get('width').setValidators([Validators.required])
        this.wayPointForm.setControl('commodityForm', control);     
    }
    else 
    {
      let control = <FormArray>this.wayPointForm.controls.commodityForm;
      control.at(index).get('dimension').setValue(dimension)
      this.wayPointForm.setControl('commodityForm', control);    
    }
  }

}

