<div class="left_side_panel" >
  <div class="lsp_head">
    <div class="la_logo_img">
      <img class="img-fluid" src="{{serverPathForUrl}}/portal/images/after-login/laneaxis-logo.svg" (click)="redirectMainPage()">
    </div>
  </div>
  <div class="lsp_body">
    <div class="lsp_body_t">
      <ul class="lsp_list">
        <li>
          <mat-accordion>
            <mat-expansion-panel [expanded]="step === 1" (opened)="setStep(1)">
              <mat-expansion-panel-header [routerLinkActive]="'active'" [routerLink]="userType=='SHIPPER' ? ['recurring-lanes/create-lanes/allLanes'] : ['recurring-lanes/available-lanes/allLanes']">
                <mat-panel-title>
                  <img src="{{serverPathForUrl}}/portal/left-side-menu-icons/Reccuring-lane-icon.svg" class="left_menu_icon">
                  <span class="munu_title fsn_4 fs_14">Recurring Lanes</span>
                </mat-panel-title>
              </mat-expansion-panel-header>
                <div class="lsp_submenu">
                  <a [routerLinkActive]="'sub_active'" [routerLink]="'/recurring-lanes/create-lanes/allLanes'"  [ngClass]="activeSubTabs =='createRecurringLane' ? 'sub_active' : ''" class="pointer left_sub_menu fsn_4 fs_14" (click)="activeSubMenu('createRecurringLane')" *ngIf="userType=='SHIPPER'">Created Lane</a>
                  <a  [routerLinkActive]="'sub_active'" [routerLink]="'/recurring-lanes/available-lanes/allLanes'"  [ngClass]="activeSubTabs =='availableLane' ? 'sub_active' : ''" class="pointer left_sub_menu fsn_4 fs_14" (click)="activeSubMenu('availableLane')" *ngIf="userType=='CARRIER'">Available Lane</a>
                  <a  [routerLinkActive]="'sub_active'" [routerLink]="'/recurring-lanes/active-lanes/inProgressByLane'"  [ngClass]="activeSubTabs =='activeLoadsFromLane' ? 'sub_active' : ''" class="pointer left_sub_menu fsn_4 fs_14" (click)="activeSubMenu('activeLoadsFromLane')" *ngIf="userType!=='STORAGE'" >Active lanes</a>
                  <a  [routerLinkActive]="'sub_active'" [routerLink]="'/recurring-lanes/confirmRateHistory'"  [ngClass]="activeSubTabs =='confirmedRatesHistory' ? 'sub_active' : ''" class="pointer left_sub_menu fsn_4 fs_14" (click)="activeSubMenu('confirmedRatesHistory')" *ngIf="userType==='SHIPPER'" >Confirmed Rates History</a>
                </div>
            </mat-expansion-panel>
          </mat-accordion>
          <mat-accordion>
            <mat-expansion-panel [expanded]="step === 2" (opened)="setStep(2)">
              <mat-expansion-panel-header [routerLinkActive]="'active'"  [routerLink]="userType=='SHIPPER' ? ['spot-rate-loads/created-loads/allLoads'] : ['spot-rate-loads/available-loads/allLoads']">
                <mat-panel-title>
                  <img src="{{serverPathForUrl}}/portal/left-side-menu-icons/spot-rates-load-icon.svg" class="left_menu_icon">
                  <span class="munu_title fsn_4 fs_14">Spot Rate Loads</span>
                </mat-panel-title>
                <!-- <span *ngIf="isallSpotLoadsCounts" class="badge custom_mess_badge fsm_5 fs_12">{{totalNotificationCount}}</span> -->
              </mat-expansion-panel-header>
                <div class="lsp_submenu">
                  <a [routerLinkActive]="'sub_active'" [ngClass]="activeSubTabs =='spotCreateLoads' ? 'sub_active' : ''" class="pointer left_sub_menu fsn_4 fs_14" (click)="activeSubMenu('spotCreateLoads')" [routerLink]="'/spot-rate-loads/created-loads/allLoads'" *ngIf="userType =='SHIPPER' " >Create Loads</a>
                  <a [routerLinkActive]="'sub_active'" [ngClass]="activeSubTabs =='availableLoads' ? 'sub_active' : ''" class="pointer left_sub_menu fsn_4 fs_14" (click)="activeSubMenu('availableLoads')" [routerLink]="'/spot-rate-loads/available-loads/allLoads'" *ngIf="userType =='CARRIER' " >Available Loads</a>
                  <a [routerLinkActive]="'sub_active'" [ngClass]="activeSubTabs =='activeLoads' ? 'sub_active' : ''" class="pointer left_sub_menu fsn_4 fs_14" (click)="activeSubMenu('activeLoads')" [routerLink]="'/spot-rate-loads/active-loads/inProgressByLoads'" *ngIf="userType !=='STORAGE'">Active Loads</a>
                  <!-- <a [ngClass]="activeSubTabs =='activeLoads' ? 'sub_active' : ''" class="pointer left_sub_menu fsn_4 fs_14" (click)="activeSubMenu('activeLoads')" *ngIf="userType !=='STORAGE'">Active Loads
                  <span *ngIf="isallSpotLoadsCounts" class="badge fsm_5 fs_12">{{allSpotLoadsCounts}}</span></a> -->
                </div>
            </mat-expansion-panel>
          </mat-accordion>
          <mat-accordion>
            <mat-expansion-panel [expanded]="step === 3" (opened)="setStep(3)">
              <mat-expansion-panel-header [routerLinkActive]="'active'" [routerLink]="['network/my-network/connected']">
                  <mat-panel-title>
                    <img src="{{serverPathForUrl}}/portal/left-side-menu-icons/Network-icon.svg" class="left_menu_icon">
                    <span class="munu_title fsn_4 fs_14">Network</span>
                  </mat-panel-title>
                  <!-- <span *ngIf="allNetworkCount" class="badge custom_mess_badge fsm_5 fs_12" id="networkBadge">{{this.allNetworkCount}}</span> -->
              </mat-expansion-panel-header>
              <div class="lsp_submenu">
                <!-- <a [ngClass]="activeSubTabs =='My Network' ? 'sub_active' : ''" class="pointer left_sub_menu fsn_4 fs_14" (click)="activeSubMenu('My Network')">My Network
                  <span *ngIf="allNetworkCount" class="badge fsm_5 fs_12" id="networkBadge">{{this.allNetworkCount}}</span></a> -->
                <a [routerLinkActive]="'sub_active'" [routerLink]="'/network/my-network/connected'"  [ngClass]="activeSubTabs =='My Network' ? 'sub_active' : ''" class="pointer left_sub_menu fsn_4 fs_14" (click)="activeSubMenu('My Network')">My Network</a>
                <a [routerLinkActive]="'sub_active'" [routerLink]="'/network/home'"  [ngClass]="activeSubTabs =='home' ? 'sub_active' : ''" class="pointer left_sub_menu fsn_4 fs_14" (click)="activeSubMenu('home')"  *ngIf="userType==='CARRIER'">Browse Shippers</a>
                <a [routerLinkActive]="'sub_active'" [routerLink]="'/network/home'"  [ngClass]="activeSubTabs =='home' ? 'sub_active' : ''" class="pointer left_sub_menu fsn_4 fs_14" (click)="activeSubMenu('home')"  *ngIf="userType==='SHIPPER'">Find Carriers</a>
              </div>
            </mat-expansion-panel>
          </mat-accordion>
        </li>
        <li class="non_expention_panel pointer" *ngIf="userType==='CARRIER'" [ngClass]="activeSubTabs=='bid-quote-assistant' ? 'active' :''" (click)="activeSubMenu('bid-quote-assistant')">
          <a class="main_menu_item"  [routerLinkActive]="'sub_active'" [routerLink]="'/bid-quote-assistant'">
            <span>
              <img src="{{serverPathForUrl}}/portal/left-side-menu-icons/qoute-bid-assistant-icon.svg" class="left_menu_icon">
            </span>
            <span class="munu_title fsn_4 fs_14">Quote Assistant</span>
          </a>
        </li>
        <li class="non_expention_panel pointer" *ngIf="userType==='CARRIER'" [ngClass]="activeSubTabs=='shipmentCreatedByMe' ? 'active' :''" (click)="activeSubMenu('shipmentCreatedByMe')">
          <a class="main_menu_item"  [routerLinkActive]="'active'" [routerLink]="'/load-created-by-me'">
            <span>
              <img src="{{serverPathForUrl}}/portal/left-side-menu-icons/shipment-created-by-icon.svg" class="left_menu_icon">
            </span>
            <span class="munu_title fsn_4 fs_14">Loads Created By Me</span>
          </a>
        </li>
        <mat-accordion *ngIf="userType==='SHIPPER'">
          <mat-expansion-panel [expanded]="step === 4" (opened)="setStep(4)">
            <mat-expansion-panel-header  [routerLink]="'/storage/shipper'">
                <mat-panel-title >
                  <img src="{{serverPathForUrl}}/portal/left-side-menu-icons/Storage-icon.svg" class="left_menu_icon">
                  <span class="munu_title fsn_4 fs_14">Storage</span>
                </mat-panel-title>
                <!-- <span class="badge custom_mess_badge fsm_5 fs_12">1</span> -->
            </mat-expansion-panel-header>
            <div class="lsp_submenu">
              <a [routerLinkActive]="'sub_active'" [routerLink]="'/storage/shipper'" [ngClass]="activeSubTabs =='Storage Network' ? 'sub_active' : ''" class="pointer left_sub_menu fsn_4 fs_14" (click)="activeSubMenu('Storage Network')">Storage Network</a>
              <a [routerLinkActive]="'sub_active'" [routerLink]="'/storage-order/list/orders'" [ngClass]="activeSubTabs =='Storage Order' ? 'sub_active' : ''" class="pointer left_sub_menu fsn_4 fs_14" (click)="activeSubMenu('Storage Order')" >Storage Order</a>
            </div>
          </mat-expansion-panel>
        </mat-accordion>
        <li *ngIf="userType==='STORAGE'" class="non_expention_panel pointer" [ngClass]="activeSubTabs =='Storage Network' ? 'active' : ''"  (click)="activeSubMenu('Storage Network')">
          <a class="main_menu_item" [routerLinkActive]="'sub_active'" [routerLink]="'/storage/shipper'">
            <span>
              <img src="{{serverPathForUrl}}/portal/images/after-login/Storage-icon.svg" class="left_menu_icon">
            </span>
            <span class="munu_title fsn_4 fs_14">Storage Order</span>
          </a>
        </li>
        <li *ngIf="userType==='CARRIER'" class="non_expention_panel pointer" [ngClass]="activeSubTabs =='Drivers' ? 'active' : ''"  (click)="activeSubMenu('Drivers')">
          <a class="main_menu_item" [routerLinkActive]="'sub_active'" [routerLink]="'/driver/list/All'">
            <span>
              <img src="{{serverPathForUrl}}/portal/left-side-menu-icons/Drivers-icon.svg" class="left_menu_icon">
            </span>
            <span class="munu_title fsn_4 fs_14">Drivers</span>
          </a>
        </li>
        <li class="non_expention_panel pointer" [ngClass]="activeSubTabs=='Messages' ? 'active' :''" (click)="activeSubMenu('Messages')">
          <a class="main_menu_item" [routerLinkActive]="'active'" [routerLink]="'/messages/active_shipment/All'">
            <span>
              <img src="{{serverPathForUrl}}/portal/left-side-menu-icons/Massages-icon.svg" class="left_menu_icon">
            </span>
            <span class="munu_title fsn_4 fs_14">Messages</span>
          </a>
        </li>

        <li class="non_expention_panel pointer" [ngClass]="activeSubTabs=='dispute' ? 'active' :''" (click)="activeSubMenu('dispute')">
          <a class="main_menu_item" [routerLinkActive]="'active'" [routerLink]="'/dispute/newDispute'">
            <span>
              <img src="{{serverPathForUrl}}/portal/left-side-menu-icons/Dispute-icon.svg" class="left_menu_icon">
            </span>
            <span class="munu_title fsn_4 fs_14">Dispute</span>
          </a>
        </li>
      </ul>
    </div>
    <div class="lsp_body_b">
      <ul class="lsp_list border-0 mb-0 pb-0">
        <li class="non_expention_panel pointer" [ngClass]="activeSubTabs=='tutorialGuides' ? 'active' :''" (click)="activeSubMenu('tutorialGuides')">
          <a class="main_menu_item" [routerLinkActive]="'active'" [routerLink]="'/tutorial/carrier/booking/create-a-lane'">
          <span>
            <img src="{{serverPathForUrl}}/portal/left-side-menu-icons/Tutorial-Guids-icon.svg" class="left_menu_icon">
          </span>
          <span class="munu_title fsn_4 fs_14">Tutorial Guides</span>
        </a>
        </li>
        <li >
          <mat-accordion>
            <mat-expansion-panel [expanded]="step ===5" (opened)="setStep(5)">
              <mat-expansion-panel-header [routerLinkActive]="'active'"  [routerLink]="userType=='SHIPPER' ? ['shipper/notification-control-panel/network'] : ['carrier/notification-control-panel/network']">
                    <mat-panel-title >
                      <img src="{{serverPathForUrl}}/portal/left-side-menu-icons/User-management-icon.svg" class="left_menu_icon">
                      <span class="munu_title fsn_4 fs_14">User Management</span>
                    </mat-panel-title>
                    <!-- <span class="badge custom_mess_badge fsm_5 fs_12"></span> -->
              </mat-expansion-panel-header>
                <div class="lsp_submenu pointer">
                  
                  <a  [routerLinkActive]="'sub_active'" [routerLink]="'/shipper/notification-control-panel/network'" [ngClass]="activeSubTabs =='notificationControl' ? 'sub_active' : ''" class="pointer left_sub_menu fsn_4 fs_14" (click)="activeSubMenu('notificationControl')" *ngIf="userType !=='STORAGE'">Notification Center</a>
                  <a  [routerLinkActive]="'sub_active'" [routerLink]="userType == 'SHIPPER' ? '/shipper/setting/change-password':'/carrier/setting/change-password'" [ngClass]="activeSubTabs =='settings' ? 'sub_active' : ''" class="pointer left_sub_menu fsn_4 fs_14" (click)="activeSubMenu('settings')">Settings</a>
                  <a  [routerLinkActive]="'sub_active'" [routerLink]="userType == 'SHIPPER' ? '/shipper/transaction/loads?orderDir=DESC':'/carrier/transaction/loads?tabIndex=0&orderDir=DESC'" [ngClass]="activeSubTabs =='transactions' ? 'sub_active' : ''" class="pointer left_sub_menu fsn_4 fs_14" (click)="activeSubMenu('transactions')">Transactions</a>
                  <a  [routerLinkActive]="'sub_active'" [routerLink]="'/carrier/referral/refer-carrier'" [ngClass]="activeSubTabs =='referralBenefits' ? 'sub_active' : ''" class="pointer left_sub_menu fsn_4 fs_14" (click)="activeSubMenu('referralBenefits')" *ngIf="userType==='CARRIER' " class="pointer left_sub_menu fsn_4 fs_14" >Referral Benefits</a>
                  <a [routerLinkActive]="'sub_active'" class="pointer left_sub_menu fsn_4 fs_14" (click)="openDialog('feedback')" class="pointer left_sub_menu fsn_4 fs_14" >Feedback</a>
                </div>
            </mat-expansion-panel>
          </mat-accordion>
        </li>
        <li>
          <mat-accordion>
            <mat-expansion-panel [expanded]="step ===6" (opened)="setStep(6)">
              <mat-expansion-panel-header [routerLinkActive]="'active'" [routerLink]="userType == 'SHIPPER' ? '/billing/address':'/billing/overview'" >
                    <mat-panel-title >
                      <img src="{{serverPathForUrl}}/portal/left-side-menu-icons/Account-management-icon.svg" class="left_menu_icon">
                      <span class="munu_title fsn_4 fs_14">Account Management</span>
                    </mat-panel-title>
                    <!-- <span class="badge custom_mess_badge fsm_5 fs_12"></span> -->
              </mat-expansion-panel-header>
                <div class="lsp_submenu pointer">
                  <a [routerLinkActive]="'sub_active'" [routerLink]="userType == 'SHIPPER' ? '/billing/address':'/billing/overview'"  [ngClass]="activeSubTabs =='billing' ? 'sub_active' : ''" class="pointer left_sub_menu fsn_4 fs_14" (click)="activeSubMenu('billing')">Billing</a>
                  <a [routerLinkActive]="'sub_active'" [routerLink]="userType == 'SHIPPER' ? '/shipper/subscription-overview':'/carrier/subscription-overview'" [ngClass]="activeSubTabs =='subscription' ? 'sub_active' : ''" class="pointer left_sub_menu fsn_4 fs_14" (click)="activeSubMenu('subscription')" *ngIf="userType !== 'STORAGE'">Subscription & Safety Credits</a>
                  <a  class="pointer left_sub_menu fsn_4 fs_14" href="https://laneaxis.com/get-in-touch/" target="_blank">Contact Support</a>
                </div>
            </mat-expansion-panel>
          </mat-accordion>
        </li>
        <li class="non_expention_panel pointer">
          <a class="main_menu_item" (click)="logoutUser()">
            <span>
              <img src="{{serverPathForUrl}}/portal/left-side-menu-icons/logout-icon.svg" class="left_menu_icon">
            </span>
            <span class="munu_title fsn_4 fs_14">Logout</span>
          </a>
        </li>
      </ul>
    </div>
  </div>
</div>