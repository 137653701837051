<div class="load_cards">
  <div class="" data-toggle="dropdown" style="position: relative;">
    <div class="dropdown_menu_list">
      <span class="three_dots">
        <div class="dropdown-menu" style="top: 25px !important; right: 0px !important; left: auto !important;">
        <span class="dropdown-item pointer" (click)="openNewTabRecord(shipmentRecord)">Open in new tab</span>
        <span class="dropdown-item pointer" (click)="editShipment($event,shipmentRecord?.id)"
          [class.disabled]="!checkEditShipmentCondition()">Edit</span>
      </div></span>
      
    </div>
  </div>
  <div >
    <div class="card_head_status">
      <span class="fs_14 fsm_5 txt_bf text-truncate pointer" matTooltip="{{shipmentRecord?.title}}">{{shipmentRecord?.title}}</span>
    </div>
    <div class="equment_chips d-flex align-items-center">
      <span class="fs_11 fsb_7 txt_p text-truncate">{{shipmentRecord?.equipmentName}}</span>
      <span class="fs_11 fsb_7 txt_p text-truncate">{{shipmentRecord?.miles + ' Miles'}}</span>
      <span class="status_label txt_w fs_11" *ngIf="shipmentRecord?.status === 59"
      style="background:#A9A9A9; border: none;">Expired</span>
    </div>
    <div class="pic_drop_lacations mb-2"  (click)="eventAction(shipmentRecord,'side_panel')">
      <div class="pic_lacation">
        
        <span class="fs_14 fsn_4 txt_b pic_location_name" [ngClass]="(userType=='CARRIER' || currentStatus=='expiredLoads' || currentStatus=='expiredLoadsByLane') ? 'pointer' : ''"><img src="\assets\icon\new-flag-card.svg"><span>{{shipmentRecord?.waypoints[0]?.city +", "+ shipmentRecord?.waypoints[0]?.state}}</span></span>
      </div>
      <div class="drop_lacation">
        
        <span class="fs_14 fsn_4 txt_b drop_lacation_name" [ngClass]="(userType=='CARRIER' || currentStatus=='expiredLoads' || currentStatus=='expiredLoadsByLane') ? 'pointer' : ''"><img src="\assets\icon\new-location-icon.svg"><span>{{shipmentRecord?.waypoints[1]?.city +", "+ shipmentRecord?.waypoints[1]?.state}}</span></span>
      </div>
    </div>
    <!-- shipper -->
    <div class="received_qoutes" *ngIf="(currentStatus === 'allLoads' || currentStatus=='quotedLoads' || currentStatus === 'allLoadsByLane' || currentStatus=='quotedLoadsByLane') && userType=='SHIPPER'">
      <span class="d-flex align-items-center w-100 justify-content-between">
  <span class="fs_14 fsm_5 txt_p" >Quotes Received</span><span class="fs_14 fsm_5 txt_b">{{shipmentRecord?.bidCount ?
    shipmentRecord?.bidCount : '0'}}</span>
      </span>
      <span *ngIf="(currentStatus=='quotedLoads' || currentStatus=='quotedLoadsByLane') && userType=='CARRIER'">
        <span class="fs_16 fsm_5 txt_p" >Current Quote/Rate: 
        </span> <span class="fs_14 fsm_5 txt_b">${{shipmentRecord?.bids?.bidAmount | twoDecimalPipe }}</span>
        </span>
        <span *ngIf="(currentStatus=='awardedLoads' || currentStatus=='loadsAwardedByLane' || currentStatus=='paymentPending') && userType=='CARRIER'">
          <span class="fs_16 fsm_5 txt_p" *ngIf="(currentStatus=='awardedLoads' || currentStatus=='loadsAwardedByLane')">Awarded : 
          </span>
          <span class="fs_16 fsm_5 txt_p txt_s" *ngIf="currentStatus=='paymentPending'">Confirm Rate : 
          </span>
              <span class="fs_14 fsm_5 txt_b">${{shipmentRecord?.bids?.bidAmount | twoDecimalPipe }}</span>
          </span>
          <span *ngIf="(currentStatus=='paymentPending') && userType=='SHIPPER'">
            <span class="fs_16 fsm_5 txt_p txt_s" >Confirmed Carriers : 
            </span>
                <span class="fs_14 fsm_5 txt_b">{{shipmentRecord?.bidCount ? shipmentRecord?.bidCount : '0'}}</span>
            </span>
    </div>

    <!-- carrier -->
    <div class="received_qoutes" *ngIf="(currentStatus=='quotedLoads' || currentStatus=='quotedLoadsByLane' || currentStatus=='loadsAwardedByLane' || currentStatus=='awardedLoads') && userType=='CARRIER'">
      <span *ngIf="(currentStatus=='quotedLoads' || currentStatus=='quotedLoadsByLane') && userType=='CARRIER'">
        <span class="fs_16 fsm_5 txt_p" *ngIf="(currentStatus=='quotedLoads' || currentStatus=='quotedLoadsByLane') && shipmentRecord?.bids?.status == 1">Current Quote/Rate: 
        </span>
        <span class="fs_16 fsm_5 txt_p" *ngIf="(currentStatus=='quotedLoads' || currentStatus=='quotedLoadsByLane') && !shipmentRecord?.bids">Current Quote/Rate: 
        </span>
        <span class="fs_16 fsm_5 txt_p"  *ngIf="(currentStatus=='quotedLoadsByLane') && shipmentRecord?.bids?.status == 4">Confirmed Quote/Rate: 
        </span>
            <span class="fs_14 fsm_5 txt_b">${{shipmentRecord?.bids?.bidAmount | twoDecimalPipe }}</span>
        </span>
        <span *ngIf="(currentStatus=='awardedLoads' || currentStatus=='loadsAwardedByLane' || currentStatus=='paymentPending') && userType=='CARRIER'">
          <span class="fs_16 fsm_5 txt_p" *ngIf="(currentStatus=='awardedLoads' || currentStatus=='loadsAwardedByLane')">Awarded : 
          </span>
          <span class="fs_16 fsm_5 txt_p txt_s" *ngIf="currentStatus=='paymentPending'">Confirm Rate : 
          </span>
              <span class="fs_14 fsm_5 txt_b">${{shipmentRecord?.bids?.bidAmount | twoDecimalPipe }}</span>
          </span>
          <span *ngIf="(currentStatus=='paymentPending') && userType=='SHIPPER'">
            <span class="fs_16 fsm_5 txt_p txt_s" >Confirmed Carriers : 
            </span>
                <span class="fs_14 fsm_5 txt_b">{{shipmentRecord?.bidCount ? shipmentRecord?.bidCount : '0'}}</span>
            </span>
    </div>
  </div>
  
  <div *ngIf="(currentStatus === 'allLoads' || currentStatus=='quotedLoads' || currentStatus === 'allLoadsByLane' || currentStatus=='quotedLoadsByLane') && userType=='SHIPPER'">
    <button class="fs_14 fsb_6 load_view_btn" [routerLink]="['./load/view',shipmentRecord?.id, 'loadBids']" >View Quotes</button>
  </div>
  <div class="bid_act_btn" *ngIf="(currentStatus === 'allLoads' || currentStatus=='quotedLoads' || currentStatus=='quotedLoadsByLane' || currentStatus === 'allLoadsByLane') && userType=='CARRIER'&& shipmentRecord?.statusSlug!=='delivered' && shipmentRecord?.statusSlug !=='assignToCarrier' &&  shipmentRecord?.statusSlug!=='completed'">
    <div *ngIf="userType ==='CARRIER' && shipmentRecord?.bids?.status == 1" class="view_btn me-1">
      <button (click)="eventAction(shipmentRecord,'rebid')">Re-Quote</button>
    </div>
    <div *ngIf="userType ==='CARRIER' && shipmentRecord?.bids?.status == 1 && shipmentRecord?.bids?.actionByShipper == 1" class="view_btn ms-1">
      <button (click)="eventAction(shipmentRecord,'confirm')" class="bg_green">Accept</button>
    </div>
    <div *ngIf="userType ==='CARRIER' && shipmentRecord?.bids?.status == 2 && shipmentRecord?.bids?.actionByShipper == 1" class="view_btn me-1">
      <button (click)="eventAction(shipmentRecord,'confirm')" class="view_btn">Confirm</button>
    </div>
    <div *ngIf="userType ==='CARRIER' && shipmentRecord?.bids?.status == 1 && shipmentRecord?.bids?.actionByShipper == 0" class="view_btn ms-1">
      <button class="bg_green adisabled">Accept</button>
    </div>
    <div *ngIf="currentStatus=='quotedLoadsByLane' && userType ==='CARRIER' && shipmentRecord?.bids?.status == 4">
     <p>Your avaialability has been sent to shipper, Avaiting shipper to confirm payment</p>
    </div>
</div>
  <div class="bid_b" *ngIf="(currentStatus === 'allLoads' || currentStatus === 'allLoadsByLane') && userType=='CARRIER' && !shipmentRecord?.bids?.status && shipmentRecord?.isOfferRate !== 'Y' && shipmentRecord?.statusSlug!=='delivered' && shipmentRecord?.statusSlug !=='assignToCarrier' && shipmentRecord?.statusSlug!=='completed' && shipmentRecord?.statusSlug!=='assign'">
    <button type="button" class="load_view_btn"
      (click)="eventAction(shipmentRecord,'bid')">Submit Your Quote</button>
  </div>

  <div class="bid_b" *ngIf="(currentStatus === 'quotedLoads' || currentStatus === 'quotedLoadsByLane') && userType=='CARRIER' && !shipmentRecord?.bids?.status && shipmentRecord?.isOfferRate !== 'Y' && shipmentRecord?.statusSlug!=='delivered' && shipmentRecord?.statusSlug !=='assignToCarrier' && shipmentRecord?.statusSlug!=='completed' && shipmentRecord?.statusSlug!=='assign'">
    <button type="button" class="load_view_btn"
      (click)="eventAction(shipmentRecord,'bid')">Submit Your Quote</button>
  </div>

  <div class="bid_b" *ngIf="(currentStatus === 'allLoads' || currentStatus === 'allLoadsByLane') && userType=='CARRIER' && shipmentRecord?.isOfferRate === 'Y' && !shipmentRecord?.bids?.status">
    <button type="button" class="load_view_btn"
      (click)="eventAction(shipmentRecord,'acceptOffer')">Accept</button>
  </div>

  <div *ngIf="(currentStatus === 'awardedLoads' || currentStatus=='loadsAwardedByLane' 
  || ((currentStatus=='allLoads' || currentStatus=='allLoadsByLane') && (shipmentRecord?.isOfferRate !== 'Y') &&(shipmentRecord?.statusSlug === 'assignToCarrier' || shipmentRecord?.statusSlug === 'completed' || shipmentRecord?.statusSlug === 'assign'))) && userType=='CARRIER'" class="text-center">
    <button class="fs_14 fsb_6 load_view_btn" (click)="viewBid(shipmentRecord?.id)">View Details</button>
  </div>


  <div class="bid_b" *ngIf="(currentStatus=='paymentPending' || currentStatus=='paymentPendingByLane') && userType=='SHIPPER'">
    <button type="button" class="load_view_btn"
    (click)="viewBid(shipmentRecord?.id)">Make Payment</button>
  </div>
  <div class="bid_b" *ngIf="(currentStatus=='paymentPending') && userType=='CARRIER'">
    <span class="fs_16 fsm_5">Waiting For the Shipper Payment</span>
  </div>
  <div class="bid_b text-center" *ngIf="(currentStatus === 'loadsLost' || currentStatus=='loadsLostByLane')">
    <span class="fs_16 fsm_5 txt_d">Bidding closed</span>
  </div>
</div>