import {Component,OnInit,Input,ElementRef,ViewChild,OnDestroy,AfterViewInit,Renderer2, EventEmitter, Output} from '@angular/core';
import { Location } from '@angular/common';
import { Router, NavigationEnd } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { CommonService } from '../commons/service/common.service';
import { AppSettings } from '../commons/setting/app_setting';
import { SharedService } from '../commons/service/shared.service';
import { AlertService } from '../commons/service/alert.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { FacebookPixelService } from '../commons/service/facebook-pixel.service';
import { ActivatedRoute } from "@angular/router";
import * as moment from 'moment';
import { EnterpricePopupComponent } from '../shipper/enterprice-popup/enterprice-popup.component';
import { SubscriptionPopupComponent } from '../shared/subscription-popup/subscription-popup.component';
import { DriverPopupComponent } from '../driver/driver-popup/driver-popup.component';
declare const $: any;
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-top-navbar',
  templateUrl: './top-navbar.component.html',
  styleUrls: ['./top-navbar.component.css'],
})
export class TopNavbarComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('toggleButton1', { static: false }) toggleButton1: ElementRef;
  @ViewChild('menu', { static: false }) menu: ElementRef;
  @Input() hideLeftMenu: any
  public proImage: any;
  @Output() outputFunction = new EventEmitter()
  public step: any;
  public contractDoc:any
  location: Location;
  public sidebutton: boolean;
  public userName: any;
  public serverPathForUrl: any;
  public params: any;
  public newparams: any;
  public recordGet: any;
  public currentRoute: any;
  public userDataForBanner: any;
  public userType: any;
  public emailVerified: any;
  public mxPhone: any;
  public phoneVerified: any;
  public userDetailForRedirect: any;
  public notificationList: any;
  public message: any;
  public totalNotificationCount: any;
  public notifcionsklitor = false;
  public isActive = false;
  public page: any;
  public totalPages: any;
  timeout: any;
  public totalUnreadTabs=0;
  sidebarVisible: any;
  public isCreateNewLoad = false;
  public isCreateNewLaneLoad = false;
  public isAddNewDriver = false;
  public spinnderLoader = false;
  public newnotificationList = [];
  public selectedTab = "";
  public routeSub: any;
  isMenuOpen = false;
  @Input() userNameUpdate: string;
  public cancelRequestCheck: any = null;
  public cancelRequestProfile: any = null;
  public selectedIndex = 'Booking';
  public allUnreadNotification=false;
  public allShipmentCounts: any;
  public allShipmentCountsForC: any;
  public allBookingCounts: any;
  public allBookingCountsForC: any;
  public userInfo: any;s
  public checkNavbar:any;
  public userAccessTokan:any;
  public logo:any;
  subscriptionData: any;
  userDetails: any;

  constructor(
    public dialog: MatDialog,
    location: Location,
    private router: Router,
    private route: ActivatedRoute,
    private commonService: CommonService,
    private alertService: AlertService,
    private renderer: Renderer2,
    public sharedService: SharedService,
    public facebookPixelService: FacebookPixelService,
    private deviceService: DeviceDetectorService,
  ) {
    this.location = location;
    this.sidebarVisible = false;
    this.sidebutton = false;
    this.renderer.listen('window', 'click', (e: Event) => {
      if (this.menu !== undefined && this.toggleButton1 !== undefined) {
        if (
          !this.toggleButton1.nativeElement.contains(e.target) &&
          !this.menu.nativeElement.contains(e.target)
        ) {
          this.isMenuOpen = false;
        }
      }
    });
  }

  ngOnInit() {   
    this.contractDoc = localStorage.getItem('contractDocument')
    this.userAccessTokan = localStorage.getItem('access_token');
    this.checkNavbar = localStorage.getItem('login');
    this.serverPathForUrl = environment.serverPathForUrl;
    this.logo = AppSettings.logo;
    this.markReadAlertNotification('');
    if(localStorage.getItem('user_type')){
      this.userType = localStorage.getItem('user_type');
    }else{
      if (this.deviceService.isMobile()){
        if(this.route && this.route.snapshot['_urlSegment']['children']['primary']['segments'][1]["path"]){
          let getUserType = this.route.snapshot['_urlSegment']['children']['primary']['segments'][1]["path"]
          if(getUserType=='carrier' || getUserType=='shipper'){
            this.userType = getUserType.toUpperCase()
            this.userType = localStorage.setItem('user_type',this.userType);
          }
        }
      } 
    }
    this.emailVerified = localStorage.getItem('email_verified');
    this.phoneVerified = localStorage.getItem('phone_verified');
      const isMobile = this.deviceService.isMobile();
        if (isMobile) {
        this.commonService.userData.subscribe((userData) => {
          this.userInfo = userData;
          if(localStorage.getItem('user_type') == 'CARRIER' && this.userInfo.subscription == true) {
            if(this.route && this.route.snapshot['_urlSegment']['children']['primary']['segments'][0]["path"]=='tutorial'){
              this.userType = localStorage.setItem('user_type','CARRIER');
            }else{
              this.router.navigate(['/dashboard/limit-mobile-view']);
            }
          } 
          if(localStorage.getItem('user_type') == 'SHIPPER' && this.userInfo.subscription == true){  
            if(this.route && this.route.snapshot['_urlSegment']['children']['primary']['segments'][0]["path"]=='tutorial'){
              this.userType = localStorage.setItem('user_type','SHIPPER');
            }else{
              this.router.navigate(['/dashboard/limit-mobile-view']);
            }
          }
          if(localStorage.getItem('user_type') == 'STORAGE'){
            this.router.navigate(['/dashboard/limit-mobile-view']);
          }
       });
    }
    
    if (this.userType === 'SHIPPER') {
      this.getSubscriptionAlert();

     let userDetail = this.sharedService.getAddress();
      if (userDetail !== undefined) {
        this.userInfoData(userDetail);
      }
    this.commonService.userData.subscribe((userData) => {
       this.userInfoData(userData);
     });
    }
    this.newparams = {
      limit: 10,
    };
    this.commonService.callToLogout.subscribe((userData) => {
      this.logoutUser();
    });
    this.sharedService.notifyEmailVerification.subscribe(() => {
      this.emailVerified = true;
    });
    this.commonService.allMarkRedBellnotiion.subscribe((userData) => {
      this.totalNotificationCount = '';
    });
    this.commonService.sendTokanUpdate.subscribe((userData) => {
      this.userType = localStorage.getItem('user_type');
      this.userName = null;
      this.proImage = null;

      if (localStorage.getItem('user_type') === 'CARRIER') {
      
        this.recordGet = AppSettings.APIsNameArray.CARRIER.GET;
      }
      if (localStorage.getItem('user_type') === 'SHIPPER') {
        this.recordGet = AppSettings.APIsNameArray.SHIPPER.GET;
      }
      if (localStorage.getItem('user_type') === 'STORAGE') {
        this.recordGet = AppSettings.APIsNameArray.COLDSTORAGE.GET;
      }
      this.getProfileCheck();
    });
    this.commonService.profileUpdate.subscribe((userData) => {
      this.getProfileCheck();

    });
    this.commonService.bellNotificationUpdate.subscribe((userData) => {
      if(localStorage.getItem('login') === 'after_login' && !this.hideLeftMenu){
        this.notifictionList(this.selectedTab);
        this.topBell();
      }
    });
    if(localStorage.getItem('login') === 'after_login' && !this.hideLeftMenu){
      this.topBell();
    }
    if (localStorage.getItem('login') === 'after_login' && localStorage.getItem('access_token') && localStorage.getItem('stripeConenct') !== 'active' && (localStorage.getItem('user_type') === 'CARRIER' || localStorage.getItem('user_type') === 'STORAGE')) {
      this.checkSubStatusConnect();
    } else if (localStorage.getItem('tag') === 'GTFO' && localStorage.getItem('user_type') === 'SHIPPER') {
      this.checkSubStatusConnect();
    }
    this.checkUserMenuProfile();
    if (this.router) {
      this.getProfileCheck();
      let route = this.router.url.split('/');
    
      let newRoute;
      if (this.userType === 'STORAGE') {
        if (route[2]) {
          newRoute = route[2].split('?');
          this.currentRoute = newRoute[0];
        } else {
          newRoute = route[1].split('?');
          this.currentRoute = newRoute[0];
        }
      } else {
        if (route[1]) {
          newRoute = route[1].split('?');
          this.currentRoute = newRoute[0];
        }
      }
      localStorage.setItem('currentRouteUrl', this.router.url);
      if (localStorage.getItem('login') === 'after_login' && localStorage.getItem('stripeConenct') !== 'active' && localStorage.getItem('access_token') && (localStorage.getItem('user_type') === 'CARRIER' || localStorage.getItem('user_type') === 'STORAGE')) {
        this.checkSubStatusConnect();
      } else if (localStorage.getItem('tag') === 'GTFO' && localStorage.getItem('user_type') === 'SHIPPER') {
        this.checkSubStatusConnect();
      }
    }
    this.routeSub = this.router.events.subscribe((event) => {
      if (event) {
        if (event instanceof NavigationEnd) {
          let route = event.url.split('/');
          let newRoute;
          if (this.userType === 'STORAGE') {
            if (route[1] == 'storage-order') {
              newRoute = route[1];
              this.currentRoute = newRoute;
            } else if (route[2]) {
              newRoute = route[2].split('?');
              this.currentRoute = newRoute[0];
            } else {
              newRoute = route[1].split('?');
              this.currentRoute = newRoute[0];
            }
          } else {
            if(route[2]=='profile'){
              newRoute = route[2].split('?');
              this.currentRoute = newRoute[0];
            }else{
              newRoute = route[1].split('?');
              this.currentRoute = newRoute[0];
            }
          }
          this.isActive = false;
          localStorage.setItem('currentRouteUrl', this.router.url);
          if (localStorage.getItem('login') === 'after_login' && localStorage.getItem('stripeConenct') !== 'active' && localStorage.getItem('access_token') && (localStorage.getItem('user_type') === 'CARRIER' || localStorage.getItem('user_type') === 'STORAGE')) {
            this.checkSubStatusConnect();
          } else if (localStorage.getItem('tag') === 'GTFO' && localStorage.getItem('user_type') === 'SHIPPER') {
            this.checkSubStatusConnect();
          }
          if (event.url.includes('/recurring-lanes/create-lanes')) {
            this.isCreateNewLaneLoad = true;
          } else {
            this.isCreateNewLaneLoad = false;
          }
          if (event.url.includes('spot-rate-loads') || event.url.includes('load-created-by-me')) {
            this.isCreateNewLoad = true;
            this.isAddNewDriver = false;
          }
          else if( event.url.includes('driver/list')) {
            this.isAddNewDriver = true;
            this.isCreateNewLoad = false;
          }
          else {
            this.isAddNewDriver = false;
            this.isCreateNewLoad = false;
          }
        }
      }
    });
  }

  ngAfterViewInit() {
    if(localStorage.getItem('login') === 'after_login' && !this.hideLeftMenu){
      this.notifictionList(this.selectedTab);
    }
  }

  changeFirebaseToken() {
    this.sharedService.changeFirebaseToken.next(null);
  }

    // Function to get user details 
   userInfoData(userDtail) {
    this.userDetails = userDtail;
  }

  toggleMenu() {
    this.userDetailForRedirect = this.sharedService.getAddress();
    if ((localStorage.getItem('user_type') === 'SHIPPER') && (this.userDetailForRedirect?.phyAddressLine1 && this.userDetailForRedirect?.phyCity && this.userDetailForRedirect?.phyState && this.userDetailForRedirect?.phyZip && this.userDetailForRedirect?.equipmentType
      && this.userDetailForRedirect?.preferredRegions && this.userDetailForRedirect?.signatureFont && this.userDetailForRedirect?.profileImage && this.userDetailForRedirect?.contractDocument)) 
    {
       this.isMenuOpen = !this.isMenuOpen;
       this.selectedTab = "bookings";
       if (this.isMenuOpen) {
         this.notifictionList(this.selectedTab);
       }
    } 

    if ((localStorage.getItem('user_type') === 'CARRIER') && (this.userDetailForRedirect?.phyAddressLine1 && this.userDetailForRedirect?.phyCity && this.userDetailForRedirect?.phyState && this.userDetailForRedirect?.phyZip && this.userDetailForRedirect?.equipmentType
      && this.userDetailForRedirect?.preferredRegions && this.userDetailForRedirect?.signatureFont && this.userDetailForRedirect?.profileImage && this.userDetailForRedirect?.coverImage && this.userDetailForRedirect?.insuranceAmount && this.userDetailForRedirect?.insuranceCompanyName && this.userDetailForRedirect?.insuranceCompanyEmail))
    {
      this.isMenuOpen = !this.isMenuOpen;
      this.selectedTab = "bookings";
      if (this.isMenuOpen) {
        this.notifictionList(this.selectedTab);
      }
    } 
    if ((localStorage.getItem('user_type') === 'CARRIER') && this.userDetailForRedirect?.subscription === null){
      this.isMenuOpen = false;
    }
    if ((localStorage.getItem('user_type') === 'SHIPPER') && this.userDetailForRedirect?.subscription === null){
      this.isMenuOpen = false;
    }
    if((localStorage.getItem('user_type') === 'STORAGE') ){
      this.isMenuOpen = !this.isMenuOpen;
      this.selectedTab = "bookings";
      if(this.isMenuOpen) {
      this.notifictionList(this.selectedTab);
       }
    }
  }

  generateFake(count: number): Array<number> {
    const indexes = [];
    for (let i = 0; i < count; i++) {
      indexes.push(i);
    }
    return indexes;
  }

  ngOnDestroy() {
    if (this.routeSub) {
      this.routeSub.unsubscribe();
    }
    clearTimeout(this.timeout);
  }

  checkUserMenuProfile() {
    this.userType = localStorage.getItem('user_type');
    if (localStorage.getItem('user_type') === 'CARRIER') {
      this.recordGet = AppSettings.APIsNameArray.CARRIER_REGISTRATION.GET;
      if (!localStorage.getItem('userName')) {
        this.getProfileCheck();
      } else {
        this.userName = localStorage.getItem('userName');
        this.proImage = localStorage.getItem('userProfileImage');
      }
    } else if (localStorage.getItem('user_type') === 'SHIPPER') {
      this.recordGet = AppSettings.APIsNameArray.SHIPPER.GET;
      if (!localStorage.getItem('userName')) {
        this.getProfileCheck();
      } else {
        this.userName = localStorage.getItem('userName');
        this.proImage = localStorage.getItem('userProfileImage');
      }
      
    } else if (localStorage.getItem('user_type') === 'STORAGE') {
      this.recordGet = AppSettings.APIsNameArray.COLDSTORAGE.GET;
      if (!localStorage.getItem('userName')) {
        this.getProfileCheck();
      } else {
        this.userName = localStorage.getItem('userName');
        this.proImage = localStorage.getItem('userProfileImage');
      }
    }
  }

  markReadAlertNotification(value: any) {
    let carrier = localStorage.getItem('user_type') === 'CARRIER';
    let shipper = localStorage.getItem('user_type') === 'SHIPPER';
    if(localStorage.getItem('user_type')=='CARRIER' || localStorage.getItem('user_type')=='SHIPPER'){
    this.commonService.putAlertNotificationOnRead(value).subscribe((data) => {
      if (data.response) {
        let allNetworkCount = data.response.connected + data.response.invite; 
        this.sharedService.AlertNotifyService.next({ name: 'network', counts: allNetworkCount });
        if (shipper) {
          this.allBookingCounts = data.response.currentBid + data.response.confirmedBids + data.response.offerRateConfirmed;
          this.allShipmentCounts = data.response.assign + data.response.assignToCarrier + data.response.create + data.response.accept + data.response.navigateToPickup + data.response.delivered + data.response.cancel + data.response.dispute +  data.response.expired;
          this.sharedService.AlertNotifyService.next({ name: 'bidding', counts: this.allBookingCounts });
          this.sharedService.AlertNotifyService.next({ name: 'shipment', counts: this.allShipmentCounts });
        } else if (carrier) {
          this.allBookingCountsForC = data.response.currentBid + data.response.awardedBids + data.response.offerRateConfirmed + data.response.newPrivateShipment + data.response.newOfferRate;
          this.allShipmentCountsForC = data.response.assign + data.response.assignToCarrier + data.response.create + data.response.accept + data.response.navigateToPickup + data.response.delivered + data.response.cancel + data.response.dispute + data.response.expired;
          this.sharedService.AlertNotifyService.next({ name: 'bidding', counts: this.allBookingCountsForC });
          this.sharedService.AlertNotifyService.next({ name: 'shipment', counts: this.allShipmentCountsForC });
        }
      } 
    });
   }
  }

  logoutUser() {
    if (localStorage.getItem('uniqueId') !== null && localStorage.getItem('fcmToken') !== null) {
      this.deleteDeviceTokan();
    }
    this.userNameUpdate = '';
    this.userName = '';
    this.userType = '';
    localStorage.clear();
    sessionStorage.clear();
    this.outputFunction.emit('leftLogiut');
    this.router.navigateByUrl('/');
    this.sharedService.configLoginCallUrl.next(null);
    this.sharedService.setAddress(null);
    localStorage.setItem('login', 'before_login');
    const body = document.getElementsByTagName('body')[0];
    body.classList.add('before_login_user');
    body.classList.remove('after_login_user');
  }

  deleteDeviceTokan() {
    let token;
    if (localStorage.getItem('lastfcmToken')) {
      token = localStorage.getItem('lastfcmToken');
    } else {
      token = localStorage.getItem('fcmToken');
    }
    if (token) {
      let value = {
        deviceId: localStorage.getItem('uniqueId'),
        token: localStorage.getItem('fcmToken'),
      };
      let APIparams = {
        apiKey: AppSettings.APIsNameArray.NOTIFICATION.DEVICETOKENDELETE,
        uri: '',
        postBody: value,
      };
      this.commonService.post(APIparams).subscribe(
        (success) => {
          if (success.success === true) {
          } else if (success.success === false) {
            this.alertService.showNotificationMessage(
              'danger',
              'bottom',
              'right',
              'txt_d',
              'cancel',
              'Remove Tokan',
              success.message
            );
          }
        },
        (error) => {
          this.alertService.showNotificationMessage(
            'danger',
            'bottom',
            'right',
            'txt_g',
            'error',
            'Unexpected Error',
            AppSettings.ERROR
          );
        }
      );
    }
  }

  /*get Driver name */
  checkSubStatusConnect() {
    this.checkSubStatus();
  }

  checkSubStatus(params = {}) {
    let uri = null;
    uri = this.commonService.getAPIUriFromParams({});
    let APIparams = {
      apiKey: AppSettings.APIsNameArray.NEWPAYMENT.CHECKSTATUS,
      uri: uri,
    };
    this.cancelRequestCheck = this.commonService
      .getList(APIparams)
      .subscribe((ServerRes) => {
        if (ServerRes.success === true) {
          this.cancelRequestCheck = null;
          if (localStorage.getItem('user_type') === 'CARRIER' || localStorage.getItem('user_type') === 'STORAGE') {
            if (ServerRes.response.connectAccountStatus === true) {
              localStorage.setItem('stripeConenct', 'active');
            } else if (ServerRes.response.connectAccountStatus === false) {
              localStorage.setItem('stripeConenct', 'Pending');
            } else {
              localStorage.setItem('stripeConenct', '');
            }
          } else if (localStorage.getItem('user_type') === 'SHIPPER' && localStorage.getItem('tag') === 'GTFO') {
            let numberOfDay: any = moment.unix(ServerRes.response.current_period_end);
            let getNewDate = moment(new Date(numberOfDay)).format('YYYY-MM-DD HH:mm');
            localStorage.setItem('checkDate', getNewDate);
            if (ServerRes.response.subscriptionStatus === 'active') {
              localStorage.setItem('premium', '1');
            } else {
              localStorage.setItem('premium', '0');
              this.logoutUser();
            }
          }
          this.commonService.updateConnectAccount.next(ServerRes.response);
        }
      });
  }

   // Popup for send invite to a new driver
   driverSendInvite() {
    const dialogRef = this.dialog.open(DriverPopupComponent, {
      disableClose: true,
      backdropClass: AppSettings.backdropClass,
      width: AppSettings.popWidth,
      data: { openPop: 'driverSendInviteSuccess' },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result.event === 'Ok') {
        this.openInvitedSuccess(result.driverName, result.inviteDriverId);
      }
      if (result.event === 'reInvite') {
        this.openAssociatSuccess(result.driverName);
      }
    });
  }

    // Driver invite success popup
    openInvitedSuccess(driverName: any, inviteDriverId: any) {
      const dialogRef = this.dialog.open(DriverPopupComponent, {
        disableClose: true,
        backdropClass: AppSettings.backdropClass,
        width: AppSettings.popWidth,
        data: {
          openPop: 'InvtedSuccess',
          driverName: driverName,
          inviteDriverId: inviteDriverId,
        },
      });
      dialogRef.afterClosed().subscribe((result) => {
        if (result.event === 'OK') {
          this.sharedService.updateDriverList.next(null);
        }
      });
    }

     // Popup for Driver reinvite  
  openAssociatSuccess(driverName: any) {
    const dialogRef = this.dialog.open(DriverPopupComponent, {
      disableClose: true,
      backdropClass: AppSettings.backdropClass,
      width: AppSettings.popWidth,
      data: { openPop: 'InvtedAssociateSuccess', driverName: driverName },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result.event === 'OK') {
        this.sharedService.updateDriverList.next(null);
      }
    });
  }

  getProfileCheck() {
    this.getProfile();
  }

  createLane(){
    if (this.userType == 'SHIPPER' && (this.subscriptionData?.isPremium != true || this.subscriptionData?.subscriptionStatus == 'canceled'))
    {
       this.getSubscriptionAlertPopup();
    } else if (this.userType == 'SHIPPER' && this.subscriptionData.subscriptionCreditHistory.shipperShipment == 0) {
       this.enterPricePlan();
    } else {
      this.router.navigate(['recurring-lanes/create/shipper']);
    }
  }

  confirmLaneList(){
    if (this.userType == 'SHIPPER' && (this.subscriptionData?.isPremium != true || this.subscriptionData?.subscriptionStatus == 'canceled'))
     {
       this.getSubscriptionAlertPopup();
     } else if (this.userType == 'SHIPPER' && this.subscriptionData.subscriptionCreditHistory.shipperShipment == 0) {
        this.enterPricePlan();
     } else {
       this.router.navigateByUrl('recurring-lanes/confirm-list');
     }
  }

  /*get Driver name */
  async getProfile(params = {}) {
    this.sharedService.setAddress(null);
    let uri = '';
    if (this.params) uri = this.commonService.getAPIUriFromParams(this.params);
    let APIparams = {
      apiKey: this.recordGet,
      uri: uri,
    };
    this.cancelRequestProfile = this.commonService
      .getList(APIparams)
      .subscribe((ServerRes) => {
        this.sharedService.setAddress(null);
        this.commonService.afteLoginUpdateLoader.next(null);
        if (ServerRes.success === true) {
          this.cancelRequestProfile = null;
          this.userDataForBanner = ServerRes.response;
          this.sharedService.setAddress(ServerRes.response);
          if (
            ServerRes.response &&
            ServerRes.response.phyCity &&
            ServerRes.response.phyState
          ) {
            const SourceLocation =
              ServerRes.response.phyCity + ',' + ServerRes.response.phyState;
            this.sharedService.setUserLocationType(SourceLocation);
          }
          this.commonService.userData.next(ServerRes.response);
          if (
            localStorage.getItem('user_type') === 'CARRIER' ||
            localStorage.getItem('user_type') === 'STORAGE'
          ) {
            localStorage.setItem('email', ServerRes.response.email);
            localStorage.setItem('isMLWallet', ServerRes.response.isMLWallet);
            localStorage.setItem('lane', ServerRes.response.lane);
            localStorage.setItem(
              'equipmentType',
              ServerRes.response.equipmentType
            );
            localStorage.setItem('industries', ServerRes.response.industries);
          }

          if (localStorage.getItem('user_type') === 'CARRIER') {
            localStorage.setItem('userName', ServerRes.response.legalName);
            if (ServerRes.response.coiDoc && !ServerRes.response.coiExpired) {
              localStorage.setItem(
                'certificateOfInsurance',
                ServerRes.response.coiDoc
              );
            } else if (
              (ServerRes.response.coiDoc && ServerRes.response.coiExpired) ||
              (!ServerRes.response.coiDoc && ServerRes.response.coiExpired)
            ) {
              localStorage.setItem('certificateOfInsurance', 'null');
            }
            this.userName = localStorage.getItem('userName');
          }
          if (localStorage.getItem('user_type') === 'SHIPPER') {
            localStorage.setItem('userName', ServerRes.response.companyName);
            localStorage.setItem(
              'contractDocument',
              ServerRes.response.contractDocument
            );
            this.userName = localStorage.getItem('userName');
          }
          if (localStorage.getItem('user_type') === 'STORAGE') {
            localStorage.setItem('userName', ServerRes.response.companyName);
            this.userName = localStorage.getItem('userName');
          }
          if (ServerRes.response.profileImage) {
            localStorage.setItem(
              'userProfileImage',
              ServerRes.response.profileImage
            );
            this.proImage = localStorage.getItem('userProfileImage');
          }
        }
      });
  }

  clickRefresh() {
    this.notifictionList(this.selectedTab);
  }

  onScroll() {
    if (this.page !== this.totalPages) {
      this.page = this.page + 1;
      this.spinnderLoader = true;
      this.getMoreNotification();
    }
  }

    // Navigate to create shipment 
    createShipmentCheck(){
      if (this.userType == 'SHIPPER' && (this.subscriptionData?.isPremium != true || this.subscriptionData?.subscriptionStatus == 'canceled'))
      {
        this.getSubscriptionAlertPopup();
      } else if (this.userType == 'SHIPPER' && this.subscriptionData.subscriptionCreditHistory.shipperShipment == 0) {
        this.enterPricePlan();
      } 
      else {
      if (this.userType === 'CARRIER'){
        this.router.navigate(['/load/create/carrier']);
      }
      if(this.userType === 'SHIPPER'){
        this.router.navigate(['/load/create/shipper']);
      } 
     }
    }

    getSubscriptionAlertPopup(){
      const dialogRef = this.dialog.open(SubscriptionPopupComponent, {
        disableClose: true,
        backdropClass: AppSettings.backdropClass,
        width: AppSettings.popWidth,
        data: { 
          openPop: 'subscriptionAlert',
          subscriptionData: this.subscriptionData
         },
      });
      dialogRef.afterClosed().subscribe((result) => {
      });
    }

  getAPIParamNoti() {
    let APIparams, params;
    params = { limit: 10, page: this.page, tabType: this.selectedTab };
    APIparams = {
      apiKey: AppSettings.APIsNameArray.NOTIFICATION.BEllNOTIFICATION,
      uri: this.commonService.getAPIUriFromParams(params),
    };
    return APIparams;
  }

  getAPIParamNotify() {
    let APIparams, params;
    params = { limit: 10, page: this.page };
    APIparams = {
      apiKey: AppSettings.APIsNameArray.NOTIFICATION.BEllNOTIFICATION,
      uri: this.commonService.getAPIUriFromParams(params),
    };
    return APIparams;
  }

  topBell() {
    this.notifcionsklitor = false;
    this.page = 1;
    var APIparams = this.getAPIParamNotify();
    this.newnotificationList = []
    this.commonService.getList(APIparams).subscribe(
      (ServerRes) => {
        this.notifcionsklitor = true;
        if (
          ServerRes.response?.bellNotifications &&
          ServerRes.response?.bellNotifications?.length > 0
        ) {
          for (let notiIndex in ServerRes.response.bellNotifications) {
            ServerRes.response.bellNotifications[notiIndex].createdAt =
              new Date(
                ServerRes.response.bellNotifications[notiIndex].createdAt +' ' + 'UTC');
          }
          this.newnotificationList = ServerRes.response.bellNotifications;
          this.totalNotificationCount = ServerRes.response.unreadNotifications;
          this.totalUnreadTabs=ServerRes.response.unreadTabs
          this.allUnreadNotification =  Object.values(ServerRes.response.unreadTabs).includes(1)
          this.totalPages = ServerRes.response.totalPages;
        } else {
          this.notificationList = [];
          this.totalNotificationCount = 0;
          this.notifcionsklitor = true;
        }
      },
      (error) => {
        this.notificationList = [];
        this.totalNotificationCount = 0;
        this.notifcionsklitor = true;
      }
    );
  };

   notifictionList(tabType: any) {
    if (tabType.length < 10) {
      this.selectedTab = tabType.toLowerCase();
      this.notifcionsklitor = false;
      this.page = 1;
      var APIparams = this.getAPIParamNoti();
      this.newnotificationList = []
      this.commonService.getList(APIparams).subscribe(
        (ServerRes) => {
          this.allUnreadNotification =  Object.values(ServerRes.response.unreadTabs).includes(1)
          this.totalUnreadTabs=ServerRes.response.unreadTabs
          this.notifcionsklitor = true;
          if (
            ServerRes.response?.bellNotifications &&
            ServerRes.response?.bellNotifications?.length > 0
          ) {
            for (let notiIndex in ServerRes.response.bellNotifications) {
              ServerRes.response.bellNotifications[notiIndex].createdAt =
                new Date(
                  ServerRes.response.bellNotifications[notiIndex].createdAt +' ' +'UTC');
            }
            this.newnotificationList = ServerRes.response.bellNotifications;
            this.totalPages = ServerRes.response.totalPages;
            this.totalNotificationCount = ServerRes.response.unreadNotifications;

            var obj = {
              eventName: 'refresh_notification',
              event_category: 'bellNotification',
              userType: localStorage.getItem('user_type'),
              EmailID: localStorage.getItem('email'),
            };
              this.sharedService.track(obj);
          } else {
            this.notificationList = [];
            this.totalNotificationCount = 0;
            this.notifcionsklitor = true;
          }
        },
        (error) => {
          this.notificationList = [];
          this.totalNotificationCount = 0;
          this.notifcionsklitor = true;
        }
      );
    }
  }

  getMoreNotification() {
    var APIparams = this.getAPIParamNoti();
    this.commonService.getList(APIparams).subscribe((ServerRes) => {
      if (
        ServerRes.response.bellNotifications &&
        ServerRes.response.bellNotifications.length > 0
      ) {
        this.spinnderLoader = false;
        for (let v = 0; v < ServerRes.response.bellNotifications.length; v++) {
          if (ServerRes.response.bellNotifications[v])
            this.newnotificationList.push(
              ServerRes.response.bellNotifications[v]
            );
        }
      }
    });
  }

  notificationAction(id, userId, status) {
    if (this.userType == 'SHIPPER' && status == 'CONNECT' && this.subscriptionData.subscriptionCreditHistory.shipperConnection == 0) {
      this.enterPricePlan();
    } else {
    var APIparams = {
      apiKey: AppSettings.APIsNameArray.MANAGE_CONNECTION[status],
      uri: '',
      postBody: {
        userPkId: userId,
        bellId: id,
      },
    };
    this.commonService.post(APIparams).subscribe((ServerRes) => {
      if (ServerRes.success === true) {
        for (let notiIndex in this.newnotificationList) {
          if (
            this.newnotificationList[notiIndex].id &&
            this.newnotificationList[notiIndex].id === id
          ) {
            this.newnotificationList[notiIndex].data.actionTaken = 0;
          }
        }
        this.markReadAlertNotification('');
        if (status === 'CONNECT') {
          this.callGoogleAnalytics(userId);
          this.sharedService.NetworkNotifyService.next("Accepted");
          this.clickRefresh();
          this.alertService.showNotificationMessage(
            'success',
            'bottom',
            'right',
            'txt_s',
            'check_circle',
            'Notification',
            ServerRes.message,
            ServerRes.isInvitationReverted
          );
        } else {
          this.alertService.showNotificationMessage(
            'success',
            'bottom',
            'right',
            'txt_s',
            'check_circle',
            'Notification',
            ServerRes.message,
            ServerRes.isInvitationReverted
          );
        }
      } else {
        this.alertService.showNotificationMessage(
          'danger',
          'bottom',
          'right',
          'txt_d',
          'cancel',
          'Notification',
          ServerRes.message,
          ServerRes.isInvitationReverted
        );
      }
    });
   }
  }

  enterPricePlan(){
    if (this.userDetails && this.userDetails.isEnterprisePlanRequested) {
      const dialogRef = this.dialog.open(EnterpricePopupComponent, {
        disableClose: true,
        backdropClass: AppSettings.backdropClass,
        width: AppSettings.popWidth,
        data: { openPop: 'enterprisePlanRequestedPopup'},
      });
      dialogRef.afterClosed().subscribe((result) => {
        if(result?.event=='Ok'){
        }
      });
    } 
    else { 
    if(this.userDetails.phone?.slice(0,3)=='+52' || this.userDetails.phone?.slice(0,3)=='+91'){
      this.mxPhone=this.userDetails.phone?.slice(4,14)
    }
    else if(this.userDetails.phone?.slice(0,2)=='+1'){
      this.mxPhone=this.userDetails.phone?.slice(3,13)
    }
    const dialogRef = this.dialog.open(EnterpricePopupComponent, {
      disableClose: true,
      backdropClass: AppSettings.backdropClass,
      width:'500px',
      height:'calc(100vh - 100px)',
      data: { openPop: 'enterPrice', userData:this.userDetails,fullName:this.userDetails.firstName + ' ' + this.userDetails.lastName,companyName:this.userDetails.companyName,email:this.userDetails.email,phone:this.mxPhone,countryCode:this.userDetails.countryCode,ftlVolume:this.userDetails.ftlVolume },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if(result?.event=='Ok'){
        this.getShipperRecord();
      }
    });
   }
  }

  // Functions to get subscription data
   getSubscriptionAlert() {
     this.commonService.getSubscriptionAlert().subscribe((serverRes) => {
       this.subscriptionData =  serverRes.response;
     });
   }

  // Get shipper details from API
   getShipperRecord() {
     let uri = null;
     let newParams = {};
     //get dynamic URI for APIs
     if (newParams) uri = this.commonService.getAPIUriFromParams(newParams);
     let APIparams = {
       apiKey: AppSettings.APIsNameArray.SHIPPER.GET,
       uri: uri,
     };
     this.commonService.getList(APIparams).subscribe((ServerRes) => {
       if (ServerRes.success === true) {
         this.userDetails = ServerRes.response;
       } 
     });
   }

  markAllRed() {
    this.notifcionsklitor = false;
    let uri = null;
    uri = this.commonService.getAPIUriFromParams({});
    let APIparams = {
      apiKey: AppSettings.APIsNameArray.NOTIFICATION.ALLMARKREAD,
      postBody: { 'tabType': this.selectedTab.toLowerCase() },
      uri: uri,
    };
    this.commonService.put(APIparams).subscribe(
      (ServerRes) => {
        if (ServerRes.success === true) {
          this.totalNotificationCount = '';
          for (let notiIndex in this.newnotificationList) {
            this.newnotificationList[notiIndex].isRead = 1;
          }
          this.totalNotificationCount = '';
          this.notifcionsklitor = true;
          var obj = {
            eventName: 'mark_all_read',
            event_category: 'bellNotification',
            userType: localStorage.getItem('user_type'),
            EmailID: localStorage.getItem('email'),
          };
          this.sharedService.track(obj);
          this.notifictionList(this.selectedTab);
        } else {
          this.notifcionsklitor = true;
        }
      },
      (error) => {
        this.notifcionsklitor = true;
      }
    );
  }

  readFun(Id: any, isRead: any, urlRedirect: any) {
    if (urlRedirect === '/carrier/transaction/shipments') {
      this.router.navigate(['/carrier/transaction/shipments'], {queryParams: {
        tabIndex: "0",
        orderDir: "DESC"
      }});
    } else if (urlRedirect === '/carrier/transaction/buy-loads') {
      this.router.navigate(['/carrier/transaction/buy-loads'], {queryParams: {
        orderDir: "DESC",
        tabIndex: "1"
      }});
    } else if (urlRedirect === '/carrier/transaction/subscription') {
      this.router.navigate(['/carrier/transaction/subscription'], {queryParams: {
        orderDir: "DESC",
        tabIndex: "2"
      }});
    } else if (urlRedirect === '/carrier/transaction/additional-credit') {
      this.router.navigate(['/carrier/transaction/additional-credit'], {queryParams: {
        orderDir: "DESC",
        tabIndex: "3"
      }});
    } else {
       this.router.navigate([urlRedirect]);
    }
    this.isMenuOpen = false;
    if (isRead === 0) {
      this.isActive = false;
      let postBody = {
        notiId: Id,
      };
      let APIparams = {
        apiKey: AppSettings.APIsNameArray.NOTIFICATION.NOTIREAD,
        uri: '',
        postBody: postBody,
      };
      this.commonService.put(APIparams).subscribe((success) => {
        if (success.success === true) {
          this.notifictionList(this.selectedTab);
        } else if (success.success === false) {
        }
      });
    }
  }

  callGoogleAnalytics(userId) {
    this.params = {
      id: userId,
    };
    const uri = this.commonService.getAPIUriFromParams(this.params);
    let APIparams = {
      apiKey: AppSettings.APIsNameArray.MANAGE_CONNECTION.GETPROFILE,
      uri: uri,
    };
    this.commonService.getList(APIparams).subscribe((ServerRes) => {
      if (ServerRes.success === true) {
        let targetLocation = '-';
        if (
          ServerRes.response &&
          ServerRes.response.phyCity &&
          ServerRes.response.phyState
        ) {
          targetLocation =
            ServerRes.response.phyCity + ',' + ServerRes.response.phyState;
        }
        let name;
        if (ServerRes.response.companyName) {
          name = ServerRes.response.companyName;
        }
        if (ServerRes.response.legalName) {
          name = ServerRes.response.legalName;
        }
        var obj = {
          eventName: 'network_accept_request',
          event_category: 'Network',
          Occurence: 'bell_notification',
          userType: localStorage.getItem('user_type'),
          EmailID: localStorage.getItem('email'),
          dotNumber: ServerRes.response.dotNumber
            ? ServerRes.response.dotNumber
            : '-',
          TargetID: name,
          TargetLocation: targetLocation,
          SourceLocation: this.sharedService.getUserLocationType(),
        };
        this.sharedService.track(obj);
      }
    });
  }

  naviageToProfile(profile){
    if(this.userType=='SHIPPER'){
      this.router.navigate(['/shipper/profile']);
      this.outputFunction.emit(this.step);
    }
    else if(this.userType=='CARRIER'){
      this.router.navigate(['/carrier/profile']);
      this.outputFunction.emit(profile);
    }
   }

  }
