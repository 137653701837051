import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'twoDecimalPipe',
  pure: true,
})
export class twoDigitDecimalPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    if (value) {
      if (value % 1 < 1 && value % 1 > 0) {
        let result = parseFloat(value);
        return result.toFixed(2);
      } else {
        return value;
      }
    } else return '0';
  }
}
