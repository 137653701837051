import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'phonecode',
  pure: true,
})
export class PhoneMultipleCountryPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    if (value) {
      let firstNum = value.slice(0, 3);
      let middleNum = value.slice(3, 6);
      let lastNum = value.slice(6, 10);

      return firstNum + '-' + middleNum + '-' + lastNum;
    } else {
      return '-';
    }
  }
}
