import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { StatusSetting } from '../../commons/setting/status_setting';
import { CommonService } from '../../commons/service/common.service';
import { AppSettings } from '../../commons/setting/app_setting';
import { AlertService } from '../../commons/service/alert.service';
import { SharedService } from '../../commons/service/shared.service';
import { DatePipe } from '@angular/common';
import { environment } from '../../../environments/environment';
import { MapsAPILoader } from '@agm/core';
import { GermanAddress } from '@angular-material-extensions/google-maps-autocomplete';
import { Router } from '@angular/router';
import { StripeAccountCreateService } from 'src/app/commons/service/stripeAccountCreate.service ';
import * as _ from 'lodash';
declare var _: _.groupBy;

export interface DialogData {
  openPop: any;
  name: any;
}

@Component({
  selector: 'app-user-onboarding',
  templateUrl: './user-onboarding.component.html',
  styleUrls: ['./user-onboarding.component.css'],
})
export class UserOnboardingComponent implements OnInit {
  public totalLanes = 0;
  public carrierContactInfo: FormGroup;
  public phStates: any;
  public manageSection = 'contactInfo';
  public getCarrierRecord: any;
  public checkValue = true;
  public equipmentList = [];
  public page: any;
  public name: any;
  public laneListGroup: any;
  public laneList: any = [];
  public addOption = false;
  public skeletonLoader = false;
  public submitted = false;
  public addChangeDetected = false;
  public addAutoNot: boolean;
  public lanLocation: number = 1;
  public checkValueLane = true;
  public laneDisabled = false;
  public loading = false;
  public signatureForm: FormGroup;
  public signatureSkeleton = true;
  public selectedFont: any;
  public currentDate: any;
  public fontList: any;
  public columnSize: any;
  public userType: any;
  public laneError = '';
  public sameLaneError: any;
  public checkUserType = false;
  public searchRestriction: any;
  public contactInfoLoader = false;
  public geoCoder;
  public imagmessage: any;
  public imgURL: any;
  public imagePath: any;
  public disbu: any;
  public serverPathForUrl: any;
  public minDateBefore = new Date();
  public signatureDisable = false;
  public connectStripe: any;
  public locations: any[] = [
    {
      id: 1,
      pickUp: '',
      dropOff: '',
    },
  ];
  public certifcateVerify: FormGroup;
  public shipperContract: any;

  constructor(
    public dialogRef: MatDialogRef<UserOnboardingComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public dialog: MatDialog,
    private alertService: AlertService,
    private commonService: CommonService,
    private stripeCreate: StripeAccountCreateService,
    private datePipe: DatePipe,
    private formBuilder: FormBuilder,
    private mapsAPILoader: MapsAPILoader,
    private router: Router,
    private sharedService: SharedService,
  ) { }

  ngOnInit() {
    this.connectStripe = localStorage.getItem('stripeConenct');
    if (this.manageSection === 'contactInfo') {
      const body = document.getElementsByTagName('body')[0];
      body.classList.add('onBoarding_sugBox');
    }
    this.serverPathForUrl = environment.serverPathForUrl;
    this.searchRestriction = environment.searchMultipleRestriction;
    this.phStates = StatusSetting.state;
    this.contactInfoLoader = true;
    this.shipperContract =
      'https://s3.us-east-2.amazonaws.com/staticdev.laneaxis.com/shipper/contract_document/laneAxisShipperCarrierContractTemplate.pdf';
    this.fontList = StatusSetting.fontList;
    this.userType = localStorage.getItem('user_type');
    let days = 1;
    this.minDateBefore.setDate(this.minDateBefore.getDate() + days);

    this.mapsAPILoader.load().then(() => {
      this.geoCoder = new google.maps.Geocoder();
    });

    if (localStorage.getItem('user_type') === 'SHIPPER') {
      this.checkUserType = true;
    } else {
      this.checkUserType = false;
    }

    this.carrierContactInfo = this.formBuilder.group({
      addressLine1: [
        '',
        [
          Validators.required,
          Validators.maxLength(64),
          Validators.pattern(/^([a-zA-Z0-9-&,#]+\s?)*$/),
        ],
      ],
      addressLine2: [
        '',
        [Validators.maxLength(64), Validators.pattern(/^([a-zA-Z0-9]+\s?)*$/)],
      ],
      zipcode: [
        '',
        [
          Validators.required,
          Validators.minLength(4),
          Validators.maxLength(6),
          Validators.pattern(/^[0-9]{0,}$/),
        ],
      ],
      city: [
        '',
        [
          Validators.required,
          Validators.maxLength(32),
          Validators.pattern(/^([a-zA-Z0-9]+\s?)*$/),
        ],
      ],
      phyState: ['', [Validators.required]],
      country: [''],
      jobTitle: [
        '',
        this.checkUserType
          ? [
            Validators.required,
            Validators.maxLength(128),
            Validators.pattern(/^([a-zA-Z0-9]+\s?)*$/),
          ]
          : [],
      ],
    });

    this.signatureForm = this.formBuilder.group({
      name: ['', [Validators.required]],
      type: ['', [Validators.required]],
    });
    this.certifcateVerify = this.formBuilder.group({
      certificate: ['', [Validators.required]],
      insurance_expiry_date: ['', [Validators.required]],
    });

    this.getUserRecord();
    this.currentDate = this.datePipe.transform(new Date(), 'yyyy/MM/dd');

  }

  userProfileRedirect() {
    if (this.userType === 'CARRIER') {
      this.router.navigateByUrl('/carrier/profile');
      this.dialogRef.close({ event: 'fail' });
    }
    if (this.userType === 'SHIPPER') {
      this.router.navigateByUrl('/shipper/profile');
      this.dialogRef.close({ event: 'fail' });
    }
  }

  closePopup() {
    this.dialogRef.close({ event: 'close' });
  }

  closePopupFail() {
    this.dialogRef.close({ event: 'fail' });
  }


  keyPress(event: any) {
    const pattern = /[0-9\+\-\ ]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode !== 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  getUserRecord() {
    this.loading = false;
    let uri = null;
    let newparams = {};
    let userType = null;
    if (localStorage.getItem('user_type') === 'CARRIER') {
      userType = AppSettings.APIsNameArray.CARRIER_REGISTRATION.GET;
    } else {
      userType = AppSettings.APIsNameArray.SHIPPER.GET;
    }
    if (newparams) uri = this.commonService.getAPIUriFromParams(newparams);
    let APIparams = {
      apiKey: userType,
      uri: uri,
    };
    this.commonService.getList(APIparams).subscribe(
      (ServerRes) => {
        this.loading = false;
        this.contactInfoLoader = false;
        if (ServerRes.success === true) {
          this.getCarrierRecord = ServerRes.response;
          this.sharedService.setAddress(ServerRes.response);
          this.checkFillOnboardin();
          let name;
          if (ServerRes.response.firstName || ServerRes.response.lastName) {
            if (ServerRes.response.lastName) {
              name =
                ServerRes.response.firstName +
                ' ' +
                ServerRes.response.lastName;
            } else {
              name = ServerRes.response.firstName;
            }

            if (name && name.length > 12) {
              this.columnSize = 12;
            } else {
              this.columnSize = 6;
            }
            this.signatureDisable = false;
          } else {
            this.signatureDisable = true;
            this.columnSize = 6;
          }

          let showLane;
          if (ServerRes.response.lane === '1') {
            showLane = '2';
            this.lanLocation = 2;
            this.laneDisabled = true;
            this.checkValueLane = false;
          } else {
            showLane = '1';
            this.lanLocation = 1;
            this.laneDisabled = false;
          }

          if (
            ServerRes.response.laneId &&
            ServerRes.response.laneId.length > 0
          ) {
            let newLo: any = {};
            if (
              ServerRes.response.laneId.pickUp !== '' &&
              ServerRes.response.laneId.dropOff !== ''
            ) {
              this.addOption = true;
              this.checkValueLane = false;
              this.locations = [];
              for (var i = 0; i < ServerRes.response.laneId.length; i++) {
                let newLo = {
                  pickUp: ServerRes.response.laneId[i].pickUp.toString(),
                  dropOff: ServerRes.response.laneId[i].dropOff.toString(),
                };
                this.locations.push(newLo);
              }
            }
          }

          this.carrierContactInfo = this.formBuilder.group({
            addressLine1: [
              ServerRes.response.phyAddressLine1,
              [
                Validators.required,
                Validators.maxLength(64),
                Validators.pattern(/^([a-zA-Z0-9&-@#,]+\s?)*$/),
              ],
            ],
            addressLine2: [
              ServerRes.response.phyAddressLine2,
              [
                Validators.maxLength(64),
                Validators.pattern(/^([a-zA-Z0-9]+\s?)*$/),
              ],
            ],
            zipcode: [
              ServerRes.response.phyZip,
              [Validators.required, Validators.pattern(/^[0-9]{0,}$/)],
            ],
            city: [
              ServerRes.response.phyCity,
              [
                Validators.required,
                Validators.maxLength(32),
                Validators.pattern(/^([a-zA-Z0-9]+\s?)*$/),
              ],
            ],
            phyState: [ServerRes.response.phyState, [Validators.required]],
            country: [
              ServerRes.response.phyCountry
                ? ServerRes.response.phyCountry
                : 'US',
            ],
            jobTitle: [
              ServerRes.response.title,
              this.checkUserType
                ? [
                  Validators.required,
                  Validators.maxLength(128),
                  Validators.pattern(/^([a-zA-Z0-9]+\s?)*$/),
                ]
                : [],
            ],
          });

          this.signatureForm = this.formBuilder.group({
            name: [name, [Validators.required]],
            type: ['', [Validators.required]],
          });
        } else {
          this.loading = false;
        }
      },
      (error) => {
        this.loading = false;
      }
    );
  }

  checkFillOnboardin() {
    this.connectStripe = localStorage.getItem('stripeConenct');
    if (this.userType === 'SHIPPER' && this.getCarrierRecord) {
      if (
        this.getCarrierRecord.phyCity &&
        this.getCarrierRecord.phyState &&
        this.getCarrierRecord.phyZip &&
        this.getCarrierRecord.equipmentType &&
        (this.getCarrierRecord.lane || this.getCarrierRecord.laneState) &&
        this.getCarrierRecord.signatureFont
      ) {
        this.showDocument();
      } else if (
        this.getCarrierRecord.phyCity &&
        this.getCarrierRecord.phyState &&
        this.getCarrierRecord.phyZip &&
        this.getCarrierRecord.equipmentType &&
        (this.getCarrierRecord.lane || this.getCarrierRecord.laneState) &&
        !this.getCarrierRecord.signatureFont
      ) {
        this.signature();
      } else if (
        this.getCarrierRecord.phyCity &&
        this.getCarrierRecord.phyState &&
        this.getCarrierRecord.phyZip &&
        this.getCarrierRecord.equipmentType &&
        (!this.getCarrierRecord.lane || !this.getCarrierRecord.laneCity == null)
      ) {
        this.showLane();
      } else if (
        this.getCarrierRecord.phyCity &&
        this.getCarrierRecord.phyState &&
        this.getCarrierRecord.phyZip &&
        !this.getCarrierRecord.equipmentType
      ) {
        this.showEquipment();
      }
    }
    if (this.userType === 'CARRIER' && this.getCarrierRecord) {

      if (
        (this.getCarrierRecord.lane || this.getCarrierRecord.laneCity) &&
        this.getCarrierRecord.signatureFont && (!this.getCarrierRecord.coiDoc || this.getCarrierRecord.coiExpired)
      ) {
        this.showDocument();
      } else if (
        this.getCarrierRecord.equipmentType &&
        (this.getCarrierRecord.lane || this.getCarrierRecord.laneCity) && !this.getCarrierRecord.signatureFont
      ) {
        this.signature();
      } else if (
        this.getCarrierRecord.equipmentType && (!this.getCarrierRecord.lane && !this.getCarrierRecord.laneCity)
      ) {
        this.showLane();
      } else if (
        !this.getCarrierRecord.equipmentType
      ) {
        this.showEquipment();
      }
      else if (this.connectStripe === '' || this.connectStripe === 'Pending' || this.connectStripe === 'On Hold') {
        this.showStripe();
      }
      else {
        this.manageSection = null;
        this.dialogRef.close({ event: 'Ok' });
      }
    }
  }

  showEquipment() {
    this.skeletonLoader = true;
    this.manageSection = 'equipmentType';
    this.getEquip();
  }

  showLane() {
    this.manageSection = 'lane';
    this.getData(null);
  }

  signature() {
    this.manageSection = 'signature';
    this.signatureSkeleton = false;
  }

  showDocument() {
    this.manageSection = 'document';
  }
 
  showStripe() {
    this.manageSection = 'stripe';
  }

  getEquip() {
    this.skeletonLoader = true;
    let uri = null;
    let newParams = { limit: 10 };
    this.loading = false;
    if (newParams) uri = this.commonService.getAPIUriFromParams(newParams);
    let APIparams = {
      apiKey: AppSettings.APIsNameArray.OTHER.EQUIPMENT,
      uri: uri,
    };
    this.commonService.getList(APIparams).subscribe((ServerRes) => {
      this.loading = false;
      if (ServerRes.success === true) {
        for (let v = 0; v < ServerRes.response.equipmentTypes.length; v++) {
          if (
            this.getCarrierRecord &&
            this.getCarrierRecord.equipmentType !== null
          ) {
            for (
              let j = 0;
              j < this.getCarrierRecord.equipmentType.length;
              j++
            ) {
              if (
                this.getCarrierRecord.equipmentType[j] ===
                ServerRes.response.equipmentTypes[v].label
              ) {
                this.checkValue = false;
                ServerRes.response.equipmentTypes[v].isChecked = 'checked';
                break;
              } else {
                ServerRes.response.equipmentTypes[v].isChecked = 'unchecked';
              }
            }
          } else {
            ServerRes.response.equipmentTypes[v].isChecked = 'unchecked';
          }
        }
        this.equipmentList = ServerRes.response.equipmentTypes;
        this.skeletonLoader = false;

      } else {
        this.equipmentList = [];
        this.skeletonLoader = false;

      }
    });
  }

  selectEquipmentType(id: any, checked: any) {
    for (let v = 0; v < this.equipmentList.length; v++) {
      if (
        this.equipmentList[v].id === id &&
        this.equipmentList[v].isChecked === 'unchecked'
      ) {
        this.equipmentList[v].isChecked = 'checked';
      } else if (
        this.equipmentList[v].id === id &&
        this.equipmentList[v].isChecked === 'checked'
      ) {
        this.equipmentList[v].isChecked = 'unchecked';
      }
    }

    if (
      this.equipmentList.some((equipment) => equipment.isChecked === 'checked')
    ) {
      this.checkValue = false;
    } else {
      this.checkValue = true;
    }
  }

  getAPIParam(searchStr) {
    let APIparams, params;
    if (searchStr) params = { limit: 57, page: this.page, search: searchStr };  // Lane changed- limit: 10,
    else params = { limit: 57, page: this.page };
    APIparams = {
      apiKey: AppSettings.APIsNameArray.CARRIER.LANE,
      uri: this.commonService.getAPIUriFromParams(params),
    };
    return APIparams;
  }

  getData(searchStr) {
    let tempArray = [];
    this.page = 1;
    var APIparams = this.getAPIParam(searchStr);
    this.commonService.getList(APIparams).subscribe((ServerRes) => {
      if (ServerRes.response.allLane && ServerRes.response.allLane.length > 0) {
        this.laneListGroup = _.groupBy(
          ServerRes.response.allLane,
          'regions'
        );
        tempArray = ServerRes.response.allLane;
        if (
          this.getCarrierRecord.laneValues &&
          this.getCarrierRecord.laneValues.length > 0
        ) {
          let newLo = {};
          for (var i = 0; i < this.getCarrierRecord.laneValues.length; i++) {
            if (this.getCarrierRecord.laneValues[i].pickUpId) {
              newLo = {
                id: this.getCarrierRecord.laneValues[i].pickUpId,
                state: this.getCarrierRecord.laneValues[i].pickUpCity,
              };

              tempArray.push(newLo);
            }
            if (this.getCarrierRecord.laneValues[i].dropId) {
              newLo = {
                id: this.getCarrierRecord.laneValues[i].dropId,
                state: this.getCarrierRecord.laneValues[i].dropOffCity,
              };
              tempArray.push(newLo);
            }
          }
          this.laneList = tempArray.filter(
            (
              (s) => (a) =>
                !s.has(a.id) && s.add(a.id)
            )(new Set())
          );
        } else {
          this.laneList = ServerRes.response.allLane;
        }
      } else {
        if (
          this.getCarrierRecord.laneValues &&
          this.getCarrierRecord.laneValues.length > 0
        ) {
          let newLo = {};
          for (var i = 0; i < this.getCarrierRecord.laneValues.length; i++) {
            if (this.getCarrierRecord.laneValues[i].pickUpId) {
              newLo = {
                id: this.getCarrierRecord.laneValues[i].pickUpId,
                state: this.getCarrierRecord.laneValues[i].pickUpCity,
              };
              tempArray.push(newLo);
            }
            if (this.getCarrierRecord.laneValues[i].dropId) {
              newLo = {
                id: this.getCarrierRecord.laneValues[i].dropId,
                state: this.getCarrierRecord.laneValues[i].dropOffCity,
              };
              tempArray.push(newLo);
            }
          }
          this.laneList = tempArray.filter(
            (
              (s) => (a) =>
                !s.has(a.id) && s.add(a.id)
            )(new Set())
          );
        } else {
          this.laneList = [];
        }
      }
    });
  }

  phyAddress(result: any) {
    this.carrierContactInfo.get('addressLine1').setValue('');
    this.carrierContactInfo.get('addressLine2').setValue('');
    this.carrierContactInfo.get('zipcode').setValue('');
    this.carrierContactInfo.get('city').setValue('');
    this.carrierContactInfo.get('phyState').setValue('');

    this.carrierContactInfo.get('addressLine1').setValue(result.formatted_address);

    for (let i = 0; i < result.address_components.length; i++) {
      if (result.address_components[i].types[0] == "locality") {
        this.carrierContactInfo.get('addressLine2').setValue(result.address_components[i].long_name);
      }
      else if(result.address_components[i].types[0] == "administrative_area_level_2"){
        this.carrierContactInfo.get('city').setValue(result.address_components[i].long_name);
      }
      else if(result.address_components[i].types[0] == "administrative_area_level_1"){
        this.carrierContactInfo.get('phyState').setValue(result.address_components[i].long_name);
      }
      else if(result.address_components[i].types[0] == "country"){
      }
      else if(result.address_components[i].types[0] == "postal_code"){
        this.carrierContactInfo.get('zipcode').setValue(result.address_components[i].long_name);
      }
    }
    this.addAutoNot = false;
    this.addChangeDetected = false;
  }

  carrerInfoSave({ value, valid }) {
    this.submitted = true;
    if (valid && !this.addAutoNot) {
      this.loading = true;
      const formData = new FormData();
      if (this.userType === 'CARRIER') {
        formData.append(
          'phyStreet',
          this.carrierContactInfo.get('addressLine1').value
        );
        formData.append(
          'phyZipShort',
          this.carrierContactInfo.get('zipcode').value
        );
      }
      if (this.userType === 'SHIPPER') {
        formData.append('title', this.carrierContactInfo.get('jobTitle').value);
        formData.append(
          'phyAddressLine1',
          this.carrierContactInfo.get('addressLine1').value
        );
        formData.append('phyZip', this.carrierContactInfo.get('zipcode').value);
      }
      if (this.carrierContactInfo.get('addressLine2').value) {
        formData.append(
          'phyAddressLine2',
          this.carrierContactInfo.get('addressLine2').value
        );
      }
      formData.append('phyCity', this.carrierContactInfo.get('city').value);
      formData.append(
        'phyState',
        this.carrierContactInfo.get('phyState').value
      );
      formData.append(
        'phyCountry',
        this.carrierContactInfo.get('country').value
      );

      let urlType;
      let methodType;
      if (localStorage.getItem('user_type') === 'CARRIER') {
        urlType = AppSettings.APIsNameArray.CARRIER.UPDATE;
      } else {
        urlType = AppSettings.APIsNameArray.SHIPPER.UPDATE;
      }

      let APIparams = {
        apiKey: urlType,
        uri: '',
        postBody: formData,
      };
      if (localStorage.getItem('user_type') === 'CARRIER') {
        methodType = this.commonService.post(APIparams);
      } else {
        methodType = this.commonService.put(APIparams);
      }
      methodType.subscribe(
        (success) => {
          this.loading = false;
          if (success.success === true) {
            const body = document.getElementsByTagName('body')[0];
            body.classList.remove('onBoarding_sugBox');
            this.loading = false;
            if (this.userType === 'SHIPPER') {
              if (!this.getCarrierRecord.equipmentType) {
                this.showEquipment();
              } else if (
                !this.getCarrierRecord.lane &&
                !this.getCarrierRecord.laneState
              ) {
                this.showLane();
              }
              if (!this.getCarrierRecord.signatureFont) {
                this.signature();
              } else if (!this.getCarrierRecord.coiDoc || this.getCarrierRecord.coiExpired) {
                this.getUserRecord();

                this.showDocument();
              }
              else if (this.connectStripe === '' || this.connectStripe === 'Pending' || this.connectStripe === 'On Hold') {
                this.showStripe();
              }
            }
            if (this.userType === 'CARRIER') {
              this.showEquipment();
            }
          } else if (success.success === false) {
            this.alertService.showNotificationMessage(
              'danger',
              'bottom',
              'right',
              'txt_s',
              'check_circle',
              'User Info',
              'You have successfully updated user detail.'
            );
            this.loading = false;
          }
        },
        (error) => {
          this.loading = false;
          this.alertService.showNotificationMessage(
            'danger',
            'bottom',
            'right',
            'txt_g',
            'error',
            'Unexpected Error',
            AppSettings.ERROR
          );
        }
      );
    }
  }

  checkLaneShowHide(event) {
    if (event === 1) {
      this.lanLocation = 2;
      this.laneDisabled = true;
      this.checkValueLane = false;
    } else {
      this.laneDisabled = false;
      if (this.getCarrierRecord.laneValues !== null) {
        this.checkValueLane = false;
      } else {
        this.checkValueLane = true;
      }
      this.lanLocation = 1;
    }
  }

  sumbitEquipment() {
    this.loading = true;
    const formData = new FormData();
    let selected = '1';
    let notSelected = '0';
    for (let v = 0; v < this.equipmentList.length; v++) {
      if (this.equipmentList[v].isChecked === 'checked') {
        formData.append(this.equipmentList[v].slug, selected);
      } else {
        formData.append(this.equipmentList[v].slug, notSelected);
      }
    }
    let urlType = null;
    let methodType = null;
    if (localStorage.getItem('user_type') === 'CARRIER') {
      urlType = AppSettings.APIsNameArray.CARRIER.UPDATE;
    } else {
      urlType = AppSettings.APIsNameArray.SHIPPER.UPDATE;
    }
    let APIparams = {
      apiKey: urlType,
      uri: '',
      postBody: formData,
    };
    if (localStorage.getItem('user_type') === 'CARRIER') {
      methodType = this.commonService.post(APIparams);
    } else {
      methodType = this.commonService.put(APIparams);
    }
    methodType.subscribe(
      (success) => {
        this.loading = false;
        if (success.success === true) {
          localStorage.setItem('equipmentType', 'success');
          if (this.userType === 'SHIPPER') {
            if (
              !this.getCarrierRecord.lane &&
              !this.getCarrierRecord.laneState
            ) {
              this.showLane();
            }
            if (!this.getCarrierRecord.signatureFont) {
              this.signature();
            } else if (!this.getCarrierRecord.coiDoc || this.getCarrierRecord.coiExpired) {
              this.getUserRecord();
              this.showDocument();
            }
            else if (this.connectStripe === '' || this.connectStripe === 'Pending' || this.connectStripe === 'On Hold') {
              this.showStripe();
            }
          }
          if (this.userType === 'CARRIER') {
            this.showLane();
          }
        } else if (success.success === false) {
        }
      },
      (error) => { }
    );
  }

  checkOption(event, name, index) {
    if (this.getCarrierRecord.laneValues) {
    } else {
      this.getCarrierRecord.laneValues = [];
    }
    let lane = this.laneList.filter((item) => item.id === event.value);
    let laneTypeData;
    if (name === 'Pick') {
      laneTypeData = {
        pickUpId: lane[0]['id'],
        pickUpCity: lane[0]['state'],
      };
    } else {
      laneTypeData = {
        dropId: lane[0]['id'],
        dropOffCity: lane[0]['state'],
      };
    }
    this.getCarrierRecord.laneValues.push(laneTypeData);
    if (
      this.locations[event.source.id].pickUp &&
      this.locations[event.source.id].dropOff
    ) {
      this.addOption = true;
      this.checkValueLane = false;
      this.sameLaneError = '';
      for (let i = 0; i < this.locations.length; i++) {
        for (let j = 0; j < this.locations.length; j++) {
          if (
            i !== j &&
            this.locations[i].pickUp === this.locations[j].pickUp &&
            this.locations[i].dropOff === this.locations[j].dropOff
          ) {
            this.sameLaneError = i;
            this.addOption = false;
            this.checkValueLane = true;
            break;
          }
        }
      }
    }
  }

  searchLane(event: any) {
    let searchStr = event.target.value;
    if (searchStr.length > 1) {
      this.getData(searchStr);
    } else {
      this.getData(null);
    }
  }

  addLocation() {
    this.locations.push({
      id: this.locations.length + 1,
      pickUp: '',
      dropOff: '',
    });
    this.addOption = false;
    this.checkValueLane = true;
    this.totalLanes = this.totalLanes + 1;
  }

  removeLocation(i: number) {
    this.locations.splice(i, 1);
    this.addOption = true;
    this.checkValueLane = false;
    this.sameLaneError = '';
    for (let i = 0; i < this.locations.length; i++) {
      for (let j = 0; j < this.locations.length; j++) {
        if (
          i !== j &&
          this.locations[i].pickUp === this.locations[j].pickUp &&
          this.locations[i].dropOff === this.locations[j].dropOff
        ) {
          this.sameLaneError = i;
          this.addOption = false;
          this.checkValueLane = true;
          break;
        }
      }
    }
  }

  showSignature() {
    this.loading = true;
    let laneType = [];
    if (this.lanLocation === 1) {
      for (let i = 0; i < this.locations.length; i++) {
        if (this.locations[i].pickUp && this.locations[i].dropOff) {
          let newLanType = {
            pickUp: this.locations[i].pickUp,
            dropOff: this.locations[i].dropOff,
          };
          laneType.push(newLanType);
        } else {
          this.laneError = i.toString();
          this.addOption = false;
          return false;
        }
      }
    }
    const formData = new FormData();
    let laneValue;
    if (laneType.length > 0 && this.lanLocation === 1) {
      laneValue = JSON.stringify(laneType);
      formData.append('laneType', laneValue);
      formData.append('lane', '0');
    } else {
      if (this.lanLocation === 2) {
        laneValue = '';
        formData.append('lane', '1');
      } else {
        laneValue = '';
        formData.append('lane', '');
      }
    }

    let urlType = null;
    let methodType = null;
    if (localStorage.getItem('user_type') === 'CARRIER') {
      urlType = AppSettings.APIsNameArray.CARRIER.UPDATE;
    } else {
      urlType = AppSettings.APIsNameArray.SHIPPER.UPDATE;
    }

    let APIparams = {
      apiKey: urlType,
      uri: '',
      postBody: formData,
    };
    if (localStorage.getItem('user_type') === 'CARRIER') {
      methodType = this.commonService.post(APIparams);
    } else {
      methodType = this.commonService.put(APIparams);
    }
    methodType.subscribe(
      (success) => {
        this.loading = false;
        if (success.success === true) {
          localStorage.setItem('lane', 'success');
          if (this.userType === 'SHIPPER') {
            if (!this.getCarrierRecord.signatureFont) {
              this.signature();
            } else {
              this.getUserRecord();
              this.showDocument();
            }
          }
          if (this.userType === 'CARRIER') {
            this.signature();
          }
        } else if (success.success === false) {
        }
      },
      (error) => { }
    );
  }

  generateFake(count: number): Array<number> {
    const indexes = [];
    for (let i = 0; i < count; i++) {
      indexes.push(i);
    }
    return indexes;
  }

  selectFont(font: any) {
    this.selectedFont = font;
    this.signatureForm.get('type').setValue(font);
  }

  signatureSave({ value, valid }) {
    this.submitted = true;
    if (valid) {
      this.loading = true;
      const formData = new FormData();

      let urlType;
      let methodType;
      if (localStorage.getItem('user_type') === 'CARRIER') {
        urlType = AppSettings.APIsNameArray.CARRIER.UPDATE;
      } else {
        urlType = AppSettings.APIsNameArray.SHIPPER.UPDATE;
      }

      let APIparams = {
        apiKey: urlType,
        uri: '',
        postBody: formData,
      };
      if (localStorage.getItem('user_type') === 'CARRIER') {
        methodType = this.commonService.post(APIparams);
      } else {
        methodType = this.commonService.put(APIparams);
      }

      formData.append('signatureFont', this.signatureForm.get('type').value);
      methodType.subscribe(
        (success) => {
          if (success.success === true) {
            this.getUserRecord();
            if (!this.getCarrierRecord.coiDoc || this.getCarrierRecord.coiExpired) {
              this.showDocument();
            }
            else if (this.connectStripe === '' || this.connectStripe === 'Pending' || this.connectStripe === 'On Hold') {
              this.showStripe();
            }
            this.loading = false;
          } else if (success.success === false) {
            this.loading = false;
            this.alertService.showNotificationMessage(
              'danger',
              'bottom',
              'right',
              'txt_s',
              'check_circle',
              'Signature',
              'You have not successfully signature updated.'
            );
          }
        },
        (error) => {
          this.loading = false;
          this.alertService.showNotificationMessage(
            'danger',
            'bottom',
            'right',
            'txt_g',
            'error',
            'Unexpected Error',
            AppSettings.ERROR
          );
        }
      );
    }
  }

  // certifcate insurace
  onSelectedFileCertifcate(event) {
    if (event.target.files.length === 0) return;
    var mimeType = event.target.files[0].type;
    if (mimeType.match(/pdf\/*/) === null) {
      this.imagmessage = 'Only supported pdf file.';
      this.imgURL = '';
      return;
    }
    var reader = new FileReader();
    this.imagePath = event.target.files;
    this.name = event.target.files[0].name;
    this.disbu = false;
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = (_event) => {
      this.imgURL = reader.result;
    };
    this.imagmessage = '';
    if (event.target.files.length) {
      const file = event.target.files[0];
      this.certifcateVerify.get('certificate').setValue(file);
    }
  }

  /*upload certificate of insurance*/
  certifcateUpload({ value, valid }) {
    if (valid) {
      this.loading = true;
      const formData = new FormData();
      let expiryDate = this.datePipe.transform(
        new Date(value.insurance_expiry_date),
        'MM/dd/yyyy'
      );
      formData.append(
        'mediaCoi',
        this.certifcateVerify.get('certificate').value
      );
      formData.append('coiExpiryDate', expiryDate);
      let APIparams = {
        apiKey: AppSettings.APIsNameArray.CARRIER.UPDATE,
        uri: '',
        postBody: formData,
      };
      this.commonService.post(APIparams).subscribe(
        (success) => {
          if (success.success === true) {
            const body = document.getElementsByTagName('body')[0];
            body.classList.remove('onBoarding_sugBox');
            this.getUserRecord();
            this.alertService.showNotificationMessage(
              'success',
              'bottom',
              'right',
              'txt_s',
              'check_circle',
              'Certificate insurance',
              'You have successfully uploaded your document.'
            );
            this.connectStripe = localStorage.getItem('stripeConenct');
            if (this.connectStripe === '' || this.connectStripe === 'Pending' || this.connectStripe === 'On Hold') {
              this.showStripe();
            }
            else
              this.dialogRef.close({ event: 'Ok' });
            setTimeout(() => {
              this.loading = false;
            }, 2000);

          } else if (success.success === false) {
            this.loading = false;
            this.alertService.showNotificationMessage(
              'danger',
              'bottom',
              'right',
              'txt_d',
              'check_circle',
              'Certificate insurance',
              'You have not successfully uploaded your document.'
            );
          }
        },
        (error) => {
          this.alertService.showNotificationMessage(
            'danger',
            'bottom',
            'right',
            'txt_g',
            'error',
            'Unexpected Error',
            AppSettings.ERROR
          );
          this.loading = false;
        }
      );
    }
  }

  acceptContractDocument() {
    this.loading = true;
    let postBody = null;
    let APIparams = {
      apiKey: AppSettings.APIsNameArray.DOCUMENT.SHIPPERCONTRACT,
      uri: '',
      postBody: postBody,
    };
    this.commonService.put(APIparams).subscribe(
      (success) => {
        if (success.success === true) {
          const body = document.getElementsByTagName('body')[0];
          body.classList.remove('onBoarding_sugBox');
          this.getUserRecord();
          this.alertService.showNotificationMessage(
            'success',
            'bottom',
            'right',
            'txt_s',
            'check_circle',
            'Accept Contract',
            'You have successfully accepted contract.'
          );
          localStorage.setItem('is_contract', '1');
          localStorage.setItem('contractDocument', 'success');
          this.dialogRef.close({ event: 'Ok' });
          setTimeout(() => {
            this.loading = false;
          }, 2000);
        } else if (success.success === false) {
          this.loading = false;
          this.alertService.showNotificationMessage(
            'danger',
            'bottom',
            'right',
            'txt_d',
            'check_circle',
            'Accept Contract',
            'Some reseaon document is not accepted.'
          );
        }
      },
      (error) => {
        this.alertService.showNotificationMessage(
          'danger',
          'bottom',
          'right',
          'txt_g',
          'error',
          'Unexpected Error',
          AppSettings.ERROR
        );
        this.loading = false;
      }
    );
  }

  noAutoselection(loc: any) {
    if (loc === 'pick') {
      this.addChangeDetected = true;
    }
  }

  noFocusOut(pickdrop: any) {
    if (pickdrop === 'pick' && this.addChangeDetected) {
      this.addAutoNot = true;
    }
  }

  createStripeAccount() {
    this.stripeCreate.connectStripe(this.getCarrierRecord);
  }

}
