<div class="detail_card_con" *ngIf="!skeletonLoader">
    <div class="ship_st_card">
      <p class="line_before mb-0">Lane Route</p>
    </div>
    <div class="list_scroll ummary_scrl" *ngIf="waypointInfo && waypointInfo.length > 0">
      <div class="shipment_road_map">
        <div class="srm_box box_{{i}} {{waypointInfo?.yuclass}} {{waypointInfo?.zompOpen}} {{waypointInfo?.status}} {{waypointInfo?.daynamicClass}} {{waypointInfo?.roundClass}}" *ngFor="let waypointInfo of waypointInfo ; let i=index;; last as isLast">
          <div class="srm_r_1">
            <p class="srm_tlt text_truncate">
              <span>{{waypointInfo?.type | titlecase }} </span>
              <span *ngIf="waypointInfo?.locationOrder">Point {{waypointInfo?.locationOrder}}</span>
            </p>
            <img *ngIf="!isLast" src="{{serverPathForUrl}}/portal/images/after-login/icon_forward.png" class="icn_fr_arrow" />
          </div>
          <div class="srm_r_2" *ngIf="waypointInfo.locationTypeImage">
            <img src="{{waypointInfo?.locationTypeImage}}" />
          </div>
          <div class="srm_r_3">
            <p class="srm_loc text_truncate">
              <img src="{{serverPathForUrl}}/portal/images/after-login/icon-map_marker.png" class="img-fluid">
              <span> {{waypointInfo.locationLongName}} </span>
            </p>
          </div>
        </div>
        <div class="clearfix"></div>
      </div>
    </div>
  </div>
  <div class="detail_card_con" *ngIf="skeletonLoader" style="margin-left: 18px;">
    <ngx-skeleton-loader [theme]="{ width: '100%', height: '30px' }" class=""
      style="margin-bottom: 30px;"></ngx-skeleton-loader>
    <ngx-skeleton-loader count="5" [theme]="{ width: '100%', height: '12px' }" class=""
      style="margin-bottom: 20px;"></ngx-skeleton-loader>
  </div>