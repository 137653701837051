import { AfterViewInit, Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from '../../commons/service/common.service';
import { AppSettings } from '../../commons/setting/app_setting';
import { AlertService } from 'src/app/commons/service/alert.service';
import { SharedService } from 'src/app/commons/service/shared.service';
import { LoadPopupComponent } from 'src/app/loads-shared/load-popup/load-popup.component';
import { UserPopupComponent } from 'src/app/profile-shared/user-popup/user-popup.component';
import { SubscriptionPopupComponent } from 'src/app/shared/subscription-popup/subscription-popup.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-create-loads',
  templateUrl: './create-loads.component.html',
  styleUrls: ['./create-loads.component.css']
})

export class CreateLoadsComponent implements OnInit, AfterViewInit {
  public createdLoadList = [];
  public loadData: any;
  public title: any;
  public connectStripe: any;
  public userDetails: any;
  public totalFilter: any;
  public subscriptionData: any;
  public skeletonLoader = false;
  public params: any = {};
  public spinnerLoader = false;
  public dataNotFound = false;
  public sidePanelData: any;
  public loading = false;
  public showAdvancedFilter = false;
  public userType: any;
  public page = 1;
  public totalPage = 1;
  public currentStatus = 'allLoads';
  public currentRouteParams: any;
  public tabCount: any;
  public countSpace: any;
  public getwindow: any;
  public searchModel: any = { pickupLocation: '', dropoffLocation: '' };
  public radius: any
  public titleFilter: any;
  public equipmentTypeFilter: any;
  public equipmentName: any;
  public equipmentNameShow: any;
  public equipmentTypeId: any;
  public currentSearchStatus: any;
  public serverPathForUrl: any
  public equipmentListing: any = [];
  public isCncelByLoads: any;
  public cancelByLoadsCount: any
  public confirmedBidByLoadsCount: any;
  public isExpiredByLoads: boolean = false
  public expiredByLoadsCount: any
  public awardedBidByLoadsCount: any;
  public isAwardedBidByLoads: boolean = false;
  public isConfirmedBidByLoads = false;
  public totalBids: any;
  public totalBidsForC: any;
  public allLoadsCount: any
  public allLoadSpotCount: any
  public quotedLoadsCount: any

  constructor(
    private commonService: CommonService,
    public dialog: MatDialog,
    private router: Router,
    private route: ActivatedRoute,
    private sharedService: SharedService,
    private alertService: AlertService,
  ) { }

  ngOnInit(): void {
    this.userType = localStorage.getItem('user_type');
    this.serverPathForUrl = environment.serverPathForUrl;
    this.markReadAlertNotification('newLoadsByLoads');
    this.params = {
      limit: (this.currentStatus == 'expiredLoadsByLane' || this.currentStatus == 'expiredLoads') ? 16 : 12,
    };
    this.currentStatus = this.route.snapshot.params['tabType'];

    if (this.userType === 'SHIPPER') {
      this.getSubscriptionAlert();
    }
    let configValue = this.sharedService.getConfig();

    if (configValue !== undefined) {
      this.equipmentListing = configValue.equipmentTypes;
      this.getConfigSet(configValue);
    }
    this.commonService.configData.subscribe((userData) => {
      this.getConfigSet(userData);
    });
    this.commonService.userData.subscribe((userData) => {
      if (userData && this.userDetails === undefined) {
        this.userInfo(userData);
      }
    });
  }

  public generateFake(count: number): Array<number> {
    const indexes = [];
    for (let i = 0; i < count; i++) {
      indexes.push(i);
    }
    return indexes;
  }

  ngAfterViewInit() {
    this.route.queryParams.subscribe((params) => {
      if (Object.keys(params).length > 0) {
        if (params['equipmentType']) {
          this.searchModel.equipmentId = parseInt(params['equipmentType']);
          this.equipmentTypeId = parseInt(params['equipmentType']);
          this.equipmentName = params['equipmentName'];
        }
        this.searchModel.title = params['title'];
      }
    });

    if (this.route.snapshot.params['tabType']) {
      const data = this.route.snapshot.params['tabType'];
      this.filterByStatus(data);
    }
  }

  userInfo(userDtail) {
    this.userDetails = userDtail;
  }

  // Getting values from config API
  getConfigSet(configValue: any) {
    this.equipmentListing = configValue.equipmentTypes;
  }

  subTabMenuFilter(event: any) {
    if (event === 'allLoads') {
      this.sidePanelData = '';
      this.filterByStatus('allLoads');
      this.markReadAlertNotification('allLoads');
    }
    else if (event === 'quotedLoads') {
      this.sidePanelData = '';
      this.filterByStatus('quotedLoads');
      this.markReadAlertNotification('quotedLoads');
    }
    else if (event === 'paymentPending') {
      this.sidePanelData = '';
      this.filterByStatus('paymentPending');
      this.markReadAlertNotification('paymentPending');
    }
    else if (event === 'expiredLoads') {
      this.filterByStatus('expiredLoads');
      this.markReadAlertNotification('expiredLoads');
    }
    else if (event === 'awardedLoads') {
      this.sidePanelData = '';
      this.filterByStatus('awardedLoads');
      this.markReadAlertNotification('awardedLoads');
    }
    else if (event === 'loadsLost') {
      this.sidePanelData = '';
      this.filterByStatus('loadsLost');
      this.markReadAlertNotification('loadsLost');
    }
  }

  camelToSnake(string) {
    return string.replace(/[\w]([A-Z])/g, function (m) {
      return m[0] + ' ' + m[1];
    });
  }

  // To filter tabs according to currentStatus which is included in route
  filterByStatus(event: any) {
    this.dataNotFound = false;
    this.page = 1;
    if (event !== null) {
      const titleValue = this.camelToSnake(event);
      if (event === 'allLoads') {
        this.currentRouteParams = 'allLoads';
        this.currentStatus = 'allLoads';
      }
      else if (event === 'quotedLoads') {
        this.currentRouteParams = 'quotedLoads';
        this.currentStatus = 'quotedLoads';
      }
      else if (event === 'paymentPending') {
        this.currentRouteParams = 'paymentPending';
        this.currentStatus = 'paymentPending';
      }
      else if (event === 'expiredLoads') {
        this.currentRouteParams = 'expiredLoads';
        this.currentStatus = 'expiredLoads';
      }
      else if (event === 'awardedLoads') {
        this.currentRouteParams = 'awardedLoads';
        this.currentStatus = 'awardedLoads';
      }
      else if (event === 'loadsLost') {
        this.currentRouteParams = 'loadsLost';
        this.currentStatus = 'loadsLost';
      }
      this.params.tabType = event;
      this.currentStatus = event;
    }
    this.applyFilterSubmit();
  }

  // To apply filter by selected value 
  applyFilterSubmit() {
    this.skeletonLoader = false;
    this.createdLoadList = [];
    this.tabCount = '';
    this.page = 1;
    if (this.showAdvancedFilter) {
      this.advanceFilter();
    } else {
      this.cancelFilterPopup();
    }
    if (this.searchModel.title) {
      this.params.title = this.searchModel.title;
      this.titleFilter = this.searchModel.title;
    }
    if (this.equipmentName) {
      this.params.equipmentType = this.searchModel.equipmentId;
      this.equipmentNameShow = this.equipmentName;
      this.equipmentTypeId = this.searchModel.equipmentId;
    } else {
      this.params.equipmentType = null;
      this.equipmentNameShow = null;
      this.equipmentTypeId = null;
    }
    if (
      this.totalFilter ||
      this.searchModel.title ||
      this.equipmentName
    ) {
      this.currentSearchStatus = 'search';
    } else {
      this.currentSearchStatus = '';
    }
    this.list();
  }

  // To hide/show filter form 
  advanceFilter() {
    if (this.userType == 'SHIPPER' && (this.subscriptionData?.isPremium != true || this.subscriptionData?.subscriptionStatus == 'canceled')) {
      this.getSubscriptionAlertPopup();
    } else {
      this.showAdvancedFilter = !this.showAdvancedFilter;
    }
  }

  // remove selected equipmentType 
  equipmentEmpty() {
    this.searchModel.equipmentId = null;
    this.equipmentName = null;
    this.params.equipmentType = null;
    this.equipmentTypeId = null;
  }

  // To close filter form 
  cancelFilterPopup() {
    this.showAdvancedFilter = false;
  }

  getSubscriptionAlertPopup() {
    const dialogRef = this.dialog.open(SubscriptionPopupComponent, {
      disableClose: true,
      backdropClass: AppSettings.backdropClass,
      width: AppSettings.popWidth,
      data: {
        openPop: 'subscriptionAlert',
        subscriptionData: this.subscriptionData
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
    });
  }

  //side panel through enter bid then refresh page.
  refreshListLoad() {
    this.skeletonLoader = false;
    this.list();
  }

  /*Search filter value*/
  searchQuantity(event: any) {
    let searchStr = event.target.value;
    let lastword = searchStr.charAt(searchStr.length - 1);
    if (lastword === ' ') {
      this.countSpace = this.countSpace + 1;
    } else {
      this.countSpace = 0;
    }
    if (this.countSpace >= 2 || (lastword === ' ' && searchStr.length === 1)) {
      this.searchModel.title = this.searchModel.title.substring(
        0,
        this.searchModel.title.length - 1
      );
    } else {
    }
  }

  // apply filter for search 
  applyFilterSearch() {
    if (this.userType == 'SHIPPER' && (this.subscriptionData?.isPremium != true || this.subscriptionData?.subscriptionStatus == 'canceled')) {
      this.getSubscriptionAlertPopup();
    } else {
      if (this.searchModel.title || this.titleFilter) {
        if (!this.searchModel.title && this.titleFilter) {
          this.titleFilter = null;
          this.searchModel.title = null;
          this.params.title = null;
        }
        this.applyFilterSubmit();
        var dimensions = null;
        if (this.searchModel.title !== null) {
          if (this.searchModel.title) {
            dimensions = 'searchText=' + this.searchModel.title;
          }
          var obj = {
            eventName: 'search_shipment',
            event_category: 'Shipment',
            Occurence: 'main',
            Dimensions: dimensions ? dimensions : '-',
            userType: localStorage.getItem('user_type'),
            EmailID: localStorage.getItem('email'),
          };
        }
        this.sharedService.track(obj);
      }
    }
  }

  /*Get All record shipment*/
  list(params = {}) {
    this.tabCount = '';
    this.createdLoadList = [];
    this.page = 1;
    let uri = null;
    var APIparams = this.getAPIParam(null);
    if (this.userType == 'SHIPPER') {
      this.router.navigate(['/spot-rate-loads/created-loads/' + this.currentStatus], {
        queryParams: {
          title: this.params.title,
          equipmentType: this.params.equipmentType,
          equipmentName: this.equipmentName,
        },
      });
    }
    if (this.userType == 'CARRIER') {
      this.router.navigate(['/spot-rate-loads/available-loads/' + this.currentStatus], {
        queryParams: {
          title: this.params.title,
          equipmentType: this.params.equipmentType,
          equipmentName: this.equipmentName,
        },
      });
    }
    this.commonService.getList(APIparams).subscribe(
      (ServerRes) => {
        if (ServerRes.success === true) {
          this.totalPage = ServerRes.response.totalPage;
          this.tabCount = ServerRes.response.tabCount;
          this.allLoadSpotCount = ServerRes.response.tabCount.allLoads;
          this.quotedLoadsCount = ServerRes.response.tabCount.quotedLoads;
          this.skeletonLoader = true;
          let result = ServerRes.response.shipments;
          if (result && result.length > 0) {
            this.createdLoadList = result;
          } else {
            this.createdLoadList = [];
          }
          this.loading = false;
        } else {
          this.createdLoadList = [];
          this.loading = false;
          this.totalPage = 0;
          this.tabCount = 0;
          this.skeletonLoader = true;
        }
      },
      (error) => {
        this.skeletonLoader = true;
        this.createdLoadList = [];
        this.loading = false;
        this.totalPage = 0;
        this.tabCount = 0;
      }
    );
  }
  // To get required params for API 
  getAPIParam(str) {
    this.radius = null;
    let APIparams, params;
    params = {
      limit: (this.currentStatus == 'expiredLoadsByLane' || this.currentStatus == 'expiredLoads') ? 16 : 12,
      radius: this.radius,
      page: this.page,
      tabType: this.currentStatus,
      title: this.params.title,
      equipmentType: this.params.equipmentType,
      equipmentName: this.equipmentName,
    };
    APIparams = {
      apiKey: AppSettings.APIsNameArray.SHIPMENT.CREATELOADSLIST,
      uri: this.commonService.getAPIUriFromParams(params),
    };
    return APIparams;
  }

  // Functions to get subscription alert popup
  getSubscriptionAlert() {
    this.commonService.getSubscriptionAlert().subscribe((serverRes) => {
      this.subscriptionData = serverRes.response;
    });
  }

  /*Single filter clear*/
  singleFilterClose(event) {
    if (event === 'title') {
      this.titleFilter = null;
      this.params.title = null;
      this.searchModel.title = null;
    }
    if (event === 'equipment') {
      this.equipmentTypeFilter = null;
      this.params.equipmentType = null;
      this.searchModel.equipmentType = null;
      this.searchModel.equipmentId = null;
      this.equipmentNameShow = null;
      this.equipmentName = null;
      this.equipmentTypeId = null;
    }
    this.createdLoadList = [];
    this.tabCount = '';
    this.page = 1;
    this.skeletonLoader = false;
    if (
      this.totalFilter ||
      this.searchModel.title ||
      this.equipmentName
    ) {
      this.currentSearchStatus = 'search';
    } else {
      this.currentSearchStatus = '';
    }
    this.list(this.params);
  }

  // To clear all the data from filter popup 
  clearFilter() {
    this.searchModel.equipmentId = null;
    this.params.equipmentType = null;
    this.equipmentName = null;
    this.titleFilter = null;
    this.searchModel.title = null;
    this.params.title = null;
    this.applyFilterSubmit();
  }


  // select equipmentType 
  equipmentSelected(equipment: any) {
    this.searchModel.equipmentId = parseInt(equipment.id);
    this.equipmentName = equipment.label;
    this.equipmentTypeId = parseInt(equipment.id);
  }


  //Onclick through multiple section event performance.
  eventAction(event) {
    const body = document.getElementsByClassName('sm_navbar_hide')[0];
    body.classList.add('side_panel_manage');
    if (event.type === 'side_panel') {
      this.sidePanelData = event.event;
      const body = document.getElementsByClassName('sm_navbar_hide')[0];
      body.classList.add('side_panel_manage');
    } else if (event.type === 'bid') {
      this.newBid(event.event);
    } else if (event.type === 'rebid') {
      if (event.event && event.event.bids) {
        this.newRedBid(event.event);
      } else {
        this.newBid(event.event);
      }
    } else if (event.type === 'stripeConenct') {
      this.checkStripeStatus();
      this.loadData = event.event;
    } else if (event.type === 'confirm') {
      this.confirmBid(event.event);
    } else if (event.type === 'acceptOffer') {
      this.acceptOfferRate(event.event);
    } else if (event.type === 'confirmOfferPrice') {
      this.confirmBidOfferRate(event.event);
    }
  }

  checkStripeStatus() {
    if (this.connectStripe && this.connectStripe !== 'active') {
      this.stripeConenctHold();
    } else if (this.connectStripe === '' || this.connectStripe === null) {
      this.stripeConenctFun();
    } else {
    }
  }

  stripeConenctFun() {
    const dialogRef = this.dialog.open(LoadPopupComponent, {
      backdropClass: AppSettings.backdropClass,
      width: AppSettings.popWidth,
      data: { openPop: 'stripeConenctCheck' },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        if (result.event === 'createNow') {
          this.stripeConnect();
        }
      }
    });
  }

    // To accept offer rate 
    acceptOfferRate(record: any){
      if(this.userDetails?.coiExpired){
        this.checkCertificate(record);
        }else{
          this.acceptOfferRateCheck(record);
      }
    }

  //close side panel
  closePanel(event: any) {
    this.sidePanelData = '';
    const body = document.getElementsByClassName('sm_navbar_hide')[0];
    body.classList.remove('side_panel_manage');
  }

  // bid by carrier
  newBid(shipment) {
    this.loadData = null;
    const dialogRef = this.dialog.open(LoadPopupComponent, {
      backdropClass: AppSettings.backdropClass,
      width: AppSettings.popWidth,
      data: {
        openPop: 'bid',
        userNameOther: shipment.shippers.companyName,
        userName: shipment.shippers.companyName,
        shipmentId: shipment.id,
        bidFor: shipment.shippers.userId,
        amount: shipment.freightRate,
        paymentMethod: shipment.paymentMethod,
        contractDocument: shipment.shippers.contractDocument,
        occurence: 'main',
        shipmentDetail: shipment,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        if (result.event === 'Ok') {
          this.router.navigate([
            '/load/view',
            shipment.id,
            'carrierLoadBids',
          ]);
        }
      }
    });
  }

  // Rebid by carrier
  newRedBid(shipment) {
    let userName;
    if (
      shipment.bids &&
      shipment.bids.actionBy === localStorage.getItem('user_id')
    ) {
      userName = localStorage.getItem('userName');
    } else {
      userName = shipment.shippers.companyName;
    }
    const dialogRef = this.dialog.open(LoadPopupComponent, {
      backdropClass: AppSettings.backdropClass,
      width: AppSettings.popWidth,
      data: {
        openPop: 'rebid',
        userNameOther: shipment.shippers.companyName,
        userName: userName,
        shipmentId: shipment.id,
        bidFor: shipment.shippers.userId,
        amount: shipment.bids.bidAmount,
        paymentMethod: shipment.paymentMethod,
        occurence: 'main',
        shipmentDetail: shipment,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        if (result.event === 'Ok') {
          this.router.navigate([
            '/load/view',
            shipment.id,
            'carrierLoadBids',
          ]);
        }
      }
    });
  }

   //Accept Offer Rate 
   acceptOfferRateCheck(shipment) {
    let actionByOffer;
    if (shipment.isOfferRate === 'Y' && shipment.overBid === 'Y') {
      actionByOffer = 'confirmOfferPrice';
    } else {
      actionByOffer = 'No';
    }
    this.loadData = null;
    const dialogRef = this.dialog.open(LoadPopupComponent, {
      backdropClass: AppSettings.backdropClass,
      width: AppSettings.popWidth,
      data: {
        openPop: 'acceptOfferRate',
        userNameOther: shipment.shippers.companyName,
        userName: shipment.shippers.companyName,
        shipmentId: shipment.id,
        bidFor: shipment.shippers.userId,
        amount: shipment.freightRate,
        paymentMethod: shipment.paymentMethod,
        contractDocument: shipment.shippers.contractDocument,
        actionByOffer: actionByOffer,
        occurence: 'main',
        shipmentDetail: shipment,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        if (result.event === 'Ok') {
          this.router.navigate([
            '/load/view',
            shipment.id,
            'carrierLoadBids',
          ]);
        }
      }
    });
  }

  //Confirm bid by carrier
  confirmBidOfferRate(record: any) {
    let getUserName;
    if (localStorage.getItem('user_id') === record.bids.actionBy) {
      getUserName = record.carriers.legalName;
    } else {
      getUserName = record.shippers.companyName;
    }
    const dialogRef = this.dialog.open(LoadPopupComponent, {
      backdropClass: AppSettings.backdropClass,
      disableClose: true,
      width: AppSettings.popWidth,
      data: {
        openPop: 'acceptBidConfirmOffer',
        userName: getUserName,
        userNameOther: record.shippers.companyName,
        amount: record.bids.bidAmount,
        note: record.title,
        bidId: parseInt(record.bids.id),
        shipmentId: record.id,
        uniqueId: record.uniqueId,
        paymentMethod: record.paymentMethod,
        bidingStatus: record.bids.status,
        occurence: 'main',
        shipmentDetail: record,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        if (result.event === 'Ok') {
          this.refreshListLoad();
        }
      }
    });
  }

  // To check/Update certificate of insurance 
  checkCertificate(record: any) {
    const dialogRef = this.dialog.open(UserPopupComponent, {
      disableClose: true,
      backdropClass: AppSettings.backdropClass,
      width: "670px",
      data: { openPop: "certificate" },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result.event == 'Ok') {
        this.confirmBidCheck(record)
      }
    });
  }

  //Confirm bid by carrier
  confirmBid(record: any) {
    if (this.userDetails?.coiExpired) {
      this.checkCertificate(record);
    } else {
      this.confirmBidCheck(record);
    }
  }

   // function for page scrolling
   onScroll() {
    if (this.page < this.totalPage) {
      this.page = this.page + 1;
      this.getMoreData(null);
    }
  }


  //  CONNECT WITH STRIPE
  stripeConnect() {
    this.loading = true;
    let postBody = null;
    postBody = {
      email: this.userDetails.emailAddress,
      url: '',
      country: 'US',
      phone_number: this.userDetails.telephone,
      business_name: this.userDetails.legalName,
      business_type: 'company',
      first_name: this.userDetails.firstName,
      last_name: this.userDetails.lastName,
      dob_day: '',
      dob_month: '',
      dob_year: '',
    };
    let APIparams = {
      apiKey: AppSettings.APIsNameArray.NEWPAYMENT.GETCONNECTLINK,
      uri: '',
      postBody: postBody,
    };
    this.commonService.post(APIparams).subscribe(
      (success) => {
        if (success.success === true) {
          this.getwindow = window.open(
            success.response,
            'myWindow',
            'width=600,height=600'
          );
          this.loading = false;
        } else if (success.success === false) {
          this.loading = false;
        }
      },
      (error) => {
        this.alertService.showNotificationMessage(
          'danger',
          'bottom',
          'right',
          'txt_g',
          'error',
          'Unexpected Error',
          AppSettings.ERROR
        );
        this.loading = false;
      }
    );
  }

  // To accept and confirm shipment 
  confirmBidCheck(record: any) {
    let getUserName;
    if (localStorage.getItem('user_id') === record.bids.actionBy) {
      getUserName = record.carriers.legalName;
    } else {
      getUserName = record.shippers.companyName;
    }
    const dialogRef = this.dialog.open(LoadPopupComponent, {
      backdropClass: AppSettings.backdropClass,
      disableClose: true,
      width: AppSettings.popWidth,
      data: {
        openPop: 'acceptBidConfirm',
        userName: getUserName,
        userNameOther: record.shippers.companyName,
        amount: record.bids.bidAmount,
        note: record.title,
        bidId: parseInt(record.bids.id),
        shipmentId: record.id,
        uniqueId: record.uniqueId,
        paymentMethod: record.paymentMethod,
        bidingStatus: record.bids.status,
        occurence: 'main',
        shipmentDetail: record,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        if (result.event === 'Ok') {
          this.refreshListLoad();
        }
      }
    });
  }

  // Popup for showing stripe status on hold
  stripeConenctHold() {
    const dialogRef = this.dialog.open(LoadPopupComponent, {
      backdropClass: AppSettings.backdropClass,
      width: '400px',
      data: { openPop: 'stripeConenctPending' },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        if (result.event === 'Oklahoma') {
          this.stripeConnect();
        }
      }
    });
  }

  // To mark as read notification by badge icon
  markReadAlertNotification(value: any) {
    let shipper = localStorage.getItem('user_type') === 'SHIPPER';
    let carrier = localStorage.getItem('user_type') === 'CARRIER';
    this.commonService.putAlertNotificationOnRead(value).subscribe((data) => {
      if (data.response) {
        if (shipper) {
          this.totalBids = data.response.currentBid + data.response.confirmedBids;
        }
        if (carrier) {
          this.totalBidsForC = data.response.currentBidByLoads + data.response.createByLoads + data.response.newLoadsByLoads;
          this.allLoadsCount = data.response.newLoadsByLoads;
        }
        if (carrier && data.response.awardedBidByLoads !== 0) {
          this.awardedBidByLoadsCount = data.response.awardedBidByLoads;
          this.isAwardedBidByLoads = true;
        } else {
          this.isAwardedBidByLoads = false;
        }
        if (shipper && data.response.confirmedBidByLoads !== 0) {
          this.confirmedBidByLoadsCount = data.response.confirmedBidByLoads;
          this.isConfirmedBidByLoads = true;
        } else {
          this.isConfirmedBidByLoads = false;
        }
        if (shipper && data.response.expiredByLoads !== 0) {
          this.expiredByLoadsCount = data.response.expiredByLoads;
          this.isExpiredByLoads = true;
        } else {
          this.isExpiredByLoads = false;
        }
        if (carrier && data.response.cancelByLoads !== 0) {
          this.cancelByLoadsCount = data.response.cancelByLoads;
          this.isCncelByLoads = true;
        } else {
          this.isCncelByLoads = false;
        }
      } else {
      }
    });
  }

  // Get more data during pagination from shipment.
  getMoreData(str) {
    this.spinnerLoader = true;
    var APIparams = this.getAPIParam(str);
    this.commonService.getList(APIparams).subscribe((ServerRes) => {
      let result = ServerRes.response.shipments;
      this.spinnerLoader = false;
      if (ServerRes.response.shipments && ServerRes.response.shipments.length > 0) {
        for (let v = 0; v < result.length; v++) {
          if (result[v]) this.createdLoadList.push(result[v]);
        }
      }
    });
  }

  // To close filter Popup 
  closeFilterPopup() {
    this.showAdvancedFilter = false;
    if (this.equipmentNameShow) {
      this.searchModel.equipmentId = this.equipmentTypeId;
      this.equipmentName = this.equipmentNameShow;
      this.params.equipmentType = this.equipmentTypeId;
    }
  }

}
