import { Component, OnInit, OnChanges, SimpleChanges, Input, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { CommonService } from 'src/app/commons/service/common.service';
import { AppSettings } from 'src/app/commons/setting/app_setting';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';
import { PaymentService } from 'src/app/payment/payment.service';
import { UserPopupComponent } from 'src/app/profile-shared/user-popup/user-popup.component';
import { SharedService } from 'src/app/commons/service/shared.service';
import { PopupComponent } from 'src/app/shared/popup/popup.component';
import { LoadPopupComponent } from 'src/app/loads-shared/load-popup/load-popup.component';

@Component({
  selector: 'app-carrier-bid',
  templateUrl: './carrier-bid.component.html',
  styleUrls: ['./carrier-bid.component.scss'],
  providers: [PaymentService],
})

export class CarrierBidComponent implements OnInit, OnChanges{
  @Input() shipmentDetail: any;
  @Input() currentTabShow: any;
  @Input() shipmentRelated:any;
  @Output() eventPassAction = new EventEmitter();
  @Input() priceAmout: any;
  public sidePanelProfileDataId: any;
  public sidePanelProfileData: any;
  public userDetails: any;
  public connectStripe: any;
  public currentBid: any = [];
  public overBidCount: any;
  public acceptBidList: any;
  public previousBid: any;
  public previousBidHistory = [];
  public lowestShipmentBid: any;
  public page = 1;
  public totalPage = 1;
  public bidHistorySekeletonLoader = false;
  public serverPathForUrl: any;
  public userType: any;
  public userId: any;
  public currentId: any;
  public ifCurrentBid: boolean = false;
  public ifAcceptBid: boolean = false;
  public ifPreviousBid: boolean = false;
  public userName: any;

  constructor(
    private route: ActivatedRoute,
    public commonService: CommonService,
    public dialog: MatDialog,
    private sharedService: SharedService,
    public paymentService: PaymentService,
    private cd: ChangeDetectorRef,
  ) { }

  async ngOnInit() {
    this.userName = localStorage.getItem('userName');
    this.serverPathForUrl = environment.serverPathForUrl;
    this.userType = localStorage.getItem('user_type');
    this.userId = localStorage.getItem('user_id');
    this.currentId = localStorage.getItem('user_id');
    this.connectStripe = localStorage.getItem('stripeConenct');
    this.shipmentDetail.id=this.route.snapshot.params['loadId'];
    let userDetail;
    if (localStorage.getItem('user_type') === 'CARRIER') {
       userDetail = this.sharedService.getAddress();
      if (userDetail !== null) {
        this.userDetails = userDetail;
        this.userInfo(userDetail);
      }
      this.commonService.userData.subscribe((userData) => {
        if(userData){
          this.userDetails=userData;
          this.userInfo(userData);
        }
      });
    }
    this.getHistoryData();   
    if (this.userType === 'CARRIER') {
      this.getLowestBid();
    }
  }
  public ngOnChanges(changes: SimpleChanges) {
    if (changes['shipmentRelated']['currentValue'] !== undefined) {
        let priceAmout = changes['shipmentRelated']['currentValue']['priceAmout'];
        if(localStorage.getItem('bids') == '1'){
          if((this.shipmentDetail.isOfferRate==='N' || !this.shipmentDetail.isOfferRate) && (localStorage.getItem('checkRebid')=='1')){
            this.bid(this.shipmentDetail.shippers.userId,this.shipmentDetail.shippers.companyName,this.shipmentDetail.freightRate,this.shipmentDetail.paymentMethod,this.shipmentDetail?.shippers?.contractDocument,priceAmout);
          }else{
          this.redBid(this.currentBid[0]?.bidAmount,this.currentBid[0]?.note,this.shipmentDetail?.shippers?.userId,this.currentBid[0]?.userName,this.shipmentDetail?.shippers?.companyName,this.shipmentDetail?.paymentMethod,priceAmout);
        }
        localStorage.setItem('bids','2')
      }
    }
 }

  // Function to get user details 
  userInfo(userDtail) {
    this.userDetails = userDtail;
  }

  // API call to get lowest bid for the shipment
  getLowestBid() {
    let uri = null;
    let params = {
      shipmentId: this.shipmentDetail.id,
    };
    //get dynamic URI for APIs
    if (params) uri = this.commonService.getAPIUriFromParams(params);
    let APIparams = {
      apiKey: AppSettings.APIsNameArray.BIDS.LOWESTBID,
      uri: uri,
    };
    this.commonService.getList(APIparams).subscribe(
      (ServerRes) => {
        if (ServerRes.success === true) {
          this.lowestShipmentBid = ServerRes.response;
        } else {
          this.lowestShipmentBid = [];
        }
      },
      (error) => {
        this.lowestShipmentBid = [];
      }
    );
  }

  // To get required params for the API
  getHiAPIParam() {
    let APIparams, params;
    params = {
      limit: 10,
      shipmentId: this.route.snapshot.params['loadId'],
      page: this.page,
    };
    APIparams = {
      apiKey: AppSettings.APIsNameArray.BIDS.HISTORY,
      uri: this.commonService.getAPIUriFromParams(params),
    };
    return APIparams;
  }

  // function for page scrolling
  onHiScroll() {
    if (this.page < this.totalPage) {
      this.page = this.page + 1;
      this.getHistoryMoreData();
    }
  }
  
  //To get data for shipment-bid history
  getHistoryData() {
    this.bidHistorySekeletonLoader = true;
    this.page = 1;
    var APIparams = this.getHiAPIParam();
    this.commonService.getList(APIparams).subscribe((ServerRes) => {
      this.bidHistorySekeletonLoader = false;
      this.overBidCount = ServerRes.overBidCount;
      if (ServerRes.response) {
        if (ServerRes.response.currentBids.length > 0) {
          for (let v = 0; v < ServerRes.response.currentBids.length; v++) {
            ServerRes.response.currentBids[v].actionTs = new Date(
              ServerRes.response.currentBids[v]['actionTs'] + ' ' + 'UTC'
            );
          }
          localStorage.setItem('checkRebid','1');
        }else{
          localStorage.setItem('checkRebid','2');        
        }
        this.currentBid = ServerRes.response.currentBids;
         if(this.currentBid.length>0){
          this.commonService.bidQuotesAssistanceUpdate.next({'status':this.currentBid[0].status})
         }else{
          this.commonService.bidQuotesAssistanceUpdate.next({'status':null})
         }
        if (ServerRes.response.acceptedBids.length > 0) {
          for (let v = 0; v < ServerRes.response.acceptedBids.length; v++) {
            ServerRes.response.acceptedBids[v].actionTs = new Date(
              ServerRes.response.acceptedBids[v]['actionTs'] + ' ' + 'UTC'
            );
          }
        }
        this.acceptBidList = ServerRes.response.acceptedBids;
        this.previousBid = ServerRes.response.previousBids;

        this.currentBid.forEach((value) => {
          if (value.status == '4' || value.actionBy == this.currentId || this.shipmentDetail.visibility === 'private') {
            this.ifCurrentBid = true;
          } else {
            this.ifCurrentBid = false;
          }
        });
        this.acceptBidList.forEach((value) => {
          if (value.status == '4' || value.actionBy == this.currentId || this.shipmentDetail.visibility === 'private') {
            this.ifAcceptBid = true;
          } else {
            this.ifAcceptBid = false;
          }
        });
        this.previousBid.forEach((value) => { });
        this.currentBid.forEach((value) => {
          if (value.status == '4' || this.shipmentDetail.visibility === 'private') {
            this.ifPreviousBid = true;
          } else {
            this.ifPreviousBid = false;
          }
        });

        if(this.currentTabShow === 'history' && localStorage.getItem('user_type') === 'CARRIER') {
          if (this.previousBid.length > 0) {
            for (let v = 0; v < this.previousBid.length; v++) {
              this.previousBid[v].actionTs = new Date(
                this.previousBid[v]['actionTs'] + ' ' + 'UTC'
              );
            }
          }
          this.previousBidHistory = this.currentBid.concat(this.previousBid);
        } else {
          if (this.previousBid.length > 0) {
            for (let v = 0; v < this.previousBid.length; v++) {
              this.previousBid[v].actionTs = new Date(
                this.previousBid[v]['actionTs'] + ' ' + 'UTC'
              );
            }
          }
          this.previousBidHistory = this.previousBid;
        }
        this.cd.detectChanges();
        this.totalPage = ServerRes.totalPage;
      } else {
        this.currentBid = [];
        this.acceptBidList = [];
        this.previousBid = [];
        this.totalPage = 0;
        this.cd.detectChanges();
        localStorage.setItem('checkRebid','1')
      }
    });
  }

  // To get pagination for more data on scroll
  getHistoryMoreData() {
    var APIparams = this.getHiAPIParam();
    this.commonService.getList(APIparams).subscribe((ServerRes) => {
      if (
        ServerRes.response.previousBids &&
        ServerRes.response.previousBids.length > 0
      ) {
        for (let v = 0; v < ServerRes.response.previousBids.length; v++) {
          ServerRes.response.previousBids[v].createdAt = new Date(
            ServerRes.response.previousBids[v]['createdAt'] + ' ' + 'UTC'
          );
          if (ServerRes.response.previousBids[v])
            this.previousBidHistory.push(ServerRes.response.previousBids[v]);
        }
      }
    });
  }

  // Alert popup if equipment type is Not Matching
  openEquipmentPopup(bidFor: any,shipperName: any,amount: any,paymentMethod: any,contractDocument: any,inputAmout?:any, type?:any){  
      const dialogRef = this.dialog.open(PopupComponent, {
        backdropClass: AppSettings.backdropClass,
        width: AppSettings.popWidth,
        disableClose: true,
        data: {
          openPop: 'equipmentNotMatchShipment',
          contain:this.shipmentDetail
        },
      });
      dialogRef.afterClosed().subscribe((result) => {
        if(result.event =='Ok' && type =='bid'){ 
          this.bidPopup(bidFor,shipperName,amount,paymentMethod,contractDocument,inputAmout)
        }else if(this.userDetails.coiExpired && type=="offerRate"){
          this.checkCertificateInsuranceOffer(bidFor,shipperName,amount,paymentMethod,contractDocument,this.shipmentDetail.shipment_id)
        }else if (result.event =='Ok' && type !='bid'){
        this.openDriverAlertPopupBid(bidFor,shipperName,amount,paymentMethod,contractDocument,this.shipmentDetail.shipment_id)
     }     
    });
  }

  // popup for first bid carrier side
  openDriverAlertPopupBid(bidFor,shipperName,amount,paymentMethod,contractDocument,shipment_id)
  {
    const dialogRef = this.dialog.open(PopupComponent, {
      backdropClass: AppSettings.backdropClass,
      width: AppSettings.popWidth,
      height:'600px',
      disableClose: true,
      data: {
        openPop: 'driverMobileNotification',
        contain:event
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result.event == 'Ok') {
        this.offerRatePopup(bidFor,shipperName,amount,paymentMethod,contractDocument,shipment_id)
      }
    
    });

  }

  bidPopup(bidFor: any,shipperName: any,amount: any,paymentMethod: any,contractDocument: any,inputAmout?:any){
    const dialogRef = this.dialog.open(LoadPopupComponent, {
      backdropClass: AppSettings.backdropClass,
      disableClose: true,
      width: AppSettings.popWidth,
      data: {
        openPop: 'bid',
        userNameOther: shipperName,
        userName: shipperName,
        shipmentId: this.route.snapshot.params['loadId'],
        bidFor: bidFor,
        amount: amount,
        paymentMethod: paymentMethod,
        contractDocument: contractDocument,
        occurence: 'shipment_view_page',
        shipmentDetail: this.shipmentDetail,
        inputAmout:inputAmout,
        type:'bidAssist'
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        if (result.event === 'Ok') {
          this.getHistoryData();
        }
      }
    });
  }

  // To open biding/equipment popup
  bid(bidFor: any,shipperName: any,amount: any,paymentMethod: any,contractDocument: any,inputAmout?:any) {
  if(!this.shipmentDetail.bid){
    this.openEquipmentPopup(bidFor,shipperName,amount,paymentMethod,contractDocument,inputAmout,'bid')
  }else {
    this.bidPopup(bidFor,shipperName,amount,paymentMethod,contractDocument,inputAmout)
  }
  }

  // function for overBid (Currently not in use)
  bidOverBids(
    bidFor: any,
    shipperName: any,
    amount: any,
    paymentMethod: any,
    contractDocument: any
  ) {
    const dialogRef = this.dialog.open(LoadPopupComponent, {
      backdropClass: AppSettings.backdropClass,
      disableClose: true,
      width: AppSettings.popWidth,
      data: {
        openPop: 'overBids',
        userNameOther: shipperName,
        userName: shipperName,
        shipmentId: this.route.snapshot.params['loadId'],
        bidFor: bidFor,
        amount: amount,
        paymentMethod: paymentMethod,
        contractDocument: contractDocument,
        freightRate: amount,
        occurence: 'shipment_view_page',
        shipmentDetail: this.shipmentDetail,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        if (result.event === 'Ok') {
          this.getHistoryData();
        }
      }
    });
  }
  
  // To check COI and confirm offer rate popup
  confirmBidOfferRate( bidId: any,amount: any, note: any,userName: any,companyName: any,paymentMethod: any,bidingStatus: any) {
    if(this.userDetails.coiExpired){
      const dialogRef = this.dialog.open(UserPopupComponent, {
        disableClose: true,
        backdropClass: AppSettings.backdropClass,
        width: "670px",
        data: { openPop: "certificate"},
      });
      dialogRef.afterClosed().subscribe((result) => {
        if(result.event=="Ok"){
          this.getHistoryData();
        }
      });
   }else{
    this.confirmBidOfferRateCheck( bidId,amount, note,userName,companyName,paymentMethod,bidingStatus);
   }
  }

  // Popup for accept and confirm offer rate
  confirmBidOfferRateCheck( bidId: any,amount: any, note: any,userName: any,companyName: any,paymentMethod: any,bidingStatus: any) {
    const dialogRef = this.dialog.open(LoadPopupComponent, {
      backdropClass: AppSettings.backdropClass,
      disableClose: true,
      width: AppSettings.popWidth,
      data: {
        openPop: 'acceptBidConfirmOffer',
        userName: companyName,
        userNameOther: userName,
        amount: amount,
        note: note,
        bidId: bidId,
        shipmentId: this.route.snapshot.params['loadId'],
        uniqueId: this.shipmentDetail.uniqueId,
        paymentMethod: paymentMethod,
        bidingStatus: bidingStatus,
        occurence: 'shipment_view_page',
        shipmentDetail: this.shipmentDetail,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        if (result.event === 'Ok') {
          this.getHistoryData();
        }
      }
    });
  }

  // Popup for counterBid/Rebid for the shipment
  redBid(amount: any,note: any,bidFor: any,userName: any,companyName: any,paymentMethod: any, inputAmout?:any) {
    const dialogRef = this.dialog.open(LoadPopupComponent, {
      backdropClass: AppSettings.backdropClass,
      disableClose: true,
      width: AppSettings.popWidth,
      data: {
        openPop: 'rebid',
        userNameOther: companyName,
        userName: userName,
        amount: amount,
        note: note,
        shipmentId: this.route.snapshot.params['loadId'],
        bidFor: bidFor,
        paymentMethod: paymentMethod,
        occurence: 'shipment_view_page',
        inputAmout:inputAmout,
        shipmentDetail: this.shipmentDetail,
        type:'bidAssist',
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        if (result.event === 'Ok') {
          this.getHistoryData();
        }
      }
    });
  }

  // Popup for acceptBid by Carrier for the shipment
  acceptBidCarrier(bidId: any,amount: any,note: any,userName: any,companyName: any,paymentMethod: any,bidingStatus: any) {
    const dialogRef = this.dialog.open(LoadPopupComponent, {
      backdropClass: AppSettings.backdropClass,
      disableClose: true,
      width: AppSettings.popWidth,
      data: {
        openPop: 'acceptBid',
        userName: companyName,
        userNameOther: userName,
        amount: amount,
        note: note,
        bidId: bidId,
        shipmentId: this.route.snapshot.params['loadId'],
        uniqueId: this.shipmentDetail.uniqueId,
        paymentMethod: paymentMethod,
        bidingStatus: bidingStatus,
        occurence: 'shipment_view_page',
        shipmentDetail: this.shipmentDetail,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        if (result.event === 'Ok') {
          this.getHistoryData();
        }else if(result.event === 'accept_and_confirm'){
          this.acceptConfirm(bidId,amount,note,userName,companyName,paymentMethod,bidingStatus);
        }
      }
    });
  }

  // To check COI/Accept bid popup
  acceptConfirm(bidId: any,amount: any, note: any, userName: any, companyName: any, paymentMethod: any, bidingStatus: any){
      if(this.userDetails.coiExpired){
        this.checkCertificateInsurance(bidId,amount, note, userName, companyName, paymentMethod, bidingStatus)
     }else{
        this.acceptConfirmCheck(bidId,amount,note,userName,companyName,paymentMethod,bidingStatus)
     }
  }

  // Popup to upload Certificate of Insurance 
  openDriverAlertPopupConfirm(bidId: any,amount: any, note: any, userName: any, companyName: any, paymentMethod: any, bidingStatus: any)
  {
    const dialogRef = this.dialog.open(PopupComponent, {
      backdropClass: AppSettings.backdropClass,
      width: AppSettings.popWidth,
      height:'600px',
      disableClose: true,
      data: {
        openPop: 'driverMobileNotification',
        contain:event
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result.event == 'Ok') {
        this.acceptConfirm(bidId,amount, note, userName, companyName, paymentMethod, bidingStatus)
      }
    });
  }

  checkCertificateInsurance(bidId: any,amount: any, note: any, userName: any, companyName: any, paymentMethod: any, bidingStatus: any){
    const dialogRef = this.dialog.open(UserPopupComponent, {
      disableClose: true,
      backdropClass: AppSettings.backdropClass,
      width: "670px",
      data: { openPop: "certificate"},
    });
    dialogRef.afterClosed().subscribe((result) => {
      if(result.event=="Ok"){
        this.acceptConfirmCheck(bidId,amount,note,userName,companyName,paymentMethod,bidingStatus)
      }
    });
  }

  // Popup to upload Certificate of Insurance for offer rate
  checkCertificateInsuranceOffer(bidFor:any,shipperName:any,amount:any,paymentMethod:any,contractDocument:any,shipmentId:any){
    const dialogRef = this.dialog.open(UserPopupComponent, {
      disableClose: true,
      backdropClass: AppSettings.backdropClass,
      width: "670px",
      data: { openPop: "certificate"},
    });
    dialogRef.afterClosed().subscribe((result) => {
      if(result.event=="Ok"){
        this.offerRatePopup(bidFor,shipperName,amount,paymentMethod,contractDocument,shipmentId)
      }
    });
  }
 
  // Popup for accept and confirm shipment
  acceptConfirmCheck(bidId: any,amount: any,note: any,userName: any,companyName: any,paymentMethod: any,bidingStatus: any) {
    const dialogRef = this.dialog.open(LoadPopupComponent, {
      backdropClass: AppSettings.backdropClass,
      disableClose: true,
      width: AppSettings.popWidth,
      data: {
        openPop: 'acceptBidConfirm',
        userName: companyName,
        userNameOther: userName,
        amount: amount,
        note: note,
        bidId: bidId,
        shipmentId: this.route.snapshot.params['loadId'],
        uniqueId: this.shipmentDetail.uniqueId,
        paymentMethod: paymentMethod,
        bidingStatus: bidingStatus,
        occurence: 'shipment_view_page',
        shipmentDetail: this.shipmentDetail,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        if (result.event === 'Ok') {
          this.getHistoryData();
        }
      }
    });
  }

  // Popup for Withdraw bid 
  cancelBid(bidId: any,amount: any,note: any,userName: any,paymentMethod: any) {
    const dialogRef = this.dialog.open(LoadPopupComponent, {
      backdropClass: AppSettings.backdropClass,
      disableClose: true,
      width: '455px',
      data: {
        openPop: 'cancelreBid',
        userName: userName,
        bidId: bidId,
        amount: amount,
        note: note,
        shipmentId: this.route.snapshot.params['loadId'],
        uniqueId: this.shipmentDetail.uniqueId,
        paymentMethod: paymentMethod,
        occurence: 'shipment_view_page',
        shipmentDetail: this.shipmentDetail,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        if (result.event === 'Ok') {
          this.getHistoryData();
        }
      }
    });
  }

  // Popup for accept and confirm offerRate shipment
  offerRatePopup(bidFor: any,shipperName: any,amount: any,paymentMethod: any,contractDocument: any,shipmentId:any){
    let actionByOffer; if (
      this.shipmentDetail.isOfferRate === 'Y' &&
      this.shipmentDetail.overBid === 'Y'
    ) {
      actionByOffer = 'confirmOfferPrice';
    } else {
      actionByOffer = 'No';
    }
    const dialogRef = this.dialog.open(LoadPopupComponent, {
      backdropClass: AppSettings.backdropClass,
      disableClose: true,
      width: AppSettings.popWidth,
      data: {
        openPop: 'acceptOfferRate',
        userNameOther: shipperName,
        userName: shipperName,
        shipmentId: this.route.snapshot.params['loadId'],
        bidFor: bidFor,
        amount: amount,
        paymentMethod: paymentMethod,
        contractDocument: contractDocument,
        actionByOffer: actionByOffer,
        occurence: 'side_panel',
        shipmentDetail: this.shipmentDetail,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        if (result.event === 'Ok') {
          this.getHistoryData();
        }
      }
    });
  }

  // To check COI/EquipmentType match/offerRate popup
  acceptOfferRate(bidFor: any,shipperName: any,amount: any,paymentMethod: any,contractDocument: any,shipmentId: any) {
    if(!this.shipmentDetail.bid){
      this.openEquipmentPopup(bidFor,shipperName,amount,paymentMethod,contractDocument,shipmentId,'offerRate')
    }else if(this.userDetails.coiExpired){
      this.checkCertificateInsuranceOffer(bidFor,shipperName,amount,paymentMethod,contractDocument,shipmentId)
    }else{
      this.offerRatePopup(bidFor,shipperName,amount,paymentMethod,contractDocument,shipmentId)
    }
  }
  
 // Event to open profile side panel
  eventActionProfileSidePanel(event: any, type: any) {
    for(let i =0; i<event?.length;i++){
      if(event[i]?.userName !== this.userName) {
        this.sidePanelProfileData=this.shipmentDetail;
        this.sidePanelProfileDataId = this.shipmentDetail?.shippers?.id;
        this.eventPassAction.emit({ event, type });
      }
    }
  }

  // To Close side panel
  closePanel(event){
    this.sidePanelProfileDataId='';
  }
  
}
