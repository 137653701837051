<div class="detail_card_con pb-4" *ngIf="(userType==='SHIPPER' && (loadDetail?.statusSlug==='delivered' || loadDetail?.statusSlug==='completed') && (loadDetail?.ratingReview?.isShipperRate === false))">
    <div class="title_action">
      <p class="line_before mb-0">Write a Review</p>
    </div>
    <p class="fs_16 txt_l fsb_6 mb-2">#{{loadDetail?.uniqueId}}</p>
    <form [formGroup]="ratingForm" (ngSubmit)="ratingFormSubmit(ratingForm)" class="mb-0 custum_input_css">
      <div class="rating_box">
        <div class="rating_stars">
          <span *ngFor="let star of stars; let i=index">
           <span class="material-icons txt_y" id="id_{{i}}"  (click)="countStar(star)" *ngIf="star.selected === true">star </span>
          </span>
          <span *ngFor="let star of stars; let i=index">
            <span class="material-icons" id="id_{{i}}"  (click)="countStar(star)" *ngIf="star.selected === false"> star_border</span>
           </span>
        </div>
      </div>
      <div class="col-md-12">
        <mat-form-field appearance="none" class="removeappearance">
          <textarea matInput type="text" [(ngModel)]="textValue" formControlName="review" placeholder="Share details of your own experience at this place." class="form-control custom_input"
            maxlength="255" rows="3"></textarea>
          <mat-error *ngIf="ratingForm.get('review').errors?.['maxlength']" class="error_mess">Review must not exceed 255 characters.</mat-error>
        </mat-form-field>
      </div>
      <button type="submit" [disabled]="disabledButton" class="btn custum_button button_primary">
        <div *ngIf="loading" class="spinner-border spinner_border txt_w" role="status">
          <span class="sr-only"><!-- Loading... --></span>
        </div>
      <span *ngIf="!loading" class="pe-2">Post Review</span></button>
   </form>
</div>

<div class="detail_card_con pb-4" *ngIf="(userType==='CARRIER' && (loadDetail?.statusSlug==='delivered' || loadDetail?.statusSlug==='completed') && (loadDetail?.ratingReview?.isCarrierRate === false))">
  <div class="title_action">
    <p class="line_before mb-0">Write a Review</p>
  </div>
  <p class="fs_16 txt_l fsb_6 mb-2">#{{loadDetail?.uniqueId}}</p>
  <form [formGroup]="ratingForm" (ngSubmit)="ratingFormSubmit(ratingForm)" class="mb-0 custum_input_css">
    <div class="rating_box">
      <div class="rating_stars">
        <span *ngFor="let star of stars; let i=index">
         <span class="material-icons txt_y" id="id_{{i}}"  (click)="countStar(star)" *ngIf="star.selected === true">star </span>
        </span>
        <span *ngFor="let star of stars; let i=index">
          <span class="material-icons" id="id_{{i}}"  (click)="countStar(star)" *ngIf="star.selected === false"> star_border</span>
         </span>
        
      </div>
    </div>
    <div class="col-md-12">
      <mat-form-field appearance="none" class="removeappearance">
        <textarea matInput type="text" [(ngModel)]="textValue" formControlName="review" placeholder="Share details of your own experience at this place." class="form-control custom_input"
          maxlength="255" rows="3"></textarea>
        <mat-error *ngIf="ratingForm.get('review').errors?.['maxlength']" class="error_mess">Review must not exceed 255 characters.</mat-error>
      </mat-form-field>
    </div>
    <button type="submit" [disabled]="disabledButton" class="btn custum_button button_primary">
      <div *ngIf="loading" class="spinner-border spinner_border txt_w" role="status">
        <span class="sr-only"><!-- Loading... --></span>
      </div>
    <span *ngIf="!loading" class="pe-2">Post Review</span></button>
 </form>
</div>

  <div class="detail_card_con pb-4" *ngIf="(userType==='SHIPPER' && (loadDetail?.statusSlug==='delivered' || loadDetail?.statusSlug==='completed') && (loadDetail?.ratingReview?.isShipperRate === true))">
    <div class="title_action">
      <p class="line_before mb-0">Your Ratings</p>
    </div>
    <p class="fs_16 txt_l fsb_6 mb-2">#{{loadDetail?.uniqueId}}</p>
      <div class="rating_box">
          <app-rating-view [rate]="0" [type]="'listing'" [ratingOwnerDetail]="ratingOwnerDetail" [ratingReview]="loadDetail?.ratingReview" [loadDetail]="loadDetail" *ngIf="loadDetail?.ratingReview?.shipperRated=='0'"></app-rating-view>
          <app-rating-view [rate]="loadDetail?.ratingReview?.shipperRated" [type]="'listing'" [ratingOwnerDetail]="ratingOwnerDetail" [ratingReview]="loadDetail?.ratingReview" [loadDetail]="loadDetail" *ngIf="loadDetail?.ratingReview?.shipperRated > 0"></app-rating-view>
      </div>
  </div>

  
  <div class="detail_card_con pb-4" *ngIf="(userType==='CARRIER' && (loadDetail?.statusSlug==='delivered' || loadDetail?.statusSlug==='completed') && (loadDetail?.ratingReview?.isCarrierRate === true))">
    <div class="title_action">
      <p class="line_before mb-0">Your Ratings</p>
    </div>
    <p class="fs_16 txt_l fsb_6 mb-2">#{{loadDetail?.uniqueId}}</p>
      <div class="rating_box">
          <app-rating-view [rate]="loadDetail?.ratingReview?.carrierRated" [type]="'listing'" [ratingOwnerDetail]="ratingOwnerDetail" [ratingReview]="loadDetail?.ratingReview" [loadDetail]="loadDetail" *ngIf="loadDetail?.ratingReview?.carrierRated"></app-rating-view>
      </div>
  </div>

 