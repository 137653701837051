import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AlertService } from '../../commons/service/alert.service';
import { CommonService } from '../../commons/service/common.service';
import { EventEmitterService } from '../../commons/service/event-emitter.service';
import { SharedService } from '../../commons/service/shared.service';
import { AppSettings } from '../../commons/setting/app_setting';
import { StatusSetting } from '../../commons/setting/status_setting';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-view-notification',
  templateUrl: './view-notification.component.html',
  styleUrls: ['./view-notification.component.css'],
})

export class ViewNotificationComponent implements OnInit {
  public notificationList: any;
  public newnotificationList = [];
  public labelNameValue: any;
  public totalNotificationCount: any;
  public params: any;
  public totalCNotification: any;
  public loading = false;
  public notifcionsklitor = false;
  totalUnreadTabs:any;
  public userName: any;
  public scrollvariable: any;
  public pageNo = 2;
  public selectedTab="Bookings";
  public totalPages: any;
  public focus = 0;
  public userType;
  public spinnderLoader = false;
  public serverPathForUrl: any;

  constructor(
    private commonService: CommonService,
    private router: Router,
    private eventEmitterService: EventEmitterService,
    private location: Location,
    private alertService: AlertService,
    private sharedService: SharedService,
  ) {}

  ngOnInit() {
    const perm = [localStorage.getItem('user_type')];
    this.userType = localStorage.getItem('user_type');
    this.serverPathForUrl = environment.serverPathForUrl;
    this.params = {
      limit: 10,
    };
    this.userName = localStorage.getItem('userName');
    this.getData(this.selectedTab);
  }

  backHistory() {
    this.location.back();
  }

  generateFake(count: number): Array<number> {
    const indexes = [];
    for (let i = 0; i < count; i++) {
      indexes.push(i);
    }
    return indexes;
  }

  clickRefresh() {
    this.notifcionsklitor = false;
    this.params.page = null;
    this.pageNo = 1;
    this.focus = 0;
    this.spinnderLoader = false;
    this.getData(this.selectedTab);
    setTimeout(() => (this.pageNo = 2), 100);
  }

  markAllRed() {
    this.notifcionsklitor = false;
    let uri = null;
    uri = this.commonService.getAPIUriFromParams({});
    let APIparams = {
      apiKey: AppSettings.APIsNameArray.NOTIFICATION.ALLMARKREAD,
      postBody : {'tabType':this.selectedTab.toLowerCase()},
      uri: uri,
    };
    this.commonService.put(APIparams).subscribe(
      (ServerRes) => {
        if (ServerRes.success === true) {
          this.commonService.allMarkRedBellnotiion.next(null);
          for (let notiIndex in this.newnotificationList) {
            this.newnotificationList[notiIndex].isRead = 1;
          }
          localStorage.setItem('allMark', '1');
          this.eventEmitterService.userProfileName();
          this.notifcionsklitor = true;
          this.getData(this.selectedTab);    // for badge update on first click.
        } else {
          this.notifcionsklitor = true;
        }
      },
      (error) => {
        this.notifcionsklitor = true;
      }
    );
  }

  getNotifiction() {
    let APIparams;
    this.commonService.getList(APIparams).subscribe(
      (ServerRes) => {
        if (ServerRes.success === true) {
          this.loading = false;
          this.notificationList = ServerRes.response.bellNotifications;
          this.totalPages = ServerRes.response.totalPages;
          for (let notiIndex in this.notificationList) {
            this.labelNameValue = StatusSetting.messageNotifictionList.filter(
              (item) =>
                item.slugName ===
                this.notificationList[notiIndex]['data']['slug']
            );
            if (this.labelNameValue && this.labelNameValue.length > 0) {
              this.notificationList[notiIndex].notiLabel =
                this.labelNameValue[0].labelName;
            }
            this.notificationList[notiIndex].createdAt = new Date(
              this.notificationList[notiIndex]['createdAt'] + ' ' + 'UTC'
            );
          }
          this.totalNotificationCount = ServerRes.response.unreadNotifications;
          this.totalCNotification = ServerRes.response.totalNotifications;
          this.notifcionsklitor = true;
          this.newnotificationList = this.notificationList;
        } else {
          this.newnotificationList = [];
          this.totalNotificationCount = '';
          this.totalCNotification = 0;
          this.loading = false;
          this.notifcionsklitor = true;
          this.scrollvariable = false;
          this.totalPages = ServerRes.response.totalPages;
        }
      },
      (error) => {
        if (error) {
          this.newnotificationList = [];
          this.totalNotificationCount = '';
          this.totalCNotification = 0;
          this.loading = false;
          this.notifcionsklitor = true;
          this.scrollvariable = false;
          this.totalPages = 0;
        }
      }
    );
  }

  getAPIParamNewList() {
    let uri = null;
    let APIparams, params;
    params = {
      limit: 10,
      page: this.pageNo,
      tabType:this.selectedTab.toLowerCase()
    };
    APIparams = {
      apiKey: AppSettings.APIsNameArray.NOTIFICATION.BEllNOTIFICATION,
      uri: this.commonService.getAPIUriFromParams(params),
    };
    return APIparams;
  }

  getData(event:any) {
    this.selectedTab=event;
    this.notifcionsklitor = false;
    this.pageNo = 1;
    var APIparams = this.getAPIParamNewList();
    this.commonService.getList(APIparams).subscribe(
      (ServerRes) => {
        this.notifcionsklitor = false;
        if (ServerRes.response.bellNotifications.length > 0) {
          this.loading = false;
          this.notificationList = ServerRes.response.bellNotifications;
          this.totalPages = ServerRes.response.totalPages;
          this.totalUnreadTabs=ServerRes.response.unreadTabs
          for (let notiIndex in this.notificationList) {
            this.labelNameValue = StatusSetting.messageNotifictionList.filter(
              (item) =>
                item.slugName ===
                this.notificationList?.notiIndex?.data?.slug
            );
            if (this.labelNameValue && this.labelNameValue.length > 0) {
              this.notificationList[notiIndex].notiLabel =
                this.labelNameValue[0].labelName;
            }
            this.notificationList[notiIndex].createdAt = new Date(
              this.notificationList[notiIndex]['createdAt'] + ' ' + 'UTC'
            );
          }
          this.totalNotificationCount = ServerRes.response.unreadNotifications;
          this.totalCNotification = ServerRes.response.totalNotifications;
          this.notifcionsklitor = true;
          this.newnotificationList = this.notificationList;
        } else {
          this.newnotificationList = [];
          this.totalNotificationCount = '';
          this.totalCNotification = 0;
          this.loading = false;
          this.notifcionsklitor = true;
          this.scrollvariable = false;
          this.totalPages = ServerRes.response.totalPages;
        }
      },
      (error) => {
        if (error) {
          this.newnotificationList = [];
          this.totalNotificationCount = '';
          this.totalCNotification = 0;
          this.loading = false;
          this.notifcionsklitor = true;
          this.scrollvariable = false;
          this.totalPages = 0;
        }
      }
    );
  }

  onScroll() {
    if (this.pageNo !== this.totalPages) {
      this.pageNo = this.pageNo + 1;
      this.spinnderLoader = true;
      this.getMoreData(this.pageNo);
    }
  }

  getMoreData(page: any) {
    var APIparams = this.getAPIParamNewList();
    this.commonService.getList(APIparams).subscribe((ServerRes) => {
      this.spinnderLoader = false;
      if (ServerRes.response.bellNotifications.length > 0) {
        this.loading = false;
        this.notificationList = ServerRes.response.bellNotifications;
        this.totalPages = ServerRes.response.totalPages;
        for (let notiIndex in this.notificationList) {
          this.labelNameValue = StatusSetting.messageNotifictionList.filter(
            (item) =>
              item.slugName === this.notificationList[notiIndex]['data']['slug']
          );
          if (this.labelNameValue && this.labelNameValue.length > 0) {
            this.notificationList[notiIndex].notiLabel =
              this.labelNameValue[0].labelName;
          }
          this.notificationList[notiIndex].createdAt = new Date(
            this.notificationList[notiIndex]['createdAt'] + ' ' + 'UTC'
          );
        }
        this.totalNotificationCount = ServerRes.response.unreadNotifications;
        this.totalCNotification = ServerRes.response.totalNotifications;
        for (let v = 0; v < this.notificationList.length; v++) {
          if (this.notificationList[v])
            this.newnotificationList.push(this.notificationList[v]);
        }
      }
    });
  }

  readFun(Id, url) {
    if (url === '/carrier/transaction/shipments') {
      this.router.navigate(['/carrier/transaction/shipments'], {queryParams: {
        tabIndex: "0",
        orderDir: "DESC"
      }});
    } else if (url === '/carrier/transaction/buy-loads') {
      this.router.navigate(['/carrier/transaction/buy-loads'], {queryParams: {
        orderDir: "DESC",
        tabIndex: "1"
      }});
    } else if (url === '/carrier/transaction/subscription') {
      this.router.navigate(['/carrier/transaction/subscription'], {queryParams: {
        orderDir: "DESC",
        tabIndex: "2"
      }});
    } else if (url === '/carrier/transaction/additional-credit') {
      this.router.navigate(['/carrier/transaction/additional-credit'], {queryParams: {
        orderDir: "DESC",
        tabIndex: "3"
      }});
    }
  
    let postBody = {
      notiId: Id,
    };
    let APIparams = {
      apiKey: AppSettings.APIsNameArray.NOTIFICATION.NOTIREAD,
      uri: '',
      postBody: postBody,
    };
    this.commonService.put(APIparams).subscribe((success) => {
      if (success.success === true) {
        this.getNotifiction();
      } else if (success.success === false) {
      }
    });
  }

  notificationAction(id, userId, status) {
    this.loading = true;
    var APIparams = {
      apiKey: AppSettings.APIsNameArray.MANAGE_CONNECTION[status],
      uri: '',
      postBody: {
        userPkId: userId,
        bellId: id,
      },
    };
    this.commonService.post(APIparams).subscribe((ServerRes) => {
      if (ServerRes.success === true) {
        this.loading = false;
        for (let notiIndex in this.newnotificationList) {
          if (
            this.newnotificationList[notiIndex].id &&
            this.newnotificationList[notiIndex].id === id
          ) {
            this.newnotificationList[notiIndex].data.actionTaken = 0;
          }
        }
        if (status === 'CONNECT') {
          this.callGoogleAnalytics(userId);
          this.alertService.showNotificationMessage(
            'success',
            'bottom',
            'right',
            'txt_s',
            'check_circle',
            'Accept',
            ServerRes.message,
            ServerRes.isInvitationReverted
          );
        } else {
          this.alertService.showNotificationMessage(
            'success',
            'bottom',
            'right',
            'txt_s',
            'check_circle',
            'Reject',
            ServerRes.message,
            ServerRes.isInvitationReverted
          );
        }
      } else {
        this.loading = false;
        this.alertService.showNotificationMessage(
          'danger',
          'bottom',
          'right',
          'txt_d',
          'cancel',
          'Notification',
          ServerRes.message,
          ServerRes.isInvitationReverted
        );
      }
    });
  }

  callGoogleAnalytics(userId) {
    this.params = {
      id: userId,
    };
    const uri = this.commonService.getAPIUriFromParams(this.params);
    let APIparams = {
      apiKey: AppSettings.APIsNameArray.MANAGE_CONNECTION.GETPROFILE,
      uri: uri,
    };
    this.commonService.getList(APIparams).subscribe((ServerRes) => {
      if (ServerRes.success === true) {
        let targetLocation = '-';
        if (
          ServerRes.response &&
          ServerRes.response.phyCity &&
          ServerRes.response.phyState
        ) {
          targetLocation =
            ServerRes.response.phyCity + ',' + ServerRes.response.phyState;
        }
        let name;
        if (ServerRes.response.companyName) {
          name = ServerRes.response.companyName;
        }
        if (ServerRes.response.legalName) {
          name = ServerRes.response.legalName;
        }
        var obj = {
          eventName: 'network_accept_request',
          event_category: 'Network',
          Occurence: 'push_notification',
          userType: localStorage.getItem('user_type'),
          EmailID: localStorage.getItem('email'),
          dotNumber: ServerRes.response.dotNumber
            ? ServerRes.response.dotNumber
            : '-',
          TargetID: name,
          TargetLocation: targetLocation,
          SourceLocation: this.sharedService.getUserLocationType(),
        };
        this.sharedService.track(obj);
      }
    });
  }
  
}
