import { Location } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { environment } from '../../../environments/environment';
import { AlertService } from '../../commons/service/alert.service';
import { CommonService } from '../../commons/service/common.service';
import { SharedService } from '../../commons/service/shared.service';
import { AppSettings } from '../../commons/setting/app_setting';

@Component({
  selector: 'app-load-summary',
  templateUrl: './load-summary.component.html',
  styleUrls: ['./load-summary.component.scss']
})
export class LoadSummaryComponent implements OnInit {
  @Input() skeletonLoader: any;
  @Input() loadDetail: any;
  @Input() currentStatus: any;
  @Input() currentLat: any;
  @Input() currentLon: any;
  @Input() waypointData: any=[];
  @Input() waypointTotalLength: any=[];
  public driverId:any;
  public mapStatus: boolean = true;
  public createdDateTime: any;
  public levelLine = 0;
  public manTab = 'track';
  public shipmentMessage: any;
  public pingRateForm: FormGroup;
  public pingRateStatus = 1;
  public shipmentPingSubmitted=false
  public pingRateCheck = false;
  public pingRateStatusUpdate: any;
  public pingRateValue: any;
  public pingRateValueShow: any;
  public shipmentPingRate: any;
  public addPingRate: any = {};
  public editPingRate: boolean = false;
  public spinnerLoading: boolean = false;
  public clearInterValTime: any;
  public loading = false;
  public currentId: any;
  public summaryDataWaypoint: any = [];
  public serverPathForUrl: any;

  constructor(
    private route: ActivatedRoute,
    public commonService: CommonService,
    public dialog: MatDialog,
    public location: Location,
    private formBuilder: FormBuilder,
    private alertService: AlertService,
    private sharedService:SharedService,
  ) { }

  ngOnInit() {
    this.driverId=this.loadDetail?.driverId
    this.serverPathForUrl = environment.serverPathForUrl;
    let configValue = this.sharedService.getConfig();
    if (configValue !== undefined){
      this.getConfigSet(configValue);
    }
    this.commonService.mapClearApiCalling.subscribe(() => {
       this.mapStatus=false;
       this.mapEvent();
       localStorage.removeItem('mapSummary');
    });
    
    this.commonService.configData.subscribe((userData) => {
      this.getConfigSet(userData);   
    });
    if(this.loadDetail && this.loadDetail.drivers){
      this.currentLat =this.loadDetail.drivers.latitude
      this.currentLon =this.loadDetail.drivers.longitude;
    }
    this.waypointTotalLength=this.waypointTotalLength + 2;
    this.waypointData[this.waypointData.length - 1].date;

    this.summaryDataWaypoint=this.waypointData;
    this.currentId = localStorage.getItem('user_id');

     let levelCompleted;
     if(this.loadDetail.status < 46){
      levelCompleted='Open';
     }
     else if(this.loadDetail.status >= 51){
      levelCompleted='Completed';
     }
 
     let creatShipment={
      shipmentId:this.loadDetail.id,
      type:'shipment Created',
      status:'Completed',
      city:'',
      state:'',
      timeSlot:'',
      timeZone:'',
      locationLongName:'',
      dateShow: this.loadDetail?.createdAt,
     }
     let completedShipment={
      shipmentId:this.loadDetail.id,
      type:'completed',
      status:levelCompleted,
      city:'',
      state:'',       
      timeSlot:'',
      timeZone:'',
      locationLongName:'',
      dateShow: this.loadDetail?.deliverydate ? this.loadDetail?.deliverydate : this.waypointData[this.waypointData.length - 1].date,
     }  
    if(this.summaryDataWaypoint.length > 0){
      this.summaryDataWaypoint.unshift(creatShipment);
      this.summaryDataWaypoint.push(completedShipment);
    }
    if(this.waypointTotalLength===this.summaryDataWaypoint.length){
    }else{
      this.summaryDataWaypoint.shift();
      this.summaryDataWaypoint.pop();
    }
   
    for (let index = 0; index < this.summaryDataWaypoint.length; index++) {
      if(this.summaryDataWaypoint[index].type==='shipment Created'){
      this.summaryDataWaypoint[index].locationTypeImage='https://staticprod.laneaxis.com/portal/images/after-login/icon_ship_crt.png';   
      this.summaryDataWaypoint[index].daynamicClass='first_box';  
      }else if(this.summaryDataWaypoint[index].type==='pickup'){
      this.summaryDataWaypoint[index].daynamicClass='pickup_box';         
      this.summaryDataWaypoint[index].locationTypeImage='https://staticprod.laneaxis.com/portal/images/after-login/icon_pic_point.png';
      this.waypointData[index].date = this.getDateFormat(this.waypointData[index].date.toString());
      }else if(this.summaryDataWaypoint[index].type==='dropoff'){
      this.summaryDataWaypoint[index].daynamicClass='dropoff_box';         
      this.summaryDataWaypoint[index].locationTypeImage='https://staticprod.laneaxis.com/portal/images/after-login/icon_drop_point.png';
      this.waypointData[index].date = this.getDateFormat(this.waypointData[index].date.toString());
      }else if(this.summaryDataWaypoint[index].type==='completed'){
      this.summaryDataWaypoint[index].locationTypeImage='https://staticprod.laneaxis.com/portal/images/after-login/icon_ship_completed.png';
      this.summaryDataWaypoint[index].daynamicClass='last_box';     
      this.waypointData[index].dateShow = this.getDateFormat(this.waypointData[index].dateShow.toString());
      }
    }

    for (let index = 0; index < this.summaryDataWaypoint.length; index++) {
      if(this.summaryDataWaypoint[index].status==='Open'){
        this.waypointData[index - 1].zompOpen='somp_to_open';
        this.waypointData[index - 1].yuclass='last_completed';
        break;
      }else if(this.summaryDataWaypoint[index].status==='In-Transit'){
        this.waypointData[index - 1].yuclass='last_completed';
        break;
      }
    }
    this.pingRateForm = this.formBuilder.group({
      pingRateValue: ['', [Validators.max(60)]],
      pingRateStatus: ['',],
    });

    this.mapStatus = true;
    if (this.loadDetail.pingRateValue && this.loadDetail.drivers) {
      if (this.currentStatus === 'upcoming' || this.currentStatus === 'inProgress') {
        this.mapEvent();
      }
    }
    if (this.loadDetail && this.loadDetail.pingRateStatus && parseInt(this.loadDetail.pingRateStatus) === 2) {
      this.pingRateStatus = parseInt(this.loadDetail.pingRateStatus);
      this.pingRateCheck = true;
      this.pingRateValue = parseInt(this.loadDetail.pingRateValue) / 60;
      this.pingRateValueShow = parseInt(this.loadDetail.pingRateValue) / 60;
      this.pingRateStatusUpdate = 2;
    } else if (parseInt(this.loadDetail.pingRateStatus) === 1) {
      this.pingRateCheck = false;
      this.pingRateStatus = parseInt(this.loadDetail.pingRateStatus);
      this.pingRateStatusUpdate = 1;
      this.pingRateValueShow = parseInt(this.loadDetail.pingRateValue) / 60;
    } else {
      this.pingRateCheck = false;
      this.pingRateStatusUpdate = null;
      this.pingRateStatus = parseInt(this.loadDetail.pingRateStatus);
      this.pingRateValueShow = null;
    }
    this.trackShipmentStatus();
  }

  // Function to get data from config API 
  getConfigSet(configValue: any) {
    this.shipmentPingRate = configValue.onShipmentPingRate;
  }

  // Function to change date formate
  getDateFormat(inputDate: any) {
    if (inputDate !== '' && typeof inputDate !== undefined) {
      return inputDate.replaceAll('-', '/');
    } else {
      return '';
    }
  }

  // Funtion to get shipment status from API
  trackShipmentStatus(params = {}) {
    let uri = null;
    let newParams = {
      id: this.route.snapshot.params['loadId'],
    };
    if (newParams) uri = this.commonService.getAPIUriFromParams(newParams);
    let APIparams = {
      apiKey: AppSettings.APIsNameArray.SHIPMENT.GET,
      uri: uri,
    };
    this.commonService.getList(APIparams).subscribe(
      async (ServerRes) => {
        if (ServerRes.success === true) {
          if (ServerRes.response.status) {
            if (
              ServerRes.response.status > 11 &&
              ServerRes.response.status <= 20
            ) {
              this.levelLine = 1;
            } else if (
              ServerRes.response.status > 20 &&
              ServerRes.response.status <= 23
            ) {
              this.levelLine = 2;
            } else if (
              ServerRes.response.status >= 24 &&
              ServerRes.response.status <= 42
            ) {
              this.levelLine = 3;
            } else if (
              ServerRes.response.status >= 43 &&
              ServerRes.response.status <= 50
            ) {
              this.levelLine = 4;
            } else if (
              ServerRes.response.status === 51 ||
              ServerRes.response.status > 51
            ) {
              this.levelLine = 5;
            }
          }
          this.createdDateTime = new Date(
            ServerRes.response.createdAt + ' ' + 'UTC'
          );
          ServerRes.response.deliverydate = ServerRes.response.deliverydate + ' ' + 'UTC'
          this.getMilesCompleted(ServerRes.response);

          this.manTab = 'track_shipment';
          this.skeletonLoader = false;
          this.loading = false;
        } else {
          this.skeletonLoader = false;
          this.shipmentMessage = 'Shipment not found';
          this.loading = false;
        }
      },
      (error) => {
        if (error) {
          this.skeletonLoader = false;
          this.shipmentMessage = 'Shipment not found';
          this.loading = false;
        }
      }
    );
  }

 // Funtion to get completed miles by status 
  getMilesCompleted(shipment) {
    let milesCompleted = 0;
    if (
      shipment.status === 51 ||
      shipment.status === 53 ||
      shipment.status === 54 ||
      shipment.status === 55 ||
      shipment.status === 58
    ) {
      this.loadDetail.tripStatus = {
        milesCompleted: shipment.miles,
      };
    }
  }

  //To copy web tracking url
  getWebTrackingLink() {
    if (
      this.loadDetail &&
      this.loadDetail.shippers &&
      this.loadDetail.shippers.email
    ) {
      var url = environment.domainPoint + '/load/track-your-load?load_id=' + this.loadDetail.uniqueId 
    } 
    else if (this.loadDetail && this.loadDetail.shipperEmail) {
      url = environment.domainPoint + '/load/track-your-load?load_id=' + this.loadDetail.uniqueId 
    }
    this.copyWebTrackingLink(url);
  }
  
  //copying web tracking link
  copyWebTrackingLink(val: string) {
    let selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.alertService.showNotificationMessage(
      'success',
      'bottom',
      'right',
      'txt_s',
      'check_circle',
      'Web Tracking Link',
      'Web tracking link copied.'
    );
  }

  // Function to get Track Shipment Condition 
  checkWebTrackShipmentCondition() {
    if (
      this.loadDetail &&
      this.loadDetail.shippers &&
      this.loadDetail.shippers.email &&
      this.loadDetail.carriers &&
      this.loadDetail.status !== '59'
    ) {
      return true;
    } else if (
      this.loadDetail &&
      this.loadDetail.shipperEmail &&
      this.loadDetail.carriers &&
      this.loadDetail.status !== '59'
    ) {
      return true;
    } else {
      return false;
    }
  }

  // Ping rate section for map refresh
  refreshMap() {
    this.spinnerLoading = true;
    if (this.loadDetail.drivers && this.loadDetail.drivers.userId === this.currentId) {
      this.getCarrierRecordInfo();
    } else {
      this.getDriverInfo();
    }
  }

   // Ping rate section for map event 
  mapEvent() {
    let intervalTime = this.loadDetail.pingRateValue * 1000;
    if (this.mapStatus) {
      localStorage.setItem('mapSummary', "calling");
      this.clearInterValTime = setInterval(() => {
        if (this.loadDetail.drivers && this.loadDetail.drivers.userId === this.currentId) {
          this.getCarrierRecordInfo();
        } else {
          this.getDriverInfo();
        }
      }, intervalTime);
    } else {
      clearInterval(this.clearInterValTime);
    }
  }

  // Function to get API params
  getAPIParamInfo() {
    let APIparams, params, urlType;
    urlType = AppSettings.APIsNameArray.CARRIER.GET;
    params = { id: this.currentId };
    APIparams = {
      apiKey: urlType,
      uri: this.commonService.getAPIUriFromParams(params),
    };
    return APIparams;
  }

  // Function to get carrier record
  getCarrierRecordInfo() {
    var APIparams = this.getAPIParamInfo();
    this.commonService.getList(APIparams).subscribe((ServerRes) => {
      if (ServerRes.success === true) {
        this.spinnerLoading = false;
        this.currentLat = ServerRes.response.latitude;
        this.currentLon = ServerRes.response.longitude;
      }
    },
    (error) => {
      this.spinnerLoading = false;
     }
     );
  }

  // Function to get driver info
  getDriverInfo() {
    this.driverRecordCall();
  }

  // Function to get driver record
  driverRecordCall() {
    let uri = null;
    let newParams = {
      driverId: this.driverId,
    };
    if (newParams) uri = this.commonService.getAPIUriFromParams(newParams);
    let APIparams = {
      apiKey: AppSettings.APIsNameArray.DRIVER.GET,
      uri: uri,
    };
    this.commonService.getList(APIparams).subscribe(
      (ServerRes) => {
        this.spinnerLoading = false;
        if (ServerRes.success === true) {
          this.currentLat = ServerRes.response.locationLat;
          this.currentLon = ServerRes.response.locationLong;
        } else {
        }
      },
      (error) => {
        this.spinnerLoading = false;
       }
    );
  }

  // Function to submit ping rate form 
  pingRateFormSubmit({ value, valid }) {
    this.shipmentPingSubmitted=true;
    if (valid) {
      if (this.pingRateStatus === 2) {
        this.addPingRate.pingRateValue = parseInt(this.pingRateForm.get('pingRateValue').value) * 60;
        this.addPingRate.pingRateStatus = 2;
      } else {
        this.addPingRate.pingRateValue = parseInt(this.shipmentPingRate) * 60;
        this.addPingRate.pingRateStatus = 1;
      }
      this.addPingRate.id = this.route.snapshot.paramMap.get('loadId');
        this.addPingRate.slug = "onlyPingRateChange"
      this.loading = true;
      let APIparams = {
        apiKey: AppSettings.APIsNameArray.SHIPMENT.UPDATE,
        uri: '',
        postBody: this.addPingRate,
      };
      this.commonService
        .putAllValue(APIparams)
        .subscribe(
          (success) => {
            if (success.success === true) {
              this.editPingRate = false;
              clearInterval(this.clearInterValTime);
              this.loadDetail.pingRateValue = this.addPingRate.pingRateValue;
              this.pingRateStatus = this.addPingRate.pingRateStatus;
              if (this.addPingRate.pingRateStatus === 1) {
                this.pingRateStatusUpdate = 1;
                this.pingRateValue = null;
                this.pingRateForm.get('pingRateValue').setValue('');
                this.pingRateCheck = false;
                this.pingRateValueShow = this.addPingRate.pingRateValue / 60;
              } else if (this.addPingRate.pingRateStatus === 2) {
                this.pingRateStatusUpdate = 2;
                this.pingRateCheck = true;
                this.pingRateValue = this.addPingRate.pingRateValue / 60;
                this.pingRateValueShow = this.addPingRate.pingRateValue / 60;
              } else {
                this.pingRateStatusUpdate = null
                this.pingRateValueShow = null;
              }
              this.mapEvent();
              this.alertService.showNotificationMessage(
                'success',
                'bottom',
                'right',
                'txt_s',
                'check_circle',
                'Shipment Update',
                'Your shipment has updated successfully.'
              );
            }
            else {
              this.alertService.showNotificationMessage(
                'danger',
                'bottom',
                'right',
                'txt_d',
                'cancel',
                'Shipment Update Fail',
                success.message
              );
            }
            this.loading = false;
          },
          (error) => {
            this.loading = false;
            this.alertService.showNotificationMessage(
              'danger',
              'bottom',
              'right',
              'txt_d',
              'cancel',
              'Shipment Update Fail',
              error.message
            );
          }
        );
    }
  }

  // Function to set ping rate 
  setPingRate(event: any) {
    if (event.checked === true) {
      this.shipmentPingSubmitted=false;

      this.pingRateStatus = 2;
      this.pingRateForm.get('pingRateValue').setValue(this.pingRateValue);
      this.pingRateForm
        .get('pingRateValue')
        .setValidators([Validators.required, Validators.max(60)]);
    } else {
      this.shipmentPingSubmitted=false;
      this.pingRateStatus = 1;
      this.pingRateForm.get('pingRateValue').clearValidators();
      this.pingRateForm.get('pingRateValue').setValue('');
      this.pingRateForm.get('pingRateValue').markAsUntouched();
    }
  }

  // Function to edit ping rate 
  editPingrate(type: any) {
    if (type === true) {
      this.editPingRate = type;
      if (this.pingRateStatus === 2) {
        this.pingRateForm = this.formBuilder.group({
          pingRateValue: [this.pingRateValue, [Validators.required, Validators.max(60)]],
          pingRateStatus: [this.pingRateCheck,],
        });
      } else {
        this.pingRateForm = this.formBuilder.group({
          pingRateValue: [this.pingRateValue,],
          pingRateStatus: [this.pingRateCheck,],
        });
      }
    } else {
      if (this.pingRateStatusUpdate === 2) {
        this.pingRateStatus = 2;
        this.editPingRate = type
      } else {
        this.pingRateStatus = 1;
        this.editPingRate = type
        this.pingRateForm.get('pingRateValue').setValue('');
      }

    }
  }

  // Function to enable/disable ping rate option
  pingRateCheckDcisabled() {
    if ((this.loadDetail &&  (this.loadDetail.status === 15  || this.loadDetail.status >= 51))) {
      return false;
    } else {
      return true;
    }
  }

  // Function to show ping rate value
  pingRateValueShowPing(pingValue:any){
    if(pingValue==1){
      return pingValue +" Minute";
    }else if(pingValue >1){
      return pingValue +" Minutes";
    }else{
      return "-"
    }
  }

}
