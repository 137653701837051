<div class="crt_Ship_body">
    <div class="crt_ship_head">
      <div class="container">
        <div class="edit_header">
          <span class="material-icons page_back" (click)="backHistory()">arrow_back</span>
          <span class="ship_head_title mb-0 ">Create Load</span>
        </div>
      </div>
    </div>
    <div class="card_container rounded_h_tab">
      <div class="container {{ activeStatus }}" *ngIf="manageSection === 'shipment'">
        <mat-horizontal-stepper #stepper>
          <mat-step label="Details">
            <ng-template matStepLabel><span *ngIf="'carrierDetailsShow || addFreightRateShow' && publicTab"
                class="material-icons me-2 one spet_checked"
                id="CreateShipmentShipper_flow013_step1_appcues">check_circle</span>
              <span class=" fsm_5">Details</span>
            </ng-template>
            <div class="ship_create">
              <div class="waypoint">
                <p class="step_discription ">Fill out the details below to quickly create a load and assign it to your
                  preferred carrier or broadcast it for bidding.</p>
                <div class="row mb-4">
                  <div class="location_added_card_con col-md-3"
                    *ngFor="let wayPointCared of pickCardArrays; let i = index"
                    [ngClass]="wayPointCared?.same == [i] ? 'error_card' : ''">
                    <div class="location_added_card" [ngClass]="wayPointCared?.type === 'pickup' ? 'l_pick' : 'l_drop'">
                      <div class="lac_l">
                        <div class="lac_lt">
                          <p class="lac_tlt">{{wayPointCared?.type | titlecase}} Waypoint {{wayPointCared?.locationOrder}}
                          </p>
                          <p class="lac_dis">{{wayPointCared?.locationLongName}}</p>
                          <p *ngIf="wayPointCared.date" class="lac_dis" style="position: absolute;">{{wayPointCared?.date | date: 'MMM dd, yyyy'}} at {{wayPointCared?.timeSlot ? wayPointCared?.timeSlot : '-'}}
                            {{wayPointCared?.timeZone ? wayPointCared?.timeZone : "-"}}</p>
                        </div>
                      </div>
                      <div class="lac_r">
                        <div class="d-flex">
                          <img *ngIf="!wayPointCared.date"
                            src="https://s3.us-east-1.amazonaws.com/staticprod.laneaxis.com/portal/images/after-login/edit_date.png"
                            class="img-fluid">
                          <img (click)="editWayPointCard(wayPointCared,i)"
                            src="https://s3.us-east-1.amazonaws.com/staticprod.laneaxis.com/portal/images/after-login/edit_lb.svg"
                            class="img-fluid">
                        </div>
                      </div>
                    </div>
                    <p class="error_mess upm_mess" *ngIf="wayPointCared?.same == [i]">This waypoint date & time should
                      always be grathen then the last waypoint Date & Time.</p>
                    <p class="error_mess upm_mess" *ngIf="updateFirstError[i]">Update Mandatory Information from first
                      lane to last lane.</p>
                  </div>
                </div>
                <p class=" banner_update " *ngIf="!showNextBlowSection">Please click on the edit icon to update the date &
                  time for the card with red sign.</p>
  
                <form [formGroup]="wayPointForm" (ngSubmit)="wayPointFormSubmit(wayPointForm)"
                  class="mb-0 custum_input_css" autocomplete="off" *ngIf="notSaved">
                  <div class="crt_card pb-3">
                    <div class="cc_head">
                      <div class="cc_head_l">
                        <p class="line_before" *ngIf="editSectionShow">
                          <span class="">Edit Waypoint Details</span>
                          <span [ngClass]="editSelecteData.type === 'pickup' ? 'l_pick' : 'l_drop'">{{editSelecteData.type | titlecase}} Waypoint {{editSelecteData?.locationOrder}} </span>
                        </p>
                        <p class="line_before " *ngIf="!editSectionShow">{{ pickCardArrays.length > 0 ? 'Add Waypoint Details' : 'Add Pickup Details' }}</p>
                      </div>
                      <span *ngIf="checkCrossButton" class="close_popup_Icon" (click)="waypointPopupClose()">
                        <img src="{{serverPathForUrl}}/portal/icon/ic_close_white.svg" class="close_Icon">
                      </span>
                    </div>
  
                    <div class="row">
                      <div class="col-md-3">
                        <div class="cc_head_r custom_redio_btn"
                          *ngIf="pickCardArrays.length > 0 && selectedEditIndex !==0 && firstStorageOrder !=='pickup'">
                          <mat-radio-group formControlName="wayPointType" aria-label="Select an option"
                            (change)="changeLocationType($event)">
                            <mat-radio-button value="pickup"><span class="fs_16 fsm_5 ">Pickup</span></mat-radio-button>
                            <mat-radio-button value="dropoff" class="ml_24"><span
                                class="fs_16 fsm_5 ">Dropoff</span></mat-radio-button>
                          </mat-radio-group>
                        </div>
                      </div>
                      <div class="col-md-9"></div>
                      <div class="col-md-3">
                        <p class="fs_14 fsn_4 txt_lb mb-1">Business Name</p>
                        <mat-form-field appearance="none" class="removeappearance">
                          <input matInput type="text" formControlName="pickupBussinessName"
                            placeholder="Enter Business Name" maxlength="65" class="form-control custom_input" />
                          <mat-error *ngIf="wayPointForm.get('pickupBussinessName')?.errors?.['maxlength']"
                            class="error_mess">Only 64 characters are allowed.</mat-error>
                        </mat-form-field>
                      </div>
                      <div class="col-md-3">
                        <p class="fs_14 fsn_4 txt_lb mb-1">
                          <span>{{ pickCardArrays.length > 0 ? 'Waypoint Location' : 'Pickup Location' }}</span>
                          <span class="txt_d"> *</span>
                        </p>
                        <div fxFlex fxFlexAlign="center" class="autocomplete-container">
                          <mat-form-field appearance="none" class="removeappearance ">
                            <input
                              matTooltip="Network Carriers will only see pickup and dropoff cities, but exact addresses will only be shown to Carrier who is assigned the load."
                              matInput ngx-gp-autocomplete (onAddressChange)="pickUpLocAuto($event)"
                              (input)="noAutoselection('pick')" (focusout)="noFocusOut('pick')"
                              (onAutocompleteSelected)="pickAutoCompleteSelected($event)"
                              [value]="wayPointForm.get('pickupLocation').value" formControlName="pickupLocation"
                              maxlength="257" placeholder="Enter Exact Address"
                              class="form-control custom_input text_truncate" [country]="searchRestriction" required />
                            <mat-error
                              *ngIf="submitted && !pickAutoNot && !sameCityMust && wayPointForm.get('pickupLocation')?.errors?.['required']"
                              class="error_mess"> Waypoint Location is required.</mat-error>
                            <mat-error *ngIf="!pickAutoNot && wayPointForm.get('pickupLocation')?.errors?.['pattern']"
                              class="error_mess"> First letter should not be space.</mat-error>
                            <small class="city_labl_address error_mess"
                              *ngIf="pickupCityError && wayPointForm.value.pickupLocation"> {{ pickupCityError }}</small>
                            <small class="city_labl_address error_mess"
                              *ngIf="shortNameValue && wayPointForm.value.pickupLocation"> {{ shortNameError }}</small>
                            <mat-error *ngIf="!pickAutoNot && wayPointForm.get('pickupLocation')?.errors?.['maxlength']"
                              class="error_mess"> Only 256 characters are allowed.</mat-error>
                            <small class="city_labl_address error_mess" *ngIf="sameCityMust">Old and New address should be
                              of same city.</small>
                          </mat-form-field>
                        </div>
                      </div>
                      <div class="col-md-3">
                        <p class="fs_14 fsn_4 txt_lb mb-1">ZIP Code <span class="txt_d"> *</span></p>
                        <mat-form-field appearance="none" class="removeappearance ">
                          <input matInput type="text" formControlName="pickupPincode" placeholder="Enter ZIP Code"
                            class="form-control custom_input" minlength="4" maxlength="8" />
                          <mat-error *ngIf="submitted && wayPointForm.get('pickupPincode')?.errors?.['required']"
                            class="error_mess">ZIP Code is required.</mat-error>
                          <mat-error class="error_mess"
                            *ngIf="(wayPointForm.get('pickupPincode')?.errors?.['minlength']) && !(wayPointForm.get('pickupPincode')?.errors?.['pattern'])">ZIP
                            Code should be minimum 4 digit.</mat-error>
                          <mat-error *ngIf="wayPointForm.get('pickupPincode')?.errors?.['pattern']"
                            class="error_mess">{{zipCodeErrorMsg}}</mat-error>
                        </mat-form-field>
                      </div>
                      <div class="col-md-3 ic_calander">
                        <p class="fs_14 fsn_4 txt_lb mb-1">
                          Date<span class="txt_d"> *</span>
                        </p>
                        <mat-form-field appearance="none" class="removeappearance" class="drop_bg cal_input">
                          <input matInput formControlName="pickupDate" [matDatepicker]="dp2" placeholder="Select Date"
                            [min]="minDate" [max]="maxDate" (dateChange)="pickDateEvent($event)" [readonly]="true"
                            class="form-control custom_input" />
                          <mat-datepicker-toggle matSuffix [for]="dp2"></mat-datepicker-toggle>
                          <mat-datepicker #dp2 disabled="false"></mat-datepicker>
                          <mat-error *ngIf="submitted && wayPointForm.get('pickupDate')?.errors?.['required']"
                            class="error_mess">Waypoint Date is required.</mat-error>
                          <mat-error *ngIf="wayPointForm.get('pickupDate')?.errors?.['matDatepickerMin']"
                            class="error_mess">Waypoint Date is Expired.</mat-error>
                        </mat-form-field>
                      </div>
                      <div class="col-md-3 ic_dropdown">
                        <p class="fs_14 fsn_4 txt_lb mb-1">
                          Select Time <span class="txt_d"> *</span>
                        </p>
                        <mat-form-field appearance="none" class="removeappearance">
                          <mat-select placeholder="Select Time" formControlName="pickupTime" name="pickupTime"
                            id="pickupTime" class="form-control custom_input">
                            <mat-option *ngFor="let timevalue of pickupTimeArr" [value]="timevalue.time"
                              [disabled]="timevalue.disabled">{{ timevalue.time}}</mat-option>
                          </mat-select>
                          <mat-error *ngIf="submitted && wayPointForm.get('pickupTime')?.errors?.['required']"
                            class="error_mess">Waypoint Time is required.</mat-error>
                        </mat-form-field>
                      </div>
                      <div class="col-md-3 ic_dropdown">
                        <p class="fs_14 fsn_4 txt_lb mb-1">
                          Time Zone <span class="txt_d"> *</span>
                        </p>
                        <mat-form-field appearance="none" class="removeappearance">
                          <mat-select formControlName="pickupTimeZone" [(ngModel)]="timeZoneSelectedPick"
                            name="pickupTimeZone" id="pickupTimeZone" placeholder="Select Time Zone"
                            class="form-control custom_input">
                            <mat-option *ngFor="let piTimeZone of pickupTZ" [value]="piTimeZone.tzShort"
                              matTooltip="{{ piTimeZone.tzLong }}">
                              {{ piTimeZone.tzShort }}
                            </mat-option>
                          </mat-select>
                          <mat-error *ngIf="submitted && wayPointForm.get('pickupTimeZone')?.errors?.['required']"
                            class="error_mess">Waypoint Time Zone is required.</mat-error>
                        </mat-form-field>
                      </div>
                      <div class="col-md-3">
                        <p _ngcontent-ltp-c458="" class="fs_14 fsn_4 txt_lb mb-1">Contact Number
                          <img [src]="defualtPickupCountryFlag" name="cla" width="auto" height="20px"
                            style="margin-left: 5px;">
                        </p>
                        <div class="ic_dropdown  shipper_ship_create">
                          <mat-form-field appearance="none" class="frm_cunty_code">
                            <div class="country_code_fld">
                              <mat-select formControlName="pickupCountryCode"
                                (selectionChange)="selectPickCountryCode($event)" class="form-control custom_input">
                                <mat-option *ngFor="let cou of countryList" [value]="cou.countryAbbr">
                                  <img [src]="cou.flag" name="cla" width="20px" height="20px">
                                  <span style="margin-left: 5px;">+{{cou.countryCode}}</span>
                                </mat-option>
                              </mat-select>
                            </div>
                          </mat-form-field>
                          <mat-form-field appearance="none" class="frm_phn_no">
                            <div class="country_code_fld">
                              <input matInput type="text" formControlName="pickPhoneNumber" placeholder="Enter Contact No"
                                class="form-control custom_input" (input)="keyPaste($event)" (keypress)="keyPress($event)"
                                maxlength="10" />
                              <mat-error *ngIf="wayPointForm.get('pickPhoneNumber')?.errors?.['required']"
                                class="error_mess">Waypoint Contact Number is required.</mat-error>
                              <mat-error *ngIf="wayPointForm.get('pickPhoneNumber')?.errors?.['pattern']"
                                class="error_mess">Please enter valid information.</mat-error>
                              <mat-error
                                *ngIf="(wayPointForm.get('pickPhoneNumber')?.errors?.['minlength']) && !(wayPointForm.get('pickPhoneNumber')?.errors?.['pattern'])"
                                class="error_mess">Contact Number should be 10 digit.</mat-error>
                            </div>
                          </mat-form-field>
                        </div>
                      </div>
                      <div class="col-md-3">
                        <p class="fs_14 fsn_4 txt_lb mb-1 mb-1">Contact Name</p>
                        <mat-form-field appearance="none" class="removeappearance" class="input_suffix">
                          <input matInput type="text" formControlName="pickupContactName" maxlength="65"
                            placeholder="Enter Contact Name" class="form-control custom_input" />
                          <mat-error *ngIf="wayPointForm.get('pickupContactName')?.errors?.['maxlength']"
                            class="error_mess">Only 64 characters are allowed.</mat-error>
                        </mat-form-field>
                      </div>
                      <div class="col-md-12">
                        <p class="fs_14 fsn_4 txt_lb mb-1 insraction_label">
                          <span>Special Req </span>
                          <span>(516 Characters)</span>
                        </p>
                        <mat-form-field appearance="none" class="removeappearance">
                          <textarea matInput type="text" formControlName="pickInstructions" placeholder="Special Req"
                            (keypress)="keyInstructionPress($event, 'pickup')" class="form-control custom_input"
                            maxlength="517" rows="3"></textarea>
                          <mat-error *ngIf="wayPointForm.get('pickInstructions')?.errors?.['required']"
                            class="error_mess">Waypoint Instructions is required.</mat-error>
                          <mat-error *ngIf="wayPointForm.get('pickInstructions')?.errors?.['maxlength']">Only 516
                            characters are allowed</mat-error>
                        </mat-form-field>
                      </div>
                      <div class="col-md-12" *ngIf="!ifPickupLoadInfo">
                        <span type="button" class="add_goods" (click)="addLoadInformation()">
                          <span class="ms-1 ">Add Commodity</span>
                          <span class="material-icons txt_p fs_20 ms-1">add_circle</span>
                        </span>
                      </div>
                      <div class="ship_goods_info" *ngIf="ifPickupLoadInfo">
                        <div formArrayName="commodityForm">
                          <p class="line_before ">Commodity</p>
                          <div class="sgi_row" *ngFor="let communityData of commodityForm.controls; let i = index;"
                            [formGroupName]="i">
                            <div class="row ship_by_lane_row">
                              <div class="dimension_txt">Dimensions</div>
  
                              <div class="col-md-3">
                                <p class="fs_14 fsn_4 txt_lb mb-1">
                                  Commodity <span class="txt_d"> *</span>
                                </p>
                                <mat-form-field appearance="none">
                                  <input matInput type="text" formControlName="commodity" placeholder="Enter Commodity"
                                    maxlength="129" class="form-control custom_input" />
                                  <mat-error
                                    *ngIf="communityDataSubmiited && communityData?.get('commodity')?.errors?.['required']"
                                    class="error_mess">Commodity is required</mat-error>
                                  <mat-error *ngIf="communityData?.get('commodity')?.errors?.['maxlength']"
                                    class="error_mess">Only 128 characters are allowed</mat-error>
                                </mat-form-field>
                              </div>
                              <div class="col-md-3 ic_dropdown">
                                <p class="fs_14 fsn_4 txt_lb mb-1">Packaging Type <span class="txt_d"> *</span></p>
                                <mat-form-field appearance="none">
                                  <mat-select class="form-control custom_input" formControlName="packagingType"
                                    placeholder="Select Packaging Type">
                                    <mat-option value="Pallet">Pallet</mat-option>
                                    <mat-option value="Skid">Skid</mat-option>
                                    <mat-option value="Crate">Crate</mat-option>
                                    <mat-option value="Box">Box</mat-option>
                                  </mat-select>
                                  <mat-error
                                    *ngIf="communityDataSubmiited && communityData?.get('packagingType')?.errors?.['required']"
                                    class="error_mess">Packaging type is required</mat-error>
                                </mat-form-field>
                              </div>
                              <div class="col-md-3">
                                <p class="fs_14 fsn_4 txt_lb mb-1">Quantity <span class="txt_d"> *</span></p>
                                <mat-form-field appearance="none">
                                  <input matInput type="text" formControlName="quantity" placeholder="Enter Quantity"
                                    maxlength="129" appZeroNotAllowFirst class="form-control custom_input" />
                                  <mat-error
                                    *ngIf="communityDataSubmiited && communityData?.get('quantity')?.errors?.['required']"
                                    class="error_mess">Quantity is required</mat-error>
                                </mat-form-field>
                              </div>
                              <div class="col-md-3">
                                <p class="fs_12 fsn_4 txt_lb mb-1">Length</p>
                                <mat-form-field appearance="none">
                                  <input matInput type="text" appDimension formControlName="length" placeholder=""
                                    class="form-control custom_input p_9" maxlength="3" appZeroNotAllowFirst
                                    (keyup)="dimensionCombiner(i)" />
                                  <mat-error *ngIf="communityData?.get('length')?.errors?.['required']"
                                    class="error_mess">Please enter length</mat-error>
                                </mat-form-field>
                              </div>
                              <div class="col-md-3">
                                <p class="fs_12 fsn_4 txt_lb mb-1">Width</p>
                                <mat-form-field appearance="none">
                                  <input matInput type="text" appDimension formControlName="width" placeholder=""
                                    class="form-control custom_input p_9" maxlength="3" appZeroNotAllowFirst
                                    (keyup)="dimensionCombiner(i)" />
                                  <mat-error *ngIf="communityData?.get('width')?.errors?.['required']"
                                    class="error_mess">Please enter width</mat-error>
                                </mat-form-field>
                              </div>
                              <div class="col-md-3">
                                <p class="fs_12 fsn_4 txt_lb mb-1">Height</p>
                                <mat-form-field appearance="none">
                                  <input matInput type="text" appDimension formControlName="height" placeholder=""
                                    class="form-control custom_input p_9" maxlength="3" appZeroNotAllowFirst
                                    (keyup)="dimensionCombiner(i)" />
                                  <mat-error *ngIf="communityData?.get('height')?.errors?.['required']"
                                    class="error_mess">Please enter height</mat-error>
                                </mat-form-field>
                              </div>
                              <div class="col-md-3">
                                <p class="fs_14 fsn_4 txt_lb mb-1">Weight</p>
                                <mat-form-field appearance="none">
                                  <input matInput type="text" formControlName="weight" placeholder="Enter Weight"
                                    appZeroNotAllowFirst maxlength="6" class="form-control custom_input" />
                                </mat-form-field>
                              </div>
                              <div class="col-md-3">
                                <p class="fs_14 fsn_4 txt_lb mb-1">$ Value</p>
                                <mat-form-field appearance="none">
                                  <input matInput type="text" formControlName="value" placeholder="Enter Value"
                                    maxlength="65" appZeroNotAllowFirst class="form-control custom_input" />
                                </mat-form-field>
                              </div>
                              <div class="col-md-3">
                                <p class="fs_14 fsn_4 txt_lb mb-1">SKU #</p>
                                <mat-form-field appearance="none">
                                  <input matInput type="text" formControlName="sku" placeholder="Enter SKU" maxlength="15"
                                    class="form-control custom_input" />
                                  <mat-error
                                    *ngIf="!communityData?.get('sku')?.errors?.['maxlength'] && communityData?.get('sku')?.errors?.['pattern']"
                                    class="error_mess">Only alpha numeric are allowed.</mat-error>
                                </mat-form-field>
                              </div>
                              <div class="col-md-3 upload_document">
                                <p class="fs_14 fsn_4 txt_lb mb-1">Upload Image</p>
                                <mat-form-field appearance="none" class="custom_matSuffix_icon">
                                  <input matInput type="text" placeholder="Choose File.."
                                    class="form-control custom_input text_truncate" id="media_{{i}}" readonly="readonly">
                                  <span class="material-iconsicon">
                                    <input class="inputfile" id="file_{{i}}" name="file" type="file"
                                      style="display: none;" (change)="onSelectedUploadeImage($event,i)"
                                      accept=".png, .jpg, .jpeg">
                                    <label class="btn btn-primary" for="file_{{i}}">
                                      <i class="fa-solid fa-upload fs_16 txt_lb"></i>
                                    </label>
                                  </span>
                                </mat-form-field>
                                <div *ngIf="currentIndex===i">
                                  <span *ngIf="showErrorImage" class="fs_11 txt_d fsm_5 ps-1">{{showErrorImage}}</span>
                                </div>
  
                              </div>
                              <div class="col-md-3">
                                <p class="fs_14 fsn_4 mb-2 ope_0">QWE</p>
                                <div class="uploded_img_box" *ngIf="wayPointForm.controls.commodityForm.value[i].media">
                                  <span class="material-icons txt_d remove_row_icn" (click)="removeMedia(i)">cancel</span>
                                  <img src="{{wayPointForm.controls.commodityForm.value[i].media}}" class="img-fluid">
                                </div>
                                <div class="uploded_img_loader" *ngIf="currentIndex===i && !showErrorImage">
                                  <img src="{{serverPathForUrl}}/portal/images/after-login/LaneAxis-loader.gif"
                                    class="img-fluid">
                                </div>
                              </div>
                              <div class="col-md-6 custom_checkbox mb-3">
                                <mat-checkbox class="form-control border-0 ps-0" formControlName="handleWithCare"
                                  style="border: none;">Handle With Care</mat-checkbox>
                              </div>
                              <span (click)="removeCommunityField(i)"
                                class="material-icons txt_d fs_18 remove_row_icn">cancel</span>
                            </div>
                          </div>
                        </div>
                        <span type="button" class="add_goods" (click)="addCommunityField()">
                          <span class="ms-1 ">Add Another Commodity</span>
                          <span class="material-icons txt_p fs_20 ms-1">add_circle</span>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="way_point_action" *ngIf="hideButtonSection">
                    <button *ngIf="editSectionShow" [disabled]="shortNameValue || buttonLoaderSave || sameCityMust"
                      class="btn custum_button button_success ml-4" type="submit">
                      <span class="spinner-border txt_w" *ngIf="buttonLoaderSave">
                        <span class="sr-only"></span>
                      </span>
                      <span>Save</span>
                    </button>
                  </div>
                </form>
              </div>
  
              <div class="shipmentDetail" *ngIf="showNextBlowSection">
                <form [formGroup]="shipmentDetailsForm" (ngSubmit)="shipmentDetailsFormSubmit(shipmentDetailsForm)"
                  class="mb-0 custum_input_css">
                  <div class="crt_card ship_goods_info">
                    <p class="line_before ">Equipment Details</p>
                    <div class="row">
                      <div class="col-md-3 ic_dropdown">
                        <p class="fs_14 fsn_4 txt_lb mb-1">
                          Equipment Type <span class="txt_d"> *</span>
                        </p>
                        <mat-form-field appearance="none" class="removeappearance" class="drop_bg">
                          <mat-select placeholder="Select Equipment Type" class="form-control custom_input"
                            formControlName="equipmentType" (selectionChange)="selectEquipmentLength($event)">
                            <mat-option *ngFor="let equi of equipmentListing" [value]="equi.id">{{equi.label}}
                            </mat-option>
                          </mat-select>
                          <mat-error *ngIf="shipmentDetailsForm.get('equipmentType')?.errors?.['required']"
                            class="error_mess">Equipment Type is required.</mat-error>
                        </mat-form-field>
                      </div>
                      <div class="col-md-3 ic_dropdown">
                        <p class="fs_14 fsn_4 txt_lb mb-1">
                          Equipment Length<span class="txt_d"> *</span>
                        </p>
                        <mat-form-field appearance="none" class="removeappearance" class="drop_bg">
                          <mat-select placeholder="Select Equipment Length" class="form-control custom_input"
                            formControlName="equipmentTypeLength" [disabled]="checkEmptyValue == '0' ? true : false">
                            <mat-option *ngFor="let equipLength of equipmentLengthList"
                              [value]="equipLength.lengthValue">{{ equipLength.lengthValue }}</mat-option>
                          </mat-select>
                          <mat-error *ngIf="shipmentDetailsForm.get('equipmentTypeLength')?.errors?.['required']"
                            class="error_mess">Equipment length is required.</mat-error>
                        </mat-form-field>
                      </div>
                      <div class="col-md-3">
                        <p class="fs_14 fsn_4 txt_lb mb-1">Purchase Order</p>
                        <mat-form-field appearance="none" class="removeappearance" class="input_suffix">
                          <input matInput type="text" [(ngModel)]="purchaseOrderId" formControlName="purchaseOrderId"
                            placeholder="Purchase Order" class="form-control custom_input" maxlength="65" />
                          <mat-error *ngIf="shipmentDetailsForm.get('purchaseOrderId')?.errors?.['maxlength']"
                            class="error_mess">Only 64 characters are allowed.</mat-error>
                        </mat-form-field>
                      </div>
                      <div class="col-md-3 ic_dropdown">
                        <p class="fs_14 fsn_4 txt_lb mb-1">Accessorials</p>
                        <mat-form-field appearance="none" class="removeappearance" class="drop_bg">
                          <mat-select placeholder="Select Accessorials" multiple class="form-control custom_input"
                            formControlName="trailerSpecs" (selectionChange)="selectTrailerSpecs($event)">
                            <mat-option *ngFor="let trailerSp of trailerSpecsRequipment"
                              [value]="trailerSp.slug">{{trailerSp.label}}</mat-option>
                          </mat-select>
                        </mat-form-field>
                        <span *ngIf="selectedTrailerSpces" class="d-block px-2 fs_14 fsn_4 txt_b"
                          style="margin-top: -12px;">{{selectedTrailerSpces}}</span>
                      </div>
  
                      <div class="col-md-3" *ngIf="isTemprature">
                        <p class="fs_14 fsn_4 txt_lb mb-1">Temperature Controlled</p>
                        <mat-form-field appearance="none" class="removeappearance" class="input_suffix">
                          <input matInput type="text" formControlName="temperature" name="temperature" min="-126"
                            max="136" class="form-control custom_input" placeholder="0°F" />
                          <span *ngIf="shipmentDetailsForm.get('temperature').value" class="temp_f_txt">F</span>
                          <mat-error
                            *ngIf="shipmentDetailsForm.get('temperature')?.errors?.['max'] || shipmentDetailsForm.get('temperature')?.errors?.['min']"
                            class="error_mess">Temperature range should be -126 to 136.</mat-error>
                          <mat-error *ngIf="shipmentDetailsForm.get('temperature')?.errors?.['pattern']"
                            class="error_mess">Please enter
                            only number.</mat-error>
                        </mat-form-field>
                      </div>
                    </div>
                  </div>
                  <div class="crt_card ping_rate_crd">
                    <div class="row">
                      <div class="col-md-12">
                        <p class="line_before mb-3 ">Ping Rate</p>
                        <div class="pay_method">
                          <div class="custom_toggle" [ngClass]="pingRateStatusCheck ? 'visibility_on': 'visibility_off'">
                            <span class="fs_16 fsm_5 me-3 visibilityl ">Standard</span>
                            <mat-slide-toggle class="example-margin" (change)="setPingRate($event)"></mat-slide-toggle>
                            <span class="fs_16 fsm_5 ms-3 visibilityr ">Custom</span>
                          </div>
                          <div class="row" *ngIf="pingRateStatus === 2">
                            <div class="col-md-4">
                              <div class="custum_input_css pe-3">
                                <mat-form-field appearance="none" class="removeappearance" class="input_suffix">
                                  <span matSuffix *ngIf="this.shipmentDetailsForm.get('pingRateValue').value == 1"
                                    class="suffix_txt">Minute</span>
                                  <span matSuffix *ngIf="this.shipmentDetailsForm.get('pingRateValue').value > 1"
                                    class="suffix_txt">Minutes</span>
                                  <input matInput type="text" maxlength="2" formControlName="pingRateValue"
                                    placeholder="Ping Rate in minutes" class="form-control custom_input"
                                    appZeroNotAllowFirst />
                                  <mat-error
                                    *ngIf="shipmentPingSubmitted && shipmentDetailsForm.get('pingRateValue')?.errors?.['required']"
                                    class="error_mess">Ping Rate required</mat-error>
                                  <mat-error *ngIf="shipmentDetailsForm.get('pingRateValue')?.errors?.['max']"
                                    class="error_mess">Ping Rate value should be between 1 to 60 minutes</mat-error>
                                </mat-form-field>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="ctr_ship_btns" style="margin-top: 40px;">
                    <div class="btns_l"></div>
                    <div class="btns_r">
                      <button id="create_shipment_flow004_step1_appcues" type="submit"
                        class="btn custum_button button_primary" [disabled]="checkErrorMessage || shipmentSubmitted">
                        <span class="pe-2 ">Next </span>
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </mat-step>
          <mat-step label="Contracted Rate">
            <div class="friegth">
              <form [formGroup]="shipmentFormFinal" (ngSubmit)="shipmentFreightRateSubmit(shipmentFormFinal)"
                class="mb-0 custum_input_css" autocomplete="off">
                <ng-template matStepLabel>
                  <span _ngcontent-xci-c5="" *ngIf="addFreightRateShow && freightRate"
                    class="material-icons me-2 spet_checked two ng-star-inserted">check_circle</span>
                  <span class=" fsm_5">Contracted
                    Rate</span>
                </ng-template>
                <p class="step_discription">
                  Select load payment terms and enter desired freight rate to
                  begin bidding.
                </p>
                <app-shipment-location-card [pickCardArrays]="pickCardArrays"
                  [hideButtonSection]="hideButtonSection"></app-shipment-location-card>
  
                <div class="patment_freight custum_input_css">
                  <div class="crt_card">
                    <p class="line_before mb-3 ">Payment Terms</p>
                    <p class="payCard_discription">
                      Please select a payment option for this load. USD Money
                      allows you to submit funds via traditional USD Currency
                      Credit Cards/ACH.
                    </p>
                    <div class="row mb-2">
                      <div class="col-md-8">
                        <div class="pay_method la_term">
                          <mat-radio-group aria-label="Select an option" class="custom_redio_btn" formControlName="terms"
                            (change)="changePaymentTerms($event)">
                            <mat-radio-button [value]="1" [checked]="true">
                              <div class="pay_term_radio">
                                <span class="fs_16 fsm_5 ">LaneAxis Terms</span>
                                <p class="mb-0 fs_16 fsn_4 txt_lb">
                                  Release payment 24 hours after load
                                  delivery, unless a dispute is filed by either
                                  party.
                                </p>
                              </div>
                            </mat-radio-button>
                            <mat-radio-button [value]="2" style="margin: 0 0 0 24px">
                              <div class="pay_term_radio">
                                <span class="fs_16 fsm_5 ">Shipper Terms</span>
                                <p class="mb-0 fs_16 fsn_4 txt_lb">
                                  Release payment {{ numberOfDays }} day(s) after
                                  load delivery, unless a filed dispute
                                  remains unresolved
                                </p>
                              </div>
                            </mat-radio-button>
                          </mat-radio-group>
                        </div>
                      </div>
                      <div class="col-md-4 ic_dropdown">
                        <p class="fs_14 fsn_4 txt_lb space_field">
                          Payment Scheduled from delivery
                        </p>
                        <mat-form-field appearance="none" class="removeappearance" class="drop_bg">
                          <mat-select placeholder="Select payment scheduled" class="form-control custom_input"
                            formControlName="stDays" [disabled]="paymentTerms"
                            (selectionChange)="selectScheduled($event)">
                            <mat-option *ngFor="let tdays of showTdays | keyvalue: originalOrder" [value]="tdays.key">
                              {{ tdays.key }} Days
                              <span [ngClass]="tdays.value!=0  ? '' : 'fw-bold'">{{ tdays.value!=0 ? ('(With additionally ' +tdays.value + '% Shipper Terms Fee)') : '(No additional terms fee)' }} </span>
                            </mat-option>
                          </mat-select>
                          <mat-error *ngIf="freightSubmit && shipmentFormFinal.get('stDays')?.errors?.['required']"
                            class="error_mess">Payment scheduled from delivery is required.
                          </mat-error>
                        </mat-form-field>
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="verify_status">
                        <div class="verifing_status" *ngIf="!skeletonLoaderCard">
                          <span class="fs_16 fsm_5 txt_b ">Vefiying ACH account(s)</span>
                          <span class="spinner ms-2">
                            <img src="{{serverPathForUrl}}/portal/images/after-login/LaneAxis-loader.gif"
                              style="max-height: 16px" />
                          </span>
                        </div>
                        <div class="ach_status_fail" *ngIf="skeletonLoaderCard && this.cardListing?.length === 0">
                          <span class="icon-ic-emergency txt_d me-1"></span>
                          Sorry! We are not able to find any ACH account linked
                          with your profile to continue with shipper terms option,
                          please click
                          <a class="pointer txt_p" [routerLink]="['/payment/payment-detail']" target="_blank">
                            <u class="">here</u>
                          </a>
                          to add your ACH account.
                        </div>
                        <div class="ach_status_succ"
                          *ngIf="paymentTermsValue == '2' && skeletonLoaderCard && this.cardListing?.length > 0">
                          <span class="material-icons txt_s fs_12 me-1">
                            check_circle
                          </span>
                          Great! You have {{ this.cardListing?.length }} account(s), please click
                          <a class="pointer txt_p mx-1" [routerLink]="['/payment/payment-detail']" target="_blank">
                            <u class="">here</u>
                          </a>
                          to view detail.
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="crt_card">
                    <p class="line_before mb-3 ">
                      Load Type
                    </p>
                    <div class="pay_method">
                      <div class="custom_toggle" [ngClass]="changeOfferRateBidding? 'visibility_on': 'visibility_off'">
                        <span class="fs_16 fsm_5 me-3 visibilityl ">Bidding</span>
                        <mat-slide-toggle class="example-margin" [checked]="changeOfferRateBidding"
                          (change)="changeBiddingFixedRate($event)">
                        </mat-slide-toggle>
                        <span class="fs_16 fsm_5 ms-3 visibilityr ">Offer Rate</span>
                      </div>
                    </div>
                  </div>
                  <div class="crt_card">
                    <p class="line_before mb-3">Truckload Type</p>
                    <div class="pay_method">
                      <div class="custom_toggle" [ngClass]="changeTruckLoadType ? 'visibility_on' : 'visibility_off'">
                        <span class="fs_16 fsm_5 me-3 visibilityl ">Full Truckload (FTL)</span>
                        <mat-slide-toggle class="example-margin" (change)="selectTruckLoadType($event)">
                        </mat-slide-toggle>
                        <span class="fs_16 fsm_5 ms-3 visibilityr ">Less Than Truckload(LTL)</span>
                      </div>
                    </div>
                  </div>
                  <div class="crt_card" *ngIf="!changeOfferRateBidding">
                    <p class="line_before mb-3 ">
                      Freight Rate<span *ngIf="selectedCurrency === 'USD'" class="">(in USD)</span>
                    </p>
                    <p class="payCard_discription mb-0">
                      Set your desired load rate below.
                    </p>
                    <p class="payCard_discription">
                    </p>
                    <div class="row">
                      <div class="col-md-6">
                        <p class="fs_14 fsn_4 txt_lb space_field">
                          Expected Load Price <span class="txt_d"> *</span>
                        </p>
                        <mat-form-field appearance="none" class="removeappearance" class="input_prefix">
                          <input *ngIf="selectedCurrency === 'USD'" matInput type="text" maxlength="10"
                            placeholder="Enter Expected Load Price" appTwoDigitDecimal class="form-control custom_input"
                            formControlName="freightRate" />
                          <span *ngIf="selectedCurrency === 'USD'" matPrefix
                            class="custom_input prefix_text">$&nbsp;</span>
                          <input *ngIf="selectedCurrency === 'USDC'" matInput type="text" maxlength="10"
                            placeholder="Enter Expected Load Price" appTwoDigitDecimaNumber
                            class="form-control custom_input" formControlName="freightRate" />
                          <mat-error *ngIf="freightSubmit && shipmentFormFinal.get('freightRate')?.errors?.['required']"
                            class="error_mess">
                            Freight Rate is required
                          </mat-error>
                          <mat-error *ngIf="shipmentFormFinal.get('freightRate')?.errors?.['pattern']" class="error_mess">
                            Please enter a valid number
                          </mat-error>
                          <mat-error
                            *ngIf="shipmentFormFinal.get('freightRate')?.errors?.['min'] && !shipmentFormFinal.get('freightRate')?.errors?.['pattern']"
                            class="error_mess">Freight Rate should not be zero
                          </mat-error>
                          <mat-error
                            *ngIf="shipmentFormFinal.get('freightRate')?.errors?.['maxlength'] && !shipmentFormFinal.get('freightRate')?.errors?.['pattern']"
                            class="error_mess">Freight Rate allows 128 digits only
                          </mat-error>
                        </mat-form-field>
                      </div>
                    </div>
                  </div>
                  <div *ngIf="changeOfferRateBidding">
                    <div class="crt_card">
                      <p class="line_before mb-3 ">
                        Offer Rate<span *ngIf="selectedCurrency === 'USD'" class="">(in USD)</span>
                      </p>
                      <p class="payCard_discription mb-2">
                        Set your desired offer rate below.
                      </p>
                      <div class="row">
                        <div class="col-md-6 prefix_input_align">
                          <p class="fs_14 fsn_4 txt_lb space_field">
                            Offer Rate <span class="txt_d"> *</span>
                          </p>
                          <mat-form-field appearance="none" class="removeappearance" class="prefix_txt">
                            <span *ngIf="selectedCurrency === 'USD'" matPrefix class="fsm_5 txt_b">$&nbsp;</span>
                            <input *ngIf="selectedCurrency === 'USD'" matInput type="text" maxlength="10"
                              placeholder="Enter Offer Rate" appTwoDigitDecimal class="form-control custom_input"
                              formControlName="freightRate" />
                            <input *ngIf="selectedCurrency === 'USDC'" matInput type="text" maxlength="10"
                              placeholder="Enter Offer Rate" appTwoDigitDecimaNumber class="form-control custom_input"
                              formControlName="freightRate" />
                            <mat-error *ngIf="freightSubmit && shipmentFormFinal.get('freightRate')?.errors?.['required']"
                              class="error_mess">Offer Rate is required.</mat-error>
                            <mat-error *ngIf="shipmentFormFinal.get('freightRate')?.errors?.['pattern']"
                              class="error_mess">Please enter a valid number.</mat-error>
                            <mat-error
                              *ngIf="shipmentFormFinal.get('freightRate')?.errors?.['min'] && !shipmentFormFinal.get('freightRate')?.errors?.['pattern']"
                              class="error_mess">Offer Rate should not be zero</mat-error>
                            <mat-error
                              *ngIf="shipmentFormFinal.get('freightRate')?.errors?.['maxlength'] && !shipmentFormFinal.get('freightRate')?.errors?.['pattern']"
                              class="error_mess">Offer Rate allows 10 digits only</mat-error>
                          </mat-form-field>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="ctr_ship_btns">
                  <div class="btns_l">
                    <button type="button" class="btn custum_button button_primary ps-0"
                      (click)="goPrevMatStep(stepper, 'carrierDetailsShow')">
                      <span class="icon-Left me-1"></span>
                      <span class="pe-2 ">Back</span>
                    </button>
                  </div>
                  <div class="btns_r">
  
                    <button type="submit" class="btn custum_button button_primary" [disabled]="checkErrorMessage"
                      (click)="isDetailsSave(false)">
                      <span class="pe-2 ">Next </span>
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </mat-step>
          <!-- new end  -->
          <mat-step label="Network">
            <div>
              <ng-template matStepLabel>
                <span _ngcontent-xci-c5="" *ngIf="selectedCarrierShow"
                  class="material-icons me-2 spet_checked three ng-star-inserted">check_circle</span>
                <span class=" fsm_5">Network</span>
              </ng-template>
              <p class="step_discription">
                Publish the load to all the carriers on the LaneAxis Network
                or select carriers from your network connections
              </p>
              <app-shipment-location-card [pickCardArrays]="pickCardArrays"
                [hideButtonSection]="hideButtonSection"></app-shipment-location-card>
  
              <div class="assign_carrier crt_card shipment_by_lane">
                <div class="visibility_carrier"  >
                
                    <p class="line_before mb-3 ">Visibility</p>
                    <div class="d-flex align-items-center">
                    <div [ngClass]="privatePublic ? 'visibility_on' : 'visibility_off'">
                      <div class="custom_toggle">
                        <span class="fs_16 fsm_5 me-3 visibilityl">Public</span>
                        <mat-slide-toggle class="example-margin" (change)="changeVisibilityText($event)"></mat-slide-toggle>
                        <span class="fs_16 fsm_5 ms-3 visibilityr">Private</span>
                      </div>
                      <div>
                        <i *ngIf="!privatePublic" class="fs_14">*Load will be visible to all carrier on the network</i>
                      </div>
                    </div>
                 
  
                  <div class="visibility_carrierr {{ selectedCarrierText }}" *ngIf="privatePublic">
                    <div class="custom_redio_btn" id="ship_by_lane_radio_btn">
                      <mat-radio-group aria-label="Select an option" [(ngModel)]="carrierType"
                        (change)="changeCarrier($event)">
                        <mat-radio-button value="confirmed" class="fs_14 fsn_4 txt_lb ml-1">Confirmed
                          Carriers</mat-radio-button>
                        <mat-radio-button value="connected" class="fs_14 fsn_4 txt_lb ml-1">All
                          Carriers</mat-radio-button>
                        <mat-radio-button value="preferred" class="fs_14 fsn_4 txt_lb ml-1"> Preferred
                          Carriers</mat-radio-button>
                      </mat-radio-group>
                    </div>
                  </div>
                </div>
                </div>
                <div class="visibity_hints" *ngIf="carrierList?.length !== 0 && !allCarrierSkeloton && privatePublic">
                  <span>*Load will be visible to the selected carriers</span>
                  <p class="slected_carrier_bar b_grey">
                    <span *ngIf="isAllCarrier === false && (allPreCheckCarrier.length != totalCarriers)">
                      <span>{{allPreCheckCarrier.length}} carriers selected out of {{totalCarriers}}.</span><br>
                      <span>Please scroll down to select more carriers.</span>
                    </span>
                    <a *ngIf="isAllCarrier === false && (allPreCheckCarrier.length != totalCarriers)" class="pointer"
                      (click)="onSelectAll()">Select All Carriers</a>
                    <span class="slected_carrier_bar"
                      *ngIf="isAllCarrier === true || allPreCheckCarrier.length == totalCarriers">
                      <a>
                        <span style="color: #818181; cursor: initial;"> All {{totalCarriers}} Carriers Selected</span>
                      </a>
                      <a (click)="onClearAll()" class="pointer" style="padding-left: 20px; ">Clear All</a>
                    </span>
                  </p>
                </div>
                <div class="list_scroll carrierSlection_list" infiniteScroll [infiniteScrollDistance]="3"
                  [infiniteScrollThrottle]="100" (scrolled)="onCarrierDataScroll()" [scrollWindow]="false"
                  *ngIf="!allCarrierSkeloton && carrierList && carrierList?.length > 0 && privatePublic">
                  <table class="table mb-0">
                    <thead>
                      <tr style="text-transform: uppercase">
                        <th scope="col">
                          <span class="fs_12 fsm_5 txt_lb ">Carriers</span>
                        </th>
                        <th scope="col">
                          <span class="fs_12 fsm_5 txt_lb ">Confirmed Rate</span>
                        </th>
  
                        <th scope="col">
                          <span class="fs_12 fsm_5 txt_lb ">Miles Driven</span>
                          <span [ngClass]="columnp === 'totalMiles' && isDescp ? 'icon-Up': 'icon-ic-Down'"
                            class="icon fs_16 txt_lb px-2 pointer" (click)="sortCarriers('totalMiles')">
                          </span>
                        </th>
  
                        <th scope="col">
                          <span class="fs_12 fsm_5 txt_lb ">Experience</span>
                          <span [ngClass]="columnp === 'addDate' && isDescp ? 'icon-Up': 'icon-ic-Down'"
                            class="icon fs_16 txt_lb px-2 pointer" (click)="sortCarriers('addDate')">
                          </span>
                        </th>
                        <th scope="col">
                          <span class="fs_12 fsm_5 txt_lb ">Equipment Type</span>
                        </th>
                        <th scope="col" style="text-align: center">
                          <span class="circle_check">
                            <mat-checkbox [(ngModel)]="masterSelected" [checked]="masterSelected" style="display: none"
                              [indeterminate]="checkIntereminate">
                            </mat-checkbox>
                          </span>
                        </th>
                      </tr>
                    </thead>
                    <tbody style="border-top: none">
                      <!--New code start-->
                      <tr *ngFor="let carrierRecord of carrierList; let j = index">
                        <td scope="row">
                          <div class="carrierN">
                            <div class="carrierNimg me-2">
                              <span *ngIf="carrierRecord?.isPreferred === 1"
                                class="material-icons star_marked">star</span>
                              <span class="ca_pro_img" *ngIf="!carrierRecord.profileImage">
                                <span class="initial_name initial_r_32 ">
                                  {{getInitials(carrierRecord.legalName)}}
                                </span>
                              </span>
                              <span class="ca_pro_img" *ngIf="carrierRecord.profileImage">
                                <img src="{{ carrierRecord.profileImage }}" class="img-fluid" />
                              </span>
                            </div>
                            <span class="abl_ca_name text_truncate fs_16 fsm_5 txt_b ">
                              {{carrierRecord.legalName}}
                            </span>
                            <span *ngIf="carrierRecord?.subscription">
                              <img src="{{serverPathForUrl}}/portal/images/after-login/premium_plan_icon.png"
                                style="max-width: 20px; margin-left: 5px;"></span>
                          </div>
                        </td>
                        <td class="fs_16 fsn_4 txt_b">
                          {{carrierRecord?.laneBids?.bidAmount ? "$"+carrierRecord?.laneBids?.bidAmount : "-"}}
                        </td>
                        <td class="fs_16 fsn_4 txt_b">
                          {{carrierRecord?.totalMiles ? carrierRecord.totalMiles: "-"}}
                        </td>
                        <td class="fs_14 fsn_4 txt_b">
                          {{ carrierRecord?.addDate | dateAgo }}
                        </td>
                        <td class="fs_14 fsn_4 txt_b text_truncate" style="max-width: 200px">
                          <span matTooltip="{{getEquipmentType(carrierRecord.equipmentType)}}">
                            {{getEquipmentType(carrierRecord.equipmentType)}}
                          </span>
                        </td>
                        <td class="fs_14 fsn_4 txt_b text-center">
                          <span class="circle_check">
                            <mat-checkbox class="example-margin" [(ngModel)]="carrierRecord.isSelected"
                              (ngModelChange)="selectCarrierData($event, carrierRecord.userId)">
                            </mat-checkbox>
                          </span>
                        </td>
                      </tr>
  
                    </tbody>
                  </table>
                  <div class="content_loader" *ngIf="moreRecordLoader">
                    <span class="loader_image">
                      <img src="{{serverPathForUrl}}/portal/images/after-login/cl-icons/loader-blue.svg"
                        class="img-fluid" />
                    </span>
                  </div>
                </div>
                <div *ngIf="allCarrierSkeloton && privatePublic">
                  <table class="table mb-0">
                    <thead>
                      <tr style="height: 65px">
                        <td></td>
                        <td></td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr style="height: 65px" *ngFor="let fake of generateFake(5)">
                        <td>
                          <div class="loader_mb_0">
                            <ngx-skeleton-loader count="1" [theme]="{ width: '45%', height: '16px' }">
                            </ngx-skeleton-loader>
                          </div>
                        </td>
                        <td>
                          <div class="loader_mb_0">
                            <ngx-skeleton-loader count="1" [theme]="{ width: '40%', height: '16px' }">
                            </ngx-skeleton-loader>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
  
                <div *ngIf=" carrierList?.length === 0 && !allCarrierSkeloton && privatePublic">
                  <div class="Empty_State_body_withou_box" style="padding-top: 6rem">
                    <div class="Empty_State_con">
                      <div class="Empty_State_img">
                        <img class="img-fluid"
                          src="{{serverPathForUrl}}/portal/images/after-login/Empty_State_icons/gr-no-carrier-found.svg" />
                      </div>
                      <div style="max-width: 400px">
                        <p class="fs_24 fsb_6 txt_b pb-1 " style="margin: 30px 0 12px"
                          *ngIf="selectedCarrierText === 'All_Carriers'">
                          No Carriers <span class="txt_p "> found</span>
                        </p>
                        <p class="fs_16 fsn_4 txt_lb pb-1" *ngIf="selectedCarrierText === 'All_Carriers'"
                          style="margin: 0px 0 20px">
                          Use the network to create connections with Carriers and
                          assign them load.
                        </p>
                        <p class="fs_24 fsb_6 txt_b pb-1 " style="margin: 30px 0 12px"
                          *ngIf="selectedCarrierText === 'Preferred_Carriers'">
                          No Preferred Carriers...
                          <span class="txt_p "> yet</span>
                        </p>
                        <p *ngIf="selectedCarrierText === 'Preferred_Carriers'" class="fs_16 fsn_4 txt_lb pb-1"
                          style="margin: 0px 0 20px">
                          Use the network to create connections with Carriers and
                          acquire loads. Once you establish connections, you can
                          message Carriers about future loads .
                        </p>
                      </div>
                      <div class="text-center">
                        <button class="btn custum_button button_primary" [routerLink]="['/network/home']">
                          Visit the network
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
  
              </div>
              <div class="ctr_ship_btns">
                <div class="btns_l">
                  <button type="button" class="btn custum_button button_primary ps-0"
                    (click)="goPrevMatStep(stepper, 'addFreightRateShow')">
                    <span class="icon-Left me-1"></span>
                    <span class="pe-2 ">Back</span>
                  </button>
                </div>
                <div class="btns_r">
                  <button [disabled]="buttonClicked" *ngIf="allPreCheckCarrier.length > 0 || !privatePublic" type="submit"
                    class="btn custum_button button_primary" (click)="shipmentConfirmationSaveDetail()">
                    <span class="pe-2 ">Create Load</span>
                  </button>
                  <button *ngIf="allPreCheckCarrier.length === 0 && privatePublic" [disabled]="true" type="submit"
                    class="btn custum_button button_primary">
                    <span class="pe-2 ">Create Load</span>
                  </button>
                </div>
              </div>
            </div>
          </mat-step>
          <!-- // new end -->
        </mat-horizontal-stepper>
      </div>
    </div>
  </div>