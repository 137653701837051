import { Location } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { environment } from '../../../environments/environment';
import { AlertService } from '../../commons/service/alert.service';
import { CommonService } from '../../commons/service/common.service';
import { AppSettings } from '../../commons/setting/app_setting';
import { PopupComponent } from '../../shared/popup/popup.component';
import { DisputePopupComponent } from '../dispute-popup/dispute-popup.component';
import { SubscriptionPopupComponent } from 'src/app/shared/subscription-popup/subscription-popup.component';

@Component({
  selector: 'app-load-view-top-section',
  templateUrl: './load-view-top-section.component.html',
  styleUrls: ['./load-view-top-section.component.scss']
})
export class LoadViewTopSectionComponent implements OnInit {
  @Input() skeletonLoader: any;
  @Input() loadDetail: any;
  @Input() userType: any;
  @Input() currentStatus: any;
  @Input() urlSlugCurrentTab:any;
  @Input() gifLoader: any;
  @Input() subscriptionData: any;
  public checkTimeDispute = false;
  public loading = false;
  public serverPathForUrl: any;
  public currentShipmentStatus: any;
  public curentDriverValue: any;
  public getBidStatus:any;
  @Output() cancelShipmentRequest = new EventEmitter();
  @Output() bidQuoteAssistancPopup = new EventEmitter();
  public TripStatus : any;

  constructor(
    private router: Router,
    public commonService: CommonService,
    public dialog: MatDialog,
    public location: Location,
    private alertService: AlertService,
  ) { }

  ngOnInit() {
    this.serverPathForUrl = environment.serverPathForUrl;
    this.commonService.bidQuotesAssistanceUpdate.subscribe((data) => {
      if(data.status){
        this.getBidStatus = data.status;
      }
    });
  }

  ngOnChanges(){
    if(this.loadDetail && !this.TripStatus && this.loadDetail?.tripStatus){
      this.TripStatus = this.loadDetail?.tripStatus;
      this.gifLoader = false; 
    }
    else{
      this.gifLoader = false; 
    }
  }

  // Function to enable/disable the message option for load
  checkMessageShipmentCondition() {
    if (
      (this.loadDetail &&
        !this.loadDetail.carriers &&
        this.loadDetail.shippers) ||
      (!this.loadDetail.shippers &&
        this.loadDetail.carriers &&
        (this.loadDetail.status === '15' ||
          this.loadDetail.status === '59'))
    ) {
      return false;
    } else if (
      (this.loadDetail &&
        this.loadDetail.carriers &&
        this.loadDetail.shippers &&
        this.loadDetail.status > '2' &&
        this.loadDetail.status !== '15') ||
      (this.loadDetail.carriers &&
        !this.loadDetail.shippers &&
        this.loadDetail.status > '2' &&
        this.loadDetail.status !== '15')
    ) {
      return true;
    }
  }

  // Function to get Track Shipment Condition 
  checkWebTrackShipmentCondition() {
    if (
      this.loadDetail &&
      this.loadDetail.shippers &&
      this.loadDetail.shippers.email &&
      this.loadDetail.carriers &&
      this.loadDetail.status !== '59'
    ) {
      return true;
    } else if (
      this.loadDetail &&
      this.loadDetail.shipperEmail &&
      this.loadDetail.carriers &&
      this.loadDetail.status !== '59'
    ) {
      return true;
    } else {
      return false;
    }
  }

 // Function to enable/disable the edit option for load
  checkEditShipmentCondition() {
    if (this.userType === 'SHIPPER' && this.loadDetail && this.loadDetail.shippers &&
      (this.loadDetail.statusSlug === 'readyToBid' ||
        this.loadDetail.statusSlug === 'expired' ||
        this.loadDetail.statusSlug === 'offerRate' || this.currentStatus === 'inProgress' || this.currentStatus === 'upcoming' || this.currentStatus === 'pending')) {
      return true;
    } else if (
      this.userType === 'CARRIER' && this.loadDetail && this.loadDetail.carriers && !this.loadDetail.shippers &&
      (this.loadDetail.statusSlug === 'expired' || this.currentStatus === 'inProgress' || this.currentStatus === 'upcoming' || this.currentStatus === 'pending' ||
        !(this.currentStatus === 'past' || this.currentStatus === 'dispute'))) {
      return true;
    } else {
      return false;
    }
  }

  // Function to navigate edit load page
  editShipment($event, id: any) {
    if (this.userType == 'SHIPPER' && (this.subscriptionData?.isPremium != true || this.subscriptionData?.subscriptionStatus == 'canceled'))
    {
      this.getSubscriptionAlertPopup();
    } else {
      $event.stopPropagation();
      this.router.navigate(['/load/edit', id]);
    }
  }

  // Function to get alert for subscription
  getSubscriptionAlertPopup(){
    const dialogRef = this.dialog.open(SubscriptionPopupComponent, {
      disableClose: true,
      backdropClass: AppSettings.backdropClass,
      width: AppSettings.popWidth,
      data: { 
        openPop: 'subscriptionAlert',
        subscriptionData: this.subscriptionData
       },
    });
    dialogRef.afterClosed().subscribe((result) => {

    });
  }

  // Function to enable/disable the cancle load option.
  checkCancelShipmentCondition() {
    if (this.userType === 'SHIPPER' && this.loadDetail && this.loadDetail.shippers && (this.currentStatus === 'availableLoads' || this.loadDetail.statusSlug === 'readyToBid' || this.loadDetail.statusSlug === 'offerRate' || this.currentStatus === 'inProgress' || this.currentStatus === 'upcoming' || this.currentStatus === 'pending')) {
      return true;
    } else if (this.userType === 'CARRIER' && this.loadDetail && this.loadDetail.carriers && this.loadDetail.status !== '15' && !this.loadDetail.shippers &&
      (this.currentStatus === 'inProgress' || this.currentStatus === 'upcoming' || this.currentStatus === 'pending')) {
      return true;
    } else {
      return false;
    }
  }

  // Function to enable/disable the file dispute option for load
  checkFileDisputeShipmentCondition() {
    if (this.loadDetail && this.loadDetail.deliverydate) {
      let days = 1;
      let deliveredDate = new Date(
        this.loadDetail.deliverydate + ' ' + 'UTC'
      );
      deliveredDate.setDate(deliveredDate.getDate() + days);
      let now = new Date();
      let deliveryDateTime = moment(deliveredDate).format('YYYY-MM-DD HH:mm');
      let currentDateTime = moment(now).format('YYYY-MM-DD HH:mm');

      if (deliveryDateTime > currentDateTime) {
        this.checkTimeDispute = true;
      } else {
        this.checkTimeDispute = false;
      }
    } else {
      this.checkTimeDispute = false;
    }

    if (
      this.loadDetail &&
      this.checkTimeDispute &&
      this.loadDetail.shippers &&
      this.loadDetail.status === 51
    ) {
      return true;
    } else {
      return false;
    }
  }

  // Function to get load status.
  getCurrentStatusBack(status) {
    if (
      status === 21 ||
      status === 22 ||
      status === 23 ||
      status === 24 ||
      status === 25 ||
      status === 26 ||
      status === 31 ||
      status === 32 ||
      status === 33 ||
      status === 34 ||
      status === 35 ||
      status === 41 ||
      status === 42 ||
      status === 43 ||
      status === 44 ||
      status === 45 ||
      status === 46
    ) {
      return 'inProgress';
    } else if (status === 13 && this.loadDetail.carriers) {
      return 'upcoming';
    } else if (
      status === 15 ||
      status === 51 ||
      (status === 54 && this.loadDetail.carriers)
    ) {
      return 'past';
    } else if (
      (status === 12 ||
        status === 11 ||
        status === 14 ||
        status === 52 ||
        status === 1 ||
        status === 18) &&
      this.loadDetail.carriers
    ) {
      return 'pending';
    } else if (status === 53) {
      return 'newDispute';
    } else if (status === 2 && this.userType === 'CARRIER') {
      return 'availableToBid';
    } else if (status === 3) {
      return 'offerRate';
    } else if (
      this.userType === 'CARRIER' &&
      status !== 2 &&
      !this.loadDetail.carriers
    ) {
      return 'nonAwardedBids';
    } else if (status === 2 && this.userType === 'SHIPPER') {
      return 'currentBids';
    } else {
      return 'inProgress';
    }
  }

  // Function to go back on previous page
  backHistory() {
    let statusVar = this.getCurrentStatusBack(this.loadDetail.status);
    if (
      statusVar === 'currentBids' ||
      statusVar === 'availableToBid' ||
      statusVar === 'awardedBids' ||
      statusVar === 'nonAwardedBids' ||
      statusVar === 'confirmedBids' ||
      statusVar === 'offerRate'
    ) {
      this.router.navigate(['/spot-rate-loads/created-loads/allLoads']);
    } else {
      this.router.navigate(['/spot-rate-loads/active-loads/inProgressByLoads']);
    }
    if(!this.loadDetail.shipperId && this.userType == 'CARRIER') {
      this.router.navigate(['/load-created-by-me/inProgress']);
    }
  }

  /*Cancel Shipment*/
  newShipmentCancel(id: any, uniqueId: any) {
    if (this.userType == 'SHIPPER' && (this.subscriptionData?.isPremium != true || this.subscriptionData?.subscriptionStatus == 'canceled'))
    {
      this.getSubscriptionAlertPopup();
    } else {
    let confirmby: any;
    let buttontext = "Cancel";
    if ((this.userType === 'SHIPPER') && (this.currentStatus === 'pending' || this.currentStatus === 'upcoming' || this.currentStatus === 'inProgress')) {
      confirmby = 'confirmCancelShipment';
      this.cancelShipmentPayment(confirmby, id, uniqueId, buttontext);
    } else {
      confirmby = 'confirmcancel';
      this.cancelShipment(confirmby, id, uniqueId, buttontext);
    }
   }
  }

  // Function to cancel Shipment Payment
  cancelShipmentPayment(confirmby: any, id: any, uniqueId: any, buttontext: any) {
    let buttonnew = 'Go Back';
    const dialogRef = this.dialog.open(PopupComponent, {
      backdropClass: AppSettings.backdropClass,
      width: AppSettings.popWidth,
      data: {
        openPop: confirmby,
        id: id,
        uniqueId: uniqueId,
        buttontext: buttontext,
        buttonnew: buttonnew,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        if (result.event === 'Ok') {
          this.cancelShipmentSubmitPayment(id, 'firstTimeCall');
        }
      }
    });
  }

  // Function to cancel Shipment
  cancelShipment(confirmby: any, id: any, uniqueId: any, buttontext: any) {
    let buttonnew = 'Go Back';
    const dialogRef = this.dialog.open(PopupComponent, {
      backdropClass: AppSettings.backdropClass,
      width: AppSettings.popWidth,
      data: {
        openPop: confirmby,
        id: id,
        uniqueId: uniqueId,
        buttontext: buttontext,
        buttonnew: buttonnew,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        if (result.event === 'Ok') {
          this.cancelShipmentSubmit(id, 'firstTimeCall');
        }
      }
    });
  }

  // Function to submit cancel Shipment payment
  cancelShipmentSubmitPayment(id: any, check: any) {
    this.loading = true;
    let postBody = null;
    let action = '';
    if (check === 'firstTimeCall') {
      action = 'cancel';
    } else {
      action = 'assign';
    }
    let driverId;
    if (this.userType === 'SHIPPER' && this.loadDetail.carriers) {
      postBody = {
        shipmentId: this.loadDetail.id,
        carrierId: this.loadDetail.carriers.userId,
        action: action,
      };
    } else if (this.userType === 'SHIPPER' && !this.loadDetail.carriers) {
      postBody = {
        shipmentId: this.loadDetail.id,
        action: action,
      };
    }
    let APIparams = {
      apiKey: AppSettings.APIsNameArray.NEWPAYMENT.CANCELSHIPMENTPAYMENT,
      uri: '',
      postBody: postBody,
    };
    this.commonService.post(APIparams).subscribe(
      async (success) => {
        if (success.success === true) {
          this.skeletonLoader = true;
          this.cancelShipmentRequest.emit();
          if (check === 'firstTimeCall') {
            if (this.currentShipmentStatus === 'unAssign') {
            } else {
            }
          } else {
            this.alertService.showNotificationMessage(
              'success',
              'bottom',
              'right',
              'txt_s',
              'check_circle',
              'Cancel load',
              success.message
            );
          }
          this.loading = false;
        } else if (success.success === false) {
          this.alertService.showNotificationMessage(
            'danger',
            'bottom',
            'right',
            'txt_d',
            'cancel',
            'Cancel load',
            success.message
          );
          this.loading = false;
        }
      },
      (error) => {
        this.loading = false;
        this.alertService.showNotificationMessage(
          'danger',
          'bottom',
          'right',
          'txt_d',
          'cancel',
          'Cancel load',
          AppSettings.ERROR
        );
      }
    );
  }

  // Function to submit cancel Shipment form
  cancelShipmentSubmit(id: any, check: any) {
    this.loading = true;
    let postBody = null;
    let action = '';
    if (check === 'firstTimeCall') {
      action = 'cancel';
    } else {
      action = 'assign';
    }
    let driverId;
    if (this.userType === 'CARRIER' && this.loadDetail.drivers) {
      postBody = {
        shipmentId: this.loadDetail.id,
        driverId: driverId,
        carrierId: localStorage.getItem('user_id'),
        action: action,
      };
    } else if (this.userType === 'CARRIER' && !this.loadDetail.drivers) {
      postBody = {
        shipmentId: this.loadDetail.id,
        carrierId: localStorage.getItem('user_id'),
        action: action,
      };
    } else if (this.userType === 'SHIPPER' && this.loadDetail.carriers) {
      postBody = {
        shipmentId: this.loadDetail.id,
        carrierId: this.loadDetail.carriers.userId,
        action: action,
      };
    } else if (this.userType === 'SHIPPER' && !this.loadDetail.carriers) {
      postBody = {
        shipmentId: this.loadDetail.id,
        action: action,
      };
    } else {
    }
    let APIparams = {
      apiKey: AppSettings.APIsNameArray.SHIPMENT.SHIPMENTCANCEL,
      uri: '',
      postBody: postBody,
    };
    this.commonService.post(APIparams).subscribe(
      async (success) => {
        if (success.success === true) {
          this.skeletonLoader = true;
          this.cancelShipmentRequest.emit();
          if (check === 'firstTimeCall') {
            if (this.currentShipmentStatus === 'unAssign') {
            } else {
            }
          } else {
            this.alertService.showNotificationMessage(
              'success',
              'bottom',
              'right',
              'txt_s',
              'check_circle',
              'Cancel load',
              success.message
            );
          }
          this.loading = false;
        } else if (success.success === false) {
          this.alertService.showNotificationMessage(
            'danger',
            'bottom',
            'right',
            'txt_d',
            'cancel',
            'Cancel load',
            success.message
          );
          this.loading = false;
        }
      },
      (error) => {
        this.loading = false;
        this.alertService.showNotificationMessage(
          'danger',
          'bottom',
          'right',
          'txt_d',
          'cancel',
          'Cancel load',
          AppSettings.ERROR
        );
      }
    );
  }

  // File a dispute
  disputeShipment($event, id: any, uniqueId: any, title: any) {
    if (this.userType == 'SHIPPER' && (this.subscriptionData?.isPremium != true || this.subscriptionData?.subscriptionStatus == 'canceled'))
    {
      this.getSubscriptionAlertPopup();
    } else {
    $event.stopPropagation();
    const dialogRef = this.dialog.open(DisputePopupComponent, {
      backdropClass: AppSettings.backdropClass,
      width: '600px',
      data: {
        openPop: 'fileDispute',
        shipmentId: id,
        uniqueId: uniqueId,
        title: title,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        if (result.event === 'Ok') {
        }
      }
    });
   }
  }

  //To copy web tracking url
  getWebTrackingLink() {
    if (
      this.loadDetail &&
      this.loadDetail.shippers &&
      this.loadDetail.shippers.email
    ) {
      var url = environment.domainPoint + '/load/track-your-load?load_id=' + this.loadDetail.uniqueId 

    } 
    else if (this.loadDetail && this.loadDetail.shipperEmail) {
      url = environment.domainPoint + '/load/track-your-load?load_id=' + this.loadDetail.uniqueId 
    }
    this.copyWebTrackingLink(url);
  }

  //copying web tracking link
  copyWebTrackingLink(val: string) {
    let selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.alertService.showNotificationMessage(
      'success',
      'bottom',
      'right',
      'txt_s',
      'check_circle',
      'Web Tracking Link',
      'Web tracking link copied.'
    );
  }

  // Function to redirect on messages page
  newMessageRedirect() {
    if (this.currentStatus === 'past' && (this.currentStatus !== 'inProgress' || this.currentStatus !== 'upcoming' || this.currentStatus !== 'pending')) {
      this.router.navigate(['/messages/shipment/All/'], {
        queryParams: {
          activeChat: 'true', id: this.loadDetail?.id, title: this.loadDetail?.title
        },
      });
    } else {
      this.router.navigate(['/messages/active_shipment/All/'], {
        queryParams: {
          activeChat: 'true', id: this.loadDetail?.id, title: this.loadDetail?.title
        },
      });
    }
  }

// bid quote assistance popup
  openUpgradePopup(){
    const dialogRef = this.dialog.open(PopupComponent, {
      backdropClass: AppSettings.backdropClass,
      width: AppSettings.popWidth,
      data: {
        openPop: 'openBidQuote',
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        if (result.event === 'Ok') {
          this.openBidQuote()
        }
      }
    });
  }

  // bid quote assistance
  openBidQuote(){
    localStorage.setItem('bids','1')
    if(localStorage.getItem('premium')=='0')
    {
      this.openUpgradePopup()
    }else{
      this.bidQuoteAssistancPopup.emit();
    }
  }

}
