import { Component } from '@angular/core';

@Component({
  selector: 'app-load-skeleton-card',
  templateUrl: './load-skeleton-card.component.html',
  styleUrls: ['./load-skeleton-card.component.css'],
})
export class LoadkeletonCardComponent {
  constructor() {}
}
