<div class="row">
  <div class="col-md-5">
    <div class="pay_method_section" *ngIf="!usdcCardLoader">
      <div class="pay_method_t">
        <div class="pay_method_tl">
          <div class="pay_method_icons">
            <img src="{{serverPathForUrl}}/portal/images/after-login/cl-icons/ic-currency-usdc.svg" class="img-fluid">
            <span>USDC</span>
          </div>
          <p class="fs_18 fsb_7 txt_p mb-0">{{balance?.susdc?.available | sixDecimalPipe}}</p>
        </div>
        <div class="pay_method_tr">
          <button type="button" (click)="paymentBuyUsdcAdd()" class="btn custum_button button_primary">Buy</button>
        </div>
      </div>
      <div class="pay_method_b">
        <div class="pay_method_bl">
          <p class="mb-0 fs_14 fsn_4 txt_lb">{{balance?.address| dotOfMiddle}}</p>
        </div>
        <div class="pay_method_br">
          <img src="{{serverPathForUrl}}/portal/images/after-login/cl-icons/magic-logo.svg" class="img-fluid">
        </div>
      </div>
    </div>
    <!-- skt -->
    <div class="usdc_money_skt" *ngIf="usdcCardLoader">
      <ngx-skeleton-loader count="1" [theme]="{ width: '100%', height: '15px'}" class="loader_m_0"></ngx-skeleton-loader>
      <ngx-skeleton-loader count="1" [theme]="{ width: '100%', height: '20px'}" class="loader_m_0"></ngx-skeleton-loader>
      <div style="margin: 18px 0 0;">
        <ngx-skeleton-loader count="1" [theme]="{ width: '100%', height: '15px'}" class="loader_m_0"></ngx-skeleton-loader>
      </div>
    </div>
  </div>
</div>