<div class="selected_filter_con">
    <div class="filter_label" *ngIf="titleFilter">
      <span class="label_txt" *ngIf="!isLane">Load: {{titleFilter}}</span>
      <span class="label_txt" *ngIf="isLane" matTooltip ={{titleFilter}}>Lane: {{titleFilter}}</span>
      <span (click)="singleFilterClose('title')" class="icon-ic-cross close_lable"><img src="{{serverPathForUrl}}/portal/icon/ic_close_grey.svg"></span>
    </div>
    <div class="filter_label" *ngIf="dropoffLocationShow">
      <span class="label_txt">Dropoff Location: {{dropoffLocationShow}}</span>
      <span (click)="singleFilterClose('dropOff')" class="icon-ic-cross close_lable"><img src="{{serverPathForUrl}}/portal/icon/ic_close_grey.svg"></span>
    </div>
    <div class="filter_label" *ngIf="carrierNameShow">
      <span class="label_txt">Name: {{carrierNameShow}}</span>
      <span (click)="singleFilterClose('carrier')" class="icon-ic-cross close_lable"><img src="{{serverPathForUrl}}/portal/icon/ic_close_grey.svg"></span>
    </div>
  
    <div class="filter_label" *ngIf="pickupLocationShow">
      <span class="label_txt">Pickup Location: {{pickupLocationShow}}</span>
      <span (click)="singleFilterClose('pickUp')" class="icon-ic-cross close_lable"><img src="{{serverPathForUrl}}/portal/icon/ic_close_grey.svg"></span>
    </div>
  
    <div class="filter_label" *ngIf="pickupDateFilter">
      <span class="label_txt">Pickup Date: {{pickupDateFilter}}</span>
      <span (click)="singleFilterClose('pickupDate')" class="icon-ic-cross close_lable"><img src="{{serverPathForUrl}}/portal/icon/ic_close_grey.svg"></span>
    </div>
  
    <div class="filter_label" *ngIf="dropOffDateFilter">
      <span class="label_txt">Dropoff Date: {{dropOffDateFilter}}</span>
      <span (click)="singleFilterClose('dropDate')" class="icon-ic-cross close_lable"><img src="{{serverPathForUrl}}/portal/icon/ic_close_grey.svg"></span>
    </div>
  
    <div class="filter_label" *ngIf="driverNameShow">
      <span class="label_txt">Driver Name: {{driverNameShow}}</span>
      <span (click)="singleFilterClose('driver')" class="icon-ic-cross close_lable"><img src="{{serverPathForUrl}}/portal/icon/ic_close_grey.svg"></span>
    </div>
  
    <div class="filter_label" *ngIf="statusLabelShow">
      <span class="label_txt">Status: {{statusLabelShow}}</span>
      <span (click)="singleFilterClose('status')" class="icon-ic-cross close_lable"><img src="{{serverPathForUrl}}/portal/icon/ic_close_grey.svg"></span>
    </div>
  
    <div class="filter_label" *ngIf="equipmentNameShow">
      <span class="label_txt">Equipment Type: {{equipmentNameShow}}</span>
      <span (click)="singleFilterClose('equipment')" class="icon-ic-cross close_lable"><img src="{{serverPathForUrl}}/portal/icon/ic_close_grey.svg"></span>
    </div>
    <div class="filter_label" *ngIf="selfId">
      <span class="label_txt">Shipment assign to himself</span>
      <span (click)="singleFilterClose('self')" class="icon-ic-cross close_lable"><img src="{{serverPathForUrl}}/portal/icon/ic_close_grey.svg"></span>
    </div>
    <div class="filter_label" *ngIf="stateShow">
      <span class="label_txt">State: {{stateShow}}</span>
      <span (click)="singleFilterClose('state')" class="icon-ic-cross close_lable"><img src="{{serverPathForUrl}}/portal/icon/ic_close_grey.svg"></span>
    </div>
    <div class="filter_label" *ngIf="carrierNameListShow">
      <span class="label_txt" matTooltip ={{carrierNameListShow}}>Carrier Name: {{carrierNameListShow}}</span>
      <span (click)="singleFilterClose('carrierName')" class="icon-ic-cross close_lable"><img src="{{serverPathForUrl}}/portal/icon/ic_close_grey.svg"></span>
    </div>
  </div>