import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CommonService } from '../../commons/service/common.service';
import { SharedService } from '../../commons/service/shared.service';
import { AppSettings } from '../../commons/setting/app_setting';
import { MatDialog } from '@angular/material/dialog';
import { DeviceDetectorService } from 'ngx-device-detector';
import { environment } from '../../../environments/environment';
import { Location } from '@angular/common';
declare var require: any;

@Component({
  selector: 'app-sign-up-verify',
  templateUrl: './sign-up-verify.component.html',
  styleUrls: ['./sign-up-verify.component.css']
})

export class SignUpVerifyComponent implements OnInit {
  public verifyOtp: FormGroup;
  public verifyEmail:any;
  public recordGet: any;
  public loading = false;
  public resendmessage:any;
  public userDetails:any;
  public isSubscription: any;
  public verifyPhone:any;
  public showMessage = '';
  public oopsError = '';
  public spinnerLoading = false;
  public verifyOtpSubmmited = false;
  public verifyOtpData:any;
  public isOtpVerify = false;
  public showVerifyWay: any;

  constructor(   
    private formBuilder: FormBuilder, 
    private commonService: CommonService,
    private router: Router,
    public dialog: MatDialog,
    public sharedService: SharedService,
    private deviceService: DeviceDetectorService,
    public location: Location
  ) { }

  ngOnInit(): void {
    const body = document.getElementsByTagName('body')[0];
    body.classList.add('before_login_user');
    this.verifyOtpSection();
    this.verifyOtp = this.formBuilder.group({
      verifyway: ['', [Validators.required]],
      verifyUserOtp : ['', [Validators.required, Validators.pattern(/^-?([1-9]\d*)?$/)],],
    });
    this.verifyOtp.valueChanges.subscribe(x => {
      if(this.verifyOtp.invalid){
       this.oopsError=null;
       this.showMessage=null;
      }
    });

    let userDetail;
    userDetail = this.sharedService.getAddress();

    if (userDetail !== null) {
      this.userDetails = userDetail;
      this.userInfo(userDetail);
    }
    this.commonService.userData.subscribe((userData) => {
      if (userData && this.userDetails === undefined) {
        this.userInfo(userData);
      }
    });

  }

  // Function to get user details 
  userInfo(userDtail){
    this.isSubscription = userDtail.subscription;
  }

  // Function to navigate login page
  goToLogin(){
    this.router.navigate(['/']);
  }

  // Function to navigate sign-up page
  singUp(){
    window.open(environment.laneAxisDotCom + '/sign-up/', 'blank'); 
  }

  // Function to verify otp and get user profile
  verifyOtpSection() {
    if (localStorage.getItem('user_type') === 'CARRIER') {
       this.recordGet = AppSettings.APIsNameArray.CARRIER_REGISTRATION.GET;
       this.getProfile();
    } else if (localStorage.getItem('user_type') === 'SHIPPER') {
       this.recordGet = AppSettings.APIsNameArray.SHIPPER.GET;
       this.getProfile();
    } else if (localStorage.getItem('user_type') === 'STORAGE') {
       this.recordGet = AppSettings.APIsNameArray.COLDSTORAGE.GET;
       this.getProfile();
    } else{
       this.goToLogin();
    }
  }

    // API call to get user profile
    getProfile() {
      let uri = '';
      let params :{};
      uri = this.commonService.getAPIUriFromParams(params);
      let APIparams = {
        apiKey: this.recordGet,
        uri: uri,
      };
      this.commonService.getList(APIparams).subscribe(
        (ServerRes) => {
          if (ServerRes.success === true) {
            if (localStorage.getItem('access_token') && (localStorage.getItem('email_verified') === 'true' || localStorage.getItem('phone_verified') === 'true')) {
              this.loginVerifyOtpSuccess();
            } else if (localStorage.getItem('access_token') && (localStorage.getItem('email_verified') === 'true' || localStorage.getItem('phone_verified') === 'true')) {
              this.loginVerifyOtpSuccess();
            }
            this.userDetails = ServerRes.response;
            if (ServerRes.response && ServerRes.response.phyCity && ServerRes.response.phyState) {
              const SourceLocation = ServerRes.response.phyCity + ',' + ServerRes.response.phyState;
              this.sharedService.setUserLocationType(SourceLocation);
            }
            localStorage.setItem('user_id', ServerRes.response.userId);
            if (localStorage.getItem('user_type') === 'CARRIER') {
              this.verifyEmail = ServerRes.response.emailAddress;
            }
            if (localStorage.getItem('user_type') === 'SHIPPER') {
              this.verifyEmail = ServerRes.response.email;
            } else if (localStorage.getItem('user_type') === 'STORAGE') {
              this.verifyEmail = ServerRes.response.email;
            }
            this.showVerifyWay=this.verifyEmail;
            this.verifyPhone = ServerRes.response.phone;
            this.verifyOtp = this.formBuilder.group({
              verifyway: [this.verifyEmail, [Validators.required]],
              verifyUserOtp : ['', [Validators.required, Validators.pattern(/^-?([1-9]\d*)?$/)],],
            });
            this.loading = false;
          } else {
            this.loading = false;
          }
        },
        (error) => {
          this.loading = false;
          if (error.status === '401') {
            this.goToLogin();
            localStorage.clear();
            sessionStorage.clear();
            this.sharedService.configLoginCallUrl.next(null);
          }
        }
      );
  }

  // Function for verify otp success
  loginVerifyOtpSuccess() {
    localStorage.setItem('login', 'after_login')
    if (localStorage.getItem('user_type') === 'CARRIER') {
      this.resendmessage = '';
      this.loading = false;
    }
    if (localStorage.getItem('user_type') === 'SHIPPER') {
      this.resendmessage = '';
      localStorage.setItem('is_contract', '0');
      this.loading = false;
    }
    this.redirectUseLogin();
    this.commonService.sendTokanUpdate.next(null);
    localStorage.removeItem('onboarding');
  }
  
  // To get input data for verification code
  keyPaste(event: any) {
    const pattern = /[0-9\+\-\ ]/;
    if (!pattern.test(event.target.value)) {
    }
  }

  // To get input data for Verification code by key press event
  keyPress(event: any) {
    const pattern = /[0-9\+\-\ ]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode !== 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  // Function to redirect User Login after otp success
 redirectUseLogin() {
    const isMobile = this.deviceService.isMobile();
    if (localStorage.getItem('user_type') === 'SHIPPER' && localStorage.getItem('tag') === 'GTFO' && localStorage.getItem('premium') == '0') {
      this.router.navigateByUrl('/subscription-plan');
    } else if (localStorage.getItem('user_type') === 'CARRIER' && !isMobile) {
       this.router.navigateByUrl('/network/my-network/connected');
    } else if (localStorage.getItem('user_type') === 'CARRIER' && isMobile && this.isSubscription == null) {
      this.router.navigateByUrl('carrier/subscription-plan');
    }  else if (localStorage.getItem('user_type') === 'SHIPPER' && !isMobile) {
      this.router.navigateByUrl('/network/my-network/connected');
    } else if (localStorage.getItem('user_type') === 'SHIPPER' && isMobile && this.isSubscription == null) {
      this.router.navigateByUrl('shipper/subscription-plan');
    } else if (localStorage.getItem('user_type') === 'STORAGE' && !isMobile) {
      this.router.navigateByUrl('/storage/location/list');
    }
    this.loading = false;
  }
  
  // To Verify OTP in another way
  verifyChangeWay() {
    this.isOtpVerify = !this.isOtpVerify;
    if (this.isOtpVerify) {
      this.verifyOtp.get('verifyway').setValue(this.verifyPhone);
      this.location.replaceState('/sign-up-verify-mobile');
      this.showVerifyWay=this.verifyPhone;
    }else{
      this.showVerifyWay=this.verifyEmail;
      this.verifyOtp.get('verifyway').setValue(this.verifyEmail);
      this.location.replaceState('/sign-up-verify-email');
    }
  }

  // API call to resend OTP 
  resendOTP() {
    this.verifyOtp.get('verifyUserOtp').setValue('');
    this.spinnerLoading = true;
    let postBody = null;
    if(this.isOtpVerify){
      postBody = { email: null, phone: this.verifyPhone, isVerifyPhone: true };
    }else{
      postBody = { email: this.verifyEmail, phone: null };
    }
    let APIparams = {
      apiKey: AppSettings.APIsNameArray.CARRIER_REGISTRATION.RESENDOTP,
      uri: '',
      postBody: postBody,
    };
    this.commonService.post(APIparams).subscribe(
      (success) => {
        if (success.success === true) {
          this.showMessage = 'Verification code sent successfully';
          this.spinnerLoading = false;
        } else if (success.success === false) {
          this.showMessage = 'Verification code sent successfully';
          this.spinnerLoading = false;        }
      },
      (error) => {
        this.oopsError = AppSettings.ERROR;
        this.spinnerLoading = false;
      }
    );
  }

  // To submit verify otp form
  verifyOtpSubmit({ value, valid }) {
    this.verifyOtpSubmmited = true;
    this.showMessage = '';
    this.oopsError='';
    var userType = localStorage.getItem('user_type');
    if (valid) {
      this.loading = true;
      let data = require('../../../../package.json');
      const getInfo = this.deviceService.getDeviceInfo();
      value.deviceId = localStorage.getItem('uniqueId');
      value.token = localStorage.getItem('fcmToken');
      value.os_version = getInfo.os_version;
      value.deviceType = '3';
      value.app_version = data.version;
      let postBody=null;
      if(this.isOtpVerify){
        postBody = { 
          deviceId: localStorage.getItem('uniqueId'), 
          token:localStorage.getItem('fcmToken'),
          os_version: getInfo.os_version,
          deviceType:'3',
          app_version:data.version,
          phone : value.verifyway,
          phoneOtp:value.verifyUserOtp,
          isVerifyPhone: true 
        };
      }else{
        postBody = { 
          deviceId: localStorage.getItem('uniqueId'), 
          token:localStorage.getItem('fcmToken'),
          os_version: getInfo.os_version,
          deviceType:'3',
          app_version:data.version,
          email : value.verifyway,
          emailOtp:value.verifyUserOtp,
        };
      }
      let APIparams = {
        apiKey: AppSettings.APIsNameArray.CARRIER_REGISTRATION.VERIFYOPT,
        uri: '',
        postBody: postBody,
      };
      this.commonService
        .post(APIparams).subscribe(
          (success) => {
            this.verifyOtpSubmmited = false;
            if (success.success === true) {
              var obj = {
                eventName: 'registration',
                event_category: 'Registration',
                userType: userType,
                EmailID: this.verifyEmail,
                Platform: this.sharedService.getPlatformType(),
              };
              this.sharedService.track(obj);
              this.verifyOtpData = success.response;
              if (success.response.premium == '0' && success.response.tag === 'GTFO') {
                localStorage.setItem('onboarding', 'signIn');
                localStorage.setItem('login', 'before_login');
              } else {
                localStorage.setItem('login', 'after_login');
              }
              localStorage.setItem('access_token', this.verifyOtpData.access);
              localStorage.setItem(
                'email_verified',
                this.verifyOtpData.emailVerified
              );
              localStorage.setItem('refresh_token', this.verifyOtpData.refresh);
              sessionStorage.setItem('access_token', this.verifyOtpData.access);
              sessionStorage.setItem(
                'email_verified',
                this.verifyOtpData.emailVerified
              );
              localStorage.setItem('view_info_popup', 'complete');
              this.loginVerifyOtpSuccess();
            } else if (success.success === false) {
              this.oopsError = 'Invalid verification code';
              this.showMessage = '';
              this.loading = false;
              if (success.error) {
                const validationErrors = success.error;
                Object.keys(validationErrors).forEach((prop) => {
                  const formControl = this.verifyOtp.get(prop);
                  if (formControl) {
                    formControl.setErrors({
                      serverError: validationErrors[prop],
                    });
                  }
                });
              }
            }
          },
          (error) => {
            this.oopsError = AppSettings.ERROR;
            this.showMessage = '';
            this.loading = false;
            this.verifyOtpSubmmited = false;
          }
        );
    }
  }

  // Function to Save & Exit for another login
  goToLoginReset() {
    this.showMessage = '';
    this.oopsError = '';
    localStorage.removeItem('user_type');
    localStorage.removeItem('premium');
    localStorage.removeItem('tag');
    localStorage.removeItem('userProfileImage');
    localStorage.removeItem('access_token');
    localStorage.removeItem('userName');
    localStorage.removeItem('user_id');
    localStorage.removeItem('refresh_token');
    localStorage.removeItem('showExpiryPopup');
    localStorage.removeItem('email_verified');
    localStorage.removeItem('phone_verified');
    localStorage.removeItem('onboarding');
    sessionStorage.clear();
    this.router.navigate(['/']);
  }

}
