<div class="text-center" *ngIf="selectedData">
    <div class="text-center" style="margin: 0; padding: 2rem 5rem;">
       <span class="material-icons" style="margin: 0 0 1.5rem;font-size: 48px" [ngStyle]="selectedData.status !== 'failed' && selectedData.status !== 'withdraw_failed' ? {'color': selectedData.color} : {'color': 'var(--clReda)'}">{{selectedData.icon}}</span>
       <p class="fs_20 fsm_5 txt_b" style="margin: 0 0 2rem;">{{selectedData.heading}}</p>
       <p class="fs_16 fsn_4 txt_lb" style="margin: 0 0 2rem; line-height: 24px;">{{selectedData.subheading}}</p>
       <div class="text-center">
          <button type="submit" class="btn custum_button w-100 text-capitalize fs_16 fsn_4 h_40" [ngClass]="{'button_success': selectedData.status !== 'failed' , 'button_danger': selectedData.status === 'failed' || selectedData.status === 'withdraw_failed' } " (click)="closePopup()">OK</button>
      </div>
    </div>
 </div>
