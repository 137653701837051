<div class="detail_card_con add_doc_con" *ngIf="!documentSklitor && shipmentDocumentGet.length > 0">
    <div class="document_container pb-2">
      <div class="title_action">
        <p class="line_before mb-0">Load Documents</p>
      </div>
      <div class="tab_panel_con add_doc_con">
        <mat-accordion class="example-headers-align" *ngFor="let shipmentDocument of shipmentDocumentGet;">
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                <p class="line_before">{{shipmentDocument?.label}} : {{shipmentDocument?.businessName ? shipmentDocument?.businessName : '-'}} | {{shipmentDocument?.address}} </p>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="tpc_box">
              <div class="row">
                <div [ngClass]="type=== 'side-panel' ? 'col-md-12' : 'col-md-6'" *ngFor="let pickupDocument of shipmentDocument?.medias;">
                  <div class="document_container">
                    <div class="coi_doc">
                      <div class="open_newWindow pn_nw_wndw">                    
                        <a href="{{pickupDocument?.mediaUrl}}" target="_blank" class="fs_14 fsn_4 txt_p">
                          <img src="https://s3.amazonaws.com/staticprod.laneaxis.com/portal/images/after-login/cl-icons/ic-New-Window-tc.svg" class="img-fluid">
                        </a>
                      </div>
                      <div class="coi_docl">
                        <span class="">
                          <img src="{{imageCheck(pickupDocument?.mediaUrl)}}" class="img-fluid">
                        </span>
                        <div class="coi_docTxt">
                          <p class="fs_14 fsm_5 txt_b mb-1">{{(pickupDocument.loadHistoryMediaText) | titlecase}}</p>
                          <p class="fs_12 fsn_4 txt_lb mb-0">Uploaded on {{pickupDocument.updatedAt | date: 'MMM dd, yyyy'}} at {{pickupDocument.updatedAt | date: 'HH:mm'}}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
    </div>
  </div>
  <!-- empty doc card -->
  <div class="detail_card_con" *ngIf="!documentSklitor && shipmentDocumentGet?.length===0">
    <div class="title_action">
      <p class="line_before mb-0">Load Documents</p>
    </div>
    <div class="empty_docs">
      <div class="Empty_State_body b_shadow_0" style="padding: 3rem 0;">
        <div class="Empty_State_con">
          <div class="Empty_State_img">
            <img class="img-fluid" src="{{serverPathForUrl}}/portal/images/after-login/Empty_State_icons/gr-no-documents.svg">
          </div>
          <div>
            <p class="fs_24 fsb_6 txt_b pb-1" style="margin: 30px 0 12px;">No Load document Uploaded <span class="txt_p"> yet...</span> </p>
            <p class="fs_16 fsn_4 txt_lb pb-1" style="margin: 0px 0 20px ">This load is currently active, but the driver has not uploaded any Load documents.</p>
          </div>
          <div class="text-center"></div>
        </div>
      </div>
    </div>
  </div>
  <div class="detail_card_con add_doc_con" *ngIf="!documentSklitor && shipperDocumentGet.length > 0">
    <div class="document_container pb-2">
      <div class="title_action">
        <p class="line_before mb-0">Shipper Documents</p>
        <p class="add_doc_action" (click)="userPopup('multipleDoc',shipperDocumentGet)" *ngIf="userType==='SHIPPER'">
          <span class="fs_14 fsb_6">Add Document(s)</span>
          <span class="material-icons txt_p fsb_7 fs_16 ms-1">add</span>
        </p>
      </div>
      <div class="row">
        <div [ngClass]="type=== 'side-panel' ? 'col-md-12' : 'col-md-6'" *ngFor="let shipmentDocument of shipperDocumentGet;">
          <div class="coi_doc pointer" (click)="navigate(shipmentDocument?.mediaUrl)">
            <div class="open_newWindow" *ngIf="userType==='SHIPPER'">
              <mat-icon (click)="userPopup('shipperDocEdit',shipmentDocument)" class="doc_actn edit_doc">edit</mat-icon>
              <mat-icon (click)="deleteDocu(shipmentDocument)" class="doc_actn remove_doc txt_d">cancel</mat-icon>
            </div>
            <div class="coi_docl">
              <span class="">
                <img src="{{imageCheck(shipmentDocument?.mediaUrl)}}" class="img-fluid">
              </span>
              <div class="coi_docTxt">
                <p class="fs_16 fsm_5 txt_b mb-1">{{(shipmentDocument.description) | titlecase}}</p>
                <p class="fs_12 fsn_4 txt_lb mb-0">Uploaded on {{shipmentDocument.updatedAt | date: 'MMM dd, yyyy'}} at {{shipmentDocument.updatedAt | date: 'HH:mm'}}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- empty doc card -->
  <div class="detail_card_con" *ngIf="!documentSklitor && loadDetail?.shipperId && shipperDocumentGet?.length===0">
    <div class="title_action">
      <p class="line_before mb-0">Shipper Documents</p>
      <p class="add_doc_action" (click)="userPopup('multipleDoc',shipperDocumentGet)" *ngIf="userType==='SHIPPER'">
        <span class="fs_14 fsb_6">Add Document(s)</span>
        <span class="material-icons txt_p fsb_7 fs_16 ms-1">add</span>
      </p>
    </div>
    <div class="empty_docs">
      <div class="Empty_State_body b_shadow_0" style="padding: 3rem 0;">
        <div class="Empty_State_con">
          <div class="Empty_State_img">
            <img class="img-fluid" src="{{serverPathForUrl}}/portal/images/after-login/Empty_State_icons/gr-no-documents.svg">
          </div>
          <div>
            <p class="fs_24 fsb_6 txt_b pb-1" style="margin: 30px 0 12px;">No shipper document Uploaded <span class="txt_p"> yet...</span> </p>
            <p class="fs_16 fsn_4 txt_lb pb-1" style="margin: 0px 0 20px ">This load is currently active, but the shipper has not uploaded any shipper documents.</p>
          </div>
          <div class="text-center"></div>
        </div>
      </div>
    </div>
  </div>
  <!-- skt loader -->
  <div class="detail_card_con" *ngIf="documentSklitor">
    <div class="document_skt">
      <div class="mb-3">
        <ngx-skeleton-loader count="1" [theme]="{ width: '70%', height: '30px'}"></ngx-skeleton-loader>
      </div>
      <div class="row">
        <div [ngClass]="type=== 'side-panel' ? 'col-md-12' : 'col-md-6'" *ngFor="let fake of generateFake(4)">
          <div class="skt_doc loader_mb_0">
            <div class="skt_docl">
              <ngx-skeleton-loader count="1" [theme]="{ width: '100%', height: '46px'}"></ngx-skeleton-loader>
            </div>
            <div class="skt_docr">
              <ngx-skeleton-loader count="1" [theme]="{ width: '100%', height: '20px'}"></ngx-skeleton-loader>
              <ngx-skeleton-loader count="1" [theme]="{ width: '100%', height: '14px'}"></ngx-skeleton-loader>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  