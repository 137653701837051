import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { CommonService } from '../../commons/service/common.service';
import { AppSettings } from '../../commons/setting/app_setting';
import { AlertService } from '../../commons/service/alert.service';
import { SharedService } from '../../commons/service/shared.service';
import { MatDialog } from '@angular/material/dialog';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-ach-card',
  templateUrl: './ach-card.component.html',
  styleUrls: ['./ach-card.component.css'],
})
export class AchCardComponent implements OnInit {
  @Input() currentCardActive: any;
  @Input() pageName: any;
  @Output() selectPaymentCardAch = new EventEmitter();
  public skeletonLoaderCard = false;
  public cardListing = [];
  public loading = false;
  public serverPathForUrl: any;
  public has_more = false;
  public getwindow: any;
  public startingAfter = null;
  public selectedCardCheck = false;
  public timer: any;
  
  constructor(
    public sharedService: SharedService,
    public dialog: MatDialog,
    public commonService: CommonService,
    public alertService: AlertService
  ) { }

  ngOnInit() {
    this.serverPathForUrl = environment.serverPathForUrl;
    this.getCardListing();
    this.sharedService.ACHService.subscribe((userData) => {
      this.closeWindowStripe();
    });
  }

  selectCardAch(id: any) {
    if (this.pageName !== 'overview') {
      this.selectedCardCheck = true;
      this.selectPaymentCardAch.emit(id);
      this.currentCardActive = id;
    }
  }

  checkSelectedCard(id: any) {
    if (this.pageName !== 'overview') {
      if (!this.selectedCardCheck) {
        this.selectPaymentCardAch.emit(id);
        this.currentCardActive = id;
      }
    }
  }
  public generateFake(number: any) {
    return this.sharedService.generateFakeSkeleton(number);
  }

  addPaymentCard() {
    this.achBankDetails();
  }

  achBankDetails() {
    this.loading = true;
    let postBody = null;
    postBody = {
    };
    let APIparams = {
      apiKey: AppSettings.APIsNameArray.ACHPAYMENT.ACHADD,
      uri: '',
      postBody: postBody,
    };
    this.commonService.post(APIparams).subscribe(
      (success) => {
        if (success.success === true) {
          this.getwindow = window.open(
            success.response.url,
            'myWindow',
            'width=600,height=600'
          );
          localStorage.setItem('windowClose', this.getwindow);
          this.timer = setInterval(() => {
            this.checkChild()
          }, 500)

          this.loading = false;
        } else if (success.success === false) {
          this.loading = false;
        }
      },
      (error) => {
        this.alertService.showNotificationMessage(
          'danger',
          'bottom',
          'right',
          'txt_g',
          'error',
          'Unexpected Error',
          AppSettings.ERROR
        );
        this.loading = false;
      }
    );
  }

  checkChild() {
    if (this.getwindow.closed) {
      clearInterval(this.timer);
      this.closeWindowStripe();
    }
  }

  closeWindowStripe() {
    if (this.getwindow) {
      this.getwindow.close();
      this.getCardListing();
      this.getwindow = null;
    }
  }

  getCardListing(params = {}) {
    this.skeletonLoaderCard = false;
    let uri = null;
    let paramsNew = {
      type: 'us_bank_account',
      limit: '8'
    };
    if (paramsNew) uri = this.commonService.getAPIUriFromParams(paramsNew);
    let APIparams = {
      apiKey: AppSettings.APIsNameArray.ACHPAYMENT.ACHLIST,
      uri: uri,
    };
    this.commonService.getList(APIparams).subscribe(
      (ServerRes) => {
        if (ServerRes.success === true) {
          this.cardListing = ServerRes.response.data;
          this.has_more = ServerRes.response.has_more;
          this.startingAfter = this.cardListing.length ? this.cardListing[this.cardListing?.length - 1].id : '';
          this.skeletonLoaderCard = true;
        } else {
          this.cardListing = [];
          this.skeletonLoaderCard = true;
        }
      },
      (error) => {
        this.skeletonLoaderCard = true;
        this.cardListing = [];
      }
    );
  }

  MoreCards() {
    this.skeletonLoaderCard = false;
    let uri = null;
    let paramsNew = {
      type: 'us_bank_account',
      limit: '8',
      startingAfter: this.startingAfter
    };
    if (paramsNew) uri = this.commonService.getAPIUriFromParams(paramsNew);
    let APIparams = {
      apiKey: AppSettings.APIsNameArray.ACHPAYMENT.ACHLIST,
      uri: uri,
    };
    this.commonService.getList(APIparams).subscribe(
      (ServerRes) => {
        if (ServerRes.success === true) {
          let newCards = ServerRes.response.data;

          for (let i = 0; i < newCards.length; i++) {
            this.cardListing.push(newCards[i]);
          }

          this.has_more = ServerRes.response.has_more;
          this.startingAfter = this.cardListing[this.cardListing.length - 1].id;
          this.skeletonLoaderCard = true;
        } else {
          this.cardListing = [];
          this.skeletonLoaderCard = true;
        }
      },
      (error) => {
        this.skeletonLoaderCard = true;
        this.cardListing = [];
      }
    );

  }

  setDefualtCard(id: any) {
    this.loading = true;
    let APIparams = {
      apiKey: AppSettings.APIsNameArray.ACHPAYMENT.SETCARD,
      uri: '',
      postBody: { paymentMethodId: id },
    };
    this.commonService.post(APIparams).subscribe(
      (success) => {
        if (success.success === true) {
          this.alertService.showNotificationMessage(
            'success',
            'bottom',
            'right',
            'txt_s',
            'check_circle',
            'Default Payment Method',
            'You have successfully updated the default payment method.'
          );
          this.loading = false;
          this.getCardListing();
        } else if (success.success === false) {
          this.loading = false;
          this.alertService.showNotificationMessage(
            'success',
            'bottom',
            'right',
            'txt_d',
            'check_circle',
            'Default Payment Method',
            success.message
          );
        }
      },
      (error) => {
        this.alertService.showNotificationMessage(
          'danger',
          'bottom',
          'right',
          'txt_g',
          'error',
          'Unexpected Error',
          AppSettings.ERROR
        );
        this.loading = false;
      }
    );
  }
 
}
