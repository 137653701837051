<div class="assign_driver_pop">
  <div class="header_popup">
    <p class="fs_20 fsm_5 txt_b line_before ">Assign Driver</p>
    <span class="close_popup_Icon pointer" (click)="onNoClick()">
      <img src="{{serverPathForUrl}}/portal/icon/ic_close_white.svg" class="close_Icon">
    </span>
  </div>
    <div *ngIf="driverSkeletonLoader"> <!-- -->
      <div class="self_assign_driver_skt" *ngFor="let fake of generateFake(1)">
        <div class="sktl">
          <ngx-skeleton-loader count="1" [theme]="{ width: '46px', height: '46px'}"></ngx-skeleton-loader>
        </div>
        <div class="sktr">
          <ngx-skeleton-loader count="1" [theme]="{ width: '100%', height: '16px'}" class="loader_mb_0"></ngx-skeleton-loader>
          <ngx-skeleton-loader count="1" [theme]="{ width: '100%', height: '14px'}" class="loader_mb_0"></ngx-skeleton-loader>
          <ngx-skeleton-loader count="1" [theme]="{ width: '100%', height: '12px'}" class="loader_mb_0"></ngx-skeleton-loader>
        </div>
      </div>
    </div>
  
  <div class="self_assign" *ngIf="!checkOccuiped && !driverSkeletonLoader">
    <div class="self_assign_con pointer" (click)="assignToConfirm(userDetails)">
      <div class="con_img" *ngIf="userDetails.profileImage">
        <img src="{{userDetails.profileImage}}" class="img-fluid">
      </div>
      <div class="con_img" *ngIf="!userDetails.profileImage">
        <span class="initial_name initial_r_28 ">{{userDetails.legalName | acronymOfName}}</span>
      </div>
      <p class="img_mess ">Assign to Self</p>
    </div>
  </div>
  
  <div class="self_assign" *ngIf="checkOccuiped && !driverSkeletonLoader">
    <div class="self_assign_con">
      <p class="fs_12 fsm_5 txt_d mb-0 text-center">
        <i>You can not assign this load to yourself <br> as you already have one active load.</i>
      </p>
    </div>
  </div>

  <div class="v_scrollBar driver_listH" style="margin: 0 -24px; padding: 0 24px;">
    <div *ngIf="!driverSkeletonLoader && driverList?.length > 0">
      <div class="driver_list" *ngFor="let driverRecord of driverList">
        <div class="dri_info_l">
          <div>
            <div class="dri_img" *ngIf="driverRecord.profileImage">
              <img src="{{driverRecord.profileImage}}" class="img-fluid">
            </div>
            <div class="dri_img" *ngIf="!driverRecord.profileImage">
              <span class="initial_name initial_r_44 ">{{driverRecord.name | acronymOfName}}</span>
            </div>
          </div>
          
          <div class="dri_info">
            <p class="dri_name ">{{driverRecord.name}}</p>
            <div class="dri_othe_info">
              <div class="dri_mile">
                <p class="d_label">Miles Driven</p>
                <p class="d_value">{{driverRecord.milesDriven ? driverRecord.milesDriven : "-"}}</p>
              </div>
              <div class="dri_exp">
                <p class="d_label">Experience</p>
                <p class="d_value">{{driverRecord.workingSince | dateAgo}}</p>
              </div>
              <div class="dri_lst_ship">
                <p class="d_label">Last Load</p>
                <p class="d_value">{{driverRecord.lastShipment ? driverRecord.lastShipment : "-"}}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="assign_action">
          <button class="btn custum_button button_primary boxS_0" (click)="assignToConfirm(driverRecord)">Assign</button>
        </div>
      </div>
    </div>

    <div class="driver_list" *ngIf="!driverSkeletonLoader && driverList?.length===0">
      <div class="Empty_State_body boxS_0 mb-0 pb-0">
        <div class="Empty_State_con" style="max-width: 80%;">
          <div class="Empty_State_img">
            <img class="img-fluid" src="{{serverPathForUrl}}/portal/images/after-login/Empty_State_icons/gr-assign-driver.svg">
          </div>
          <p class="fs_24 fsb_6 txt_b pb-1 " style="margin: 30px 0 12px;">No Drivers <span class="txt_p "> Available</span> </p>
          <p class="fs_16 fsn_4 txt_lb pb-1" style="margin: 0px 0 20px;">You have either not added any drivers or all added drivers are currently assigned to loads. If you are also a driver, You have the option of assigning the load to yourself.</p>
        </div>
      </div>
    </div>

    <div *ngIf="driverSkeletonLoader && driverList?.length===0"> <!-- -->
      <div class="driver_list_skt" *ngFor="let fake of generateFake(5)">
        <div class="sktl">
          <ngx-skeleton-loader count="1" [theme]="{ width: '46px', height: '46px'}"></ngx-skeleton-loader>
        </div>
        <div class="sktr">
          <ngx-skeleton-loader count="1" [theme]="{ width: '100%', height: '16px'}" class="loader_mb_0"></ngx-skeleton-loader>
          <ngx-skeleton-loader count="1" [theme]="{ width: '100%', height: '14px'}" class="loader_mb_0"></ngx-skeleton-loader>
          <ngx-skeleton-loader count="1" [theme]="{ width: '100%', height: '12px'}" class="loader_mb_0"></ngx-skeleton-loader>
        </div>
      </div>
    </div>
  </div>
</div>

