<div class="detail_card_con mt-4" style="position: relative;">
    <div class="carrierBid_con acceptd_previous_table mt-0">
      <div class="expected_bidRate">
        <span class="fs_13 fsm_5 txt_b usdUdsd">
          <span class="txt_lb me-1">Expected Rate:</span>
          <span>${{shipmentDetail?.freightRate ? shipmentDetail?.freightRate : '-'}}</span>
        </span>
      </div>
      <div class="carrierList_head mb-3" [ngClass]="currentCarrierTab==='connected' ? 'visibility_off' : 'visibility_on'">
        <p class="line_before mb-0 ">Add Carriers to Bid</p>
        <div class="d-flex align-items-center">
          <div class="visibility_carrier me-3" [ngClass]="privatePublic ? 'visibility_on' : 'visibility_off'">
            <div class="custom_toggle">
              <span class="fs_16 fsm_5 me-3 visibilityl">Public</span>
              <mat-slide-toggle class="" [(ngModel)]="privatePublic" (change)="changeVisibilityText($event)"></mat-slide-toggle>
              <span class="fs_16 fsm_5 ms-3 visibilityr">Private</span>
              <div><i *ngIf="!privatePublic" class="fs_14">*Load will be visible to all carriers on the network</i></div>
            </div>
          </div>
  
          <div class="custom_redio_btn" *ngIf="privatePublic">
            <mat-radio-group aria-label="Select an option" [(ngModel)]="carrierType" (change)="changeCarrier($event)">
              <mat-radio-button value="confirmedOnLane" *ngIf="shipmentDetail?.laneId"
                class="fs_14 fsn_4 txt_lb me-3">Confirmed Carriers</mat-radio-button>
              <mat-radio-button value="connected" class="fs_14 fsn_4 txt_lb me-3">All Carriers</mat-radio-button>
              <mat-radio-button value="preferred" class="fs_14 fsn_4 txt_lb"> Preferred Carriers</mat-radio-button>
            </mat-radio-group>
          </div>
        </div>
      </div>
  
      <p class="slected_carrier_bar b_grey" *ngIf="carrierListAdd && carrierListAdd?.length > 0 && privatePublic">
        <span *ngIf="isAllCarrier === false && (allPreCheckCarrier?.length != totalCarriers)">
          <span>{{allPreCheckCarrier?.length}} carriers selected out of {{totalCarriers}}.</span><br>
          <span>Please scroll down to select more carriers.</span>
        </span>
        <a *ngIf="isAllCarrier === false && (allPreCheckCarrier?.length != totalCarriers)" (click)="onSelectAll()"
          class="pointer fw-5">Select All Carriers</a>
        <span *ngIf="isAllCarrier === true || allPreCheckCarrier?.length == totalCarriers" class="select_carriers_btn">
          <a>
            <span style="color: #818181; cursor: initial;"> All {{totalCarriers}} Carriers Selected</span>
          </a>
          <a (click)="onClearAllData()" class="fw-5">Clear All</a>
        </span>
      </p>
      <div class="list_scroll addCarrier_bid" infiniteScroll [infiniteScrollDistance]="3" [infiniteScrollThrottle]="100"
        (scrolled)="onCarrierDataScroll()" [scrollWindow]="false" *ngIf="!allAddCarrierSkeloton && carrierListAdd && carrierListAdd?.length > 0 && privatePublic">
        <table class="table mb-0">
          <thead>
            <tr style="text-transform: uppercase;">
              <th scope="col" class="ps-0">
                <span class="fs_12 fsm_5 txt_lb ">Carriers</span>
              </th>
              <th scope="col" class="ps-0" *ngIf="shipmentDetail?.laneId">
                <span class="fs_12 fsm_5 txt_lb ">Confirmed Rate</span>
              </th>
              <th scope="col">
                <span class="fs_12 fsm_5 txt_lb ">Miles Driven</span>
                <span [ngClass]="columnp === 'totalMiles' && isDescp ? 'icon-Up' : 'icon-ic-Down'"
                  class="icon fs_16 txt_lb px-2 pointer" (click)="sortCarriers('totalMiles')"></span>
              </th>
              <th scope="col">
                <span class="fs_12 fsm_5 txt_lb ">Experience</span>
                <span [ngClass]="columnp === 'addDate' && isDescp ? 'icon-Up' : 'icon-ic-Down'"
                  class="icon fs_16 txt_lb px-2 pointer" (click)="sortCarriers('addDate')"></span>
              </th>
              <th scope="col">
                <span class="fs_12 fsm_5 txt_lb ">Equipment Type</span>
              </th>
            </tr>
          </thead>
          <tbody>
            <!--New code start-->
            <tr *ngFor="let carrierAddRecord of carrierListAdd;let j=index;" class="addCarriersToBid">
              <td scope="row" class="ps-0">
                <div class="carrierN">
                  <div class="carrierN_img">
                    <span *ngIf="carrierAddRecord?.isPreferred === 1" class="material-icons star_marked">star</span>
                    <span class="ca_pro_img" *ngIf="!carrierAddRecord.profileImage">
                      <span class="initial_name initial_r_32 ">{{getInitials(carrierAddRecord?.legalName)}}</span>
                    </span>
                    <span class="ca_pro_img" *ngIf="carrierAddRecord.profileImage">
                      <img src="{{carrierAddRecord.profileImage}}" class="img-fluid">
                    </span>
                  </div>
                  <div class="carrierNl">
                    <p class="abl_ca_name fs_16 fsm_5 txt_b m-0  d-flex">
                      <span class="text_truncate w_100">{{carrierAddRecord?.legalName}}</span><span
                        *ngIf="userType=='SHIPPER'">
                        <img *ngIf="carrierAddRecord?.subscription"
                          src="{{serverPathForUrl}}/portal/images/after-login/premium_plan_icon.png"
                          class="img-fluid verified_icon">
                      </span>
                    </p>
                  </div>
                </div>
              </td>
              <td *ngIf="shipmentDetail?.laneId" class="fs_14 fsn_4 txt_b">{{carrierAddRecord?.laneBids?.bidAmount ?
                "$"+carrierAddRecord?.laneBids?.bidAmount : "-"}}
              </td>
              <td class="fs_14 fsn_4 txt_b">{{carrierAddRecord?.totalMiles ? carrierAddRecord?.totalMiles : '-' }}</td>
              <td class="fs_14 fsn_4 txt_b">
                <span *ngIf="carrierAddRecord?.addDate">{{carrierAddRecord?.addDate | dateAgo}}</span>
                <span *ngIf="!carrierAddRecord?.addDate"></span>
              </td>
              <td class="fs_14 fsn_4 txt_b" style="max-width: 200px;">
                <span matTooltip="{{getEquipmentType(carrierAddRecord?.equipmentType)}}"
                  class="text_truncate w_100">{{getEquipmentType(carrierAddRecord?.equipmentType)}}</span>
              </td>
              <td class="fs_14 fsn_4 txt_b text-center pe-0">
                <span class="circle_check cirCheck_22">
                  <mat-checkbox [(ngModel)]="carrierAddRecord.isSelected" (ngModelChange)="selectCarrierData($event,carrierAddRecord?.userId)"></mat-checkbox>
                </span>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="content_loader" *ngIf="moreRecordLoader">
          <span class="loader_image">
            <img src="{{serverPathForUrl}}/portal/images/after-login/cl-icons/loader-blue.svg"
              class="img-fluid">
          </span>
        </div>
      </div>
      <div *ngIf="allAddCarrierSkeloton && privatePublic">
        <table class="table mb-0">
          <thead>
            <tr style="height: 65px;">
              <td></td>
              <td></td>
            </tr>
          </thead>
          <tbody>
            <tr style="height: 65px;" *ngFor="let fake of generateFake(5)">
              <td>
                <div class="loader_mb_0">
                  <ngx-skeleton-loader count="1" [theme]="{ width: '45%', height: '16px'}">
                  </ngx-skeleton-loader>
                </div>
              </td>
              <td>
                <div class="loader_mb_0">
                  <ngx-skeleton-loader count="1" [theme]="{ width: '40%', height: '16px'}">
                  </ngx-skeleton-loader>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div *ngIf="carrierListAdd?.length === 0 && !allAddCarrierSkeloton">
        <div class="Empty_State_body b_shadow_0" style="padding-top: 6rem;">
          <div class="Empty_State_con">
            <div class="Empty_State_img">
              <img class="img-fluid"
                src="{{serverPathForUrl}}/portal/images/after-login/Empty_State_icons/graphic_no-carrier.svg">
            </div>
            <div style="max-width: 400px">
              <div *ngIf="currentCarrierTab==='connected'">
                <p class="fs_24 fsb_6 txt_b pb-1 " style="margin: 30px 0 12px;">No Carriers Connection... <span
                    class="txt_p "> yet</span>
                </p>
                <p class="fs_16 fsn_4 txt_lb pb-1" style="margin: 0px 0 20px;">Use the network to create connections with
                  Carriers and assign them shipment.</p>
              </div>
              <div *ngIf="currentCarrierTab==='preferred'">
                <p class="fs_24 fsb_6 txt_b pb-1 " style="margin: 30px 0 12px;">No Preferred Carriers... <span
                    class="txt_p "> yet</span>
                </p>
                <p class="fs_16 fsn_4 txt_lb pb-1" style="margin: 0px 0 20px;">Use the network to create connections with
                  Carriers and acquire loads. Once you establish connections, you can message Carriers about future loads.
                </p>
              </div>
            </div>
            <div class="text-center">
              <button type="button" class="btn custum_button button_primary" [routerLink]="['/network/home']">Visit the
                network</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="carrierBid_action">
      <button class="btn custum_button button_primary" [disabled]="(!allPreCheckCarrier?.length && !allCheckCarrier?.length && privatePublic)"
      (click)="assigCarrier()">Update</button>
  </div>
  <ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '14px',fullScreenBackdrop:true }">
  </ngx-loading>