<div class="">
    <div class="create_lanes_p">
      <div class="post_lanes_card" *ngIf="skeletonLoader">
        <div class="booking_lane">
          <div class="lane_card_dropdown" data-toggle="dropdown">
            <div class="dropdown_menu_list">
              <span class="list_option_icon"></span>
              <div class="dropdown-menu">
                <span class="dropdown-item" (click)="openNewTabRecord(laneRecord,currentStatus)">Open in new tab</span>
                <span *ngIf="userType ==='SHIPPER'" [class.disabled]="currentStatus == 'archivedLanes' || currentStatus == 'confirmedLanes' || laneRecord?.status == 3" class="dropdown-item" (click)="openEditForm(laneRecord.id)">Edit</span>
                <span *ngIf="userType ==='SHIPPER'" [class.disabled]="currentStatus == 'archivedLanes'" class="dropdown-item" (click)="openArchived(laneRecord.id, true)">Archive Lane</span>
              </div>
            </div>
          </div>
          <div class="shipment_card_T pointer" (click)="openCarrierRecordView(laneRecord)">
            <div class="sct_head">
              <div class="sct_head_l">
                <div *ngIf="userType==='SHIPPER'" matTooltip="{{laneRecord?.title}}">
                  <span class="text-truncate fs_14 fsm_5">{{laneRecord?.title ? laneRecord?.title : '-'}}</span>
                </div>
                <div *ngIf="userType==='CARRIER'" matTooltip="{{laneRecord?.title}}">
                  <span class="text-truncate fs_14 fsm_5">{{laneRecord?.title ? laneRecord?.title : '-'}}</span>
                </div>
              </div>
              <div class="sct_head_r">
              </div>
            </div>
            
            <p class="keypoints">
              <span class="text-truncate" matTooltip="{{laneRecord?.equipmentName}}"> {{laneRecord?.equipmentName ? laneRecord?.equipmentName : '-'}}</span>
              <span class="text-truncate" matTooltip="{{milesCeil(laneRecord?.miles)}} Miles">{{ milesCeil(laneRecord?.miles)}} Miles</span>
            </p>
            <div class="sc_locations ng-star-inserted">
              <div class="sc_loca_picup">
                <span class="icon-ic-pickupq loca_iconFlag">
                  <img src="\assets\icon\new-flag-card.svg" class="img-fluid ">
                </span>
                <div class="p_loca_time">
                  <p class="loca_picup text_truncate"> {{(laneRecord?.waypoints[0]?.city ? laneRecord?.waypoints[0]?.city : laneRecord?.waypoints[0]?.state) +", " + laneRecord?.waypoints[0]?.state +", " + laneRecord?.waypoints[0]?.countryName}}</p>
                </div>
              </div>
              <div class="sc_loca_drop">
                <span>
                  <img src="\assets\icon\new-location-icon.svg" class="loca_icon">
                </span>
                <div class="d_loca_time">
                  <p class="loca_drop text_truncate">{{(laneRecord?.waypoints[1]?.city ? laneRecord?.waypoints[1]?.city : laneRecord?.waypoints[1]?.state) + ", " + laneRecord?.waypoints[1]?.state +", " + laneRecord?.waypoints[1]?.countryName}}</p>
                </div>
              </div>
            </div>
            <div *ngIf="userType ==='SHIPPER' && (currentStatus == 'allLanes' || currentStatus == 'quotedLanes') && laneRecord?.rateType=='2'" class="fs_12 fsm_5 rcv_bid border_bt">
              <span>Fixed Rate</span> 
              <span class="txt_b" [ngClass]="estimateAmount <= '5' ? '' : 'flex_column'"> 
                ${{(laneRecord?.estimatedPrice ? laneRecord?.estimatedPrice : '-') | twoDecimalPipe}}
                <app-rate-per-mile class="fs_12 fsm_5 mt-1 txt_db" [amount]="laneRecord?.estimatedPrice" [miles]="laneRecord?.miles"></app-rate-per-mile>
              </span>
            </div>
            <div *ngIf="userType ==='SHIPPER' && (currentStatus == 'allLanes' || currentStatus == 'quotedLanes') && laneRecord?.rateType=='1'" class="fs_14 fsm_5 rcv_bid border_bt">
              <span>Quotes Received </span>
              <span class="txt_b"> {{laneRecord?.bidReceived ? laneRecord?.bidReceived : '0'}} </span>
            </div>
            <div *ngIf="userType ==='SHIPPER' && currentStatus == 'confirmedLanes'" class="fs_12 fsm_5 rcv_bid border_bt">
              <span class="txt_green">Confirmed Carriers</span>
              <span class="txt_b">{{(laneRecord?.confirmedCarriers | twoDecimalPipe)}}</span>
            </div>
            <div *ngIf="userType ==='SHIPPER' && currentStatus == 'confirmRateHistory'" class="fs_12 fsm_5 rcv_bid border_bt">
              <span class="txt_green">Total Loads</span>
              <span class="txt_b">{{laneRecord?.shipmentCount ? laneRecord?.shipmentCount : '-'}}</span>
            </div>
            <div *ngIf="userType ==='SHIPPER' && currentStatus == 'archivedLanes'" class="fs_14 fsm_5 rcv_bid border_bt">
              <span class="txt_p">Archived Lane</span>
            </div>
            <div *ngIf="userType ==='CARRIER'" class="fs_14 fsm_5 rcv_bid border_bt">
              <span>Frequency</span>
              <span class="txt_b">
                {{laneRecord?.monthlyFrequency ? laneRecord?.monthlyFrequency : '-'}} / <span>Month</span>
              </span>
            </div>
            <div *ngIf="userType ==='CARRIER' && (currentStatus == 'allLanes' || currentStatus=='quotedLanes') && !laneRecord?.bids?.bidAmount && laneRecord?.rateType=='1'" class="fs_14 fsm_5 rcv_bid border_b">
              <span>Quote/Bid Rate</span>
              <span class="txt_b" *ngIf="!laneRecord?.bids?.bidAmount">-</span>
              <span class="txt_b" *ngIf="laneRecord?.bids?.bidAmount">
                ${{(laneRecord?.estimatedPrice ? laneRecord?.estimatedPrice : '-') | twoDecimalPipe}}
                <app-rate-per-mile class="fs_12 fsm_5 mt-1 txt_db" [amount]="laneRecord?.estimatedPrice" [miles]="laneRecord?.miles"></app-rate-per-mile>
              </span>
            </div>
  
            <div *ngIf="userType ==='CARRIER' && (currentStatus == 'allLanes' || currentStatus=='quotedLanes') && laneRecord?.rateType=='2'" class="fs_14 fsm_5 rcv_bid border_b">
              <span class="ta_l">Fixed Rate</span>
              <span class="txt_b" [ngClass]="estimateAmount <= '5' ? '' : 'flex_column'">
                ${{(laneRecord?.estimatedPrice ? laneRecord?.estimatedPrice : '-') | twoDecimalPipe}}
                <app-rate-per-mile class="fs_12 fsm_5 mt-1 txt_db" [amount]="laneRecord?.estimatedPrice" [miles]="laneRecord?.miles"></app-rate-per-mile>
              </span>
            </div>
  
            <div *ngIf="userType ==='CARRIER' && (currentStatus == 'allLanes' || currentStatus=='quotedLanes') && laneRecord?.bids?.bidAmount && laneRecord?.rateType=='1'" class="fs_14 fsm_5 rcv_bid border_b">
              <span class="ta_l">Quote/Bid Rate</span>
              <span class="txt_b" [ngClass]="bidAmount <= '5' ? '' : 'flex_column'">
                ${{(laneRecord?.bids?.bidAmount ? laneRecord?.bids?.bidAmount : '-') | twoDecimalPipe}}
                <app-rate-per-mile class="fs_12 fsm_5 mt-1 txt_db" [amount]="laneRecord?.bids?.bidAmount" [miles]="laneRecord?.miles"></app-rate-per-mile>
              </span>
            </div>
            <div *ngIf="((userType ==='CARRIER' && currentStatus == 'confirmedLanes') || (userType ==='CARRIER' && laneRecord?.status==3))" class="fs_14 fsm_5 rcv_bid border_b">
              <span class="txt_green">Confirmed</span>
              <span class="txt_b" [ngClass]="bidAmount <= '5' ? '' : 'flex_column'">
                ${{(laneRecord?.bids?.bidAmount ? laneRecord?.bids?.bidAmount : '-' | twoDecimalPipe)}}
                <app-rate-per-mile class="fs_12 fsm_5 mt-1 txt_db" [amount]="laneRecord?.bids?.bidAmount" [miles]="laneRecord?.miles"></app-rate-per-mile>
              </span>
            </div>
          </div>
          <div class="pointer" *ngIf="userType ==='CARRIER' && currentStatus == 'confirmedLanes'" (click)="eventActionProfileSidePanel(laneRecord,'shipper_side_panel')">
            <div class="detail_img">
              <div *ngIf="laneRecord?.shippers?.profileImage" class="s_user_img">
                <img src="{{laneRecord?.shippers?.profileImage}}" class="img-fluid">
              </div>
              <div *ngIf="!laneRecord?.shippers?.profileImage" class="s_user_img">
                <span class="initial_name initial_r_38">{{laneRecord?.shippers?.companyName | acronymOfName}}</span>
              </div>
              <div class="u_detail">
                <div style="display: flex; text-align: center;">
                  <p class="u_detail_value text_truncate pointer" matTooltip="{{laneRecord?.shippers?.companyName}}"> {{laneRecord?.shippers?.companyName ? laneRecord?.shippers?.companyName : '-'}}</p>
                </div>
                <p class="u_detail_lable">
                  <span>Shipper</span>
                </p>
              </div>
            </div>
          </div>
          <div *ngIf="userType ==='CARRIER' && currentStatus == 'confirmedLanes'">
            <p>You have confirmed a rate. You are now eligible to get Loads on this lane.</p>
          </div>
        </div>
        <div *ngIf="userType ==='SHIPPER' && (currentStatus == 'allLanes' || currentStatus=='quotedLanes')" class="view_btn">
          <button (click)="viewLaneBidBidding(laneRecord?.id)">View Quotes</button>
        </div>
        <div *ngIf="userType ==='SHIPPER' && (currentStatus == 'confirmedLanes' || currentStatus == 'confirmRateHistory')" class="view_btn">
          <button (click)="viewLaneBid(laneRecord?.id)">View Details</button>
        </div>
        <div *ngIf="userType ==='SHIPPER' && currentStatus == 'archivedLanes'" class="view_btn">
          <button (click)="viewLaneBid(laneRecord?.id)">View Details</button>
        </div>
        <div *ngIf="userType ==='CARRIER' && !laneRecord?.bids && laneRecord?.rateType == '1' && laneRecord?.status!==3" class="view_btn">
          <button (click)="eventAction(laneRecord,'firstLaneBid')">Submit Your Quote/Bid</button>
        </div>
        <div *ngIf="userType ==='CARRIER' && laneRecord?.rateType == '2' && (currentStatus == 'allLanes' || currentStatus=='quotedLanes') && laneRecord?.isConnected == true && laneRecord?.status!==3" class="view_btn">
          <button (click)="eventAction(laneRecord,'laneAcceptBidForFixRate')" class="bg_green">Accept</button>
        </div>
        <div *ngIf="userType ==='CARRIER' && laneRecord?.rateType == '2' && (currentStatus == 'allLanes' || currentStatus=='quotedLanes') && laneRecord?.isConnected == false && laneRecord?.status!==3" class="view_btn">
          <button (click)="eventAction(laneRecord,'connectInviteLaneFixRate')" class="bg_green">Accept</button>
        </div>
  
        <div class="bid_act_btn">
          <div *ngIf="userType ==='CARRIER' && laneRecord?.bids?.status == 1 " class="view_btn">
            <button (click)="eventAction(laneRecord,'laneRebid')">Re-bid</button>
          </div>
          <div *ngIf="userType ==='CARRIER' && laneRecord?.bids?.status == 1 && laneRecord?.bids?.actionByShipper == 1 && laneRecord?.rateType == '1' && laneRecord?.isConnected == true" class="view_btn">
            <button (click)="eventAction(laneRecord,'laneAcceptBidCarrier')" class="bg_green">Accept</button>
          </div>
          <div *ngIf="userType ==='CARRIER' && laneRecord?.bids?.status == 1 && laneRecord?.bids?.actionByShipper == 1 && laneRecord?.rateType == '1' && laneRecord?.isConnected == false" class="view_btn">
            <button (click)="eventAction(laneRecord,'connectInviteLane')" class="bg_green">Accept</button>
          </div>
          <div *ngIf="userType ==='CARRIER' && laneRecord?.bids?.status == 1 && laneRecord?.bids?.actionByShipper == 0" class="view_btn">
            <button class="bg_green adisabled">Accept</button>
          </div>
        </div>
      </div>
    </div>
    <div style="position: relative;" *ngIf="sidePanelProfileDataId">
      <div class="side-show-panel">
        <div class="side_bar_con">
          <app-shipper-side-panel [sidePanelProfileData]="sidePanelProfileData" [sidePanelProfileDataId]="sidePanelProfileDataId" [userType]="userType" (closeSidePanel)="closePanel($event)"></app-shipper-side-panel>
        </div>
      </div>
    </div>
  </div>