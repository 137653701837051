interface ScriptPixel {
  name: string;
  src: string;
}

export const ScriptFacebookStore: ScriptPixel[] = [
  {
    name: 'facebookPixel',
    src: 'https://www.facebook.com/tr?id=753986152142037&ev=PageView&noscript=1',
  },
];
