import { Component, OnInit, Input, EventEmitter, Output} from '@angular/core';
import { CommonService } from 'src/app/commons/service/common.service';
import { AppSettings } from 'src/app/commons/setting/app_setting';
import { MatDialog } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { AlertService } from 'src/app/commons/service/alert.service';
import { environment } from 'src/environments/environment';
import { PaymentService } from 'src/app/payment/payment.service';
import { SharedService } from 'src/app/commons/service/shared.service';
import { UserOnboardingComponent } from 'src/app/shared/user-onboarding/user-onboarding.component';
import { NetworkPopupComponent } from 'src/app/network-shared/network-popup/network-popup.component';
import { StatusSetting } from 'src/app/commons/setting/status_setting';
import { SubscriptionPopupComponent } from 'src/app/shared/subscription-popup/subscription-popup.component';
import { LoadPopupComponent } from 'src/app/loads-shared/load-popup/load-popup.component';
declare let Stripe: any;

@Component({
  selector: 'app-shipper-bid',
  templateUrl: './shipper-bid.component.html',
  styleUrls: ['./shipper-bid.component.scss'],
  providers: [PaymentService],
})

export class ShipperBidComponent implements OnInit {
  public sidePanelProfileDataId: any;
  public eCarrierCheckData:any
  public sidePanelProfileData:any;
  @Input() shipmentDetail: any;
  @Input() subscriptionData: any;
  @Output() eventPassAction = new EventEmitter();
  public subCurrentTab: any;
  public currentBid: any = [];
  public previousBid: any;
  public checkPreAllBid = 'allCarrier';
  public allPreCheckCarrier = [];
  public masterSelected: boolean = false;
  public carrierList: any=[];
  public allCarrierSkeloton = false;
  public shipmentConfirmChecked: boolean;
  public page = 1;
  public totalPage = 1;
  public shipmentChecked = false;
  public selectedIndex = 0;
  public serverPathForUrl: any;
  public allAddCarrierSkeloton = false;
  public carrierListAdd: any;
  public loading: boolean = false;
  public visibility = 'private';
  public carrierRecord: any;
  public userType: any;
  public userId: any;
  public userDetails: any;
  public laneaxisPrice: any;
  public priceStripeFixed = 0;
  public priceStripePercent = 0;
  public usdcCardLoader = false;
  public balance: any;
  public checkeUserConnection: any;
  public checkUsdcPayment = false;
  public userAccepted: any;
  public acceptUserId: any;
  public acceptBidId: any;
  public acceptAmount: any;
  public skeletonLoaderAdd = true;
  public shipperDetails: any = {};
  public saveCardButton = true;
  public paymentAddress: any;
  public currentAddressActive: any;
  public paymentCard: FormGroup;
  public paymentForm: FormGroup;
  public paymentFormACH: FormGroup;
  public currentCardActive: any;
  public currentId: any;
  public paymentCardId: any;
  public stripe: any;
  public card: any;
  public shipmentId: any;
  public eCarrierCheckRisk:any;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    public commonService: CommonService,
    public dialog: MatDialog,
    public location: Location,
    private alertService: AlertService,
    public paymentService: PaymentService,
    private sharedService: SharedService,
  ) { 

  }

  async ngOnInit() {
    this.eCarrierCheckRisk=StatusSetting.eCarrierCheckToolTip;
    this.shipmentId = this.route.snapshot.params['loadId'];
    this.currentId = localStorage.getItem('user_id');
    this.paymentFormACH = this.formBuilder.group({
      address: ['', [Validators.required]],
      paymentId: ['', [Validators.required]],
    });

    this.paymentForm = this.formBuilder.group({
      address: ['', [Validators.required]],
      cardName: ['', [Validators.required]],
      paymentId: ['', [Validators.required]],
      recipientName: [localStorage.getItem('userName')],
      street: ['', [Validators.required]],
      city: ['', [Validators.required]],
      state: ['', [Validators.required]],
      zip: ['', [Validators.required]],
      country: ['', [Validators.required]],
    });
    let userDetail = this.sharedService.getAddress();
    if (userDetail !== undefined) {
      this.userInfo(userDetail);
    }

    this.commonService.userData.subscribe((userData) => {
      this.userInfo(userData);
    });
    this.serverPathForUrl = environment.serverPathForUrl;
    this.userType = localStorage.getItem('user_type');
    this.userId = localStorage.getItem('user_id');
    this.getData('connected');
    this.allCarrierSkeloton = false;

  }

  // Function to get user details 
  userInfo(userDtail) {
    this.userDetails = userDtail;
  }

  // Function for generate duplicate loaders 
  public generateFake(count: number): Array<number> {
    const indexes = [];
    for (let i = 0; i < count; i++) {
      indexes.push(i);
    }
    return indexes;
  }

  // To get required param for API
  getAPIParam(type: any) {
    let isConfirmed;
    if (this.shipmentConfirmChecked) {
      isConfirmed = 1;
    } else {
      isConfirmed = 0;
    }
    let APIparams, params;
    params = {
      limit: 10,
      tabType: type,
      shipmentId: this.route.snapshot.params['loadId'],
      isBid: 1,
      page: this.page,
      isConfirmed: isConfirmed,
    };
    APIparams = {
      apiKey: AppSettings.APIsNameArray.SHIPMENTFORSHIPPER.CARRIER,
      uri: this.commonService.getAPIUriFromParams(params),
    };
    return APIparams;
  }

  // function for page scrolling
  onScroll() {
    if (this.page !== this.totalPage) {
      this.page = this.page + 1;
      if (this.shipmentChecked) {
        this.getMoreData('preferred');
      } else {
        this.getMoreData('connected');
      }
    }
  }

  // To get carrier list data 
  getData(type: any) {
    this.allCarrierSkeloton = false;
    this.page = 1;
    var APIparams = this.getAPIParam(type);
    this.commonService.getList(APIparams).subscribe(
      (ServerRes) => {
        this.allCarrierSkeloton = true;
        if (ServerRes.response && ServerRes.response.length > 0) {
          for (let v = 0; v < ServerRes.response.length; v++) {
            ServerRes.response[v].isSelected = false;
            if (ServerRes.response && ServerRes.response[v].previousBid) {
              if (ServerRes.response[v].previousBid) {
                ServerRes.response[v].previousBid.createdAt = new Date(
                  ServerRes.response[v].previousBid.createdAt + ' ' + 'UTC'
                );
              }
              if (ServerRes.response[v].currentBid) {
                ServerRes.response[v].currentBid.createdAt = new Date(
                  ServerRes.response[v].currentBid.createdAt + ' ' + 'UTC'
                );
              }
            }
          }
          this.carrierList = ServerRes.response;
          this.totalPage = ServerRes.totalPages;
        } else {
          this.carrierList = [];
          this.totalPage = 0;
          this.selectedIndex = 0;
        }
      },
      (error) => {
        this.carrierList = [];
        this.allCarrierSkeloton = true;
      }
    );
  }

  // To get pagination for more data on scroll
  getMoreData(type: any) {
    var APIparams = this.getAPIParam(type);
    this.commonService.getList(APIparams).subscribe((ServerRes) => {
      if (ServerRes.response && ServerRes.response.length > 0) {
        for (let v = 0; v < ServerRes.response.length; v++) {
          if (ServerRes.response[v].previousBid) {
            ServerRes.response[v].previousBid.createdAt = new Date(
              ServerRes.response[v].previousBid.createdAt + ' ' + 'UTC'
            );
          }
          if (ServerRes.response[v].currentBid) {
            ServerRes.response[v].currentBid.createdAt = new Date(
              ServerRes.response[v].currentBid.createdAt + ' ' + 'UTC'
            );
          }
          if (ServerRes.response[v])
            this.carrierList.push(ServerRes.response[v]);
        }
      }
    });
  }

  // To get required param for API
  getAPICarrierParam(type) {
    let APIparams, params;
    params = {
      limit: 7,
      tabType: type,
      isLastShipment: 1,
      isTotalMiles: 1,
      page: this.page,
    };
    APIparams = {
      apiKey: AppSettings.APIsNameArray.SHIPMENTFORSHIPPER.CARRIER,
      uri: this.commonService.getAPIUriFromParams(params),
    };
    return APIparams;
  }
  
  // selecting checkbox for carriers
  checkAlreadyCarrier() {
    for (let caIndex in this.carrierListAdd) {
      for (let sIndex in this.allPreCheckCarrier) {
        if (
          this.carrierListAdd[caIndex].userId ===
          this.allPreCheckCarrier[sIndex]
        ) {
          this.carrierListAdd[caIndex].isSelected = true;
          this.allPreCheckCarrier.push(this.carrierListAdd[caIndex].userId);
        }
      }
    }
    this.checkDpulicate();
  }

  // All carrier/preffered carrier toggle 
  changeCarrierBids(event) {
    this.masterSelected = false;
    this.allPreCheckCarrier = [];
    this.allCarrierSkeloton = true;
    this.page = 1;
    if (event.checked) {
      this.getData('preferred');
    } else {
      this.getData('connected');
    }
  }

  //to check duplicate carriers
  checkDpulicate() {
    let checkDuplicate: any;
    let allCarrierDupiicate = [];
    let newCheckEqul = [];
    allCarrierDupiicate = this.allPreCheckCarrier;
    checkDuplicate = allCarrierDupiicate.filter(function (item, pos) {
      return allCarrierDupiicate.indexOf(item) === pos;
    });
    this.allPreCheckCarrier = [];
    this.allPreCheckCarrier = checkDuplicate;
    for (let caIndex in this.carrierListAdd) {
      for (let sIndex in this.allPreCheckCarrier) {
        if (
          this.carrierListAdd[caIndex].userId ===
          this.allPreCheckCarrier[sIndex]
        ) {
          newCheckEqul.push(this.carrierListAdd[caIndex].userId);
          if (this.carrierListAdd.length === newCheckEqul.length) {
            this.masterSelected = true;
          }
        }
      }
    }
  }

  // To get carrier data from list 
  getCarrierData(type: any) {
    this.allAddCarrierSkeloton = true;
    this.page = 1;
    var APIparams = this.getAPICarrierParam(type);
    this.commonService.getList(APIparams).subscribe((ServerRes) => {
      this.allAddCarrierSkeloton = false;
      if (ServerRes.response && ServerRes.response.length > 0) {
        for (let v = 0; v < ServerRes.response.length; v++) {
          ServerRes.response[v].isSelected = false;
        }
        this.carrierListAdd = ServerRes.response;
        this.totalPage = ServerRes.totalPages;
        this.checkAlreadyCarrier();
      } else {
        this.carrierListAdd = [];
        this.totalPage = 0;
        this.selectedIndex = 0;
      }
    });
  }

  //Get price detail for laneaxis
  getPriceLaneaxis() {
    let uri = null;
    //get dynamic URI for APIs
    let paramsNew = {};
    if (paramsNew) uri = this.commonService.getAPIUriFromParams(paramsNew);
    let APIparams = {
      apiKey: AppSettings.APIsNameArray.NEWPAYMENT.FEES,
      uri: uri,
    };
    this.commonService.getList(APIparams).subscribe(
      (ServerRes) => {
        if (ServerRes.response) {
          this.laneaxisPrice = ServerRes.response;
          this.priceStripePercent =
            this.laneaxisPrice.payOrder.usd.shipper.stripe.percent;
          this.priceStripeFixed =
            this.laneaxisPrice.payOrder.usd.shipper.stripe.fixed;
        } else {
          this.laneaxisPrice = null;
        }
      },
      (err) => {
        this.laneaxisPrice = null;
        this.alertService.showNotificationMessage(
          'danger',
          'bottom',
          'right',
          'txt_g',
          'error',
          'Unexpected Error',
          AppSettings.ERROR
        );
      }
    );
  }

// For LaneAxis Visibility Fee
  frightVissionFeeUsdc() {
    return parseFloat('5');
  }

 // LaneAxis price calculation
  laneaxisFee(currentBidAmount: any) {
    let laneaxisPercent =
      this.laneaxisPrice.payOrder.usd.shipper.laneAxis.percent;
    let lanexisPrice = (currentBidAmount * laneaxisPercent) / 100;
    return lanexisPrice;
  }

  // To get Transaction Fee
  priceFixed(currentBidAmount: any) {
    let laneaxisPercent =
      this.laneaxisPrice.payOrder.usd.shipper.laneAxis.percent;
    let stripePercent = this.laneaxisPrice.payOrder.usd.shipper.stripe.percent;
    let stripeFixed = this.laneaxisPrice.payOrder.usd.shipper.stripe.fixed;
    let lanexisFixed = this.laneaxisPrice.payOrder.usd.shipper.laneAxis.fixed;
    let lanexisPricePercent = (
      (currentBidAmount * laneaxisPercent) /
      100
    ).toFixed(2);
    let totalForTrasitionFees =
      parseFloat(currentBidAmount) +
      parseFloat(lanexisPricePercent) +
      parseFloat(lanexisFixed);
    let directLanexis: any = (totalForTrasitionFees * stripePercent) / 100;
    let directPayment = Number(directLanexis) + Number(stripeFixed);
    let newdirectPayment = directPayment
      .toString()
      .match(/^-?\d+(?:\.\d{0,2})?/)[0];
    return newdirectPayment;
  }

  // Total amount for payment
  totalAmount(currentBidAmount: any) {
    let laneaxisPercent =
      this.laneaxisPrice.payOrder.usd.shipper.laneAxis.percent;
    let stripePercent = this.laneaxisPrice.payOrder.usd.shipper.stripe.percent;
    let stripeFixed = this.laneaxisPrice.payOrder.usd.shipper.stripe.fixed;
    let lanexisFixed = this.laneaxisPrice.payOrder.usd.shipper.laneAxis.fixed;
    let lanexisPricePercent = (
      (currentBidAmount * laneaxisPercent) /
      100
    ).toFixed(2);
    let totalForTrasitionFees =
      parseFloat(currentBidAmount) +
      parseFloat(lanexisPricePercent) +
      parseFloat(lanexisFixed);
    let directLanexis: any = (totalForTrasitionFees * stripePercent) / 100;
    let directPayment = parseFloat(directLanexis) + parseFloat(stripeFixed);
    let totalAmountTranstio =
      Number(totalForTrasitionFees) + Number(directPayment);
    let totalAmount = totalAmountTranstio
      .toString()
      .match(/^-?\d+(?:\.\d{0,2})?/)[0];
    return totalAmount;
  }

  // Saving amount from gross price
  saveAmount(price: any, currentBidAmount: any) {
    const getSaveAmount = parseFloat(price) - parseFloat(currentBidAmount);
    return getSaveAmount;
  }

 // For LaneAxis Visibility Fee
  frightVissionFee() {
    return parseFloat(this.laneaxisPrice.payOrder.usd.shipper.laneAxis.fixed);
  }

  // To get LaneAxis Processing Fee
  getLaneaxisProcessFees() {
    return this.laneaxisPrice.payOrder.usdAch.shipper.laneAxis.percent;
  }

  // To get Shipper Terms Processing Fee
  getProcessFees(sdays: any) {
    let rateNumberOf =
      this.laneaxisPrice.payOrder.usdAch.shipper.laneAxis.terms[sdays];
    return rateNumberOf;
  }

  // To get laneaxis ACH Fee
  laneaxisFeeACH(currentBidAmount: any) {
    let laneaxisFee =
      this.laneaxisPrice.payOrder.usdAch.shipper.laneAxis.percent;
    let lanexisPrice = (currentBidAmount * laneaxisFee) / 100;
    return lanexisPrice;
  }

  // Shipper Terms ACH Fee
  shipperTermsFeeACH(currentBidAmount: any, sdays: any) {
    let rateNumberOf =
      this.laneaxisPrice.payOrder.usdAch.shipper.laneAxis.terms[sdays];
    let lanexisPrice = (currentBidAmount * rateNumberOf) / 100;
    return lanexisPrice;
  }

  // Transaction Fee by ACH
  transactionFeeACH(currentBidAmount: any, sdays: any) {
    let rateNumberOf =
      this.laneaxisPrice.payOrder.usdAch.shipper.laneAxis.terms[sdays];
    let lanexisPrice = (currentBidAmount * rateNumberOf) / 100;
    let laneaxisFee =
      this.laneaxisPrice.payOrder.usdAch.shipper.laneAxis.percent;
    let lanexisPriceFee = (currentBidAmount * laneaxisFee) / 100;
    let addAmountForFees =
      parseFloat(currentBidAmount) +
      lanexisPrice +
      lanexisPriceFee +
      parseFloat(this.laneaxisPrice.payOrder.usdAch.shipper.laneAxis.fixed);
    let transationFee =
      (addAmountForFees *
        this.laneaxisPrice.payOrder.usdAch.shipper.stripe.percent) /
      100;
    let transationFeeChange;
    if (transationFee > 5) {
      transationFeeChange = 5;
    } else {
      transationFeeChange = transationFee;
    }
    return transationFeeChange;
  }

  // Total Transaction Fee 
  transactionFeeACHTotal(currentBidAmount: any, sdays: any) {
    let rateNumberOf =
      this.laneaxisPrice.payOrder.usdAch.shipper.laneAxis.terms[sdays];
    let lanexisPrice = ((currentBidAmount * rateNumberOf) / 100).toFixed(2);
    let transationFee = this.transactionFeeACH(currentBidAmount, sdays);
    let laneaxisFee =
      this.laneaxisPrice.payOrder.usdAch.shipper.laneAxis.percent;
    let lanexisPriceFee = ((currentBidAmount * laneaxisFee) / 100).toFixed(2);
    let totalAmount =
      parseFloat(currentBidAmount) +
      parseFloat(lanexisPrice) +
      parseFloat(transationFee) +
      parseFloat(lanexisPriceFee) +
      parseFloat(this.laneaxisPrice.payOrder.usdAch.shipper.laneAxis.fixed);
    return totalAmount;
  }

  // To select payment Card
  selectCard(event) {
    this.paymentForm.get('paymentId').setValue(event);
    this.currentCardActive = event;
    this.paymentCardId = event;
  }

  // Address Information for Billing 
  selectAddressACH(event) {
    let address =
      event.addLine1 +
      ', ' +
      event.city +
      ', ' +
      event.state +
      ' ,' +
      event.country +
      ', ' +
      event.zipCode;
    this.paymentFormACH.get('address').setValue(address);
    this.currentAddressActive = event.type;
    this.paymentAddress = address;
  }

  // To Select payment method ACH
  selectCardAch(event) {
    this.paymentFormACH.get('paymentId').setValue(event);
    this.currentCardActive = event;
    this.paymentCardId = event;
  }

  // Saved Amount in USDC
  saveAmountUsdc(price: any, currentBidAmount: any) {
    const getSaveAmount = parseFloat(price) - parseFloat(currentBidAmount);
    return getSaveAmount;
  }

  // Fixed price in USDC
  priceFixedUsdc(price: any, currentBidAmount: any) {
    let laneaxisPercent =
      this.laneaxisPrice.payOrder.usdc.shipper.laneAxis.percent;
    let stripePercent = this.laneaxisPrice.payOrder.usdc.shipper.bc.percent;
    let stripeFixed = this.laneaxisPrice.payOrder.usdc.shipper.bc.fixed;
    let lanexisPrice = (price * laneaxisPercent) / 100;
    let directLanexis = (price * stripePercent) / 100;
    let directPayment = (directLanexis + parseFloat(stripeFixed)).toFixed(2);
    let totalAmount = (lanexisPrice + parseFloat(directPayment)).toFixed(2);
    return totalAmount;
  }

  // Total amount for payment in USDC
  totalAmountUsdc(price: any, currentBidAmount: any) {
    let laneaxisPercent =
      this.laneaxisPrice.payOrder.usdc.shipper.laneAxis.percent;
    let stripePercent = this.laneaxisPrice.payOrder.usdc.shipper.bc.percent;
    let stripeFixed = this.laneaxisPrice.payOrder.usdc.shipper.bc.fixed;
    let laneaxsiFixed = this.laneaxisPrice.payOrder.usdc.shipper.laneAxis.fixed;

    const lanexisPrice = ((price * laneaxisPercent) / 100).toFixed(2);
    const directLanexis = ((price * stripePercent) / 100).toFixed(2);
    const directPayment = (
      parseFloat(directLanexis) + parseFloat(stripeFixed)
    ).toFixed(2);
    const totalAmount = (
      parseFloat(price) +
      parseFloat(laneaxsiFixed) +
      parseFloat(lanexisPrice) +
      parseFloat(directPayment)
    ).toFixed(2);
    if (this.balance) {
      const avialable = parseFloat(this.balance.susdc.available).toFixed(2);
      if (totalAmount > avialable) {
        this.checkUsdcPayment = true;
      } else {
        this.checkUsdcPayment = false;
      }
    }
    return totalAmount;
  }

  // Popup for counterBid/Rebid for the shipment
  redBid(
    amount: any,
    note: any,
    bidFor: any,
    userName: any,
    companyName: any,
    paymentMethod: any
  ) {
    if (this.userType == 'SHIPPER' && (this.subscriptionData?.isPremium != true || this.subscriptionData?.subscriptionStatus == 'canceled'))
    {
      this.getSubscriptionAlertPopup(false);
    } else {
    const dialogRef = this.dialog.open(LoadPopupComponent, {
      backdropClass: AppSettings.backdropClass,
      disableClose: true,
      width: AppSettings.popWidth,
      data: {
        openPop: 'rebid',
        userNameOther: companyName,
        userName: userName,
        amount: amount,
        note: note,
        shipmentId: this.route.snapshot.params['loadId'],
        bidFor: bidFor,
        paymentMethod: paymentMethod,
        occurence: 'shipment_view_page',
        shipmentDetail: this.shipmentDetail,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        if (result.event === 'Ok') {
          if (localStorage.getItem('user_type') === 'SHIPPER') {
            if (this.shipmentChecked) {
              this.getData('preferred');
            } else {
              this.getData('connected');
            }
          }
        }
      }
    });
   }
  }

  // Popup for Withdraw bid 
  cancelBid(
    bidId: any,
    amount: any,
    note: any,
    userName: any,
    paymentMethod: any
  ) {
    if (this.userType == 'SHIPPER' && (this.subscriptionData?.isPremium != true || this.subscriptionData?.subscriptionStatus == 'canceled'))
    {
      this.getSubscriptionAlertPopup(false);
    } else {
    const dialogRef = this.dialog.open(LoadPopupComponent, {
      backdropClass: AppSettings.backdropClass,
      disableClose: true,
      width: '455px',
      data: {
        openPop: 'cancelreBid',
        userName: userName,
        bidId: bidId,
        amount: amount,
        note: note,
        shipmentId: this.route.snapshot.params['loadId'],
        uniqueId: this.shipmentDetail.uniqueId,
        paymentMethod: paymentMethod,
        occurence: 'shipment_view_page',
        shipmentDetail: this.shipmentDetail,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        if (result.event === 'Ok') {
          if (localStorage.getItem('user_type') === 'SHIPPER') {
            if (this.shipmentChecked) {
              this.getData('preferred');
            } else {
              this.getData('connected');
            }
          }
        }
      }
    });
   }
  }

  // Popup for acceptBid by Shipper for the shipment
  acceptBidShipper(carrierRecord: any) {
    if (this.userType == 'SHIPPER' && (this.subscriptionData?.isPremium != true || this.subscriptionData?.subscriptionStatus == 'canceled'))
    {
      this.getSubscriptionAlertPopup(false);
    } else {
    const dialogRef = this.dialog.open(LoadPopupComponent, {
      backdropClass: AppSettings.backdropClass,
      disableClose: true,
      width: AppSettings.popWidth,
      data: {
        openPop: 'acceptBidShipper',
        userNameOther: carrierRecord.currentBid.userName,
        userName: carrierRecord.legalName,
        amount: carrierRecord.currentBid?.bidAmount,
        note: carrierRecord.currentBid.note,
        acceptedUser: carrierRecord.currentBid.isAccepted,
        paymentMethod: this.shipmentDetail.paymentMethod,
        bidId: carrierRecord.currentBid?.id,
        shipmentId: this.route.snapshot.params['loadId'],
        uniqueId: this.shipmentDetail.uniqueId,
        occurence: 'shipment_view_page',
        shipmentDetail: this.shipmentDetail,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        if (result.event === 'shipperAccept') {
          if (localStorage.getItem('user_type') === 'SHIPPER') {
            if (this.shipmentChecked) {
              this.getData('preferred');
            } else {
              this.getData('connected');
            }
          }
        }
      }
    });
   }
  }

  // Popup for counterBid for the shipment
  counterBid(
    amount: any,
    note: any,
    bidFor: any,
    userName: any,
    companyName: any,
    paymentMethod: any,
    freightRate: any
  ) {
    if (this.userType == 'SHIPPER' && (this.subscriptionData?.isPremium != true || this.subscriptionData?.subscriptionStatus == 'canceled'))
    {
      this.getSubscriptionAlertPopup(false);
    } else {
    const dialogRef = this.dialog.open(LoadPopupComponent, {
      backdropClass: AppSettings.backdropClass,
      disableClose: true,
      width: AppSettings.popWidth,
      data: {
        openPop: 'counterBid',
        userNameOther: companyName,
        userName: userName,
        amount: amount,
        note: note,
        shipmentId: this.route.snapshot.params['loadId'],
        bidFor: bidFor,
        paymentMethod: paymentMethod,
        freightRate: freightRate,
        occurence: 'shipment_view_page',
        shipmentDetail: this.shipmentDetail,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        if (result.event === 'Ok') {
          if (localStorage.getItem('user_type') === 'SHIPPER') {
            if (this.shipmentChecked) {
              this.getData('preferred');
            } else {
              this.getData('connected');
            }
          }
        }
      }
    });
   }
  }

  // confirm and assign shipment
  confirmAddAssign(carrierRecord: any) {
    if (this.userType == 'SHIPPER' && (this.subscriptionData?.isPremium != true || this.subscriptionData?.subscriptionStatus == 'canceled'))
    {
      this.getSubscriptionAlertPopup(false);
    } else {
    this.carrierRecord = carrierRecord;
    this.confirmAssign(carrierRecord);
    }
  }

  // First time open popup user basic information.
  checkUserInfo() {
    const dialogRef = this.dialog.open(UserOnboardingComponent, {
      disableClose: true,
      backdropClass: AppSettings.backdropClass,
      panelClass: 'userOnboarding_con',
      width: '690px',
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        if (result.event === 'Ok') {
          let userDetail = this.sharedService.getAddress();
          if (userDetail !== undefined) {
            this.userInfo(userDetail);
          }
          this.confirmAssign(this.carrierRecord);
        }
      }
    });
  }

  // Popup for Confirm & Assign for the shipment
  confirmAssign(carrierRecord: any) {
    this.getPriceLaneaxis();
    let newStatusLabel;
    if (
      carrierRecord &&
      carrierRecord.currentBid &&
      carrierRecord.currentBid.statusLabel === 'Offer Rate Confirmed'
    ) {
      newStatusLabel = 'offer rate';
    } else if (
      carrierRecord &&
      carrierRecord.currentBid &&
      carrierRecord.currentBid.statusLabel === 'Over bid'
    ) {
      newStatusLabel = 'overbid';
    } else if (
      carrierRecord &&
      carrierRecord.currentBid &&
      carrierRecord.currentBid.statusLabel === 'Counter quote/bid confirmed'
    ) {
      newStatusLabel = 'Counter quote/bid';
    } else {
      newStatusLabel = 'Counter quote/bid';
    }
    const dialogRef = this.dialog.open(LoadPopupComponent, {
      backdropClass: AppSettings.backdropClass,
      disableClose: true,
      width: AppSettings.popWidth,
      data: {
        openPop: 'confirmAndAssign',
        userNameOther: carrierRecord.currentBid.userName,
        userName: carrierRecord.legalName,
        amount: carrierRecord.currentBid?.bidAmount,
        note: carrierRecord.currentBid.note,
        acceptedUser: carrierRecord.currentBid.isAccepted,
        paymentMethod: this.shipmentDetail.paymentMethod,
        bidId: carrierRecord.currentBid?.id,
        shipmentId: this.route.snapshot.params['loadId'],
        uniqueId: this.shipmentDetail.uniqueId,
        newStatusLabel: newStatusLabel,
        occurence: 'shipment_view_page',
        shipmentDetail: this.shipmentDetail,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        if (result.event === 'shipperConfirmAssign') {
          this.acceptBidShow(carrierRecord);
        }
      }
    });
  }

  // To show accept bid
  acceptBidShow(carrierRecord: any) {
    this.getUserProfile(carrierRecord.carrierPkId);
    if (
      this.shipmentDetail.paymentMethod === 1 &&
      this.shipmentDetail.terms === 'LaneAxis Terms'
    ) {
      this.subCurrentTab = 'acceptUsd';
    } else if (
      this.shipmentDetail.paymentMethod === 1 &&
      this.shipmentDetail.terms === 'Shipper Terms'
    ) {
      this.subCurrentTab = 'acceptACH';
      this.usdcCardLoader = true;
    } else if (
      this.shipmentDetail.paymentMethod === 2 &&
      this.shipmentDetail.terms === 'LaneAxis Terms'
    ) {
      this.subCurrentTab = 'acceptUsdc';
      this.getUserDetail();
      this.usdcCardLoader = true;
    }
    this.userAccepted = carrierRecord;
  
    this.acceptUserId = carrierRecord.userId;
    this.acceptBidId = carrierRecord.currentBid?.id;
    this.acceptAmount = carrierRecord.currentBid?.bidAmount;
    this.getCarrierRecord();
  }

  // To get user detail
  getUserDetail() {
    this.paymentService.getUserInfo().subscribe(
      (res) => {
        if (res) {
          this.balance = res;
          this.usdcCardLoader = false;
        } else {
          this.balance = null;
          this.usdcCardLoader = false;
        }
      },
      (err) => {
        this.balance = null;
        this.usdcCardLoader = false;
        this.alertService.showNotificationMessage(
          'danger',
          'bottom',
          'right',
          'txt_g',
          'error',
          'Unexpected Error',
          AppSettings.ERROR
        );
      }
    );
  }

  // To edit address in billing information
  editAddress(event) {
    const dialogRef = this.dialog.open(LoadPopupComponent, {
      disableClose: true,
      backdropClass: AppSettings.backdropClass,
      // width: '50vw',
      width: '470px',
      data: {
        openPop: 'editAddress',
        addressLine1: event.addLine1,
        addressLine2: event.addLine2,
        city: event.city,
        state: event.state,
        zipCode: event.zipCode,
        country: event.country,
        type: event.type,
        occurence: 'shipment_view_page',
        shipmentDetail: this.shipmentDetail,
      },
    });
    dialogRef.afterClosed().subscribe(async (result) => {
      if (result.event === 'Address') {
        this.getCarrierRecord();
      }
    });
  }

  // To Add address in billing information
  addAddress(event: any) {
    const dialogRef = this.dialog.open(LoadPopupComponent, {
      disableClose: true,
      backdropClass: AppSettings.backdropClass,
      width: '470px',
      data: { openPop: 'addAddress', type: event.type },
    });
    dialogRef.afterClosed().subscribe(async (result) => {
      if (result.event === 'Address') {
        this.getCarrierRecord();
      }
    });
  }

  // Address Information for Billing 
  selectAddress(event) {
    let address =
      event.addLine1 +
      ', ' +
      event.city +
      ', ' +
      event.state +
      ' ,' +
      event.country +
      ', ' +
      event.zipCode;
    this.paymentForm.get('street').setValue(event.addLine1);
    this.paymentForm.get('city').setValue(event.city);
    this.paymentForm.get('state').setValue(event.state);
    this.paymentForm.get('zip').setValue(event.zipCode);
    this.paymentForm.get('country').setValue(event.country);
    this.paymentForm.get('address').setValue(address);
    this.currentAddressActive = event.type;
    this.paymentAddress = address;
  }

  // To submit payment by ACH 
  shipmentPayACH({ value, valid }) {
    if (this.userType == 'SHIPPER' && (this.subscriptionData?.isPremium != true || this.subscriptionData?.subscriptionStatus == 'canceled'))
    {
      this.getSubscriptionAlertPopup(false);
    } else {
    if (valid) {
      this.loading = true;
      let postBody = null;
      postBody = {
        paymentMethodId: this.paymentCardId,
        amount: this.userAccepted?.currentBid?.bidAmount,
        shipmentBidId: this.userAccepted?.currentBid?.id,
      };
      let APIparams = {
        apiKey: AppSettings.APIsNameArray.ACHPAYMENT.ACHPAY,
        uri: '',
        postBody: postBody,
      };
      this.commonService.post(APIparams).subscribe(
        (success) => {
          if (success.success === true) {
            this.loading = false;
            this.paymentCardId = '';
            this.paymentStatusACH(
              'paymentSuccessACH',
              'Success',
              'Payment is success'
            );
            this.autoConnection();
          } else if (success.success === false) {
            if (success.response === 'connect_error') {
              this.paymentStatusACH(
                'stripeConenctFail',
                'Fail',
                'Payment was failed'
              );
            } else {
              this.paymentStatusACH(
                'paymentFailACH',
                'Fail',
                'Payment was failed'
              );
            }
            this.loading = false;
          }
        },
        (error) => {
          this.paymentStatusACH('paymentFailACH', 'Fail', 'Payment was failed');
          this.saveCardButton = false;
          this.loading = false;
        }
      );
    }
   }
  }

  // To submit payment by USD 
  async shipmentPay({ value, valid }) {
    if (this.userType == 'SHIPPER' && (this.subscriptionData?.isPremium != true || this.subscriptionData?.subscriptionStatus == 'canceled'))
    {
      this.getSubscriptionAlertPopup(false);
    } else {
    this.stripe = Stripe(environment.stripePublicKey);
    if (this.paymentCardId && this.paymentAddress) {
      this.loading = true;
      this.saveCardButton = true;
      this.paymentIntentSub(this.paymentCardId);
    } else {
      if (valid) {
        this.loading = true;
        this.saveCardButton = true;
        const { paymentMethod, error } = await this.stripe.createPaymentMethod({
          type: 'card',
          card: this.card,
          billing_details: {
            name: value.cardName,
          },
        });
        if (error) {
          if (error.code) {
            this.alertService.showNotificationMessage(
              'danger',
              'bottom',
              'right',
              'txt_g',
              'error',
              'Unexpected Error',
              AppSettings.ERROR
            );
            this.saveCardButton = false;
            this.loading = false;
          } else {
            this.loading = false;
            this.saveCardButton = false;
            this.alertService.showNotificationMessage(
              'danger',
              'bottom',
              'right',
              'txt_g',
              'error',
              'Unexpected Error',
              AppSettings.ERROR
            );
          }
        } else {
          this.paymentCardId = paymentMethod.id;
          this.paymentIntentSub(paymentMethod.id);
        }
      }
    }
   }
  }

  // API call for shipper payment
  paymentIntentSub(paymentMethodId: any) {
    let postBody = null;
    let currentUserId = localStorage.getItem('user_id');
    postBody = {
      paymentMethodId: paymentMethodId,
      amount: this.shipmentDetail.freightRate,
      shipmentBidId: this.acceptBidId,
      recipientName: this.paymentForm.get('recipientName').value,
      street: this.paymentForm.get('street').value,
      city: this.paymentForm.get('city').value,
      state: this.paymentForm.get('state').value,
      zip: this.paymentForm.get('zip').value,
      country: this.paymentForm.get('country').value,
    };
    let APIparams = {
      apiKey: AppSettings.APIsNameArray.NEWPAYMENT.BIDPAYMENT,
      uri: '',
      postBody: postBody,
    };
    this.commonService.post(APIparams).subscribe(
      (success) => {
        if (success.success === true) {
          this.paymentConfirmation(success.response.secret);
        } else if (success.success === false) {
          this.saveCardButton = false;

          if (success.response === 'connect_error') {
            this.paymentStatus(
              'stripeConenctFail',
              'Fail',
              'Payment was failed'
            );
          } else {
            this.paymentStatus('paymentFail', 'Fail', 'Payment was failed');
          }

          this.loading = false;
        }
      },
      (error) => {
        this.paymentStatus('paymentFail', 'Fail', 'Payment was failed');
        this.saveCardButton = false;
        this.loading = false;
      }
    );
  }

  // Google analytics event
  callAnalytics(eventName) {
    let shipmentType = '-';
    if (
      this.shipmentDetail &&
      this.shipmentDetail.isOfferRate === 'Y'
    ) {
      shipmentType = 'OfferRate';
    }
    if (
      this.shipmentDetail &&
      this.shipmentDetail.isOfferRate === 'N'
    ) {
      shipmentType = 'Bidding';
    }
    let bidAmount = 'bidAmount=' + this.shipmentDetail.freightRate;
    let obj = {
      eventName: eventName,
      event_category: 'Bidding',
      Occurence: 'shipment_view_page',
      ShipmentID: this.shipmentId,
      userType: localStorage.getItem('user_type'),
      EmailID: localStorage.getItem('email'),
      Dimensions: bidAmount ? bidAmount : '-',
      ShipmentType: shipmentType ? shipmentType : '-',
      EquipmentType: this.shipmentDetail.equipmentName
        ? this.shipmentDetail.equipmentName
        : '-',
      SourceLocation: this.shipmentDetail.pickupLocationLongName
        ? this.shipmentDetail.pickupLocationLongName
        : '-',
      TargetLocation: this.shipmentDetail.dropLocationLongName
        ? this.shipmentDetail.dropLocationLongName
        : '-',
    };
    this.sharedService.track(obj);
  }

  // Confirmation for payment success/fail
  async paymentConfirmation(clientsecret: any) {
    if (clientsecret) {
      const { paymentIntent, error } = await this.stripe.confirmCardPayment(
        clientsecret,
        {
          payment_method: this.paymentCardId,
        }
      );
      if (error) {
        this.loading = false;
        this.paymentStatus('paymentFail', 'Fail', 'Payment was failed');
        this.callAnalytics('payment_failed');
      } else {
        if (paymentIntent) {
          if (paymentIntent.status === 'succeeded') {
            this.paymentCardId = '';
            this.callAnalytics('payment_success');
            this.paymentStatus(
              'paymentSuccess',
              'Success',
              'Payment is success'
            );
            this.autoConnection();

          }
          this.loading = false;
        }
        this.loading = false;
      }
    }
  }

  // For auto generate connection
  autoConnection(params = {}) {
    let uri = null;
    params = {
      connectionUserId: this.userAccepted.userId,
    };
    //get dynamic URI for APIs
    if (params) uri = this.commonService.getAPIUriFromParams(params);
    let APIparams = {
      apiKey: AppSettings.APIsNameArray.MANAGE_CONNECTION.AUTOCONNECTION,
      uri: uri,
    };
    this.commonService.getList(APIparams).subscribe(
      (ServerRes) => {
        if (ServerRes.success === true) {
        } else {
        }
      },
      (error) => { }
    );
  }

  // Popup for ACH payment status
  paymentStatusACH(type: any, title: any, contain: any): void {
    const dialogRef = this.dialog.open(LoadPopupComponent, {
      disableClose: true,
      backdropClass: AppSettings.backdropClass,
      width: AppSettings.popWidth,
      data: {
        openPop: type,
        title: title,
        contain: contain,
        shipmentId: this.route.snapshot.params['loadId'],
        uniqueId: this.shipmentDetail.uniqueId,
        occurence: 'shipment_view_page',
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result.event === 'Ok') {
      }
    });
  }

  // Popup for USD payment status
  paymentStatus(type: any, title: any, contain: any): void {
    const dialogRef = this.dialog.open(LoadPopupComponent, {
      disableClose: true,
      backdropClass: AppSettings.backdropClass,
      width: AppSettings.popWidth,
      data: {
        openPop: type,
        title: title,
        contain: contain,
        shipmentId: this.route.snapshot.params['loadId'],
        uniqueId: this.shipmentDetail.uniqueId,
        occurence: 'shipment_view_page',
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result.event === 'Ok') {
      }
    });
  }

  private getFullPath(route: ActivatedRoute): string {
    const segments = [];
    while (route) {
      if (route.snapshot.url.length) {
        segments.unshift(...route.snapshot.url.map(segment => segment.path));
      }
      route = route.parent;
    }
    
    return segments.join('/');
  }

  // Get user profile api
  getAPIParamUser(userId: any) {
    let APIparams, params, urlType;
    urlType = AppSettings.APIsNameArray.MANAGE_CONNECTION.GETPROFILE;
    params = { id: userId };
    APIparams = {
      apiKey: urlType,
      uri: this.commonService.getAPIUriFromParams(params),
    };
    return APIparams;
  }

  // Get user profile 
  getUserProfile(userId: any) {
    var APIparams = this.getAPIParamUser(userId);
    this.commonService.getList(APIparams).subscribe((ServerRes) => {
      if (ServerRes.success === true) {
        this.checkeUserConnection = ServerRes.response;
      }
    });
  }

  // Get shipper details from API
  getCarrierRecord() {
    this.skeletonLoaderAdd = false;
    let uri = null;
    let newParams = {};
    //get dynamic URI for APIs
    if (newParams) uri = this.commonService.getAPIUriFromParams(newParams);
    let APIparams = {
      apiKey: AppSettings.APIsNameArray.SHIPPER.GET,
      uri: uri,
    };
    this.commonService.getList(APIparams).subscribe((ServerRes) => {
      if (ServerRes.success === true) {
        this.shipperDetails = ServerRes.response;
        this.skeletonLoaderAdd = true;
      } else {
        this.skeletonLoaderAdd = true;
      }
    });
  }

  // To confirm payment 
  confirmPayment(event) {
    const fullPath = this.getFullPath(this.route);
    let routerUrlName;
    if (event.checked === true) {
      this.shipmentConfirmChecked = true;
    } else {
      this.shipmentConfirmChecked = false;
    }
    if (this.shipmentConfirmChecked) {
      routerUrlName = this.router.createUrlTree(
        [fullPath+'/load/view', this.route.snapshot.params['loadId'], 'loadBids'],
        {
          queryParams: {
            isConfirmed: this.shipmentConfirmChecked,
          },
        }
      );
      this.location.go(routerUrlName);
    } else if (!this.shipmentConfirmChecked) {
      this.shipmentConfirmChecked = false;
      routerUrlName = this.router.createUrlTree(
        [fullPath+'/load/view', this.route.snapshot.params['loadId'], 'loadBids'],
        {
          queryParams: {
            isConfirmed: this.shipmentConfirmChecked,
          },
        }
      );
      this.location.go(routerUrlName);
    } else {
      this.shipmentConfirmChecked = false;
      routerUrlName = this.router.createUrlTree([
        fullPath+'/load/view',
        this.route.snapshot.params['loadId'],
        'loadBids',
      ]);
      this.location.go(routerUrlName);
    }
    if (this.shipmentChecked) {
      this.getData('preferred');
    } else {
      this.getData('connected');
    }

  }

  //shipment bids history show on popup
  showPreviousHistory(
    userId: any,
    userName: any,
    paymentMethod: any,
    shipmentDetail: any
  ) {
    const dialogRef = this.dialog.open(LoadPopupComponent, {
      disableClose: true,
      backdropClass: AppSettings.backdropClass,
      width: '860px',
      data: {
        openPop: 'bidHistoryShow',
        userId: userId,
        userName: userName,
        shipmentId: this.route.snapshot.params['loadId'],
        paymentMethod: paymentMethod,
        shipmentDetail: shipmentDetail,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result.event === 'Ok') {
      }
    });
  }

//Get date in UTC
  UTCDate(date: any) {
    date = new Date(date + ' ' + 'UTC');
    return date;
  }

 // Event to open profile side panel
  eventActionProfileSidePanel(event: any, type: any) {
    if (type === 'carrier_side_panel') {
      this.sidePanelProfileDataId = event?.id;
      this.sidePanelProfileData=event;
      this.eventPassAction.emit({ event, type });
    }
  }

  // To Close side panel
  closePanel(event: any) {
    this.sidePanelProfileDataId = '';
  }


// open e-Carrier check popup
  ecarriercheck(record) {
    const dialogRef = this.dialog.open(NetworkPopupComponent, {
      disableClose: true,
      backdropClass: AppSettings.backdropClass,
      width:'1000px',
      height:'calc(100vh - 100px)',
      data: { openPop: 'ecarriercheck',dotNumber : record?.dotNumber },
    });
    dialogRef.afterClosed().subscribe((result) => {
    });
  }
    
  unlockSafetyScore(carrierRecord,dotNumber,index){
    this.loading=true;
    if (this.userType == 'SHIPPER' && (((this.subscriptionData?.subscriptionCreditHistory?.defaultCredit + this.subscriptionData?.subscriptionCreditHistory?.additionalCredit)==0) && this.subscriptionData?.isPremium != true && this.subscriptionData?.subscriptionStatus == 'canceled') 
    || (this.subscriptionData?.isPremium == true && this.subscriptionData?.subscriptionStatus == 'active' && ((this.subscriptionData?.subscriptionCreditHistory?.defaultCredit + this.subscriptionData?.subscriptionCreditHistory?.additionalCredit)==0)))
    {
      this.getSubscriptionAlertPopup('isUnlockSafetyScore');
    } else {
    let uri = null;
    let newParams = {
      dotNumber: dotNumber
    };
    if (newParams) uri = this.commonService.getAPIUriFromParams(newParams);
    let APIparams = {
      apiKey: AppSettings.APIsNameArray.CARRIER.ECARRIERCHECK,
      uri: uri,
    };
    this.commonService.getList(APIparams).subscribe(
       (ServerRes) => {
        if(ServerRes?.success==true){
          this.eCarrierCheckData=ServerRes?.response;
          if(this.eCarrierCheckData?.profile?.equipmentType){
            this.carrierList[index].equipmentType=ServerRes?.response?.profile?.equipmentType 
          } 
          this.carrierList[index].shipperCarrierScoreMapping=true
          this.carrierList[index].carrierSafetyScore={
            id : ServerRes.response?.profile?.id,
            authority : ServerRes.response.authority,
            riskLevel : ServerRes.response.riskLevel,
            riskLabel:ServerRes.response.riskLabel,
            recentCrashes :ServerRes.response.recentCrashesCount,
            dotNumber : ServerRes.response.profile?.dotNumber,
            recentInspections :ServerRes.response.recentInspectionsCount
          }

          this.loading=false
        }
        else if(ServerRes.isCreditAvailable==false && ServerRes?.success==false){
          const dialogRef = this.dialog.open(NetworkPopupComponent, {
            disableClose: true,
            backdropClass: AppSettings.backdropClass,
            width:'500px',
            data: { openPop: 'eCarreirCreadits', },

          });
          dialogRef.afterClosed().subscribe((result) => {
            this.loading=false;
          });
          this.loading=false;

        }
        else if(ServerRes?.success==false){
          this.alertService.showNotificationMessage(
            'danger',
            'bottom',
            'right',
            'txt_d',
            'check_circle',
            'Outside Carrier',
            ServerRes?.message
          );
          this.loading=false
        }
      },
      (error) => {      
        this.alertService.showNotificationMessage(
          'danger',
          'bottom',
          'right',
          'txt_d',
          'check_circle',
          'Outside Carrier',
          error?.error?.message?.message
        );
  
      this.loading = false;
    });
    }
  }
  

  getSubscriptionAlertPopup(type){
    const dialogRef = this.dialog.open(SubscriptionPopupComponent, {
      disableClose: true,
      backdropClass: AppSettings.backdropClass,
      width: type == 'isUnlockSafetyScore' ? '500px' : AppSettings.popWidth,
      data: { 
        openPop: 'subscriptionAlert',
        subscriptionData: this.subscriptionData,
        type: type
       },
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.loading=false;

    });
    this.loading=false;
  }


}
