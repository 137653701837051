import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { AlertService } from 'src/app/commons/service/alert.service';
import { AppSettings } from 'src/app/commons/setting/app_setting';
import { CommonService } from 'src/app/commons/service/common.service';
import { MatDialog } from '@angular/material/dialog';
import { PopupComponent } from '../../popup/popup.component';

@Component({
  selector: 'app-notification-control',
  templateUrl: './notification-control.component.html',
  styleUrls: ['./notification-control.component.css']
})

export class NotificationControlComponent implements OnInit {
  selectedIndex: any
  public loading = false;
  public newInvitionNetwork: FormGroup
  public invitationAcceptDecline: FormGroup
  public newReminder: FormGroup
  public newConnection: FormGroup
  public insurenceUpdate: FormGroup
  public shipmentPayment: FormGroup
  public withdrawnCurrentBid: FormGroup
  public submitedRebid: FormGroup
  public updateOnShipment: FormGroup
  public submittedFirstBid: FormGroup
  public shipmentAwarded: FormGroup
  public shipmentConfirmed: FormGroup
  public shipmentReminder: FormGroup
  public shimpmentDelivered: FormGroup
  public pickUpLocation: FormGroup
  public shipmentDisputeSubmitted: FormGroup
  public dropOffLoction: FormGroup
  public addedSuccessfully: FormGroup
  public paymentMethodAdded: FormGroup
  public subsciptionCreated: FormGroup
  public invitationAccepted: FormGroup
  public userType: any;
  public notifictionType = {
    network: {
      newInvitation: {
        email: 'Once a day',
        sms: true,
        push: false,
        bell: true,
      },
      newReminder: {
        email: 'Once a day',
        sms: true,
        push: true,
        bell: true,
      }, 
      invitationAcceptDecline: {
        email: 'Once month',
        sms: true,
        push: false,
        bell: true,
      },
      newConnection: {
        email: 'Once a day',
        sms: true,
        push: false,
        bell: true,
      },
      insurenceUpdate: {
        email: 'Once a day',
        sms: true,
        push: false,
        bell: true,
      },
    },
  }

  constructor(private router: Router,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private alertService: AlertService,
    private commonService: CommonService,
    public dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    this.userType = localStorage.getItem('user_type');
    const data = this.route.snapshot.params['type'].split('?')
    this.selectedTab(data[0]);
    this.getNotificationPanel();

    this.newInvitionNetwork = this.fb.group({
      email: ['', [Validators.required,],],
    });

    this.invitationAcceptDecline = this.fb.group({
      email: ['', [Validators.required,],],
      sms: [this.notifictionType.network.invitationAcceptDecline.sms, [],],
      PushNotification: [this.notifictionType.network.invitationAcceptDecline.push, , [],],
      BellNotification: [this.notifictionType.network.invitationAcceptDecline.bell, [],],
    });

    this.newReminder = this.fb.group({
      email: [this.notifictionType.network.newReminder.email, [Validators.required,],],
      sms: [this.notifictionType.network.newReminder.sms, [],],
      PushNotification: [this.notifictionType.network.newReminder.push, , [],],
      BellNotification: [this.notifictionType.network.newReminder.bell, [],],
    });

    this.newConnection = this.fb.group({
      email: [this.notifictionType.network.newConnection.email, [Validators.required,],],
      sms: [this.notifictionType.network.newConnection.sms, [],],
      PushNotification: [this.notifictionType.network.newConnection.push, , [],],
      BellNotification: [this.notifictionType.network.newConnection.bell, [],],
    });

    this.insurenceUpdate = this.fb.group({
      email: [this.notifictionType.network.insurenceUpdate.email, [Validators.required,],],
      sms: [this.notifictionType.network.insurenceUpdate.sms, [],],
      PushNotification: [this.notifictionType.network.insurenceUpdate.push, , [],],
      BellNotification: [this.notifictionType.network.insurenceUpdate.bell, [],],
    });
  }

  getNotificationPanel() {
    let APIparams = {
      apiKey: AppSettings.APIsNameArray.NOTIFICATION.PANEL,
    };
    this.commonService.getList(APIparams).subscribe((ServerRes) => {
      if (ServerRes.success === true) {
        this.newInvitionNetwork = this.fb.group({
         email: [ServerRes.response.inviteEmailNotification, [Validators.required]],
        });
      }
    });
  }

  newInvitionNetworkForm({ value, valid }) {
    let postBody = {
      inviteEmailNotification: this.newInvitionNetwork.get('email').value
    };
    if (valid) {
      this.loading = true;
      let APIparams = {
        apiKey: AppSettings.APIsNameArray.NOTIFICATION.PANELUPDATE,
        uri: '',
        postBody: postBody,
      };
      this.commonService.post(APIparams).subscribe(
        (success) => {
          if (success.success === true) {
            this.confirmSuccess();
            this.loading = false;
          } else if (success.success === false) {
            this.loading = false;
          }
        },
        (error) => {
          this.loading = false;
        }
      );
    }
  }

  confirmSuccess() {
    const dialogRef = this.dialog.open(PopupComponent, {
      backdropClass: AppSettings.backdropClass,
      width: AppSettings.popWidth,
      data: {
        openPop: 'notificationSuccessUpdate'
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        if (result.event === 'Ok') {
        }
      }
    });
  }

  selectedTab(event: any) {
    if (event === 'bookings') {
      this.selectedIndex = 0
      if (this.userType == 'SHIPPER') {
       this.router.navigate(['shipper/notification-control-panel/' + event]);
      }
      if (this.userType == 'CARRIER') {
        this.router.navigate(['carrier/notification-control-panel/' + event]);
      }
    } else if (event === 'shipments') {
      this.selectedIndex = 1
      if (this.userType == 'SHIPPER') {
        this.router.navigate(['shipper/notification-control-panel/' + event]);
       }
       if (this.userType == 'CARRIER') {
         this.router.navigate(['carrier/notification-control-panel/' + event]);
       }
    } else if (event === 'network') {
      this.selectedIndex = 2
      if (this.userType == 'SHIPPER') {
        this.router.navigate(['shipper/notification-control-panel/' + event]);
       }
       if (this.userType == 'CARRIER') {
         this.router.navigate(['carrier/notification-control-panel/' + event]);
       }
    } else if (event === 'drivers') {
      this.selectedIndex = 3
      if (this.userType == 'SHIPPER') {
        this.router.navigate(['shipper/notification-control-panel/' + event]);
      }
      if (this.userType == 'CARRIER') {
        this.router.navigate(['carrier/notification-control-panel/' + event]);
      }
    }
    else if (event === 'others') {
      this.selectedIndex = 4
      if (this.userType == 'SHIPPER') {
        this.router.navigate(['shipper/notification-control-panel/' + event]);
      }
      if (this.userType == 'CARRIER') {
        this.router.navigate(['carrier/notification-control-panel/' + event]);
      }
    }
  }

  onTabChanged(event: any) {
    if (event.target.innerText === "Bookings" || event.target.innerText === "Shipments" || event.target.innerText === "Others" || event.target.innerText === "Network" || event.target.innerText === "Drivers") {
      this.router.navigate(['shipper/notification-control-panel/' + event.target.innerText.toLowerCase()]);
    }
  }

}
