<div>
    <div class="detail_card_con p_24">
      <div *ngIf="lowestShipmentBid && shipmentDetail?.isOfferRate==='N'">
        <p class="line_before mb-3 ">Lowest Quotes/Bids</p>
        <ul class="lowest_bids">
          <li *ngIf="shipmentDetail?.paymentMethod === 1">
            <span class="loest_bid_lbl">Lowest Quote/Bid: </span>
            <span class=" txt_b" *ngIf="lowestShipmentBid?.lowestBid"> ${{lowestShipmentBid?.lowestBid | twoDecimalPipe}}</span>
            <span class=" txt_b" *ngIf="!lowestShipmentBid?.lowestBid">-</span>
          </li>
          <li *ngIf="shipmentDetail?.paymentMethod === 1">
            <span class="loest_bid_lbl">Second Lowest Quote/Bid: </span>
            <span class=" txt_b" *ngIf="lowestShipmentBid?.secondLowestBid"> ${{lowestShipmentBid?.secondLowestBid | twoDecimalPipe}}</span>
            <span class=" txt_b" *ngIf="!lowestShipmentBid?.secondLowestBid">-</span>
          </li>
          <li *ngIf="shipmentDetail?.paymentMethod === 1">
            <span class="loest_bid_lbl">Third Lowest Quote/Bid: </span>
            <span class=" txt_b" *ngIf="lowestShipmentBid?.thirdLowestBid"> ${{lowestShipmentBid?.thirdLowestBid | twoDecimalPipe}}</span>
            <span class=" txt_b" *ngIf="!lowestShipmentBid?.thirdLowestBid">-</span>
          </li>
        </ul>
      </div>
      <div class="current_offer_bid">
        <p class="line_before mb-0 " *ngIf="shipmentDetail?.isOfferRate==='Y'">Current Offer Rate -
          {{shipmentDetail?.freightRate ? "$" + shipmentDetail?.freightRate : '-'}}</p>
        <p class="line_before mb-0 " *ngIf="shipmentDetail?.isOfferRate==='N'">Current Quote/Bid</p>
  
        <span class="offer_bid_txt fs_14 fsm_5"
          *ngIf="shipmentDetail?.isOfferRate==='Y' && shipmentDetail?.overBid==='Y'">
           <span *ngIf="overBidCount > 1">
            Higher quotes/overbids </span>
             <span *ngIf="overBidCount <= 1"> Higher quote/overbid </span>
          available : {{overBidCount}} </span>
      </div>
      <div class="row" *ngIf="currentBid?.length === 0 && acceptBidList?.length === 0">
  
        <div class="col-md-9"
          *ngIf="(shipmentDetail?.isOfferRate==='N') && (shipmentDetail?.overBid==='N' || !shipmentDetail?.overBid)">
          <p class="mb-1 fs_16 fsn_4 txt_lb">You have not entered a quote/bid yet for this shipment.</p>
          <p class="mb-0 fs_16 fsn_4 txt_lb">Use the 'Enter Quote/Bid' button to submit your first quote/bid to the
            Shipper.</p>
        </div>
  
        <div class="col-md-9"
          *ngIf="(shipmentDetail?.isOfferRate==='Y') && (shipmentDetail?.overBid==='Y' || shipmentDetail?.overBid==='N')">
          <p class="mb-0 fs_16 fsn_4 txt_lb"
            *ngIf="(shipmentDetail?.isOfferRate==='Y') && (shipmentDetail?.overBid==='Y')">Use the
            ' Higher quotes/overbids' or 'Accept' button to submit your first quote/bid to the Shipper.</p>
          <p class="mb-0 fs_16 fsn_4 txt_lb"
            *ngIf="(shipmentDetail?.isOfferRate==='Y') && (shipmentDetail?.overBid==='N')">Use the 'Accept' button to
            confirm the Offer Rate.</p>
        </div>
  
  
        <div class="col-md-3 bids_icn_action">
          <span
            *ngIf="(shipmentDetail?.paymentMethod === 1 && (shipmentDetail?.isOfferRate === 'N' || !shipmentDetail?.isOfferRate) && !shipmentDetail?.overBid)"
            class="bids_icn"
            (click)="bid(shipmentDetail?.shippers?.userId,shipmentDetail?.shippers?.companyName,shipmentDetail?.freightRate,shipmentDetail?.paymentMethod,shipmentDetail?.shippers?.contractDocument)">
            <img class="img-fluid pointer" src="{{serverPathForUrl}}/portal/images/after-login/cl-icons/ic-hm-bid.svg"
              matTooltip="Enter Quote/Bid">
          </span>
          <span
            *ngIf="(shipmentDetail?.isOfferRate==='Y' && (shipmentDetail?.overBid==='N' || shipmentDetail?.overBid==='Y'))"
            class="bids_icn"
            (click)="acceptOfferRate(shipmentDetail?.shippers?.userId,shipmentDetail?.shippers?.companyName,shipmentDetail?.freightRate,shipmentDetail?.paymentMethod,shipmentDetail?.shippers?.contractDocument)">
            <img class="img-fluid pointer" src="{{serverPathForUrl}}/portal/images/after-login/cl-icons/ic-accept-bid.svg"
              matTooltip="Accept Offer">
          </span>
        </div>
      </div>
      <table class="table mb-0 row_aligment acceptdBid_row" *ngIf="acceptBidList?.length > 0">
        <tbody>
          <tr>
            <td class="fs_16 fsn_4 txt_b ps-0 img_name" style="width:320px;">
              <div class="carrierN" *ngIf="!ifAcceptBid">
              
                <span class="abl_ca_name text_truncate fs_16 fsm_5 txt_b">
                  <span class="">Shipper</span>
                </span>
              </div>
              <div class="carrierN" [ngClass]="acceptBidList[0]?.userName != userName ? 'pointer' : ''" *ngIf="ifAcceptBid" (click)="eventActionProfileSidePanel(acceptBidList,'carrier_side_panel')">
                <span class="curr_bid_img">
                  <img class="img-fluid" *ngIf="acceptBidList[0]?.profileImage" src="{{acceptBidList[0]?.profileImage}}">
                  <span *ngIf="!acceptBidList[0]?.profileImage"
                    class="initial_name initial_r_32 ">{{acceptBidList[0]?.userName |
                    acronymOfName}}</span>
                </span>
                <span class="abl_ca_name text_truncate fs_16 fsm_5 txt_b">
                  <span class="">{{acceptBidList[0]?.userName ? acceptBidList[0]?.userName :
                    '-'}}</span>
                  <span *ngIf="currentId === acceptBidList[0]?.actionBy" class="">(Me)</span> 
                  <img *ngIf="currentBid[0]?.subscription" src="{{serverPathForUrl}}/portal/images/after-login/premium_plan_icon.png" class="img-fluid verified_icon">
                </span>
              </div>
            </td>
            <td class="fs_16 fsn_4 txt_b" style="width: 200px;">
              {{acceptBidList[0]?.actionTs | date: 'MMM dd, yyyy'}} at {{acceptBidList[0]?.actionTs |
              date: 'MM:hh:ss'}}
            </td>
            <td class="fs_16 fsn_4 txt_b" style="width: 200px;">
              <p class="mb-0 usdUdsd contract_offer_Rate">
                <span *ngIf="shipmentDetail?.paymentMethod === 2" class="ms-1 usdc_iconS">
                  <span>{{acceptBidList[0]?.bidAmount | sixDecimalPipe}}</span>
                </span>
                <span>
                  <span *ngIf="shipmentDetail?.paymentMethod === 1">${{acceptBidList[0]?.bidAmount |
                    twoDecimalPipe}}</span>
                  <app-rate-per-mile *ngIf="shipmentDetail?.paymentMethod === 1" class="fs_12 fsm_5 txt_lb ml_2"
                    [amount]="acceptBidList[0]?.bidAmount" [miles]="shipmentDetail?.miles"></app-rate-per-mile>
                </span>
              </p>
              <span class="bid_message txt_b pointer"
                matTooltip="{{acceptBidList[0]?.note}}">{{acceptBidList[0]?.note}}</span>
            </td>
            <td class="fs_16 fsn_4 txt_s" style="width:160px;">
              <div class="flex_end" *ngIf="acceptBidList[0]?.statusLabel">
                <span class="fs_16 fsb_6 txt_s pe-0 ">{{acceptBidList[0]?.statusLabel}}</span>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <table class="table mb-0 row_aligment currentBid_row" *ngIf="currentBid?.length > 0">
        <tbody>
          <tr>
            <td class="fs_16 fsn_4 txt_b ps-0 img_name" style="width: 320px;">
              <div class="carrierN" *ngIf="!ifCurrentBid">
                <span class="abl_ca_name text_truncate fs_16 fsm_5 txt_b">
                  <span class="">Shipper</span>
                </span>
              </div>
              <div class="carrierN" [ngClass]="currentBid[0]?.userName != userName ? 'pointer' : ''" *ngIf="ifCurrentBid" (click)="eventActionProfileSidePanel(currentBid,'carrier_side_panel')">
                <span class="curr_bid_img">
                  <img class="img-fluid" *ngIf="currentBid[0]?.profileImage" src="{{currentBid[0]?.profileImage}}">
                  <span *ngIf="!currentBid[0]?.profileImage"
                    class="initial_name initial_r_32 ">{{currentBid[0]?.userName |
                    acronymOfName}}</span>
                </span>
                <span class="abl_ca_name text_truncate fs_16 fsm_5 txt_b">
                  <span class="">{{currentBid[0]?.userName ?
                    currentBid[0]?.userName : '-'}}</span>
                  <span *ngIf="currentBid[0]?.bidAmount && currentId === currentBid[0]?.actionBy"
                    class="">(Me)</span> <img *ngIf="currentBid[0]?.subscription" src="{{serverPathForUrl}}/portal/images/after-login/premium_plan_icon.png" class="img-fluid verified_icon">
                </span>
              </div>
            </td>
            <td class="fs_16 fsn_4 txt_b" style="width: 200px;">
              {{currentBid[0]?.actionTs | date: 'MMM dd, yyyy'}} at {{currentBid[0]?.actionTs | date: 'HH:mm:ss'}}
            </td>
            <span></span>
            <td class="fs_16 fsn_4 txt_b" style="width: 200px;" *ngIf="shipmentDetail?.isOfferRate==='N'">
              <p class="mb-0 usdUdsd contract_offer_Rate">
                <span *ngIf="shipmentDetail?.paymentMethod === 2" class="ms-1 usdc_iconS"></span>
                <span *ngIf="shipmentDetail?.paymentMethod === 2">{{currentBid[0]?.bidAmount | sixDecimalPipe}}</span>
                <span>
                  <span *ngIf="shipmentDetail?.paymentMethod === 1">${{currentBid[0]?.bidAmount | twoDecimalPipe}}</span>
                  <span *ngIf="shipmentDetail?.paymentMethod === 1" class="fs_12 fsm_5 txt_lb ml_2">( ${{!shipmentDetail?.miles ? '0.0' :  (currentBid[0]?.bidAmount/(shipmentDetail?.miles)) | twoDecimalPipe}}/mi)</span>
                </span>
                <span class="caAccepted_tag " *ngIf="currentBid[0]?.status === 2 || currentBid[0]?.status===4">{{currentBid[0]?.statusLabel}}</span>
              </p>
              <span class="bid_message txt_b pointer" matTooltip="{{currentBid[0]?.note}}">{{currentBid[0]?.note ? currentBid[0]?.note : '-'}}</span>
            </td>
            <td class="fs_16 fsn_4 txt_b" style="width: 200px;" *ngIf="shipmentDetail?.isOfferRate==='Y'">
              <p class="mb-0 usdUdsd" style="display: inherit;">
                <span *ngIf="shipmentDetail?.paymentMethod === 2" class="ms-1 usdc_iconS"></span>
                <span *ngIf="shipmentDetail?.paymentMethod === 2">{{currentBid[0]?.bidAmount |
                  sixDecimalPipe}}</span>
                <span *ngIf="shipmentDetail?.paymentMethod === 1">${{currentBid[0]?.bidAmount |
                  twoDecimalPipe}}</span>
                <app-rate-per-mile *ngIf="shipmentDetail?.paymentMethod === 1" class="fs_12 fsm_5 txt_lb ml_2"
                  [amount]="currentBid[0]?.bidAmount" [miles]="shipmentDetail?.miles"></app-rate-per-mile>
                <span class="caAccepted_tag  ms-0"
                  *ngIf="currentBid[0]?.status === 2 || currentBid[0]?.status===4">{{currentBid[0]?.statusLabel}}</span>
              </p>
              <span class="bid_message txt_b pointer" matTooltip="{{currentBid[0]?.note}}">{{currentBid[0]?.note ?
                currentBid[0]?.note :
                '-'}}</span>
            </td>
            <td class="fs_16 fsn_4 txt_s pe-0" style="width: 120px;">
              <div class="bids_icn_action" *ngIf="currentBid[0]?.status <= 1">
  
                <span *ngIf="currentId === currentBid[0]?.actionBy"
                  (click)="cancelBid(currentBid[0]?.id,currentBid[0]?.bidAmount,currentBid[0]?.note, shipmentDetail.shippers.companyName,shipmentDetail?.paymentMethod)"
                  class="pointer bids_icn" matTooltip="Cancel Quote/Bid">
                  <img src="{{serverPathForUrl}}/portal/images/after-login/cl-icons/ic-withdraw-bid.svg"
                    class="img-fluid">
                </span>
  
                <span
                  *ngIf="currentId !== currentBid[0]?.actionBy && currentBid[0]?.statusLabel !=='carrier confirmed' && shipmentDetail?.isOfferRate==='Y' && shipmentDetail?.overBid==='Y'"
                  (click)="confirmBidOfferRate(currentBid[0]?.id,currentBid[0]?.bidAmount,currentBid[0]?.note,shipmentDetail.shippers.companyName,currentBid[0]?.userName,shipmentDetail?.paymentMethod,currentBid[0]?.status)"
                  class="pointer bids_icn" matTooltip="Accept Offer">
                  <img src="{{serverPathForUrl}}/portal/images/after-login/cl-icons/ic-accept-bid.svg" class="img-fluid">
                </span>
                <span
                  *ngIf="currentId !== currentBid[0]?.actionBy && currentBid[0]?.statusLabel !=='carrier confirmed' && (shipmentDetail?.isOfferRate==='N' || !shipmentDetail?.isOfferRate) && !shipmentDetail?.overBid"
                  (click)="acceptBidCarrier(currentBid[0]?.id,currentBid[0]?.bidAmount,currentBid[0]?.note,shipmentDetail?.shippers?.companyName,currentBid[0]?.userName,shipmentDetail?.paymentMethod,currentBid[0]?.status)"
                  class="pointer bids_icn" matTooltip="Accept & Confirm Bid">
                  <img src="{{serverPathForUrl}}/portal/images/after-login/cl-icons/ic-accept-bid.svg" class="img-fluid">
                </span>
  
                <span *ngIf="currentId !== currentBid[0]?.actionBy && currentBid[0]?.statusLabel ==='carrier confirmed'"
                  class="pointer adisabled bids_icn">
                  <img src="{{serverPathForUrl}}/portal/images/after-login/cl-icons/ic-withdraw-bid.svg"
                    class="img-fluid">
                </span>
  
                <span
                  *ngIf="(shipmentDetail?.isOfferRate==='N' || !shipmentDetail?.isOfferRate) && (!shipmentDetail?.overBid)"
                  (click)="redBid(currentBid[0]?.bidAmount,currentBid[0]?.note,shipmentDetail?.shippers?.userId,currentBid[0]?.userName,shipmentDetail?.shippers?.companyName,shipmentDetail?.paymentMethod)"
                  class="pointer bids_icn" matTooltip="Counter quote/bid">
                  <img src="{{serverPathForUrl}}/portal/images/after-login/cl-icons/ic-rebid-bid.svg" class="img-fluid">
                </span>
  
              </div>
              <div class="flex_end" *ngIf="currentBid[0]?.status > 1">
  
                <button *ngIf="currentBid[0]?.status === 2" type="button"
                  class="btn custum_button button_primary px-3 m-0 py-2 boxS_0"
                  (click)="openDriverAlertPopupConfirm(currentBid[0]?.id,currentBid[0]?.bidAmount,currentBid[0]?.note,shipmentDetail.shippers.companyName,currentBid[0]?.userName,shipmentDetail?.paymentMethod,currentBid[0]?.status)">Confirm</button>
  
                <span *ngIf="currentBid[0]?.status === 4 && shipmentDetail?.shippers && (shipmentDetail?.isOfferRate==='N'  || !shipmentDetail?.isOfferRate ) && !shipmentDetail?.overBid"
                  class="txt_s ">Confirmed</span>
  
                <span *ngIf="currentBid[0]?.status === 4 && (currentBid[0]?.statusLabel==='Counter quote/bid Confirmed' || currentBid[0]?.statusLabel==='Confirmed' || currentBid[0]?.statusLabel==='Offer Rate Confirmed') && shipmentDetail?.shippers && (shipmentDetail?.isOfferRate==='Y') && (shipmentDetail?.overBid==='Y' || shipmentDetail?.overBid==='N') && (overBidCount >= 0 || !overBidCount)"
                  class="txt_s ">Confirmed</span>
  
                <span *ngIf="currentBid[0]?.status === 4 && shipmentDetail?.shippers && currentBid[0]?.statusLabel === 'Overbid' && shipmentDetail?.isOfferRate === 'Y' && shipmentDetail?.overBid === 'Y' && overBidCount >= 0"
                  class="txt_s"> Higher quote/overbid</span>
  
                <span *ngIf="currentBid[0]?.status === 3" class="txt_p " matTooltip="{{currentBid[0]?.statusLabel}}">{{currentBid[0]?.statusLabel}}</span>
  
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!-- (scrolled)="onHiScroll()" -->
    <div class="detail_card_con p_24" *ngIf="previousBidHistory?.length > 0">
      <p class="line_before mb-1 ">Previous Bids</p>
      <div id="all_Drivers" class="list_scroll previous_bids_scroll" (scrolled)="onHiScroll()" infiniteScroll
        [infiniteScrollDistance]="3" [infiniteScrollThrottle]="100" [scrollWindow]="false">
        <table class="table mb-0 acceptd_previous_table row_aligment">
          <tbody>
            <tr *ngFor="let historyRecord of previousBidHistory;let j=index;">
              <td class="fs_16 fsn_4 txt_lb ps-0 img_name" style="width: 320px;">
                <div class="carrierN" [ngClass]="historyRecord.userName != userName ? 'pointer' : ''" (click)="eventActionProfileSidePanel(historyRecord,'carrier_side_panel')">
                  <span class="curr_bid_img">
                    <img class="img-fluid" *ngIf="historyRecord?.profileImage" src="{{historyRecord?.profileImage}}">
                    <span class="initial_name initial_r_32 "
                      *ngIf="!historyRecord?.profileImage">{{historyRecord.userName |
                      acronymOfName}}</span>
                  </span>
                  <span class="abl_ca_name text_truncate fs_16 fsm_5 txt_lb">
                    <span class=""
                      *ngIf="historyRecord.bidAmount && currentId !== historyRecord.actionBy && !ifPreviousBid">
                      Shipper
                    </span>
                    <span class=""
                      *ngIf="historyRecord.bidAmount && currentId !== historyRecord.actionBy && ifPreviousBid">
                      {{historyRecord.userName ? historyRecord.userName : '-'}}
                    </span>
                    <span class="" *ngIf="historyRecord.bidAmount && currentId === historyRecord.actionBy">
                      {{historyRecord.userName ? historyRecord.userName : '-'}}
                    </span>
                    <span *ngIf="historyRecord.bidAmount && currentId === historyRecord.actionBy" class="">
                      (Me)
                    </span>
                    <img *ngIf="historyRecord?.subscription" src="{{serverPathForUrl}}/portal/images/after-login/premium_plan_icon.png" class="img-fluid verified_icon">
                  </span>
                </div>
              </td>
              <td class="fs_16 fsn_4 txt_lb" style="width: 200px;">{{historyRecord.actionTs | date: 'MMM dd, yyyy'}} at {{historyRecord.actionTs | date: 'HH:mm:ss'}}</td>
              <td class="fs_16 fsn_4 txt_lb" style="width: 200px;">
                <p class="mb-0 usdUdsd contract_offer_Rate">
                  <span *ngIf="shipmentDetail?.paymentMethod === 2" class="ms-1 usdc_iconS"></span>
                  <span *ngIf="shipmentDetail?.paymentMethod === 2">
                    <span>{{historyRecord.bidAmount | sixDecimalPipe}}</span>
                  </span>
                  <span>
                    <span *ngIf="shipmentDetail?.paymentMethod === 1">${{historyRecord.bidAmount |
                      twoDecimalPipe}}</span>
                    <app-rate-per-mile *ngIf="shipmentDetail?.paymentMethod === 1" class="fs_12 fsm_5 txt_lb ml_2"
                      [amount]="historyRecord.bidAmount" [miles]="shipmentDetail?.miles"></app-rate-per-mile>
                  </span>
                </p>
                <span class="bid_message pointer" matTooltip="{{historyRecord.note}}">{{historyRecord.note ? historyRecord.note : '-'}}</span>
              </td>
              <td class="fs_16 fsm_5 pe-0" style="width: 120px; text-align: right;">
                <span *ngIf="historyRecord.statusLabel==='Withdrawn'" class="txt_d ">{{historyRecord.statusLabel ?
                  historyRecord.statusLabel : '-'}}</span>
                <span *ngIf="historyRecord.statusLabel !=='Withdrawn'" class="txt_p ">{{historyRecord.statusLabel ?
                  historyRecord.statusLabel : '-'}}</span>
              </td>
            </tr>
          </tbody>
        </table>
        <!-- spinner loader -->
        <div class="text-center" *ngIf="spinnerLoaderBid">
          <div class="spinner-border txt_p" role="status">
            <span class="sr-only">
              <!-- Loading... -->
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="" style="position: relative;" *ngIf="sidePanelProfileDataId">
    <div class="side-show-panel">
      <div class="side_bar_con">
        <app-carrier-side-panel [sidePanelProfileData]="sidePanelProfileData" [sidePanelProfileDataId]="sidePanelProfileDataId" [userType]="userType" 
        (closeSidePanel)="closePanel($event)"></app-carrier-side-panel>
      </div>
    </div>
  </div>
  
  <div class="" style="position: relative;" *ngIf="sidePanelProfileDataId">
    <div class="side-show-panel">
      <div class="side_bar_con">
        <app-shipper-side-panel [sidePanelProfileData]="sidePanelProfileData" [sidePanelProfileDataId]="sidePanelProfileDataId" [userType]="userType" 
        (closeSidePanel)="closePanel($event)"></app-shipper-side-panel>
      </div>
    </div>
  </div>