<nav class="navbar navbar-color-on-scroll navbar-transparent fixed-top navbar-expand-lg web_nab" color-on-scroll="100" id="sectionsNav">
  <div class="container">
    <div class="collapse navbar-collapse cus_nav">
      <div class="cus_navl">
        <div class="nav_left_part" (click)="loginPage()">
          <a class="header_logo">
            <img src="{{logo}}" alt="logo" >
          </a> 
        </div>
        <div class="header_menu">
          <ul class="nav me-auto" id="accordion">
            <li class="nav-item">
            </li>
            <li class="nav-item">
            </li>
      
          </ul>
        </div>
      </div>
      <div class="cus_navr">
        <span  *ngIf="!userLogin" [ngClass]="currentTab === 'login' ? 'active' : 'css-class-2'">
          <button type="button" class="btn custum_button button_primary nav_btn px-3"  (click)="trackShipment()">Track Your Load</button>
        </span>
        <button type="button" *ngIf="currentTab === 'track' && !userLogin" class="btn custum_button button_primary nav_btn" [routerLink]="['/']" click="loginPage()">Sign In</button>
      </div>
    </div>
  </div>
</nav>

<div class="mobile_nab">
  <nav class="mob_menu">
    <div>
      <input id="responsive-menu" type="checkbox">
      
      <div class="menu_header">
        <img  src="{{logo}}" alt="logo" class="img-fluid res_logo">
        <label for="responsive-menu">
          <span id="menu-icon"></span>
        </label>
      </div>
      
      <div id="overlay"></div>
      <ul>
       
        <li>
          <button type="button" class="btn custum_button button_primary nav_btn px-3" (click)="trackShipment()">Track Your Load</button>   <!-- 22 -->
        </li>

      </ul>
    </div>
  </nav>
</div>
