
<div class="sign_in_header">
  <app-login-navbar></app-login-navbar>
</div>
<div class="container mt-5">
  <form [formGroup]="paymentCard" (ngSubmit)="subscriptionPay(paymentCard)" class="crd_pymt_form mb-0">
    <div class="shipper_reg shipper_reg_step4 custom_checkbox mb-4">
      <div class="card_headers">
        <div class="chs_l">
          <p class="fs_18 fsb_7 txt_b mb-1">Choose a Plan</p>
          <p class="fs_14 fsn_4 txt_lb">To complete your registration, please select a subscription plan and enter your credit card details here.</p>
        </div>
        <div class="chs_r">
          
        </div>
      </div>
      <div class="row" *ngIf="currentPay=='mini_card'">
        <div class="col-md-4" *ngFor="let plans of payPlan; let i = index">
          <div class="plan_cards" (click)="miniSelectPlan(plans.id,plans.unit_amount,plans.recurring.interval_count,plans.recurring.interval,i)">
            <div class="card_plains mini_card" [ngClass]="selectPlanName == plans.id  ? 'active_mini_card':''">
              <div class="card_plain_in">
                <span class="material-icons checked_card">verified</span>
                <i class="fa fa-check-circle" aria-hidden="true"></i>
                <div class="card_plain1_in" style="margin-top: -2.3em; margin-bottom: .5em;" *ngIf="plans.metadata.moneyback=='true'">
                  <span class="plan_tag">Money back guarantee</span>
                </div>
                <div class="card_plain1">
                  <div class="card_plain1t">
                    <div class="plain_tl">
                      <p class="plan_time">
                        <span class="plain_titl" *ngIf="plans.recurring.interval_count !='1'">{{plans.recurring.interval_count}} months subscription</span>
                        <span class="plain_titl" *ngIf="plans.recurring.interval_count=='1' && plans.recurring.interval=='day'"> Day-to-day </span>
                        <span class="plain_titl" *ngIf="plans.recurring.interval_count=='1' && plans.recurring.interval=='month'"> Month-to-month </span>

                        <span class="plain_titl_re" *ngIf="plans.nickname">{{plans.nickname}}</span>
                      </p>
                      <p class="plain_prize">${{ priceFixed(plans.unit_amount)}} </p>
                      <p class="prize_valedity fs_14">
                        <span>${{priceFixedMonth(plans.unit_amount, plans.recurring.interval_count)}} /{{plans.recurring.interval}}</span>
                      </p>
                  
                      
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row" *ngIf="currentPay=='big_card'">
        <div class="col-md-4" *ngFor="let plans of payPlan;let i = index">
          <div class="plan_cards">
            <div class="card_plains">
              <div class="card_plain_in">
                <span class="material-icons checked_card">verified </span>
                <i class="fa fa-check-circle" aria-hidden="true"></i>
                <div class="card_plain1_in" style="margin-top: -2.3em; margin-bottom: .5em;" *ngIf="plans.metadata.moneyback=='true'">
                   <span class="plan_tag">Money back guarantee</span>
                </div>
                <div class="card_plain1">
                  <div class="card_plain1t">
                    <div class="plain_tl">
                      <p class="plan_time">
                        <span class="plain_titl" *ngIf="plans.recurring.interval_count !='1'">{{plans.recurring.interval_count}} months subscription</span>
                        <span class="plain_titl" *ngIf="plans.recurring.interval_count=='1' && plans.recurring.interval=='day'"> Day-to-day </span>
                        <span class="plain_titl" *ngIf="plans.recurring.interval_count=='1' && plans.recurring.interval=='month'"> Month-to-month </span>
                        <span class="plain_titl_re" *ngIf="plans.nickname">{{plans.nickname}}</span>
                      </p>
                      <p class="plain_prize" *ngIf="centPrice">${{ priceFixed(plans.unit_amount)}} </p>
                      <p class="plain_prize" *ngIf="centDollar">${{ (plans.unit_amount)}} </p>
                      <p class="prize_valedity fs_14">
                        <span>${{priceFixedMonth(plans.unit_amount, plans.recurring.interval_count)}}/{{plans.recurring.interval}}</span>
                      </p>
                    </div>
                    <!-- promo code desing -->
                    <div>
                      <div class="promo_code_add"><span class="blue_s1 cuppon_code_txt" *ngIf="plans?.indexType == false">Have a promo Code?</span> <span
                          class="txt_p cuppon_code_txt txt_underline ms-1 pointer" (click)="addCouponCode(i)" *ngIf="plans?.indexType == false">Add Code</span></div>
                          
                      <div class="promo_co_box" *ngIf="plans?.indexFeild==true">
                        <span (click)="closeCouponCodeFeild(i)"><img src="{{serverPathForUrl}}/portal/icon/ic_close_grey.svg"
                            class="close_icon_new pointer"></span>
                        <div class="d-flex align-items-center mb-1"><span class="fs_12 txt_lb fsn_4">Apply Promo
                            Code</span><img src="{{serverPathForUrl}}/portal/icon/ic_ifo_grey.svg" matTooltip="Enter the Promo code and then click on the Apply button for available offers."
                            class="pointer m-2 mx_13"></div>
                        <form class="mb-0 inputInPopup" [formGroup]="couponCodeForm" autocomplete="off"
                          (ngSubmit)="onSubmitSouponCode(couponCodeForm,i, plans.id)">
                          <div class="promo_input_box">
                            <div class="promo_app_input">
                              <mat-form-field appearance="none" class="">
                                <input matInput formControlName="promoCode" id="" maxlength="64"
                                  placeholder="Enter Promo Code" class="fs_12">
                                <div class="input_check_loader" *ngIf="spinnerLoader">
                                  <div class="spinner-border txt_p" role="status">
                                    <span class="sr-only"></span>
                                  </div>
                                </div>
                               
                                <small class="error_mess error_mess" *ngIf="message">{{message}}</small>
                    
                              </mat-form-field>
                            </div>
                            <div class="" [ngClass]="{disabledNoOfCasesDiv: spinnerLoader}"><button class="promo_app_btn" type="submit" [disabled]="!couponCodeForm.get('promoCode').value">Apply</button></div>
                    
                          </div>
                        </form>
                      </div>
                      <div class="discount_co_box" *ngIf="plans?.indexMessage == true">
                        <div class="coupon_box">
                            <img src="{{serverPathForUrl}}/portal/icon/ic_descount_c_icon.svg"
                                class="img-fluid coupon_img"><span class="dicount_txt">Congratulations!
                                <span *ngIf="percentOff!=null">{{percentOff + '%'}}</span> <span
                                    *ngIf="amountOff !=null">{{'$' + amountOff}}</span> Discount Applied</span>
                        </div>
                        <p class="pointer d-flex justify-content-between">
                            <span class="add_coupan_txt" (click)="addAnotherPromoCode(i)">Change Promo Code</span>
                            <span class="add_coupan_txt text_red" (click)="removeCouponCode(i)">Remove Code</span>
                         </p>
                    </div>
                    </div>
                    <div class="plain_b">
                      <div class="plain_point" *ngFor="let item of plans.metadata | keyvalue">
                        <p class="benifit_points" >
                          <span class="material-icons checked_point">check</span>
                          <span class="plain_subtitl" ><span class="txt_b">{{item.key}} - </span> <span> {{item.value}}</span></span>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="plain_b card_plain1b">
                    <button (click)="selectPlan(plans.id,plans.unit_amount,plans.recurring.interval_count,plans.recurring.interval,i)" type="button" name="plan" id="first_plan" class="plan_select_btn"><span *ngIf="planId == plans.id">Renew Plan</span><span *ngIf="planId !=plans.id">Select this Plan</span></button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- skelaton loader -->
      <div class="row" *ngIf="payPlan?.length==0">
        <div class="col-md-4" *ngFor="let fake of generateFake(3)">
          <div class="plan_cards">
            <div class="card_plains" >
              <div style="min-height: 24.5rem;">
                <div class="loader_mt_5">
                  <ngx-skeleton-loader count="1" [theme]="{ width: '100%', height: '15px'}"></ngx-skeleton-loader>
                </div>
                <div class="">
                  <ngx-skeleton-loader count="1" [theme]="{ width: '60%', height: '24px'}"></ngx-skeleton-loader>
                </div>
                <div class="">
                  <ngx-skeleton-loader count="1" [theme]="{ width: '80%', height: '15px'}"></ngx-skeleton-loader>
                </div>
                <div class="">
                  <ngx-skeleton-loader count="1" [theme]="{ width: '70%', height: '15px'}"></ngx-skeleton-loader>
                </div>
                <div class="loader_mb_0">
                  <ngx-skeleton-loader count="1" [theme]="{ width: '100%', height: '15px'}"></ngx-skeleton-loader>
                </div>
                <div class="">
                  <ngx-skeleton-loader count="1" [theme]="{ width: '70%', height: '15px'}"></ngx-skeleton-loader>
                </div>
                <div class="">
                  <ngx-skeleton-loader count="1" [theme]="{ width: '80%', height: '15px'}"></ngx-skeleton-loader>
                </div>
                <div class="">
                  <ngx-skeleton-loader count="1" [theme]="{ width: '70%', height: '15px'}"></ngx-skeleton-loader>
                </div>
                <div class="loader_mb_0">
                  <ngx-skeleton-loader count="1" [theme]="{ width: '100%', height: '15px'}"></ngx-skeleton-loader>
                </div>
                <div class="">
                  <ngx-skeleton-loader count="1" [theme]="{ width: '70%', height: '15px'}"></ngx-skeleton-loader>
                </div>
                <div class="">
                  <ngx-skeleton-loader count="1" [theme]="{ width: '100%', height: '30px'}"></ngx-skeleton-loader>
                </div>
              </div>
            </div>
          </div>
        </div>   
      </div>

      <div class="row" *ngIf="currentPay=='mini_card'">
        <div class="col-md-4" *ngFor="let cardRecord of cardListing">
          <div class="payment_cardAdded" [ngClass]="currentCardActive == cardRecord?.id ? 'active_card_child' : ''">
            <span class="icon-ic-cross fs_18 fsm_5 remove_card disabled_txt" *ngIf="cardListing?.length == 1"><i class="fa-solid fa-xmark"></i></span>
            <span *ngIf="cardListing?.length !=1" class="icon-ic-cross fs_18 fsm_5 remove_card" (click)="removeCard(cardRecord?.id)"></span>
            <div class="card_container pointer" (click)="selectCard(cardRecord?.id)">
              <img class="img-fluid notifications" id="{{cardRecord?.card?.brand}}1x" src="{{serverPathForUrl}}/portal/images/after-login/img_trans.gif" style="margin: 8px 0;">
              <p class=" mb-2 fs_16 fsb_7 txt_b">xxxx xxxx xxxx {{cardRecord?.card.last4 ?  cardRecord?.card?.last4 : "-"}}</p>
              <p class=" mb-2 fs_14 fsn_4 txt_lb">Valid thru  {{cardRecord?.card?.exp_month ?  cardRecord?.card?.exp_month : "-"}} / {{cardRecord?.card?.exp_year ?  cardRecord?.card?.exp_year : "-"}}</p>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="add_bill_info pointer" (click)="addPaymentCard()">
            <span class="fs_30 txt_p icon-ic-plus-in-circle mb-1"><i class="fa-solid fa-file-arrow-up"></i></span>
            <p class=" mb-0 fs_12 fsb_7 txt_p">Add Card</p>
          </div>
        </div>
      </div>

      <div class="card_instraction" *ngIf="currentPay=='mini_card'">
        <p class="fs_14 fsn_4 txt_lb mb-0">
          <span class="txt_b plain_titl" *ngIf="trialDay >'0' && isFreeTracking =='true'">You won’t be charged during the trial period.</span> You subscription will renew automatically and we’ll charge you <span class="txt_b plain_titl" *ngIf="selectAmount=='0'"> $2.99    </span> <span class="txt_b plain_titl" *ngIf="selectAmount=='1'"> $9.99    </span> <span class="txt_b plain_titl" *ngIf="selectAmount=='2'"> $99.00 </span>at that time. Your next renewal date will be
          <span class="txt_b plain_titl">{{selectPlanDate | date:  'MMM dd, yyyy'}} </span>
        </p>
      </div>
      <div class="sv_exit_btn">
        <button type="button"class="btn custum_button button_primary btn_gry" (click)="saveExist()">Save and Exit</button>
        <button  class="btn custum_button button_primary mx-4 px-4" type="submit" *ngIf="currentPay=='mini_card'" [disabled]="saveCardButton">Make Payment</button>
      </div>
    </div>
  </form>
</div>
<app-footer></app-footer>
<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '14px',fullScreenBackdrop:true }"></ngx-loading>