import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ConnectAccountSuccessComponent } from './connect-account-success/connect-account-success.component';
import { SignUpInLoadingComponent } from './sign-up-in-loading/sign-up-in-loading.component';
import { SignInComponent } from './sign-in/sign-in.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { SignUpVerifyComponent } from './sign-up-verify/sign-up-verify.component';
import { PendingChangesGuard } from '../commons/guard/pending-changes.guard';
import { PlanPurchaseComponent } from './plan-purchase/plan-purchase.component';
const routes: Routes = [
  {
    path: '',
    component: SignInComponent,
    canDeactivate: [PendingChangesGuard],
  },

  {
    path: 'sign-up-verify-email',
    component: SignUpVerifyComponent,
    canDeactivate: [PendingChangesGuard],
  },
  {
    path: 'sign-up-verify-mobile',
    component: SignUpVerifyComponent,
    canDeactivate: [PendingChangesGuard],
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent,
    canDeactivate: [PendingChangesGuard],
  },
  {
    path: 'carrier/stripe/connect-account-success',
    component: ConnectAccountSuccessComponent,
    canDeactivate: [PendingChangesGuard],
  },
  {
    path: 'shipper/stripe/ach-success',
    component: ConnectAccountSuccessComponent,
    canDeactivate: [PendingChangesGuard],
  },
  {
    path: 'configure-ach-account',
    component: ConnectAccountSuccessComponent,
    canDeactivate: [PendingChangesGuard],
  },
  {
    path: 'sign-up-in-loading',
    component: SignUpInLoadingComponent,
    canDeactivate: [PendingChangesGuard],
  },
  {
    path: 'subscription-plan',
    component: PlanPurchaseComponent,
    canDeactivate: [PendingChangesGuard],
  },  
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class CoreRoutingModule { }
