import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRoute ,ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(private router: Router, private route: ActivatedRoute) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (localStorage.getItem('access_token') !== null && localStorage.getItem('onboarding') === 'signUp') {
      this.router.navigate(['/']);
    } else if (localStorage.getItem('access_token') !== null) {
      return true;
    } else {
      this.router.navigate(['/'], { queryParams: { returnUrl: state.url}});
      return false;
    }
  }
}
