import { Component, EventEmitter, HostListener, OnInit, Output } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { AppSettings } from 'src/app/commons/setting/app_setting';
import { CommonService } from 'src/app/commons/service/common.service';
import { BidQuoteAssistancePopupComponent } from 'src/app/bid-quote-assistant/bid-quote-assistance-popup/bid-quote-assistance-popup.component';
import { HereMapsService } from 'src/app/commons/service/here-maps.service';
import { PopupComponent } from 'src/app/shared/popup/popup.component';
import { SubscriptionPopupComponent } from 'src/app/shared/subscription-popup/subscription-popup.component';
import { SharedService } from 'src/app/commons/service/shared.service';
import { EnterpricePopupComponent } from 'src/app/shipper/enterprice-popup/enterprice-popup.component';
import { LoadPopupComponent } from 'src/app/loads-shared/load-popup/load-popup.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-lane-view',
  templateUrl: './lane-view.component.html',
  styleUrls: ['./lane-view.component.scss']
})
export class LaneViewComponent implements OnInit {
  @Output() eventPassAction = new EventEmitter();
  public skeletonLoader = true;
  public userType: any;
  public laneId: any;
  public totalLanemiles=0;
  public journeyTime = 0;
  public laneDetail: any;
  public laneShipperDetail: any;
  public laneCarrierRecord: any;
  public bidQuoteDetail: any;
  public bidQuoteCheck: any;
  public isArchived: any;
  public currentUrlTab: any;
  public ratingOwnerDetail: {};
  public showFilter = false;
  public searchModel: any = { pickupLocation: '', dropoffLocation: '' };
  public titleFilter: any;
  public params: any;
  public searchBoxError = false;
  public countSpace = 1;
  page = 1;
  public carrierNameListShow: any;
  public pricecheck: boolean = false;
  public ratecheck: boolean = false;
  loading: boolean=false;
  public currentRouteParams: any;
  public currentStatus = 'bidding';
  public isParam = false;
  public dataNotFound = false;
  public cancelRequestUser: any = null;
  public tabActionDisabled = false;
  public sortingValue: any;
  public sortingOrder: any;
  public tabCount: any;
  public carrierlanesList = [];
  totalPage = 1;
  public subscriptionData:any;
  public currentSearchStatus: any;
  public subTabMenu: any;
  public mxPhone: any;
  public selectedIndex = 0;
  public carrierName: any;
  orderByUrl: any;
  orderDirUrl: any;
  ordeDirShow: any;
  ordeByShow: any;
  public getTotalHeight: any;
  public spinnerLoader = false;
  public totalLanes: any;
  public currentEventType:any;
  userDetails: any;
  laneShipperLoadConfirmedDetail: any = [];
  isConfirmRateUpdate: string;
  public serverPathForUrl: any;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    public commonService: CommonService,
    private hereMapsService: HereMapsService,
    private sharedService: SharedService,
  ) { }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll(event) {
    this.getTotalHeight = window.innerHeight + window.scrollY;
    if (
      $(window).scrollTop() + 1 >=
      $(document).height() - $(window).height()
    ) {
      if (
        this.page !== this.totalPage &&
        this.page >= 1 &&
        this.totalPage &&
        this.carrierlanesList.length > 0
      ) {
        this.page = this.page + 1;
        this.spinnerLoader = true;
        this.dataNotFound = false;
        this.getMoreData(null);
      } else if (this.spinnerLoader === false) {
        this.dataNotFound = true;
      }
    }
  }

  async ngOnInit() {
    this.userType = localStorage.getItem('user_type');
    let userDetail = this.sharedService.getAddress();
    this.serverPathForUrl = environment.serverPathForUrl;
    if (userDetail !== undefined) {
      this.userInfo(userDetail);
    }
    this.commonService.userData.subscribe((userData) => {
      this.userInfo(userData);
    });
    this.laneId = this.route.snapshot.params['laneId'];
    this.route.params.subscribe(async (params) => {
      if (params['laneId'] !== null) {
        this.laneId = params['laneId'];
        if (this.route.snapshot.params['tabType']) {
          const data = this.route.snapshot.params['tabType'].split('?');
          this.currentUrlTab = data[0];
          this.currentActive(data[0]);
        } else {
          const data = this.route.snapshot.params['type'].split('?');
          this.currentUrlTab = data[0];
          this.currentActive(data[0]);
        }
        this.laneRecordGet();
        await this.wayPointsListGet();
        if(this.userType == 'SHIPPER'){
          this.laneCarrierBidList();
        }
        if(this.userType == 'CARRIER'){
          this.laneRecordGetShipper();
          this.bidAssistantGet();
        }
      }
    });

    if (this.userType === 'SHIPPER') {
      this.getSubscriptionAlert();
    }

    this.params = {
      limit: 10,
    };
    this.isConfirmRateUpdate =  localStorage.getItem('isConfirmRateUpdate');
  }

  private getFullPath(route: ActivatedRoute): string {
    const segments = [];
    while (route) {
      if (route.snapshot.url.length) {
        segments.unshift(...route.snapshot.url.map(segment => segment.path));
      }
      route = route.parent;
    }
     segments.splice(-2,2)
    return segments.join('/')+'/';
  }


 // Function for generate duplicate loaders 
 public generateFake(count: number): Array<number> {
  const indexes = [];
  for (let i = 0; i < count; i++) {
    indexes.push(i);
  }
  return indexes; 
}

// Function to get user info.
userInfo(userDtail) {
   this.userDetails = userDtail;
}

// To change tab and call api according to the event 
onTabChanged(event: any) {
  const fullPath = this.getFullPath(this.route);
  if(event && event.target.innerText === 'Carriers Quotes') {
      this.currentStatus = 'bidding';
      this.router.navigate([fullPath + this.laneId + '/' + this.currentStatus]);
      this.currentActive('bidding');
      this.laneCarrierBidList();
      this.laneRecordGet();   // For quote/bid count
   } else if (event && event.target.innerText === 'Summary') {
      this.currentStatus = 'summary';
      this.router.navigate([fullPath + this.laneId + '/' + this.currentStatus]);
      this.currentActive('summary');
   } else if (event && event.target.innerText === 'Details') {
      this.currentStatus = 'details';
      this.router.navigate([fullPath + this.laneId + '/' + this.currentStatus]);
      this.currentActive('details');
   } else if (event && event.target.innerText === 'Confirmed Rates') {
      this.currentStatus = 'booked';
      this.currentActive('booked');
      this.router.navigate([fullPath + this.laneId + '/' + this.currentStatus]);
      this.laneCarrierBidList();
      this.laneRecordGet();   // For quote/bid count
   } else if (event && event.target.innerText === 'Lane Rate') {
      this.currentStatus = 'laneRate';
      this.currentActive('laneRate');
      this.router.navigate([fullPath + this.laneId + '/' + this.currentStatus]);
      this.laneRecordGetShipper();
   } else if (event && event.target.innerText === 'Confirmed Loads') {
      this.currentStatus = 'confirmedLoads';
      this.currentActive('confirmedLoads');
      this.router.navigate([fullPath + this.laneId + '/' + this.currentStatus]);
      this.laneConfirmedLoadsShipper();
      this.laneRecordGet();   // For quote/bid count
  }
}

// To active current tab according to the tab type
currentActive(type: any) {
  const fullPath = this.getFullPath(this.route);
 if (this.userType === 'SHIPPER') {
  if (type === 'bidding') {
    this.currentUrlTab = 'bidding';
    this.subTabMenu = 'bidding';
    this.currentStatus = 'bidding';
    this.selectedIndex = 0;
    this.router.navigate([fullPath + this.laneId + '/' + this.currentStatus]);
  } else if (type === 'summary') {
    this.currentUrlTab = 'summary';
    this.subTabMenu = 'summary';
    this.currentStatus = 'summary';
    this.selectedIndex = 1;
    this.router.navigate([fullPath + this.laneId + '/' + this.currentStatus]);
  }  else if (type === 'details') {
    this.currentUrlTab = 'details';
    this.subTabMenu = 'details';
    this.currentStatus = 'details';
    this.selectedIndex = 2;
    this.router.navigate([fullPath + this.laneId + '/' + this.currentStatus]);
  }  else if (type === 'booked') {
    this.currentUrlTab = 'booked';
    this.subTabMenu = 'booked';
    this.currentStatus = 'booked';
    this.selectedIndex = 3;
    this.router.navigate([fullPath + this.laneId + '/' + this.currentStatus]);
  } else if (type === 'confirmedLoads') {
    this.currentUrlTab = 'confirmedLoads';
    this.subTabMenu = 'confirmedLoads';
    this.currentStatus = 'confirmedLoads';
    this.selectedIndex = 4;
    this.router.navigate([fullPath + this.laneId + '/' + this.currentStatus]);
  } 
 }
 if (this.userType === 'CARRIER') {
   if (type === 'summary') {
    this.currentUrlTab = 'summary';
    this.subTabMenu = 'summary';
    this.currentStatus = 'summary';
    this.selectedIndex = 0;
    this.router.navigate([fullPath + this.laneId + '/' + this.currentStatus]);
  }  else if (type === 'details') {
    this.currentUrlTab = 'details';
    this.subTabMenu = 'details';
    this.currentStatus = 'details';
    this.selectedIndex = 1;
    this.router.navigate([fullPath + this.laneId + '/' + this.currentStatus]);
  }  else if (type === 'laneRate') {
    this.currentUrlTab = 'laneRate';
    this.subTabMenu = 'laneRate';
    this.currentStatus = 'laneRate';
    this.selectedIndex = 3;
    this.router.navigate([fullPath + this.laneId + '/' + this.currentStatus]);
  } 
 }
}

  ngAfterViewInit() {
    this.route.queryParams.subscribe((params) => {
      if (Object.keys(params).length > 0) {
        if (params['orderBy']) {
          this.orderByUrl = params['orderBy'];
        }
        this.orderDirUrl = params['orderDir'];
        this.searchModel.title = params['carrierName'];
      }
    });
    if(this.route.snapshot.params['type']){
      const data = this.route.snapshot.params['type'].split('?');
      this.filterByStatus(data[0]);
    }
  }

// Function to get lane details according to the laneId
  laneRecordGet() {
      this.skeletonLoader = true;
      let uri = null;
      let newParams = {
        id: this.route.snapshot.params['laneId'],
      };
      if (newParams) uri = this.commonService.getAPIUriFromParams(newParams);
      let APIparams = {
        apiKey: AppSettings.APIsNameArray.SHIPMENT.LANEGET,
        uri: uri,
      };
      this.commonService.getList(APIparams).subscribe(
        async (ServerRes) => {
          if (ServerRes.success === true) {
           this.skeletonLoader = false;
           this.laneDetail = ServerRes.response;
           this.isArchived = ServerRes.response.isArchive;
          } else {
          }
        },
        (error) => {
          this.skeletonLoader = false;
        }
      );
  }

// API call for getting lane waypoint list
  wayPointsListGet() {
    this.skeletonLoader = true;
    let uri = null;
    let newParams = {
      laneId: this.route.snapshot.params['laneId'],
    };
    if (newParams) uri = this.commonService.getAPIUriFromParams(newParams);
    let APIparams = {
      apiKey: AppSettings.APIsNameArray.SHIPMENT.LANEWAYPOINTS,
      uri: uri,
    };
    this.commonService.getList(APIparams).subscribe(
      async (ServerRes) => {
        if (ServerRes.success === true) {
          this.calculateMiles(ServerRes.response.waypoints)
        } else {
        }
      },
      (error) => {
        this.skeletonLoader = false;
      }
    );
}

// To calculate miles from heremap
  async calculateMiles(data){
    this.totalLanemiles=0;
    this.journeyTime = 0;
    for(let i= 0; i < data.length-1; i++){
      this.skeletonLoader = true;
      let location1 = {latitude :  data[i].latitude, longitude:data[i].longitude}; 
      let location2 = {latitude :  data[i+1].latitude, longitude:data[i+1].longitude}; 
      this.hereMapsService.calculateRouteForTwoLanes(location1,location2).then(
          (responseLanes) => {
          this.totalLanemiles+=responseLanes.response.route[0].summary.distance;
          this.journeyTime+=responseLanes.response.route[0].summary.trafficTime;
          },
          (error) => {         
          }
        );
    }
    this.skeletonLoader = false;
  } 

// Function to get lane-bid-carrier according to the laneId
  laneRecordGetShipper() {
      this.skeletonLoader = true;
      let uri = null;
      let newParams = {
        laneId: this.route.snapshot.params['laneId'],
      };
      if (newParams) uri = this.commonService.getAPIUriFromParams(newParams);
      let APIparams = {
        apiKey: AppSettings.APIsNameArray.SHIPMENT.LANESHIPPERGET,
        uri: uri,
      };
      this.commonService.getList(APIparams).subscribe(
        async (ServerRes) => {
          if (ServerRes.success === true) {
           this.skeletonLoader = false;
           this.laneShipperDetail = ServerRes.response;
          } else {
          }
        },
        (error) => {
          this.skeletonLoader = false;
        }
      );
  }

// For Navigation to lane edit page shipper side 
  openEditForm(id) {
  if (this.userType == 'SHIPPER' && (this.subscriptionData?.isPremium != true || this.subscriptionData?.subscriptionStatus == 'canceled'))
   {
     this.getSubscriptionAlertPopup();
   } else {
    this.router.navigate(['recurring-lanes/edit/shipper/'+id])
   }
  }

// To filter tabs according to currentStatus which is included in route
  filterByStatus(event: any) {
    this.tabActionDisabled = true;
    this.dataNotFound = false;
    this.page = 1;
    if (event !== null) {
      if (localStorage.getItem('user_type') === 'SHIPPER') {
        if (event === 'bidding') {
          this.selectedIndex = 0;
          this.currentRouteParams = 'bidding';
          this.currentStatus = 'bidding';
          this.applyFilterSubmit();
        } else if (event === 'summary') {
          this.selectedIndex = 1;
          this.currentRouteParams = 'summary';
          this.currentStatus = 'summary';
        } else if (event === 'details') {
          this.selectedIndex = 2;
          this.currentRouteParams = 'details';
          this.currentStatus = 'details';
        } else if (event === 'booked') {
          this.selectedIndex = 3;
          this.currentRouteParams = 'booked';
          this.currentStatus = 'booked';
          this.applyFilterSubmit();
        } else if (event === 'confirmedLoads') {
          this.selectedIndex = 4;
          this.currentRouteParams = 'confirmedLoads';
          this.currentStatus = 'confirmedLoads';
          this.applyFilterSubmit();
        } 
      }

      if (localStorage.getItem('user_type') === 'CARRIER') {
        if (event === 'summary') {
          this.selectedIndex = 0;
          this.currentRouteParams = 'summary';
          this.currentStatus = 'summary';
        } else if (event === 'details') {
          this.selectedIndex = 1;
          this.currentRouteParams = 'details';
          this.currentStatus = 'details';
        } else if (event === 'laneRate') {
          this.selectedIndex = 2;
          this.currentRouteParams = 'laneRate';
          this.currentStatus = 'laneRate';
        } 
      }
      this.currentStatus = event;
    }
  }

  // To hide/show filter popup on view page
  showFilterSort(){
  if (this.userType == 'SHIPPER' && (this.subscriptionData?.isPremium != true || this.subscriptionData?.subscriptionStatus == 'canceled'))
  {
    this.getSubscriptionAlertPopup();
  } else {
    this.showFilter = !this.showFilter;
  }
  }

  // To navigate lane list page from lane view
  goToList(){
    if(this.userType == 'SHIPPER'){
      this.router.navigate(['/recurring-lanes/create-lanes/allLanes']);
    } else {
      this.router.navigate(['/recurring-lanes/available-lanes/allLanes']);
    }
  }

// Navigate to create lane page
  createLane(laneId){
  if (this.userType == 'SHIPPER' && (this.subscriptionData?.isPremium != true || this.subscriptionData?.subscriptionStatus == 'canceled'))
  {
    this.getSubscriptionAlertPopup();
  } else {
   if (this.userType === "SHIPPER"){
    if(laneId)
    {
    let id=  this.route.snapshot.params['laneId'];
      this.router.navigate(['/recurring-lanes/create/load/'+id]);
    }
    else 
   {
     this.router.navigate(['/recurring-lanes/create/shipper']);
    }
  }
  if (this.userType === "CARRIER"){
    this.router.navigate(['/recurring-lanes/create/carrier']);
  }
}
}

getSubscriptionAlertPopup(){
  const dialogRef = this.dialog.open(SubscriptionPopupComponent, {
    disableClose: true,
    backdropClass: AppSettings.backdropClass,
    width: AppSettings.popWidth,
    data: { 
      openPop: 'subscriptionAlert',
      subscriptionData: this.subscriptionData
     },
  });
  dialogRef.afterClosed().subscribe((result) => {

  });
}

// For showing miles in HTML 
milesCeil(miles)
{
  return  Math.ceil(miles)
}

  // For showing sorted value, in ASC/DESC order
  sorting(event, sorting: any) {
     if (sorting == 'bid_amount') {
       this.ratecheck = false;
       this.sortingValue = 'ASC';
       this.sortingOrder = 'bid_amount';
       this.isParam = false;
     } else if (sorting == 'rating') {
       this.pricecheck = false;
       this.sortingValue = 'DESC';
       this.sortingOrder = 'rating';
       this.isParam = false;
     } if (event.checked == false){
       this.sortingValue = '';
       this.sortingOrder = '';
       this.isParam = true;
     }
  }

  // For showing sorted list on apply button click 
  sortedList(){
    this.laneCarrierBidList();
    this.showFilter = false;
  }

// Get more data by pagination for lane bidding.
  getMoreData(str) {
    var APIparams = this.getAPIParam(str);
    this.commonService.getList(APIparams).subscribe((ServerRes) => {
      let result = ServerRes.response;
      this.totalLanes = ServerRes.totalBid;
      this.spinnerLoader = false;
      if (ServerRes.response && ServerRes.response.length > 0) {
        for (let v = 0; v < result.length; v++) {
          if (result[v]) this.carrierlanesList.push(result[v]);
        }
      }
    });
  }

  // To get required params for API 
  getAPIParam(str) {
   if (this.currentStatus == 'bidding' || this.currentStatus == 'booked') {
     let APIparams, params;
     params = {
       limit: 10,
       page: this.page,
       laneId: this.route.snapshot.params['laneId'],
       carrierName: this.carrierName,
       orderBy: this.sortingValue,
       orderDir: this.sortingOrder,
       tabType: this.currentStatus,
     };
     APIparams = {
       apiKey: AppSettings.APIsNameArray.SHIPMENT.LANECARRIERLIST,
       uri: this.commonService.getAPIUriFromParams(params),
     };
     return APIparams;
   }
 
  }

  // API call for getting lane-bid carrier-list
  laneCarrierBidList(params = {}) {
    const fullPath = this.getFullPath(this.route);
   if (this.currentStatus == 'bidding' || this.currentStatus == 'booked') {
    this.skeletonLoader = true;
    this.tabCount = '';
    this.carrierlanesList = [];
    this.page = 1;
    let uri = null;
    var APIparams = this.getAPIParam(null);
  
    this.cancelRequestUser = this.commonService.getList(APIparams).subscribe((ServerRes) => {
      if (ServerRes.success === true) {
        this.cancelRequestUser = null;
        this.tabActionDisabled = false;
        this.totalLanes = ServerRes.totalBid;
        this.totalPage = ServerRes.totalPage;
        this.tabCount = ServerRes.response.tabCount;
        this.skeletonLoader = false;
        this.laneCarrierRecord = ServerRes.response;
        let carrierData;
        if (this.laneCarrierRecord && this.laneCarrierRecord.length > 0) {
          for (let v = 0; v < this.laneCarrierRecord.length; v++) {
            carrierData = this.laneCarrierRecord[v].carrier;
          }
          this.carrierlanesList = this.laneCarrierRecord;
        } else {
          this.carrierlanesList = [];
        }
        this.loading = false;
        if (this.userType === 'SHIPPER') {
          this.ratingOwnerDetail = {
            key: 'carrierId',
            id: carrierData?.userId
          }
        }
      } else {
        this.carrierlanesList = [];
        this.totalLanes = 0;
        this.loading = false;
        this.totalPage = 0;
        this.tabCount = 0;
        this.skeletonLoader = true;
        this.tabActionDisabled = false;
      }
    });
   }
  }

   // To get required params for API 
   getAPIParamForLoad(str) {
      let APIparams, params;
      params = {
        limit: 10,
        page: this.page,
        laneId: this.route.snapshot.params['laneId'],
        carrierName: this.carrierName,
        orderBy: this.sortingValue,
        orderDir: this.sortingOrder,
        tabType: this.currentStatus,
      };
      APIparams = {
        apiKey: AppSettings.APIsNameArray.SHIPMENT.LANESHIPPERCONFIRMEDLOADS,
        uri: this.commonService.getAPIUriFromParams(params),
      };
      return APIparams;
   }

  laneConfirmedLoadsShipper() {
    const fullPath = this.getFullPath(this.route);
    this.skeletonLoader = true;
    this.tabCount = '';
    this.carrierlanesList = [];
    this.page = 1;
    let uri = null;
    var APIparam = this.getAPIParamForLoad(null);
    if (!this.isParam) {
     this.router.navigate([fullPath + this.laneId + '/' + this.currentStatus], {
      queryParams: {
        orderBy: this.sortingValue,
        orderDir: this.sortingOrder,
        carrierName: this.carrierName,
      },
     });
    }
    this.cancelRequestUser = this.commonService.getList(APIparam).subscribe(
      async (ServerRes) => {
        if (ServerRes.success === true) {
         this.cancelRequestUser = null;
         this.skeletonLoader = false;
         this.totalLanes = ServerRes.totalRecord;
         this.totalPage = ServerRes.totalPage;
         this.tabCount = ServerRes.response.tabCount;
         this.laneShipperLoadConfirmedDetail = ServerRes.response.allShipment;
        } else {
          this.laneShipperLoadConfirmedDetail = [];
          this.totalLanes = 0;
          this.loading = false;
          this.totalPage = 0;
          this.tabCount = 0;
          this.skeletonLoader = true;
          this.tabActionDisabled = false;
        }
      },
      (error) => {
        this.skeletonLoader = false;
      }
    );
  }

   // Functions to get subscription alert popup
 getSubscriptionAlert() {
  this.commonService.getSubscriptionAlert().subscribe((serverRes) => {
     this.subscriptionData =  serverRes.response;
  });
 }

/*Apply filter*/
  applyFilterSearch() {
  if (this.userType == 'SHIPPER' && (this.subscriptionData?.isPremium != true || this.subscriptionData?.subscriptionStatus == 'canceled'))
  {
    this.getSubscriptionAlertPopup();
  } else {
    if (this.searchModel.title || this.titleFilter) {
      if (!this.searchModel.title && this.titleFilter) {
        this.titleFilter = null;
        this.searchModel.title = null;
        this.carrierName = null;
      }
      this.applyFilterSubmit();
    }
   } 
  }

// To apply filter by selected value 
  applyFilterSubmit() {
      this.skeletonLoader = false;
      this.carrierlanesList = [];
      this.tabCount = '';
      this.page = 1;
      if (this.searchModel.title) {
        this.carrierName = this.searchModel.title;
        this.titleFilter = this.searchModel.title;
      }

      if (this.orderDirUrl) {
        this.ordeDirShow = this.orderDirUrl;
      } else {
        this.ordeDirShow = null;
      }
      
      if (this.orderByUrl) {
        this.ordeByShow = this.orderByUrl;
      } else {
        this.ordeByShow = null;
      }

      if (this.carrierName) {
        this.params.carrierName = this.searchModel.title;
        this.carrierNameListShow = this.carrierName;
      } else {
        this.params.state = null;
        this.carrierNameListShow = null;
      }
   
      if (this.searchModel.title) {
        this.currentSearchStatus = 'search';
      } else {
        this.currentSearchStatus = '';
      }

      if (this.currentStatus == 'confirmedLoads') {
          this.laneConfirmedLoadsShipper();
      } else {
        this.laneCarrierBidList();
      }

  }

   /*Search filter by keyup value*/
  searchQuantity(event: any) {
    let searchStr = event.target.value;
    this.searchModel.title = searchStr;
    let lastword = searchStr.charAt(searchStr.length - 1);
    if (lastword === ' ') {
      this.countSpace = this.countSpace + 1;
    } else {
      this.countSpace = 0;
    }
    if (this.countSpace >= 2 || (lastword === ' ' && searchStr.length === 1)) {
      this.searchModel.title = this.searchModel.title.substring(
        0,
        this.searchModel.title.length - 1
      );
      this.searchBoxError = true;
    } else {
      this.searchBoxError = false;
    }
  }

/*Remove filtered data by removing single filter value.*/
  singleFilterClose(event) {
    if (event === 'title') {
      this.titleFilter = null;
      this.params.title = null;
      this.searchModel.title = null; 
    }

    if (event === 'carrierName') {
      this.params.carrierName = null;
      this.searchModel.title = null;
      this.searchModel.stateId = null;
      this.carrierNameListShow = null;
      this.carrierName = null;
    }

    this.carrierlanesList = [];
    this.tabCount = '';
    this.page = 1;
    this.skeletonLoader = false;
    if (this.carrierName) {
      this.currentSearchStatus = 'search';
    } else {
      this.currentSearchStatus = '';
    }
    this.laneCarrierBidList(this.params);
  }

  // Get events for lane bidding along with event type 
  eventAction(event) {
    if(event) 
    if (event.type === 'laneRebid') {
        this.laneRebidShipper(event.event);
    } else if (event.type === 'laneAcceptBidCarrier') {
        this.acceptBidCarrier(event.event);
    } else if (event.type === 'connectInviteLane') {
        this.acceptConnectInvite(event.event);
    } else if (event.type === 'firstLaneBid') {
      this.newLanewBid(event.event);
    }  else if (event.type === 'firstLaneBidCarrier') {
      this.newLanewBidCarrier(event.event);
    }  else if (event.type === 'laneRebidCarrier') {
      this.laneRebidForCarrier(event.event);
    }  else if (event.type === 'laneAcceptBidShipperCarrier') {
      this.acceptBidShipperCarrier(event.event);
    } else if (event.type === 'connectInviteLaneCarrier') {
      this.acceptConnectInviteCarrier(event.event);
    }  else if (event.type === 'laneAcceptBidForFixRate') {
      this.acceptLaneBidFixRateCarrier(event.event);
    }  else if (event.type === 'connectInviteLaneCarrierForFixRate') {
      this.acceptConnectInviteCarrierFixRate(event.event);
    } 
    else if(event?.type=='bidding'){
      this.skeletonLoader=false;
      this.laneCarrierBidList() 
    }
    else if(event?.type=='booked'){
      this.skeletonLoader=false;
      this.laneCarrierBidList() 
    }
  }

 // First bid by carrier for lane
  newLanewBid(lanes:any,) {
    const dialogRef = this.dialog.open(LoadPopupComponent, {
      backdropClass: AppSettings.backdropClass,
      width: AppSettings.popWidth,
      data: {
        openPop: 'firstLaneBid',
        laneId: lanes.id,
        laneDetail: lanes,
        amount: lanes.estimatedPrice,
        userNameOther: lanes.shippers.companyName,
        userName: lanes.shippers.companyName,
        bidFor: lanes.shippers.userId,
        contractDocument: lanes.shippers.contractDocument,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        if (result.event === 'Ok') {
          this.skeletonLoader = false;
          this.laneCarrierBidList();
        }
      }
    });
  }

// Function to open Bid Quote Assistant popup 
  openUpgradePopup()
  {
    const dialogRef = this.dialog.open(PopupComponent, {
      backdropClass: AppSettings.backdropClass,
      width: AppSettings.popWidth,
      data: {
        openPop: 'openBidQuote',
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        if (result.event === 'Ok') {
          this.openBidQuote()
        }
      }
    });
  }

 async openBidQuote(){
  if(localStorage.getItem('premium')=='0')
  {
    this.openUpgradePopup()
    return 0
  }
  let popupType:any;
    if(this.bidQuoteCheck){
      popupType='updateBidQuote';
    }else{
      popupType='bidQuoteAssistant'; 
    }
    let tripdays = 0
    this.bidQuoteDetail.totalLanemiles=  Math.ceil(this.totalLanemiles/1609.344);
    let trafficTime = this.journeyTime * 1000;
    const hours = Math.round((trafficTime / (1000 * 60 * 60)));
     tripdays = Math.round(hours/11);
    if(hours < 11 && hours >0){
      tripdays = 1;
    }else if(hours > 11){
      tripdays = Math.round(hours / 11);
      if (tripdays > 9) {
        tripdays = 9;
      }   
    }else{
      tripdays=0;
    }
    this.bidQuoteDetail.tripdays = tripdays;
    const dialogRef = this.dialog.open(BidQuoteAssistancePopupComponent, {
      disableClose: true,
      backdropClass: AppSettings.backdropClass,
      width: '700px',
      data: {
        openPop: popupType,
        bidQuoteDetail: this.bidQuoteDetail,
        laneShipperDetail:this.laneShipperDetail,
        laneDetail:this.laneDetail,
        pageType:'lane',
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.currentEventType=result.event;
        if (result.event == 'lane') {
          if(this.laneShipperDetail &&  this.laneShipperDetail.lastBid && this.laneShipperDetail.lastBid?.status != 2){
            this.laneRebidForCarrier(this.laneShipperDetail,result.priceAmout)
          }else{
            this.newLanewBidCarrier(this.laneShipperDetail,result.priceAmout)
          }
        }else if(result.event=='Final'){
               this.bidAssistantGet();
        }
      }
    });
  }

// Function for bid-assistant get API call
  bidAssistantGet() {
    let uri = null;
    let APIparams = {
      apiKey: AppSettings.APIsNameArray.SHIPMENT.BIDASSISTANT,
      uri: uri,
    };
    this.commonService.getList(APIparams).subscribe(
      async (ServerRes) => {
        if (ServerRes.success === true) {
         this.skeletonLoader = false;
         this.bidQuoteCheck = ServerRes.isNew;
         this.bidQuoteDetail = ServerRes.response;
          if(this.currentEventType=='Final'){
            this.openBidQuote();
          }
        } else {
          this.skeletonLoader = false;
        }
      },
      (error) => {
        this.skeletonLoader = false;
      }
    );
  }
  
 // First bid by carrier for lane
  newLanewBidCarrier(lanes:any,inputAmout?:any) {
    const dialogRef = this.dialog.open(LoadPopupComponent, {
      backdropClass: AppSettings.backdropClass,
      width: AppSettings.popWidth,
      data: {
        openPop: 'firstLaneBidCarrier',
        laneId: lanes.id,
        laneDetail: lanes,
        amount: lanes.estimatedPrice,
        userNameOther: lanes.shipper.companyName,
        userName: lanes.shipper.companyName,
        bidFor: lanes.shipper.userId,
        contractDocument: lanes.shipper.contractDocument,
        inputAmout:inputAmout,
        type: "bidAssist",
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        if (result.event === 'Ok') {
          this.skeletonLoader = false;
          this.laneRecordGetShipper();
        }
      }
    });
}

// ReBid popup for lane
laneRebidShipper(lanes) {
  let userName;
  if (lanes.bidAmount && lanes.actionBy === localStorage.getItem('user_id')) {
    userName = localStorage.getItem('userName');
  } else {
    userName = lanes.carrier.legalName;
  }
  const dialogRef = this.dialog.open(LoadPopupComponent, {
    backdropClass: AppSettings.backdropClass,
    disableClose: true,
    width: AppSettings.popWidth,
    data: {
      openPop: 'laneRebid',
      userNameOther: lanes.carrier.legalName,
      userName: userName,
      amount: lanes.bidAmount,
      bidFor: lanes.carrierId,
      laneDetail: lanes,
      laneId: lanes.laneId,
      coiDoc: lanes.carrier.coiDoc,
    },
  });
  dialogRef.afterClosed().subscribe((result) => {
    if (result) {
      if (result.event === 'Ok') {
        this.skeletonLoader = true;
        this.laneCarrierBidList();
      }
    }
  });
}

// ReBid popup for lane
laneRebidForCarrier(lanes:any,inputAmout?:any) {
  const dialogRef = this.dialog.open(LoadPopupComponent, {
    backdropClass: AppSettings.backdropClass,
    disableClose: true,
    width: AppSettings.popWidth,
    data: {
      openPop: 'laneRebidCarrier',
      userNameOther: lanes.shipper ? lanes.shipper.companyName : lanes.carrier.legalName,
      userName: lanes.carrier ? lanes.carrier.legalName : lanes.shipper.companyName,
      amount: lanes.lastBid.bidAmount,
      bidFor: this.userType == 'CARRIER' ? lanes.shipperId : lanes.carrierId,
      laneDetail: lanes,
      laneId: lanes.lastBid.laneId,
      contractDocument: lanes.shipper ? lanes.shipper.contractDocument : '',
      inputAmout:inputAmout,
      type: "bidAssist",
    },
  });
  dialogRef.afterClosed().subscribe((result) => {
    if (result) {
      if (result.event === 'Ok') {
        this.skeletonLoader = true;
        this.laneRecordGetShipper();
      }
    }
  });
}

// Accept bid for lane by carrier popup 
acceptBidCarrier(lanes) { 
  const dialogRef = this.dialog.open(LoadPopupComponent, {
    backdropClass: AppSettings.backdropClass,
    disableClose: true,
    width: AppSettings.popWidth,
    data: {
      openPop: 'laneAcceptBidCarrier',
      laneId: lanes.laneId,
      laneDetail: lanes,
      amount: lanes.bidAmount,
      userNameOther: lanes.carrier.legalName,
      userName: lanes.carrier.legalName,
      bidFor: lanes.shipperId,
      bidingStatus: lanes.status,
      laneBidId: parseInt(lanes.id),
      rateType: 'acceptForCarrierList',        
    },
  });
  dialogRef.afterClosed().subscribe((result) => {
    if (result) {
      if (result.event === 'Ok') {
        this.skeletonLoader = true;
        this.laneCarrierBidList();
      }
    }
  });
}

// Popup for lane accept bid  
acceptBidShipperCarrier(lanes) { 
  const dialogRef = this.dialog.open(LoadPopupComponent, {
    backdropClass: AppSettings.backdropClass,
    disableClose: true,
    width: AppSettings.popWidth,
    data: {
      openPop: 'laneAcceptBidShipperCarrier',
      laneId: lanes.lastBid.laneId,
      laneDetail: lanes,
      amount: lanes.lastBid.bidAmount,
      userNameOther: lanes.shipper ? lanes.shipper.companyName : lanes.carrier.legalName,
      userName: lanes.carrier ? lanes.carrier.legalName : lanes.shipper.companyName,
      bidingStatus: lanes.status,
      laneBidId: parseInt(lanes.lastBid.id),
      rateType: 'acceptForCarrierList',
    },
  });
  dialogRef.afterClosed().subscribe((result) => {
    if (result) {
      if (result.event === 'Ok') {
        this.skeletonLoader = true;
        this.laneRecordGetShipper();
      }
    }
  });
}

// Popup for lane accept bid for fix rate carrier side
acceptLaneBidFixRateCarrier(lanes) {
  const dialogRef = this.dialog.open(LoadPopupComponent, {
    backdropClass: AppSettings.backdropClass,
    disableClose: true,
    width: AppSettings.popWidth,
    data: {
      openPop: 'laneAcceptBidForFixRate',
      laneId: lanes.id,
      laneDetail: lanes,
      amount: lanes.estimatedPrice,
      userNameOther: lanes.shipper.companyName,
      userName: lanes.shipper.companyName,
      rateType: this.laneDetail.rateType,
    },
  });
  dialogRef.afterClosed().subscribe((result) => {
    if (result) {
      if (result.event === 'Ok') {
        this.skeletonLoader = false;
        this.laneRecordGetShipper();
      }
    }
  });
}

// Accept connect Invite lane popup shipper side
acceptConnectInvite(lanes) {  
  const dialogRef = this.dialog.open(LoadPopupComponent, {
    backdropClass: AppSettings.backdropClass,
    disableClose: true,
    width: AppSettings.popWidth,
    data: {
      openPop: 'connectInviteLane',
      laneId: lanes.laneId,
      laneDetail: lanes,
      amount: lanes.bidAmount,
      userNameOther: lanes.carrier.legalName,
      bidingStatus: lanes.status,
      laneBidId: parseInt(lanes.id),
      city: lanes.carrier.city,
      state: lanes.carrier.state,
      equipmentType: lanes.carrier.equipmentType,
      preferredRegions: lanes.carrier.preferredRegions,
      regions: lanes.carrier.regions,
      coiDoc: lanes.carrier.coiDoc,
      certificateOfInsurance: lanes.carrier.certificateOfInsurance,
      coiExpired: lanes.carrier.coiExpired,
      insuranceCertificateExpireDate: lanes.carrier.insuranceCertificateExpireDate,
      rateType: this.laneDetail.rateType,
    },
  });
  dialogRef.afterClosed().subscribe((result) => {
    if (result) {
      if (result.event === 'Ok') {
        this.skeletonLoader = true;
        this.laneCarrierBidList();
      }
    }
  });
}

// Accept connect Invite lane popup carrier side
acceptConnectInviteCarrier(lanes) {
  const dialogRef = this.dialog.open(LoadPopupComponent, {
    backdropClass: AppSettings.backdropClass,
    disableClose: true,
    width: AppSettings.popWidth,
    data: {
      openPop: 'connectInviteLaneCarrier',
      laneId: lanes.id,
      laneDetail: lanes,
      amount: lanes.lastBid ? lanes.lastBid.bidAmount : lanes.estimatedPrice,
      userNameOther: lanes.shipper ? lanes.shipper.companyName : lanes.carrier.legalName,
      userName: lanes.carrier ? lanes.carrier.legalName : lanes.shipper.companyName,
      bidingStatus: lanes.status,
      laneBidId: parseInt(lanes.lastBid.id),
      city: lanes.shipper ? lanes.shipper.city : lanes.carrier.city,
      state: lanes.shipper ? lanes.shipper.state : lanes.carrier.state ,
      equipmentType: lanes.shipper ? lanes.shipper.equipmentType : lanes.carrier.equipmentType,
      preferredRegions: lanes.shipper ? lanes.shipper.preferredRegions :lanes.carrier.preferredRegions,
      regions: lanes.shipper ? lanes.shipper.regions : lanes.carrier.regions,
      contractDocument: lanes.shipper ? lanes.shipper.contractDocument : '',
      rateType: this.laneDetail.rateType,
    },
  });
  dialogRef.afterClosed().subscribe((result) => {
    if (result) {
      if (result.event === 'Ok') {
        this.skeletonLoader = true;
        this.laneRecordGetShipper();
      }
    }
  });
}

// Accept connect Invite lane popup carrier side for fix rate
acceptConnectInviteCarrierFixRate(lanes) {
  const dialogRef = this.dialog.open(LoadPopupComponent, {
    backdropClass: AppSettings.backdropClass,
    disableClose: true,
    width: AppSettings.popWidth,
    data: {
      openPop: 'connectInviteLaneCarrierForFixRate',
      laneId: lanes.id,
      laneDetail: lanes,
      amount: lanes.lastBid ? lanes.lastBid.bidAmount : lanes.estimatedPrice,
      userNameOther: lanes.shipper ? lanes.shipper.companyName : lanes.carrier.legalName,
      userName: lanes.carrier ? lanes.carrier.legalName : lanes.shipper.companyName,
      bidingStatus: lanes.status,
      city: lanes.shipper ? lanes.shipper.city : lanes.carrier.city,
      state: lanes.shipper ? lanes.shipper.state : lanes.carrier.state ,
      equipmentType: lanes.shipper ? lanes.shipper.equipmentType : lanes.carrier.equipmentType,
      preferredRegions: lanes.shipper ? lanes.shipper.preferredRegions :lanes.carrier.preferredRegions,
      regions: lanes.shipper ? lanes.shipper.regions : lanes.carrier.regions,
      contractDocument: lanes.shipper ? lanes.shipper.contractDocument : '',
      rateType: this.laneDetail.rateType,
    },
  });
  dialogRef.afterClosed().subscribe((result) => {
    if (result) {
      if (result.event === 'Ok') {
        this.skeletonLoader = true;
        this.laneRecordGetShipper();
      }
    }
  });
}

// Navigate to create shipment page from create lane by shipment
createLaneByShipment(){
  if (this.userType == 'SHIPPER' && (this.subscriptionData?.isPremium != true || this.subscriptionData?.subscriptionStatus == 'canceled'))
  {
    this.getSubscriptionAlertPopup();
  } else if (this.userType == 'SHIPPER' && this.subscriptionData.subscriptionCreditHistory.shipperShipment == 0) {
    this.enterPricePlan();
  } 
   else {
    let id=  this.route.snapshot.params['laneId'];
    this.router.navigate(['/recurring-lanes/create/load/'+id]);
  }
}

   // Function for open enterPrice plan popup
   enterPricePlan(){
    if (this.userDetails && this.userDetails.isEnterprisePlanRequested) {
      const dialogRef = this.dialog.open(EnterpricePopupComponent, {
        disableClose: true,
        backdropClass: AppSettings.backdropClass,
        width: AppSettings.popWidth,
        data: { openPop: 'enterprisePlanRequestedPopup'},
      });
      dialogRef.afterClosed().subscribe((result) => {
        if(result?.event=='Ok'){
        }
      });
    } 
    else { 
    if(this.userDetails.phone?.slice(0,3)=='+52' || this.userDetails.phone?.slice(0,3)=='+91'){
      this.mxPhone=this.userDetails.phone?.slice(4,14)
    }
    else if(this.userDetails.phone?.slice(0,2)=='+1'){
      this.mxPhone=this.userDetails.phone?.slice(3,13)
    }
    const dialogRef = this.dialog.open(EnterpricePopupComponent, {
      disableClose: true,
      backdropClass: AppSettings.backdropClass,
      width:'500px',
      height:'calc(100vh - 100px)',
      data: { openPop: 'enterPrice', userData:this.userDetails,fullName:this.userDetails.firstName + ' ' + this.userDetails.lastName,companyName:this.userDetails.companyName,email:this.userDetails.email,phone:this.mxPhone,countryCode:this.userDetails.countryCode,ftlVolume:this.userDetails.ftlVolume },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if(result?.event=='Ok'){
        this.getShipperRecord();
      }
    });
   }
  }

   // Get shipper details from API
   getShipperRecord() {
    let uri = null;
    let newParams = {};
    if (newParams) uri = this.commonService.getAPIUriFromParams(newParams);
    let APIparams = {
      apiKey: AppSettings.APIsNameArray.SHIPPER.GET,
      uri: uri,
    };
    this.commonService.getList(APIparams).subscribe((ServerRes) => {
      if (ServerRes.success === true) {
        this.userDetails = ServerRes.response;
      } 
    });
  }

// Navigate to confirm-list
confirmLaneList(){
  if (this.userType == 'SHIPPER' && (this.subscriptionData?.isPremium != true || this.subscriptionData?.subscriptionStatus == 'canceled'))
  {
    this.getSubscriptionAlertPopup();
  } else if (this.userType == 'SHIPPER' && this.subscriptionData.subscriptionCreditHistory.shipperShipment == 0) {
    this.enterPricePlan();
  } 
   else {
    this.router.navigate(['/recurring-lanes/confirm-list']);
  }
}

// To open popup for Archived lane
openArchived(id, isArchived) {
  if (this.userType == 'SHIPPER' && (this.subscriptionData?.isPremium != true || this.subscriptionData?.subscriptionStatus == 'canceled'))
  {
    this.getSubscriptionAlertPopup();
  } else {
  const dialogRef = this.dialog.open(LoadPopupComponent, {
    backdropClass: AppSettings.backdropClass,
    width: AppSettings.popWidth,
    data: {
      openPop: 'archivedLane',
      laneId: id,
      isArchived: isArchived
    },
  });
  dialogRef.afterClosed().subscribe((result) => {
    if (result) {
      if (result.event === 'close') {
        this.skeletonLoader = false;
      }
      if (result && result.success == true) {
        this.skeletonLoader = false;
        this.laneRecordGet();
      }
    }
  });
 }
}

// Navigate to create lane page by using existing lane
openCreateLaneForm(id) {
  this.router.navigate(['/recurring-lanes/create/shipper/'+id])
}


}
