import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { CommonService } from 'src/app/commons/service/common.service';
import { SharedService } from 'src/app/commons/service/shared.service';
import { AppSettings } from 'src/app/commons/setting/app_setting';
import { LoadDetailCommodityComponent } from '../load-detail-commodity/load-detail-commodity.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-lane-detail',
  templateUrl: './lane-detail.component.html',
  styleUrls: ['./lane-detail.component.scss']
})
export class LaneDetailComponent implements OnInit {
  @Input() laneDetail: any;
  @Input() waypoint: any;
  @Input() currentStatus: any;
  public userType: any;
  public laneId: any;
  public wayPoints: any;
  public skeletonLoader = true;
  public pickupWayPoint: any = [];
  public dropoffWayPoint: any = [];
  public type: any;
  public fequeryMonthShow:any;
  public serverPathForUrl: any;

  constructor(
    private route: ActivatedRoute,
    public commonService: CommonService,
    public dialog: MatDialog,
    private sharedService: SharedService,
  ) { }

  ngOnInit(): void {
    let configValue = this.sharedService.getConfig();
    this.serverPathForUrl = environment.serverPathForUrl;
    if (configValue !== undefined) {
      this.getConfigSet(configValue);
    }
    this.commonService.configData.subscribe((userData) => {
      this.getConfigSet(userData);
    });

    this.userType = localStorage.getItem('user_type');
    this.laneId = this.route.snapshot.params['laneId'];
    this.wayPointDetailsGet();
  }

  getConfigSet(configValue: any) {
    this.fequeryMonthShow =configValue.laneFrequencyDays; 
  }

  checkday(day, currentday) {
   for (let i = 0; i < day?.length; i++) 
      {
        if(day[i]== currentday)
        return true;
     }
   }
  
  wayPointDetailsGet() {
      this.skeletonLoader = true;
      let uri = null;
      let newParams = {
      laneId: this.route.snapshot.params['laneId'],
      };
      if (newParams) uri = this.commonService.getAPIUriFromParams(newParams);
      let APIparams = {
        apiKey: AppSettings.APIsNameArray.SHIPMENT.LANEWAYPOINTS,
        uri: uri,
      };
      this.commonService.getList(APIparams).subscribe(
          (ServerRes) => {
          if (ServerRes.success === true) {
           this.skeletonLoader = false;
           this.wayPoints = ServerRes.response.waypoints;
          this.pickupWayPoint =this.wayPoints.filter(x=> x.type == 'pickup');
          this.dropoffWayPoint =this.wayPoints.filter(x=> x.type == 'dropoff');
          } else {
            this.skeletonLoader = false;
          }
        },
        (error) => {
          this.skeletonLoader = false;
        }
      );
  }

  showDetailCommodity(detail:any,laneDetail:any) {
    const dialogRef = this.dialog.open(LoadDetailCommodityComponent, {
      disableClose: true,
      backdropClass: 'cus_backdrop_class',
      panelClass: 'cus_popup_vw',
      width: '1140px',
      data: { openPop: 'showLansShipmentDetail' ,shipmentDetail:detail, laneDetail :laneDetail, type: 'lane',status:this.laneDetail?.status},
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result.event === 'fail') {
      }
    });
  }


}
