import { NgModule } from '@angular/core';
import { AgmCoreModule } from '@agm/core';
import { MatGoogleMapsAutocompleteModule } from '@angular-material-extensions/google-maps-autocomplete';
import { CommonModule, DatePipe } from '@angular/common';
import { LoadRoutingModule } from './load-routing.module';
import { LoadComponent } from './load/load.component';
import { TrackYourLoadComponent } from './track-your-load/track-your-load.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NgxLoadingModule } from 'ngx-loading';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { MatTabsModule } from '@angular/material/tabs';
import { SharedModule } from '../shared/shared.module';
import { MaterialModule } from '../material.module';
import { LoadsSharedModule } from '../loads-shared/loads-shared.module';
import { CoreModule } from '../core/core.module';
import { AddToCarrierComponent } from './add-to-carrier/add-to-carrier.component';
import { CarrierBidComponent } from './carrier-bid/carrier-bid.component';
import { ShipperBidComponent } from './shipper-bid/shipper-bid.component';
import { NgxGpAutocompleteModule } from '@angular-magic/ngx-gp-autocomplete';
import { Loader } from '@googlemaps/js-api-loader';

@NgModule({
  declarations: [
    LoadComponent,
    TrackYourLoadComponent,
    AddToCarrierComponent,
    CarrierBidComponent,
    ShipperBidComponent
  ],
  imports: [
    CommonModule,
    LoadRoutingModule,
    // AgmCoreModule.forRoot({
    //   apiKey: 'AIzaSyAMMBwZYg03hSwJEnODnIWf14YJKjWz_2A',
    //   libraries: ['places'],
    // }),
    NgxLoadingModule.forRoot({}),
    MatGoogleMapsAutocompleteModule,
    FormsModule,
    ReactiveFormsModule,
    CoreModule,
    RouterModule,
    InfiniteScrollModule,
    NgxSkeletonLoaderModule,
    InfiniteScrollModule,
    MatTabsModule,
    SharedModule,
    LoadsSharedModule,
    MaterialModule,
    NgxGpAutocompleteModule
  ],
  providers: [DatePipe,{ provide: Loader,useValue: new Loader({
    apiKey: 'AIzaSyAMMBwZYg03hSwJEnODnIWf14YJKjWz_2A',
    libraries: ['places']
  }) }],
  exports: [
    LoadComponent,
    AddToCarrierComponent,
    TrackYourLoadComponent,
    CarrierBidComponent,
    ShipperBidComponent
  ],
})
export class LoadModule { }
