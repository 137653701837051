import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { AlertService } from '../../../../commons/service/alert.service';
import { CommonService } from '../../../../commons/service/common.service';
import { AppSettings } from '../../../../commons/setting/app_setting';
import { DisputePopupComponent } from 'src/app/loads-shared/dispute-popup/dispute-popup.component';
export interface DialogData {
  name: string;
  condition: any;
}

@Component({
  selector: 'app-dispute',
  templateUrl: './dispute.component.html',
  styleUrls: ['./dispute.component.scss'],
})
export class DisputeComponent implements OnInit {
  public disputeForm: FormGroup;
  public getDisputeDetail: any;
  public loading = false;
  public reasonList: any;
  public imgURL: any;
  public name: any;
  public disputeDetailReason: any;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private commonService: CommonService,
    private alertService: AlertService,
    public dialog: MatDialog,
    private datePipe: DatePipe,
  ) {}

  ngOnInit() {
    this.disputeForm = this.formBuilder.group({
      uniqueId: ['', [Validators.required]],
      deliveryDate: ['', [Validators.required]],
      paymentDate: [''],
      proofOfDelivery: [''],
      carrierCompany: [''],
      carrierName: [''],
      shipperCompany: [''],
      shipperName: ['', [Validators.maxLength(64)]],
      disputeReason: ['', [Validators.required]],
      uploadDocument: ['', [Validators.required]],
      discription: ['', [Validators.required]],
    });

    this.getDispute(null);
    this.getReasonList();
  }

  getAPIParam() {
    let APIparams, params;
    params = { limit: 30 };
    APIparams = {
      apiKey: AppSettings.APIsNameArray.DISPUTE.LIST,
      uri: this.commonService.getAPIUriFromParams(params),
    };
    return APIparams;
  }

  // get disput reason list 
  getReasonList() {
    var APIparams = this.getAPIParam();
    this.commonService.getList(APIparams).subscribe((ServerRes) => {
      if (ServerRes.response && ServerRes.response.length > 0) {
        this.reasonList = ServerRes.response;
      } else {
        this.reasonList = [];
      }
    });
  }

  // for disput detail reason 
  disputeData(disputeData) {
    if (disputeData.title) {
      this.disputeDetailReason = disputeData.title;
    }
  }

  // get disput data 
  getDispute(params = {}) {
    this.loading = true;
    let uri = null;
    params = {
      shipmentId: this.route.snapshot.params['id'],
    };
    if (params) uri = this.commonService.getAPIUriFromParams(params);
    let APIparams = {
      apiKey: AppSettings.APIsNameArray.DISPUTE.GET,
      uri: uri,
    };
    this.commonService.getList(APIparams).subscribe(
      (ServerRes) => {
        if (ServerRes.success === true) {
          this.loading = false;
          let paymentDate;
          this.getDisputeDetail = ServerRes.response;
          const deliveryDate = new Date(ServerRes.response.deliveryDate);
          if (ServerRes.response.paymentDate !== 'Invalid date') {
            paymentDate = new Date(ServerRes.response.paymentDate);
          } else {
            paymentDate = '';
          }
          this.disputeForm = this.formBuilder.group({
            uniqueId: [ServerRes.response.uniqueId, [Validators.required]],
            deliveryDate: [deliveryDate, [Validators.required]],
            paymentDate: [paymentDate],
            proofOfDelivery: [ServerRes.response.proofOfDelivery],
            carrierCompany: [ServerRes.response.carrierCompany],
            carrierName: [ServerRes.response.carrierName],
            shipperCompany: [ServerRes.response.shipperCompany],
            shipperName: [
              ServerRes.response.shipperName,
              [Validators.maxLength(64)],
            ],
            disputeReason: ['', [Validators.required]],
            uploadDocument: ['', [Validators.required]],
            discription: ['', [Validators.required]],
          });
        } else {
          this.loading = false;
          this.getDisputeDetail = '';
        }
      },
      (error) => {
        this.loading = false;
        this.getDisputeDetail = '';
      }
    );
  }

  // Document
  onSelectedFile(event) {
    if (event.target.files.length === 0) return;
    var mimeType = event.target.files[0].type;
    var reader = new FileReader();
    this.name = event.target.files[0].name;
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = (_event) => {
      this.imgURL = reader.result;
    };
    if (event.target.files.length) {
      const file = event.target.files[0];
      this.disputeForm.get('uploadDocument').setValue(file);
    }
  }

  // create dispute 
  createDispute({ value, valid }) {
    if (valid) {
      this.loading = true;
      let deliveryDate = this.datePipe.transform(
        new Date(this.disputeForm.get('deliveryDate').value),
        'MM/dd/yyyy'
      );
      const formData = new FormData();
      formData.append('document', this.disputeForm.get('uploadDocument').value);
      formData.append('shipmentId', this.route.snapshot.params['id']);
      formData.append('deliveryDate', deliveryDate);
      formData.append(
        'disputeReason',
        this.disputeForm.get('disputeReason').value
      );
      formData.append('description', this.disputeForm.get('discription').value);

      let APIparams = {
        apiKey: AppSettings.APIsNameArray.DISPUTE.ADD,
        uri: '',
        postBody: formData,
      };
      this.commonService.post(APIparams).subscribe(
        (success) => {
          if (success.success === true) {
            this.loading = false;
            this.disputeSuccessPopup();
          } else if (success.success === false) {
            this.alertService.showNotificationMessage(
              'danger',
              'bottom',
              'right',
              'txt_d',
              'cancel',
              'Dispute Shipment',
              success.message
            );
            this.loading = false;
          }
        },
        (error) => {
          this.loading = false;
          this.alertService.showNotificationMessage(
            'danger',
            'bottom',
            'right',
            'txt_d',
            'cancel',
            'Dispute Shipment',
            AppSettings.ERROR
          );
        }
      );
    }
  }

  // popup for disput success 
  disputeSuccessPopup() {
    const dialogRef = this.dialog.open(DisputePopupComponent, {
      backdropClass: AppSettings.backdropClass,
      width: AppSettings.popWidth,
      disableClose: true,
      data: {
        openPop: 'disputeSuccess',
        shipmentId: this.getDisputeDetail.id,
        title: this.getDisputeDetail.title,
        uniqueId: this.getDisputeDetail.uniqueId,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        if (result.event === 'Ok') {
        }
      }
    });
  }
  
}
