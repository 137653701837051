<div class="shipment_bidding tab_header">
    <div class="expected_bidRate">
      <span class="fs_13 fsm_5 txt_b d-block contract_offerRateQW text_truncate" style="max-width: 250px;">
        <span class="txt_lb me-1"
          *ngIf="(!shipmentDetail?.isOfferRate || shipmentDetail?.isOfferRate==='N') && (!shipmentDetail?.overBid)">Expected
          Rate: </span>
        <span class="txt_lb me-1"
          *ngIf="(shipmentDetail?.isOfferRate==='Y') && (shipmentDetail?.overBid==='Y' || shipmentDetail?.overBid==='N')">Offer
          Rate: </span>
  
        <span *ngIf="shipmentDetail?.paymentMethod === 2 && shipmentDetail?.freightRate" class="usdc_iconS"></span>
        <span *ngIf="shipmentDetail?.paymentMethod === 2 && shipmentDetail?.freightRate" class="fsn_4">
          {{shipmentDetail?.freightRate | sixDecimalPipe}}</span>
        <span *ngIf="shipmentDetail?.paymentMethod === 1 && shipmentDetail?.freightRate">
          <span class="fsn_4"> ${{shipmentDetail?.freightRate | twoDecimalPipe}}</span>
          <app-rate-per-mile class="fs_14 fsm_5 txt_lb" [amount]="shipmentDetail?.freightRate"
            [miles]="shipmentDetail?.miles"></app-rate-per-mile>
        </span>
        <span *ngIf="!shipmentDetail?.paymentMethod"> -</span>
      </span>
    </div>
    <div class="detail_card_con"
      *ngIf="subCurrentTab !=='acceptUsd' && subCurrentTab !=='acceptUsdc' && subCurrentTab !=='acceptACH'">
      <div class="carrier_list_con">
        <div class="carrierList_head" [ngClass]="checkPreAllBid==='allCarrier' ? 'visibility_off' : 'visibility_on'">
          <p class="line_before mb-0 " *ngIf="checkPreAllBid==='allCarrier'">All Carriers</p>
          <p class="line_before mb-0 " *ngIf="checkPreAllBid==='preCarrier'">Preferred Carriers</p>
          <div *ngIf="userType==='SHIPPER'" [ngClass]="shipmentConfirmChecked ? 'confBidA' :'confBidU'"
            class="custom_toggle example-margin d-flex">
            <mat-slide-toggle [(ngModel)]="shipmentConfirmChecked" class="example-margin"
              (change)="confirmPayment($event)"></mat-slide-toggle>
            <span class="fs_16 fsm_5 ms-3 Confbid_a " *ngIf="shipmentDetail?.isOfferRate==='N'">Confirmed
              Quotes/Bids</span>
            <span class="fs_16 fsm_5 ms-3 Confbid_a " *ngIf="shipmentDetail?.isOfferRate==='Y'">Confirmed Offer
              Rate</span>
          </div>
          <div class="custom_toggle">
            <span class="fs_16 fsm_5 me-3 visibilityl ">All Carriers</span>
            <mat-slide-toggle [(ngModel)]="shipmentChecked" class="example-margin" (change)="changeCarrierBids($event)">
            </mat-slide-toggle>
            <span class="fs_16 fsm_5 ms-3 visibilityr ">Preferred Carriers</span>
          </div>
          <div *ngIf="shipmentDetail?.isOfferRate==='Y' && shipmentDetail?.overBid==='Y'"
            style="margin-top: 51px;position: absolute;">
            <span class="fs_16 fsm_5 me-3">Note: You can Counter a Higher quote/overbid twice with each carrier.</span>
          </div>
        </div>
        <!-- All carrier list -->
        <div id="all_Drivers" class="list_scroll allCarrier_scroll" infiniteScroll [infiniteScrollDistance]="3"
          [infiniteScrollThrottle]="100" (scrolled)="onScroll()" [scrollWindow]="false">
          <table class="table mb-0 acceptd_previous_table">
            <tbody *ngIf="allCarrierSkeloton && carrierList && carrierList?.length > 0">
              <tr *ngFor="let carrierRecord of carrierList;let j=index;">
                <td class="fs_16 fsn_4 txt_lb ps-0 pointer" style="width: 29%;">
                  <div class="carrierN">
                    <div class="carrierN_img">
                      <span *ngIf="carrierRecord?.isPreferred === 1" class="material-icons star_marked">star</span>
                      <img *ngIf="carrierRecord?.profileImage" src="{{carrierRecord?.profileImage}}" class="img-fluid">
                      <span class="initial_name initial_r_32 "
                        *ngIf="!carrierRecord?.profileImage">{{carrierRecord.legalName |
                        acronymOfName}}</span>
                    </div>
                    <div class="shipper_bid_box">
                      <div (click)="eventActionProfileSidePanel(carrierRecord,'carrier_side_panel')">
  
                        <div class="carrierNl">
                          <p class="abl_ca_name fs_16 fsm_5 txt_b m-0  d-flex">
                            <span class="text_truncate w_60" matTooltip="{{carrierRecord.legalName}}">
                              {{carrierRecord.legalName}} </span><span *ngIf="userType=='SHIPPER'">
                              <img *ngIf="carrierRecord?.subscription"
                                src="{{serverPathForUrl}}/portal/images/after-login/premium_plan_icon.png"
                                class="img-fluid verified_icon">
                            </span>
                          </p>
                        </div>
                      </div>
                      <div class="carrierNl">
                        <p *ngIf="(!shipmentDetail?.isOfferRate || shipmentDetail?.isOfferRate==='N') && !shipmentDetail?.overBid"
                          class="fs_12 fsn_4 txt_p mb-0"
                          (click)="showPreviousHistory(carrierRecord?.userId,carrierRecord?.legalName,shipmentDetail?.paymentMethod,shipmentDetail)">
                          <u class="pointer ">Show previous quotes/bids</u>
                        </p>
                      </div>
                      
                      <div class="unlock_btn" *ngIf="userType=='SHIPPER' && !carrierRecord?.shipperCarrierScoreMapping"
                        (click)="unlockSafetyScore(carrierRecord,carrierRecord?.dotNumber,j)">
                        <button><img src="\assets\icon\unlock_btn_icon.svg" class="mr-1">Unlock Safety Score</button>
                      </div>
                     
  
                    </div>
                  </div>
                  <div *ngIf="userType=='SHIPPER' && carrierRecord?.shipperCarrierScoreMapping">
                    <div class="ecarrier_check_box mt-2">
                      <div>
                        <span class="d-block pointer">
                          <img src="\assets\icon\info_icon_green.svg" matTooltip="{{eCarrierCheckRisk?.lowRisk}}"
                            *ngIf="carrierRecord?.carrierSafetyScore?.riskLevel=='4'">
                          <img src="\assets/icon/ecarrier_info_light_red.svg"
                            matTooltip="{{eCarrierCheckRisk?.highRisk}}"
                            *ngIf="carrierRecord?.carrierSafetyScore?.riskLevel=='2'">
                          <img src="\assets/icon/ingo_icon_yellow.svg"
                            matTooltip="{{eCarrierCheckRisk?.elevatedRisk}}"
                            *ngIf="carrierRecord?.carrierSafetyScore?.riskLevel=='3'">
                          <img src="\assets/icon/ecarrier_info_light_pure_red.svg"
                            matTooltip="{{eCarrierCheckRisk?.disqualified}}"
                            *ngIf="carrierRecord?.carrierSafetyScore?.riskLevel=='1'">
                        </span>
                        <span class="fs_8 txt_b fsm_5">{{carrierRecord?.carrierSafetyScore?.riskLabel ?
                          carrierRecord?.carrierSafetyScore?.riskLabel : '-'}}</span>
                      </div>
  
                      <div *ngIf="carrierRecord?.carrierSafetyScore?.authority">
                        <span class="d-block" style="height:20px;">
                          <img src="\assets\icon\ecarrier_succes_icon.svg"
                            *ngIf="carrierRecord?.carrierSafetyScore?.authority">
                        </span>
                        <span class="fs_8 txt_b fsm_5">Authority</span>
                      </div>
  
                      <div>
                        <span class="d-block"><img src="\assets\icon\ecarrier_crases_icon_red.svg"></span>
                        <span class="fs_8 txt_b fsm_5">Crashes<span
                            class="text_red ms-1">({{carrierRecord?.carrierSafetyScore?.recentCrashes ?
                            carrierRecord?.carrierSafetyScore?.recentCrashes : '0'}})</span></span>
                      </div>
  
                      <div>
                        <span class="d-block"><span
                            class="c_nu">{{carrierRecord?.carrierSafetyScore?.recentInspections
                            ? carrierRecord?.carrierSafetyScore?.recentInspections : '0'}}</span></span>
                        <span class="fs_8 txt_b fsm_5">Inspection</span>
                      </div>
  
  
                    </div>
                    <div (click)="ecarriercheck(carrierRecord)" class="view_shot_btn">
                      <span class="fs_8 txt_p fsm_5 pointer">View Safety Report</span>
                    </div>
                  </div>
                </td>
  
  
                <td class="bid_col" style="width: 29%;">
                  <p class="bid_rate fsm_5 usdUdsd contract_offer_Rate">
                    <span *ngIf="shipmentDetail?.paymentMethod === 2 && carrierRecord?.previousBid"
                      class="ms-1 usdc_iconS"></span>
                    <span *ngIf="shipmentDetail?.paymentMethod === 2 && carrierRecord?.previousBid"
                      class="fsn_4">{{carrierRecord?.previousBid?.bidAmount | sixDecimalPipe}}</span>
                    <span *ngIf="shipmentDetail?.paymentMethod === 1 && carrierRecord?.previousBid">
                      <span class="fsn_4">${{carrierRecord?.previousBid?.bidAmount |
                        twoDecimalPipe}}</span>
                      <app-rate-per-mile class="fs_12 fsm_5 txt_lb ml_1" [amount]="carrierRecord?.previousBid?.bidAmount"
                        [miles]="shipmentDetail?.miles">
                      </app-rate-per-mile>
                    </span>
                    <span *ngIf="!carrierRecord?.previousBid">-</span>
                    <span *ngIf="currentId === carrierRecord?.previousBid?.actionBy" class="">(Me)</span>
                    <span class="caAccepted_tag " *ngIf="carrierRecord?.previousBid?.isAccepted === '1'"
                      matTooltip="{{carrierRecord?.previousBid?.statusLabel}}">{{carrierRecord?.previousBid?.statusLabel}}</span>
                  </p>
                  <p class="bid_date" *ngIf="carrierRecord?.previousBid">
                    {{UTCDate(carrierRecord?.previousBid?.createdAt) | date: 'MMM dd, yyyy' }} at {{UTCDate(carrierRecord?.previousBid?.createdAt) | date: 'HH:mm:ss'}}</p>
                  <p class="bid_date" *ngIf="!carrierRecord?.previousBid">-</p>
                  <p class="bid_mess">{{carrierRecord?.previousBid?.note ?
                    carrierRecord?.previousBid?.note : '-'}}</p>
                </td>
                <td class="bid_col" style="width: 29%;" *ngIf="shipmentDetail?.isOfferRate==='N'">
                  <p class="bid_rate fsm_5 usdUdsd contract_offer_Rate">
                    <span *ngIf="shipmentDetail?.paymentMethod === 2 && carrierRecord?.currentBid?.bidAmount"
                      class="ms-1 usdc_iconS"></span>
                    <span *ngIf="shipmentDetail?.paymentMethod === 2 && carrierRecord?.currentBid?.bidAmount"
                      class="fsn_4"> {{carrierRecord?.currentBid?.bidAmount | sixDecimalPipe}}</span>
                    <span *ngIf="shipmentDetail?.paymentMethod === 1 && carrierRecord?.currentBid?.bidAmount">
                      <span class="fsn_4">${{carrierRecord?.currentBid?.bidAmount |
                        twoDecimalPipe}}</span>
                      <app-rate-per-mile class="fs_12 fsm_5 txt_lb ml_1" [amount]="carrierRecord?.currentBid?.bidAmount"
                        [miles]="shipmentDetail?.miles">
                      </app-rate-per-mile>
                    </span>
                    <span *ngIf="!carrierRecord?.currentBid?.bidAmount">-</span>
                    <span *ngIf="currentId === carrierRecord?.currentBid?.actionBy" class="">(Me)</span>
                    <span class="caAccepted_tag " *ngIf="carrierRecord?.currentBid?.isAccepted === 1"
                      matTooltip="{{carrierRecord?.currentBid?.statusLabel}}">{{carrierRecord?.currentBid?.statusLabel}}</span>
                  </p>
                  <p class="bid_date fsn_4" *ngIf="carrierRecord?.currentBid">
                    <span>{{ UTCDate(carrierRecord?.currentBid?.createdAt) | date: 'MMM dd, yyyy' }} at {{
                      UTCDate(carrierRecord?.currentBid?.createdAt) | date: 'HH:mm:ss' }}</span>
                  </p>
                  <p></p>
                  <p class="bid_date fsn_4" *ngIf="!carrierRecord?.currentBid">-</p>
                  <p class="bid_mess">{{carrierRecord?.currentBid?.note ? carrierRecord?.currentBid?.note
                    : '-'}}</p>
                </td>
                <td class="bid_col" style="width: 29%;" *ngIf="shipmentDetail?.isOfferRate==='Y'">
                  <p class="bid_rate fsm_5 usdUdsd">
                    <span *ngIf="shipmentDetail?.paymentMethod === 2 && carrierRecord?.currentBid?.bidAmount">
                      <span class="ms-1 usdc_iconS"></span>
                      <span> {{carrierRecord?.currentBid?.bidAmount | sixDecimalPipe}}</span>
                    </span>
                    <span style="display: flex; flex-direction: column;">
                      <span *ngIf="shipmentDetail?.paymentMethod === 1 && carrierRecord?.currentBid?.bidAmount">
                        <span class="fsn_4">${{carrierRecord?.currentBid?.bidAmount |
                          twoDecimalPipe}}</span>
                        <app-rate-per-mile class="fs_12 fsm_5 txt_lb ml_1" [amount]="carrierRecord?.currentBid?.bidAmount"
                          [miles]="shipmentDetail?.miles">
                        </app-rate-per-mile>
                      </span>
                      <span>
                        <span *ngIf="currentId === carrierRecord?.currentBid?.actionBy" class="me-1 ">(Me)</span>
                        <span class="caAccepted_tag  ms-0" *ngIf="carrierRecord?.currentBid?.isAccepted === 1"
                          matTooltip="{{carrierRecord?.currentBid?.statusLabel}}">{{carrierRecord?.currentBid?.statusLabel}}</span>
                      </span>
                    </span>
                    <span *ngIf="!carrierRecord?.currentBid?.bidAmount">-</span>
                  </p>
                  <p class="bid_date fsn_4" *ngIf="carrierRecord?.currentBid">
                    <span>{{ UTCDate(carrierRecord?.currentBid?.createdAt) | date: 'MMM dd, yyyy' }} at {{
                      UTCDate(carrierRecord?.currentBid?.createdAt) | date: 'HH:mm:ss' }}</span>
                  </p>
                  <p class="bid_date fsn_4" *ngIf="!carrierRecord?.currentBid">-</p>
                  <p class="bid_mess">{{carrierRecord?.currentBid?.note ? carrierRecord?.currentBid?.note
                    : '-'}}</p>
                </td>
                <td class="fs_16 fsn_4 txt_p pe-0" style="width: 13%; vertical-align: initial; text-align: end;">
                  <div class="caBid_action">
                    <span
                      *ngIf="currentId === carrierRecord?.currentBid?.actionBy && carrierRecord?.currentBid?.isAccepted === 0 && shipmentDetail?.isOfferRate==='N' && !shipmentDetail?.overBid"
                      (click)="cancelBid(carrierRecord?.currentBid?.id,carrierRecord?.currentBid?.bidAmount,carrierRecord?.currentBid?.note,carrierRecord?.legalName,shipmentDetail?.paymentMethod)"
                      class="bid_btnImg pointer" matTooltip="Cancel Quote/Bid">
                      <img src="{{serverPathForUrl}}/portal/images/after-login/cl-icons/ic-withdraw-bid.svg"
                        class="img-fluid">
                    </span>
                    <span
                      *ngIf="((carrierRecord?.currentBid?.status === 1) && ((carrierRecord?.currentBid !== null && carrierRecord?.currentBid?.actionBy !== currentId) || (carrierRecord?.currentBid?.isAccepted === 1 && carrierRecord?.currentBid?.actionBy === currentId)))"
                      (click)="acceptBidShipper(carrierRecord)" class="bid_btnImg pointer" matTooltip="Accept Quote/Bid">
                      <img src="{{serverPathForUrl}}/portal/images/after-login/cl-icons/ic-accept-bid.svg"
                        class="img-fluid">
                    </span>
                    <span
                      *ngIf="((carrierRecord?.currentBid?.status === 2) &&  ((carrierRecord?.currentBid !==null && carrierRecord?.currentBid?.actionBy !== currentId) || (carrierRecord?.currentBid?.isAccepted === 1 && carrierRecord?.currentBid?.actionBy === currentId)))"
                      class="bid_btnImg disabled">
                      <img src="{{serverPathForUrl}}/portal/images/after-login/cl-icons/ic-accept-bid.svg"
                        class="img-fluid">
                    </span>
                    <span
                      *ngIf="currentId === carrierRecord?.currentBid?.actionBy && carrierRecord?.currentBid?.isAccepted===0 && carrierRecord?.currentBid?.statusLabel==='Counter quote/bid' && shipmentDetail?.isOfferRate==='Y' && shipmentDetail?.overBid==='Y' && shipmentDetail?.overBid==='Y'"
                      class="bid_btnImg disabled">
                      <img src="{{serverPathForUrl}}/portal/images/after-login/cl-icons/ic-bid-payment.svg"
                        class="img-fluid">
                    </span>
  
                    <span
                      *ngIf="((carrierRecord?.currentBid?.status === 4) && ((carrierRecord?.currentBid !== null && carrierRecord?.currentBid?.actionBy !== currentId) || (carrierRecord?.currentBid?.isAccepted ===1 && carrierRecord?.currentBid?.actionBy === currentId)))"
                      (click)="confirmAddAssign(carrierRecord)" class="bid_btnImg pointer"
                      matTooltip="Confirm And Assign">
                      <img src="{{serverPathForUrl}}/portal/images/after-login/cl-icons/ic-bid-payment.svg"
                        class="img-fluid">
                    </span>
  
                    <!-- counter bids -->
                    <span
                      *ngIf="(carrierRecord?.currentBid !== null && carrierRecord?.overBidCount > 0 && carrierRecord?.currentBid.statusLabel !=='Counter quote/bid' && shipmentDetail?.isOfferRate==='Y' && shipmentDetail?.overBid==='Y') "
                      (click)="counterBid(carrierRecord?.currentBid?.bidAmount,carrierRecord?.currentBid?.note,carrierRecord?.userId,carrierRecord?.currentBid?.userName,carrierRecord?.legalName,shipmentDetail?.paymentMethod,shipmentDetail?.freightRate)"
                      class="bid_btnImg pointer ms-3" matTooltip="Counter quote/bid">
                      <img src="{{serverPathForUrl}}/portal/images/after-login/cl-icons/ic-rebid-bid.svg"
                        class="img-fluid">
                    </span>
  
                    <span
                      *ngIf="((carrierRecord?.currentBid === null && (!shipmentDetail?.isOfferRate || shipmentDetail?.isOfferRate==='N') && !shipmentDetail?.overBid))"
                      class="bid_btnImg disabled">
                      <img src="{{serverPathForUrl}}/portal/images/after-login/cl-icons/ic-accept-bid.svg"
                        class="img-fluid">
                    </span>
  
                    <span
                      *ngIf="(carrierRecord?.currentBid !==null && (shipmentDetail?.isOfferRate==='N' || !shipmentDetail?.isOfferRate) && !shipmentDetail?.overBid) "
                      (click)="redBid(carrierRecord?.currentBid?.bidAmount,carrierRecord?.currentBid?.note,carrierRecord?.userId,carrierRecord?.currentBid?.userName,carrierRecord?.legalName,shipmentDetail?.paymentMethod)"
                      class="bid_btnImg pointer ms-3" matTooltip="Counter quote/bid">
                      <img src="{{serverPathForUrl}}/portal/images/after-login/cl-icons/ic-rebid-bid.svg"
                        class="img-fluid">
                    </span>
  
                    <span
                      *ngIf="((carrierRecord?.currentBid ===null && (shipmentDetail?.isOfferRate==='N' || !shipmentDetail?.isOfferRate) && !shipmentDetail?.overBid)) || (shipmentDetail?.isOfferRate==='Y' && (shipmentDetail?.overBid==='Y' || shipmentDetail?.overBid==='N') && carrierRecord?.currentBid !== null && carrierRecord?.currentBid?.actionBy === currentId && (carrierRecord?.overBidCount >= 0 || !carrierRecord?.overBidCount))"
                      class="bid_btnImg ms-3 disabled">
                      <img src="{{serverPathForUrl}}/portal/images/after-login/cl-icons/ic-rebid-bid.svg"
                        class="img-fluid">
                    </span>
  
                  </div>
                </td>
              </tr>
            </tbody>
            <tbody *ngIf="!allCarrierSkeloton">
              <tr *ngFor="let fake of generateFake(4)">
                <td scope="row" colspan="4">
                  <div class="shipBisCa_skt" style="align-items: inherit;">
                    <div class="shipBisCa_sktl">
                      <ngx-skeleton-loader count="1" [theme]="{ width: '32px', height: '32px'}" class="loader_m_0">
                      </ngx-skeleton-loader>
                    </div>
                    <div class="shipBisCa_sktr">
                      <ngx-skeleton-loader count="1" [theme]="{ width: '100%', height: '14px'}" class="loader_m_0">
                      </ngx-skeleton-loader>
                      <ngx-skeleton-loader count="1" [theme]="{ width: '100%', height: '14px'}" class="loader_m_0">
                      </ngx-skeleton-loader>
                      <ngx-skeleton-loader count="1" [theme]="{ width: '100%', height: '14px'}" class="loader_m_0">
                      </ngx-skeleton-loader>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <div *ngIf="carrierList?.length === 0 && allCarrierSkeloton">
            <div class="Empty_State_body_withou_box Empty_State_body1 b_shadow_0">
              <div class="Empty_State_con">
                <div class="Empty_State_img">
                  <img class="img-fluid"
                    src="{{serverPathForUrl}}/portal/images/after-login/Empty_State_icons/gr-no-bids-received.svg">
                </div>
                <div>
                  <p class="fs_24 fsb_6 txt_b pb-1 " *ngIf="shipmentDetail?.isOfferRate==='Y'"
                    style="margin: 30px 0 12px ">No Offer Rate Confirmation <span class="txt_p "> Yet</span>
                  </p>
                  <p class="fs_24 fsb_6 txt_b pb-1 " *ngIf="shipmentDetail?.isOfferRate==='N'"
                    style="margin: 30px 0 12px ">No Quotes/Bids Received <span class="txt_p "> Yet</span>
                  </p>
  
                  <p *ngIf="(shipmentDetail?.isOfferRate==='Y') && (shipmentDetail?.overBid==='Y' || shipmentDetail?.overBid==='N') && (checkPreAllBid==='preCarrier')"
                    class="fs_16 fsn_4 txt_lb pb-1" style="margin: 0px 42px 20px;">You have not received any Offer Rate
                    Confirmation from Preferred Carriers on this shipment.</p>
  
                  <p *ngIf="(shipmentDetail?.isOfferRate==='Y') && (shipmentDetail?.overBid==='Y' || shipmentDetail?.overBid==='N') && (checkPreAllBid !=='preCarrier')"
                    class="fs_16 fsn_4 txt_lb pb-1" style="margin: 0px 42px 20px ">You have not received any Offer
                    Rate Confirmation from Carriers on this shipment.</p>
  
                  <p *ngIf="(shipmentDetail?.isOfferRate==='N') && (!shipmentDetail?.overBid || shipmentDetail?.overBid==='N') && (checkPreAllBid ==='preCarrier')"
                    class="fs_16 fsn_4 txt_lb pb-1" style="margin: 0px 42px 20px ">You have not
                    received any quotes/bids on this load from any preferred carriers yet.</p>
  
                  <p *ngIf="(shipmentDetail?.isOfferRate==='N') && (!shipmentDetail?.overBid || shipmentDetail?.overBid==='N') && (checkPreAllBid !=='preCarrier')"
                    class="fs_16 fsn_4 txt_lb pb-1" style="margin: 0px 42px 20px;">You have not received
                    any quotes/bids on this load from any carriers yet.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Shipment accept shipper side section -->
    <div *ngIf="subCurrentTab === 'acceptUsd'">
      <div class="detail_card_con pb-3">
        <div class="carrier_list_con">
          <p class="line_before mb-3 ">Accepted Bid</p>
          <table class="table mb-0 acceptd_previous_table row_aligment">
            <tbody>
              <tr>
                <td class="fs_16 fsn_4 txt_lb ps-0 img_name" style="width: 29%; vertical-align: middle;">
                  <div class="carrierN" style="display: flex; align-items: center;">
                    <div class="carrierN_img" style="top: 3px;">
                      <span *ngIf="!userAccepted?.currentBid?.profileImage"
                        class="initial_name initial_r_32 ">{{userAccepted?.currentBid?.userName |
                        acronymOfName}}</span>
                      <span *ngIf="userAccepted?.currentBid?.profileImage" class="ca_pro_img" style="border: none;">
                        <img src="{{userAccepted?.currentBid?.profileImage}}" class=" img-fluid">
                      </span>
                    </div>
                    <div class="carrierNl">
                      <p class="abl_ca_name text_truncate fs_16 fsm_5 txt_b m-0 " style="max-width: 150px;">
                        {{userAccepted?.currentBid?.userName}}</p>
                    </div>
                    <img *ngIf="userAccepted?.subscription"
                      src="{{serverPathForUrl}}/portal/images/after-login/premium_plan_icon.png"
                      class="img-fluid verified_icon">
                  </div>
                  <div *ngIf="userType=='SHIPPER' && userAccepted?.shipperCarrierScoreMapping">
                    <div class="ecarrier_check_box mt-2">
                      <div>
                        <span class="d-block">
                          <img src="\assets\icon\info_icon_green.svg" matTooltip="{{eCarrierCheckRisk?.lowRisk}}"
                            *ngIf="userAccepted?.carrierSafetyScore?.riskLevel=='4'">
                          <img src="\assets/icon/ecarrier_info_light_red.svg" matTooltip="{{eCarrierCheckRisk?.highRisk}}"
                            *ngIf="userAccepted?.carrierSafetyScore?.riskLevel=='2'">
                          <img src="\assets/icon/ingo_icon_yellow.svg" matTooltip="{{eCarrierCheckRisk?.elevatedRisk}}"
                            *ngIf="userAccepted?.carrierSafetyScore?.riskLevel=='3'">
                          <img src="\assets/icon/ecarrier_info_light_pure_red.svg"
                            matTooltip="{{eCarrierCheckRisk?.disqualified}}"
                            *ngIf="userAccepted?.carrierSafetyScore?.riskLevel=='1'">
                        </span>
                        <span class="fs_8 txt_b fsm_5">{{userAccepted?.carrierSafetyScore?.riskLabel ?
                          userAccepted?.carrierSafetyScore?.riskLabel : '-'}}</span>
                      </div>
  
                      <div *ngIf="userAccepted?.carrierSafetyScore?.authority">
                        <span class="d-block" style="height:20px;">
                          <img src="\assets\icon\ecarrier_succes_icon.svg"
                            *ngIf="userAccepted?.carrierSafetyScore?.authority">
                        </span>
                        <span class="fs_8 txt_b fsm_5">Authority</span>
                      </div>
  
                      <div>
                        <span class="d-block"><img src="\assets\icon\ecarrier_crases_icon_red.svg"></span>
                        <span class="fs_8 txt_b fsm_5">Crashes<span
                            class="text_red ms-1">({{userAccepted?.carrierSafetyScore?.recentCrashes ?
                            userAccepted?.carrierSafetyScore?.recentCrashes : '0'}})</span></span>
                      </div>
  
                      <div>
                        <span class="d-block"><span class="c_nu">{{userAccepted?.carrierSafetyScore?.recentInspections
                            ? userAccepted?.carrierSafetyScore?.recentInspections : '0'}}</span></span>
                        <span class="fs_8 txt_b fsm_5">Inspection</span>
                      </div>
  
  
                    </div>
                    <div (click)="ecarriercheck(userAccepted)" class="view_shot_btn">
                      <span class="fs_8 txt_p fsm_5 pointer">View Safety Report</span>
                    </div>
                  </div>
                  <div class="unlock_btn" *ngIf="userType=='SHIPPER' && !userAccepted?.shipperCarrierScoreMapping"
                    (click)="unlockSafetyScore(userAccepted,userAccepted?.dotNumber,'unlockSafetyScore')">
                    <button><img src="\assets\icon\unlock_btn_icon.svg" class="mr-1">Unlock Safety Score</button>
                  </div>
                
                </td>
  
                <td class="bid_col" style="width: 29%; vertical-align: top;">
                  <p class="fs_16 fsn_4 txt_b mb-0">{{ UTCDate(userAccepted?.currentBid?.createdAt) | date: 'MMM dd, yyyy' }} at {{ UTCDate(userAccepted?.currentBid?.createdAt) | date: 'HH:mm:ss'}}</p>
                </td>
                <td class="bid_col" style="width: 29%; vertical-align: top;" *ngIf="shipmentDetail?.isOfferRate==='N'">
                  <span class="v_center">
                    <p class="fs_16 fsn_4 txt_b mb-0 ">${{userAccepted?.currentBid?.bidAmount |
                      twoDecimalPipe}}</p>
                    <app-rate-per-mile class="fs_12 fsm_5 txt_lb ml_2 d-flex"
                      [amount]="userAccepted?.currentBid?.bidAmount" [miles]="shipmentDetail?.miles">
                    </app-rate-per-mile>
                  </span>
                </td>
                <td class="bid_col" style="width: 29%; vertical-align: middle;" *ngIf="shipmentDetail?.isOfferRate==='Y'">
                  <span class="v_center">
                    <p class="fs_16 fsn_4 txt_b mb-0 ">${{userAccepted?.currentBid?.bidAmount |
                      twoDecimalPipe}}</p>
                    <app-rate-per-mile class="fs_12 fsm_5 txt_lb ml_2 d-flex"
                      [amount]="userAccepted?.currentBid?.bidAmount" [miles]="shipmentDetail?.miles">
                    </app-rate-per-mile>
                  </span>
                </td>
                <td class="fs_16 fsn_4 txt_p pe-0" style="width: 13%; text-align: end; vertical-align: top;">
                  <span class="fs_14 fsm_5 txt_s ">Accepted</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="detail_card_con pb-3">
        <p class="line_before mb-4 ">Payment</p>
        <div>
          <div class="bid_row">
            <p class="mb-0 fs_16 fsm_5 txt_b">Gross Price</p>
            <p class="mb-0 bid_row_r">
              <span class="fs_16 fsb_7 txt_b">${{userAccepted?.currentBid?.bidAmount |
                twoDecimalPipe}}</span>
              <span class="fs_14 fsm_5 txt_s" *ngIf="shipmentDetail?.freightRate >userAccepted?.currentBid?.bidAmount">You
                Save
                ${{saveAmount(shipmentDetail?.freightRate,userAccepted?.currentBid?.bidAmount) |
                twoDecimalPipe}}</span>
            </p>
          </div>
          <div class="bid_row">
            <p class="mb-0">
              <span class=" fs_16 fsm_5 txt_b">LaneAxis Visibility Fee</span>
              <br>
              <span class="fs_14 fsn_4" style="color: var(--clBlkc);">(AXIS Token Worth
                ${{frightVissionFee() | twoDecimalPipe}})</span>
            </p>
            <p class="mb-0 bid_row_r">
              <span class="fs_16 fsb_7 txt_b">${{frightVissionFee() | twoDecimalPipe}}</span>
            </p>
          </div>
          <div class="bid_row">
            <p class="mb-0">
              <span class=" fs_16 fsm_5 txt_b">LaneAxis Fee </span> <br>
              <span class=" fs_14 fsn_4" style="color: var(--clBlkc);">(LaneAxis Processing Fee 1%)</span>
            </p>
            <p class="mb-0 bid_row_r">
              <span class="fs_16 fsb_7 txt_b">${{laneaxisFee(userAccepted?.currentBid?.bidAmount) |
                twoDecimalPipe}}</span>
            </p>
          </div>
          <div class="bid_row">
            <p class="mb-0">
              <span class=" fs_16 fsm_5 txt_b">Transaction Fee</span> <br>
              <span class=" fs_14 fsn_4" style="color: var(--clBlkc);">(Stripe Transaction Fee
                {{priceStripePercent}}% + ${{priceStripeFixed}})</span>
            </p>
            <p class="mb-0 bid_row_r">
              <span class="fs_16 fsb_7 txt_b">${{priceFixed(userAccepted?.currentBid?.bidAmount)}}</span>
            </p>
          </div>
  
          <hr style="margin-top: 16px; margin-bottom: 16px; border: 0; border-top: 1px solid #EDEDED;">
          <div class="bid_row mb-0">
            <p class="mb-0">
              <span class=" fs_16 fsm_5 txt_lb ">Total</span>
            </p>
            <p class="mb-0 bid_row_r">
              <span class="fs_16 fsb_6 txt_b ">${{totalAmount(userAccepted?.currentBid?.bidAmount) | twoDecimalPipe}}</span>
            </p>
          </div>
        </div>
      </div>
      <div>
        <form [formGroup]="paymentForm" (ngSubmit)="shipmentPay(paymentForm)" class="crd_pymt_form mb-0 custum_input_css">
          <div class="detail_card_con">
            <p class="line_before ">Billing Information</p>
            <div class="address_card" id="card-info">
              <app-address-card [userDetail]="shipperDetails" [currentAddressActive]=""
                [skeletonAddress]="skeletonLoaderAdd" (editAdd)="editAddress($event)"
                (addCardAdrress)="addAddress($event)" (selectAddresCard)="selectAddress($event)">
              </app-address-card>
              <div class="row">
                <div class="col-md-4">
                  <mat-form-field appearance="none" class="removeappearance" class="hide_input">
                    <input matInput placeholder="address" formControlName="address" class="form-control custom_input">
                    <mat-error *ngIf="paymentForm.get('address').errors?.['required']" class="error_mess">
                      Address is required.</mat-error>
                  </mat-form-field>
                </div>
              </div>
            </div>
          </div>
          <!-- Payment card section  -->
          <div class="detail_card_con">
            <p class="line_before mb-2 ">Payment Method</p>
            <p class="fs_14 fsn_4" style="color: var(--clBlkc); margin: 0 0 24px;">You have choosen USD
              money as your payment method.</p>
            <app-payment-card (selectPaymentCard)="selectCard($event)" [currentCardActive]="currentCardActive">
            </app-payment-card>
            <mat-form-field appearance="none" class="removeappearance" class="hide_input" style="margin: -24px 0 0;">
              <input matInput placeholder="paymentId" formControlName="paymentId">
              <mat-error *ngIf="paymentForm.get('paymentId').errors?.['required']" class="error_mess">
                Payment method is required.</mat-error>
            </mat-form-field>
          </div>
  
          <!-- Carrier and Shipper are not connectd  -->
          <div class="detail_card_con p_24"
            *ngIf="checkeUserConnection?.networkLabel !=='CONNECTED' && checkeUserConnection?.contractData?.carrierAccepted !==1 && checkeUserConnection?.contractData?.shipperAccepted !== 1">
            <p class="fs_12 fsm_5 txt_lb mb-0" style="line-height: 24px;">
              <span>By proceeding with payment, you agree to accept</span>
              <span class="txt_b mx-1">{{userAccepted?.legalName}}</span>
              <span class="me-1">'s certificate of insurance(COI), click here to </span>
              <a href="{{checkeUserConnection?.coiDoc}}" target="_blank"> <u class="txt_p me-1 ">view
                  document</u></a>
              <span class="me-1">and establish a direct-network connection with</span>
              <span class="txt_b">{{userAccepted?.legalName}}.</span>
            </p>
          </div>
          <div class="payment_actions mx-0">
            <button type="submit" class="btn custum_button button_primary px-4">Make Payment</button>
          </div>
        </form>
      </div>
    </div>
  
    <div *ngIf="subCurrentTab === 'acceptUsdc'">
      <div class="detail_card_con pb-3">
        <div class="carrier_list_con">
          <p class="line_before mb-3 ">Accepted Bid</p>
          <table class="table mb-0 acceptd_previous_table row_aligment">
            <tbody>
              <tr>
                <td class="fs_16 fsn_4 txt_lb ps-0 img_name" style="width: 29%;">
                  <div class="carrierN">
                    <div class="carrierN_img">
                      <span *ngIf="!userAccepted?.currentBid?.profileImage"
                        class="initial_name initial_r_32 ">{{userAccepted?.currentBid?.userName |
                        acronymOfName}}</span>
                      <span *ngIf="userAccepted?.currentBid?.profileImage" class="ca_pro_img" style="border: none;">
                        <img src="{{userAccepted?.currentBid?.profileImage}}" class=" img-fluid">
                      </span>
                    </div>
                    <div class="carrierNl">
                      <p class="abl_ca_name text_truncate fs_16 fsm_5 txt_b m-0 " style="max-width: 150px;">
                        {{userAccepted?.currentBid?.userName}}</p>
                    </div>
                  </div>
                </td>
                <td class="bid_col" style="width: 29%;">
                  <p class="fs_16 fsn_4 txt_b mb-0">{{ UTCDate(userAccepted?.currentBid?.createdAt) | date: 'MMM dd, yyyy' }} at {{UTCDate(userAccepted?.currentBid?.createdAt) | date: 'HH:mm:ss' }}</p>
                </td>
                <td class="bid_col" style="width: 29%;">
                  <p class="fs_16 fsn_4 txt_b mb-0 d_flx">
                    <span class="ms-1 usdc_iconS"></span>{{userAccepted?.currentBid?.bidAmount |
                    sixDecimalPipe}}
                  </p>
                </td>
                <td class="fs_16 fsn_4 txt_p pe-0" style="width: 13%; text-align: end;">
                  <span class="fs_14 fsm_5 txt_s ">Accepted</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="detail_card_con pb-3">
        <p class="line_before mb-4 ">Payment</p>
        <div>
          <div class="bid_row">
            <p class="mb-0 fs_16 fsm_5 txt_b">Gross Price</p>
            <p class="mb-0 bid_row_r">
              <span class="fs_16 fsb_7 txt_b df_e">
                <span class="ms-1 usdc_iconS"></span>
                <span>{{userAccepted?.currentBid?.bidAmount | sixDecimalPipe}}</span>
              </span>
              <span class="fs_14 fsm_5 txt_s df_e"
                *ngIf="shipmentDetail?.freightRate >userAccepted?.currentBid?.bidAmount">
                <span>You Save</span> <span class="ms-1 usdc_iconS wh_14"></span>
                <span>{{saveAmountUsdc(shipmentDetail?.freightRate ,userAccepted?.currentBid?.bidAmount) |
                  sixDecimalPipe}}</span>
              </span>
            </p>
          </div>
          <div class="bid_row">
            <p class="mb-0">
              <span class=" fs_16 fsm_5 txt_b">LaneAxis Visibility Fee</span>
              <br>
              <span class="fs_14 fsn_4 usdUdsd" style="color: var(--clBlkc);">(AXIS Token Worth <span
                  class="ms-1 usdc_iconS wh_14"></span>{{frightVissionFeeUsdc() | sixDecimalPipe}})</span>
            </p>
            <p class="mb-0 bid_row_r">
              <span class="fs_16 fsb_7 txt_b"><span class="ms-1 usdc_iconS df_e"></span>{{frightVissionFeeUsdc() |
                sixDecimalPipe}}</span>
            </p>
          </div>
          <div class="bid_row">
            <p class="mb-0">
              <span class=" fs_16 fsm_5 txt_b">Direct Payment Fee </span> <br>
              <span class=" fs_14 fsn_4" style="color: var(--clBlkc);">(LaneAxis Processing Fee 1%)</span>
            </p>
            <p class="mb-0 bid_row_r">
              <span class="fs_16 fsb_7 txt_b df_e"><span
                  class="ms-1 usdc_iconS"></span>{{priceFixedUsdc(userAccepted?.currentBid?.bidAmount,shipmentDetail?.freightRate) | sixDecimalPipe}}</span>
            </p>
          </div>
  
          <hr style="margin-top: 16px; margin-bottom: 16px; border: 0; border-top: 1px solid #EDEDED;">
          <div class="bid_row mb-0">
            <p class="mb-0">
              <span class=" fs_16 fsm_5 txt_lb ">Total</span>
            </p>
            <p class="mb-0 bid_row_r">
              <span class="fs_16 fsb_6 txt_b df_e "><span
                  class="ms-1 usdc_iconS"></span>{{totalAmountUsdc(userAccepted?.currentBid?.bidAmount,shipmentDetail?.freightRate) | sixDecimalPipe}}</span>
            </p>
          </div>
        </div>
      </div>
      <div>
  
        <!-- Payment card section  -->
        <div class="detail_card_con p_24">
          <p class="line_before mb-2 ">Payment Method</p>
          <p class="fs_14 fsn_4" style="color: var(--clBlkc); margin: 0 0 24px;">You have choosen USDC
            money as your payment method.</p>
          <app-payment-usdc-card [balance]="balance" [usdcCardLoader]="usdcCardLoader"
            (paymentBuyUsdcPopup)="paymentBuyUsdc($event)"></app-payment-usdc-card>
        </div>
        <!-- Carrier and Shipper are not connectd  -->
        <div class="detail_card_con p_24"
          *ngIf="checkeUserConnection?.networkLabel !=='CONNECTED' && checkeUserConnection?.contractData?.carrierAccepted !== 1 && checkeUserConnection?.contractData?.shipperAccepted !== 1">
          <p class="fs_12 fsm_5 txt_lb mb-0" style="line-height: 24px;">
            <span>By proceeding with payment, you agree to accept</span>
            <span class="txt_b mx-1">{{userAccepted?.legalName}}</span>
            <span class="me-1">'s certificate of insurance(COI), click here to</span>
            <a href="{{checkeUserConnection?.coiDoc}}" target="_blank"> <u class="txt_p me-1 ">view
                document</u></a>
            <span class="me-1">and establish a direct-network connection with</span>
            <span class="txt_b">{{userAccepted?.legalName}}.</span>
          </p>
        </div>
        <div class="payment_actions mx-0">
          <button type="button" [disabled]="checkUsdcPayment" class="btn custum_button button_primary px-4"
            (click)="payUsdcMethod()">Make Payment</button>
        </div>
        <!-- </form> -->
      </div>
    </div>
  
    <div *ngIf="subCurrentTab === 'acceptACH'">
      <div class="detail_card_con pb-3">
        <div class="carrier_list_con">
          <p class="line_before mb-3 ">Accepted Bid</p>
          <table class="table mb-0 acceptd_previous_table row_aligment">
            <tbody>
              <tr>
                <td class="fs_16 fsn_4 txt_lb ps-0 img_name" style="width: 29%; vertical-align: middle;">
                  <div class="carrierN">
                    <div class="carrierN_img">
                      <span *ngIf="!userAccepted?.currentBid?.profileImage"
                        class="initial_name initial_r_32 ">{{userAccepted?.currentBid?.userName |
                        acronymOfName}}</span>
                      <span *ngIf="userAccepted?.currentBid?.profileImage" class="ca_pro_img" style="border: none;">
                        <img src="{{userAccepted?.currentBid?.profileImage}}" class=" img-fluid">
                      </span>
                    </div>
                    <div class="carrierNl">
                      <p class="abl_ca_name text_truncate fs_16 fsm_5 txt_b m-0 " style="max-width: 150px;">
                        {{userAccepted?.currentBid?.userName}}</p>
                    </div>
                  </div>
                </td>
                <td class="bid_col" style="width: 29%; vertical-align: top;">
                  <p class="fs_16 fsn_4 txt_b mb-0">{{
                    UTCDate(userAccepted?.currentBid?.createdAt) | date: 'MMM dd, yyyy' }} at {{ UTCDate(userAccepted?.currentBid?.createdAt) | date: 'HH:mm:ss'}}
                  </p>
                </td>
                <td class="bid_col" style="width: 29%; vertical-align: middle;" *ngIf="shipmentDetail?.isOfferRate==='N'">
                  <span class="v_center">
                    <p class="fs_16 fsn_4 txt_b mb-0 ">${{userAccepted?.currentBid?.bidAmount |
                      twoDecimalPipe}}</p>
                    <app-rate-per-mile class="fs_12 fsm_5 txt_lb ml_2 d-flex"
                      [amount]="userAccepted?.currentBid?.bidAmount" [miles]="shipmentDetail?.miles">
                    </app-rate-per-mile>
                  </span>
                </td>
                <td class="bid_col" style="width: 29%; vertical-align: middle;" *ngIf="shipmentDetail?.isOfferRate==='Y'">
                  <span class="v_center">
                    <p class="fs_16 fsn_4 txt_b mb-0 ">${{userAccepted?.currentBid?.bidAmount |
                      twoDecimalPipe}}</p>
                    <app-rate-per-mile class="fs_12 fsm_5 txt_lb ml_2 d-flex"
                      [amount]="userAccepted?.currentBid?.bidAmount" [miles]="shipmentDetail?.miles">
                    </app-rate-per-mile>
                  </span>
                </td>
                <td class="fs_16 fsn_4 txt_p pe-0" style="width: 13%; text-align: end; vertical-align: top;">
                  <span class="fs_14 fsm_5 txt_s ">Accepted</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="detail_card_con pb-3">
        <p class="line_before mb-4 ">Payment</p>
        <div>
          <div class="bid_row">
            <p class="mb-0 fs_16 fsm_5 txt_b">Gross Price</p>
            <p class="mb-0 bid_row_r">
              <span class="fs_16 fsb_7 txt_b">${{userAccepted?.currentBid?.bidAmount |
                twoDecimalPipe}}</span>
              <span class="fs_14 fsm_5 txt_s" *ngIf="shipmentDetail?.freightRate >userAccepted?.currentBid?.bidAmount">You
                Save
                ${{saveAmount(shipmentDetail?.freightRate,userAccepted?.currentBid?.bidAmount) |
                twoDecimalPipe}}</span>
            </p>
          </div>
          <div class="bid_row">
            <p class="mb-0">
              <span class=" fs_16 fsm_5 txt_b">LaneAxis Visibility Fee</span>
              <br>
              <span class="fs_14 fsn_4" style="color: var(--clBlkc);">(AXIS Token Worth
                ${{frightVissionFee() | twoDecimalPipe}})</span>
            </p>
            <p class="mb-0 bid_row_r">
              <span class="fs_16 fsb_7 txt_b">${{frightVissionFee() | twoDecimalPipe}}</span>
            </p>
          </div>
          <div class="bid_row">
            <p class="mb-0">
              <span class=" fs_16 fsm_5 txt_b">LaneAxis Direct Fee </span> <br>
              <span class=" fs_14 fsn_4" style="color: var(--clBlkc);">(LaneAxis Processing Fee
                {{getLaneaxisProcessFees()}}%)</span>
            </p>
            <p class="mb-0 bid_row_r">
              <span class="fs_16 fsb_7 txt_b">${{laneaxisFeeACH(userAccepted?.currentBid?.bidAmount) |
                twoDecimalPipe}}</span>
            </p>
          </div>
          <div class="bid_row">
            <p class="mb-0">
              <span class=" fs_16 fsm_5 txt_b">Shipper Terms Fee </span> <br>
              <span class=" fs_14 fsn_4" style="color: var(--clBlkc);">(Shipper Terms Processing Fee
                {{getProcessFees(shipmentDetail?.stDaysNumber)}}%)</span>
            </p>
            <p class="mb-0 bid_row_r">
              <span
                class="fs_16 fsb_7 txt_b">${{shipperTermsFeeACH(userAccepted?.currentBid?.bidAmount,shipmentDetail?.stDaysNumber) | twoDecimalPipe}}</span>
            </p>
          </div>
          <div class="bid_row">
            <p class="mb-0">
              <span class=" fs_16 fsm_5 txt_b">Transaction Fee</span> <br>
              <span class=" fs_14 fsn_4" style="color: var(--clBlkc);">(Stripe Transaction Fee 0.8
                %)</span>
            </p>
            <p class="mb-0 bid_row_r">
              <span
                class="fs_16 fsb_7 txt_b">${{transactionFeeACH(userAccepted?.currentBid?.bidAmount,shipmentDetail?.stDaysNumber) | twoDecimalPipe}}</span>
            </p>
          </div>
  
          <hr style="margin-top: 16px; margin-bottom: 16px; border: 0; border-top: 1px solid #EDEDED;">
          <div class="bid_row mb-0">
            <p class="mb-0">
              <span class=" fs_16 fsm_5 txt_lb ">Total</span>
            </p>
            <p class="mb-0 bid_row_r">
              <span
                class="fs_16 fsb_6 txt_b ">${{transactionFeeACHTotal(userAccepted?.currentBid?.bidAmount,shipmentDetail?.stDaysNumber) | twoDecimalPipe}}</span>
            </p>
          </div>
        </div>
  
      </div>
      <div>
        <form [formGroup]="paymentFormACH" (ngSubmit)="shipmentPayACH(paymentFormACH)"
          class="crd_pymt_form mb-0 custum_input_css">
          <div class="detail_card_con">
            <p class="line_before ">Billing Information</p>
            <div class="address_card" id="card-info">
              <app-address-card [userDetail]="shipperDetails" [currentAddressActive]=""
                [skeletonAddress]="skeletonLoaderAdd" (editAdd)="editAddress($event)"
                (addCardAdrress)="addAddress($event)" (selectAddresCard)="selectAddressACH($event)">
              </app-address-card>
              <div class="row">
                <div class="col-md-4">
                  <mat-form-field appearance="none" class="removeappearance" class="hide_input">
                    <input matInput placeholder="address" formControlName="address" class="form-control custom_input">
                    <mat-error *ngIf="paymentFormACH.get('address').errors?.['required']" class="error_mess">Address is
                      required.</mat-error>
                  </mat-form-field>
                </div>
              </div>
            </div>
          </div>
          <!-- Payment card section  -->
          <div class="detail_card_con">
            <p class="line_before mb-2 ">Payment Method</p>
            <p class="fs_14 fsn_4" style="color: var(--clBlkc); margin: 0 0 24px;">You have choosen USD
              money as your payment method.</p>
            <app-ach-card (selectPaymentCardAch)="selectCardAch($event)" [currentCardActive]="currentCardActive">
            </app-ach-card>
            <mat-form-field appearance="none" class="removeappearance" class="hide_input" style="margin: -24px 0 0;">
              <input matInput placeholder="paymentId" formControlName="paymentId">
              <mat-error *ngIf="paymentFormACH.get('paymentId').errors?.['required']" class="error_mess">
                Payment method is required.</mat-error>
            </mat-form-field>
          </div>
  
          <!-- Carrier and Shipper are not connectd  -->
          <div class="detail_card_con p_24"
            *ngIf="checkeUserConnection?.networkLabel !=='CONNECTED' && checkeUserConnection?.contractData?.carrierAccepted !==1 && checkeUserConnection?.contractData?.shipperAccepted !== 1">
            <p class="fs_12 fsm_5 txt_lb mb-0" style="line-height: 24px;">
              <span>By proceeding with payment, you agree to accept</span>
              <span class="txt_b mx-1">{{userAccepted?.legalName}}</span>
              <span class="me-1">'s certificate of insurance(COI), click here to </span>
              <a href="{{checkeUserConnection?.coiDoc}}" target="_blank"> <u class="txt_p me-1 ">view
                  document</u></a>
              <span class="me-1">and establish a direct-network connection with</span>
              <span class="txt_b">{{userAccepted?.legalName}}.</span>
            </p>
          </div>
          <div class="detail_card_con p_24" *ngIf="shipmentDetail?.terms==='Shipper Terms'">
            <p class="fs_12 fsm_5 txt_b mb-0" style="line-height: 24px;">
              <span>Note: Payment will not be pulled from your ACH account and released to carrier until
                load is completed, any pending disputes are resolved, and the specified number of days
                have elapsed per the shipper payment terms you selected.</span>
            </p>
          </div>
  
          <div class="payment_actions mx-0">
            <button type="submit" class="btn custum_button button_primary px-4">Submit Payment</button>
          </div>
        </form>
      </div>
    </div>
  </div>
  <div class="" style="position: relative;" *ngIf="sidePanelProfileDataId">
    <div class="side-show-panel">
      <div class="side_bar_con">
        <app-carrier-side-panel [sidePanelProfileData]="sidePanelProfileData"
          [sidePanelProfileDataId]="sidePanelProfileDataId" [userType]="userType"
          (closeSidePanel)="closePanel($event)"></app-carrier-side-panel>
      </div>
    </div>
  </div>
  <ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '14px',fullScreenBackdrop:true }">
  </ngx-loading>