<div class="cus_matTab">
    <p class="panel_card_tlt" *ngIf="!skeletonLoader">Pickup Information's<span class="panel_card_tlt ms-1">(Total
            {{pickupWayPoint?.length}} Pickup)</span></p>
    <p class="panel_card_tlt" *ngIf="skeletonLoader"><ngx-skeleton-loader [theme]="{ width: '30%', height: '30px' }"
            class="" style="margin-bottom: 20px;"></ngx-skeleton-loader>
    </p>

    <div>
        <div *ngIf="!skeletonLoader" class="row" style="margin: 0px;">
            <div class="location_added_card_con col-md-3"
                *ngFor="let pickupData of pickupWayPoint let pickupIndex = index">
                <div class="location_added_card">
                    <div class="lac_l">
                        <div class="con_wrapper_row crd_btm_actns" (click)="showDetailCommodity(pickupData,laneDetail)">
                            <img src="{{serverPathForUrl}}/portal/icon/ic_Eye_icon.png" class="eye_icon">
                        </div>
                        <div class="row_label line_before_pic">
                            <p class="tlt_line">Pickup Point Location {{pickupIndex + 1}}</p>
                        </div>

                        <div class="lac_lt">
                            <p class="lac_dis" *ngIf="((userType=='SHIPPER') || (userType=='CARRIER' && laneDetail?.status=='3' && laneDetail?.bidReceived))">{{pickupData.locationLongName ? pickupData.locationLongName : '-'}}</p>
                            <span class="lac_dis" *ngIf="((userType=='CARRIER' && laneDetail?.status=='2') || (userType=='CARRIER' && laneDetail?.status=='3' && !laneDetail?.bidReceived))">{{(pickupData?.city
                                ? pickupData?.city : pickupData?.state) +","+ pickupData?.state +","+ pickupData?.countryName}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row" *ngIf="skeletonLoader" style="margin-left: 18px;">
            <div class="location_added_card_con_skt col-md-3">
               
                <ngx-skeleton-loader [theme]="{ width: '100%', height: '18px' }" class=""
                    style="margin-bottom: 20px;"></ngx-skeleton-loader>
                <ngx-skeleton-loader [theme]="{ width: '50%', height: '18px' }" class=""></ngx-skeleton-loader>
                <ngx-skeleton-loader [theme]="{ width: '35%', height: '18px' }" class=""></ngx-skeleton-loader>
            </div>
            <div class="location_added_card_con_skt col-md-3">
              
                <ngx-skeleton-loader [theme]="{ width: '100%', height: '18px' }" class=""
                    style="margin-bottom: 20px;"></ngx-skeleton-loader>
                <ngx-skeleton-loader [theme]="{ width: '50%', height: '18px' }" class=""></ngx-skeleton-loader>
                <ngx-skeleton-loader [theme]="{ width: '35%', height: '18px' }" class=""></ngx-skeleton-loader>
            </div>
        </div>
    </div>

    <p class="panel_card_tlt mt-3" *ngIf="!skeletonLoader">Dropoff Information's<span class="panel_card_tlt ms-1">(Total
            {{dropoffWayPoint?.length}} Dropoff)</span></p>
    <p class="panel_card_tlt mt-3" *ngIf="skeletonLoader"><ngx-skeleton-loader
            [theme]="{ width: '30%', height: '30px' }" class="" style="margin-bottom: 20px;"></ngx-skeleton-loader></p>
    <div class="row" *ngIf="!skeletonLoader" style="margin: 0px;">
        <div class="location_added_card_con col-md-3"
            *ngFor="let dropOffData of dropoffWayPoint let dropoffIndex = index">
            <div class="location_added_card">
                <div class="lac_l" [ngClass]="dropOffData?.pickupViewInfo===pickupIndex ? 'pickupHideShow' : ''">
                    <div class="con_wrapper_row crd_btm_actns" (click)="showDetailCommodity(dropOffData,laneDetail)">
                        <img src="{{serverPathForUrl}}/portal/icon/ic_Eye_icon.png" class="eye_icon">
                    </div>
                    <div class="row_label line_before_pic_green">
                        <p class="tlt_line_drop">Dropoff Point Location {{dropoffIndex + 1}}</p>
                    </div>
                    <div class="lac_lt">
                        <p class="lac_dis"
                            *ngIf="((userType=='SHIPPER') || (userType=='CARRIER' && laneDetail?.status=='3' && laneDetail?.bidReceived))">
                            {{dropOffData.locationLongName ? dropOffData.locationLongName : '-'}}</p>
                        <span class="lac_dis"
                            *ngIf="((userType=='CARRIER' && laneDetail?.status=='2') || (userType=='CARRIER' && laneDetail?.status=='3' && !laneDetail?.bidReceived))">{{(dropOffData?.city
                            ? dropOffData?.city : dropOffData?.state) +","+ dropOffData?.state +", "+ dropOffData?.countryName}}</span>
            
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row" *ngIf="skeletonLoader" style="margin-left: 18px;" >
        <div class="location_added_card_con_skt col-md-3">
            <ngx-skeleton-loader [theme]="{ width: '100%', height: '18px' }" class=""
                style="margin-bottom: 20px;"></ngx-skeleton-loader>
            <ngx-skeleton-loader [theme]="{ width: '50%', height: '18px' }" class=""></ngx-skeleton-loader>
            <ngx-skeleton-loader [theme]="{ width: '35%', height: '18px' }" class=""></ngx-skeleton-loader>
        </div>
        <div class="location_added_card_con_skt col-md-3">
            <ngx-skeleton-loader [theme]="{ width: '100%', height: '18px' }" class=""
                style="margin-bottom: 20px;"></ngx-skeleton-loader>
            <ngx-skeleton-loader [theme]="{ width: '50%', height: '18px' }" class=""></ngx-skeleton-loader>
            <ngx-skeleton-loader [theme]="{ width: '35%', height: '18px' }" class=""></ngx-skeleton-loader>
        </div>
    </div>
</div>
        <!-- equip box css -->
        <div class="container" *ngIf="!skeletonLoader">
            <div class="equip_box">
                <span>{{pickUpCommidity}}</span>
                <div class="line_before">
                    <p class="equip_txt mb-2">Commodity</p>
                </div>
                <div class="row mt-2" *ngIf="pickupWayPoint[0]?.commodity?.length > 0">
					<div class="col-md-3" *ngFor="let pickUpCommidity of pickupWayPoint[0]?.commodity">
						<div class="comdty_box">
							<div class="">
								<div class="con_wrapper_row">
									<p class="row_label">Commodity</p>
									<p class="row_value text-truncate pointer" matTooltip="{{pickUpCommidity?.commodity}}">{{pickUpCommidity?.commodity ? pickUpCommidity?.commodity: '-'}}
									</p>
								</div>
							</div>
							<div class="">
								<div class="con_wrapper_row">
									<p class="row_label">Weight</p>
									<p class="row_value text-truncate">{{pickUpCommidity?.weight ? pickUpCommidity?.weight : '-'}} <span
                                        *ngIf="pickUpCommidity?.weight">lbs</span></p>
								</div>
							</div>
							<div class="">
								<div class="con_wrapper_row">
									<p class="row_label">Handle With Care</p>
									<p class="row_value mb-0">{{pickUpCommidity?.handleWithCare ? 'Yes' : 'No'}}</p>
								</div>
							</div>
						</div>
		        	</div>
            </div>
            <div *ngIf="pickupWayPoint[0]?.commodity?.length == 0">
                <p>There is no commodity attached with this lane.</p>
            </div>
            </div>
            <div class="equip_box">
                <div class="line_before">
                    <p class="equip_txt mb-2">Equipment Details</p>
                </div>
                <div class="row">
                    <p class="col-md-3">
                        <span class="fs_16 txt_lb d-block mb-3">Equipment Type</span>
                        <span class="fs_18 txt_b">{{laneDetail?.equipmentName ? laneDetail?.equipmentName: '-'}}</span>
                    </p>
                    <p class="col-md-3">
                        <span class="fs_16 txt_lb d-block mb-3">Equipment Length</span>
                        <span class="fs_18 txt_b">{{laneDetail?.equipmentLength ?
                            laneDetail?.equipmentLength +' Feet' : '-'}}</span>
                    </p>
                </div>
            </div>
            <div class="equip_box">
                <div class="line_before">
                    <p class="equip_txt mb-2">Frequency Details</p>
                </div>
                <div class="row">
                    <p class="col-md-3">
                        <span class="fs_16 txt_lb d-block mb-1">Monthly Frequency</span>
                        <span class="fs_18 txt_b">{{laneDetail?.monthlyFrequency ? laneDetail?.monthlyFrequency: '-'}}</span>
                    </p>


                    <div class="col-md-6 circle_box">
                        <p class="fs_16 fsn_4 txt_lb mb-2"><span>Weekly Frequency</span> </p>
                       <div class="latters_circles">
                        <div class="circles" *ngFor="let weekNameSort of fequeryMonthShow" [ngClass]="checkday(this.laneDetail?.frequencyDays, weekNameSort?.day) ? 'active_circle' : '' " ><span>{{weekNameSort?.dayAbbr}}</span></div>                              
                       </div>
                    </div>

                    <p class="col-md-3">
                        <span class="fs_16 txt_lb d-block mb-1">Lane Duration</span>
                        <span class="fs_18 txt_b">{{laneDetail?.lockPeriod ? laneDetail?.lockPeriod > 1 ? laneDetail?.lockPeriod + ' Months':laneDetail?.lockPeriod + ' Month': '-'}}</span>
                    </p>
                </div>
            </div>
            <div class="equip_box" *ngIf="userType=='SHIPPER'">
                <div class="line_before">
                    <p class="equip_txt mb-2">Rate Details</p>
                </div>
                <div class="row">
                    <p class="col-md-3" >
                        <span class="fs_16 txt_lb d-block mb-3">Rate Type</span>
                        <span class="fs_18 txt_b" *ngIf="laneDetail?.rateType=='1'">Quote/Bid Rate</span>
                            <span class="fs_18 txt_b" *ngIf="laneDetail?.rateType=='2'">Fixed Rate</span>
                    </p>
                    <p class="col-md-3" *ngIf="laneDetail?.rateType=='1'">
                        <span class="fs_16 txt_lb d-block mb-3">Expected Rate</span>
                        <span class="fs_18 txt_b" >{{laneDetail?.estimatedPrice ? "$"+laneDetail?.estimatedPrice : '-' }}</span>
                    </p>
                    <p class="col-md-3" *ngIf="laneDetail?.rateType=='2'">
                        <span class="fs_16 txt_lb d-block mb-3">Fixed Rate</span>
                        <span class="fs_18 txt_b">${{laneDetail?.estimatedPrice}}</span>
                    </p>
                </div>
            </div>
            <div class="equip_box" *ngIf="userType=='CARRIER' && laneDetail?.rateType=='2'">
                <div class="line_before">
                    <p class="equip_txt mb-2">Rate Details</p>
                </div>
                <div class="row">
                    <p class="col-md-3" >
                        <span class="fs_16 txt_lb d-block">Rate Type</span>
                            <span class="fs_18 txt_b" >Fixed Rate</span>
                    </p>
                    <p class="col-md-3" >
                        <span class="fs_16 txt_lb d-block">Fixed Rate</span>
                        <span class="fs_18 txt_b">${{laneDetail?.estimatedPrice}}</span>
                    </p>
                </div>
            </div>    
        </div>
        <div class="equip_box" *ngIf="skeletonLoader">
           
            <ngx-skeleton-loader [theme]="{ width: '100%', height: '25px' }" class=""
                style="margin-bottom: 20px;"></ngx-skeleton-loader>
            <ngx-skeleton-loader count="2" [theme]="{ width: '100%', height: '18px' }" class=""
                style="margin-bottom: 20px;"></ngx-skeleton-loader>
        </div>

        <div class="equip_box" *ngIf="skeletonLoader">
            <ngx-skeleton-loader [theme]="{ width: '100%', height: '25px' }" class=""
                style="margin-bottom: 20px;"></ngx-skeleton-loader>
            <ngx-skeleton-loader count="2" [theme]="{ width: '100%', height: '18px' }" class=""
                style="margin-bottom: 20px;"></ngx-skeleton-loader>
        </div>