<div class="popup_cons" *ngIf="data.openPop ==='required_Login'">
   <div class="popup_header mb-0">
      <p class="fs_20 fsm_5 txt_b line_before ope_0">Popup Title</p>
      <span class="close_popup_Icon" (click)="closePopup()">
         <img src="{{serverPathForUrl}}/portal/icon/ic_close_white.svg" class="close_Icon">
      </span>
   </div>
   <div class="poup_body text-center">
      <img src="{{serverPathForUrl}}/portal/images/before-login/laneaxis-x.png"
         class="img-fluid lane_x_logo">
      <p class="fs_20 fsm_5 txt_b mt_24 mb_24">You're on your way to connecting with {{data.type==="SHIPPER" ?
         'Shippers' :'Carriers'}}!</p>
      <p class="fs_16 fsn_4 txt_lb mb_32">Register with us to grow your network and get found for more opportunities</p>
   </div>
   <div class="popup_footer">
      <button type="button" class="btn custum_button button_success" *ngIf="data.type==='CARRIER'"
         (click)="onCreateAccount('createAccount')">Create an Account</button>
      <button type="button" class="btn custum_button button_success" *ngIf="data.type==='SHIPPER'"
         (click)="onCreateAccount('createAccount')">Create an Account</button>
      <p class="fs_16 fsm_5 mt_24">
         <span class="txt_lb">Already have an account.</span>
         <span class="txt_p pointer ms-1" (click)="onCreateAccount('singIn')">Sign In</span>
      </p>
   </div>
</div>

<div *ngIf="data?.openPop ==='eCarreirCreadits'">
   <div class="header_popup">
      <p class="fs_20 fsb_6 txt_b line_before ">Purchase Additional Safety Report Credits</p>
      <span class="close_popup_Icon" (click)="onNoClick()">
         <img src="{{serverPathForUrl}}/portal/icon/ic_close_white.svg" class="close_Icon">
      </span>
   </div>
   <div class="text-center">
      <p class="fs_16 fsn_4 txt_lb mb_24">The Shipper have no default and additional credits.</p>
      <div class="text-centerQW">
       <button type="button" class="btn custum_button button_primary fs_16 fsm_5">
         <span>
            <span class="" (click)="redirectToBuyAdditional()">Purchase Safety Report Credits</span>
         </span>
       </button>
    </div>
   </div>
</div>
<!-- e-carrier check -->

<div class="e_carrier_popup" *ngIf="data.openPop =='ecarriercheck' && eCarrierCheckData">
   <span class="close_popup_Icon pointer" (click)="closePopup()"><img src="{{serverPathForUrl}}/portal/icon/ic_close_white.svg" class="close_Icon"></span>
   <div class="">
      <div class="popup_title  mb-4">Safety Report</div>
      <div class="snapshot_status_box">
         <div class="fs_24 fw-5 txt_b">{{eCarrierCheckData?.profile?.name ? eCarrierCheckData?.profile?.name :'-'}}</div>
         <div class="fs_13 txt_lb mb-4">DOT: {{eCarrierCheckData?.profile?.dotNumber ? eCarrierCheckData?.profile?.dotNumber
            : '-'}}</div>
         <div class="s_box_perent">
            <div class="s_box bg_red">
               <div class="s_box_nu">{{eCarrierCheckData?.recentCrashesCount ? eCarrierCheckData?.recentCrashesCount :
                  '0'}}</div>
               <div class="s_box_txt">Recent Crashes</div>
            </div>

            <div class="s_box " [ngStyle]="{'background-color': selectColourLabel(eCarrierCheckData?.riskLevel)}">
               <div class="s_box_txt color_box " style="margin-top: 21px;">{{eCarrierCheckData?.riskLabel ? eCarrierCheckData?.riskLabel : '-'}}</div>
            </div>
            <div class="s_box bg_grey">
               <div class="s_box_nu" *ngIf="eCarrierCheckData?.profile?.addedDate">
                  {{eCarrierCheckData?.profile?.addedDate ? eCarrierCheckData?.profile?.addedDate : '-'}}</div>
               <div class="s_box_txt">Date Added</div>
            </div>

            <div class="s_box bg_yellow">
               <div class="s_box_nu">{{eCarrierCheckData?.matchingCompanies ? eCarrierCheckData?.matchingCompanies :'0'}}
               </div>
               <div class="s_box_txt">Related Companies</div>
            </div>

            <div class="s_box bg_green">
               <div class="s_box_nu" *ngIf="eCarrierCheckData?.profile?.isHireable==true">YES</div>
               <div class="s_box_nu" *ngIf="eCarrierCheckData?.profile?.isHireable==false">NO</div>
               <div class="s_box_txt">Authorized for Hire</div>
            </div>
            <div class="s_box bg_green">
               <div class="s_box_nu" *ngIf="eCarrierCheckData?.profile?.propertyInsurance==true">YES</div>
               <div class="s_box_nu" *ngIf="eCarrierCheckData?.profile?.propertyInsurance==false">NO</div>
               <div class="s_box_txt">Property Insurance</div>
            </div>
         </div>
      </div>

      <div class="snapshot_perset_box">
         <div class="perset_box">
            <div class="box_title">SMS Percentiles</div>
            <div class="sms_percentiles_list">
               <p class="d-flex align-items-center">
                  <span class="status_dot"
                     [ngStyle]="{'background-color': selectColour(eCarrierCheckData?.profile?.unsafeDrivingPercent | percent)}"></span>
                  <span class="fs_13 txt_b">Unsafe Driving</span>
                  <img src="\assets\icon\ecarrier_information_icon.svg" class="info_icon pointer" matTooltip="{{SMSPercentiles?.unSafeDriving}}">
               </p>
               <p class="d-flex align-items-center">
                  <span class="status_dot"
                     [ngStyle]="{'background-color': selectColour(eCarrierCheckData?.profile?.hoursOfServiceDrivingPercent | percent)}"></span>
                  <span class="fs_13 txt_b">Hours Of Service</span>
                  <img src="\assets\icon\ecarrier_information_icon.svg" class="info_icon pointer" matTooltip="{{SMSPercentiles?.hoursOfService}}">
               </p>
               <p class="d-flex align-items-center">
                  <span class="status_dot"
                     [ngStyle]="{'background-color': selectColour(eCarrierCheckData?.profile?.driverFitnessPercent | percent)}"></span>
                  <span class="fs_13 txt_b">Driver Fitness</span>
                  <img src="\assets\icon\ecarrier_information_icon.svg" class="info_icon pointer" matTooltip="{{SMSPercentiles?.driverFitness}}">
               </p>
               <p class="d-flex align-items-center">
                  <span class="status_dot"
                     [ngStyle]="{'background-color': selectColour(eCarrierCheckData?.profile?.vehicleMaintenancePercent | percent)}"></span>
                  <span class="fs_13 txt_b">Vehicle Maintenance</span>
                  <img src="\assets\icon\ecarrier_information_icon.svg" class="info_icon pointer" matTooltip="{{SMSPercentiles?.vehicleMaintenance}}">
               </p>
               <p class="d-flex align-items-center">
                  <span class="status_dot"
                     [ngStyle]="{'background-color': selectColour(eCarrierCheckData?.profile?.drugsAndAlcoholPercent | percent)}"></span>
                  <span class="fs_13 txt_b">Drugs And Alcohol</span>
                  <img src="\assets\icon\ecarrier_information_icon.svg" class="info_icon pointer" matTooltip="{{SMSPercentiles?.drugsAndAlcohol}}">
               </p>
            </div>
         </div>

         <div class="perset_box">
            <div class="box_title">Out-Of-Service Percentages</div>
            <div class="sms_percentiles_list">
               <p class="d-flex align-items-center">
                  <span class="status_dot"
                     [ngStyle]="{'background-color': selectColour(eCarrierCheckData?.profile?.vehicleOosPercent | percent)}"></span>
                  <span class="fs_13 txt_b">Vehicle OOS Percent</span>
                  <img src="\assets\icon\ecarrier_information_icon.svg" class="info_icon" matTooltip="{{outOfServicePercentages?.vehicleOOS}}">
               </p>
               <p class="d-flex align-items-center">
                  <span class="status_dot"
                     [ngStyle]="{'background-color': selectColour(eCarrierCheckData?.profile?.driverOosPercent | percent)}"></span>
                  <span class="fs_13 txt_b">Driver OOS Percent</span>
                  <img src="\assets\icon\ecarrier_information_icon.svg" class="info_icon" matTooltip="{{outOfServicePercentages?.driverOOS}}">
               </p>
               <p class="d-flex align-items-center">
                  <span class="status_dot"
                     [ngStyle]="{'background-color': selectColour(eCarrierCheckData?.profile?.hazmatOosPercent | percent)}"></span>
                  <span class="fs_13 txt_b">Hazmat OOS Percent</span>
                  <img src="\assets\icon\ecarrier_information_icon.svg" class="info_icon" matTooltip="{{outOfServicePercentages?.hazmatOOS}}">
               </p>
            </div>
         </div>

         <div class="perset_box">
            <div class="box_title">Authority & Insurance</div>
            <div class="sms_percentiles_list">
               <p class="d-flex align-items-center">
                  <span class="status_dot"  [ngClass]="eCarrierCheckData?.profile?.propertyInsurance ==true ? 'bg_green' : 'bg_grey'"></span>
                  <span class="fs_13 txt_b">Property</span>
                  <img src="\assets\icon\ecarrier_information_icon.svg" class="info_icon" matTooltip="{{authorityAndInsurance?.property}}">
               </p>
               <p class="d-flex align-items-center">
                  <span class="status_dot" [ngClass]="eCarrierCheckData?.profile?.passengerInsurance ==true ? 'bg_green' : 'bg_grey'"></span>
                  <span class="fs_13 txt_b">Passenger</span>
                  <img src="\assets\icon\ecarrier_information_icon.svg" class="info_icon" matTooltip="{{authorityAndInsurance?.passenger}}">
               </p>
               <p class="d-flex align-items-center">
                  <span class="status_dot" [ngClass]="eCarrierCheckData?.profile?.householdGoods ==true ? 'bg_green' : 'bg_grey'"></span>
                  <span class="fs_13 txt_b">Household Goods</span>
                  <img src="\assets\icon\ecarrier_information_icon.svg" class="info_icon" matTooltip="{{authorityAndInsurance?.householdGoods}}">
               </p>

            </div>
         </div>
      </div>
      <div class="vh_height">
         <div class="breakdown_details">
            <div class="breakdown_title">Cargo Vehicle Breakdown</div>
            <div class="breakdown_list">
               <div class="breakdown_list_row">
                  <span class="fs_13 txt_b">Fleet Type</span>
                  <div class="mb-2">
                  <span class="fs_13 txt_b">{{eCarrierCheckData?.profile?.cargoVehicleFleetType ?
                     eCarrierCheckData?.profile?.cargoVehicleFleetType : '-'}}</span>
                     <img *ngIf="eCarrierCheckData?.profile?.cargoVehicleFleetType=='combination' || eCarrierCheckData?.profile?.cargoVehicleFleetType=='Combination'"
                     matTooltip="Combination trucks or motorcoaches make up 70% or more of the total Power Units in this carrier's fleet." src="\assets\icon\ecarrier_information_icon.svg" class="info_icon">
                     <img *ngIf="eCarrierCheckData?.profile?.cargoVehicleFleetType=='straight' || eCarrierCheckData?.profile?.cargoVehicleFleetType=='Straight'"
                     matTooltip="Straight trucks or other vehicles constitue more than 30%  of the total Power Units in this carrier's fleet." src="\assets\icon\ecarrier_information_icon.svg" class="info_icon">
                  </div>
                  </div>
               <p class="breakdown_list_row"><span class="fs_13 txt_b">Straight Trucks</span><span
                     class="fs_13 txt_b">{{eCarrierCheckData?.profile?.cargoVehicleStraightTrucks ?
                     eCarrierCheckData?.profile?.cargoVehicleStraightTrucks : '0'}}</span></p>
               <p class="breakdown_list_row"><span class="fs_13 txt_b">Truck Tractors</span><span
                     class="fs_13 txt_b">{{eCarrierCheckData?.profile?.cargoVehicleTruckTractors ?
                     eCarrierCheckData?.profile?.cargoVehicleTruckTractors : '0'}}</span></p>
               <p class="breakdown_list_row"><span class="fs_13 txt_b">Trailers</span>
                  <span class="fs_13 txt_b">{{eCarrierCheckData?.profile?.cargoVehicleTrailers ?
                     eCarrierCheckData?.profile?.cargoVehicleTrailers : '0'}}</span>
               </p>
               <p class="breakdown_list_row"><span class="fs_13 txt_b fsb_6">Total Cargo Units</span><span
                     class="fs_13 txt_b fsb_6">{{eCarrierCheckData?.profile?.cargoVehicleTotalCargoUnits ?
                     eCarrierCheckData?.profile?.cargoVehicleTotalCargoUnits : '0'}}</span></p>
               <p class="breakdown_list_row"><span class="fs_13 txt_b fsb_6">Cargo Percentage</span><span
                     class="fs_13 txt_b fsb_6">{{eCarrierCheckData?.profile?.cargoVehicleCargoPercentage ?
                     (eCarrierCheckData?.profile?.cargoVehicleCargoPercentage | percent) : '0'}}</span></p>
            </div>
         </div>

         <div class="breakdown_details mr_0">
            <div class="breakdown_title">Driver Breakdown</div>
            <div class="breakdown_list">
               <p class="breakdown_list_row">
                  <span class="fs_13 txt_b w_33_l">Intrastate</span>
                  <span class="fs_13 txt_b w_33_c"><span ><</span>100 Miles</span>
                     <span></span>
                  <span class="fs_13 txt_b w_33_r">{{eCarrierCheckData?.profile?.driverIntrastateLt100 ?
                     eCarrierCheckData?.profile?.driverIntrastateLt100 : '0'}}</span>
                  </p>
               <p class="breakdown_list_row">
                  <span class="w_33_l"></span>
                  <span class="fs_13 txt_b w_33_c"><span >></span>100 Miles</span>
                     <span class="fs_13 txt_b w_33_r">{{eCarrierCheckData?.profile?.driverIntrastateGt100 ?
                     eCarrierCheckData?.profile?.driverIntrastateGt100 : '0'}}</span></p>
               <p class="breakdown_list_row">
                  <span class="w_33_l"></span>
                  <span class="fsb_6 fs_13 txt_b w_33_c">Total</span>
                  <span class="fs_13 txt_b fsb_6 w_33_r">{{eCarrierCheckData?.profile?.driverIntrastateTotalNumber ?
                     eCarrierCheckData?.profile?.driverIntrastateTotalNumber : '0'}}</span></p>
               <p class="breakdown_list_row">
                  <span class="fs_13 txt_b w_33_l">Interstate</span>
                  <span class="fs_13 txt_b w_33_c"><span ><</span>100 Miles</span><span
                     class="fs_13 txt_b w_33_r">{{eCarrierCheckData?.profile?.driverInterstateLt100 ?
                     eCarrierCheckData?.profile?.driverInterstateLt100 : '0'}}</span></p>
               <p class="breakdown_list_row">
                  <span class="w_33_l"></span>
                  <span class="fs_13 txt_b w_33_c"><span >></span>100 Miles</span><span
                     class="fs_13 txt_b w_33_r">{{eCarrierCheckData?.profile?.driverInterstateGt100 ?
                     eCarrierCheckData?.profile?.driverInterstateGt100 : '0'}}</span></p>
               <p class="breakdown_list_row">
                  <span class="w_33_l"></span>
                  <span class="fsb_6 txt_b fs_13 w_33_c">Total</span><span
                     class="fs_13 txt_b fsb_6 w_33_r">{{eCarrierCheckData?.profile?.driverInterstateTotalNumber ?
                     eCarrierCheckData?.profile?.driverInterstateTotalNumber : '0'}}</span></p>
               <p class="breakdown_list_row">
                  <span class="fsb_6 txt_b fs_13 w_33_l">CDL Drivers</span>
                  <span class="fs_13 txt_b fsb_6 w_33_r">{{eCarrierCheckData?.profile?.driverBreakdownTotalCdlDrivers ?
                     eCarrierCheckData?.profile?.driverBreakdownTotalCdlDrivers : '0'}}</span></p>
               <p class="breakdown_list_row">
                  <span class="fsb_6 txt_b fs_13 w_33_l">Total Drivers</span>
                  <span class="fs_13 txt_b fsb_6 w_33_r">{{eCarrierCheckData?.profile?.driverBreakdownTotalDrivers ?
                     eCarrierCheckData?.profile?.driverBreakdownTotalDrivers : '0'}}</span></p>
            </div>

         </div>
      </div>
      <div class="popup_title  mb-4">Recent Crashes (<span
            class="text_red">{{eCarrierCheckData?.recentCrashesCount ? eCarrierCheckData?.recentCrashesCount :
            '0'}}</span>)</div>
      <div class="crases_tabel" *ngFor="let crashes of eCarrierCheckData?.recentCrashes">
         <div class="crases_detail_box border_bottom">
            <div class="crases_row w_18">
               <div class="fs_13 fsb_6 txt_b" matTooltip="Crashes Date">{{(crashes?.reportDate ?  crashes?.reportDate : '-') | date: 'MMM dd, yyyy'}} </div>
               <p class="fs_13 txt_lb fsb_6" matTooltip="Crashes State">{{crashes?.reportState ? crashes?.reportState : '-'}}</p>
            </div>


            <div class="crases_row w_20">
               <div class="fs_13 fsm_5 txt_b">{{crashes?.reportId ? crashes?.reportId :'-'}}</div>
            </div>
            <div class="crases_row cras_symbol">
               <div class="one_num">
               <span  matTooltip="Fatalities"
                  class="mr-2"><span class="ms-2"></span><img
                     src="https://s3.us-east-1.amazonaws.com/staticprod.laneaxis.com/portal/images/carrier-safety-report-icons/crash_icon-10.png"  [ngClass]="crashes.fatalities ? 'dark_img_color' : 'fade_img_color'" class="mxw_33"></span>
               <span  matTooltip="Injuries"
                  class="mr-2"> <span class="ms-2"></span><img
                     src="https://s3.us-east-1.amazonaws.com/staticprod.laneaxis.com/portal/images/carrier-safety-report-icons/crash_icon-11.png" [ngClass]="crashes.injuries ? 'dark_img_color' : 'fade_img_color'" class="mxw_33"></span>
               <span  matTooltip="Hazmat Released"
                  class="mr-2"> <span class="ms-2"></span><img
                     src="https://s3.us-east-1.amazonaws.com/staticprod.laneaxis.com/portal/images/carrier-safety-report-icons/crash_icon-12.png" [ngClass]="crashes.hazmatReleased ? 'dark_img_color' : 'fade_img_color'" class="mxw_33"></span>
               <span  matTooltip="Tow Away"
                  class="mr-2">
                  <span class="ms-2"></span><img
                     src="https://s3.us-east-1.amazonaws.com/staticprod.laneaxis.com/portal/images/carrier-safety-report-icons/crash_icon-13.png" [ngClass]="crashes.towAway ? 'dark_img_color' : 'fade_img_color'" class="mxw_33"></span>
                     </div>
            </div>
            <div class="crases_row w_18">
               <div class="citation_txt">  
                  <span class="fs_13 txt_llb inner_w">Citation</span>
                  <span class="fs_13 txt_b fsm_5 ms-2">{{crashes?.citationIssuedDescription ?
                     crashes?.citationIssuedDescription : '-'}}</span>
               </div>
            </div>
         </div>
      </div>


      <div class="popup_title  mb-4 mt_20">Inspection Locations & History (<span
         class="text_red">{{eCarrierCheckData?.recentInspectionsCount ? eCarrierCheckData?.recentInspectionsCount : '0'}}</span>)</div>
      <div class="crases_tabel" *ngFor="let inspection of eCarrierCheckData?.recentInspections">
         <div class="crases_detail_box">
            <div class="crases_row w_10">
               <div class="fs_13 fsb_6 txt_b" matTooltip="Inspection State">{{inspection?.reportState ? inspection?.reportState : 'USEEEE'}}</div>
            </div>
            <div class="crases_row w_12">
               <div class="fs_13 fsb_6 txt_b" matTooltip="Inspection Date" *ngIf="inspection?.inspectionDate">{{(inspection?.inspectionDate ?
                  inspection?.inspectionDate: '-')| date: 'MMM dd, yyyy'}}
               </div>
               <p class="fs_13 txt_lb">{{inspection?.inspectionViolationUnit?.name ?
                  inspection?.inspectionViolationUnit?.name : '-'}}</p>
               <p class="fs_13 txt_b" *ngFor="let voilations of inspection?.violations?.extraViolations">{{voilations?.key ? voilations?.key : '-'}}</p>
            </div>
            <div class="crases_row cras_symbol w_60">
               <div class="icon_container">
                  <span matTooltip="Unsafe Driving BASIC Violations"><span
                        
                        class="one_num">{{inspection.violations.violationCounts.unsafeDrivingBasicViolations ?
                        inspection.violations.violationCounts.unsafeDrivingBasicViolations : ''}}</span><img
                        src="{{serverPathForUrl}}/portal/images/carrier-safety-report-icons/crash_icon_1.png" [ngClass]="inspection.violations.violationCounts.unsafeDrivingBasicViolations > 0 ? 'dark_img_color' : 'fade_img_color'"></span>
                  <span matTooltip="Hours of Service BASIC violations"><span
                       
                        class="one_num">{{inspection.violations.violationCounts.hoursOfServiceBasicViolations ?
                        inspection.violations.violationCounts.hoursOfServiceBasicViolations : ''}}</span><img
                        src="{{serverPathForUrl}}/portal/images/carrier-safety-report-icons/crash_icon-02.png"  [ngClass]="inspection.violations.violationCounts.hoursOfServiceBasicViolations > 0 ? 'dark_img_color' : 'fade_img_color'"></span>
                  <span matTooltip="Driver Fitness BASIC violations"><span
                        
                        class="one_num">{{inspection.violations.violationCounts.driverFitnessBasicViolation ?
                        inspection.violations.violationCounts.driverFitnessBasicViolation : ''}}</span><img
                        src="{{serverPathForUrl}}/portal/images/carrier-safety-report-icons/crash_icon-03.png" [ngClass]="inspection.violations.violationCounts.driverFitnessBasicViolation > 0 ? 'dark_img_color' : 'fade_img_color'"></span>
                  <span matTooltip="Drugs/Alcohol BASIC violations"><span
                        
                        class="one_num">{{inspection.violations.violationCounts.drugAndAlcoholBasicViolation ?
                        inspection.violations.violationCounts.drugAndAlcoholBasicViolation : ''}}</span><img
                        src="{{serverPathForUrl}}/portal/images/carrier-safety-report-icons/crash_icon-04.png" [ngClass]="inspection.violations.violationCounts.drugAndAlcoholBasicViolation > 0 ? 'dark_img_color' : 'fade_img_color'"></span>
                  <span matTooltip="Vehicle Maintenance BASIC violations"><span
                       
                        class="one_num">{{inspection.violations.violationCounts.vehicleMaintenance ?
                        inspection.violations.violationCounts.vehicleMaintenance : ''}}</span><img
                        src="{{serverPathForUrl}}/portal/images/carrier-safety-report-icons/crash_icon-05.png"  [ngClass]="inspection.violations.violationCounts.vehicleMaintenance > 0 ? 'dark_img_color' : 'fade_img_color'"></span>

                  <span matTooltip="VEHICLE OOS"><span
                        
                        class="one_num">{{inspection.violations.violationCounts.vehicleOOS ?
                        inspection.violations.violationCounts.vehicleOOS : ''}}</span><img
                        src="{{serverPathForUrl}}/portal/images/carrier-safety-report-icons/crash_icon-06.png" [ngClass]="inspection.violations.violationCounts.vehicleOOS > 0 ? 'dark_img_color' : 'fade_img_color'"></span>
                  <span matTooltip="DRIVER OOS"><span
                       
                        class="one_num">{{inspection.violations.violationCounts.driverOOS ?
                        inspection.violations.violationCounts.driverOOS : ''}}</span><img
                        src="{{serverPathForUrl}}/portal/images/carrier-safety-report-icons/crash_icon-07.png"  [ngClass]="inspection.violations.violationCounts.driverOOS > 0 ? 'dark_img_color' : 'fade_img_color'"></span>
                  <span matTooltip="HAZMAT OOS"><span
                        
                        class="one_num">{{inspection.violations.violationCounts.hazmatOOS ?
                        inspection.violations.violationCounts.hazmatOOS : ''}}</span>
                        <img src="{{serverPathForUrl}}/portal/images/carrier-safety-report-icons/crash_icon-08.png" [ngClass]="inspection.violations.violationCounts.hazmatOOS > 0 ? 'dark_img_color' : 'fade_img_color'">
                   </span>
                  <span matTooltip="OOS TOTAL"><span
                        class="one_num">{{inspection.violations.violationCounts.oOSTotal ?
                        inspection.violations.violationCounts.oOSTotal : ''}}</span><img
                        src="{{serverPathForUrl}}/portal/images/carrier-safety-report-icons/crash_icon-09.png" [ngClass]="inspection.violations.violationCounts.oOSTotal > 0 ? 'dark_img_color' : 'fade_img_color'"></span>
               </div>
               <div class="fmcsa_black_txt"><p class="fs_13 txt_b" *ngFor="let voilations of inspection?.violations?.extraViolations">{{voilations?.value ? voilations?.value : '-'}}</p></div>
            </div>
            <div class="crases_row w_18">
               <div class="fmcsa_btn"><a class="fs_13 fsn_4 txt_b" href="{{inspection?.fmcsaUrl}}" target="_blank">View on FMCSA</a>
               </div>

            </div>
         </div>
      </div>

   </div>
</div>

<!-- Send invitation Direct to Shipper -->
<div *ngIf="data.openPop ==='directInvitedShipper'">
   <div class="header_popup">
      <p class="fs_20 fsb_6 txt_b line_before " style="border-left: 5px solid var(--clBlua);">Shipper Referral
         Program</p>
      <span class="close_popup_Icon" (click)="onNoClick()">
         <img src="{{serverPathForUrl}}/portal/icon/ic_close_white.svg" class="close_Icon">
      </span>
   </div>
   <div>
      <form [formGroup]="inviteFormShipper" (ngSubmit)="carrierShipperSubmit(inviteFormShipper)" autocomplete="off"
         class="mb-0 custum_input_css inputInPopup" autocomplete="off">
         <div>
            <p class="fs_14 fsn_4 txt_lb mb-4" style="font-weight: 600 !important;">Enter the LaneAxis Referral Program
               and receive 3 free invite credits.
            </p>
         </div>
         <div class="inviteDriver_popQ p_0">
            <div class="" style="margin: 0 -24px; padding: 0 24px;">
               <div class="row">
                  <div class="col-md-6">
                     <p class="fs_14 fsn_4 txt_lb mb-1">Shipping Company Name <span class="txt_d"> *</span></p>
                     <mat-form-field appearance="none">
                        <input matInput formControlName="companyName" placeholder="Enter Shipping Company Name"
                           maxlength="65" class="form-control custom_input">
                        <mat-error *ngIf="userSubmitted && inviteFormShipper.get('companyName').errors?.['required']"
                           class="error_mess">Shipping company name is required.</mat-error>
                        <mat-error *ngIf="inviteFormShipper.get('companyName').errors?.['pattern']" class="error_mess">
                           Please enter valid information.</mat-error>
                        <mat-error *ngIf="inviteFormShipper.get('companyName').errors?.['maxlength']"
                           class="error_mess">
                           Only 64 characters are allowed.</mat-error>
                     </mat-form-field>
                  </div>
                  <div class="col-md-6">
                     <p class="fs_14 fsn_4 txt_lb mb-1">Website Name <span class="txt_d"> *</span></p>
                     <mat-form-field appearance="none">
                        <input matInput formControlName="websiteUrl" placeholder="Enter Website Name" maxlength="65"
                           class="form-control custom_input">
                        <mat-error *ngIf="userSubmitted && inviteFormShipper.get('websiteUrl').errors?.['required']"
                           class="error_mess">Website name is required.</mat-error>
                        <mat-error *ngIf="inviteFormShipper.get('websiteUrl').errors?.['pattern']" class="error_mess">
                           Enter only website url.</mat-error>
                        <mat-error *ngIf="inviteFormShipper.get('websiteUrl').errors?.['maxlength']" class="error_mess">
                           Only 256 characters are allowed.</mat-error>
                     </mat-form-field>
                  </div>
                  <div class="col-md-6">
                     <p class="fs_14 fsn_4 txt_lb mb-1">First Name <span class="txt_d"> *</span></p>
                     <mat-form-field appearance="none">
                        <input matInput formControlName="firstName" placeholder="Enter First Name" maxlength="65"
                           class="form-control custom_input">
                        <mat-error *ngIf="userSubmitted && inviteFormShipper.get('firstName').errors?.['required']"
                           class="error_mess">First name is required.</mat-error>
                        <mat-error *ngIf="inviteFormShipper.get('firstName').errors?.['pattern']"
                           class="error_mess">Please
                           enter valid information.</mat-error>
                        <mat-error *ngIf="inviteFormShipper.get('firstName').errors?.['maxlength']"
                           class="error_mess">Only
                           64 characters are allowed.</mat-error>
                     </mat-form-field>
                  </div>
                  <div class="col-md-6">
                     <p class="fs_14 fsn_4 txt_lb mb-1">Last Name <span class="txt_d"> *</span></p>
                     <mat-form-field appearance="none">
                        <input matInput formControlName="lastName" placeholder="Enter Last Name" maxlength="65"
                           class="form-control custom_input">
                        <mat-error *ngIf="userSubmitted && inviteFormShipper.get('lastName').errors?.['required']"
                           class="error_mess">Last name is required.</mat-error>
                        <mat-error *ngIf="inviteFormShipper.get('lastName').errors?.['pattern']"
                           class="error_mess">Please
                           enter valid information.</mat-error>
                        <mat-error *ngIf="inviteFormShipper.get('lastName').errors?.['maxlength']"
                           class="error_mess">Only
                           64 characters are allowed.</mat-error>
                     </mat-form-field>
                  </div>
                  <div class="col-md-6">
                     <p class="fs_14 fsn_4 txt_lb mb-1">Email Address <span class="txt_d"> *</span></p>
                     <mat-form-field appearance="none">
                        <input matInput formControlName="email" placeholder="Enter Email Address" maxlength="129"
                           class="form-control custom_input">
                        <mat-error *ngIf="userSubmitted && inviteFormShipper.get('email').errors?.['required']"
                           class="error_mess"> Email is required.</mat-error>
                        <mat-error *ngIf="inviteFormShipper.get('email').errors?.['pattern']" class="error_mess"> Please
                           enter valid email address.</mat-error>
                        <mat-error
                           *ngIf="(inviteFormShipper.get('email').errors?.['maxlength']) && !(inviteFormShipper.get('email').errors?.['pattern'])"
                           class="error_mess">Email must not exceed 128 characters.</mat-error>
                     </mat-form-field>
                  </div>
                  <div class="selection_optionZQ custom_checkbox col-md-6">
                     <p class="fs_14 fsn_4 txt_lb mb-0">Contact Number <span class="txt_d"> *</span> <img
                           [src]="defualtPickupCountryFlag" name="cla" width="auto" height="20px"
                           style="margin-left: 5px;"></p>
                     <div class="num_country_code ic_dropdown">
                        <mat-form-field appearance="none" class="frm_cunty_code">
                           <div class="country_code_fld">
                              <mat-select formControlName="countryCode"
                                 (selectionChange)="selectPickCountryCode($event.value)"
                                 class="form-control custom_input">
                                 <mat-option *ngFor="let cou of countryList" [value]="cou.countryAbbr">
                                    <img [src]="cou.flag" name="cla" width="auto" height="20px">
                                    <span style="margin-left: 5px;">+{{cou.countryCode}}</span>
                                 </mat-option>
                              </mat-select>
                              <mat-error
                                 *ngIf="userSubmitted && inviteFormShipper.get('countryCode').errors?.['required']"
                                 class="error_mess">Country code is required.</mat-error>
                           </div>
                        </mat-form-field>
                        <mat-form-field appearance="none">
                           <input matInput formControlName="phone" placeholder="Enter Contact Number (no spaces)"
                              class="form-control custom_input" minlength="10" maxlength="10"
                              (keypress)="keyPress($event)" (input)="keyPasteShipper($event)">
                           <mat-error *ngIf="userSubmitted && inviteFormShipper.get('phone').errors?.['required']"
                              class="error_mess">Phone number is required.</mat-error>
                           <mat-error *ngIf="inviteFormShipper.get('phone').errors?.['minlength']"
                              class="error_mess">Min
                              length should be 10 digit.</mat-error>
                           <mat-error *ngIf="inviteFormShipper.get('phone').errors?.['maxlength']"
                              class="error_mess">Max
                              length 10 digit.</mat-error>
                           <mat-error
                              *ngIf="(inviteFormShipper.get('phone').errors?.['pattern']) && !(inviteFormShipper.get('phone').errors?.['minlength'])"
                              class="error_mess">Phone number should be number.</mat-error>
                        </mat-form-field>
                     </div>
                  </div>
               </div>
            </div>
            <div class="text-end mt-2">
               <button type="submit" class="btn custum_button button_primary fs_16 fsm_5 w-100 mb-3"
                  style="height: 40px;">Submit Invitation</button>
               <span class="fs_16 fsm_5 txt_lb pointer  px-4 py-3 me-4 w-100 text-center d-block" type="submit"
                  (click)="onNoClick()" style="height: 40px;">Cancel</span>
            </div>
         </div>
      </form>
   </div>
</div>

<div *ngIf="data.openPop ==='directInvitedCarrierRefferal' || data.openPop ==='directInvitedCarrier'">
   <div class="header_popup">
      <p class="fs_20 fsb_6 txt_b line_before " style="border-left: 5px solid var(--clBlua);"
         *ngIf="userType == 'CARRIER'">Carrier Referral Program</p>
      <p class="fs_20 fsb_6 txt_b line_before " style="border-left: 5px solid var(--clBlua);"
         *ngIf="userType == 'SHIPPER'">Invite Carrier</p>
      <span class="close_popup_Icon" (click)="onNoClick()">
         <img src="{{serverPathForUrl}}/portal/icon/ic_close_white.svg" class="close_Icon">
      </span>
   </div>
   <div>
      <form [formGroup]="inviteFormCarrier" (ngSubmit)="shipperCarrierSubmit(inviteFormCarrier)" autocomplete="off"
         class="mb-0 custum_input_css inputInPopup" autocomplete="off">
         <div *ngIf="userType == 'CARRIER'">
            <p class="fs_14 fsn_4 txt_lb mb-4" style="font-weight: 600 !important;">Enter the LaneAxis Referral Program
               and receive 3 free invite credits.</p>
         </div>
         <div class="inviteDriver_popQ" id='inviteDriver_popQ'>
            <div class="row" style="margin: 0 -24px; padding: 0 24px;">
               <div class="col-md-6">
                  <p class="fs_14 fsn_4 txt_lb mb-1">Carrier Company Name <span class="txt_d"> *</span></p>
                  <mat-form-field appearance="none">
                     <input matInput formControlName="companyName" placeholder="Enter Carrier Company Name"
                        maxlength="65" class="form-control custom_input">
                     <mat-error *ngIf="userSubmitted && inviteFormCarrier.get('companyName').errors?.['required']"
                        class="error_mess">Company name is required.</mat-error>
                     <mat-error *ngIf="inviteFormCarrier.get('companyName').errors?.['pattern']" class="error_mess">
                        Please enter valid information.</mat-error>
                     <mat-error *ngIf="inviteFormCarrier.get('companyName').errors?.['maxlength']" class="error_mess">
                        Only 64 characters are allowed.</mat-error>
                  </mat-form-field>
               </div>
               <div class="col-md-6">
                  <p class="fs_14 fsn_4 txt_lb mb-1">First Name <span class="txt_d"> *</span></p>
                  <mat-form-field appearance="none">
                     <input matInput formControlName="firstName" placeholder="Enter First Name" maxlength="65"
                        class="form-control custom_input">
                     <mat-error *ngIf="userSubmitted && inviteFormCarrier.get('firstName').errors?.['required']"
                        class="error_mess">First name is required.</mat-error>
                     <mat-error *ngIf="inviteFormCarrier.get('firstName').errors?.['pattern']" class="error_mess">Please
                        enter valid information.</mat-error>
                     <mat-error *ngIf="inviteFormCarrier.get('firstName').errors?.['maxlength']" class="error_mess">Only
                        64 characters are allowed.</mat-error>

                  </mat-form-field>
               </div>
               <div class="col-md-6">
                  <p class="fs_14 fsn_4 txt_lb mb-1">Last Name <span class="txt_d"> *</span></p>
                  <mat-form-field appearance="none">
                     <input matInput formControlName="lastName" placeholder="Enter Last Name" maxlength="65"
                        class="form-control custom_input">
                     <mat-error *ngIf="userSubmitted && inviteFormCarrier.get('lastName').errors?.['required']"
                        class="error_mess">Last name is required.</mat-error>
                     <mat-error *ngIf="inviteFormCarrier.get('lastName').errors?.['pattern']" class="error_mess">Please
                        enter valid information.</mat-error>
                     <mat-error *ngIf="inviteFormCarrier.get('lastName').errors?.['maxlength']" class="error_mess">Only
                        64 characters are allowed.</mat-error>
                  </mat-form-field>
               </div>
               <div class="col-md-6">
                  <p class="fs_14 fsn_4 txt_lb mb-1">Email Address <span class="txt_d"> *</span></p>
                  <mat-form-field appearance="none">
                     <input matInput formControlName="email" placeholder="Enter Email Address" maxlength="129"
                        class="form-control custom_input">
                     <mat-error *ngIf="userSubmitted && inviteFormCarrier.get('email').errors?.['required']"
                        class="error_mess"> Email is required.</mat-error>
                     <mat-error *ngIf="inviteFormCarrier.get('email').errors?.['pattern']" class="error_mess"> Please
                        enter valid email address.</mat-error>
                     <mat-error
                        *ngIf="(inviteFormCarrier.get('email').errors?.['maxlength']) && !(inviteFormCarrier.get('email').errors?.['pattern'])"
                        class="error_mess">Email must not exceed 128 characters.</mat-error>
                  </mat-form-field>
               </div>
               <div class="col-md-6">
                  <div class="selection_optionZQ custom_checkbox">
                     <p class="fs_14 fsn_4 txt_lb mb-0">Contact Number<span class="txt_d"> *</span><span
                           class="fs_14 txt_b fsm_5 filter_title ">
                           <img [src]="defualtPickupCountryFlag" name="cla" width="auto" height="20px"
                              style="margin-left: 5px;">
                        </span></p>
                     <div class="num_country_code ic_dropdown">
                        <mat-form-field appearance="none" class="frm_cunty_code">
                           <div class="country_code_fld">
                              <mat-select formControlName="countryCode"
                                 (selectionChange)="selectPickCountryCode($event.value)"
                                 class="form-control custom_input">
                                 <mat-option *ngFor="let cou of countryList" [value]="cou.countryAbbr">
                                    <img [src]="cou.flag" name="cla" width="auto" height="20px">
                                    <span style="margin-left: 5px;">+{{cou.countryCode}}</span>
                                 </mat-option>
                              </mat-select>
                              <mat-error
                                 *ngIf="userSubmitted && inviteFormCarrier.get('countryCode').errors?.['required']"
                                 class="error_mess"> Country code is required.</mat-error>
                           </div>
                        </mat-form-field>
                        <mat-form-field appearance="none">
                           <input matInput formControlName="phone" placeholder="Enter Contact Number (no spaces)"
                              class="form-control custom_input" minlength="10" maxlength="10"
                              (keypress)="keyPress($event)" (input)="keyPasteCarrier($event)">
                           <mat-error *ngIf="userSubmitted && inviteFormCarrier.get('phone').errors?.['required']"
                              class="error_mess">Phone number is required.</mat-error>
                           <mat-error *ngIf="inviteFormCarrier.get('phone').errors?.['minlength']"
                              class="error_mess">Min
                              length should be 10 digit.</mat-error>
                           <mat-error *ngIf="inviteFormCarrier.get('phone').errors?.['maxlength']"
                              class="error_mess">Max length 10 digit.</mat-error>
                           <mat-error
                              *ngIf="(inviteFormCarrier.get('phone').errors?.['pattern']) && !(inviteFormCarrier.get('phone').errors?.['minlength'])"
                              class="error_mess">Phone number should be number.</mat-error>
                        </mat-form-field>
                     </div>
                  </div>
               </div>
               <div class="col-md-6">
                  <p class="fs_14 fsn_4 txt_lb mb-1">DOT Number </p>
                  <mat-form-field appearance="none">
                     <input matInput formControlName="dotNumber" placeholder="Enter DOT Number"
                        class="form-control custom_input" maxlength="10">
                     <mat-error *ngIf="inviteFormCarrier.get('dotNumber').errors?.['pattern']" class="error_mess">DOT
                        number should be a number.</mat-error>
                  </mat-form-field>
               </div>
            </div>
            <div class="text-end mt-2">
               <button type="submit" class="btn custum_button button_primary fs_16 fsm_5 w-100 mb-3"
                  style="height: 40px;">Submit Invitation</button>
               <span class="fs_16 fsm_5 txt_lb pointer  px-4 py-3 me-4 w-100 text-center d-block" type="submit"
                  (click)="onNoClick()" style="height: 40px;">Cancel</span>
            </div>
         </div>
      </form>
   </div>
</div>

<div *ngIf="data.openPop ==='inviteCSNew'" class="invitationsCarrier">
   <div class="header_popup">
      <p class="fs_20 fsb_6 txt_b line_before " *ngIf="data.status==='INVITE'">Invite to Connect</p>
      <p class="fs_20 fsb_6 txt_b line_before " *ngIf="data.status==='REINVITE'">Reinvite to Connect</p>
      <span class="close_popup_Icon" (click)="onNoConfirmClick()">
         <img src="{{serverPathForUrl}}/portal/icon/ic_close_white.svg" class="close_Icon">
      </span>
   </div>
   <div class="v_scrollBar vertical_sh" style="max-height: 70vh;">
      <form [formGroup]="invitationForm" (ngSubmit)="inviteCarrierSubmit(invitationForm)" autocomplete="off"
         class="custum_input_css">
         <div class="doc_container">
            <div class="doc_containerl" *ngIf="data.allRecord?.profileImage">
               <img [src]="data.allRecord?.profileImage" class="img-fluid">
            </div>
            <div class="doc_containerl bg_w" *ngIf="!data.allRecord?.profileImage">
               <span class="initial_name  initial_r_44" *ngIf="userType==='SHIPPER'">{{data?.allRecord?.legalName
                  | acronymOfName}}</span>
               <span class="initial_name  initial_r_44"
                  *ngIf="userType==='CARRIER'">{{data?.allRecord?.companyName | acronymOfName}}</span>
            </div>
            <div class="doc_containerr">
               <p class="fs_18 fsb_6 txt_b mb-1 " *ngIf="userType==='CARRIER'"
                  style="text-transform: capitalize;">{{data.allRecord?.companyName ? data.allRecord?.companyName :
                  '-'}}</p>

               <p class="fs_18 fsb_6 txt_b mb-1 " *ngIf="userType==='SHIPPER'"
                  style="text-transform: capitalize;">{{data.allRecord?.legalName ? data.allRecord?.legalName : '-'}}
               </p>
               <p class="fs_14 fsn_4 mb-1 txt_lb">
                  <span class="create_date"><span
                        *ngIf="data?.allRecord?.phyCity && data?.allRecord?.phyState">{{data?.allRecord?.phyCity + ","}}
                        {{data?.allRecord?.phyState}}</span></span>
                  <span class="create_date"><span
                        *ngIf="!data.allRecord.phyCity && !data.allRecord.phyState">-</span></span>
               </p>
               <p class="fs_14 fsn_4 txt_lb mb-1">Equipment Type(s):
                  <span class="txt_b" *ngFor="let equipment of data?.allRecord?.equipmentType; let i=index">
                     {{ equipment ? equipment : '-'}}<span *ngIf="i < data?.allRecord?.equipmentType?.length-1">,
                     </span>
                  </span>
               </p>
               <p class="fs_14 fsn_4 txt_lb mb-1">Regions:
                  <span class="txt_b" *ngIf="data?.allRecord?.preferredRegions?.length=== 0">I’m open for all
                     Regions</span>
                  <span class="txt_b" *ngIf="data?.allRecord?.preferredRegions?.length > 0">
                     <span *ngFor="let regionSelect of regionSelectedData; let i = index;" class="pointer"
                        matTooltip="{{regionAddSpace(regionSelect.stateNameArray)}}">
                        {{regionSelect?.region ? regionSelect?.region : '-'}}<span
                           *ngIf="i < regionSelectedData?.length - 1">, </span>
                     </span>
                  </span>
               </p>
            </div>
         </div>
         <div>
            <div class="">
               <div class="ic_dropdown">
                  <p class="fs_14 fsn_4 txt_lb mt-3 mb-0">Have you hauled for this shipper before? <span
                        class="txt_d">*</span></p>
                  <mat-form-field appearance="none">
                     <mat-select formControlName="know" class="form-control custom_input"
                        placeholder="Have you hauled for this shipper before" autocomplete="off">
                        <mat-option value="yes" class="mat_dropdown">Yes</mat-option>
                        <mat-option value="no" class="mat_dropdown">No</mat-option>
                     </mat-select>
                     <mat-error *ngIf="submitInviation && invitationForm.get('know').errors?.['required']"
                        class="error_mess m_t2">Please select an option.</mat-error>
                  </mat-form-field>
               </div>
               <div *ngIf="data?.planDetail?.isPremium" class="custom_space my-1">
                  <div class="cus_textarea">
                     <p class="fs_14 fsn_4 txt_lb my-2">Add a note to personalize your request or to ask questions.</p>
                     <div class="mess_limit">
                        <p class="fs_14 fsn_4 txt_lb mb-0">Message (Optional)</p>
                        <p class="fs_14 fsn_4 txt_lb mb-0">200 characters</p>
                     </div>
                     <mat-form-field appearance="none">
                        <textarea matInput type="text" placeholder="Enter your message here…"
                           class="form-control custom_input" maxlength="201" rows="3" [(ngModel)]="searchModel.message"
                           #message="ngModel" [ngModelOptions]="{standalone: true}"></textarea>
                     </mat-form-field>
                  </div>
               </div>
            </div>

            <p class="custom_checkbox view_doc" *ngIf="!data?.allRecord?.contractDocument && userType==='CARRIER'"
               style="margin: -4px 0 24px;">
               <mat-checkbox [disabled]="true">
                  <span class="fs_14 fsn_4 txt_lb">The Shipper has not uploaded their contract yet.</span>
               </mat-checkbox>
            </p>
            <p class="custom_checkbox view_doc" *ngIf="data?.allRecord?.contractDocument && userType==='CARRIER'"
               style="margin: -4px 0 24px;">
               <mat-checkbox [checked]="documentCheck" (change)="checkDocument($event)">
                  <span class="fs_14 fsn_4 txt_lb">Accept contract document. Click here to</span>
                  <a *ngIf="data.allRecord.contractDocument" href="{{data.allRecord.contractDocument}}" target="_blank"
                     class="txt_p">
                     <u class="txt_p ms-1 ">view document</u>.
                  </a>
               </mat-checkbox>
            </p>
            <p class="fs_14 fsn_4 txt_lb mb-4"
               *ngIf="subscriptionData?.subscriptionCreditHistory?.defaultCredit === 0 && subscriptionData?.subscriptionCreditHistory?.additionalCredit===0 && data?.planDetail?.isPremium">
               To continue invite shippers, buy Additional Direct Invite Credits</p>
         </div>
         <div class="text-centerQW">
            <button type="submit" class="btn custum_button button_primary fs_16 fsm_5">
               <span
                  *ngIf="(subscriptionData?.subscriptionCreditHistory?.defaultCredit > 0 || subscriptionData?.subscriptionCreditHistory?.additionalCredit > 0) && ((subscriptionData?.subscriptionStatus) != 'canceled' && (data?.planDetail?.isPremium))">
                  <span *ngIf="data.status==='INVITE'" class="">Send Invitation</span>
                  <span *ngIf="data.status==='REINVITE'" class="">Send Reinvitation</span>
               </span>
               <span
                  *ngIf="(subscriptionData?.subscriptionCreditHistory?.defaultCredit === 0 && subscriptionData?.subscriptionCreditHistory?.additionalCredit===0 && data?.planDetail?.isPremium != true) || subscriptionData?.subscriptionStatus == 'canceled'"
                  (click)="gotoPriemum()">
                  <span class="">Upgrade To Carrier Community Plan</span>
               </span>
               <span
                  *ngIf="subscriptionData?.subscriptionCreditHistory?.defaultCredit === 0 && subscriptionData?.subscriptionCreditHistory?.additionalCredit===0 && data?.planDetail?.isPremium"
                  (click)="gotoPriemumAdditional()">
                  <span class="">Buy Additional Invite Credits</span>
               </span>
            </button>
         </div>
      </form>
   </div>
</div>


<div *ngIf="data.openPop ==='inviteCS' && data?.allRecord" class="accept_invitations">
   <div class="header_popup">
      <p class="fs_20 fsb_6 txt_b line_before " *ngIf="data.status==='INVITE'">Invite to Connect</p>
      <p class="fs_20 fsb_6 txt_b line_before " *ngIf="data.status==='REINVITE'">Reinvite to Connect</p>
      <span class="close_popup_Icon" (click)="onNoConfirmClick()">
         <img src="{{serverPathForUrl}}/portal/icon/ic_close_white.svg" class="close_Icon">
      </span>
   </div>
   <div class="v_scrollBar vertical_sh" style="max-height: 70vh;">
      <p class="fs_16 fsn_4 txt_lb">Build a quality network by connecting with {{userType==='SHIPPER' ? 'Carriers' :
         'Shippers'}} based in your region, run the same lanes as you, and/or have the equipment required to haul your
         freight. If this information is unclear, you can ask them in the “message” section.</p>

      <div class="doc_container">
         <div class="doc_containerl" *ngIf="data.allRecord?.profileImage">
            <img [src]="data.allRecord?.profileImage" class="img-fluid">
         </div>
         <div class="doc_containerl bg_w" *ngIf="!data.allRecord?.profileImage">
            <span class="initial_name  initial_r_44" *ngIf="userType==='SHIPPER'">{{data.allRecord?.legalName |
               acronymOfName}}</span>
            <span class="initial_name  initial_r_44" *ngIf="userType==='CARRIER'">{{data.allRecord?.companyName |
               acronymOfName}}</span>
         </div>

         <div class="doc_containerr">
            <p class="fs_18 fsb_6 txt_b mb-1 " *ngIf="userType==='CARRIER'" style="text-transform: capitalize;">
               {{data.allRecord?.companyName ? data.allRecord?.companyName : '-'}}
            </p>
            <p class="fs_18 fsb_6 txt_b mb-1 " *ngIf="userType==='SHIPPER'" style="text-transform: capitalize;">
               {{data.allRecord?.legalName ? data.allRecord?.legalName : '-'}}
               <span> <img *ngIf="data.allRecord?.subscription"
                     src="{{serverPathForUrl}}/portal/images/after-login/premium_plan_icon.png"
                     class="img-fluid verified_icon"></span>
            </p>
            <p class="fs_14 fsn_4 mb-1 txt_lb">
               <span class="create_date"><span
                     *ngIf="data.allRecord.phyCity && data.allRecord.phyState">{{data.allRecord?.phyCity + ","}}
                     {{data.allRecord?.phyState}}</span></span>
               <span class="create_date"><span
                     *ngIf="!data.allRecord.phyCity && !data.allRecord.phyState">-</span></span>
            </p>
            <p class="fs_14 fsn_4 txt_lb mb-1">Equipment Type(s):
               <span class="txt_b" *ngFor="let equipment of data?.allRecord?.equipmentType; let i=index">
                  {{ equipment ? equipment : '-'}}<span *ngIf="i < data?.allRecord?.equipmentType?.length-1">, </span>
               </span>
            </p>
            <p class="fs_14 fsn_4 txt_lb mb-1">Regions:
               <span class="txt_b" *ngIf="data?.allRecord?.preferredRegions?.length=== 0">I’m open for all
                  Regions</span>
               <span class="txt_b" *ngIf="data?.allRecord?.preferredRegions?.length > 0">
                  <span *ngFor="let regionSelect of regionSelectedData; let i = index;" class="pointer"
                     matTooltip="{{regionAddSpace(regionSelect.stateNameArray)}}">
                     {{regionSelect.region ? regionSelect.region : '-'}}<span
                        *ngIf="i < regionSelectedData?.length - 1">, </span>
                  </span>
               </span>
            </p>
         </div>
      </div>
      <div>
         <p class="fs_14 fsn_4 txt_lb" style="margin: 20px 0 16px;">Add a note to personalize your request or to ask
            questions.</p>
         <div class="">
            <div class="cus_textarea">
               <div class="mess_limit">
                  <p class="fs_14 fsn_4 txt_lb mb-0">Message (Optional)</p>
                  <p class="fs_14 fsn_4 txt_lb mb-0">200 characters</p>
               </div>
               <mat-form-field appearance="none">
                  <textarea matInput type="text" placeholder="Enter your message here…"
                     class="form-control custom_input" maxlength="200" rows="3" [(ngModel)]="searchModel.message"
                     #message="ngModel" [ngModelOptions]="{standalone: true}"></textarea>
               </mat-form-field>
            </div>
         </div>
      </div>
      <!-- carrier side  -->
      <div>
         <p class="custom_checkbox view_doc" *ngIf="!data.allRecord.contractDocument && userType==='CARRIER'"
            style="margin: -4px 0 24px;">
            <mat-checkbox [disabled]="true">
               <span class="fs_14 fsn_4 txt_lb">The Shipper has not uploaded their contract yet.</span>
            </mat-checkbox>
         </p>

         <p class="custom_checkbox view_doc" *ngIf="data.allRecord.contractDocument && userType==='CARRIER'"
            style="margin: -4px 0 24px;">
            <mat-checkbox [checked]="documentCheck" (change)="checkDocument($event)">
               <span class="fs_14 fsn_4 txt_lb">Accept contract document. Click here to</span>
               <a *ngIf="data.allRecord.contractDocument" href="{{data.allRecord.contractDocument}}" target="_blank"
                  class="txt_p">
                  <u class="txt_p ms-1 ">view document</u>.
               </a>
            </mat-checkbox>
         </p>
      </div>

      <!-- sipper side  -->
      <div>
         <p class="custom_checkbox view_doc"
            *ngIf="!data.allRecord.coiDoc && userType==='SHIPPER' && !data.allRecord.coiExpired"
            style="margin: -4px 0 24px;">
            <mat-checkbox [disabled]="true">
               <span class="fs_14 fsn_4 txt_lb" style="white-space: break-spaces;">The Carrier has not uploaded their
                  insurance document yet.</span>
            </mat-checkbox>
         </p>
         <p class="custom_checkbox view_doc"
            *ngIf="(!data.allRecord.coiDoc || data.allRecord.coiDoc) && data.allRecord.coiExpired && userType==='SHIPPER'"
            style="margin: -4px 0 24px;">
            <mat-checkbox [disabled]="true">
               <span class="fs_14 fsn_4 txt_lb" style="white-space: break-spaces;">The Carrier insurance document has
                  expired {{data.allRecord.insuranceCertificateExpireDate | date: 'MMM dd, yyyy' }}.</span>
            </mat-checkbox>
         </p>
         <p class="custom_checkbox view_doc"
            *ngIf="!data.allRecord.coiExpired && data.allRecord.coiDoc && userType==='SHIPPER'"
            style="margin: -4px 0 24px;">
            <mat-checkbox [checked]="documentCheck" (change)="checkDocument($event)">
               <span class="fs_14 fsn_4 txt_lb">Accept Insurance document. Click here to</span>
               <a href="{{data.allRecord.certificateOfInsurance}}" target="_blank" class="txt_p">
                  <u class="txt_p ms-1 ">view document</u>.
               </a>
            </mat-checkbox>
         </p>
      </div>

      <div class="text-centerQW">
         <button type="submit" class="btn custum_button button_primary fs_16 fsm_5" (click)="sendInvite()">
            <span *ngIf="data.status==='INVITE'" class="">Send Invitation</span>
            <span *ngIf="data.status==='REINVITE'" class="">Send Reinvitation</span>
         </button>
      </div>
   </div>
</div>

<div *ngIf="data.openPop ==='accept_invitation'">
   <div class="accept_invitations">
      <div class="header_popup">
         <p class="fs_20 fsb_6 txt_b line_before ">Accept Invitation</p>
         <span class="close_popup_Icon" (click)="onNoConfirmClick()">
            <img src="{{serverPathForUrl}}/portal/icon/ic_close_white.svg" class="close_Icon">
         </span>
      </div>
      <div class="" style="margin: 0;">
         <p class="fs_16 fsn_4 txt_lb">Build a quality network by accepting invitation only with people you know.<span
               class="txt_b"></span></p>
      </div>
      <div class="doc_container">
         <div class="doc_containerl" *ngIf="data.allRecord?.profileImage">
            <img [src]="data.allRecord?.profileImage" class="img-fluid">
         </div>
         <div class="doc_containerl" *ngIf="!data.allRecord?.profileImage">
            <span *ngIf="userType==='SHIPPER'"
               class="initial_name  initial_r_44 ">{{data.allRecord?.legalName | acronymOfName}}</span>
            <span *ngIf="userType==='CARRIER'"
               class="initial_name  initial_r_44 ">{{data.allRecord?.companyName | acronymOfName}}</span>
         </div>

         <div class="doc_containerr">
            <div>
               <p class="fs_16 fsb_6 txt_b mb-1 " *ngIf="userType==='CARRIER'"> {{data.allRecord?.companyName}}
               </p>

               <p class="fs_16 fsb_6 txt_b mb-1 " *ngIf="userType==='SHIPPER'">
                  <span>{{data.allRecord?.legalName}} </span>
                  <span *ngIf="userType=='SHIPPER' && data.allRecord?.subscription!==null">
                     <img *ngIf="!data.allRecord?.subscription"
                        src="{{serverPathForUrl}}/portal/images/after-login/free_plan_icon.png"
                        class="img-fluid verified_icon">
                     <img *ngIf="data.allRecord?.subscription"
                        src="{{serverPathForUrl}}/portal/images/after-login/premium_plan_icon.png"
                        class="img-fluid verified_icon">
                  </span>
               </p>
               <p class="fs_14 fsn_4 mb-0 txt_lb">
                  <span class="create_date"><span
                        *ngIf="data.allRecord.phyCity && data.allRecord.phyState">{{data.allRecord.phyCity +
                        ","}} {{data.allRecord.phyState}}</span></span>

                  <span class="create_date"><span
                        *ngIf="!data.allRecord.phyCity && !data.allRecord.phyState"></span></span>
               </p>
            </div>
            <p *ngIf="data.allRecord?.note" class="card_mess text_truncate"><span class="pointer"
                  matTooltip="{{data.allRecord?.note}}">{{data.allRecord?.note}}</span></p>
         </div>
      </div>

      <!-- carrier side  -->
      <div class="mt-4">
         <p class="custom_checkbox view_doc" *ngIf="!data.allRecord.contractDocument && userType==='CARRIER'">
            <mat-checkbox [disabled]="true">
               <span class="fs_14 fsn_4 txt_lb">The Shipper has not uploaded their contract yet.</span>
            </mat-checkbox>
         </p>
         <p class="custom_checkbox view_doc" *ngIf="userType==='CARRIER'">
            <mat-checkbox [checked]="documentCheck" (change)="checkDocument($event)">
               <span class="fs_14 fsn_4 txt_lb">Accept contract document. Click here to</span>
               <a href="{{data.allRecord.contractDocument}}" target="_blank" class="txt_p">
                  <u class="txt_p ms-1 ">view document</u>.
               </a>
            </mat-checkbox>
         </p>
      </div>

      <!-- shipper side -->
      <div class="mt-4">
         <p class="custom_checkbox view_doc"
            *ngIf="!data.allRecord.coiDoc && userType==='SHIPPER' && !data.allRecord.coiExpired">
            <mat-checkbox [disabled]="true">
               <span class="fs_14 fsn_4 txt_lb" style="white-space: break-spaces;">The Carrier has not uploaded their
                  insurance document yet.</span>
            </mat-checkbox>
         </p>
         <p class="custom_checkbox view_doc"
            *ngIf="(!data.allRecord.coiDoc || data.allRecord.coiDoc) && data.allRecord.coiExpired && userType==='SHIPPER'">
            <mat-checkbox [disabled]="true">
               <span class="fs_14 fsn_4 txt_lb" style="white-space: break-spaces;">The Carrier insurance document has
                  expired {{data.allRecord.insuranceCertificateExpireDate | date: 'MMM dd, yyyy' }}.</span>
            </mat-checkbox>
         </p>
         <p class="custom_checkbox view_doc"
            *ngIf="userType==='SHIPPER'  && data.allRecord.coiDoc && !data.allRecord.coiExpired">
            <mat-checkbox [checked]="documentCheck" (change)="checkDocument($event)">
               <span class="fs_14 fsn_4 txt_lb">Accept Insurance document. Click here to</span>
               <a href="{{data.allRecord.coiDoc}}" target="_blank" class="txt_p">
                  <u class="txt_p ms-1 ">view document</u>.
               </a>
            </mat-checkbox>
         </p>
      </div>

      <p class="fs_14 fsn_4 txt_db mb-0 text-center"
         *ngIf="userType === 'CARRIER' && data.allRecord?.contractData?.carrierAccepted === '0' && data.allRecord?.contractData?.shipperAccepted === '1'">
         <i>* {{data.allRecord.companyName}} has accepted your insurance document.</i>
      </p>

      <p class="fs_14 fsn_4 txt_db mb-0 text-center"
         *ngIf="userType === 'SHIPPER' && data.allRecord?.contractData?.carrierAccepted === '1' && data.allRecord?.contractData?.shipperAccepted === '0'">
         <i>* {{data.allRecord.legalName}} has accepted your contract document.</i>
      </p>

      <div class="form_action text-center"
         *ngIf="((subscriptionData?.subscriptionCreditHistory?.defaultCredit > 0 || subscriptionData?.subscriptionCreditHistory?.additionalCredit > 0) && subscriptionData?.isPremium ) || userType==='SHIPPER'">
         <button type="submit" (click)="acceptRequest()" class="btn custum_button button_success fs_16 fsm_5"
            style="padding: 0 16%;">Accept</button>
      </div>

      <div class="form_action text-center"
         *ngIf="subscriptionData?.subscriptionCreditHistory?.defaultCredit === 0 && subscriptionData?.subscriptionCreditHistory?.additionalCredit===0 && subscriptionData?.isPremium ">
         <button type="submit" (click)="gotoPriemumAdditional()" class="btn custum_button button_primary fs_16 fsm_5"
            style="padding: 0 16%;">Add More Invite Credits</button>
      </div>

      <div class="form_action text-center" *ngIf="!subscriptionData?.isPremium && subscriptionData">
         <button type="submit" (click)="gotoPriemum()" class="btn custum_button button_primary fs_16 fsm_5 "
            style="padding: 0 16%;">Upgrade to Premium </button>
      </div>
   </div>
</div>

<div *ngIf="data.openPop ==='acceptContract'">
   <div *ngIf="!documentType">
      <div class="header_popup">
         <p class="fs_20 fsb_6 txt_b line_before ">{{data.title}}</p>
         <span class="close_popup_Icon" (click)="onNoConfirmClick()">
            <img src="{{serverPathForUrl}}/portal/icon/ic_close_white.svg" class="close_Icon">
         </span>
      </div>
      <div class="" style="margin: 0;">
         <p *ngIf="userType==='CARRIER'" class="fs_16 fsn_4 txt_lb" style="line-height: 24px;">You must accept contract
            from <span class="txt_b">{{data.name}}</span> before shipment can commence.</p>
         <p *ngIf="userType==='SHIPPER'" class="fs_16 fsn_4 txt_lb" style="line-height: 24px;">You must accept <span
               class="txt_b">{{data.name}}</span> insurance document before shipment can commence.</p>
      </div>
      <div class="doc_container">
         <div class="doc_containerl">
            <img src="{{serverPathForUrl}}/portal/images/tmp/demo_img1.png" class="img-fluid">
         </div>
         <div class="doc_containerr" *ngIf="userType==='CARRIER'">
            <a href="{{data.documentUrl}}" target="_blank">
               <p class="fs_14 fsm_5 txt_b m-0">Shipper Contract</p>
               <p class="fs_12 fsn_4 mb-0 txt_lb">
                  <span class="create_date">Uploaded on {{data.date | date: 'MMM dd, yyyy' }}</span>
               </p>
            </a>
         </div>
         <div class="doc_containerr" *ngIf="userType==='SHIPPER'">
            <a href="{{data.documentUrl}}" target="_blank">
               <p class="fs_14 fsm_5 txt_b m-0">certificate-of-insurance.pdf</p>
               <p class="fs_12 fsn_4 txt_lb m-0">
                  <span class="create_date" *ngIf="">Uploaded on {{data.date | date: 'MMM dd, yyyy' }}</span>
                  <span class="expire_date">
                     <span *ngIf="data.coiExpired">Expired </span>
                     <span *ngIf="!data.coiExpired">Expires </span>At {{data.expiredDate | date: 'MMM dd, yyyy' }}
                  </span>
               </p>
            </a>
         </div>
      </div>
      <div class="contract_action">
         <div *ngIf="!data.coiExpired" class="contract_actionl" (click)="acceptDoc()">
            <span class="material-icons txt_s fs_14 me-1">check_circle</span>
            <span class="fs_14 fsm_5 txt_s ">Accept</span>
         </div>
         <div *ngIf="data.coiExpired" class="contract_actionl disabled">
            <span class="material-icons txt_s fs_14 me-1">check_circle</span>
            <span class="fs_14 fsm_5 txt_s ">Accept</span>
         </div>
      </div>
   </div>
   <div class="inviteDriver_con" *ngIf="documentType">
      <div class="text-center" style="margin: 0; padding: 1rem;">
         <span class="material-icons"
            style="margin: 0 0 1.5rem;font-size: 48px;color: var(--clGrna);">check_circle</span>
         <p class="fs_20 fsb_6 txt_b " style="margin: 0 0 2rem;">Congratulations</p>
         <p class="fs_16 fsn_4 txt_lb" style="margin: 0 0 2rem; line-height: 24px;">
            You have accepted <span class="txt_b">{{data.name}}</span>
            <span *ngIf="userType==='SHIPPER'"> COI.</span><span *ngIf="userType==='CARRIER'">contract</span> Now you
            can start shipment with <span class="txt_b">{{data.name}}</span>
         </p>
         <div class="text-center">
            <button type="button" class="btn custum_button button_success w-100 text-capitalize fs_16 fsm_5"
               (click)="onSuccess()"
               style="margin: 0 0 1.5rem; height: 40px; border-radius: 30px; box-shadow: none;">OK</button>
         </div>
      </div>
   </div>
</div>

<div *ngIf="data.openPop ==='acceptContractDoc'">
   <div *ngIf="!documentType">
      <div class="header_popup">
         <p class="fs_20 fsb_6 txt_b line_before ">{{data.title}}</p>
         <span class="close_popup_Icon" (click)="onNoConfirmClick()">
            <img src="{{serverPathForUrl}}/portal/icon/ic_close_white.svg" class="close_Icon">
         </span>
      </div>
      <div class="" style="margin: 0;">
         <p *ngIf="userType==='CARRIER'" class="fs_16 fsn_4 txt_lb" style="line-height: 24px;">You must accept contract
            from <span class="txt_b">{{data.name}}</span> before shipment can commence.</p>

         <p *ngIf="userType==='SHIPPER'" class="fs_16 fsn_4 txt_lb" style="line-height: 24px;">You must accept <span
               class="txt_b">{{data.name}}</span> insurance document before shipment can commence.</p>

      </div>
      <div class="doc_container">
         <div class="doc_containerl">
            <img src="{{serverPathForUrl}}/portal/images/tmp/demo_img1.png" class="img-fluid">
         </div>
         <div class="doc_containerr" *ngIf="userType==='CARRIER'">
            <a href="{{data.documentUrl}}" target="_blank">
               <p class="fs_14 fsm_5 txt_b m-0">Shipper Contract</p>
               <p class="fs_12 fsn_4 mb-0 txt_lb">
                  <span class="create_date">Uploaded on {{data.date | date: 'MMM dd, yyyy' }}</span>
               </p>
            </a>
         </div>
         <div class="doc_containerr" *ngIf="userType==='SHIPPER'">
            <a href="{{data.documentUrl}}" target="_blank">
               <p class="fs_14 fsm_5 txt_b m-0">certificate-of-insurance.pdf</p>
               <p class="fs_12 fsn_4 txt_lb m-0">
                  <span class="expire_date txt_d">
                     <span *ngIf="data.coiExpired">Expired </span>
                     <span *ngIf="!data.coiExpired">Expires </span>
                     At {{data.expiredDate | date: 'MMM dd, yyyy' }}
                  </span>
               </p>
            </a>
         </div>
      </div>

      <p *ngIf="userType==='SHIPPER'" class="fs_12 fsn_4 txt_lb" style="line-height: 24px;">Send a message to
         {{data.name}}, Inc. and request to upload an updated insurance document.</p>
   </div>
</div>

<div *ngIf="data.openPop ==='confirmConnectionPopup'">
   <div class="" style="margin: 0;" *ngIf="disableDriver">
      <p class="fs_20 fsb_6 txt_b  line_before">{{data.title}}</p>
      <p class="fs_16 fsn_4 txt_lb" style="margin: 0 0 2rem; line-height: 24px;">{{data.contain}}</p>
      <div class="text-center">
         <button type="submit" class="btn custum_button button_primary w-100 fs_16 fsm_5"
            (click)="onConfirmationClick()"
            style="margin: 0 0 1.5rem; height: 40px; border-radius: 30px; box-shadow: none;">{{data.buttontext}}</button>
         <span class="fs_16 fsm_5 txt_lb pointer  px-4 py-2" (click)="onNoConfirmClick()">Cancel</span>
      </div>
   </div>
</div>
<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '14px',fullScreenBackdrop:true }"></ngx-loading>