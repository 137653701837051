<div *ngIf="loadDetail?.drivers && !skeletonLoader">
    <div>
       <div class="detail_card_con">
          <div class="dri_card_head">
             <p class="line_before mb-0">Assigned Driver</p>
             <p class="fs_14 fsm_5 txt_p pointer mb-0"
                *ngIf="userType==='CARRIER' && loadDetail.status < 51 && loadDetail.status!==15">
                <u (click)="shipmentAssignDriver(loadDetail)">Change Driver</u>
             </p>
          </div>
          <div class="dri_profile">
             <div class="dri_name_img pointer" (click)="eventActionProfileSidePanel(loadDetail,'profile_side_panel')">
                <span class="dri_img" *ngIf="loadDetail?.drivers?.profileImage">
                   <img src="{{loadDetail?.drivers?.profileImage}}" class="img-fluid">
                </span>
                <span class="dri_img" *ngIf="!loadDetail?.drivers?.profileImage">
                   <span class="initial_name initial_r_44">{{loadDetail?.drivers?.name |
                      acronymOfName}}</span>
                </span>
                <p class="dri_name" *ngIf="loadDetail?.drivers?.userId !== userId">
                   {{loadDetail?.drivers?.name }}</p>
                <p class="dri_name" *ngIf="loadDetail?.drivers?.userId === userId">You</p>
             </div>
             <div class="dri_profile_r">
 
                <div class="driver_dtl_rating">
                   <app-rating-view [rate]="loadDetail?.drivers?.ratingReview?.rate"
                      [ratingReview]="loadDetail?.drivers?.ratingReview" [ratingOwnerDetail]="ratingOwnerDetail"
                      [shipmentDetail]="loadDetail" *ngIf="loadDetail?.drivers?.ratingReview?.rate">
                   </app-rating-view>
                </div>
 
                <div *ngIf="userType==='CARRIER' && loadDetail?.drivers?.userId !== userId" class="ml_24">
                   <!-- -->
                   <p class="ship_message m-0 pointer"
                      *ngIf="loadDetail?.drivers?.userId && loadDetail?.drivers && loadDetail?.parentStatus !== 1 && loadDetail?.parentStatus !== 3"
                      [routerLink]="['/messages/driver',loadDetail?.drivers?.userId]">
                      <img
                         src="{{serverPathForUrl}}/portal/images/after-login/cl-icons/ic-message-blue.svg"
                         class="me-1">
                      <span class="fs_14 fsm_5 txt_p">Message</span>
                   </p>
                </div>
             </div>
          </div>
          <div class="dir_othe_details">
             <div class="dod_1">
                <div class="row_label mb-1">Current Location</div>
                <div class="row_value" *ngIf="currentlocation">{{currentlocation.fullAddress}}</div>
                <div class="row_value" *ngIf="!currentlocation">-</div>
             </div>
             <div class="dod_2">
                <div class="row_label mb-1">Miles Completed</div>
                <div *ngIf="gifLoader !== false">
                   <img src="{{serverPathForUrl}}/portal/images/after-login/LaneAxis-loader.gif"
                      style="max-height: 16px;">
                </div>
                <div class="row_value" *ngIf="loadDetail?.tripStatus">
                   {{loadDetail?.tripStatus?.milesCompleted}} Miles
                </div>
                <div class="row_value" *ngIf="!loadDetail?.tripStatus">0 Miles</div>
             </div>
             <div class="dod_3">
                <div class="row_label mb-1">ETA </div>
                <div *ngIf="gifLoader !== false">
                   <img src="{{serverPathForUrl}}/portal/images/after-login/LaneAxis-loader.gif"
                      style="max-height: 16px;">
                </div>
                <div class="row_value" *ngIf="statusInformation">
                   <span>{{statusInformation | date: 'MMM dd, yyyy'}}</span>
                   <span class="ms-2">{{statusInformation | date: 'HH:mm:ss'}}</span>
                </div>
                <div *ngIf="!statusInformation">
                   <span>-</span>
                </div>
             </div>
          </div>
          <div class="dir_othe_details">
             <div class="dod_1" *ngIf="userType==='CARRIER'">
                <div class="row_label mb-1">Contact Number</div>
                <span class="ctry_flag">
                   <img [src]="countryFlag" name="cla">
                   <div *ngIf='loadDetail?.drivers?.phone' class="row_value">
                      {{loadDetail?.drivers?.phone | phone }}
                   </div>
                   <div *ngIf='loadDetail?.drivers?.telephone' class="row_value">
                      {{loadDetail?.drivers?.telephone | phone }}
                   </div>
                </span>
                
             </div>
             <div class="dod_2">
                <div class="row_label mb-1">CDL No.</div>
                <div class="row_value">{{(loadDetail?.drivers?.cdlNumber &&
                   loadDetail?.drivers?.cdlNumber!=='null') ? loadDetail?.drivers?.cdlNumber :
                   '-'}}
                </div>
             </div>
             <div class="dod_3">
                <div class="row_label mb-1">Equipment Type</div>
                <div class="row_value">{{checkEquipmentType(loadDetail?.drivers?.equipmentName)}}
                </div>
             </div>
          </div>
       </div>
    </div>
 </div>
 <div *ngIf="(!skeletonLoader) && (loadDetail?.drivers === '' || loadDetail?.drivers === null)">
    <div class="Empty_State_body">
       <div class="Empty_State_con">
          <div class="Empty_State_img">
             <img class="img-fluid"
                src="{{serverPathForUrl}}/portal/images/after-login/Empty_State_icons/gr-assign-driver.svg">
          </div>
          <div *ngIf="userType === 'SHIPPER'">
             <p class="fs_24 fsb_6 txt_b pb-1" style="margin: 30px 0 12px">No Driver
                Assigned <span class="txt_p"> Yet</span>
             </p>
             <p class="fs_16 fsn_4 txt_lb pb-1" style="margin: 0px 0 20px">Shipment is not
                assigned
                to any driver yet.
             </p>
          </div>
          <div *ngIf="userType === 'CARRIER'">
             <p class="fs_24 fsb_6 txt_b pb-1" style="margin: 30px 0 12px">No Driver
                Assigned<span class="txt_p"> Yet</span>
             </p>
             <p class="fs_16 fsn_4 txt_lb pb-1" style="margin: 0px 0 20px">You have either not
                added any drivers or all added drivers are currently assigned to loads. If you are also a
                driver, You have the option of assigning the load to yourself.
             </p>
          </div>
          <div class="text-center" *ngIf="userType === 'CARRIER'">
             <button [disabled]="checkAssignDriver()" class="btn custum_button button_primary" (click)="shipmentAssignDriver(loadDetail)">Assign
                Driver</button>
          </div>
       </div>
    </div>
 </div>
 <div class="" style="position: relative;" *ngIf="sidePanelProfileDataId">
    <div class="side-show-panel">
      <div class="side_bar_con">
        <app-driver-side-panel [sidePanelProfileData]="sidePanelProfileData" [shipmentDetail]="loadDetail"  [sidePanelProfileDataId]="sidePanelProfileDataId" [userType]="userType" 
        (closeSidePanel)="closePanel($event)"></app-driver-side-panel>
      </div>
    </div>
  </div>
  