import {Component, OnChanges, SimpleChanges,Input,AfterViewInit,Output} from '@angular/core';
import { AppSettings } from '../../commons/setting/app_setting';
import { HttpClient } from '@angular/common/http';
import * as mapboxgl from 'mapbox-gl';

@Component({
  selector: 'app-mapbox-summary',
  templateUrl: './mapbox-summary.component.html',
  styleUrls: ['./mapbox-summary.component.css'],
})
export class MapBoxSummaryComponent implements OnChanges, AfterViewInit {
  @Input() latitude: any;
  @Input() longitude: any;
  @Input() type: any;
  @Input() origin: any;
  @Input() destination: any;
  @Input() information: any;
  @Output() isScrollZoomEnabled: any;
  public end: any;
  public start: any;
  constructor(public http: HttpClient) {
    mapboxgl.accessToken =
      'pk.eyJ1IjoibGFuZWF4aXMiLCJhIjoiY2tidnZma2VpMGEydzJ0bnFvdjdvejFmMiJ9.A8iCJZIH4tL1IlmM3mTQHA';
  }

  public ngAfterViewInit() {
    if (this.longitude && this.latitude) {
      this.showUserLocation(this.longitude, this.latitude, this.information);
    } else {
      this.currnetLoction()
    }
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (changes['information'] && !changes['information'].isFirstChange()) {
      if (changes['information']['currentValue']) {
        if (
          changes['longitude'] &&
          changes['latitude'] &&
          changes['longitude']['currentValue'] &&
          changes['latitude']['currentValue']
        ) {
          this.showUserLocation(
            changes['longitude']['currentValue'],
            changes['latitude']['currentValue'],
            changes['information']['currentValue']
          );
        } else {
          this.showUserLocation(
            this.longitude,
            this.latitude,
            changes['information']['currentValue']
          );
        }
      }
    } else {
      if (changes['longitude'] && changes['latitude']) {
        if (
          changes['longitude']['currentValue'] &&
          changes['latitude']['currentValue']
        ) {
          this.showUserLocation(
            changes['longitude']['currentValue'],
            changes['latitude']['currentValue'],
            this.information
          );
        }
      }
    }
  }
  map: mapboxgl.Map;
  showUserLocation(longitude: any, latitude: any, information: any) {
    var _this = this;
    this.map = new mapboxgl.Map({
      container: 'map',
      style: 'mapbox://styles/mapbox/streets-v11',
      center: [longitude, latitude],
      zoom: 9.15,
      scrollZoom: true,
      boxZoom: true,
      doubleClickZoom: true,
    });

    let el = document.createElement('map');

    el.className = 'marker';
    if (information.profileImage) {
      el.innerHTML += '<img  src="' + information.profileImage + '" />';
    } else if (information.name) {
      el.innerHTML = _this.getInitials(information.name);
    }

    el.style.width = '100px';
    el.style.height = '100px';

    new mapboxgl.Marker(el).setLngLat([longitude, latitude]).addTo(this.map);
    this.map.flyTo({
      center: [longitude, latitude],
      zoom: 9.15,
    });
    this.map.scrollZoom.enable();
    this.map.addControl(new mapboxgl.NavigationControl());
  }

  getInitials(name) {
    let acronym;
    if (name) {
      acronym = name
        .split(/\s/)
        .reduce((response, word) => (response += word.slice(0, 1)), '');
      if (acronym && acronym.length >= 2) acronym = acronym.substr(0, 2);
    } else acronym = '';
    return acronym;
  }

  currnetLoction() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        let mapLocation = new mapboxgl.Map({
          container: 'map',
          style: 'mapbox://styles/mapbox/streets-v10',
          center: [position.coords.longitude, position.coords.latitude],
          zoom: 9.15,
        });

        let el = document.createElement('map');

        // adding a class
        el.className = 'marker';

        //user profile image
        el.style.backgroundImage =
          'url(' + AppSettings.dashbord_Current_loc + ')';

        //of image
        el.style.width = '100px';
        el.style.height = '100px';

        // add marker to map
        new mapboxgl.Marker(el)
          .setLngLat([position.coords.longitude, position.coords.latitude])
          .addTo(mapLocation);
      });
    }
  }
}
