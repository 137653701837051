import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';
import { environment } from '../../environments/environment';
import { map } from 'rxjs/operators';

@Injectable()
export class PaymentService {
  public apiUrl: any;

  constructor(public httpService: HttpClient) {
    this.apiUrl = environment.axisTokanApiEndpoint + '/api/v1/';
  }

  async getEncryptedData(dataToEncrypt, circle): Promise<any> {
    const ciphertext = '';
    return btoa(ciphertext);
  }

  generateUniqId() {
    return uuidv4();
  }

  getPublicKey(): Observable<any> {
    return this.httpService
      .get(this.apiUrl + 'accounts/encryption-key', { withCredentials: true })
      .pipe(map((res) => {
        return res;
      }));
  }

  addcard(requestPayload) {
    let apiUrl = this.apiUrl + 'accounts/card';
    return this.httpService
      .post(apiUrl, requestPayload, { withCredentials: true })
      .pipe(map((res) => {
        return res;
      }));
  }

  withdraw(requestPayload) {
    let apiUrl = this.apiUrl + 'payouts';
    return this.httpService
      .post(apiUrl, requestPayload, { withCredentials: true })
      .pipe(map((res) => {
        return res;
      }));
  }

  addBankWire(requestPayload) {
    let apiUrl = this.apiUrl + 'accounts/banks/wire';
    return this.httpService
      .post(apiUrl, requestPayload, { withCredentials: true })
      .pipe(map((res) => {
        return res;
      }));
  }

  payment(requestPayload) {
    let apiUrl = this.apiUrl + 'payments';
    return this.httpService
      .post(apiUrl, requestPayload, { withCredentials: true })
      .pipe(map((res) => {
        return res;
      }));
  }

  getCardList(): Observable<any> {
    return this.httpService
      .get(this.apiUrl + 'accounts/card/list', { withCredentials: true })
      .pipe(map((res) => {
        return res;
      }));
  }

  getBankWires(): Observable<any> {
    return this.httpService
      .get(this.apiUrl + 'accounts/wire/list', { withCredentials: true })
      .pipe(map((res) => {
        return res;
      }));
  }

  getAddress(requestPayload) {
    let apiUrl = this.apiUrl + 'user/address';
    return this.httpService.post(apiUrl, requestPayload).pipe(map((res) => {
      return res;
    }));
  }

  getUserInfo(): Observable<any> {
    return this.httpService.get(this.apiUrl + 'user/info').pipe(map((res) => {
      return res;
    }));
  }

  getPaymentConfig(): Observable<any> {
    return this.httpService
      .get(this.apiUrl + 'contracts/fees', { withCredentials: true })
      .pipe(map((res) => {
        return res;
      }));
  }

  addMoreLoad(requestPayload) {
    let apiUrl = this.apiUrl + 'contracts/buy-loads';
    return this.httpService.post(apiUrl, requestPayload).pipe(map((res) => {
      return res;
    }));
  }

  getPrice(): Observable<any> {
    return this.httpService.get(this.apiUrl + 'contracts/fees').pipe(map((res) => {
      return res;
    }));
  }
}
