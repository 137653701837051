import { Location } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CommonService } from '../../commons/service/common.service';
import { AppSettings } from '../../commons/setting/app_setting';
import { PaymentService } from '../../payment/payment.service';
import { LoadDetailCommodityComponent } from '../load-detail-commodity/load-detail-commodity.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-load-detail',
  templateUrl: './load-detail.component.html',
  styleUrls: ['./load-detail.component.scss'],
  providers: [PaymentService],
})
export class LoadDetailComponent implements OnInit {
  @Input() skeletonLoader: any;
  @Input() loadDetail: any;
  @Input() waypoint: any;
  @Input() userType: any;
  public ngClassCondition:any;
  public pickupViewInfo: any = '';
  public dropoffViewInfo: any = '';
  public isTemprature = false;
  public pickupWayPoint: any = [];
  public dropoffWayPoint: any = [];
  public serverPathForUrl: any;

  constructor(
    public commonService: CommonService,
    public dialog: MatDialog,
    public location: Location,
    public paymentService: PaymentService,
  ) { }

  async ngOnInit() {
    this.serverPathForUrl = environment.serverPathForUrl;
    if (this.waypoint) {
      for (let shipmentIndex in this.waypoint) {
        if (this.waypoint && this.waypoint[shipmentIndex].type === 'pickup') {
          this.waypoint[shipmentIndex].date = this.getDateFormat(this.waypoint[shipmentIndex].date.toString());
          this.pickupWayPoint.push(this.waypoint[shipmentIndex]);
        }
        if (this.waypoint && this.waypoint[shipmentIndex].type === 'dropoff') {
          this.waypoint[shipmentIndex].date = this.getDateFormat(this.waypoint[shipmentIndex].date.toString());
          this.dropoffWayPoint.push(this.waypoint[shipmentIndex]);
        }
      }
    }
  } 

  getDateFormat(inputDate: any) {
    if (inputDate !== '' && typeof inputDate !== undefined) {
      return inputDate.replaceAll('-', '/');
    } else {
      return '';
    }
  }

  checkCardDAy(stday: any) {
    let daysnubmber;
    if (stday) {
      daysnubmber = stday;
    } else {
      daysnubmber = AppSettings.laneaxisTerms;
    }
    return daysnubmber;
  }

  trailSpecialRequipment(dataInfo: any) {
    if (dataInfo && dataInfo.length > 0) {
      if (dataInfo.includes('Temp-controlled')) {
        this.isTemprature = true;
      }
      return (dataInfo.toString().split(',').join(', ')).trim();
    } else {
      return '-';
    }
  }

  showDetailCommodity(detail:any) {
    const dialogRef = this.dialog.open(LoadDetailCommodityComponent, {
      disableClose: true,
      backdropClass: 'cus_backdrop_class',
      panelClass: 'cus_popup_vw',
      width: '1140px',
      data: { openPop: 'showShipmentDetail' ,shipmentDetail:detail},
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result.event === 'fail') {
      }
    });
  }

  saveAmount(bidAmount:any ,freightRate:any){
    let amount;
    if(bidAmount && freightRate){
      amount= freightRate - bidAmount;
    }else{
      amount= '-';
    }
   return amount;
  }

}
