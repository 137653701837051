import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';
import { AppSettings } from 'src/app/commons/setting/app_setting';
import { PopupComponent } from 'src/app/shared/popup/popup.component';
import { SubscriptionPopupComponent } from 'src/app/shared/subscription-popup/subscription-popup.component';

@Component({
  selector: 'app-spot-loads-card',
  templateUrl: './spot-loads-card.component.html',
  styleUrls: ['./spot-loads-card.component.css']
})
export class SpotLoadsCardComponent implements OnInit {
  @Input() shipmentRecord: any;
  @Input() currentStatus: any;
  @Input() currentRouteParams: any;
  @Input() subscriptionData: any;
  @Output() eventPassAction = new EventEmitter();
  public userType: any;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public dialog: MatDialog,
  ) { }
  
  ngOnInit() {
    this.userType = localStorage.getItem('user_type');
  }


  private getFullPath(route: ActivatedRoute): string {
    const segments = [];
    while (route) {
      if (route.snapshot.url.length) {
        segments.unshift(...route.snapshot.url.map(segment => segment.path));
      }
      route = route.parent;
    }
    
    return segments.join('/');
  }

  //View bid button function on bidding tab.
  viewBid(id:any) {
    const fullPath = this.getFullPath(this.route);
    if (this.userType == 'SHIPPER') {
      this.router.navigate([fullPath+'/load/view', id, 'loadBids']);
    }
    if (this.userType == 'CARRIER') {
      this.router.navigate([fullPath+'/load/view', id, 'details']);
    }
  }

openNewTabRecord(shipmentRecord: any) {
  const fullPath = this.getFullPath(this.route);
  if (this.currentRouteParams === 'carrierLoadBids' || this.currentRouteParams === 'loadBids') {
    if (shipmentRecord && (shipmentRecord.status === 15 || shipmentRecord.status === 18)) {
      let newCurrentStatus = 'summary';
      const url = this.router.serializeUrl(
        this.router.createUrlTree([
          fullPath+ `/load/view/${shipmentRecord.id}/${newCurrentStatus}`,
        ])
      );
      window.open(url, '_blank');
    }else if (shipmentRecord && shipmentRecord.status === 59 ) {
      let newCurrentStatus = 'details';
      const url = this.router.serializeUrl(
        this.router.createUrlTree([
          fullPath+ `/load/view/${shipmentRecord.id}/${newCurrentStatus}`,
        ])
      );
      window.open(url, '_blank');
    } else {
      const url = this.router.serializeUrl(
        this.router.createUrlTree([
          fullPath+ `/load/view/${shipmentRecord.id}/${this.currentRouteParams}`,
        ])
      );
      window.open(url, '_blank');
    }

  } else {
    if (shipmentRecord &&  shipmentRecord.status === 15) {
      let newCurrentStatus = 'summary';
      const url = this.router.serializeUrl(
        this.router.createUrlTree([
          fullPath+`/load/view/${shipmentRecord.id}/${newCurrentStatus}`,
        ])
      );
      window.open(url, '_blank');
    }else if (shipmentRecord && shipmentRecord.status === 59) {
      let newCurrentStatus = 'details';
      const url = this.router.serializeUrl(
        this.router.createUrlTree([
          fullPath+`/load/view/${shipmentRecord.id}/${newCurrentStatus}`,
        ])
      );
      window.open(url, '_blank');
    }else if (shipmentRecord && (this.currentRouteParams === 'allLoads' || this.currentRouteParams === 'allLoadsByLane' || this.currentRouteParams === 'loadsLostByLane' || this.currentRouteParams === 'loadsLost')) {
      let newCurrentStatus = 'details';
      const url = this.router.serializeUrl(
        this.router.createUrlTree([
          fullPath+ `/load/view/${shipmentRecord.id}/${newCurrentStatus}`,
        ])
      );
      window.open(url, '_blank');
    } else if (shipmentRecord && (this.currentRouteParams === 'quotedLoads' || this.currentRouteParams === 'paymentPending' || this.currentRouteParams === 'quotedLoadsByLane' 
    || this.currentRouteParams === 'paymentPendingByLane' || this.currentRouteParams === 'loadsAwardedByLane')) {
      let newCurrentStatus;
      if (this.userType == 'SHIPPER') {
        newCurrentStatus = 'loadBids'
      }
      if (this.userType == 'CARRIER') {
        newCurrentStatus = 'carrierLoadBids'
      }
      const url = this.router.serializeUrl(
        this.router.createUrlTree([
          fullPath+`/load/view/${shipmentRecord.id}/${newCurrentStatus}`,
        ])
      );
      window.open(url, '_blank');
    }
     else {
      const url = this.router.serializeUrl(
        this.router.createUrlTree([
          fullPath+`/load/view/${shipmentRecord.id}/${this.currentRouteParams}`,
        ])
      );
      window.open(url, '_blank');
    }
  }
}

checkEditShipmentCondition() {
  if (this.userType === 'SHIPPER' && this.shipmentRecord && this.shipmentRecord.shippers &&
    (this.shipmentRecord.statusSlug === 'readyToBid' || this.shipmentRecord.statusSlug === 'offerRate' ||
       this.currentStatus === 'allLoads' || this.currentStatus === 'quotedLoads' || this.currentStatus === 'paymentPending' || this.currentStatus === 'expiredLoads' || this.currentStatus === 'expiredLoadsByLane')) {
    return true;
  } else if (this.userType === 'CARRIER' && this.shipmentRecord && this.shipmentRecord.carriers && !this.shipmentRecord.shippers &&
    (this.currentStatus === 'allLoads' || this.currentStatus === 'quotedLoads' || this.currentStatus === 'paymentPending' || this.currentStatus === 'expiredLoads' || this.currentStatus === 'expiredLoadsByLane')) {
    return true;
  } else {
    return false;
  }
}

editShipment($event, id: any) {
  if (this.userType == 'SHIPPER' && (this.subscriptionData?.isPremium != true || this.subscriptionData?.subscriptionStatus == 'canceled'))
  {
    this.getSubscriptionAlertPopup();
  } 
  else {
    $event.stopPropagation();
    this.router.navigate(['/load/edit', id]);
  }
}

getSubscriptionAlertPopup(){
  const dialogRef = this.dialog.open(SubscriptionPopupComponent, {
    disableClose: true,
    backdropClass: AppSettings.backdropClass,
    width: AppSettings.popWidth,
    data: { 
      openPop: 'subscriptionAlert',
      subscriptionData: this.subscriptionData
     },
  });
  dialogRef.afterClosed().subscribe((result) => {
  });
}


  // Multiple event for sidepanel.
  eventAction(event: any, type: any) {
  const fullPath = this.getFullPath(this.route);
    if (this.userType === 'SHIPPER') {
    if (
      this.shipmentRecord.statusSlug !== 'allLoads'
    ) {
      this.eventPassAction.emit({ event, type });
    } else if (
      this.shipmentRecord.statusSlug === 'dispute' ||
      this.shipmentRecord.statusSlug === 'disputeResolved'
    ) {
      if (
        this.currentStatus === 'confirmedBids' ||
        this.currentStatus === 'offerRateConfirmed' ||
        this.currentStatus === 'offerRate'
      ) {
        this.router.navigate(
          [fullPath+'load/view/', this.shipmentRecord.id, this.currentRouteParams],
          { queryParams: { isConfirmed: true } }
        );
      } else {
        this.router.navigate([fullPath+
          'load/view/',
          this.shipmentRecord.id,
          this.currentRouteParams,
        ]);
      }
    } else if (
      this.shipmentRecord.statusSlug === 'readyToBid' ||
      this.shipmentRecord.statusSlug === 'offerRate' ||
      this.currentStatus === 'offerRateConfirmed'
    ) {
      if (
        this.currentStatus === 'confirmedBids' ||
        this.currentStatus === 'offerRateConfirmed'
      ) {
        this.router.navigate(
          [fullPath+'load/view/', this.shipmentRecord.id, this.currentRouteParams],
          { queryParams: { isConfirmed: true } }
        );
      } else
        this.router.navigate([
          fullPath+'load/view/',
          this.shipmentRecord.id,
          this.currentRouteParams,
        ]);
    }
  }
  if (this.userType === 'CARRIER') {
   if(type == 'bid' || type == 'acceptOffer') {
        const dialogRef = this.dialog.open(PopupComponent, {
          backdropClass: AppSettings.backdropClass,
          width: AppSettings.popWidth,
          disableClose: true,
          data: {
            openPop: 'equipmentNotMatchShipment',
            contain:event
          },
        });
        dialogRef.afterClosed().subscribe((result) => {
          if (result.event == 'Ok') {
            if(type == 'acceptOffer')
          { 
             this.openDriverAlertPopup(event, type)
            }
            else 
            {
              this.eventPassAction.emit({ event, type });
            }
          }
        });
      }
    else {   
         if (
      this.shipmentRecord.statusSlug !== 'dispute' &&
      this.shipmentRecord.statusSlug !== 'disputeResolved'
    ) {
      if(type =='confirm')
     {
       this.openDriverAlertPopup( event, type )
      }
      else 
      {
        this.eventPassAction.emit({ event, type });
      }
    }
    if (
      this.shipmentRecord.statusSlug === 'dispute' ||
      this.shipmentRecord.statusSlug === 'disputeResolved'
    ) {
      this.router.navigate([
        fullPath+'load/view/',
        this.shipmentRecord.id,
        this.currentRouteParams,
      ]);
    }}
  }
}

openDriverAlertPopup(event, type)
{
  const dialogRef = this.dialog.open(PopupComponent, {
    backdropClass: AppSettings.backdropClass,
    width: AppSettings.popWidth,
    height:'600px',
    disableClose: true,
    data: {
      openPop: 'driverMobileNotification',
      contain:event
    },
  });
  dialogRef.afterClosed().subscribe((result) => {
    if (result.event == 'Ok') {
      this.eventPassAction.emit({ event, type });
    }
  });
 }

}
