import { Directive, HostListener,ElementRef } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[formControlName][appDimension]',
})
export class DimensionDirective {

  constructor(public ngControl: NgControl,private el: ElementRef) {}
  
  @HostListener('ngModelChange', ['$event'])
  onModelChange(event) {
    this.onInputChange(event, false);
  }

  @HostListener('keydown.backspace', ['$event'])
  keydownBackspace(event) {
    this.onInputChange(event.target.value, true);
  }

  onInputChange(event, backspace) {
    if(event){
      let newVal = event.replace(/\D/g, '');
      if (backspace && newVal.length <= 8) {
        newVal = newVal.substring(0, newVal.length - 1);
      }
    if (newVal.length === 0) {
      newVal = '';
    } else if (newVal.length <= 3) {
      newVal = newVal.replace(/^(\d{0,3})/, '$1');
    } else if (newVal.length <= 6) {
      newVal = newVal.replace(/^(\d{0,3})(\d{0,3})/, '$1x$2');
    } else if (newVal.length <= 9) {
      newVal = newVal.replace(/^(\d{0,3})(\d{0,3})(\d{0,3})/, '$1x$2x$3');
    } else {
      newVal = newVal.substring(0, 9);
      newVal = newVal.replace(/^(\d{0,3})(\d{0,3})(\d{0,3})/, '$1x$2x$3');
    }
    if(newVal){
      this.ngControl.valueAccessor.writeValue(newVal);
    }
   }
  }
}
