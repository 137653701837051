import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { CommonService } from '../../commons/service/common.service';
import { SharedService } from '../../commons/service/shared.service';
import { AppSettings } from '../../commons/setting/app_setting';
import { environment } from '../../../environments/environment';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { NetworkPopupComponent } from 'src/app/network-shared/network-popup/network-popup.component';
import { AlertService } from 'src/app/commons/service/alert.service';
import { StatusSetting } from 'src/app/commons/setting/status_setting';
import { SubscriptionPopupComponent } from 'src/app/shared/subscription-popup/subscription-popup.component';

@Component({
  selector: 'app-bid-history',
  templateUrl: './bid-history.component.html',
  styleUrls: ['./bid-history.component.css'],
})

export class BidHistoryComponent implements OnInit {
  @Input() shipmentDetail: any;
  @Input() userType: any;
  @Input() currentTabShow: any;
  @Output() eventPassAction = new EventEmitter();
  public currentUrlTab: any;
  public assignUserData: any;
  public timeCount: any;
  public subscriptionData: any;
  public loading = false;
  public serverPathForUrl: any;
  public sidePanelProfileDataId: any;
  public sidePanelProfileData: any
  public userName:any;
  public invitationTimer = 0;
  public showSidePanel: any;
  public userId: any;
  public currentBid: any;
  public overBidCount: any;
  public acceptBidList: any;
  public previousBid: any;
  public previousBidHistory = [];
  public newPreviousBidHistory = [];
  public bidHistorySekeletonLoader = false;
  public page = 1;
  public totalBidPages = 0;
  public spinnerLoaderBid = false;
  public totalPage = 1;
  public eCarrierCheckRisk:any;

  constructor(
    private route: ActivatedRoute,
    public sharedService: SharedService,
    public commonService: CommonService,
    public dialog: MatDialog,
    private alertService: AlertService,
  ) { }

  ngOnInit() {
    this.eCarrierCheckRisk = StatusSetting.eCarrierCheckToolTip;
    this.serverPathForUrl = environment.serverPathForUrl;
    this.userId = localStorage.getItem('user_id');
    this.userName = localStorage.getItem('userName');
    this.bidHistoryData();
    this.getHistoryData();
    if (this.userType === 'SHIPPER') {
      this.getSubscriptionAlert();
    }
  }


  //Shipment bid history
  getBidHiAPIParam() {
    let APIparams, params;
    params = {
      limit: 10,
      shipmentId: this.route.snapshot.params['loadId'],
      page: this.page,
    };
    APIparams = {
      apiKey: AppSettings.APIsNameArray.BIDS.PREHISTORY,
      uri: this.commonService.getAPIUriFromParams(params),
    };
    return APIparams;
  }

  onBidHiScroll() {
    if (this.page !== this.totalBidPages) {
      this.page = this.page + 1;
      this.bidHistoryMoreData();
      this.spinnerLoaderBid = true;
    }
  }

  bidHistoryData() {
    this.bidHistorySekeletonLoader = true;
    this.page = 1;
    var APIparams = this.getBidHiAPIParam();
    this.commonService.getList(APIparams).subscribe((ServerRes) => {
      this.bidHistorySekeletonLoader = false;
      if (ServerRes.response) {
        for (let v = 0; v < ServerRes.response.length; v++) {
          ServerRes.response[v].actionTs = new Date(
            ServerRes.response[v]['actionTs'] + ' ' + 'UTC'
          );
        }
        this.newPreviousBidHistory = ServerRes.response;
        this.totalBidPages = ServerRes.totalPage;
      } else {
        this.newPreviousBidHistory = [];
        this.totalBidPages = 0;
      }
    });
  }

  bidHistoryMoreData() {
    var APIparams = this.getBidHiAPIParam();
    this.commonService.getList(APIparams).subscribe((ServerRes) => {
      this.spinnerLoaderBid = false;
      if (ServerRes.response && ServerRes.response.length > 0) {
        for (let v = 0; v < ServerRes.response.length; v++) {
          ServerRes.response[v].createdAt = new Date(
            ServerRes.response[v]['createdAt'] + ' ' + 'UTC'
          );
          if (ServerRes.response[v])
            this.newPreviousBidHistory.push(ServerRes.response[v]);
        }
      }
    });
  }

  getHiAPIParam() {
    let APIparams, params;
    params = {
      limit: 10,
      shipmentId: this.route.snapshot.params['loadId'],
      page: this.page,
    };
    APIparams = {
      apiKey: AppSettings.APIsNameArray.BIDS.HISTORY,
      uri: this.commonService.getAPIUriFromParams(params),
    };
    return APIparams;
  }

  //pre carrier
  getHistoryData() {
    this.bidHistorySekeletonLoader = true;
    this.page = 1;
    var APIparams = this.getHiAPIParam();
    this.commonService.getList(APIparams).subscribe((ServerRes) => {
      this.bidHistorySekeletonLoader = false;
      this.overBidCount = ServerRes.overBidCount;
      if (ServerRes.response) {
        if (ServerRes.response.currentBids.length > 0) {
          for (let v = 0; v < ServerRes.response.currentBids.length; v++) {
            ServerRes.response.currentBids[v].actionTs = new Date(
              ServerRes.response.currentBids[v]['actionTs'] + ' ' + 'UTC'
            );
          }
        }
        this.currentBid = ServerRes.response.currentBids;
        if (ServerRes.response.acceptedBids.length > 0) {
          for (let v = 0; v < ServerRes.response.acceptedBids.length; v++) {
            ServerRes.response.acceptedBids[v].actionTs = new Date(
              ServerRes.response.acceptedBids[v]['actionTs'] + ' ' + 'UTC'
            );
          }
        }
        this.acceptBidList = ServerRes.response.acceptedBids;
        this.previousBid = ServerRes.response.previousBids;

        if (
          this.currentTabShow === 'history' &&
          localStorage.getItem('user_type') === 'CARRIER'
        ) {
          if (this.previousBid.length > 0) {
            for (let v = 0; v < this.previousBid.length; v++) {
              this.previousBid[v].actionTs = new Date(
                this.previousBid[v]['actionTs'] + ' ' + 'UTC'
              );
            }
          }
          this.previousBidHistory = this.currentBid.concat(this.previousBid);
        } else {
          if (this.previousBid.length > 0) {
            for (let v = 0; v < this.previousBid.length; v++) {
              this.previousBid[v].actionTs = new Date(
                this.previousBid[v]['actionTs'] + ' ' + 'UTC'
              );
            }
          }
          this.previousBidHistory = this.previousBid;
        }
        this.totalPage = ServerRes.totalPage;
      } else {
        this.currentBid = [];
        this.acceptBidList = [];
        this.previousBid = [];
        this.totalPage = 0;
      }
    });
  }


  public generateFake(count: number): Array<number> {
    const indexes = [];
    for (let i = 0; i < count; i++) {
      indexes.push(i);
    }
    return indexes;
  }

  eventActionProfileSidePanel(event: any, type: any){
    if((event[0]?.actionBy && event[0]?.actionBy !==this.userId) || (event?.actionBy && event?.actionBy !==this.userId)){
      this.sidePanelProfileData=this.shipmentDetail;
      if(type==='carrier_side_panel'){
        this.sidePanelProfileDataId = this.shipmentDetail?.carriers?.id;
        this.eventPassAction.emit({ event, type });
      }
      else {
        this.sidePanelProfileDataId = this.shipmentDetail?.shippers?.id;
        this.eventPassAction.emit({ event, type });
      }
    }
   
  }

  // connect carrier shipper

  closePanel(event: any) {
    if (event.type === 'connect') {
      if (this.currentUrlTab !== 'search') {
        this.sidePanelProfileDataId = '';
      }
      this.assignUserData = event.value;
      this.connect(event.value);
    }
    if (event.type === 'close') {
      this.sidePanelProfileDataId = '';
    }
    else if(event.type == 'history'){
      this.getHistoryData();
      this.bidHistoryData();
      this.sidePanelProfileDataId = '';
    }
  }

  connect(event: any) {
    let name;
    let status;
    if (event.companyName) {
      name = event.companyName;
    }
    if (event.legalName) {
      name = event.legalName;
    }
    if (event.networkLabel === 'CONNECT') {
      status = 'INVITE';
    }
    if (event.networkLabel === 'PENDING') {
      status = 'REINVITE';
    }
      let typeOfPopup; 
      let typeOfwidth;
      if(this.userType=='CARRIER'){
        typeOfPopup='inviteCSNew';
        typeOfwidth='600px'
       }else{
        typeOfPopup='inviteCS'
        typeOfwidth=AppSettings.popWidth
       }
      const dialogRef = this.dialog.open(NetworkPopupComponent, {
        disableClose: true,
        backdropClass: AppSettings.backdropClass,
        width: '526px',
        data: {
          openPop: typeOfPopup,
          allRecord: event,
          id: event.id,
          driverName: name,
          status: status,
          planDetail:this.subscriptionData,
        },
      });
      dialogRef.afterClosed().subscribe((result) => {
        if (result.event === 'Ok') {
          if (!this.assignUserData) {
            let data;
            let loader = true;
            data = { status: 'CONNECT', loader: loader, userId: event.id };
            this.sharedService.networkPassData.next(data);
          }
          if (this.assignUserData) {
            this.loading = true;
          }
          this.connectRequest(
            event.id,
            name,
            event,
            status,
            result.message,
            result.documentType,
            result.isKnown,
            event.dotNumber
          );
        }
      });
  }

  connectRequest(
    id: any,
    name: any,
    targetUserData: any,
    status: any,
    message: any,
    documentType: any,
    know:any,
    dotNumber: any
  ) {
    let docType;
    if (documentType === true) {
      docType = 1;
    } else {
      docType = null;
    }
    let checkValue;
      if(know==='yes'){
        checkValue=true;
      }else{
        checkValue=false;
      }
    var APIparams = {
      apiKey: AppSettings.APIsNameArray.MANAGE_CONNECTION[status],
      uri: '',
      postBody: { userPkId: id, note: message, isDocumentVerified: docType ,isKnown:checkValue, },
    };
    this.commonService.post(APIparams).subscribe((ServerRes) => {
      if (ServerRes.success === true) {
        if(this.userType==='CARRIER'){
          this.getActiveSubscription()
        }
        let targetLocation = '-';
        if (
          targetUserData &&
          targetUserData.phyCity &&
          targetUserData.phyState
        ) {
          targetLocation = targetUserData.phyCity + ',' + targetUserData.phyState;
        }
        if (status === 'INVITE') {
          var obj = {
            eventName: 'network_invitation',
            event_category: 'Network',
            Occurence: 'main',
            userType: localStorage.getItem('user_type'),
            EmailID: localStorage.getItem('email'),
            TargetID: name,
            dotNumber: dotNumber ? dotNumber : '-',
            TargetLocation: targetLocation,
            SourceLocation: this.sharedService.getUserLocationType(),
            TimeSpent: this.invitationTimer,
          };
          this.sharedService.track(obj);
        }
        if (status === 'REINVITE') {
          var obj = {
            eventName: 'network_send_reminder',
            event_category: 'Network',
            Occurence: 'main',
            userType: localStorage.getItem('user_type'),
            EmailID: localStorage.getItem('email'),
            TargetID: name,
            dotNumber: dotNumber ? dotNumber : '-',
            TargetLocation: targetLocation,
            SourceLocation: this.sharedService.getUserLocationType(),
            TimeSpent: this.invitationTimer,
          };
          this.sharedService.track(obj);
        }
        if (!this.assignUserData) {
          let data;
          data = { status: 'PENDING', loader: false, userId: id };
          this.sharedService.networkPassData.next(data);
        }
        if (this.assignUserData) {
          this.assignUserData.networkLabel = 'PENDING';
          if (this.currentUrlTab !== 'search') {
            this.showSidePanel = this.assignUserData;
          }

          this.assignUserData = '';
          this.loading = false;
        }
        this.loading = false;
        if (this.userType === 'SHIPPER')
          this.alertService.showNotificationMessage(
            'success',
            'bottom',
            'right',
            'txt_s',
            'check_circle',
            'Connection Request',
            ServerRes.message,
            ServerRes.isInvitationReverted
          );
        if (this.userType === 'CARRIER')
          this.alertService.showNotificationMessage(
            'success',
            'bottom',
            'right',
            'txt_s',
            'check_circle',
            'Connection Request',
            ServerRes.message,
            ServerRes.isInvitationReverted
          );
      } else {
        if (!this.assignUserData) {
          let data;
          data = { status: 'CONNECT', loader: false, userId: id };
          this.sharedService.networkPassData.next(data);
        }
        if (this.assignUserData) {
          this.assignUserData.networkLabel = 'CONNECT';
          if (this.currentUrlTab !== 'search') {
            this.showSidePanel = this.assignUserData;
          }
          this.assignUserData = '';
          this.loading = false;
        }
        this.loading = false;
        if (this.userType === 'SHIPPER')
          this.alertService.showNotificationMessage(
            'danger',
            'bottom',
            'right',
            'txt_d',
            'check_circle',
            'Connection Request',
            ServerRes.message,
            ServerRes.isInvitationReverted
          );
        if (this.userType === 'CARRIER')
          this.alertService.showNotificationMessage(
            'danger',
            'bottom',
            'right',
            'txt_d',
            'check_circle',
            'Connection Request',
            ServerRes.message,
            ServerRes.isInvitationReverted
          );
      }
      if (this.timeCount) {
        this.timeCount.unsubscribe();
      }
    });
  }

  getActiveSubscription() {
    let APIparams = {
      apiKey: AppSettings.APIsNameArray.SUBSCRIPTION.ACTIVEPLAN,
    };
    this.commonService.getList(APIparams).subscribe((ServerRes) => {
      if (ServerRes.success === true) {
        this.subscriptionData = ServerRes.response;
       }
    });
  } 
 

  ecarriercheck(record) {
    const dialogRef = this.dialog.open(NetworkPopupComponent, {
      disableClose: true,
      backdropClass: AppSettings.backdropClass,
      width:'1000px',
      height:'calc(100vh - 100px)',
      data: { openPop: 'ecarriercheck',dotNumber : record[0]?.dotNumber ? record[0]?.dotNumber : record?.dotNumber },
    });
    dialogRef.afterClosed().subscribe((result) => {
    });
  }

  
   // Functions to get subscription alert popup
   getSubscriptionAlert() {
    this.commonService.getSubscriptionAlert().subscribe((serverRes) => {
       this.subscriptionData =  serverRes.response;
    });
   }
 
   getSubscriptionAlertPopup(type){
     const dialogRef = this.dialog.open(SubscriptionPopupComponent, {
       disableClose: true,
       backdropClass: AppSettings.backdropClass,
       width: type == 'isUnlockSafetyScore' ? '500px' : AppSettings.popWidth,
       data: { 
         openPop: 'subscriptionAlert',
         subscriptionData: this.subscriptionData,
         type: type
        },
     });
     dialogRef.afterClosed().subscribe((result) => {
      this.loading=false;

     });
     this.loading=false;

   }

  unlockSafetyScore(dotNumber,type){
    this.loading=true
    if (this.userType == 'SHIPPER' && (((this.subscriptionData?.subscriptionCreditHistory?.defaultCredit + this.subscriptionData?.subscriptionCreditHistory?.additionalCredit)==0) && this.subscriptionData?.isPremium != true && this.subscriptionData?.subscriptionStatus == 'canceled') 
    || (this.subscriptionData?.isPremium == true && this.subscriptionData?.subscriptionStatus == 'active' && ((this.subscriptionData?.subscriptionCreditHistory?.defaultCredit + this.subscriptionData?.subscriptionCreditHistory?.additionalCredit)==0)))
    {
      this.getSubscriptionAlertPopup('isUnlockSafetyScore');
    } else {
    this.bidHistorySekeletonLoader=false
    let uri = null;
    let newParams = {
      dotNumber: dotNumber
     
    };
    if (newParams) uri = this.commonService.getAPIUriFromParams(newParams);
    let APIparams = {
      apiKey: AppSettings.APIsNameArray.CARRIER.ECARRIERCHECK,
      uri: uri,
    };
    this.commonService.getList(APIparams).subscribe(
       (ServerRes) => {
        if(ServerRes?.success==true){
          this.getHistoryData();
          this.bidHistoryData();
          this.loading=false;
        }
        else if(ServerRes.isCreditAvailable==false && ServerRes?.success==false){
          const dialogRef = this.dialog.open(NetworkPopupComponent, {
            disableClose: true,
            backdropClass: AppSettings.backdropClass,
            width:'500px',
            data: { openPop: 'eCarreirCreadits', },
          });
          dialogRef.afterClosed().subscribe((result) => {
            this.loading=false;
          });
          this.loading=false;
        }
        else if(ServerRes?.success==false){
          this.alertService.showNotificationMessage(
            'danger',
            'bottom',
            'right',
            'txt_d',
            'check_circle',
            'Outside Carrier',
            ServerRes?.message
          );
          this.loading=false;
        }
      },
      (error) => {      
        this.alertService.showNotificationMessage(
          'danger',
          'bottom',
          'right',
          'txt_d',
          'check_circle',
          'Outside Carrier',
          error?.error?.message?.message
        );
  
      this.loading = false;
    });
   
    }
  }
}
