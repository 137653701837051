<div class="">
<div class="bg_b" [ngClass]="moduleType == 'Recommended Guides' ?  'blueColor' : 'blackColor' " *ngIf="showBanner">
  <div class="guide_cards_container">
    <span class="close_icon icon-ic-cross" style="color:white" (click)="dissMissBanner()"><i class="fa-solid fa-xmark"></i></span>
    <div class="ver_bg">
      <div class="container">
        <div class="guide_nav">
          <div class="d-flex-mob">
            <span class="Booking_guide_text fs_16 fsm_5 txt_w">{{moduleType}} - Tutorials Guides</span>
            <span class="ms-2"><img src="\assets\icon\new_booking_guide_icon.svg"></span>
          </div>
          <div *ngIf="moduleType !='Recommended Guides'">
            <a (click)="viewAllGuides()" class="fs_14 fsm_5 txt_w faq_btn pointer">View All FAQ and Guides</a>
          </div>
        </div>
      
        <div class="guide_nav_card">
          <div *ngFor="let record of userTutorial; i as index;" class="nav_card_box">
          <div class="nav_cards" *ngIf="record.tutorialScribePortal">
            <div>
              <span class="fs_14 fsm_5 txt_b text-truncate" matTooltip="{{record?.tutorialLab}}">{{record?.tutorialLab}}</span>
            </div>
            <div class="fs_10 txt_lb">{{record?.tutorialdTime}}</div>
            <div class="text-end">
              <a class="fs_10 txt_p fsm_5 b_none bg_none pointer"  [routerLink]="[record.tutorialViewGuide]" (click)="showChildTabData(record)">View Guide <img
                  src="\assets\icon\right_guide_arrow.svg"></a>
              <span class="arrow"></span>
            </div>
          </div>
        </div>
        </div>
      </div>
    </div>
  </div>
</div>
</div>