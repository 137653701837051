import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

export interface DialogData {
  title: any;
  uniqueId: any;
  openPop: any;
  shipmentId: any;
}

@Component({
  selector: 'app-dispute-popup',
  templateUrl: './dispute-popup.component.html',
  styleUrls: ['./dispute-popup.component.scss'],
})
export class DisputePopupComponent implements OnInit {
  public userType: any;
  public serverPathForUrl: any;

  constructor(
    public dialog: MatDialog,
    private router: Router,
    public dialogRef: MatDialogRef<DisputePopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {}

  ngOnInit() {
    this.userType = localStorage.getItem('user_type');
    this.serverPathForUrl = environment.serverPathForUrl;
  }

  onNoClick(): void {
    this.dialogRef.close({ event: 'fail' });
  }

  disputRedirect() {
    this.dialogRef.close({ event: 'fail' });
    this.router.navigate(['/load/dispute', this.data.shipmentId]);
  }
}
