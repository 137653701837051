import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSliderModule } from '@angular/material/slider';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatMenuModule } from '@angular/material/menu';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatListModule } from '@angular/material/list';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatCardModule } from '@angular/material/card';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTabsModule } from '@angular/material/tabs';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { MatBadgeModule } from '@angular/material/badge';
import { NgModule } from '@angular/core';
import { DateAgoPipe } from './commons/pipe/date-ago.pipe';
import { DotOfMiddlePipe } from './commons/pipe/dot-of-middle.pipe';
import { PhonePipe } from './commons/pipe/phone.pipe';
import { PhoneMultipleCountryPipe } from './commons/pipe/phone-multiple-country.pipe';
import { SearchFilterPipe } from './commons/pipe/search-filter.pipe';
import { FilterPipe } from './commons/pipe/filter.pipe';
import { OrderByPipe } from './commons/pipe/order-by.pipe';
import { AcronymPipe } from './commons/pipe/acronym.pipe';
import { sixDigitDecimalPipe } from './commons/pipe/decimal.pipe';
import { twoDigitDecimalPipe } from './commons/pipe/two-digit-decimal.pipe';
import { DecimalStartWithZeroPipe } from './commons/pipe/decimal-start-with-zero.pipe';
import { DimensionDirective }  from './commons/directives/dimension.directive';

@NgModule({
  imports: [
    MatCheckboxModule,
    MatCheckboxModule,
    MatButtonModule,
    MatInputModule,
    MatAutocompleteModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatRadioModule,
    MatSelectModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatMenuModule,
    MatSidenavModule,
    MatToolbarModule,
    MatListModule,
    MatGridListModule,
    MatCardModule,
    MatStepperModule,
    MatTabsModule,
    MatExpansionModule,
    MatButtonToggleModule,
    MatChipsModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatDialogModule,
    MatTooltipModule,
    MatSnackBarModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    MatBadgeModule,
    
  ],
  providers: [{ provide: MatDialogRef, useValue: {} }],

  declarations: [
    DateAgoPipe,
    SearchFilterPipe,
    PhonePipe,
    FilterPipe,
    DotOfMiddlePipe,
    AcronymPipe,
    sixDigitDecimalPipe,
    twoDigitDecimalPipe,
    DecimalStartWithZeroPipe,
    OrderByPipe,
    PhoneMultipleCountryPipe,
    DimensionDirective
  ],

  bootstrap: [],
  exports: [
    MatCheckboxModule,
    MatCheckboxModule,
    MatButtonModule,
    MatInputModule,
    MatAutocompleteModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatRadioModule,
    MatSelectModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatMenuModule,
    MatSidenavModule,
    MatToolbarModule,
    MatListModule,
    MatGridListModule,
    MatCardModule,
    MatStepperModule,
    MatTabsModule,
    MatExpansionModule,
    MatButtonToggleModule,
    MatChipsModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatDialogModule,
    MatTooltipModule,
    MatSnackBarModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    MatNativeDateModule,
    MatRippleModule,
    MatBadgeModule,
    DateAgoPipe,
    SearchFilterPipe,
    FilterPipe,
    DotOfMiddlePipe,
    AcronymPipe,
    sixDigitDecimalPipe,
    twoDigitDecimalPipe,
    PhonePipe,
    DecimalStartWithZeroPipe,
    OrderByPipe,
    PhoneMultipleCountryPipe,
    DimensionDirective
  ],
})
export class MaterialModule { }
