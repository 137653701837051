<div *ngIf="shipmentEmptyData">
  <div class="Empty_State_body">
    <div class="Empty_State_con">
      <div class="Empty_State_img">
        <img class="img-fluid" src="{{serverPathForUrl}}/{{shipmentEmptyData[0]?.image}}">
      </div>
      <div style="margin: 0px 0 32px;">
        <p class="fs_24 fsb_6 txt_b pb-1" style="margin: 30px 0 12px;" [innerHTML]="shipmentEmptyData[0]?.title"></p>
        <p class="fs_16 fsn_4 txt_lb pb-1" style="margin: 0px 0 20px;">{{shipmentEmptyData[0]?.description}}</p>
      </div>
      <div *ngIf="shipmentEmptyData" class="text-center pointer" [routerLink]="[shipmentEmptyData[0]?.buttonURL]"> 
        <span style="background: var(--clBlua); color: var(--clWhta); font-size: 16px; font-weight: 500; padding: 10px 16px; border-radius: 30px;">{{shipmentEmptyData[0]?.buttonText}}</span>
      </div>
    </div>
  </div>
</div>