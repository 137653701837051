import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { finalize } from 'rxjs/operators';
import { CommonService } from '../../commons/service/common.service';
import { HereMapsService } from '../../commons/service/here-maps.service';
import { SharedService } from '../../commons/service/shared.service';
import { AppSettings } from '../../commons/setting/app_setting';
import { STATUS, STATUS_COLOR_CODES } from '../../commons/setting/Heremaps.constants';
import { StatusSetting } from '../../commons/setting/status_setting';
import { PopupComponent } from '../../shared/popup/popup.component';
import { SubscriptionPopupComponent } from 'src/app/shared/subscription-popup/subscription-popup.component';
declare var require: any;
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-route-plan',
  templateUrl: './route-plan.component.html',
  styleUrls: ['./route-plan.component.scss']
})
export class RoutePlanComponent implements OnInit {
  canDeactivate() {
    throw new Error('Method not implemented.');
  }
  @Input() loadDetail: any;
  @Input() userType: any;
  @Input() subscriptionData: any;
  skeletonLoader = true;
  public countMiles = false;
  public wayPoints: any;
  public gifLoader: boolean = false;
  public type: any;
  public pickDate: any;
  public initialPickUps: number;
  public initialDrops: number;
  public pickupTimeError = false;
  public disablePickupTime = false;
  pickupDate: any;
  pickuptimeslot: any;
  public disabled1 = true;
  public selectTime: any;
  public pimaxDate: any;
  public pickupTimezoneEdit: any;
  public pickupTimeEdit: any;
  public pickupDateEdit: any;
  public isTimeSloteAvailble = false;
  public isTimeSloteExpired = false; 
  public noTimeSloteError: any;
  public deliveryTimeShow: any;
  public maxDateDropOff = new Date();
  public calemaxte: any;
  olddropDate: any;
  public dropTimeArr: any;
  public addshipment: any = {};
  public totalMiles=0;
  public timedat: any;
  public pickupTimeArr: any;
  public wayPointEditForm: FormGroup;
  public pickupTZ: any;
  public minDate: any = new Date();
  public maxDate: any = new Date();
  public selectedIndex: any;
  public dateErrorMessage: boolean = false;
  public isRouteDelete: boolean = false;
  public isUpdatedWaypoint: boolean;
  public waypointLocationLongName: any;
  public errorIndex = [];
  edit_card: boolean = false;
  getTimeZoneForETA: any;
  public serverPathForUrl: any;

  constructor(
    private route: ActivatedRoute,
    private hereMapsService: HereMapsService,
    private formBuilder: FormBuilder,
    public sharedService: SharedService,
    public commonService: CommonService,
    public dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    this.serverPathForUrl = environment.serverPathForUrl;
    this.shipmentGetForRoute();
    this.pickupTimeArr = StatusSetting.pickUpTime;
    this.dropTimeArr = StatusSetting.dropOffTime;
    this.pickupTZ = StatusSetting.newTimeZoneList;

    this.wayPointEditForm = this.formBuilder.group({
      pickupDate: ['', [Validators.required]],
      pickupTime: ['', [Validators.required]],
      pickupTimeZone: ['', [Validators.required]],
    });
  }

  // To edit date and time of waypoint
  cardClickEvent(index: any) {
    this.selectedIndex = index;
    this.edit_card = !this.edit_card;
    let newPickdate: string | number | Date;
    if (this.wayPoints[index].date) {
      newPickdate = this.wayPoints[index].date.replaceAll('-', '/');
    } else {
      newPickdate = null;
    }
    let currentDate = this.wayPoints[this.selectedIndex].date;
    this.setMinMaxDate(currentDate);
    this.wayPointEditForm = this.formBuilder.group({
      pickupDate: [new Date(newPickdate), [Validators.required]],
      pickupTime: [this.setTimeSlot(index), [Validators.required]],
      pickupTimeZone: [this.wayPoints[index].timeZone, [Validators.required]],
    });
  }

  // Function to set time slote
  setTimeSlot(index){
    let i = this.getIndex(this.wayPoints[index].timeSlot); 
    return StatusSetting.pickUpTime[i];
  }

  // get index to set time slot
  getIndex(timeSlot){
    const index =  StatusSetting.pickUpTime.findIndex((x: { time: any; }) => x.time === timeSlot);
    return index; 
  }

  // Function to set First WayPoint Range With TimeZone
  setFirstWayPointRangeWithTimeZone(currentDate){
    this.enableTimeZones();
    this.minDate = new Date(); //set min date to current one 
    if(moment(this.wayPoints[this.selectedIndex+1].date).isBefore(new Date(), 'day'))
    this.maxDate = null; 
    else
    this.maxDate = new Date(this.wayPoints[this.selectedIndex+1].date.replaceAll('-', '/'));
    if(moment(currentDate).isSame(this.wayPoints[this.selectedIndex+1].date, 'day')) {
      let maxTimeslot = this.wayPoints[this.selectedIndex+1].timeSlot;
      let maxIndex = this.getIndex(maxTimeslot); 
      this.setTimeSlotDropDownForCurrentSelectedDate(0, maxIndex);
    }
    else
    this.pickupTimeArr = StatusSetting.pickUpTime; 
    this.checkIfTodaysDate(currentDate); 
  }

  // To enable TimeZones
  enableTimeZones(){
    StatusSetting.pickUpTime.map((obj)=>{
      obj.disabled = false; 
    });
  }

  // Function to set Last WayPoint Range With TimeZone
  setLastWayPointRangeWithTimeZone(currentDate){
      this.enableTimeZones();
      if(moment(this.wayPoints[this.selectedIndex-1].date).isBefore(new Date(), 'day'))
      this.minDate = new Date(); 
      else
      this.minDate = new Date(this.wayPoints[this.selectedIndex-1].date.replaceAll('-', '/'));
      this.maxDate = null;
      if(moment(currentDate).isSame(this.wayPoints[this.selectedIndex-1].date, 'day')) {
        let minTimeslot =  this.wayPoints[this.selectedIndex-1].timeSlot;
        let minIndex = this.getIndex(minTimeslot);  
        let maxIndex =  StatusSetting.pickUpTime.length; 
        this.setTimeSlotDropDownForCurrentSelectedDate(minIndex+1, maxIndex);
      }
      else
      this.pickupTimeArr = StatusSetting.pickUpTime; 
      this.checkIfTodaysDate(currentDate);
    }

  // Function to set Min/Max Date
  setMinMaxDate(currentDate){
    //first way point 
    if (this.selectedIndex ===  0){
      this.setFirstWayPointRangeWithTimeZone(currentDate);
    }
    //second to second last way point 
    if(this.selectedIndex > 0 &&  (this.selectedIndex <= this.wayPoints.length-2)){
      this.enableTimeZones();
      if(moment(this.wayPoints[this.selectedIndex-1].date).isBefore(new Date(), 'day'))
      this.minDate = new Date(); 
      else
      this.minDate = new Date(this.wayPoints[this.selectedIndex-1].date.replaceAll('-', '/'));
      if(moment(this.wayPoints[this.selectedIndex+1].date).isBefore(new Date(), 'day'))
      this.maxDate = null; 
      else 
      this.maxDate = new Date(this.wayPoints[this.selectedIndex+1].date.replaceAll('-', '/'));
      let previousDate = this.wayPoints[this.selectedIndex-1].date; 
      let nextDate = this.wayPoints[this.selectedIndex+1].date; 
      this.checkTimeSlot(currentDate,previousDate, nextDate ); 
      this.checkIfTodaysDate(currentDate); 
    }
    //last way point 
    if(this.selectedIndex > 0 && this.selectedIndex === this.wayPoints.length-1){
      this.setLastWayPointRangeWithTimeZone(currentDate); 
    }
  }

  // Function to check Time Slot
  checkTimeSlot(currentDate,previousDate, nextDate){
    if(!(moment(currentDate).isSame(previousDate, 'day')) && 
    moment(currentDate).isSame(nextDate, 'day')) {
      let maxTimeslot = this.wayPoints[this.selectedIndex+1].timeSlot;
      let maxIndex = this.getIndex(maxTimeslot); 
      this.setTimeSlotDropDownForCurrentSelectedDate(0, maxIndex);
    }
    // 4 way point date == previous date and way point date !== next date
    else if((moment(currentDate).isSame(previousDate, 'day')) && !(moment(currentDate).isSame(nextDate, 'day'))) {
      let minTimeslot = this.wayPoints[this.selectedIndex-1].timeSlot; 
      let minIndex = this.getIndex(minTimeslot); 
      let maxIndex =  StatusSetting.pickUpTime.length; 
      this.setTimeSlotDropDownForCurrentSelectedDate(minIndex+1, maxIndex);
    }
    // 3. way point date == previous date and way point date == next date
    else if((moment(currentDate).isSame(previousDate, 'day')) && (moment(currentDate).isSame(nextDate, 'day'))) {
      let minTimeslot = this.wayPoints[this.selectedIndex-1].timeSlot; 
      let minIndex = this.getIndex(minTimeslot); 
      let maxTimeslot = this.wayPoints[this.selectedIndex+1].timeSlot;
      let maxIndex = this.getIndex(maxTimeslot); 
      this.setTimeSlotDropDownForCurrentSelectedDate(minIndex+1, maxIndex);
    }
    else{
     this.pickupTimeArr = StatusSetting.pickUpTime; 
    }
  }

  // Function to set TimeSlot DropDown For Current Selected Date
  setTimeSlotDropDownForCurrentSelectedDate(minTimeSlotIndex: any, maxTimeSlotIndex: any){
    let pickupArray = StatusSetting.pickUpTime; 
    if(pickupArray && pickupArray.length > 0){
      this.pickupTimeArr = StatusSetting.pickUpTime.slice(minTimeSlotIndex,maxTimeSlotIndex);
    }
    if (this.pickupTimeArr && this.pickupTimeArr.length === 0){
      this.isTimeSloteAvailble = true;
      this.noTimeSloteError = "No time slot is available for this date. Please select the next available date."
    }
  }

  // Function for generate duplicate loaders 
  public generateFake(count: number): Array<number> {
    const indexes = [];
    for (let i = 0; i < count; i++) {
      indexes.push(i);
    }
    return indexes;
  }

  // Function to drage and drop waypoints 
  async drop(event: CdkDragDrop<string[]>, item: any) {
    this.countMiles=true;
    this.dateErrorMessage = false;
    const wayPointsLastIndex = this.wayPoints.length - 1;
    const startingIndex =  0; 
    const nonCompletedWayPoints = this.wayPoints.filter(
      (x: { status: string; }) => x.status !== 'Completed'
    );
    if(
      ((event.currentIndex === startingIndex && this.wayPoints[event.previousIndex].type === 'dropoff' && event.previousIndex == wayPointsLastIndex) || 
      (event.currentIndex === wayPointsLastIndex && this.wayPoints[event.previousIndex].type === 'pickup' && event.previousIndex == startingIndex))){
      this.dragDropAlert('pickup & dropoff');
    }
    else if(
      ((event.currentIndex === startingIndex && this.wayPoints[event.previousIndex].type === 'dropoff') && 
      (event.currentIndex === wayPointsLastIndex && this.wayPoints[event.previousIndex].type === 'pickup'))){
      this.dragDropAlert('pickup & dropoff');
    }
    else if (
      event.currentIndex === startingIndex &&
      this.wayPoints[event.previousIndex].type === 'dropoff'
    ) {
      this.dragDropAlert('pickup');
    } 
    else if (
      event.currentIndex === wayPointsLastIndex &&
      this.wayPoints[event.previousIndex].type === 'pickup'
    ) {
      this.dragDropAlert('dropoff');
    }
    else if (
      event.previousIndex === wayPointsLastIndex && 
      this.wayPoints[event.currentIndex].type === 'pickup'
    ) {
      this.dragDropAlert('dropoff');
    } 
    
    else if (
      event.previousIndex === startingIndex && this.wayPoints[event.currentIndex].type === 'dropoff'
    ) {
      this.dragDropAlert('pickup');  
    } 
    
     else if (
      this.wayPoints[event.currentIndex].status === 'Completed' ||
      this.wayPoints[event.previousIndex].status === 'Completed'
    ) {
      this.dragDropAlertForCompleted(event.currentIndex);
    } else {
      this.sharedService.setGuardValue();
      if (this.wayPoints[event.currentIndex].errorIndex) {
        this.wayPoints[event.currentIndex].errorIndex = false;
      }
      if (this.wayPoints[event.previousIndex].errorIndex) {
        this.wayPoints[event.previousIndex].errorIndex = false;
      }
        this.wayPoints[event.currentIndex].isUpdated = true; 
      this.calculateETA();
     
      moveItemInArray(this.wayPoints, event.previousIndex, event.currentIndex);
      let  miles:any;
      this.totalMiles =0
      for(let i=0; i<this.wayPoints.length-1 ; i++)
      { 
      miles =  await this.getRouteCompetability(this.wayPoints[i].latitude,this.wayPoints[i].longitude,this.wayPoints[i+1].latitude,this.wayPoints[i+1].longitude)
      this.totalMiles = miles['miles']+this.totalMiles; 
     }
    }
  }

  // Function to get waypoint list
  shipmentGetForRoute() {
    this.skeletonLoader = true;
    this.gifLoader = true;
    let uri = null;
    let newParams = {
      shipmentId: this.route.snapshot.params['loadId'],
    };
    if (newParams) uri = this.commonService.getAPIUriFromParams(newParams);
    let APIparams = {
      apiKey: AppSettings.APIsNameArray.SHIPMENT.WAYPOINTLIST,
      uri: uri,
    };
    this.commonService.getList(APIparams).subscribe((ServerRes) => {
      if (ServerRes.success === true) {
        this.skeletonLoader = false;
        this.wayPoints = ServerRes.response.waypoints;
        this.wayPoints = this.getUpdatedWayPoints();

        let pickup = this.wayPoints.filter((x: { type: string; }) => x.type === 'pickup');
        this.initialPickUps = pickup ? pickup.length : 0;
        let drop = this.wayPoints.filter((x: { type: string; }) => x.type === 'dropoff');
        this.initialDrops = drop ? drop.length : 0;
        this.calculateETA();
      } else {
      }
    });
  }

  // Function to get ETA For CompletedWaypoints
  getETAForCompletedWaypoints(){
    this.wayPoints.forEach(item => {
      //pass arrived date as first argument and drop date as second and timeslot as third
      let tripStatus = this.hereMapsService.getTripStatusForCompletedWayPoint(item.arrivedAt, item.date, item.timeSlot);
      if(tripStatus){
        item.tripStatus = {}; 
        item.tripStatus.Color = tripStatus.Color; 
        item.tripStatus.Text = tripStatus.Text; 
      }
    });
  }

 // Function to calculate ETA
  calculateETA() {
    let nonCompletedwayPoints = [];
    let completedWayPoints = []; 
    nonCompletedwayPoints = this.wayPoints.filter(
      (x: { status: string; }) => x.status !== 'Completed'
    );
    completedWayPoints = this.wayPoints.filter(
      (x: { status: string; }) => x.status === 'Completed'
    );
    if(completedWayPoints.length > 0 ){
      this.getETAForCompletedWaypoints(); 
    }
    nonCompletedwayPoints.forEach((value: any, i: any) => {
      let tempArr = [];
      var countdown = 0;
      value.tripStatus = null;
      while (countdown <= i) {
        tempArr.push(nonCompletedwayPoints[countdown]);
        countdown++;
      }
      this.getLastDrop(tempArr);
      if(i=== 0 && !this.loadDetail.drivers && !this.loadDetail.drivers?.latitude && !this.loadDetail.drivers?.longitude){
        var tripStatus = this.hereMapsService.getTripResponse(
        '', '', '', '', '', 0, this.loadDetail); 
        value.tripStatus = tripStatus;
        value.eta = "-";
      }
      else{        
        this.calculateMultiRoute(this.loadDetail, tempArr, value,tempArr[i]);
      }
    });
  }

  // Function to get Last Drop location for waypoint
  getLastDrop(tempArr: string | any[]){
    if(tempArr && tempArr.length > 0){
    this.loadDetail.dropStatus = tempArr[tempArr.length - 1].status;
    this.loadDetail.dropTimeZone = tempArr[tempArr.length - 1].timeZone;
    this.loadDetail.dropTimeslot = tempArr[tempArr.length - 1].timeSlot;
    this.loadDetail.dropDate = tempArr[tempArr.length - 1].date;
    }
  }

   // Function to calculat eMulti Route
  async calculateMultiRoute(loadDetail: any, tempArr: any[], wayPointObj: any, wayIndex:any) {
    await this.hereMapsService.calculateMultiRoute(loadDetail, tempArr)
      .then(async(response: any) => {
        var tripStatus = await this.hereMapsService.calculateTripStatus(response,this.loadDetail,true);
        wayPointObj.tripStatus = tripStatus;
        wayPointObj.eta = this.getTripStatus(tripStatus, wayIndex);
        wayPointObj.eta = wayPointObj.eta ? wayPointObj.eta : "-";
        this.gifLoader = false; 
      },
      (error: any) => {
        var tripStatus = this.hereMapsService.getTripResponse(
          STATUS_COLOR_CODES.BLUE, STATUS.NO_ROUTE, '', '', '', 0, this.loadDetail);
          wayPointObj.tripStatus = tripStatus;
          wayPointObj.eta = this.getTripStatus(tripStatus, wayIndex);
          wayPointObj.eta = wayPointObj.eta ? wayPointObj.eta : "-";
      });
  }

   // Function to get TripStatus
  getTripStatus(tripStatus: { Color?: any; Text?: any; ETA: any; ETAWithColon?: any; ETAFor?: any; milesCompleted?: any; latitude?: any; longitude?: any; }, wayIndex) {
    var moment = require('moment-timezone');
    if (tripStatus) {
      if (tripStatus.ETA != null && tripStatus.ETA != undefined) {
        let dropTimeZone = this.hereMapsService.getTimeZone(
          this.loadDetail.dropTimeZone
        );
        let etaTimeZone;
        if (wayIndex.timeZone == 'PST') {
          etaTimeZone = 'America/Los_Angeles';
        } else if (wayIndex.timeZone == 'CST') {
          etaTimeZone = 'America/Chicago';
        } else if (wayIndex.timeZone == 'AKST') {
          etaTimeZone = 'America/Anchorage'; 
        } else {
          etaTimeZone = wayIndex.timeZone;
        }
        this.getTimeZoneForETA = etaTimeZone; 
        let currentTime = moment().tz(this.getTimeZoneForETA).format('MM/DD/YYYY HH:mm');
        let currentTS = Date.parse(currentTime);

        return tripStatus.ETA + currentTS;
      }
    } else {
      return null;
    }
  }

  // Function to get TimeZone
  getTimeZone(timeZoneLabel) {
    let timeZone;
    if (timeZoneLabel === 'PST') {
      timeZone = 'America/Los_Angeles';
    } else if (timeZoneLabel === 'MST') {
      timeZone = 'America/Denver';
    }
    if (timeZoneLabel === 'CST') {
      timeZone = 'America/Chicago';
    }
    if (timeZoneLabel === 'EST') {
      timeZone = 'America/New_York';
    }
    if (timeZoneLabel === 'HST') {
      timeZone = 'Pacific/Honolulu';
    }
    if (timeZoneLabel === 'AKST') {
      timeZone = 'America/Anchorage';
    }
    return timeZone;
  }

  // To validate PickUp DropOff waypoint
  validatePickUpDropOff() {
    var pickUpCount = 0;
    var dropOffCount = 0;
    var self = this;
    this.wayPoints.forEach(function (value: any, index: string | number) {
      if (self.wayPoints[index].type === 'pickup') {
        pickUpCount = pickUpCount + 1;
      } else if (self.wayPoints[index].type === 'dropoff') {
        dropOffCount = dropOffCount + 1;
      }
    });
    if (pickUpCount === 0 || dropOffCount === 0) {
      this.pickDropCheckAlert();
      return false;
    } else if (
      (this.wayPoints[0].type !== 'pickup' &&
      this.wayPoints[this.wayPoints.length - 1].type !== 'dropoff') ||  
      (this.wayPoints.length == 2 && (this.wayPoints[0].type !== 'pickup' ||
      this.wayPoints[this.wayPoints.length - 1].type !== 'dropoff'))
    ) {
      this.dragDropAlert('pickup & dropoff');
      return false;
    } else if (
      this.wayPoints[0].type !== 'pickup'
    ) {
      this.dragDropAlert('pickup');
      return false;
    }else if (
      this.wayPoints[this.wayPoints.length - 1].type !== 'dropoff'
    ) {
      this.dragDropAlert('dropoff');
      return false;
    }else {
      return true;
    }
  }

  // Function to get Date Format
  getDateFormat(inputDate: any) {
    if (inputDate !== '' && typeof inputDate !== undefined) {
      return inputDate.replaceAll('-', '/');
    } else {
      return '';
    }
  }

  // Function to check Date Validation
  checkDateValidation() {
    var self = this;
    let isDateValid = true;
    this.wayPoints.forEach(function (value: any, index: string | number) {
      self.wayPoints[index].errorIndex = false;
    });
    this.wayPoints.forEach(function (value: any, index: number) {
      if (index < self.wayPoints.length - 1) {
        let firstObject = value;
        let secondObject = self.wayPoints[index + 1];

        if (moment(secondObject.date).isBefore(firstObject.date, 'day')) {
          self.dateErrorMessage = true;
          self.wayPoints[index + 1].errorIndex = true;
          isDateValid = false;
        } else if (moment(secondObject.date).isSame(firstObject.date, 'day')) {
          var startTime = firstObject.timeSlot.split(/[ -]+/);
          let start = startTime[0];
          var endTime = secondObject.timeSlot.split(/[ -]+/);
          let end = endTime[1];
          if (
            start > end ||
            start === end ||
            firstObject.timeSlot === secondObject.timeSlot
          ) {
            self.dateErrorMessage = true;
            self.wayPoints[index + 1].errorIndex = true;
            isDateValid = false;
          } else {
            isDateValid = true;
          }
        } else {
          isDateValid = true;
        }
      }
      if (index === self.wayPoints.length - 1) return isDateValid;
    });
    return isDateValid;
  }

  // Function to get Updated WayPoints
  getUpdatedWayPoints() {
    for (let value of this.wayPoints) {
      if (value.date) {
        value.date = this.getDateFormat(value.date.toString());
      }
      if(value.arrivedAt){
        value.arrivedAt = new Date(value.arrivedAt + ' ' + 'UTC');
      }
    }
    return this.wayPoints;
  }

  // Function to get Subscription Alert Popup
  getSubscriptionAlertPopup(){
    const dialogRef = this.dialog.open(SubscriptionPopupComponent, {
      disableClose: true,
      backdropClass: AppSettings.backdropClass,
      width: AppSettings.popWidth,
      data: { 
        openPop: 'subscriptionAlert',
        subscriptionData: this.subscriptionData
       },
    });
    dialogRef.afterClosed().subscribe((result) => {

    });
  }

  // Function to save route
  saveRoute() {
    if (this.userType == 'SHIPPER' && (this.subscriptionData?.isPremium != true || this.subscriptionData?.subscriptionStatus == 'canceled'))
    {
      this.getSubscriptionAlertPopup();
    } else {
    if (this.checkDateValidation() && this.validatePickUpDropOff() && this.dateErrorMessage === false) {
      this.skeletonLoader = true;
      let addRoutePlan: any = {};
      addRoutePlan.shipmentId = this.route.snapshot.params['loadId'];
      let wayPointPayload = this.wayPoints.map((x: any) => Object.assign({}, x)); 
      
      for (let value of wayPointPayload) {
        delete value.loadHistoryStatus;
        delete value.createdAt;
        delete value.shipmentId;
        delete value.updatedAt;
        delete value.eta;
        delete value.arrivedAt;
        delete value.errorIndex;        
        delete value.tripStatus;

        if (value.commodity === null) {
          delete value.commodity;
        }
        if (value.contactName === null) {
          delete value.contactName;
        }
        if (value.countryName === null || value.countryName === '') {
          delete value.countryName;
        }
        if (value.height === null) {
          delete value.height;
        }
        if (value.countryCode === null) {
          delete value.countryCode;
        }
        if (value.length === null) {
          delete value.length;
        }
        if (value.phone === null) {
          delete value.phone;
        }
        if (value.quantity === null) {
          delete value.quantity;
        }
        if (value.weight === null) {
          delete value.weight;
        }
        if (value.width === null) {
          delete value.width;
        }
        if (value.laneId === null) {
          delete value.laneId;
        }

        if(value.commodity && value.commodity.length > 0){
          let commodityList = value.commodity; 
           for (let item of commodityList) {
            delete item.createdAt;
            delete item.shipmentId;
            delete item.updatedAt;
            delete item.waypointId;
            
            if (item.dimension === null || item.dimension === '') {
              delete item.dimension;
            }
            if (item.media === null || item.media === '') {
              delete item.media;
            }
            if (item.sku === null || item.sku === '') {
              delete item.sku;
            }
            if (item.type === null || item.type === '') {
              delete item.type;
            }
            if (item.value === null || item.value === '') {
              delete item.value;
            }
            if (item.weight === null || item.weight === '') {
              delete item.weight;
            }
            if (item.type === null || item.type === '') {
              delete item.type;
            }
            if (item.laneId === null || item.laneId === '') {
              delete item.laneId;
            }
            if (item.handleWithCare === null || item.handleWithCare === '') {
              delete item.handleWithCare;
            }
            delete item.length
            delete item.height
            delete item.width
         }
        }
      }
      addRoutePlan.waypoints = wayPointPayload;
      let uri = null;
      let APIparams = {
        apiKey: AppSettings.APIsNameArray.SHIPMENT.UPDATEROUTE,
        uri: uri,
        postBody: addRoutePlan,
      };
      this.commonService.post(APIparams).subscribe((ServerRes) => {
        if (ServerRes.success === true) {
          if(this.isUpdatedWaypoint || this.countMiles)
          {
            this.editShipmentAPifortotalMiles()
          }
          this.skeletonLoader = false;
          this.sharedService.setGuardValueFalse();
          this.finalSaveRoute();
        } 
      });
    } 
   }
  }

 // Function to save final route
  finalSaveRoute() {
    const dialogRef = this.dialog.open(PopupComponent, {
      disableClose: true,
      backdropClass: AppSettings.backdropClass,
      width: AppSettings.popWidth,
      data: {
        openPop: 'routePlanSuccess',
        loadDetail: this.loadDetail,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.shipmentGetForRoute();
      }
    });
  }

  // Function to edit Shipment APi for total Miles
  editShipmentAPifortotalMiles()
  {
    this.addshipment.id = this.route.snapshot.params['loadId'];
    this.addshipment.miles = this.totalMiles
    // ping rage 
       let postBody = null;
    let APIparams = {
      apiKey: AppSettings.APIsNameArray.SHIPMENT.UPDATE,
      uri: '',
      postBody: this.addshipment,
    };
    this.commonService
      .putAllValue(APIparams)
      .pipe(
        finalize(() => {
        })
      ).subscribe(
        (success) => {
        },
      );
      }

  // Function to delete waypoint
  deleteRoute(index: any) {
    this.type = this.wayPoints[index].type;
    this.waypointLocationLongName = this.wayPoints[index].locationLongName;
    const dialogRef = this.dialog.open(PopupComponent, {
      disableClose: true,
      backdropClass: AppSettings.backdropClass,
      width: AppSettings.popWidth,
      data: {
        openPop: 'deleteMultiStop',
        loadDetail: this.loadDetail,
        type: this.type,
        index: index,
        wayPoints: this.wayPoints,
      },
    });
    dialogRef.afterClosed().subscribe(async(result) => {
      if (result) {
        if (result.event == 'Ok') {
          this.isRouteDelete = true;
          this.countMiles = true;
        } else {
          this.isRouteDelete = false;
        }
        this.initialPickUps = this.wayPoints.filter(
          (x: { type: string; }) => x.type === 'pickup'
        ).length;
        this.initialDrops = this.wayPoints.filter(
          (x: { type: string; }) => x.type === 'dropoff'
        ).length;
        this.checkDateValidation();
        this.calculateETA();
        if (this.isRouteDelete == true){
          let eventName; 
          if (this.type == 'pickup') {
           eventName = 'delete_pick_waypoint'
          }
          if (this.type == 'dropoff') {
           eventName = 'delete_drop_waypoint'
          }
          var dimensions = null;
          if (this.waypointLocationLongName && this.type == 'pickup'){
           dimensions = 'pickupLocation=' + this.waypointLocationLongName;
          }
          if (this.waypointLocationLongName && this.type == 'dropoff'){
           dimensions = 'dropoffLocation=' + this.waypointLocationLongName;
          }
           var obj = {
             eventName: eventName,
             event_category: 'route_plan',
             userType: localStorage.getItem('user_type'),
             EmailID: localStorage.getItem('email'),
             Dimensions: dimensions ? dimensions : '-',
             ShipmentID: this.route.snapshot.params['loadId']
           };
           this.sharedService.track(obj);
        }
        let  miles:any;
        this.totalMiles =0
        for(let i=0; i<this.wayPoints.length-1 ; i++)
        { 
         miles =  await this.getRouteCompetability(this.wayPoints[i].latitude,this.wayPoints[i].longitude,this.wayPoints[i+1].latitude,this.wayPoints[i+1].longitude)
        this.totalMiles = miles['miles']+ this.totalMiles; 
       }
      }
    });
  }

  // Function to get Alert for drag and Drop
  dragDropAlert(type) {
    const dialogRef = this.dialog.open(PopupComponent, {
      disableClose: true,
      backdropClass: AppSettings.backdropClass,
      width: AppSettings.popWidth,
      data: {
        openPop: 'dragAndDropAlert',
        type: type,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
      }
    });
  }

   // Function to get dragDrop Alert
  dragDropAlertForCompleted(index: any) {
    this.type = this.wayPoints[index].type;
    const dialogRef = this.dialog.open(PopupComponent, {
      disableClose: true,
      backdropClass: AppSettings.backdropClass,
      width: AppSettings.popWidth,
      data: {
        openPop: 'dragAndDropCompleteAlert',
        type: this.type,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
      }
    });
  }

  // Function to check minimum 1 pickup and drop waypoint
  pickDropCheckAlert() {
    const dialogRef = this.dialog.open(PopupComponent, {
      disableClose: true,
      backdropClass: AppSettings.backdropClass,
      width: AppSettings.popWidth,
      data: {
        openPop: 'pickAndDropAlert',
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
      }
    });
  }

  // Function to edit waypoint
  editRoute(index: any): void {
    let oldWayPoint = [];
    oldWayPoint = JSON.parse(JSON.stringify(this.wayPoints));
    this.type = this.wayPoints[index].type;
    let selectedWayPointToEdit = this.wayPoints[index];
    let previousWayPointToEdit = this.wayPoints[index - 1];
    if(index === 0){
      previousWayPointToEdit = this.wayPoints[1];
    }
    let status = this.wayPoints[index].status;
    const dialogRef = this.dialog.open(PopupComponent, {
      disableClose: true,
      backdropClass: AppSettings.backdropClass,
      width: '1060px',
      data: {
        openPop: 'editMultiStop',
        type: this.type,
        loadDetail: this.loadDetail,
        selectedWayPointToEdit: selectedWayPointToEdit,
        previousWayPointToEdit: previousWayPointToEdit,
        index: index,
        wayPoints: this.wayPoints,
        waypointLength: this.wayPoints.length,
      },
    });
    dialogRef.afterClosed().subscribe(async(result) => {
      if (result) {
        if (result && result.updatedWayPoint) {
          this.isUpdatedWaypoint = result.updatedWayPoint.isUpdated;
          let filterData = [];
          let maxLocationOrder: number;
          let finalLocationOrder: any;

          if (result.isWayPointTypeChanged) {
            filterData = oldWayPoint.filter(function (x) {
              return x.type === result.updatedWayPoint.type;
            });
            maxLocationOrder = Math.max(
              ...filterData.map((o) => o.locationOrder)
            );
            finalLocationOrder = maxLocationOrder + 1;
            result.updatedWayPoint.locationOrder = finalLocationOrder;
           
            //delete previous type order by -1
            let originalType = this.wayPoints[index].type;
            let startingIndex = index + 1;
            while (
              startingIndex <= this.wayPoints.length - 1
            ) {
              if (
                this.wayPoints[startingIndex].type === originalType
              ) {
                this.wayPoints[startingIndex].locationOrder =
                  this.wayPoints[startingIndex].locationOrder - 1;
              }
              startingIndex++;
            }
          }

          status = result.updatedWayPoint.status;
          this.wayPoints[index] = result.updatedWayPoint;

          this.initialPickUps = this.wayPoints.filter(
            (x: { type: string; }) => x.type === 'pickup'
          ).length;
          this.initialDrops = this.wayPoints.filter(
            (x: { type: string; }) => x.type === 'dropoff'
          ).length;

          this.dateErrorMessage = false;
          this.checkDateValidation();
          this.calculateETA();
          let  miles:any;
          this.totalMiles =0
          for(let i=0; i<this.wayPoints.length-1 ; i++)
          { 
          miles =  await this.getRouteCompetability(this.wayPoints[i].latitude,this.wayPoints[i].longitude,this.wayPoints[i+1].latitude,this.wayPoints[i+1].longitude)
          this.totalMiles = miles['miles']+this.totalMiles; 
         }

          if (this.isUpdatedWaypoint == true){
            let eventName; 
            if (result.updatedWayPoint.type == 'pickup') {
             eventName = 'update_pick_waypoint'
            }
            if (result.updatedWayPoint.type == 'dropoff') {
             eventName = 'update_drop_waypoint'
            }
            var dimensions = null;
            if (result.updatedWayPoint.locationLongName && result.updatedWayPoint.type == 'pickup'){
             dimensions = 'pickupLocation=' + result.updatedWayPoint.locationLongName;
            }
            if (result.updatedWayPoint.locationLongName && result.updatedWayPoint.type == 'dropoff'){
             dimensions = 'dropoffLocation=' + result.updatedWayPoint.locationLongName;
            }
             var obj = {
               eventName: eventName,
               event_category: 'route_plan',
               userType: localStorage.getItem('user_type'),
               EmailID: localStorage.getItem('email'),
               Dimensions: dimensions ? dimensions : '-',
               ShipmentID: this.route.snapshot.params['loadId']
             };
             this.sharedService.track(obj);
         }
        }
       
      }
    });
  }

  // Function to add waypoint
  addRoute(types: any): void {
    let type: string;
    let previousWayPointToEdit: any;
    if (types === 'pick') {
      type = 'pickup';
      previousWayPointToEdit = this.wayPoints[this.wayPoints.length-2];
    }
    if (types === 'drop') {
      type = 'dropoff';
      previousWayPointToEdit = this.wayPoints[this.wayPoints.length-1];
    }

    const dialogRef = this.dialog.open(PopupComponent, {
      disableClose: true,
      backdropClass: AppSettings.backdropClass,
      width: '1020px',
      data: {
        openPop: 'addMultiStopRoute',
        type: type,
        loadDetail: this.loadDetail,
        previousWayPointToEdit: previousWayPointToEdit,
        wayPoints: this.wayPoints,
      },
    });
    dialogRef.afterClosed().subscribe(async (result) => {
      if (result && result.addNewWayPoint) {
        this.countMiles=true;
        if (result.addNewWayPoint.type === 'pickup') {
          this.initialPickUps = this.initialPickUps + 1;
          result.addNewWayPoint.locationOrder = this.initialPickUps;
          this.wayPoints.push(result.addNewWayPoint);
          this.dateErrorMessage = false;
          this.checkDateValidation();
          this.calculateETA();
        }
        if (result.addNewWayPoint.type === 'dropoff') {
          this.initialDrops = this.initialDrops + 1; //added new drop
          result.addNewWayPoint.locationOrder = this.initialDrops;
          this.wayPoints.push(result.addNewWayPoint);
          this.dateErrorMessage = false;
          this.checkDateValidation();
          this.calculateETA();
        }
        let  miles:any;
        this.totalMiles =0
        for(let i=0; i<this.wayPoints.length-1 ; i++)
        { 
         miles =  await this.getRouteCompetability(this.wayPoints[i].latitude,this.wayPoints[i].longitude,this.wayPoints[i+1].latitude,this.wayPoints[i+1].longitude)
        this.totalMiles = miles['miles']+this.totalMiles; 
       }
          let eventName; 
          if (result.addNewWayPoint.type == 'pickup') {
           eventName = 'create_pick_waypoint'
          }
          if (result.addNewWayPoint.type == 'dropoff') {
           eventName = 'create_drop_waypoint'
          }
          var dimensions = null;
          if (result.addNewWayPoint.locationLongName && result.addNewWayPoint.type == 'pickup'){
           dimensions = 'pickupLocation=' + result.addNewWayPoint.locationLongName;
          }
          if (result.addNewWayPoint.locationLongName && result.addNewWayPoint.type  == 'dropoff'){
           dimensions = 'dropoffLocation=' + result.addNewWayPoint.locationLongName;
          }
           var obj = {
             eventName: eventName,
             event_category: 'route_plan',
             userType: localStorage.getItem('user_type'),
             EmailID: localStorage.getItem('email'),
             Dimensions: dimensions ? dimensions : '-',
             ShipmentID: this.route.snapshot.params['loadId']
           };
          this.sharedService.track(obj);
      }
    });
  }

  // Function for date Time Edit Form Submit
  dateTimeEditFormSubmit({ value, valid }: any, index: string | number) {
    this.isTimeSloteExpired = value.pickupTime.disabled == true ? true :  false; 
    if (valid && !this.isTimeSloteExpired && !this.isTimeSloteAvailble) {
      this.pickupDateEdit = moment(value.pickupDate).format('MM/DD/YYYY');
      this.pickupTimeEdit = value.pickupTime.time;
      this.pickupTimezoneEdit = value.pickupTimeZone;
      this.wayPoints[index].date = this.pickupDateEdit;
      this.wayPoints[index].timeSlot = this.pickupTimeEdit;
      this.wayPoints[index].timeZone = this.pickupTimezoneEdit;
      if(this.wayPoints[index].status === 'In-Transit' && this.wayPointEditForm.dirty){
        this.wayPoints[index].status = 'Open'; 
        this.wayPoints[index].isCurrentRoute = true; 
      }
      else{
        this.wayPoints[index].isCurrentRoute = false; 
      }
      if(this.wayPointEditForm.dirty){
        this.wayPoints[index].isUpdated = true; 
      } 
      else{
        this.wayPoints[index].isUpdated = false; 
      }

      this.dateErrorMessage = false;
      this.edit_card = false;
      this.sharedService.setGuardValue();
      if (this.wayPoints[index].errorIndex) {
        this.wayPoints[index].errorIndex = false;
      }
      this.checkDateValidation();
      this.calculateETA();
    }
  }

  // Function to get pickup Date Event
  pickDateEvent(event1: any) {
    this.isTimeSloteAvailble = false;
    let currentDate = moment(event1.value).format('YYYY-MM-DD');
    this.checkIfTodaysDate(event1); 
    this.setMinMaxDate(currentDate); 
  }

  // Function to check Todays Date
  checkIfTodaysDate(event1){
     let selectedFormattedDate = moment(event1).format('YYYY-MM-DD');
     let today = new Date(); 
     let todayFormattedDate = moment(today).format('YYYY-MM-DD').valueOf();
     let ctTime = moment(today, 'hh:mm');
     if (todayFormattedDate === selectedFormattedDate) {
      this.pickupTimeArr.map((item)=>{
        let timeSlot   = item.time.split('-');
        let timeSlotToCompare =  moment(timeSlot[0], 'hh:mm'); 
        if( ctTime.isSameOrAfter(timeSlotToCompare)){
          item.disabled = true; 
        }
        else{
          item.disabled = false; 
        }
      });
     }
  }

   // Function to cancel edit form
  onNoClick() {
    this.edit_card = false;
    this.isTimeSloteAvailble = false;
    this.isTimeSloteExpired = false; 
  }

   // Function to check time Restriction
  timeRestriction(event: { value: string; }) {
    this.isTimeSloteExpired = false; 
    this.calemaxte = new Date(this.calemaxte);
    this.pickupTimeError = false;
    let now = new Date();
    const ctTime = moment(now).format('HH:00');
    const currentPickDate: any = moment(now).format('YYYY-MM-DD');

    /*check current time is less than ot grather thne 10pm*/
    let checkeCurrentTime = event.value.split('-');
    if (
      checkeCurrentTime[0].match(/22:00/g) ||
      checkeCurrentTime[0].match(/23:00/g)
    ) {
      let days = 1;
      this.calemaxte.setDate(this.calemaxte.getDate() + days);
      if (this.maxDate >= this.maxDateDropOff) {
      }
    } else if (this.pickupDate === currentPickDate) {
      this.calemaxte = now;
    } else {
      this.calemaxte = this.pickupDate;
    }
    if (this.pickupDate === this.olddropDate) {
      this.deliveryTimeShow = [];
    } else {
    }

    this.selectTime = event.value;
    if (this.pimaxDate && this.selectTime) {
      this.disabled1 = false;
    }
    const maxDateDropOffNew: any = moment(this.maxDateDropOff).format(
      'YYYY-MM-DD'
    );

    this.maxDateDropOff = maxDateDropOffNew;
    const timeDatById = this.dropTimeArr.filter(
      (item: { time: any; }) => item.time === event.value
    );
    this.timedat = timeDatById;
    this.deliveryTimeShow = [];

    for (let reIndex in this.dropTimeArr) {
      if (
        this.maxDate === currentPickDate &&
        this.maxDate === this.maxDateDropOff
      ) {
        if (this.dropTimeArr[reIndex].id > timeDatById[0].id) {
          this.dropTimeArr[reIndex].disabled = false;
          this.dropTimeArr[reIndex].time = this.dropTimeArr[reIndex].time;
        } else {
          this.dropTimeArr[reIndex].disabled = true;
          this.dropTimeArr[reIndex].time = this.dropTimeArr[reIndex].time;
        }
        if (this.pimaxDate && this.selectTime) {
          this.wayPointEditForm.get('deliveryTime').setValue('');
        }
      } else if (this.maxDate === this.maxDateDropOff) {
        if (this.dropTimeArr[reIndex].id > timeDatById[0].id) {
          this.dropTimeArr[reIndex].disabled = false;
          this.dropTimeArr[reIndex].time = this.dropTimeArr[reIndex].time;
        } else {
          this.dropTimeArr[reIndex].disabled = true;
          this.dropTimeArr[reIndex].time = this.dropTimeArr[reIndex].time;
        }
        this.wayPointEditForm.get('deliveryTime').setValue('');
      }
    }
    this.deliveryTimeShow = this.dropTimeArr;
    this.pickuptimeslot = checkeCurrentTime[0];
  }

 // Function to get Route Competability
  async getRouteCompetability(newlatitude,newlongitude,getCurrentLatitude,getCurrentLongitude) {
    return await this.hereMapsService.getRouteFound(newlatitude,newlongitude,getCurrentLatitude,getCurrentLongitude, 'truck').then(
      (hereMapsRoutingResponse) => {
        let miles:any; 
        if(hereMapsRoutingResponse.response.route[0].summary.distance==0){
          miles = 1 ;
         }else{
          miles = Math.floor(hereMapsRoutingResponse.response.route[0].summary.distance / 1609.344)
         }

      return { miles};
      },
       (error) => {
        return  this.hereMapsService.getRouteFound(newlatitude,newlongitude,getCurrentLatitude,getCurrentLongitude, 'car').then(
          (hereMapsRoutingResponse) => {
            this.showCarRoute();   // Show Car popup
          },
          (error) => {
             this.showNoRoute();       // Show no route popup,  set pickup loc null
          })
      });
  }

  // Function to show Car Route
  showCarRoute() {
    const dialogRef = this.dialog.open(PopupComponent, {
      disableClose: true,
      backdropClass: AppSettings.backdropClass,
      width: AppSettings.popWidth,
      data: {
        openPop: 'carRoutePlan'
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
      }
    });
  }

  // Function to show No Route
  showNoRoute() {
    const dialogRef = this.dialog.open(PopupComponent, {
      disableClose: true,
      backdropClass: AppSettings.backdropClass,
      width: AppSettings.popWidth,
      data: {
        openPop: 'noRoutePlan'
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
      }
    });
  }


}
