<div class="trac_shipmentCon">
    <app-before-user-login-navbar *ngIf="currentTab" [currentTab]="currentTab"></app-before-user-login-navbar>
   
    <div class="container" *ngIf="manTab==='track' && '!loginStatus'">
      <div class="track_ship_con">
        <div class="row m_center">
          <div class="col-md-6 flex_center">
            <div class="tab_container_r">
              <div class="tab_container_inpput bg_w log_reg_box">
                <p class="onboarding_h">Track by Load Id</p>
                <p class="fs_14 fsn_4 txt_lb">Need the status of your Load? Enter your tracking number below.</p>
                <div class="tab-content">
                  <div>
                    <form [formGroup]="trackForm" (ngSubmit)="trackSubmit(trackForm)" autocomplete="off" class="sign_up_tab" autocomplete="off">
                      <p class="fs_14 fsn_4 txt_lb">Load Id<span class="txt_d"> *</span></p>
                      <mat-form-field appearance="none" class="removeappearance">
                        <input matInput type="text" formControlName="shipmentId" placeholder="Enter load id"
                          class="form-control custom_input" />
                        <mat-error class="mt-1 error_mess"
                          *ngIf="submitted && trackForm.get('shipmentId').errors?.['required']">Load id is required
                        </mat-error>
                      </mat-form-field>
                      <span *ngIf="shipmentMessage" class="fs_12 fsn_4 txt_d">{{shipmentMessage}}</span><br>
                      <div class="col-md-12 px-0 py-2 my-4">
                        <button class="btn custum_button button_primary w-100" type="submit">Track your Load</button>
                      </div>
                    </form>
                    <div style="display: flex; justify-content: space-between;">
                      <p class="forgot_password mb-0"><u class="pointer fs_16 fsm_5 txt_p"
                          (click)="loginPage()">Sign In</u></p>
                      <p class="forgot_password mb-0"><u class="pointer fs_16 fsm_5 txt_p"><a
                            href="https://laneaxis.com/get-in-touch/" target="_blank">Need Help?</a></u>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="onboarding_img">
              <img src="https://s3.us-east-1.amazonaws.com/staticprod.laneaxis.com/load-id-landing-img.png"
                class="img-fluid">
            </div>
          </div>
        </div>
      </div>
    </div>
  
    <div *ngIf="manTab==='track_shipment'">
      <div class="page_con_header">
      
        <div class="container-fluid">
          <div class="container_header">
            <div class="backTO m-0">
              <span class="material-icons page_back" (click)="backHistory()">arrow_back</span>
            </div>
            <span class="shimentId_detail" *ngIf="shipmentDetail">{{shipmentDetail.title}} #{{shipmentDetail.uniqueId}}</span>
          </div>

          <div class="container_body_T">
            <div class="l_detail_card">
              <div class="d-flex align-items-center">
                <div class="map_img">
                  <img src="{{shipmentDetail?.routeImageUrlSm}}" class="img-fluid">
                </div>
                <div class="l_label_value">
                  <p class="l_label fs_14 txt_lb mb-2">Shipment Name</p>
                  <p class="l_value fs_18 fsm_5 text_truncate pointer mb-2"
                    matTooltip="{{shipmentDetail?.title}}">{{shipmentDetail?.title ? shipmentDetail?.title : '-'}}</p>
                  <div>
                    <span class="truck_lo_status">{{shipmentDetail?.equipmentName ? shipmentDetail?.equipmentName : '-'}}</span>
                    <span class="shipment_complete_date">{{shipmentDetail?.statusLabel}} at {{UTCDate(shipmentDetail?.updatedAt) | date: 'MMM dd, yyyy'}} at 
                    {{UTCDate(shipmentDetail?.updatedAt) | date: 'HH:mm'}}</span>
                  </div>
                </div>
              </div>
              <div class="d-flex">
              <div class="l_label_value">
                <p class="l_label fs_14 txt_lb mb-2">Updated On</p>
                <p class="l_value mb-0">{{UTCDate(shipmentDetail?.updatedAt) | date: 'MMM dd, yyyy'}} at {{UTCDate(shipmentDetail?.updatedAt)
                  |date: 'HH:mm'}}</p>
              </div>
          
              <div class="l_label_value ms-3">
                <p class="l_label fs_14 txt_lb mb-2">Created On</p>
                <p class="l_value mb-0">{{UTCDate(shipmentDetail?.createdAt) | date: 'MMM dd, yyyy'}} at {{UTCDate(shipmentDetail?.createdAt)
                  |date: 'HH:mm'}}</p>
              </div>
            </div>
            </div>
          </div>
        </div>
      </div>
      <div class="">
        <div class="track_ship_info container-fluid">
          <div class="row">
            <div class="col-md-12 res_tabs">
              <div class="track_ship_tabs" id="list-tab" role="tablist">
                <button class="sub_tabs" [ngClass]="currentTabShow == 'shipment' ? 'active' : ''" data-toggle="list" role="tab" (click)="currentTabActive('shipment')">Load Details</button>
                <button class="sub_tabs" [ngClass]="currentTabShow == 'waypoint' ? 'active' : ''" data-toggle="list" role="tab" (click)="currentTabActive('waypoint')">Waypoints</button>
                <button class="sub_tabs" [ngClass]="currentTabShow == 'driver' ? 'active' : ''" data-toggle="list" role="tab" (click)="currentTabActive('driver')">Driver</button>
                <button class="sub_tabs" [ngClass]="currentTabShow == 'load' ? 'active' : ''" data-toggle="list" role="tab" (click)="currentTabActive('load')">View Load History</button>
                <button class="sub_tabs" [ngClass]="currentTabShow == 'document' ? 'active' : ''" data-toggle="list" role="tab" (click)="currentTabActive('document')">Documents</button>
              </div>
            </div>
            <div class="col-md-12 mt-4">
              <div *ngIf="currentTabShow==='shipment'">
                <div *ngIf="!skeletonLoader && shipmentDetail">
                  <div class="detail_card_con shipCon_status">
                    <p class="line_before" style="margin-bottom: 0px;">Load Status</p>
                    <div class="status_info step{{levelLine}}">
                      <div class="status_info_first status_steps">
                        <span class="line_drow line1">
                          <span class="status_icon">
                            <img src="{{serverPathForUrl}}/portal/images/shipment-map-icon/assign.svg" class="img-fluid">
                          </span>
                          <div class="status_stepr">
                            <p class="mb-0 fs_14 fsn_4 txt_lb">{{createdDateTime | date: 'MMM dd, yyyy HH:mm'}}</p>
                            <p class="mb-0 fs_16 fsm_5 txt_b" *ngIf="shipmentDetail.driver">Driver Assigned</p>
                            <p class="mb-0 fs_16 fsm_5 txt_b" *ngIf="shipmentDetail.driver ===null">Driver Unassigned</p>
                            <p class="mb-0 fs_14 fsn_4 txt_lb mt-1" *ngIf="shipmentDetail.driver">{{shipmentDetail.driver.name}}</p>
                            <p class="mb-0 fs_14 fsn_4 txt_lb mt-1" *ngIf="shipmentDetail.driver ===null">-</p>
                          </div>
                        </span>
                      </div>
                      <div class="status_info_center status_steps">
                        <span class="line_drow line2">
                          <span class="status_icon">
                            <img src="{{serverPathForUrl}}/portal/images/shipment-map-icon/navigateToPickup.svg" class="img-fluid">
                          </span>
                          <div class="status_stepr">
                            <p class="mb-0 fs_14 fsn_4 txt_lb">{{waypointData[0]?.date | date: 'MMM dd, yyyy'}} {{waypointData[0]?.timeSlot}} {{waypointData[0]?.timeZone}}</p>
                            <p class="mb-0 fs_16 fsm_5 txt_b">Load Picked Up </p>
                            <p class="mb-0 fs_14 fsn_4 txt_lb mt-1">
                              <span class="icon-ic-location"></span>
                              <span>{{waypointData[0]?.locationLongName}}</span>
                            </p>
                          </div>
                        </span>
                      </div>
  
                      <div class="status_info_center status_steps">
                        <span class="line_drow line3">
                          <span class="status_icon">
                            <img src="{{serverPathForUrl}}/portal/images/shipment-map-icon/navigateToDropOff.svg" class="img-fluid">
                          </span>
                          <div class="status_stepr">
                            <p class="mb-0 fs_16 fsm_5 txt_b">In Transit</p>
                          </div>
                        </span>
                      </div>
  
                      <div class="status_info_center status_steps">
                        <span class="line_drow line4">
                          <span class="status_icon">
                            <img src="{{serverPathForUrl}}/portal/images/shipment-map-icon/atDropOffLocation.svg" class="img-fluid">
                          </span>
                          <div class="status_stepr">
                            <p class="mb-0 fs_14 fsn_4 txt_lb"> {{shipmentDetail.dropDate | date: 'MMM dd, yyyy'}} {{shipmentDetail.dropTimeslot}} {{shipmentDetail.dropTimeZone}} </p>
                            <p class="mb-0 fs_16 fsm_5 txt_b">Load ETA</p>
                          </div>
                        </span>
                      </div>
  
                      <div class="status_info_end status_steps" style="text-align: end; flex-basis: 8%;">
                        <span class="line_drow line5">
                          <span class="status_icon">
                            <img src="{{serverPathForUrl}}/portal/images/shipment-map-icon/delivered.svg" class="img-fluid">
                          </span>
                          <div class="status_stepr">
                            <p class="mb-0 fs_16 fsm_5 txt_b status_stepd">Delivered</p>
                          </div>
                        </span>
                      </div>
                    </div>
                  </div>
  
                  <div class="detail_card_con">
                    <p class="line_before">Shipper Information</p>
                    <div class="row">
                      <div class="col-md-9">
                        <div class="con_wrapper_row">
                          <div class="row_label">Shipper Name</div>
                          <div class="row_value" *ngIf="shipmentDetail">{{shipmentDetail.shipperName ?
                            shipmentDetail.shipperName:'-' }}</div>
                        </div>
                      </div>
                      <div class="col-md-3">
                        <div class="con_wrapper_row">
                          <div class="row_label">Shipper Email</div>
                          <div class="row_value" *ngIf="shipmentDetail" style="overflow-wrap: anywhere;">
                            {{shipmentDetail.shipperEmail ? shipmentDetail.shipperEmail : '-'}}</div>
                        </div>
                      </div>
                      <div class="col-md-9" *ngIf="shipmentDetail.shipperId">
                        <div class="con_wrapper_row">
                          <div class="row_label">Created On</div>
                          <div class="row_value" >{{createdDateTime ?
                            (createdDateTime | date: 'MMM dd, yyyy HH:mm') :'-'}}</div>
                        </div>
                      </div>
                    </div>
                  </div>
  
                  <div class="detail_card_con">
                    <p class="line_before">Carrier Information</p>
                    <div class="row">
                      <div class="col-md-9">
                        <div class="con_wrapper_row">
                          <div class="row_label">Carrier Name</div>
                          <div class="row_value" *ngIf="shipmentDetail">{{shipmentDetail.carrierLegalName ? shipmentDetail.carrierLegalName:'-' }}</div>
                        </div>
                      </div>
                      <div class="col-md-3">
                        <div class="con_wrapper_row">
                          <div class="row_label">Carrier Email</div>
                          <div class="row_value" *ngIf="shipmentDetail" style="overflow-wrap: anywhere;">{{shipmentDetail.carrierEmail ? shipmentDetail.carrierEmail : '-'}}</div>
                        </div>
                      </div>
                      <div class="col-md-9" *ngIf="!shipmentDetail.shipperId">
                        <div class="con_wrapper_row">
                          <div class="row_label">Created On</div>
                          <div class="row_value">{{createdDateTime ? (createdDateTime | date: 'MMM dd, yyyy HH:mm') : '-'}}</div>
                        </div>
                      </div>
                    </div>
                  </div>
  
                  <div class="detail_card_con">
                    <p class="line_before">Load Information</p>
                    <div class="con_wrapper">
                      <div class="row">
                        <div class="col-md-9">
                          <div class="con_wrapper_row">
                            <div class="row_label">Equipment Type</div>
                            <div class="row_value">{{shipmentDetail?.equipmentName ? shipmentDetail?.equipmentName : '-'}}
                            </div>
                          </div>
                        </div>
                        <div class="col-md-3">
                          <div class="con_wrapper_row">
                            <div class="row_label">Equipment Length</div>
                            <div class="row_value">{{shipmentDetail?.equipmentLength ? shipmentDetail?.equipmentLength + ' Feet': '-'}}</div>
                          </div>
                        </div>
                        <div class="col-md-9">
                          <div class="con_wrapper_row">
                            <div class="row_label">Purchase Order</div>
                            <div class="row_value">{{shipmentDetail.purchaseOrderId ? shipmentDetail.purchaseOrderId : '-'}}</div>
                          </div>
                        </div>
                        <div class="col-md-3">
                          <div class="con_wrapper_row">
                            <div class="row_label">Accessorials</div>
                            <div class="row_value">{{trailSpecialRequipment(shipmentDetail?.trailerSpecs)}}</div>
                          </div>
                        </div>
                        <div class="col-md-9" *ngIf="isTemprature">
                          <div class="con_wrapper_row">
                            <div class="row_label">Temperature Controlled</div>
                            <div class="row_value ms-1">{{shipmentDetail?.temperature ? shipmentDetail?.temperature: '-'}}
                              <span *ngIf="shipmentDetail?.temperature">F</span></div>
                            </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
  
                <!-- skelaton loader for shipper detail -->
                <div class="skelaton_shipper_view" *ngIf="skeletonLoader">
                  <div class="list-view">
                    <div class="list-card-block pb-1" style="min-height: 190px;">
                      <h3 class="mb-0">
                        <ngx-skeleton-loader count="1" [theme]="{ width: '30%', height: '15px'}"></ngx-skeleton-loader>
                      </h3>
                      <ngx-skeleton-loader count="1" [theme]="{ width: '100%', height: '30px'}"></ngx-skeleton-loader>
  
                      <div class="list-lable-wrapper mb-0">
                        <div class="list-lable-placeholder loader_mb_0" *ngFor="let fake of generateFake(3)">
                          <ngx-skeleton-loader count="1" [theme]="{ width: '50%', height: '10px'}"></ngx-skeleton-loader>
                          <ngx-skeleton-loader count="1" [theme]="{ width: '80%', height: '14px'}"></ngx-skeleton-loader>
                          <ngx-skeleton-loader count="1" [theme]="{ width: '80%', height: '10px'}"></ngx-skeleton-loader>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="list-view">
                    <div class="list-card-block pb-1">
                      <h3 class="mb-3">
                        <ngx-skeleton-loader count="1" [theme]="{ width: '50%', height: '15px'}"></ngx-skeleton-loader>
                      </h3>
                      <div class="list-lable-wrapper mb-3">
                        <div class="list-lable-placeholder" *ngFor="let fake of generateFake(3)">
                          <ngx-skeleton-loader count="1" [theme]="{ width: '50%', height: '15px'}"></ngx-skeleton-loader>
                          <ngx-skeleton-loader count="1" [theme]="{ width: '80%', height: '15px'}"></ngx-skeleton-loader>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="list-view">
                    <div class="list-card-block pb-1">
                      <h3 class="mb-3">
                        <ngx-skeleton-loader count="1" [theme]="{ width: '50%', height: '15px'}"></ngx-skeleton-loader>
                      </h3>
                      <div class="list-lable-wrapper mb-3">
                        <div class="list-lable-placeholder" *ngFor="let fake of generateFake(3)">
                          <ngx-skeleton-loader count="1" [theme]="{ width: '50%', height: '15px'}"></ngx-skeleton-loader>
                          <ngx-skeleton-loader count="1" [theme]="{ width: '80%', height: '15px'}"></ngx-skeleton-loader>
                        </div>
                      </div>
                      <div class="list-lable-wrapper mb-3">
                        <div class="list-lable-placeholder" *ngFor="let fake of generateFake(3)">
                          <ngx-skeleton-loader count="1" [theme]="{ width: '50%', height: '15px'}"></ngx-skeleton-loader>
                          <ngx-skeleton-loader count="1" [theme]="{ width: '80%', height: '15px'}"></ngx-skeleton-loader>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="list-view">
                    <div class="list-card-block pb-1">
                      <h3 class="mb-3">
                        <ngx-skeleton-loader count="1" [theme]="{ width: '50%', height: '15px'}"></ngx-skeleton-loader>
                      </h3>
                      <div class="list-lable-wrapper mb-3">
                        <div class="list-lable-placeholder" *ngFor="let fake of generateFake(3)">
                          <ngx-skeleton-loader count="1" [theme]="{ width: '50%', height: '15px'}"></ngx-skeleton-loader>
                          <ngx-skeleton-loader count="1" [theme]="{ width: '80%', height: '15px'}"></ngx-skeleton-loader>
                        </div>
                      </div>
                      <div class="list-lable-wrapper mb-3">
                        <div class="list-lable-placeholder" *ngFor="let fake of generateFake(3)">
                          <ngx-skeleton-loader count="1" [theme]="{ width: '50%', height: '15px'}"></ngx-skeleton-loader>
                          <ngx-skeleton-loader count="1" [theme]="{ width: '80%', height: '15px'}"></ngx-skeleton-loader>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="list-view">
                    <div class="list-card-block pb-1">
                      <h3 class="mb-3">
                        <ngx-skeleton-loader count="1" [theme]="{ width: '50%', height: '15px'}"></ngx-skeleton-loader>
                      </h3>
                      <div class="list-lable-wrapper mb-3">
                        <div class="list-lable-placeholder" *ngFor="let fake of generateFake(3)">
                          <ngx-skeleton-loader count="1" [theme]="{ width: '50%', height: '15px'}"></ngx-skeleton-loader>
                          <ngx-skeleton-loader count="1" [theme]="{ width: '80%', height: '15px'}"></ngx-skeleton-loader>
                        </div>                    </div>
                      <div class="list-lable-wrapper mb-3">
                        <div class="list-lable-placeholder" *ngFor="let fake of generateFake(3)">
                          <ngx-skeleton-loader count="1" [theme]="{ width: '50%', height: '15px'}"></ngx-skeleton-loader>
                          <ngx-skeleton-loader count="1" [theme]="{ width: '80%', height: '15px'}"></ngx-skeleton-loader>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
  
              <div *ngIf="currentTabShow ==='waypoint'">
                <div *ngIf="waypointSelekton"> 
                   <app-waypoint-detail [waypoint]="waypointData"></app-waypoint-detail>
                </div>
                <div class="skelaton_con" *ngIf="!waypointSelekton">
                  <ngx-skeleton-loader *ngFor="let fake of generateFake(10)" count="1" [theme]="{ width: '100%', height: '15px'}"></ngx-skeleton-loader>
                </div>
              </div>
  
              <div class="dri_detail_tab" *ngIf="currentTabShow==='driver'">
                <div *ngIf="driverDetails === ''">
                  <div class="Empty_State_body mx-0">
                    <div class="Empty_State_con">
                      <div class="Empty_State_img">
                        <img class="img-fluid" src="{{serverPathForUrl}}/portal/images/after-login/Empty_State_icons/graphic_no-driver.svg">
                      </div>
                      <p class="fs_24 fsb_6 txt_b pb-1" style="margin: 30px 0 12px;">No Driver assigned… <span class="txt_p"> yet</span> </p>
                      <p class="fs_16 fsn_4 txt_lb pb-1" style="margin: 0px 0 20px;">When you assigned a driver, it will appear here. Use the LaneAxis Mobile Application Or portal to search the driver, & assign Load.</p>
                    </div>
                  </div>
                </div>
  
                <div *ngIf="driverDetails !==''">
                  <div class="detail_card_con" *ngIf="!documentSklitor && driverDetails !==''">
                    <div class="dri_card_head">
                      <p class="line_before mb-0">Assigned Driver</p>
                    </div>
                    <div class="dri_profile">
                      <div class="dri_name_img">
                        <span class="dri_img" *ngIf="driverDetails?.driverProfile">
                          <img src="{{driverDetails?.driverProfile}}" class="img-fluid">
                        </span>
                        <span class="dri_img" *ngIf="!driverDetails?.driverProfile">
                          <span class="initial_name initial_r_44">{{driverDetails.name | acronymOfName}}</span>
                        </span>
                        <p class="dri_name">{{driverDetails.name}}</p>
                      </div>
                    </div>
                    <div class="dir_othe_details">
                      <div class="dod_1">
                        <div class="row_label mb-1">Current Location</div>
                        <div class="row_value" *ngIf="currentlocation">{{currentlocation?.fullAddress ? currentlocation?.fullAddress : '-'}}</div>
                      </div>
                      <div class="dod_2">
                        <div class="row_label mb-1">Miles Completed</div>
                        <div *ngIf="gifLoader !== false">
                          <img src="{{serverPathForUrl}}/portal/images/after-login/LaneAxis-loader.gif" style="max-height: 16px;">
                        </div>
                        <div class="row_value" *ngIf="shipmentDetail.miles">{{shipmentDetail?.tripStatus?.milesCompleted ? shipmentDetail?.tripStatus?.milesCompleted : '0'}} Miles</div>
                      </div>
                      <div class="dod_3">
                        <div class="row_label mb-1">ETA</div>
                        <div *ngIf="gifLoader !== false">
                          <img src="{{serverPathForUrl}}/portal/images/after-login/LaneAxis-loader.gif" style="max-height: 16px;">
                        </div>
                        <div class="row_value" *ngIf="statusInformation">
                          <span>{{statusInformation | date: 'MMM dd, yyyy'}}</span>
                          <span class="ms-2">{{statusInformation | date: 'HH:mm:ss'}}</span>
                        </div>
                        <div *ngIf="!statusInformation"><span>-</span></div>
                      </div>
                    </div>
                    <div class="dir_othe_details">
                      <div class="dod_1">
                        <div class="row_label mb-1">CDL No.</div>
                        <div class="row_value">{{(driverDetails?.cdlNumber && driverDetails?.cdlNumber!=='null') ?
                          driverDetails?.cdlNumber : '-'}}</div>
                      </div>
                      <div class="dod_2">
                        <div class="row_label mb-1">Equipment Type</div>
                        <div class="row_value"> {{checkEquipmentType(shipmentDetail?.driver?.equipmentType)}}</div>
                      </div>
                    </div>
                  </div>
  
                  <div class="skelaton_con" *ngIf="documentSklitor">
                    <ngx-skeleton-loader count="1" [theme]="{ width: '70%', height: '22px'}"></ngx-skeleton-loader>
                    <ngx-skeleton-loader count="1" [theme]="{ width: '100%', height: '15px'}"></ngx-skeleton-loader>
                    <ngx-skeleton-loader count="1" [theme]="{ width: '100%', height: '15px'}"></ngx-skeleton-loader>
                    <ngx-skeleton-loader count="1" [theme]="{ width: '100%', height: '15px'}"></ngx-skeleton-loader>
                    <ngx-skeleton-loader count="1" [theme]="{ width: '100%', height: '15px'}"></ngx-skeleton-loader>
                  </div>
                </div>
              </div>
  
              <!-- Load History -->
              <div class="load_detail_tab" *ngIf="currentTabShow==='load'">
                <div class="detail_card_con">
                  <app-trip-history [shipmentId]="SplitedID" [typeOfSection]="'shipment-view'"></app-trip-history>
                </div>
              </div>
  
              <!-- Documents -->
              <div class="doc_detail_tab" *ngIf="currentTabShow==='document'">
                <app-document-multiple [shipmentId]="SplitedID" [loadDetail]="shipmentDetail" [type]="'track-shipment'"></app-document-multiple>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  