import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { environment } from '../../../environments/environment';
import {
  STATUS_COLOR_CODES,
  STATUS_CODE,
  STATUS,
} from '../setting/Heremaps.constants';
declare var require: any;
declare var H: any;

@Injectable({
  providedIn: 'root',
})
export class HereMapsService {
  private platform: any;
  public waypoint: any;
  constructor() {}

  calculateRoutewithCallingHereMaps(shipment) {
    let lastStatus = '';
    let barColor = '';
    if (shipment.dropStatus && shipment.dropStatus === 'on_time') {
      lastStatus = STATUS.ON_TIME;
      barColor = STATUS_COLOR_CODES.GREEN;
    } else if (shipment.dropStatus && shipment.dropStatus === 'delayed') {
      lastStatus = STATUS.DELAYED;
      barColor = STATUS_COLOR_CODES.RED;
    } else if (shipment.dropStatus && shipment.dropStatus === 'pot_delayed') {
      lastStatus = STATUS.POT_DELAYED;
      barColor = STATUS_COLOR_CODES.YELLOW;
    }
 
    return this.getTripResponse(
      barColor,
      lastStatus,
      '',
      '',
      'To Drop-Off',
      shipment.miles,
      shipment
    );
  }

  calculateRoute(shipment) {
    this.platform = new H.service.Platform({
      apikey: environment.heremapapiKey,
    });
    if (
      shipment &&
      shipment.pickupLocationLat &&
      shipment.pickupLocationLong &&
      shipment.dropLocationLat &&
      shipment.dropLocationLong &&
      shipment.drivers
    ) {
      let pickupLocation =
        'geo!' + shipment.pickupLocationLat + ',' + shipment.pickupLocationLong;
      let dropLocation =
        'geo!' + shipment.dropLocationLat + ',' + shipment.dropLocationLong;
      var routingParameters = {};
      let currentLocation =
        'geo!' + shipment.drivers.latitude + ',' + shipment.drivers.longitude;
      if (shipment.status < STATUS_CODE.ATPICKUPLOCATION) {
        routingParameters = {
          mode: 'fastest;truck;traffic:disabled',
          waypoint0: currentLocation,
          waypoint1: pickupLocation,
          waypoint2: dropLocation,
          routeattributes: 'none,summary',
        };
      } else {
        routingParameters = {
          mode: 'fastest;truck;traffic:disabled;',
          waypoint0: currentLocation,
          waypoint1: dropLocation,
          routeattributes: 'none,summary',
        };
      }
      var router = this.platform.getRoutingService(null, 7);
      return router.calculateRoute(
        routingParameters,
        (result) => {
          return result;
        },
        (error) => {
          return this.getTripResponse(
            STATUS_COLOR_CODES.BLUE,
            STATUS.NO_ROUTE,
            '',
            '',
            '',
            0,
            shipment
          );
        }
      );
    } else
      return this.getTripResponse(
        STATUS_COLOR_CODES.BLUE,
        STATUS.NO_ROUTE,
        '',
        '',
        '',
        0,
        shipment
      );
  }

  calculateRouteForTwoPoints(shipment, pickupPoint, dropPoint) {
    this.platform = new H.service.Platform({
      apikey: environment.heremapapiKey,
    });

    if (shipment && shipment.drivers && pickupPoint) {
      let firstWaypoint =
        'geo!' + pickupPoint.latitude + ',' + pickupPoint.longitude;
      let secondWaypoint = null;
      if (dropPoint)
        secondWaypoint =
          'geo!' + dropPoint.latitude + ',' + dropPoint.longitude;
      var routingParameters = {};
      let currentLocation =
        'geo!' + shipment.drivers.latitude + ',' + shipment.drivers.longitude;
      if (shipment.status < STATUS_CODE.ATPICKUPLOCATION && secondWaypoint) {
        routingParameters = {
          mode: 'fastest;truck;traffic:disabled',
          waypoint0: currentLocation,
          waypoint1: firstWaypoint,
          waypoint2: secondWaypoint,
          routeattributes: 'none,summary',
        };
      } else {
        routingParameters = {
          mode: 'fastest;truck;traffic:disabled;',
          waypoint0: currentLocation,
          waypoint1: firstWaypoint,
          routeattributes: 'none,summary',
        };
      }
      var router = this.platform.getRoutingService(null, 7);
      return router.calculateRoute(
        routingParameters,
        (result) => {
          return result;
        },
        (error) => {
          return this.getTripResponse(
            STATUS_COLOR_CODES.BLUE,
            STATUS.NO_ROUTE,
            '',
            '',
            '',
            0,
            shipment
          );
        }
      );
    } else
      return this.getTripResponse(
        STATUS_COLOR_CODES.BLUE,
        STATUS.NO_ROUTE,
        '',
        '',
        '',
        0,
        shipment
      );
  }

  multiWayPointPayload(waypoints) {
    let routingParameters = {
      mode: 'fastest;truck;traffic:disabled',
      routeattributes: 'none,summary',
    };
    let index = 0;
    for (let i = 0; i < waypoints.length; i++) {
      if (waypoints[i].status !== 'Completed') {
        routingParameters['waypoint' + (index + 1)] =
          'geo!' + waypoints[i].latitude + ',' + waypoints[i].longitude;
        index++;
      }
    }
    return routingParameters;
  }

  multiWayPointPayloadRoute(waypoints: any, type) {
    let routingParameters = {
      mode: 'fastest;' + type + ';traffic:disabled',
      routeattributes: 'none,summary',
    };
    let index = 0;
    for (let i = 0; i < waypoints.length; i++) {
      routingParameters['waypoint' + index] =
        'geo!' + waypoints[i].latitude + ',' + waypoints[i].longitude;
      index++;
    }
    return routingParameters;
  }

  calculateMultiRouteFind(waypoint: any, type: any) {
    this.platform = new H.service.Platform({
      apikey: environment.heremapapiKey,
    });
    let multiWaypointPayload = this.multiWayPointPayloadRoute(waypoint, type);
    var router = this.platform.getRoutingService(null, 7);
    return router.calculateRoute(
      multiWaypointPayload,
      (result) => {
        return result;
      },
      (error) => {
        return 'route not found';
      }
    );
  }

  calculateMultiRoute(shipment, waypoint) {
    this.platform = new H.service.Platform({
      apikey: environment.heremapapiKey,
    });
    if (shipment && waypoint) {
      let multiWaypointPayload = this.multiWayPointPayload(waypoint);
      let latitude =
        shipment.drivers && shipment.drivers.latitude            // DYNAMIC current location
          ? 'geo!' + shipment.drivers.latitude
          : 'geo!' + waypoint[0].latitude;
      let longitude =
        shipment.drivers && shipment.drivers.longitude
          ? shipment.drivers.longitude
          : waypoint[0].longitude;
      let currentLocation = latitude + ',' + longitude;

      multiWaypointPayload['waypoint0'] = currentLocation;

      if (shipment.status < STATUS_CODE.ATPICKUPLOCATION) {
        multiWaypointPayload;
      } else {
        multiWaypointPayload;
      }
      var router = this.platform.getRoutingService(null, 7);
      return router.calculateRoute(
        multiWaypointPayload,
        (result) => {
          return result;
        },
        (error) => {
          return this.getTripResponse(
            STATUS_COLOR_CODES.BLUE,
            STATUS.NO_ROUTE,
            '',
            '',
            '',
            0,
            shipment
          );
        }
      );
    } else
      return this.getTripResponse(
        STATUS_COLOR_CODES.BLUE,
        STATUS.NO_ROUTE,
        '',
        '',
        '',
        0,
        shipment
      );
  }

  getTripStatus(colorcode, status){
    return {
      Color : colorcode,
      Text :  status
    }
  }

  getTripStatusForCompletedWayPoint(arrivedAt, dropDate, timeSlot){
    if(arrivedAt && dropDate && timeSlot){
      dropDate = dropDate.replaceAll('-', '/');
           if (moment(arrivedAt).isBefore(dropDate, 'day')) { 
        return this.getTripStatus(STATUS_COLOR_CODES.GREEN, STATUS.ON_TIME); 
      }
      else if (moment(arrivedAt).isSame(dropDate, 'day')) {
        var drop_last_TimeSlot = timeSlot.split(/[ -]+/);
        let dropTime = drop_last_TimeSlot[1];
        let dropDateObj = dropDate + " " + dropTime;
        let dropTimestamp = new Date(dropDateObj).getTime();
        let arrivedAtts = arrivedAt.valueOf(); // arrival time in ms
        if(arrivedAtts - dropTimestamp > 1.0 * 3.6e6){
          return this.getTripStatus(STATUS_COLOR_CODES.RED, STATUS.DELAYED); 
        }
        if (dropTimestamp > arrivedAtts) {
          return this.getTripStatus(STATUS_COLOR_CODES.GREEN, STATUS.ON_TIME); 
        } else if (dropTimestamp - arrivedAtts > 1.0 * 3.6e6) {
          return this.getTripStatus(STATUS_COLOR_CODES.RED, STATUS.DELAYED); 
        } else if (
          // dropTimestamp - arrivedAtts < 1.0 * 3.6e6 && dropTimestamp - arrivedAtts > 0.2 * 3.6e6  // (PREVIOUS CODE FOR Potentially Delayed)
          arrivedAtts - dropTimestamp < 1.0 * 3.6e6 && arrivedAtts - dropTimestamp > 0.2 * 3.6e6     // (NEW CODE FOR Potentially Delayed)  
        ) {
          return this.getTripStatus(STATUS_COLOR_CODES.YELLOW, STATUS.POT_DELAYED); 
        } else if (dropTimestamp - arrivedAtts < 0.2 * 3.6e6) {
          return this.getTripStatus(STATUS_COLOR_CODES.GREEN, STATUS.ON_TIME); 
        }
      } 
      else{
        return this.getTripStatus(STATUS_COLOR_CODES.RED, STATUS.DELAYED); 
      }
     }
  }

  calculateTripStatus(hereMapsRoutingResponse, shipment, routePlan=false) {
    if (hereMapsRoutingResponse) {
      let destinationTime;
      let ETAFor = '';
      let tzCityName;
      let currentTime;
      let currentTS;
      let shipmentTz;

      ETAFor = 'To Drop-Off';
      tzCityName = this.getTimeZone(shipment.dropTimeZone);
      let current;
      var moment = require('moment-timezone');
      if(tzCityName){
     
        currentTime = moment().tz(tzCityName).format('MM/DD/YYYY HH:mm');
        currentTS = Date.parse(currentTime);
      }
      const dropTime = shipment.dropTimeslot.split(' - ');
      let dropDateAndTime;
      let dropDate = moment(shipment.dropDate).format('MM/DD/YYYY'); 
      shipment.dropDate = dropDate.replaceAll('-', '/');
      if (dropTime[1] === '00:00') {
        shipment.dropDate = moment(shipment.dropDate).add(1, 'days');
        dropDateAndTime =
          moment(shipment.dropDate).format('MM/DD/YYYY') + ' ' + dropTime[1];
      } else dropDateAndTime = shipment.dropDate + ' ' + dropTime[1];
      
      shipmentTz = moment(dropDateAndTime).format('MM/DD/YYYY HH:mm');
      destinationTime = Date.parse(shipmentTz);
      if (!shipment.drivers && !routePlan) {
        return this.getTripResponse(
          STATUS_COLOR_CODES.BLUE,
          STATUS.LOCATION_DISABLED,
          '',
          '',
          '',
          0,
          shipment
        );
      }
      let mapETAInMS, milesLeft;
      let milesCompleted = 0;
      if (hereMapsRoutingResponse.response) {
        mapETAInMS =
          hereMapsRoutingResponse.response.route[0].summary.trafficTime * 1000;
        milesLeft = Math.floor(
          hereMapsRoutingResponse.response.route[0].summary.distance / 1609.344
        );
        if (
          shipment &&
          shipment.status > STATUS_CODE.VERIFYING_DOCUMENTS_PICKUP_LOCATION
        ) {
          milesCompleted = Math.abs(shipment.miles - milesLeft);
        }
      } else {
        return this.getTripResponse(
          STATUS_COLOR_CODES.BLUE,
          STATUS.NO_ROUTE,
          '',
          '',
          '',
          0,
          shipment
        );
      }
      let mapETA = this.msToTime(mapETAInMS);
      let exactTravelTimeRemaining = destinationTime - currentTS;
      const diffTime = Math.abs(mapETAInMS - exactTravelTimeRemaining);
      let delayTime = this.msToTime(diffTime);
      if (exactTravelTimeRemaining > mapETAInMS) {
        return this.getTripResponse(
          STATUS_COLOR_CODES.GREEN,
          STATUS.ON_TIME,
          mapETAInMS,
          mapETA[1],
          ETAFor,
          milesCompleted,
          shipment
        );
      } else if (mapETAInMS - exactTravelTimeRemaining > 1.0 * 3.6e6) {
        return this.getTripResponse(
          STATUS_COLOR_CODES.RED,
          STATUS.DELAYED,
          mapETAInMS,
          mapETA[1],
          ETAFor,
          milesCompleted,
          shipment
        );
      } else if (
        mapETAInMS - exactTravelTimeRemaining < 1.0 * 3.6e6 &&
        mapETAInMS - exactTravelTimeRemaining > 0.2 * 3.6e6
      ) {
        return this.getTripResponse(
          STATUS_COLOR_CODES.YELLOW,
          STATUS.POT_DELAYED,
          mapETAInMS,
          mapETA[1],
          ETAFor,
          milesCompleted,
          shipment
        );
      } else if (mapETAInMS - exactTravelTimeRemaining < 0.2 * 3.6e6) {
        return this.getTripResponse(
          STATUS_COLOR_CODES.GREEN,
          STATUS.ON_TIME,
          mapETAInMS,
          mapETA[1],
          ETAFor,
          milesCompleted,
          shipment
        );
      }
    } else {
      return this.getTripResponse(
        STATUS_COLOR_CODES.BLUE,
        STATUS.NO_ROUTE,
        '',
        '',
        '',
        0,
        shipment
      );
    }
  }

  // check for new fucntion

  calculateTripStatusNew(hereMapsRoutingResponse, shipment, wayIndex ,routePlan=false) {
    if (hereMapsRoutingResponse) {
      let destinationTime;
      let ETAFor = '';
      let tzCityName;
      let currentTime;
      let currentTS;
      let shipmentTz;

      ETAFor = 'To Drop-Off';
      tzCityName = this.getTimeZone(wayIndex.timeZone);
      let current;
      var moment = require('moment-timezone');
      if(tzCityName){
        currentTime = moment().tz(tzCityName).format('MM/DD/YYYY HH:mm');
        currentTS = Date.parse(currentTime);
      }
      const dropTime = wayIndex.timeSlot.split(' - ');
      let dropDateAndTime;
      let dropDate = moment(wayIndex.date).format('MM/DD/YYYY'); 
      wayIndex.date = dropDate.replaceAll('-', '/');
      if (dropTime[1] === '00:00') {
        wayIndex.date = moment(wayIndex.date).add(1, 'days');
        dropDateAndTime = moment(wayIndex.date).format('MM/DD/YYYY') + ' ' + dropTime[1];
      } else{
        dropDateAndTime = wayIndex.date + ' ' + dropTime[1];
      } 
      shipmentTz = moment(dropDateAndTime).format('MM/DD/YYYY HH:mm');
      destinationTime = Date.parse(shipmentTz);

      if (!shipment.drivers && !routePlan) {
        return this.getTripResponse(
          STATUS_COLOR_CODES.BLUE,
          STATUS.LOCATION_DISABLED,
          '',
          '',
          '',
          0,
          shipment
        );
      }
      let mapETAInMS, milesLeft;
      let milesCompleted = 0;
      if (hereMapsRoutingResponse.response) {
        mapETAInMS = hereMapsRoutingResponse.response.route[0].summary.trafficTime * 1000;
        milesLeft = Math.floor(hereMapsRoutingResponse.response.route[0].summary.distance / 1609.344);
        if (
          wayIndex && wayIndex.status > STATUS_CODE.VERIFYING_DOCUMENTS_PICKUP_LOCATION
        ) {
          milesCompleted = Math.abs(shipment.miles - milesLeft);
        }
      } else {
        return this.getTripResponse(
          STATUS_COLOR_CODES.BLUE,
          STATUS.NO_ROUTE,
          '',
          '',
          '',
          0,
          shipment
        );
      }
      let mapETA = this.msToTime(mapETAInMS);
      let exactTravelTimeRemaining = destinationTime - currentTS;
      const diffTime = Math.abs(mapETAInMS - exactTravelTimeRemaining);
      let delayTime = this.msToTime(diffTime);
      if (exactTravelTimeRemaining > mapETAInMS) {
        return this.getTripResponse(
          STATUS_COLOR_CODES.GREEN,
          STATUS.ON_TIME,
          mapETAInMS,
          mapETA[1],
          ETAFor,
          milesCompleted,
          shipment
        );
      } else if (mapETAInMS - exactTravelTimeRemaining > 1.0 * 3.6e6) {
        return this.getTripResponse(
          STATUS_COLOR_CODES.RED,
          STATUS.DELAYED,
          mapETAInMS,
          mapETA[1],
          ETAFor,
          milesCompleted,
          shipment
        );
      } else if (
        mapETAInMS - exactTravelTimeRemaining < 1.0 * 3.6e6 &&
        mapETAInMS - exactTravelTimeRemaining > 0.2 * 3.6e6
      ) {
        return this.getTripResponse(
          STATUS_COLOR_CODES.YELLOW,
          STATUS.POT_DELAYED,
          mapETAInMS,
          mapETA[1],
          ETAFor,
          milesCompleted,
          shipment
        );
      } else if (mapETAInMS - exactTravelTimeRemaining < 0.2 * 3.6e6) {
        return this.getTripResponse(
          STATUS_COLOR_CODES.GREEN,
          STATUS.ON_TIME,
          mapETAInMS,
          mapETA[1],
          ETAFor,
          milesCompleted,
          shipment
        );
      }
    } else {
      return this.getTripResponse(
        STATUS_COLOR_CODES.BLUE,
        STATUS.NO_ROUTE,
        '',
        '',
        '',
        0,
        shipment
      );
    }
  }

  getTripResponse(
    colorCode,
    statusText,
    ETA,
    EtawithColon,
    ETAFor,
    milesCompleted,
    shipment
  ) {
    const tripResponse = {
      Color: colorCode,
      Text: statusText,
      ETA: ETA,
      ETAWithColon: EtawithColon,
      ETAFor: ETAFor,
      milesCompleted: milesCompleted,
      latitude:
        shipment.drivers && shipment.drivers.latitude
          ? shipment.drivers.latitude
          : '-',
      longitude:
        shipment.drivers && shipment.drivers.longitude
          ? shipment.drivers.longitude
          : '-',
    };
    return tripResponse;
  }

  getTimeZone(timeZoneLabel) {
    let timeZone;
    if (timeZoneLabel === 'PST') {
      timeZone = 'America/Los_Angeles';
    } else if (timeZoneLabel === 'MST') {
      timeZone = 'America/Denver';
    }
    if (timeZoneLabel === 'CST') {
      timeZone = 'America/Chicago';
    }
    if (timeZoneLabel === 'EST') {
      timeZone = 'America/New_York';
    }
    if (timeZoneLabel === 'HST') {
      timeZone = 'Pacific/Honolulu';
    }
    if (timeZoneLabel === 'AKST') {
      timeZone = 'America/Anchorage';
    }
    return timeZone;
  }

  msToTime(timeStamp: any) {
    if (timeStamp) {
      let minutes = Math.floor((timeStamp / (1000 * 60)) % 60);
      let hours = Math.floor((timeStamp / (1000 * 60 * 60)) % 24);
      let days = Math.floor(timeStamp / (1000 * 60 * 60 * 24));
      let hours_converted = hours < 10 ? '0' + hours : hours;
      let minutes_converted = minutes < 10 ? '0' + minutes : minutes;
      let days_converted = days < 10 ? '0' + days : days;

      if (days_converted !== undefined && +days_converted > 0) {
        const tsWithStr =
          days_converted +
          ' days ' +
          hours_converted +
          ' hours ' +
          minutes_converted +
          ' minutes';
        const tsWithColon =
          days_converted + ':' + hours_converted + ':' + minutes_converted;
        return [tsWithStr, tsWithColon];
      } else {
        const tsWithStr = hours_converted + ' hours ' + minutes + ' minutes';
        const tsWithColon = '00:' + hours_converted + ':' + minutes;
        return [tsWithStr, tsWithColon];
      }
    } else {
      const tsWithStr = '00' + ' hours ' + '00' + ' minutes';
      const tsWithColon = '00:' + ':' + '00';
      return [tsWithStr, tsWithColon];
    }
  }

  calculateMilesTime(shipment) {
    this.platform = new H.service.Platform({
      apikey: environment.heremapapiKey,
    });
    if (
      shipment.pickupLocationLat &&
      shipment.pickupLocationLong &&
      shipment.pickupLocationLat &&
      shipment.pickupLocationLong
    ) {
      let pickupLocation =
        'geo!' + shipment.pickupLocationLat + ',' + shipment.pickupLocationLong;
      let dropLocation =
        'geo!' + shipment.pickupLocationLat + ',' + shipment.pickupLocationLong;
      var routingParameters = {};

      routingParameters = {
        mode: 'fastest;truck;traffic:disabled;',
        waypoint0: pickupLocation,
        waypoint1: dropLocation,
        routeattributes: 'none,summary',
      };

      var router = this.platform.getRoutingService(null, 7);
      router.calculateRoute(
        routingParameters,
        (result: any) => {
          return result;
        },
        (error: any) => {
          return 'error occuring';
        }
      );
    }
  }

  // Rohit I will mage code padmini mam

  async getRouteFound(
    newlatitude: any,
    newlongitude: any,
    getCurrentLatitude: any,
    getCurrentLongitude: any,
    type: any
  ) {
    this.platform = new H.service.Platform({
      apikey: environment.heremapapiKey,
    });
    if (
      newlatitude &&
      newlongitude &&
      getCurrentLatitude &&
      getCurrentLongitude
    ) {
      let pickupLocation = 'geo!' + newlatitude + ',' + newlongitude;
      let dropLocation =
        'geo!' + getCurrentLatitude + ',' + getCurrentLongitude;
      var routingParameters = {};

      routingParameters = {
        mode: 'fastest;' + type + ';traffic:disabled;',
        waypoint0: pickupLocation,
        waypoint1: dropLocation,
        routeattributes: 'none,summary',
      };

      var router = this.platform.getRoutingService(null, 7);
      return router.calculateRoute(
        routingParameters,
        (result: any) => {
          return result;
        },
        (error: any) => {
          return 'error occuring';
        }
      );
    }
  }


  calculateRouteForTwoLanes(pickupPoint, dropPoint) {
    this.platform = new H.service.Platform({
      apikey: environment.heremapapiKey,
    });

    if ( pickupPoint && dropPoint) {
      let firstWaypoint =
        'geo!' + pickupPoint.latitude + ',' + pickupPoint.longitude;
      let secondWaypoint = null;
      if (dropPoint)
        secondWaypoint =
          'geo!' + dropPoint.latitude + ',' + dropPoint.longitude;
      var routingParameters = {};
     
        routingParameters = {
          mode: 'fastest;truck;traffic:disabled',
          waypoint0: firstWaypoint,
          waypoint1: secondWaypoint,
          routeattributes: 'none,summary',
        };
      }
      var router = this.platform.getRoutingService(null, 7);
      return router.calculateRoute(
        routingParameters,
        (result) => {
          return result;
        },
        (error) => {
          return error
        }
      );
    } 
}
